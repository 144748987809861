import dayjs from 'dayjs';

// Date Formats - https://day.js.org/docs/en/display/format
export const FORMAT_SINGLE_DIGIT_SHORT_YEAR = 'M/D/YY';
export const FORMAT_SHORT = 'M/D/YYYY';
export const FORMAT_LONG = 'dddd, MMMM D, YYYY';
export const FORMAT_LONG_TIME = `${FORMAT_LONG} h:mm A`;
export const FORMAT_ABBREVIATED = 'MMM D, YYYY';
export const FORMAT_ABBREVIATED_TIME = `${FORMAT_ABBREVIATED} h:mm A`;

/**
 * Use formatDateWithoutTimezone for Encompass dates
 */
export const formatDateWithoutTimezone = (date: string, format?: string) => formatDate(date.split('T')[0], format);

export const formatDate = (date: string | Date, format?: string) => dayjs(date).format(format);
