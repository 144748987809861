import { iHELOCLoan } from '../features/helocLoans/types';

export const helocLoanFunded: iHELOCLoan = {
  "tenantId": "gri",
  "productAccountId": "20db9ba9-50e1-438c-91a3-9216fef82948",
  "productId": "e7885c3b-4b62-4608-b581-aad7a4fd4498",
  "borrowerId": "dc0ee27e-7ff8-418b-a6a6-a316ab7f99d0",
  "loanDetails": {
    "fundingDate": "2022-08-10",
    "initialDrawAmount": 35000,
    "term": 120,
    "termType": "months",
    "interestRate": 0.0825,
    "originalEffectiveDate": 1696477642,
    "status": "funded",
    "originalDrawPeriod": "36"
  },
  "productType": "HELOC",
  "id": "e7885c3b-4b62-4608-b581-aad7a4fd4498",
  "propertyAddress": {
    "street": [
      "3940 N Ravenswood"
    ],
    "city": "Chicago",
    "state": "ID",
    "zip": "83616"
  },
  "program": "FIGURE_GR_HELOC",
  "originator": {
    "accountId": "bcbd3b9c-13bb-4ec0-bec0-1258f78dbf23",
    "customerId": "671a1120-21ce-4fdc-a307-a98d59f6bceb",
    "loanNumber": "1-22207-652"
  },
};

export default helocLoanFunded;
