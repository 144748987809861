import { useState, useEffect, useRef, FormEvent, FocusEvent, ChangeEvent, memo, SyntheticEvent, AnimationEvent } from 'react';
import cn from 'classnames';
import axios, { AxiosError } from 'axios';
import Form from '../ui/form/Form';
import { FormGroupWrapper } from '../ui/form/FormGroup';
import FormGroupError from '../ui/form/FormGroupError';
import FormLabel from '../ui/form/FormLabel';
import FormInput from '../ui/form/FormInput';
import FormButton from '../ui/form/FormButton';
import Icon from '../ui/icon/Icon';
import { bgColors, icons, bgColorAndIcon } from './ICreatePassword';

import './CreatePassword.scss';

interface CreatePasswordProps {
  handleSubmitPassword: (password: string) => Promise<void>;
  handlePasswordError: (error: any) => void;
}

interface PasswordErrorResponse {
  message: string;
}

// input ids
const PASSWORD_ID = 'create-password';
const PASSWORD_CONFIRMATION_ID = 'confirm-password';

// max length for password and passwordConfirmation
const MAX_PASSWORD_LENGTH = 72;

// all allowed regex patterns (null is for map that follows)
const allAllowedChars = ['a-z', 'A-Z', '0-9', `"!#$%&'()*+,\\-./\\\\:;<=>?@[\\]^_\`{|}~`, ' ', null];
// regExpPatterns = [ /[a-z]/, /[A-Z]/, /[0-9]/, /['!#$%&'()*+,\-./\\:;<=>?@[\]^_`{|}~]/, /[^a-zA-Z0-9'!#$%&'()*+,\-./\\:;<=>?@[\]^_`{|}~ ]/g ];
const regExpPatterns = allAllowedChars.map((val, i, arr) => {
  if (i < arr.length - 1) return new RegExp(`[${val}]`); // for getBgColorsAndIcons function
  return new RegExp(`[^${arr.join('')}]`); // to ensure only valid characters are used
});

const ERRORS = {
  // password error feedback
  INVALID_CHARACTER: 'Your password must contain standard ASCII characters only.',
  MAX_PASSWORD_LENGTH_EXCEEDED: 'Your password must be 72 characters or fewer.',
  REQUIREMENTS_NOT_MET: 'Your password must fulfill all requirements.',
  // password and passwordConfirmation error feedback
  PROVIDE_YOUR_INPUT: 'Please provide your input.',
  // passwordConfirmation error feedback
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match.',
  // submission error feedback
  OKTA_ERROR: 'Password does not meet complexity requirements, please try again.',
  UNEXPECTED_ERROR: 'An unexpected error has occurred, please try again.',
} as const;

/**
 * Allows a borrower to create a password
 */
const CreatePassword = ({ handleSubmitPassword, handlePasswordError }: CreatePasswordProps) => {
  const [autofill, setAutofill] = useState(false);
  const isMountedRef = useRef(false);

  // password state
  const [password, setPassword] = useState('');
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [wasPasswordInteractedWith, setWasPasswordInteractedWith] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const passwordRef = useRef<HTMLInputElement>(null);

  // password confirmation state
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isPasswordConfirmationHidden, setIsPasswordConfirmationHidden] = useState(true);
  const [isPasswordConfirmationFocused, setIsPasswordConfirmationFocused] = useState(false);
  const [wasPasswordConfirmationInteractedWith, setWasPasswordConfirmationInteractedWith] = useState(false);
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
  const passwordConfirmationRef = useRef<HTMLInputElement>(null);

  // validation state
  const [bgColorsAndIcons, setBgColorsAndIcons] = useState<bgColorAndIcon[]>([]);
  const [passwordMeetsGivenCriteria, setPasswordMeetsGivenCriteria] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  // submission state
  const [isSettingUpAccount, setIsSettingUpAccount] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  /**
   * Validation useEffects
   */
  // update Validation component's icons/colors when password changes
  useEffect(() => {
    setBgColorsAndIcons(getBgColorsAndIcons(password));
  }, [password]);

  // if all given password criteria are met, set state as so
  useEffect(() => {
    if (bgColorsAndIcons.length && bgColorsAndIcons.every(obj => obj.bgColor === 'bg-ok')) {
      setPasswordMeetsGivenCriteria(true);
    } else {
      setPasswordMeetsGivenCriteria(false);
    }
  }, [bgColorsAndIcons]);

  // main validation
  useEffect(() => {
    // password and passwordConfirmation match and meet given criteria
    if (password && passwordConfirmation && password === passwordConfirmation && passwordMeetsGivenCriteria) {
      // on first blur and every keystroke thereafter
      if (wasPasswordInteractedWith) {
        // if it contains an invalid character
        if (regExpPatterns[regExpPatterns.length - 1].test(password)) {
          setPasswordError(ERRORS.INVALID_CHARACTER);
          setPasswordsMatch(false);
          // if it exceeds max length
        } else if (password.length > MAX_PASSWORD_LENGTH) {
          setPasswordError(ERRORS.MAX_PASSWORD_LENGTH_EXCEEDED);
          setPasswordsMatch(false);
          // otherwise, clear error and setPasswordsMatch
        } else {
          setPasswordError('');
          setPasswordsMatch(true);
        }
      }

      // since password && passwordConfirmation && password === passwordConfirmation,
      // clear error, if any
      setPasswordConfirmationError('');

      // password and passwordConfirmation do not match and/or do not meet given criteria
    } else {
      // on first blur and every keystroke thereafter
      if (wasPasswordInteractedWith) {
        // if it contains an invalid character
        if (regExpPatterns[regExpPatterns.length - 1].test(password)) {
          setPasswordError(ERRORS.INVALID_CHARACTER);
          // if it exceeds max length
        } else if (password.length > MAX_PASSWORD_LENGTH) {
          setPasswordError(ERRORS.MAX_PASSWORD_LENGTH_EXCEEDED);
          // if no input
        } else if (!password) {
          setPasswordError(ERRORS.PROVIDE_YOUR_INPUT);
          // if does not meet given criteria
        } else if (!passwordMeetsGivenCriteria) {
          setPasswordError(ERRORS.REQUIREMENTS_NOT_MET);
          // if password and passwordConfirmation do not match, but password meets given criteria
        } else if (passwordMeetsGivenCriteria) {
          setPasswordError('');
        }
      }

      // on blur only and every keystroke thereafter
      if (wasPasswordConfirmationInteractedWith) {
        // if they do not match
        if (passwordConfirmation && passwordConfirmation !== password) {
          setPasswordConfirmationError(ERRORS.PASSWORDS_DO_NOT_MATCH);
          // if they do match, remove error feedback, regardless of meeting given criteria
        } else if (passwordConfirmation && passwordConfirmation === password) {
          setPasswordConfirmationError('');
          // if no input
        } else if (!passwordConfirmation) {
          setPasswordConfirmationError(ERRORS.PROVIDE_YOUR_INPUT);
        }
      }

      setPasswordsMatch(false);
    }
  }, [
    password,
    passwordConfirmation,
    passwordMeetsGivenCriteria,
    wasPasswordInteractedWith,
    wasPasswordConfirmationInteractedWith,
  ]);

  /**
   * Show/Hide input useEffects
   */
  // when toggling show/hide, focus on password input element
  useEffect(() => {
    isMountedRef.current && passwordRef.current?.querySelector('input')?.focus();
  }, [isPasswordHidden]);

  // when toggling show/hide, focus on passwordConfirmation input element
  useEffect(() => {
    isMountedRef.current && passwordConfirmationRef.current?.querySelector('input')?.focus();
  }, [isPasswordConfirmationHidden]);

  // indicate when mounted (for toggling show/hide)
  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  /**
   * onChange handler for both inputs
   */
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target as HTMLElement;

    if (id === PASSWORD_ID) {
      setPassword(e.target.value);
    } else if (id === PASSWORD_CONFIRMATION_ID) {
      setPasswordConfirmation(e.target.value);
    }

    // reset submission error, if any
    setSubmissionError('');
  };

  /**
   * onFocus handler for both inputs
   */
  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    const { id } = e.target as HTMLElement;

    if (id === PASSWORD_ID) {
      setIsPasswordFocused(true);
    } else if (id === PASSWORD_CONFIRMATION_ID) {
      setIsPasswordConfirmationFocused(true);
    }
  };

  /**
   * onBlur handler for both inputs
   */
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    // if user clicks on show/hide via onMouseDown,
    // then e.target === document.activeElement
    if (e.target === document.activeElement) return;

    const { id } = e.target as HTMLElement;

    // if password input
    if (id === PASSWORD_ID) {
      setIsPasswordFocused(false);
      setWasPasswordInteractedWith(true);

      // else if passwordConfirmation input
    } else if (id === PASSWORD_CONFIRMATION_ID) {
      setIsPasswordConfirmationFocused(false);
      setWasPasswordConfirmationInteractedWith(true);
    }
  };

  /**
   * Show/Hide input values handler for both input elements
   */
  const handleShowHideInputValue = (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const toggle = (target.closest('.create-password__show-hide') as HTMLElement)?.dataset.toggle;

    if (toggle === 'password' && passwordRef.current) {
      setIsPasswordHidden(prev => !prev);
    } else if (toggle === 'passwordConfirmation' && passwordConfirmationRef.current) {
      setIsPasswordConfirmationHidden(prev => !prev);
    }
  };

  /**
   * onKeyUp handler to initiate live validation
   */
  const handleOnKeyUp = (e: KeyboardEvent) => {
    if (e.code === 'Enter' || e.key === 'Enter') {
      const { id } = e.target as HTMLElement;
      if (id === PASSWORD_ID) {
        setWasPasswordInteractedWith(true);
      } else if (id === PASSWORD_CONFIRMATION_ID) {
        setWasPasswordConfirmationInteractedWith(true);
      }
    }
  };

  /**
   * onSubmit handler
   */
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (isSettingUpAccount) return;

    setIsSettingUpAccount(true);
    setIsPasswordHidden(true);
    setIsPasswordConfirmationHidden(true);

    try {
      await handleSubmitPassword(password);
    } catch (err) {
      handlePasswordError(err);
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError<PasswordErrorResponse>;
        if (error.response?.status === 500) {
          setSubmissionError(ERRORS.UNEXPECTED_ERROR);
        } else if (error.response?.status === 400) {
          // Okta password criteria error
          const oktaError = error.response?.data?.message || ERRORS.OKTA_ERROR;
          setSubmissionError(oktaError);
        }
      } else {
        // display error in case of, for example, server error or network disruption
        setSubmissionError(ERRORS.UNEXPECTED_ERROR);
      }

      setIsSettingUpAccount(false);
    }
  };

  /**
   * onAnimationStart handler
   */
  const handleAnimationStart = (evt: AnimationEvent<HTMLInputElement>) => {
    if (evt.animationName === 'onAutoFillStart') {
      setAutofill(true);
    }
  };

  /**
   * Scoped variables
   */
  const isPasswordActive = isPasswordFocused || autofill || !!password;
  const isPasswordConfirmationActive = isPasswordConfirmationFocused || autofill || !!passwordConfirmation;

  return (
    <Form className='flex flex-col items-center create-password' onSubmit={handleSubmit}>
      <h1 className='header-medium lg:text-5xl mb-4 text-center'>Let's create your password</h1>
      <p className='text-lg mb-8 text-center'>Enter and confirm a password to finish creating your account.</p>
      <div ref={passwordRef} className='create-password__form-group-container w-full relative'>
        {/* password input */}
        <FormGroupWrapper className='w-full' active={isPasswordActive} error={passwordError}>
          <FormLabel htmlFor={PASSWORD_ID}>New password</FormLabel>
          <FormInput
            value={password}
            hasError={!!passwordError}
            id={PASSWORD_ID}
            onChange={handleOnChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyUp={handleOnKeyUp}
            onAnimationStart={handleAnimationStart}
            autoComplete='new-password'
            minLength='8'
            required={true}
            type={isPasswordHidden ? 'password' : 'text'}
            spellCheck={false}
          />
          <FormGroupError error={passwordError} id={PASSWORD_ID} />
        </FormGroupWrapper>
        <div
          className='create-password__show-hide cursor-pointer z-10'
          onMouseDown={handleShowHideInputValue}
          data-toggle='password'
          title={isPasswordHidden ? 'Show password' : 'Hide password'}
        >
          {isPasswordHidden ?
            <Icon className='icon-show' name='eye-show' /> :
            <Icon className='icon-hide' name='eye-hide' />
          }
        </div>
      </div>

      <div ref={passwordConfirmationRef} className='create-password__form-group-container w-full relative'>
        {/* password confirmation input */}
        <FormGroupWrapper className='w-full' active={isPasswordConfirmationActive} error={passwordConfirmationError}>
          <FormLabel htmlFor={PASSWORD_CONFIRMATION_ID}>Confirm new password</FormLabel>
          <FormInput
            value={passwordConfirmation}
            hasError={!!passwordConfirmationError}
            id={PASSWORD_CONFIRMATION_ID}
            onChange={handleOnChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyUp={handleOnKeyUp}
            onAnimationStart={handleAnimationStart}
            autoComplete='new-password'
            minLength='8'
            required={true}
            type={isPasswordConfirmationHidden ? 'password' : 'text'}
            spellCheck={false}
          />
          <FormGroupError error={passwordConfirmationError} id={PASSWORD_CONFIRMATION_ID} />
        </FormGroupWrapper>
        <div
          className='create-password__show-hide cursor-pointer z-10'
          onMouseDown={handleShowHideInputValue}
          data-toggle='passwordConfirmation'
          title={isPasswordConfirmationHidden ? 'Show password' : 'Hide password'}
        >
          {isPasswordConfirmationHidden ?
            <Icon className='icon-show' name='eye-show' /> :
            <Icon className='icon-hide' name='eye-hide' />
          }
        </div>
      </div>

      <Validation bgColorsAndIcons={bgColorsAndIcons} />

      <FormButton
        className={cn('w-full', 'h-12', 'mb-4', {
          'create-password__button-disabled': !passwordsMatch,
        })}
        disabled={!passwordsMatch}
        loading={isSettingUpAccount}
      >
        Create my account
      </FormButton>

      {/* server error, network disruption, Okta complexity requirements error */}
      {submissionError && (
        <p className='text-critical body-disclaimer text-center create-password__submission-error'>{submissionError}</p>
      )}
    </Form>
  );
};

const validationCriteria = [
  'At least 8 characters',
  'Contains an uppercase and lowercase character',
  'Contains a number and symbol',
];

const grayX: bgColorAndIcon = {
  bgColor: 'bg-gray-25',
  icon: 'close',
};
const redX: bgColorAndIcon = {
  bgColor: 'bg-critical',
  icon: 'close',
};

const greenCheck: bgColorAndIcon = {
  bgColor: 'bg-ok',
  icon: 'check-tick',
};

/**
 * Determines icons/color based on validation of given criteria
 */
const getBgColorsAndIcons = (password: string) => {
  if (!password.length) return new Array(validationCriteria.length).fill(grayX);

  return validationCriteria.map((criterion, i) => {
    switch (i) {
      case 0:
        // test for min length
        return password.length >= 8 ? greenCheck : redX;
      case 1:
        // test for upper and lower case
        return regExpPatterns[0].test(password) && regExpPatterns[1].test(password) ? greenCheck : redX;
      case 2:
        // test for number and symbol
        return regExpPatterns[2].test(password) && regExpPatterns[3].test(password) ? greenCheck : redX;
      default:
        return grayX;
    }
  });
};

/**
 * Component to render icons/color based on `getBgColorsAndIcons` function
 */
const Validation = memo(
  ({ bgColorsAndIcons }: { bgColorsAndIcons: bgColorAndIcon[] }) => {
    return (
      <div className='create-password__validation-criteria w-full mb-6'>
        {bgColorsAndIcons.map((obj, i, arr) => {
          return (
            <div className='flex mb-2' key={validationCriteria[i]}>
              <ValidationIcon bgColor={obj.bgColor} icon={obj.icon} />
              <p>{validationCriteria[i]}</p>
            </div>
          );
        })}
      </div>
    );
  },
  // areEqual function
  (prevProps: { bgColorsAndIcons: bgColorAndIcon[] }, nextProps: { bgColorsAndIcons: bgColorAndIcon[] }) => {
    let prevBgColor;
    let prevIcon;
    let nextBgColor;
    let nextIcon;
    for (let i = 0; i < nextProps.bgColorsAndIcons.length; i++) {
      prevBgColor = prevProps.bgColorsAndIcons[i]?.bgColor;
      prevIcon = prevProps.bgColorsAndIcons[i]?.icon;
      nextBgColor = nextProps.bgColorsAndIcons[i]?.bgColor;
      nextIcon = nextProps.bgColorsAndIcons[i]?.icon;

      if (prevBgColor !== nextBgColor || prevIcon !== nextIcon) {
        return false; // are not equal, invoke callback
      }
    }

    return true; // are equal, use memoized result
  },
);

/**
 * Icon used for validation
 */
const ValidationIcon = ({ bgColor, icon }: { bgColor: bgColors; icon: icons }) => (
  <div className={`flex justify-center items-center w-6 h-6 rounded-full mr-4 p-2 ${bgColor}`}>
    <Icon name={icon} className='text-white text-xs' />
  </div>
);

export default CreatePassword;
