import { selectAppLanguage } from '../../../features/app/appSlice';
import type { Loan } from '../../../features/loans/types';
import { selectUser } from '../../../features/user/userSlice';
import { useAppSelector } from '../../../hooks/hooks';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';
import { DEFAULT_LANGUAGE } from '../../../utils/multilingual';
import Alert from '../../ui/alert/Alert';
import { AlertType } from '../../ui/alert/IAlert';

const TASK_DISCLAIMER_CONTENT: Record<string, string> = {
  en: 'Tasks or task customization may not be available in Spanish. If you want the interface in Spanish, contact your loan officer to update your language preference.',
  es: 'Es posible que tareas o la personalización de tareas no estén disponibles en español. Si deseas el interfaz en español comunícate con tu oficial de préstamos para actualizar tu preferencia de idioma.',
};

const DOCUMENTS_DISCLAIMER_CONTENT: Record<string, string> = {
  en: 'Financial documents to validate income, assets, debts, identity or other information such as rental lease agreements, gift funds, divorce decrees must be provided in English. If you are unable to provide the English version to avoid delays, please notify your mortgage expert as soon as possible.',
  es: 'Documentos financieros para validar ingresos, activos, deudas, identidad u otra información, como contratos de arrendamiento, fondos de donación y sentencias de divorcio, deben proporcionarse en inglés. Si no puede proporcionar la versión en inglés para evitar demoras, notifique a su experto en hipotecas lo antes posible.',
};

export const TasksTranslationAlert = ({ loan }: { loan: Loan }) => {
  const multilingual = useFeatureEnabled('multilingual');
  const appLanguage = useAppSelector(selectAppLanguage);

  if (!multilingual || loan['lap?'] || !appLanguage || appLanguage === DEFAULT_LANGUAGE) {
    return null;
  }

  return <Alert type='info' description={TASK_DISCLAIMER_CONTENT[appLanguage]} className='OneLinkNoTx mb-6' showClose={false} />;
};

export const DocumentsTranslationAlert = ({ loan }: { loan: Loan }) => {
  const multilingual = useFeatureEnabled('multilingual');
  const appLanguage = useAppSelector(selectAppLanguage);
  const userLanguagePreference = useAppSelector(selectUser)?.['language-preference'];

  // Always show alert if selected app language is not English
  // Also show alert if app language is English but user's preferred language is something else
  const usesDefaultLanguage =
    (!appLanguage || appLanguage === DEFAULT_LANGUAGE) && (!userLanguagePreference || userLanguagePreference === DEFAULT_LANGUAGE);

  if (!multilingual || usesDefaultLanguage) {
    return null;
  }

  return (
    <Alert
      type='info'
      description={DOCUMENTS_DISCLAIMER_CONTENT[appLanguage || DEFAULT_LANGUAGE]}
      className='OneLinkNoTx mb-6'
      showClose={false}
    />
  );
};

const tasklistAlertTypes = ['noTasks', 'tasksCompleted'] as const;
type TasklistAlertTypes = (typeof tasklistAlertTypes)[number];

const TasklistAlerts: { [type in TasklistAlertTypes]: { alertType: AlertType; alertText: string } } = {
  noTasks: {
    alertType: 'info',
    alertText: 'Hang tight while we set up your loan. Check back periodically to see your associated tasks.',
  },
  tasksCompleted: {
    alertType: 'success',
    alertText: 'Great! You have completed all your tasks.',
  },
};

const TasklistAlert = ({ type }: { type: TasklistAlertTypes }) => {
  return (
    <Alert type={TasklistAlerts[type].alertType} description={TasklistAlerts[type].alertText} showClose={false} className='mb-8' />
  );
};

export default TasklistAlert;
