import { useEffect, useRef } from 'react';
import cn from 'classnames';
import nextId from '../../utils/nextId';
import HeaderLogo from '../header/HeaderLogo';
import Button from '../ui/button/Button';
import Icon from '../ui/icon/Icon';
import { MainNavigationLinks, SecondaryNavigationLinks } from './Navigation';
import useError from '../../hooks/useError';
import { useAppDispatch } from '../../hooks/hooks';
import { unauthenticate } from '../../features/auth/authSlice';
import { useCurrentLo } from '../../hooks/useCurrentLo';
import { LoanOfficerComponentRow } from '../loan/support/Support';
import { LoanOfficer } from '../../features/loans/types';
import { content } from '../../features/tenant/tenant';
import ehlLogo from '../../images/ehl-logo.svg';

/**
 * Left flyout for mobile navigation
 */

const TopMenuTop = () => {
  const { showingError } = useError();

  if (showingError) return null;

  return (
    <div>
      <nav aria-label='Main navigation' role='navigation'>
        <MainNavigationLinks className='w-full' />
      </nav>
    </div>
  );
};

const UserMenu = () => {
  return <SecondaryNavigationLinks />;
};

const LoanTeam = () => {
  const { lo } = useCurrentLo();

  if (!lo || !lo.email || lo.status === 'inactive') return null;

  return (
    <div className='mt-3 mx-2'>
      <LoanOfficerComponentRow loanOfficer={lo as LoanOfficer} />
      <div className='mt-6 mb-3 border-b border-gray-25' />
    </div>
  );
};

const FooterMenu = () => {
  return (
    <div className='flex flex-col mt-3'>
      <img
        src={ehlLogo}
        alt='Equal Housing Lender Badge'
        width='150'
        height='30'
        style={{ width: '150px', height: '30px' }}
      />
      <p className='mx-2 my-3 text-sm' style={{ fontStretch: 'condensed' }}>
        Copyright &copy; {content.footer.copyrightStart && `${content.footer.copyrightStart}-`}
        {new Date().getFullYear()} <span className='OneLinkNoTx'>{content.legalName || content.company}</span>. All rights reserved.
      </p>
      {content.footer.licenses.map((license, i) => (
        <p className='mx-2 text-sm' style={{ fontStretch: 'condensed' }} key={i}>
          {license.licenseText}
          {license.delimiter || ' #'}
          {license.licenseNumber}
        </p>
      ))}
    </div>
  );
};

const TopMenu = ({ id, closeFn }: { id?: string; closeFn: () => void }) => {
  const dispatch = useAppDispatch();
  id = id || nextId('flyout-');
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleCloseMenu = () => {
    closeFn?.();
  };

  const handleLogout = () => {
    dispatch(unauthenticate());
  };

  useEffect(() => {
    const parentEl = containerRef.current;

    if (!parentEl) return;

    const eventListener = (evt: MouseEvent): void => {
      const target = evt.target as HTMLElement;
      if (target.closest('button, a')) {
        closeFn?.();
      }
    };

    parentEl.addEventListener('click', eventListener);
    return () => {
      parentEl.removeEventListener('click', eventListener);
    };
  }, [closeFn]);

  const logoContainerClassnames = 'flex flex-row justify-between items-center bg-white w-full mb-4';

  return (
    <div className='p-6 pt-4 flex flex-col h-full overflow-auto' id={id} ref={containerRef}>
      <div className={cn(logoContainerClassnames)}>
        <Button
          buttonType='icon'
          icon={<Icon name='close' size='1rem' hoverEffect={true} />}
          rotate={true}
          text='Close menu'
          onClick={handleCloseMenu}
          buttonRef={closeButtonRef}
        />
        <HeaderLogo className='justify-start flex-grow ml-4 lg:ml-0' />
        <Button
          buttonType='quaternary'
          text='Log out'
          onClick={handleLogout}
        />
      </div>
      <section id='top-menu'>
        <TopMenuTop />
        <div className='mx-2 my-3 border-b border-gray-25' />
      </section>
      <section id='user-menu'>
        <UserMenu />
        <div className='mx-2 my-3 border-b border-gray-25' />
      </section>
      <section id='support-menu'>
        <LoanTeam />
      </section>
      <section id='footer-menu'>
        <FooterMenu />
      </section>
    </div>
  );
};

export default TopMenu;
