import getFromStore from '../../store/getFromStore';
import { selectAppState, selectGlobalMobileApp, selectSessionId, selectAppLanguage } from './appSlice';
import type { AppState } from './types';

export const getAppFromStore = () => getFromStore(selectAppState) as AppState;

export const getAppLanguageFromStore = () => getFromStore(selectAppLanguage);

export const getAppSessionIdFromStore = () => getFromStore(selectSessionId);

export const getAppGlobalMobileAppFromStore = () => getFromStore(selectGlobalMobileApp);
