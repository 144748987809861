import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { patchTask } from '../../../features/tasks/actions';
import { Task } from '../../../features/tasks/types';
import { useAdditionalTaskDetails } from '../../../hooks/useAdditionalTaskDetails';
import useScript from '../../../hooks/useScript';
import { useTaskText } from '../../../hooks/useTaskText';
import { log } from '../../../utils/logger';
import { serializeError } from '../../../utils/serializeError';
import FormButtonComponent from '../../ui/form/FormButtonComponent';
import Icon from '../../ui/icon/Icon';
import { AdditionalTaskDetails, DefaultTaskBodyDescription } from '../task/Task';
import { AxiosError } from 'axios';
import { ErrorType, errorMessages, iVoieStatus } from './Voie';
import { sendTaskUpdateEvent } from '../../../features/analytics/sendTaskUpdateEvent';

const VoieReverifyAssets = ({
  loanGuid,
  task,
  onError,
}: {
  loanGuid: string;
  task: Task<'credentialed_voie_reverify'>;
  onError: (error: string | undefined) => void;
}) => {
  const { 'task-id': taskId, 'task-completed': taskCompleted, source, 'task-meta': taskMeta, 'task-type': taskType } = task;
  const { publishableKey, sessionToken } = taskMeta || {};
  const [status, setStatus] = useState<iVoieStatus>('idle');
  const [scriptLoaded, scriptHasError, scriptError] = useScript({
    src: 'https://js.truework.com/v1',
    skip: status !== 'loading',
  });
  const dispatch = useDispatch();

  const handleError = (errorType: ErrorType, error: string) => {
    setStatus('error');
    onError(errorMessages[errorType] || errorMessages.defaultAutomatically);
    log({ level: 'error', loanGuid, taskId, message: `Failed to re-verify VOIE: ${errorType} ${error}` });
  };

  useEffect(() => {
    if (status !== 'error') {
      onError(undefined);
    }
  }, [onError, status]);

  useEffect(() => {
    if (!publishableKey || !sessionToken) {
      setStatus('error');
      onError('Error: Task is missing data required to proceed.');
    } else {
      setStatus('idle');
    }
  }, [onError, publishableKey, sessionToken]);

  useEffect(() => {
    if (scriptHasError) {
      setStatus('error');
      onError(errorMessages['trueworkScriptError'] || errorMessages.defaultAutomatically);
      log({
        loanGuid,
        taskId: taskId,
        level: 'error',
        message: `VOIE Reverify: Truework script did not load: ${JSON.stringify({ scriptError })}`,
      });
    }
  }, [loanGuid, scriptError, scriptHasError, scriptLoaded, taskId, onError]);

  useEffect(() => {
    const trueworkModule = window.Truework?.credentials;
    if (status === 'loading' && scriptLoaded && trueworkModule && publishableKey && sessionToken) {
      try {
        const credentials = trueworkModule.init({
          publishableKey: publishableKey,
          sessionToken: sessionToken,
          env: trueworkModule.Env.Production,
        });
        credentials.onOpen(() => {
          setStatus('idle');
        });
        credentials.onSuccess(() => {
          sendTaskUpdateEvent({ taskId, taskType });
          setStatus('success');
          if (!taskCompleted) {
            setStatus('patching');
            dispatch(patchTask(loanGuid, taskId, { source, 'task-completed': true }))
              .then(() => setStatus('success'))
              .catch((error: AxiosError) => {
                setStatus('error');
                handleError('taskPatchError', serializeError(error));
              });
          }
        });
        credentials.onError(e => {
          handleError('trueworkOnError', JSON.stringify(e));
        });
        credentials.open();
      } catch (error) {
        setStatus('error');
        handleError('trueworkUnknown', serializeError(error));
      }
    } else if (scriptLoaded && !trueworkModule) {
      handleError('trueworkModuleMissing', 'Script loaded, but Truework credentials object not found.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded, status, publishableKey, sessionToken]);

  const handleButtonClick = async () => {
    log({ loanGuid, taskId, level: 'info', message: 'VOIE: Reverify' });
    setStatus('loading'); // Triggers Truework JS script to load
  };

  return (
    <>
      {!taskCompleted && (
        <FormButtonComponent
          containerClassName='pb-3'
          buttonContainerClassName='mt-0 w-full md:w-fit-content'
          className='w-full md:w-fit-content'
          buttonType='primary'
          onClick={handleButtonClick}
          loading={status === 'loading' || status === 'patching'}
          disabled={!sessionToken || !publishableKey}
        >
          Verify your employment
        </FormButtonComponent>
      )}
      {status === 'success' && (
        <div className='flex justify-center items-center mb-3'>
          <Icon name='check-tick' className='text-ok mr-2' size='0.75rem' />
          <p>Your submission was successful</p>
        </div>
      )}
    </>
  );
};

const VoieReverifyTaskBody = ({ loanGuid, task }: { loanGuid: string; task: Task<'credentialed_voie_reverify'> }) => {
  const [error, setError] = useState<string | undefined>();
  const taskDescription = useTaskText(task, 'description', loanGuid);
  const additionalDetails = useAdditionalTaskDetails(task, loanGuid);
  const { 'task-completed': isTaskCompleted } = task;

  const handleError = (error: string | undefined) => {
    setError(error);
  };

  return (
    <>
      <DefaultTaskBodyDescription taskDescription={taskDescription} />
      {additionalDetails && <AdditionalTaskDetails additionalDetails={additionalDetails} />}
      <div className='flex flex-col justify-center'>
        <VoieReverifyAssets loanGuid={loanGuid} task={task} onError={handleError} />
        {isTaskCompleted && <div className='flex font-bold justify-center'>You have successfully connected to your payroll.</div>}
        {error && (
          <div className='text-center h-0'>
            <p className='form-button-error-text text-error-important'>{error}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default VoieReverifyTaskBody;
