import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { useAppSelector } from '../../hooks/hooks';
import { RequestError } from '../../interfaces/IRequest';
import type { AppDispatch, RootState } from '../../store/types';
import { log } from '../../utils/logger';
import { setIdentity } from '../analytics/hotjar';
import { setDataLayer } from '../analytics/setDataLayer';
import { setIdentity as setApmIdentity } from '../apm/apm';
import api from '../api';
import { forceLogout } from '../auth/authSlice';
import {
  LOAN_ATTRIBUTES,
  LoanAttributes,
  LoanAttributesEntity,
  LoanAttributesMap,
  User,
  UserAttributeKey,
  UserAttributesMap,
  UserEntity,
  UserKey,
  UserState,
} from './types';

const namespace = 'user';

const initialState: UserState = {
  user: {
    loadingStatus: 'idle',
    data: null,
  },
  attributes: {
    loadingStatus: 'idle',
    data: {},
  },
  docsPreviouslyLastViewed: {},
};

export const fetchUser = createAsyncThunk<User, undefined, { dispatch: AppDispatch }>(
  `${namespace}/fetchUser`,
  async (_, { dispatch }) => {
    try {
      const response = await api.getUser();
      return response.data;
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      if (error.response && error.response.status === 401) {
        dispatch(forceLogout('fetchUser'));
      } else {
        if (!axios.isAxiosError(error)) {
          log({
            level: 'error',
            message: `fetchUser error - ${error}`,
          });
        }
      }
      throw err;
    }
  },
);

export const updateUser = createAsyncThunk<User, { key: UserKey; value: string }>(
  `${namespace}/updateUser`,
  async ({ key, value }, { getState }) => {
    try {
      const userState = selectUser(getState() as RootState);
      await api.updateUser(key, value);
      return { ...userState, [key]: value } as User;
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      if (!axios.isAxiosError(error)) {
        log({
          level: 'error',
          message: `updateUser error - ${error}`,
        });
      }
      throw err;
    }
  },
);

export const fetchUserAttribute = createAsyncThunk<
  UserAttributesMap[UserAttributeKey] | Record<string, never>,
  UserAttributeKey,
  {
    dispatch: AppDispatch;
  }
>(`${namespace}/fetchUserAttribute`, async (attributeKey, { dispatch }) => {
  try {
    const response = await api.getUserAttribute(attributeKey);
    return response.data;
  } catch (err) {
    const error = err as AxiosError<RequestError>;
    if (error.response && error.response.status === 401) {
      dispatch(forceLogout('fetchUserAttribute'));
    } else {
      if (!axios.isAxiosError(error)) {
        log({
          level: 'error',
          message: `fetchUserAttribute ${attributeKey} error - ${error}`,
        });
      }
    }
    throw err;
  }
});

export const updateUserLoanAttribute = createAsyncThunk<LoanAttributesMap, { loanGuid: string; value: Partial<LoanAttributes> }>(
  `${namespace}/updateUserLoanAttribute`,
  async ({ loanGuid, value }, { getState }) => {
    const state = getState() as RootState;
    const currentState = (state.user as UserState).attributes.data[LOAN_ATTRIBUTES] || {};
    const payload = {
      ...currentState,
      [loanGuid]: {
        ...currentState[loanGuid],
        ...value,
      },
    };
    const resp = await api.postUserAttribute(LOAN_ATTRIBUTES, payload);
    return resp.data;
  },
);

export const userSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUser.pending, state => {
        state.user.loadingStatus = 'pending';
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.user.loadingStatus = 'success';
        state.user.data = payload;
        setApmIdentity({ guid: payload.guid, email: payload.email });
        setDataLayer('user', payload);
        setIdentity(payload);
      })
      .addCase(fetchUser.rejected, (state, { error }) => {
        state.user.loadingStatus = 'failed';
        state.user.error = error;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.user.data = payload;
        setDataLayer('user', payload);
      })
      .addCase(fetchUserAttribute.pending, state => {
        state.attributes.loadingStatus = 'pending';
      })
      .addCase(fetchUserAttribute.fulfilled, (state, { payload, meta }) => {
        state.attributes.loadingStatus = 'success';
        if (meta.arg === LOAN_ATTRIBUTES) {
          for (const loanGuid in payload) {
            const lastViewed = payload[loanGuid]['docs-last-viewed'];
            if (lastViewed) {
              state.docsPreviouslyLastViewed[loanGuid] = state.docsPreviouslyLastViewed[loanGuid] || lastViewed;
            }
          }
        }
        state.attributes.data[meta.arg] = payload;
      })
      .addCase(fetchUserAttribute.rejected, (state, { error }) => {
        state.attributes.loadingStatus = 'failed';
        state.attributes.error = error;
      })
      .addCase(updateUserLoanAttribute.pending, (state, { meta }) => {
        const { loanGuid, value } = meta.arg;
        const docsPreviouslyLastViewed = state.attributes.data[LOAN_ATTRIBUTES]?.[loanGuid]?.['docs-last-viewed'];
        state.attributes.data[LOAN_ATTRIBUTES] = {
          ...state.attributes.data[LOAN_ATTRIBUTES],
          [loanGuid]: {
            ...state.attributes.data[LOAN_ATTRIBUTES]?.[loanGuid],
            ...value,
          },
        };
        if (value['docs-last-viewed'] && docsPreviouslyLastViewed && value['docs-last-viewed'] !== docsPreviouslyLastViewed) {
          state.docsPreviouslyLastViewed[loanGuid] = docsPreviouslyLastViewed;
        }
      })
      .addCase(updateUserLoanAttribute.fulfilled, (state, { payload, meta: { arg } }) => {
        const { loanGuid } = arg;
        state.attributes.data[LOAN_ATTRIBUTES] = {
          ...state.attributes.data[LOAN_ATTRIBUTES],
          [loanGuid]: {
            ...state.attributes.data[LOAN_ATTRIBUTES]?.[loanGuid],
            ...payload[loanGuid],
          },
        };
      });
  },
});

const selectUserState = (state: RootState): UserState => state.user;

export const selectUserEntity = (state: RootState): UserEntity => selectUserState(state).user;

export const selectUser = (state: RootState): User | null => selectUserState(state).user.data;

export const selectUserLanguage = (state: RootState): string | undefined => selectUser(state)?.['language-preference'];

export const useUser = () => useAppSelector(selectUser);

export const selectHasUser = (state: RootState): boolean => selectUser(state) !== null;

export const selectUserAttributeEntity = (state: RootState): LoanAttributesEntity => selectUserState(state).attributes;

export const selectUserAttribute = (state: RootState, attributeKey: UserAttributeKey): UserAttributesMap[UserAttributeKey] =>
  selectUserAttributeEntity(state).data[attributeKey];

export const selectHasUserAttribute = (state: RootState, attributeKey: UserAttributeKey): boolean =>
  !!selectUserAttribute(state, attributeKey);

export const selectUserLoanAttributesByLoan = (state: RootState, loanGuid: string): LoanAttributes =>
  selectUserAttribute(state, LOAN_ATTRIBUTES)?.[loanGuid];

// Immediately updated when docs page is viewed
export const selectDocumentsLastViewed = (state: RootState, loanGuid: string): number | undefined =>
  selectUserLoanAttributesByLoan(state, loanGuid)?.['docs-last-viewed'];

// Always one update behind, when documents page is viewed
export const selectDocumentsPreviouslyLastViewed = (state: RootState, loanGuid: string): number | undefined =>
  selectUserState(state).docsPreviouslyLastViewed[loanGuid];

export const selectUserZip = (state: RootState): string | undefined => selectUser(state)?.address?.['postal-code'];

export default userSlice.reducer;
