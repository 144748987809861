import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import {
  fetchRateAlert,
  selectRateAlert,
  selectRateAlertError,
  selectRateAlertFetching,
  selectRateAlertHasData,
  selectRateAlertState,
} from './rateAlertSlice';

export const useFetchRateAlert = (loanGuid: string) => {
  const dispatch = useAppDispatch();
  const rateAlertFetching = useAppSelector(state => selectRateAlertFetching(state, loanGuid));
  const rateAlertHasData = useAppSelector(state => selectRateAlertHasData(state, loanGuid));
  const rateAlertError = useAppSelector(state => selectRateAlertError(state, loanGuid));
  const rateAlert = useAppSelector(state => selectRateAlert(state, loanGuid));

  useEffect(() => {
    if (!rateAlertFetching && !rateAlertHasData && !rateAlertError) {
      dispatch(fetchRateAlert(loanGuid));
    }
  }, [dispatch, loanGuid, rateAlertFetching, rateAlertHasData, rateAlertError]);

  return {
    rateAlertFetching,
    rateAlertHasData,
    rateAlertError,
    rateAlert,
  };
};
export const useFetchRateAlerts = (fundedLoanGuids: string[]) => {
  const dispatch = useAppDispatch();
  const rateAlertsState = useAppSelector(state => selectRateAlertState(state));

  useEffect(() => {
    // this is not great but should only have very few per user
    fundedLoanGuids.forEach(fundedLoanGuid => {
      const fetching = !!rateAlertsState.byLoanGuid[fundedLoanGuid]?.fetching;
      const hasData = !!rateAlertsState.byLoanGuid[fundedLoanGuid]?.hasData;
      const hasError = !!rateAlertsState.byLoanGuid[fundedLoanGuid]?.error;
      if (!fetching && !hasData && !hasError) {
        dispatch(fetchRateAlert(fundedLoanGuid));
      }
    });
  }, [dispatch, fundedLoanGuids, rateAlertsState]);

  const rateAlerts = Object.keys(rateAlertsState.byLoanGuid).map(loanGuid => {
    return rateAlertsState.byLoanGuid[loanGuid].rateAlert;
  });

  return rateAlerts;
};
