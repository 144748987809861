import Modal from '../../../ui/modal/Modal';
import ModalFooter from '../../../ui/modal/ModalFooter';
import Button from '../../../ui/button/Button';
import celebrateImage from '../../../../images/celebrate.svg';
import { ImagePreloader } from '../../../ui/image/ImagePreloader';

export const WelcomeModalContent = ({ onClose }: { onClose: () => void }) => {
  return (
    <>
      <div className='text-center mb-6'>
        <img className='mx-auto mb-4' src={celebrateImage} alt='' width='70' />
        <h2 className='text-marketing-xs md:text-marketing-md'>Welcome to myAccount!</h2>
      </div>
      <div className='text-md'>
        <p className='bold mb-4'>
          This is your overview page. Here, you will provide all information and documentation needed for your loan.
        </p>
        <div className='px-4 lg:px-12 mb-4'>
          <ul className='list-disc-red space-y-2 lg:space-y-4'>
            <li>We'll keep your to-do list up to date with everything needed for underwriting.</li>
            <li>As you complete tasks, those tasks will auto-move from incomplete to completed.</li>
            <li>Everything you provide us will be automatically sent to us so we can start the review.</li>
            <li>As you provide us with documents they'll still be accessible to you in the completed tasks section.</li>
          </ul>
        </div>
        <p>Please don't hesitate to reach out with questions.</p>
      </div>
      <ModalFooter>
        <Button onClick={onClose} buttonType='primary' size='large'>
          Get started
        </Button>
      </ModalFooter>
    </>
  );
};

export const WelcomeModal = ({ open, onOpen, onRequestClose }: { open: boolean; onOpen?: () => void; onRequestClose: () => void }) => {
  return (
    <>
      <ImagePreloader>
        <img src={celebrateImage} alt='' />
      </ImagePreloader>
      <Modal open={open} onOpen={onOpen} onRequestClose={onRequestClose} contentLabel='Welcome Modal'>
        <WelcomeModalContent onClose={onRequestClose} />
      </Modal>
    </>
  );
};

export default WelcomeModal;
