import type { iTaskTitleBadgeType, TaskTitleBadgeProps } from './ITaskTitleBadge';
import TaskTitle from './TaskTitle';
import type { iTextBadge } from '../../ui/badge/ITextBadge';
import TextBadge from '../../ui/badge/TextBadge';
import ConditionalWrapper from '../../ui/conditionalWrapper/ConditionalWrapper';
import cn from 'classnames';

const getBadge = (badgeType: iTaskTitleBadgeType) => {
  let text = '';
  let type = 'ok-secondary' as iTextBadge;
  if (badgeType === 'priority') {
    text = 'Priority';
    type = 'critical';
  } else if (badgeType === 'new') {
    text = 'New';
  } else if (badgeType === 'accepted') {
    text = 'Accepted';
  } else if (badgeType === 'declined') {
    text = 'Declined';
    type = 'critical-secondary';
  }
  return {
    text,
    type,
  };
};

const TaskTitleError = ({ error }: { error: string }) => {
  return (
    <div className='text-error-important text-form-error font-normal absolute'>
      {error}
    </div>
  );
};

const TaskTitleBadge = (props: TaskTitleBadgeProps) => {
  const { title, additionalContent, badgeType, titleClassName, error } = props;
  const badge = !!badgeType && getBadge(badgeType);
  return (
    <>
      <ConditionalWrapper condition={!!badgeType} el='span' className='mr-3'>
        <>
          <TaskTitle title={title} className={cn(titleClassName, 'OneLinkNoTx')} />
          {additionalContent && <span>{additionalContent}</span>}
          {error && <TaskTitleError error={error}/>}
        </>
      </ConditionalWrapper>
      {badgeType && badge && <TextBadge {...badge} />}
    </>
  );
};

export default TaskTitleBadge;
