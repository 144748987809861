import { useRouteMatch } from 'react-router-dom';
import { selectLoan } from '../../../features/loans/loansSlice';
import { ILoanRouteParams } from '../../../interfaces/IRouteParams';
import Button from '../../ui/button/Button';
import insuranceImageSmall from '../../../images/insurance-homeowners-sm.png';
import insuranceImageMedium from '../../../images/insurance-homeowners-md.png';
import insuranceImageLarge from '../../../images/insurance-homeowners-lg.png';
import insuranceImageSmallEs from '../../../images/insurance-homeowners-sm-es.png';
import insuranceImageMediumEs from '../../../images/insurance-homeowners-md-es.png';
import insuranceImageLargeEs from '../../../images/insurance-homeowners-lg-es.png';
import type { Loan } from '../../../features/loans/types';
import Modal from '../../ui/modal/Modal';
import { InsuranceAdSettings, QuoteModalContentProps } from './IInsuranceQuote';
import Icon from '../../ui/icon/Icon';
import { useState } from 'react';
import { useFeatureEnabled } from '../../../hooks/useFeatureEnabled';
import Tel from '../../ui/tel/Tel';
import { sendEvent } from '../../../features/analytics/sendEvent';
import { homeLoanInProcessPaths } from '../../routes/paths';
import ModalFooter from '../../ui/modal/ModalFooter';
import {
  fetchInsuranceEligibility,
  selectInsuranceEligibility,
  selectInsuranceEligibilityMetadata,
  selectInsuranceQuoteMetadata,
  selectInsuranceQuoteSent,
  sendInsuranceQuoteRequest,
} from '../../../features/insurance/insuranceSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import ModalLoading from '../../ui/modal/ModalLoading';
import ConfirmationModalContent from '../../ui/modal/ConfirmationModalContent';
import ErrorModalContent from '../../ui/modal/ErrorModalContent';
import CastleEdgeQuote from './CastleEdgeQuote';
import { content } from '../../../features/tenant/tenant';
import { unformatPhoneNumber } from '../../../utils/phone';
import { isTasklistLoan } from '../../../features/loans/helpers';
import { selectAppLanguage } from '../../../features/app/appSlice';
import { useMobileAppMode } from '../../embedMode/EmbedMode';
import { getInsuranceCompanyName } from '../../../utils/getInsuranceCompanyName';

const disallowedStates = new Set(['AK', 'DC', 'DE', 'VT', 'WV']);

export const sizes = ['small', 'medium', 'large'] as const;
export type iSize = (typeof sizes)[number];

const insurable = ({ property: { state } }: Loan): boolean => {
  return !!(state && !disallowedStates.has(state));
};

export const Checkmark = ({ title, text }: { title: string; text: string }) => (
  <div className='my-4'>
    <h3 className='flex items-center mb-2'>
      <Icon name='check-tick' className='text-ok mr-2' size='0.9375rem' />
      {title}
    </h3>
    <p>{text}</p>
  </div>
);

export const QuoteModalContent = (props: QuoteModalContentProps) => {
  const { insuranceCompanyName = 'Rate Insurance, LLC', onClose, onClick } = props;
  const companyName = content.legalName || content.company;

  return (
    <>
      <h1 className='header-small mb-6'>
        Here's why we know you'll love working with <span className='lg:whitespace-nowrap'>{insuranceCompanyName}</span>
      </h1>
      <Checkmark
        title='We do the leg work for you!'
        text='We work with multiple insurance providers and shop the marketplace for the best price!'
      />
      <Checkmark
        title='We make it quick and easy!'
        text='We understand the loan process and work with your loan team to ensure a hassle-free experience!'
      />
      <ModalFooter className='my-8'>
        <Button onClick={onClose} buttonType='secondary' size='large' className='lg:mr-3' style={{ minWidth: '200px' }}>
          Cancel
        </Button>
        <Button onClick={onClick} buttonType='primary' size='large' className='mb-4 lg:mb-0 lg:ml-3' style={{ minWidth: '200px' }}>
          Get a free quote
        </Button>
      </ModalFooter>
      <div className='bg-gray-10 mt-0 -m-6 p-8 sm:py-10 lg:-mx-12 lg:-mb-11 lg:px-12'>
        <p className='body-disclaimer mb-4'>
          By clicking "Get a free quote", I authorize and consent to {companyName} sharing the information I have provided to them,
          including "eligibility information," with their affiliate, {insuranceCompanyName} for the purpose of {insuranceCompanyName} using
          such information to market their products and services to me as described under the Fair Credit
          Reporting Act (15 U.S. Code § 1681(s-3), et seq.) . To review a copy of our Affiliated Business Disclosure, click here.
        </p>
        <p className='body-disclaimer mb-4'>
          {companyName} and {insuranceCompanyName} are affiliates. Because of this relationship, this referral may provide{' '}
          {companyName} a financial or other benefit. You are not required to use {insuranceCompanyName} as a condition for
          obtaining a mortgage loan through {companyName}. You are free to shop around to determine that you are receiving the best
          services and the best rate for these services.
        </p>
        <p className='body-disclaimer'>
          {insuranceCompanyName} is licensed in all 50 states (d/b/a Rate Insurance Agency, LLC in California (License 0K09890), Michigan, Minnesota, North Dakota, New York and Texas).
        </p>
      </div>
    </>
  );
};

export const locationTypes = ['task', 'right-rail'] as const;
export type iLocationTypes = (typeof locationTypes)[number];

export const QuoteCTA = ({
  loanGuid,
  source,
  size = 'small',
  className,
  location,
}: {
  loanGuid: string;
  source: string;
  size?: iSize;
  className?: string;
  location: iLocationTypes;
}) => {
  const loan = useAppSelector(state => selectLoan(state, loanGuid));
  const insuranceCompanyName = getInsuranceCompanyName(loan);
  const [disabled, setDisabled] = useState(false);
  const [showModal, setModalState] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const eligibilityCheckEnabled = useFeatureEnabled('insurance-online-marketplace');
  const dispatch = useAppDispatch();
  const {
    isFetching: eligibilityIsFetching,
    hasData: eligibilityHasData,
    hasError: eligibilityCheckFailed,
  } = useAppSelector(state => selectInsuranceEligibilityMetadata(state, loanGuid));
  const { eligible, url } = useAppSelector(state => selectInsuranceEligibility(state, loanGuid));
  const { isFetching: quoteIsFetching, hasError: quoteFailed } = useAppSelector(state =>
    selectInsuranceQuoteMetadata(state, loanGuid),
  );
  const insuranceQuoteSent = useAppSelector(state => selectInsuranceQuoteSent(state, loanGuid));
  const phone = content.insurance.phone;
  const language = useAppSelector(selectAppLanguage) || 'en';
  const mobileApp = useMobileAppMode();
  const campaign = `${mobileApp ? 'rate-app' : 'mya'}-${location}`;

  const handleConfirm = () => {
    setModalState(false);
    sendEvent('insuranceOnlineMarketplaceViewed', { loanGuid });
  };

  const settings: InsuranceAdSettings = {
    small: {
      imagePath: { en: insuranceImageSmall, es: insuranceImageSmallEs },
      width: '312px',
      height: '312px',
      buttonStyles: { height: '50px', bottom: '8px', left: '8px', right: '8px' },
    },
    medium: {
      imagePath: { en: insuranceImageMedium, es: insuranceImageMediumEs },
      width: '620px',
      height: '295px',
      buttonStyles: { top: '48%', left: '43%' },
    },
    large: {
      imagePath: { en: insuranceImageLarge, es: insuranceImageLargeEs },
      width: '912px',
      height: '240px',
      buttonStyles: { top: '41%', left: '60%' },
    },
  };

  const handleGetQuote = () => {
    if (disabled) return;
    setDisabled(true);
    setButtonClicked(true);
    sendEvent('insuranceQuoteRequested', { loanGuid });
    if (eligibilityCheckEnabled) {
      dispatch(fetchInsuranceEligibility({ loanGuid, campaign }));
    } else {
      // Request insurance quote though SNS topic
      dispatch(sendInsuranceQuoteRequest(loanGuid));
    }
  };

  return (
    <div style={{ maxWidth: settings[size].width }} className={className}>
      <div className='relative' style={{ maxWidth: settings[size].width }}>
        <img alt='insurance' width={settings[size].width} height={settings[size].height} src={settings[size].imagePath[language]} />
        <Button
          className='absolute'
          style={settings[size].buttonStyles}
          onClick={() => {
            setModalState(true);
            sendEvent('insuranceModalViewed', { loanGuid, source });
          }}
          buttonType='secondary'
          text='Get quotes now'
        />
      </div>
      <Modal
        contentLabel='Insurance Quote'
        open={showModal}
        onClosed={() => {
          setButtonClicked(false);
          setDisabled(false);
        }}
        onRequestClose={() => setModalState(false)}
      >
        {quoteIsFetching || eligibilityIsFetching ? (
          <ModalLoading title='Hang tight while we send your request.' />
        ) : buttonClicked && (quoteFailed || eligibilityCheckFailed) ? (
          <ErrorModalContent
            onDone={() => setModalState(false)}
            title='There was an issue submitting your information.'
            textNode={
              <>
                We were unable to submit your information to {insuranceCompanyName}. If you give us a call at{' '}
                <Tel label={phone} tel={unformatPhoneNumber(phone)} title={`Call ${phone}`} /> we will be happy to assist you with a
                free quote.
              </>
            }
            location='your tasklist'
          />
        ) : buttonClicked && eligibilityHasData && eligible && url ? (
          <ConfirmationModalContent
            title='Thank you for waiting'
            text='Your insurance quote link is ready.'
            confirmButtonText='View quote'
            onConfirm={handleConfirm}
            confirmHref={url}
            confirmHrefTarget='_blank'
          />
        ) : buttonClicked && (insuranceQuoteSent || (eligibilityHasData && !eligible)) ? (
          <ConfirmationModalContent
            title='Thank you.'
            textNode={
              <>
                One of the skilled agents from {insuranceCompanyName} will be reaching out you shortly to provide you with a
                free quote, or you can reach us at <Tel label={phone} tel={unformatPhoneNumber(phone)} title={`Call ${phone}`} />.
              </>
            }
            cancelButtonText='Back to your task list'
            onCancel={() => setModalState(false)}
          />
        ) : (
          <QuoteModalContent insuranceCompanyName={insuranceCompanyName} onClose={() => setModalState(false)} onClick={handleGetQuote} />
        )}
      </Modal>
    </div>
  );
};

export const GetQuoteRecommendation = ({ loanGuid, location }: { loanGuid: string; location: iLocationTypes }) => {
  const ctaSizes: { size: iSize; classes: string }[] = [
    { size: 'small', classes: 'block sm:hidden lg:block' },
    { size: 'medium', classes: 'hidden sm:block lg:hidden' },
  ];
  return (
    <>
      {ctaSizes.map(({ classes, size }) => (
        <div className={classes} key={size}>
          <QuoteCTA loanGuid={loanGuid} size={size} className='mx-auto lg:mx-0' source='Recommendation' location={location} />
        </div>
      ))}
    </>
  );
};

const WithRouteMatchCheck = ({ location }: { location: iLocationTypes }) => {
  const routeMatch = useRouteMatch<ILoanRouteParams>({
    path: homeLoanInProcessPaths,
    exact: true,
  });
  const guid = routeMatch?.params.guid;
  const loan = useAppSelector(state => guid && selectLoan(state, guid));
  const shouldShow = loan && isTasklistLoan(loan);
  const eligibilityCheckEnabled = useFeatureEnabled('insurance-online-marketplace');
  return shouldShow && (eligibilityCheckEnabled || insurable(loan)) ? (
    <div className='mt-8 text-center'>
      <GetQuoteRecommendation loanGuid={loan['loan-guid']} location={location} />
    </div>
  ) : null;
};

const WithFeatureCheck = ({ location }: { location: iLocationTypes }) => {
  const grInsuranceEnabled = useFeatureEnabled('insurance-quote');
  const castleEdgeInsuranceEnabled = useFeatureEnabled('castle-edge-insurance');
  return castleEdgeInsuranceEnabled ? <CastleEdgeQuote /> : grInsuranceEnabled ? <WithRouteMatchCheck location={location} /> : null;
};

export default WithFeatureCheck;
