import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { ILoanRouteParams } from '../../../interfaces/IRouteParams';
import { LocationState } from '../../../interfaces/ILocationState';
import { log } from '../../../utils/logger';
import { iPersonalLoan } from '../../../features/personalLoans/types';
import { selectPersonalLoanById } from '../../../features/personalLoans/personalLoansSlice';
import useFetchPersonalLoansData from '../../../features/personalLoans/useFetchPersonalLoansData';
import { useEffect } from 'react';
import { updateGlobalLoadingOnce } from '../../../features/app/appSlice';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';

interface withPersonalLoanProps {
  personalLoan: iPersonalLoan;
}

export const withPersonalLoan = <P extends Record<string, unknown>>(Component: React.ComponentType<P & withPersonalLoanProps>) =>
  function New(props: P) {
    const dispatch = useAppDispatch();
    const personalLoanEnabled = useFeatureEnabled('personal-loans');
    const { hasData, hasError } = useFetchPersonalLoansData();
    const location = useLocation<LocationState>();
    const { guid } = useParams<ILoanRouteParams>();
    const personalLoan = useAppSelector(state => selectPersonalLoanById(state, guid));

    useEffect(() => {
      (!personalLoanEnabled || hasData || hasError) && dispatch(updateGlobalLoadingOnce(false));
    }, [dispatch, personalLoanEnabled, hasData, hasError]);

    if (personalLoanEnabled && !hasData && !hasError) return null;

    if (!personalLoan) {
      log({ loanGuid: guid, message: 'No personal loan found. Showing 404 error.' });
      return (
        <Redirect
          to={{
            pathname: location.pathname,
            state: { notFoundError: true },
          }}
        />
      );
    }

    return <Component {...props} personalLoan={personalLoan} />;
  };

export default withPersonalLoan;
