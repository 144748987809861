import { CircularTracker } from '../../ui/tracker/CircularTracker';
import { LoanMilestoneStep } from '../loanStatusInfo/ILoanStatusInfo';
import { LoanConfig } from '../loanCard/ILoanCard';

export const HelocLoanMilestoneHeroContents = ({
  loanConfig,
  milestoneStep,
}: {
  loanConfig: LoanConfig;
  loanMilestoneSteps?: LoanMilestoneStep[];
  milestoneStep: LoanMilestoneStep | undefined;
}) => {
  return (
    <div className='flex gap-8 w-full justify-start'>
      <div className='flex self-start relative top-1'>
        <CircularTracker size='small' value={loanConfig.trackerValue} />
      </div>
      {milestoneStep && (
        <div className='flex flex-col justify-center'>
          <div className='inline-flex items-start'>
            <span className='font-bold mr-2'>Milestone: {milestoneStep.title}</span>
          </div>
          <p className='mt-1'>{milestoneStep.description}</p>
        </div>
      )}
    </div>
  );
};

export const PersonalLoanMilestoneHeroContents = ({
  milestoneStep,
}: {
  milestoneStep: LoanMilestoneStep | undefined;
}) => {
  return (
    <div className='flex w-full justify-start'>
      {milestoneStep &&
        <div className='flex flex-col justify-center'>
          <div className='inline-flex items-start'>
            <span className='font-bold mr-2 text-2xl'>Status: {milestoneStep.title}</span>
          </div>
          <p className='mt-1'>{milestoneStep.description}</p>
        </div>
      }
    </div>
  );
};
