/* istanbul ignore file */
export const iconName = [
  'arrow-down',
  'arrow-right',
  'arrow-up',
  'calculator',
  'calendar',
  'chat-help',
  'chat-text',
  'check-tick-circle',
  'check-tick',
  'cheeseburger',
  'chevron-down-small',
  'chevron-left-large',
  'chevron-left-small',
  'chevron-right-large',
  'chevron-right-small',
  'chevron-up-small',
  'clock-pending',
  'close',
  'date',
  'desktop',
  'doc-check',
  'dollar-coin',
  'edit',
  'ellipsis-horizontal',
  'external-link',
  'eye-hide',
  'eye-show',
  'file',
  'home',
  'information-circle',
  'interface-cancel',
  'interface-download',
  'doc-long',
  'loading',
  'location-pin',
  'mail',
  'mailbox',
  'mobile',
  'money-bag',
  'phone',
  'retry',
  'search',
  'shield-check',
  'social-facebook',
  'sort-ascending',
  'sort-descending',
  'speedometer-fast',
  'support-call',
  'sync',
  'trash',
  'upload',
  'user-circle',
  'wallet',
  'warning-triangle',
  'warning-triangle-filled',
] as const;

export type iIconName = typeof iconName[number];

export interface IconProps {
  name: iIconName;
  'aria-label'?: string;
  className?: string;
  size?: string;
  style?: React.CSSProperties;
  'data-qa'?: string;
  hoverEffect?: boolean;
}
