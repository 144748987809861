/* istanbul ignore file */
import { AnchorHTMLAttributes, ButtonHTMLAttributes, CSSProperties, HTMLAttributes, MutableRefObject, Ref } from 'react';
import { TextBadgeProps } from '../badge/ITextBadge';
import { iIconName } from '../icon/IIcon';

export const buttonTypes = ['primary', 'secondary', 'tertiary', 'quaternary', 'inline', 'plain', 'nav', 'subnav', 'icon'] as const;
export type iButtonType = typeof buttonTypes[number];

export const buttonSizes = ['small', 'medium', 'large'] as const;
export type iButtonSize = typeof buttonSizes[number];

export const iconPlacements = ['left', 'right'] as const;
export type iIconPlacement = typeof iconPlacements[number];

export type TextOrChildren = { text: string; children?: never } | { children: any; text?: never };

export type IconOrIconName =
  | { icon?: JSX.Element; iconName?: never; iconSize?: never; iconClassname?: never; iconStyle?: never; iconPlacement?: iIconPlacement; iconAriaLabel?: string; iconHoverEffect?: boolean; }
  | { iconName?: iIconName; iconSize?: string; iconClassname?: string; iconStyle?: CSSProperties; icon?: never; iconPlacement?: iIconPlacement; iconAriaLabel?: string; iconHoverEffect?: boolean; };

type ButtonOrLink = HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> &
  AnchorHTMLAttributes<HTMLAnchorElement> &
  ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = ButtonOrLink &
  TextOrChildren &
  IconOrIconName & {
    to?: string;
    buttonType?: iButtonType | null;
    size?: iButtonSize;
    loading?: boolean;
    hover?: boolean;
    active?: boolean;
    badge?: TextBadgeProps;
    anchorRef?: MutableRefObject<null>;
    buttonRef?: Ref<HTMLButtonElement>;
    color?: string;
    hasBg?: boolean;
    rotate?: boolean;
  };
