import dayjs, { OpUnitType, QUnitType } from 'dayjs';

const timestampDifference = (
  dateString1: string,
  dateString2: string,
  unit?: QUnitType | OpUnitType,
  float?: boolean,
): number => dayjs(dateString1).diff(dayjs(dateString2), unit, float);

export default timestampDifference;
