import SortableTable, { sortableHeaderCell, sortableTableCell } from '../../../components/sortableTable/SortableTable';
import TextBadge from '../../../components/ui/badge/TextBadge';
import Card from '../../../components/ui/card/Card';
import Icon from '../../../components/ui/icon/Icon';
import Tooltip from '../../../components/ui/tooltip/Tooltip';

const SortableTableStyleguide = () => {

  const MockTooltip = ({ data }: { data: string }) => {
    return (
      <div className='flex items-center gap-2'>
        <p>{data}</p>
        <Tooltip
          tip='Expire in X days'
          place='bottom'
        >
          <Icon name='warning-triangle' size='1rem' />
        </Tooltip>
      </div>
    );
  };

  const MockTextBadge = ({ data }: { data: string }) => {
    return (
      <div className='flex items-center gap-2'>
        <p>{data}</p>
        <TextBadge type='ok' text='New' />
      </div>
    );
  };

  const mockHeaders = [
    sortableHeaderCell('Header 1', true),
    sortableHeaderCell('Header 2', true),
    sortableHeaderCell('Header 3', true),
    sortableHeaderCell('Header 4'),
    sortableHeaderCell(undefined),
  ];

  const mockDataRows = [
    {
      dataModel: undefined,
      cells: [
        sortableTableCell({ data: 'Test 11' }),
        sortableTableCell({ data: 'Test 12' }),
        sortableTableCell({ data: 'Test 13', widget: <MockTooltip data='Test 13' /> }),
        sortableTableCell({ data: 'Test 14' }),
        sortableTableCell({ widget: <Icon name='interface-download' size='1rem' />}),
      ]
    },
    {
      dataModel: undefined,
      cells: [
        sortableTableCell({ data: 'Test 21' }),
        sortableTableCell({ data: 'Test 2' }),
        sortableTableCell({ data: 'Super Long Long Long Test 23' }),
        sortableTableCell({ data: 'Test 24' }),
        sortableTableCell({ widget: <Icon name='interface-download' size='1rem' />}),
      ]
    },
    {
      dataModel: undefined,
      cells: [
        sortableTableCell({ data: 'Test 31', widget: <MockTextBadge data='Long Long Long Long Long Test 31' />, tooltipText: 'Not as long' }),
        sortableTableCell({ data: 'Test 32', widget: <MockTooltip data='Long Long Long Long Long Test 32' />}),
        sortableTableCell({ data: 'Test 33' }),
        sortableTableCell({ data: 'Test 34' }),
        sortableTableCell({ widget: <Icon name='interface-download' size='1rem' />}),
      ]
    },
    {
      dataModel: undefined,
      cells: [
        sortableTableCell({ data: 'Test 41', tooltipText: 'Tooltip test 41', showTooltipTextOnHover: true }),
        sortableTableCell({ data: 'Test 42', tooltipText: 'Tooltip test 42', showTooltipTextOnHover: true }),
        sortableTableCell({ data: 'Test 43', tooltipText: 'Tooltip test 43', showTooltipTextOnHover: true }),
        sortableTableCell({ data: 'Test 44', tooltipText: 'Tooltip test 44', showTooltipTextOnHover: true }),
        sortableTableCell({ tooltipText: 'download', widget: <Icon name='interface-download' size='1rem' />, showTooltipTextOnHover: true }),
      ]
    },
    {
      dataModel: undefined,
      cells: [
        sortableTableCell({ data: 'Test 51' }),
        sortableTableCell({ data: 'Test 52' }),
        sortableTableCell({ data: 'Test 53' }),
        sortableTableCell({ data: 'Test 54' }),
        sortableTableCell({ widget: <Icon name='interface-download' size='1rem' />}),
      ]
    },
    {
      dataModel: undefined,
      cells: [
        sortableTableCell({ data: 'Test 61' }),
        sortableTableCell({ data: 'Test 62' }),
        sortableTableCell({ data: 'Test 63' }),
        sortableTableCell({ data: 'Test 64' }),
        sortableTableCell({ widget: <Icon name='interface-download' size='1rem' />}),
      ]
    },
  ];

  const mockStyles = [
    'h-14',
    'h-14',
    'h-14',
    'h-14',
    'w-6 h-14',
  ];

  return (
    <>
      <div className='mb-10'>
        <SortableTable
          headers={mockHeaders}
          dataRows={mockDataRows}
          columnClassNames={mockStyles}
          maxRow={4}
        />
      </div>

      <div className='w-1/2 mb-10'>
        <SortableTable
          headers={mockHeaders}
          dataRows={mockDataRows}
          columnClassNames={mockStyles}
        />
      </div>

      <div className='mb-10'>
        <SortableTable
          headers={mockHeaders}
          dataRows={[]}
          columnClassNames={mockStyles}
          loading={true}
        />
      </div>

      <div>
        <Card>
          <header className='font-bold'>Test Table</header>
          <SortableTable
            headers={mockHeaders}
            dataRows={mockDataRows}
            columnClassNames={mockStyles}
            maxRow={4}
          />
        </Card>
      </div>
    </>
  );
};

export default SortableTableStyleguide;
