import { useLayoutEffect } from 'react';

const originalOverflow = document.body.style.overflow;
const originalWidth = document.body.style.width;

export const lockBody = (scrollbarWidth: number) => {
  document.body.style.overflow = 'hidden';
  document.body.style.width = `calc(100% - ${scrollbarWidth}px)`;
};

export const unlockBody = () => {
  document.body.style.overflow = originalOverflow;
  document.body.style.width = originalWidth;
};

const bodyLockInstances = { count: 0 };

/**
 * Component for adding styles to lock the body.
 * Keeps track of instances, so the last instance removed
 * from the DOM resets styles.
 *
 * @param msDelay Optionally delay the reset, during animations for example
 */
const BodyLock = ({ msDelay = 0 }: { msDelay?: number }) => {
  useLayoutEffect(() => {
    const scrollbarWidth = window.innerWidth - document.body.offsetWidth;

    bodyLockInstances.count++;
    if (bodyLockInstances.count === 1) {
      lockBody(scrollbarWidth);
    }

    return () => {
      bodyLockInstances.count--;
      if (!bodyLockInstances.count) {
        msDelay ? setTimeout(unlockBody, msDelay) : unlockBody();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default BodyLock;
