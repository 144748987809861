import './HomeSearch.scss';
import cn from 'classnames';
import { SavedHome, useSavedHomes } from '../../features/homeSearch/homeSearchSlice';
import { usdAmount } from '../../utils/amount';

const HomeTile = ({ home }: { home?: SavedHome }) => {
  // placeholder for when there are less than 3 saved homes
  if (!home) {
    return (
      <div className='saved-home-tile invisible' />
    );
  }

  const { propertyInfo: info, listingPrice, propertyAddress: address, propertyUrl } = home;
  const propertyDetailsString = `${info.numberOfBedrooms} Beds | ${info.fullBathCount} Baths | ${info.buildingAreaSqFt} Sq. Ft.`;
  const homeImage = home.propertyImages?.length > 0 && home.propertyImages[0];

  const onHomeClick = () => {
    window.open(propertyUrl, '_blank', 'noopener');
  };

  return (
    <div className='saved-home-tile' role='button' onClick={onHomeClick}>
      <div className='flex flex-col flex-1'>
        <div className='saved-home-image' style={{ backgroundImage: `url(${homeImage})` }}></div>
        <div className='flex flex-col' style={{ padding: '15px 15px 0 15px' }}>
          <p className='font-bold pb-px leading-tight' style={{ fontSize: '16px' }}>{usdAmount(listingPrice, false)}</p>
          <p className='pb-2' style={{ fontSize: '12px' }}>{address.streetAddress}</p>
          <p style={{ fontSize: '10px' }}>{propertyDetailsString}</p>
        </div>
      </div>
    </div>
  );
};

export const SavedHomesComponentPresentational = ({ homes }: { homes: SavedHome[] }) => {
  const MAX_TILES = 3;

  const displayHomes: (SavedHome | undefined)[] = homes.slice(0, MAX_TILES);

  if (displayHomes.length < MAX_TILES) {
    const dummyTilesCount = MAX_TILES - displayHomes.length;
    for (let i = 0; i < dummyTilesCount; i++) {
      displayHomes.push(undefined);
    }
  }

  return (
    <div className='flex justify-center'>
      <div className='flex flex-col w-fit-content'>
        <h1 className='my-4 saved-homes-title'>Saved homes</h1>
        <div className='flex flex-row'>
          {displayHomes.map((home, index) => {
            return <HomeTile home={home} key={index} />
          })}
        </div>
      </div>
    </div>
  );
};

const SavedHomesComponent = ({ className }: { className?: string }) => {
  const { savedHomes, fetching, error, hasData } = useSavedHomes();

  if (fetching || error || (hasData && savedHomes.length === 0)) {
    return null;
  }

  return (
    <div className={cn(className)}>
      <SavedHomesComponentPresentational homes={savedHomes} />
    </div>
  );
};

export default SavedHomesComponent;
