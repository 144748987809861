/**
 * Format string or number to USD currency with or without decimals
 */
export const usdAmount = (
  amount: string | number | undefined,
  withTrailingZeros = true,
  round = false
) => {
  if (amount === null || amount === undefined) {
    return amount;
  }

  let amt: number = typeof amount === 'string' ? +amount.replace(/[$,]/g, '') : amount;

  // Math.round rounds to the next integer in the direction of +Infinity
  // therefore, for symmetrical rounding, if rounding a negative decimal number
  if (round && amt < 0 && amt % 1 !== 0) {
    if (Math.abs(amt % 1) < 0.5) {
      // e.g. -0.49 rounds to -0
      amt = Math.ceil(amt);
    } else {
      // e.g. -0.5 rounds to -1
      amt = Math.floor(amt);
    }
    // otherwise, if rounding a positive decimal number
  } else if (round && amt > 0 && amt % 1 !== 0) {
    amt = Math.round(amt);
  }

  // adjust for -0 to mitigate '-$0' and '-$0.00'
  if (amt === -0) {
    amt = 0;
  }

  const formatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amt);

  return withTrailingZeros ? formatted : formatted.replace('.00', '');
};
