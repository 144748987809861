import { sendTaskUpdateEvent } from '../../../features/analytics/sendTaskUpdateEvent';
import api from '../../../features/api';
import { fetchDocuments } from '../../../features/documents/documentsSlice';
import { fetchTask } from '../../../features/tasks/actions';
import { Task } from '../../../features/tasks/types';
import { useAppDispatch } from '../../../hooks/hooks';
import { log } from '../../../utils/logger';
import FileUpload from '../../ui/upload/FileUpload';

const TaskUpload = ({
  loanGuid,
  task,
  visible,
  setAccordionActive,
}: {
  loanGuid: string,
  task: Task<any>,
  visible: boolean,
  setAccordionActive?: (active: boolean) => void
}) => {
  const { 'task-id': taskId, source: taskSource, 'task-type': taskType } = task;
  const dispatch = useAppDispatch();

  const uploadFile = (file: File) => {
    return api.postDocument(loanGuid, file, taskId, taskSource);
  };

  const onSubmitFiles = (files: File[]) => {
    sendTaskUpdateEvent({ taskId, taskType });
    log({ loanGuid, taskId, message: `Task Upload: Submitted files ${files.length}` });
  };

  const doneUpload = (hasError: boolean) => {
    // when done upload all files, with or without error, refetch the task and documents
    log({ loanGuid, taskId, message: `Task Upload: uploaded files has error ${hasError}, refetching task` });
    dispatch(fetchTask(loanGuid, taskId, taskSource));
    log({ loanGuid, taskId, message: `Task Upload: refetching documents after document upload` });
    dispatch(fetchDocuments(loanGuid));
  };

  return (
    <FileUpload
      loanGuid={loanGuid}
      taskId={taskId}
      className='mt-8'
      showTitle={false}
      dropzoneSize='small'
      processFile={uploadFile}
      uploadedDocuments={task.documents}
      visible={visible}
      setAccordionActive={setAccordionActive}
      submitFilesCallback={onSubmitFiles}
      doneProcessCallback={doneUpload}
    />
  );
};

export default TaskUpload;
