import { selectCobrowseInSession } from '../features/app/appSlice';
import { content } from '../features/tenant/tenant';
import { useAppSelector } from './hooks';
import useFeatureEnabled from './useFeatureEnabled';

export const useCobrowseEnabled = () => {
  const enabled = useFeatureEnabled('cobrowse');
  return enabled && !!content.cobrowse?.license;
};

const useCobrowse = () => {
  const enabled = useCobrowseEnabled();
  const inSession = useAppSelector(selectCobrowseInSession);

  return {
    enabled,
    inSession,
  };
};

export default useCobrowse;
