import IMask from 'imask';
import { IMaskInput } from 'react-imask';
import cn from 'classnames';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Button from '../button/Button';
import dayjs from 'dayjs';
import { isSafariBrowser } from '../../../utils/browser';

export interface FormDateInputProps {
  className?: string;
  hasError?: boolean;
  datePicker?: boolean;
  [x: string]: any;
}

const FormDateInput = (props: FormDateInputProps) => {
  const { hasError, className, id, onBlur, onChange, onFocus, disabled, value, datePicker = true, ...rest } = props;

  const [dateValue, setDateValue] = useState<dayjs.Dayjs>();
  const [maskDateValue, setMaskDateValue] = useState<dayjs.Dayjs>();
  const [inputDateValue, setInputDateValue] = useState<dayjs.Dayjs>();

  const maskInputRef = useRef(null);
  const dateInputElementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let dateValueString = '';
    if (dateValue) {
      dateValueString = dateValue.format('MM/DD/YYYY');
    }
    onChange?.(dateValueString);
  }, [dateValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if mask date value updated - update the input field value directly to avoid change event
    if (maskDateValue) {
      // format for date input
      const dateInputString = maskDateValue.format('YYYY-MM-DD');
      if (dateInputElementRef.current) {
        dateInputElementRef.current.value = dateInputString;
      }
    }
    setDateValue(maskDateValue);
  }, [maskDateValue]);

  useEffect(() => {
    // if input date value updated - update the mask value directly to avoid change event
    if (inputDateValue) {
      // format for mask input
      const maskInputString = inputDateValue.format('MM/DD/YYYY');
      if (maskInputRef.current) {
        (maskInputRef.current as any).maskValue = maskInputString;
      }
    } else {
      (maskInputRef.current as any).maskValue = '';
    }
    setDateValue(inputDateValue);
  }, [inputDateValue]);

  const onDateInputValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const dateInputValue = event.target.value;
    const dateValue = dayjs(dateInputValue);
    if (dateValue.isValid()) {
      setInputDateValue(dateValue);
    } else {
      setInputDateValue(undefined);
    }
  };

  const onCalendarButtonClick = () => {
    dateInputElementRef.current?.showPicker();
  };

  const onMaskInputFocus = () => {
    if (maskInputRef.current) {
      (maskInputRef.current as any).element.placeholder = 'MM/DD/YYYY';
    }
    onFocus?.();
  };

  const onMaskInputBlur = () => {
    if (maskInputRef.current) {
      (maskInputRef.current as any).element.placeholder = '';
    }
    onBlur?.();
  };

  const onMaskInputAccept = (value: string, _mask: IMask.AnyMasked) => {
    const dateValue = dayjs(value);
    if (dateValue.isValid()) {
      setMaskDateValue(dateValue);
    } else {
      setMaskDateValue(undefined);
    }
  };
  
  return (
    <>
      <IMaskInput
        ref={maskInputRef}
        id={id}
        mask={'MM/DD/YYYY'}
        blocks={{
          YYYY: {
            mask: '0000'
          },
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12
          },
          DD: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31
          },
        }}
        unmask={false}
        className={cn('form-input', className)}
        onAccept={onMaskInputAccept}
        onFocus={onMaskInputFocus}
        onBlur={onMaskInputBlur}
        disabled={disabled}
        {...rest}
        type='text'
      />
      {datePicker && !isSafariBrowser &&
        <>
          <Button
            buttonType='icon'
            iconName='calendar'
            text='calendar'
            onClick={onCalendarButtonClick}
            className='absolute right-4 bottom-4'
            disabled={disabled}
          />
          <input
            type='date'
            className='absolute left-0 bottom-0 w-0 h-0'
            ref={dateInputElementRef}
            onChange={onDateInputValueChange}
            tabIndex={-1}
          />
        </>
      }
    </>
  );
};

export default FormDateInput;
