import { content } from '../../../../features/tenant/tenant';
import type { Loan } from '../../../../features/loans/types';
import { AlpLoanType, LoanMilestones } from '../../../../features/loans/types';
import Modal from '../../../ui/modal/Modal';
import { startConfetti } from '../../../../utils/confetti';
import { useAppSelector } from '../../../../hooks/hooks';
import { selectUser } from '../../../../features/user/userSlice';
import SocialShareComponent from '../../../social/SocialShareComponent';
import { SocialShareConfigs } from '../../../social/ISocialShareConfig';
import Button from '../../../ui/button/Button';
import { useSocialShare } from '../../../../hooks/useSocialShare';
import { formatDateWithoutTimezone, FORMAT_SHORT } from '../../../../utils/date';

const formatDate = (value?: string) => {
  return value ? formatDateWithoutTimezone(value, FORMAT_SHORT) : undefined;
};

const ClearToCloseModalContent = ({ loan, requestClose }: { loan: Loan; requestClose: () => void } ) => {
  const { 'respa-to-ctc': timeToApproval } = loan;
  const closeDate = formatDate(loan['closing-date'] || loan['scheduled-closing-date'] || loan['estimated-closing-date']);

  return (
    <div className='flex flex-col text-center'>
      {timeToApproval
        ? <p className='text-marketing-xs md:text-marketing-md'>Your loan was final approved in {timeToApproval}! 🎉</p>
        : <p className='text-marketing-xs md:text-marketing-md'>Your loan was final approved! 🎉</p>
      }
      <p className='my-6'>This is a big deal - the industry standard is 30 days. Thanks for the opportunity - we aim to impress and make the process fast and easy.</p>
      <p className='mb-8'><strong>What's next?</strong> Complete any tasks that remain and get ready to close{ closeDate ? ` on ${closeDate}` : '' }.</p>
      <Button
        buttonType='primary'
        text='Whoohoo!'
        className='self-center w-full lg:w-fit-content'
        size='large'
        onClick={requestClose}
      />
    </div>
  );
};

const ClosedModalContent = ({ loan }: { loan: Loan }) => {
  const { 'refinance?': isRefinance } = loan;
  const currentUser = useAppSelector(selectUser);
  const userFirstName = currentUser?.name?.first;
  const socialShareEnabled = useSocialShare(SocialShareConfigs.CLOSED_MILESTONE_MODAL);

  let celebrationText = 'You just bought a home! 🥳🎊';
  if (loan['alp-loan-type'] === AlpLoanType.HELOC) {
    celebrationText = 'Congratulations on your HELOC! 🥳🎊';
  } else if (isRefinance) {
    celebrationText = 'You just refinanced a house 🥳🎊';
  }

  return (
    <div className='flex flex-col text-center'>
      <p className='text-marketing-xs md:text-marketing-md'>{celebrationText}</p>
      {socialShareEnabled
        ?
        <>
          <p className='mt-6'>Congrats{userFirstName ? `, ${userFirstName}` : ''}! This is a big deal. Share the exciting news on social media:</p>
          <div className='my-8'>
            <SocialShareComponent configKey={SocialShareConfigs.CLOSED_MILESTONE_MODAL} fullButton={true} showTitle={false} />
          </div>
        </>
        :
        <p className='my-6'>Congrats{userFirstName ? `, ${userFirstName}` : ''}! This is a big deal!</p>
      }
      <p><strong>What's next?</strong> We are setting up information for your first payment.</p>
      <p className='mt-6'>Check your account periodically to access helpful servicing information, track your home's value over time and explore a multitude of insurance options.</p>
    </div>
  );
};

export const MODAL_MILESTONES = [LoanMilestones.CLEAR_TO_CLOSE, LoanMilestones.CLOSED];

export const MilestoneModalContent = ({ loan, requestClose }: { loan: Loan; requestClose: () => void }) => {
  const { 'loan-milestone': loanMilestone } = loan;
  switch (loanMilestone) {
    case LoanMilestones.CLEAR_TO_CLOSE:
      return <ClearToCloseModalContent loan={loan} requestClose={requestClose} />;
    case LoanMilestones.CLOSED:
      return <ClosedModalContent loan={loan} />;
  };
  return null;
};

const MilestoneModal = ({
  loan,
  open,
  onOpen,
  onRequestClose,
}: {
  loan: Loan;
  open: boolean;
  onOpen?: () => void;
  onRequestClose: () => void;
}) => {
  const { 'loan-milestone': loanMilestone } = loan;

  const handleOpen = () => {
    onOpen?.();
    if (loanMilestone === LoanMilestones.CLEAR_TO_CLOSE && !content.milestoneModals?.disableConfetti) {
      startConfetti(2000);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onOpen={handleOpen}
        onRequestClose={onRequestClose}
        contentLabel={`${loanMilestone} modal`}
      >
        <MilestoneModalContent loan={loan} requestClose={onRequestClose} />
      </Modal>
    </>
  );
};

export default MilestoneModal;
