import cn from 'classnames';
import { ReactNode } from 'react';
import { TextBadgeProps } from '../ui/badge/ITextBadge';
import Dropdown from '../ui/dropdown/Dropdown';
import Link from '../ui/link/Link';
import './SubpageNavigation.scss';

export type NavigationTabProps = {
  text: string;
  value: string;
  className?: string;
  textClassName?: string;
  to?: string;
  href?: string;
  target?: string;
  primaryBadge?: TextBadgeProps | ReactNode;
  secondaryBadge?: TextBadgeProps | ReactNode;
  isActive?: boolean;
  disabled?: boolean;
  activeStyle?: 'underline' | 'highlight'
};

export const NavigationTab = ({
  text: label,
  to,
  href,
  target,
  secondaryBadge,
  isActive,
  disabled,
  className,
  textClassName,
  activeStyle = 'highlight',
}: NavigationTabProps) => (
  <Link
    to={to}
    href={href}
    target={target}
    className={cn(className, 'no-underline border-b-4 border-white rounded-xl py-3', {
      bold: isActive,
      'bg-tab-active': isActive && activeStyle === 'highlight',
      'hover:bg-tab-hover': !disabled && activeStyle === 'highlight',
      'text-disabledText': disabled,
      'pointer-events-none': disabled,
    })}
    disabled={disabled}
    aria-label={label}
    role='menuitem'
    {...(isActive && { 'aria-current': 'page' })}
  >
    <div className={cn(textClassName, 'flex items-center font-bold', {
      'subnav-item': !disabled && activeStyle === 'underline',
    })}>
      {label}
      {secondaryBadge}
    </div>
  </Link>
);

export const SubpageNavigation = ({ tabs }: { tabs: NavigationTabProps[] }) => {
  const activeTab = tabs.find((tab: NavigationTabProps) => tab.isActive);
  const items = tabs.map(({ primaryBadge, secondaryBadge, isActive, ...tab }: NavigationTabProps) => ({
    ...tab,
    badge: primaryBadge,
  }));

  return (
    <>
      <div className='flex-row w-full hidden lg:flex items-center' aria-label='Subpage navigation' role='menu'>
        {tabs?.map((tab: NavigationTabProps, index: number) => {
          return <NavigationTab textClassName='px-6 text-base' {...tab} activeStyle='highlight' key={index} />;
        })}
      </div>
      <div className='w-full lg:hidden'>
        <Dropdown
          id='subpage-nav'
          text={activeTab?.text || 'Menu'}
          textBadge={activeTab?.primaryBadge}
          items={items}
          mobile={true}
          height='3.5rem'
          dropdownBorderColor='tertiaryBtn-text'
        />
      </div>
    </>
  );
};
