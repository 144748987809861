import AnimateHeight from 'react-animate-height';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { AccordionProps } from './IAccordion';
import './Accordion.scss';
import Icon from '../icon/Icon';
import nextId from '../../../utils/nextId';
import { scrollIntoViewWithOffset } from '../../../utils/scrollIntoViewWithOffset';

const Accordion = (props: AccordionProps) => {
  const { title, children, active, scrollOnActive = false } = props;
  const [isActive, setIsActive] = useState(active);
  const [id] = useState(nextId('accordion'));

  useEffect(() => {
    if (active !== isActive) {
      handleToggle();
    }
  }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggle = () => {
    const active = !isActive;
    setIsActive(active);
    props.onToggle?.(active);
  };

  const onAccordionShow = () => {
    if (isActive && scrollOnActive) {
      const element = document.getElementById(id);
      scrollIntoViewWithOffset(element, 24);
    }
  };

  const height = isActive ? 'auto' : 0;

  return (
    <div className={cn('accordion')} id={id}>
      <div className={`accordion__item`}>
        {/* accordion header */}
        <div role='heading' aria-level={3}>
          <button
            className='accordion__title w-full no-underline'
            onClick={handleToggle}
            aria-expanded={isActive}
            aria-controls={id}
            id={`${id}__button`}
          >
            <span className='accordion__title__text'>{title}</span>
            <div>
              <span className='accordion__icon-container'>
                <span
                  className={cn('accordion__icon', {
                    'accordion__icon--active': isActive,
                  })}
                >
                  <Icon name='chevron-down-small' size='0.875rem' hoverEffect={true} />
                </span>
              </span>
            </div>
          </button>
        </div>

        {/* accordion panel */}
        <div className='accordion__content' role='region' aria-labelledby={`${id}__button`} aria-hidden={!isActive}>
          <AnimateHeight height={height} duration={250} onAnimationEnd={onAccordionShow}>
            {children}
          </AnimateHeight>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
