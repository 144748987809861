import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Icon from '../icon/Icon';
import { themeId } from '../../../features/tenant/tenant';
import THEMES from '../../../themes/themes';

const themes = THEMES.THEMES as Record<iThemes, unknown>;
const theme = themes[themeId] as TenantTheme;

export const sizes = ['small', 'large'] as const;
export type iSize = typeof sizes[number];

interface Props {
  size?: iSize;
  value: number;
}

const getConfig = (size: iSize) => {
  const defaultConfig = { size: '48px', iconSize: '0.75rem', strokeWidth: 12, remainingStrokeWidth: 8 };
  if (size === 'large') {
    return { ...defaultConfig, size: '90px', iconSize: '1rem', remainingStrokeWidth: 6 };
  }
  return defaultConfig;
};

export const CircularTracker = ({ size = 'small', value }: Props) => {
  const config = getConfig(size);
  return (
    <div style={{ width: config.size, height: config.size }}>
      <CircularProgressbarWithChildren
        styles={{
          root: {},
          path: {
            stroke: theme.colors.circularTrackerStroke,
            strokeWidth: config.strokeWidth,
          },
          trail: {
            stroke: '#d6d6d6',
            strokeWidth: config.remainingStrokeWidth,
          },
          background: {
            fill: '#edf6f5',
          },
        }}
        background={value === 100}
        strokeWidth={config.strokeWidth}
        value={value}
      >
        {value === 100 && <Icon name='check-tick' className='font-bold text-ok-125' size={config.iconSize} />}
      </CircularProgressbarWithChildren>
    </div>
  );
};
