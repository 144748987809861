import { useEffect, useRef, useState } from 'react';
import { clearNavigationBlockers } from '../../features/app/appSlice';
import { unauthenticate, useIsAuth } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../hooks/hooks';
import { log } from '../../utils/logger';
import Stopwatch from '../stopwatch/stopwatch';
import ConfirmationModalContent from '../ui/modal/ConfirmationModalContent';
import Modal from '../ui/modal/Modal';
import useCountdown from '../../hooks/useCountdown';
import { throttle } from '../../utils/throttle';
import useEmbed from '../../hooks/useEmbed';
import { buildId, get, set } from '../../utils/localStore';

const logoutAfterMsOverride = process.env.REACT_APP_INACTIVITY_LOGOUT_AFTER ? parseInt(process.env.REACT_APP_INACTIVITY_LOGOUT_AFTER) : undefined;
const logoutAfterMs = logoutAfterMsOverride || (10 * 60 * 1000); // 10 minutes
const modalTimeoutCounterMsOverride = process.env.REACT_APP_INACTIVITY_MODAL_COUNTER ? parseInt(process.env.REACT_APP_INACTIVITY_MODAL_COUNTER) : undefined;
const modalTimeoutCounterMs = modalTimeoutCounterMsOverride || (1 * 60 * 1000); // 1 minute
const modalTimeout = logoutAfterMs - modalTimeoutCounterMs;

export const InactivityTimeoutModalContent = (
  {
    counter,
    onStay,
    onLogout,
    onTimeout,
    expired,
  }:
  {
    counter: number;
    onStay: () => void;
    onLogout: () => void;
    onTimeout: () => void;
    expired: boolean;
  }
) => {
  const count = useCountdown(counter, onTimeout);

  return (
    <ConfirmationModalContent
      title={expired ? 'Your session expired' : 'Anyone home?'}
      textNode={!expired ?
        (
          <div className='flex flex-col lg:flex-row'>
            <div className='flex flex-row'>
              <p className='whitespace-nowrap'>Your session will expire in&nbsp;</p>
              <span className='font-bold whitespace-nowrap'>
                <span className='inline-block OneLinkNoTx' style={{ width: `${count.toString().length}ch` }}>
                  {count}
                </span>{' '}
                seconds.
              </span>
            </div>
            <p className='whitespace-nowrap'>&nbsp;Do you want to stay logged in?</p>
          </div>
        ) :
        (
          <p>Please wait a moment while you are redirected to the login page.</p>
        )
      }
      imageComponent={<Stopwatch startMinute={expired ? -1 : 59 - counter} paused={expired ? true : count === 0} />}
      confirmButtonText='No, log out'
      onConfirm={expired ? undefined : onLogout}
      cancelButtonText={`Yes, I'm here`}
      onCancel={expired ? undefined : onStay}
    />
  );
};

const InactivityTimeout = () => {
  const dispatch = useAppDispatch();
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const showingInactivityModalRef = useRef(false);
  const activityCheckTimeoutRef = useRef(0);
  const [expired, setExpired] = useState(false);
  const PREFIX = 'mya';
  const ACTIVITY_KEY = 'lastActivity';

  const resetMovementTime = throttle(() => {
    set(PREFIX, ACTIVITY_KEY, (new Date()).getTime());
  }, 100);

  const logout = () => {
    dispatch(clearNavigationBlockers());
    dispatch(unauthenticate());
  };

  const stopInactivityCheck = () => {
    window.removeEventListener('mousemove', resetMovementTime);
    window.removeEventListener('touchstart', resetMovementTime);
    window.removeEventListener('keydown', resetMovementTime);
    window.clearInterval(activityCheckTimeoutRef.current);
  };

  const checkTimeout = () => {
    const now = (new Date()).getTime();
    const lastActivityTime = get(PREFIX, ACTIVITY_KEY, now);
    const idleTime = lastActivityTime ? now - lastActivityTime : 0;

    if (!showingInactivityModalRef.current && idleTime >= modalTimeout && idleTime < logoutAfterMs) {
      setExpired(false);
      stopInactivityCheck();
      log({ level: 'info', message: 'Inactivity: Inactivity Modal is presented to user.' });
      setShowInactivityModal(true);
    } else if (idleTime > logoutAfterMs) {
      setExpired(true);
      stopInactivityCheck();
      log({ level: 'info', message: 'Inactivity: User has been logged out due to being inactive.' });
      logout();
    }
  };

  const startInactivityCheck = () => {
    window.addEventListener('mousemove', resetMovementTime);
    window.addEventListener('touchstart', resetMovementTime);
    window.addEventListener('keydown', resetMovementTime);

    // check movement idle every second
    activityCheckTimeoutRef.current = window.setInterval(() => {
      checkTimeout();
    }, 1000);
  };

  useEffect(() => {
    showingInactivityModalRef.current = showInactivityModal;
  }, [showInactivityModal]);

  const handleStorageChange = (event: StorageEvent) => {
    // If the inactivity modal is showing in this window, and the 'lastActivity' key is updated in another window, dismiss the modal
    if (!document.hasFocus() && event.key === buildId(PREFIX, ACTIVITY_KEY) && showingInactivityModalRef.current) {
      log({ level: 'info', message: 'Inactivity: Inactivity modal was dismissed in another window.' });
      setShowInactivityModal(false);
      resetMovementTime();
      startInactivityCheck();
    }
  };

  useEffect(() => {
    // Set initial value for lastActivity
    set(PREFIX, ACTIVITY_KEY, (new Date()).getTime());

    const checkTimeoutOnVisibilityChange = () => {
      // check timeout when window is visible
      if (!document.hidden) {
        checkTimeout();
      }
    };

    // check inactivity timeout immeidately if page visibility change
    window.addEventListener('visibilitychange', checkTimeoutOnVisibilityChange);

    // When localStorage has been modified with a new 'lastActivity' timestamp, check if the modal should be dismissed
    window.addEventListener('storage', handleStorageChange);

    // start mouse move check
    startInactivityCheck();

    return () => {
      stopInactivityCheck();
      window.removeEventListener('visibilitychange', checkTimeoutOnVisibilityChange);
      set(PREFIX, ACTIVITY_KEY, undefined);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onStayLogin = () => {
    setShowInactivityModal(false);
    resetMovementTime();
    startInactivityCheck();
    log({ level: 'info', message: 'Inactivity: User has dismissed the Inactivity Modal.' });
  };

  const onLogout = () => {
    log({ level: 'info', message: 'Inactivity: User has clicked log out on the Inactivity Modal.' });
    setExpired(true);
    logout();
  };

  const onTimeout = () => {
    log({ level: 'info', message: 'Inactivity: User has logged out due to not responding to Inactivity Modal' });
    setExpired(true);
    logout();
  };

  return (
    // regular modals has z-index of 2. This one would beat them.
    <Modal overlayStyle={{ zIndex: 3 }} open={showInactivityModal} contentLabel='Inactivity Timeout' closeable={false}>
      <InactivityTimeoutModalContent
        counter={modalTimeoutCounterMs / 1000}
        onStay={onStayLogin}
        onLogout={onLogout}
        onTimeout={onTimeout}
        expired={expired}
      />
    </Modal>
  );
};

export const withChecks = <P extends Record<string, unknown>>(Component: React.ComponentType<P>) =>
  function New(props: P) {
    const embed = useEmbed();
    const isAuthenticated = useIsAuth();

    if (embed || !isAuthenticated || process.env.REACT_APP_DISABLE_INACTIVITY_TIMEOUT) return null;

    return <Component {...props} />;
  };

export default withChecks(InactivityTimeout);
