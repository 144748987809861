import cn from 'classnames';
import Accordion from '../../../components/ui/accordion/Accordion';
import FAQs, { IFAQ } from '../../../components/ui/faqs/FAQs';

const AccordionStyleguide = () => {
  // taken from home val faqs for example, may not be up to date
  const homeValueFAQs = (): IFAQ[] => [
    {
      question: 'What is a home value estimate?',
      answer: <p>A calculation that determines a general selling price of your property in the current market.</p>,
    },
    {
      question: 'How is my home value calculated?',
      answer: <p>The estimate uses data collected from third parties and public records.</p>,
    },
    {
      question: 'What does high and low mean?',
      answer: (
        <p>
          An estimate's accuracy varies based on the availability of property information. A low- and high-end range may provide a
          better glimpse of the potential value.
        </p>
      ),
    },
    {
      question: 'How accurate is my home value estimate?',
      answer: (
        <p>
          Estimates may vary based on the available information on a property. This is not an official appraised value. The actual
          appraised value of any property may be higher or lower.
        </p>
      ),
    },
    {
      question: 'How often does my home value change?',
      answer: (
        <p>
          The estimate will be updated every month based on our available records. Make sure the checkbox is filled to get alerts
          any time your value changes. The history panel near the bottom of the page will track the changes over time.
        </p>
      ),
    },
    {
      question: 'How much does an estimate cost?',
      answer: (
        <p>
          Free. The home value estimate is a complimentary service provided by Guaranteed Rate to help our customers make informed
          decisions.
        </p>
      ),
    },
    {
      question: 'The estimate will be updated every month based on our available records. Make sure the checkbox is filled to get alerts any time your value changes. The history panel near the bottom of the page will track the changes over time.',
      answer: (
        <p>
          Free. The home value estimate is a complimentary service provided by Guaranteed Rate to help our customers make informed
          decisions.
        </p>
      ),
    },
  ];
  const faqs = homeValueFAQs();
  const accordionInfo = [
    {
      title: 'Lorem ipsum dolor sit amet',
      body: (
        <p>
          Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
        </p>
      ),
    },
    {
      title: 'Laboris nisi ut aliquip ex ea commodo consequat',
      body: <p>Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>,
    },
    {
      title: 'Ut enim ad minim veniam.',
      body: <p>Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>,
    },
    {
      title: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
      body: <p>Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>,
    },
  ];

  return (
    <>
      <div>
        <h2 className='header-medium mb-8'>Accordion</h2>
        <div>
          <ul className='mb-12'>
            <li>
              <div className=''>
                {accordionInfo.map((accordion, i) => (
                  <div
                    key={`accordion-${i}`}
                    className={cn('border-t border-inactive-25', { 'border-b': i === accordionInfo.length - 1 })}
                  >
                    <Accordion title={accordion.title}>{accordion.body}</Accordion>
                  </div>
                ))}
              </div>
            </li>
          </ul>

          <ul>
            <li>
              <FAQs faqs={faqs} heading='Frequently asked questions' />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AccordionStyleguide;
