import { useState, useEffect, useCallback, SyntheticEvent, ChangeEvent, AnimationEvent, FocusEvent, useRef } from 'react';
import cn from 'classnames';
import { FormGroupWrapperProps, FormGroupProps, FormGroupElement } from './IFormGroup';
import FormLabel from './FormLabel';
import FormInput from './FormInput';
import FormGroupError from './FormGroupError';
import FormDollarInput from './FormDollarInput';
import FormPhoneInput from './FormPhoneInput';
import FormDateInput from './FormDateInput';

export const FormGroupWrapper = ({ active, error, className, type = 'text', children }: FormGroupWrapperProps) => {
  return (
    <div
      className={cn('form-group', { 'form-group--error': error }, { 'form-group--active': active }, { 'form-group-textarea': type === 'textarea' }, className)}
      data-ui='form-group'
    >
      {children}
    </div>
  );
};

export const FormGroup = (props: FormGroupProps) => {
  const {
    value,
    focus,
    interacted,
    autofill,
    id,
    label,
    onChange,
    onFocus,
    onBlur,
    onInteracted,
    onAnimationStart,
    onAutoFill,
    onValidate,
    onValueChange,
    onError,
    error,
    defaultErrorMessage,
    className,
    inputRef,
    type = 'text',
    ...rest
  } = props;

  const [valueUncontrolled, setValueUncontrolled] = useState('');
  const [focusUncontrolled, setFocusUncontrolled] = useState(false);
  const [interactedUncontrolled, setInteractedUncontrolled] = useState(false);
  const [autofillUncontrolled, setAutoFillUncontrolled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(error);
  const initForcusRef = useRef(false);

  const state = {
    value: value !== undefined ? value : valueUncontrolled,
    focus: focus !== undefined ? focus : focusUncontrolled,
    interacted: interacted !== undefined ? interacted : interactedUncontrolled,
    autofill: autofill !== undefined ? autofill : autofillUncontrolled,
  };

  // Form field is active if
  // user focused input field
  // input is not blank
  // browser autofill was triggered
  const active = state.focus || Boolean(state.value) || (state.autofill && !state.interacted);

  const handleChange = (evt: ChangeEvent<FormGroupElement>): void => {
    const eventValue = evt.target.value;
    value === undefined && setValueUncontrolled(eventValue);
    if (!error && eventValue) {
      setErrorMessage(undefined);
      onError?.(undefined);
    }
    onChange?.(evt);
  };

  const handleMaskedInputChange = (newValue: string) => {
    value === undefined && setValueUncontrolled(newValue);
    if (errorMessage && newValue) {
      setErrorMessage(undefined);
      onError?.(undefined);
    }
    onValueChange?.(newValue);
  };

  const handleFocus = (evt: SyntheticEvent): void => {
    focus === undefined && setFocusUncontrolled(true);
    initForcusRef.current = true;
    onFocus?.(evt);
  };

  useEffect(() => {
    // if input is required AND focused before AND lost focus AND input is empty AND no prop error
    if (props.required && initForcusRef.current && !focusUncontrolled && !state.value && !error) {
      setErrorMessage(defaultErrorMessage || `${label} is required`);
      onError?.(defaultErrorMessage || `${label} is required`);
    }
  }, [focusUncontrolled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  const handleBlur = (evt: FocusEvent<FormGroupElement>): void => {
    focus === undefined && setFocusUncontrolled(false);
    onBlur?.(evt);
  };

  const handleAnimationStart = (evt: AnimationEvent<FormGroupElement>): void => {
    onAnimationStart?.(evt);
    if (evt.animationName === 'onAutoFillStart') {
      autofill === undefined && setAutoFillUncontrolled(true);
      onAutoFill?.();
    }
  };

  const handleValidate = useCallback(
    value => {
      onValidate?.(value);
    },
    [onValidate],
  );

  useEffect(() => {
    if (state.interacted) {
      handleValidate(state.value);
    }
  }, [handleValidate, state.interacted, state.value]);

  const handleInteracted = useCallback(
    hasInteracted => {
      interacted === undefined && setInteractedUncontrolled(hasInteracted);
      onInteracted?.(hasInteracted);
    },
    [onInteracted, interacted, setInteractedUncontrolled],
  );

  useEffect(() => {
    if (!state.interacted && state.focus && error) {
      handleInteracted(true);
    }

    if (!state.interacted && !state.focus && state.value?.length) {
      handleInteracted(true);
    }
  }, [handleInteracted, state.interacted, state.focus, error, state.value]);

  const InputEl =
    type === 'phone' ? FormPhoneInput :
    type === 'currency' ? FormDollarInput :
    type === 'date' ? FormDateInput :
    FormInput; 
  const maskedInput = ['phone', 'currency', 'date'].includes(type);

  return (
    <FormGroupWrapper className={className} active={active} error={errorMessage} type={type}>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <InputEl
        value={state.value}
        {...rest}
        hasError={Boolean(errorMessage)}
        id={id}
        onChange={maskedInput ? handleMaskedInputChange : handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onAnimationStart={handleAnimationStart}
        type={type}
        {...(inputRef && { ref: inputRef })}
      />
      <FormGroupError error={errorMessage} id={id} />
    </FormGroupWrapper>
  );
};

export default FormGroup;
