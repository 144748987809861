import FlyoutComponent from '../../../components/flyout/FlyoutComponent';
import { ArticlesList } from '../../../components/loan/helpfulLink/HelpfulLinks';
import { SubpageNavigation } from '../../../components/navigation/SubpageNavigation';
import TopMenu from '../../../components/navigation/TopMenu';
import { NumberBadge } from '../../../components/ui/badge/NumberBadge';
import TextBadge from '../../../components/ui/badge/TextBadge';
import { Article } from '../../../features/managedContent/types';
import { PAGES } from '../../../components/routes/paths';
import { LoanMilestones } from '../../../features/loans/types';

const tabs = [
  {
    text: 'Overview',
    value: 'Overview',
    href: '#overview',
    isActive: true,
    disabled: false,
  },
  {
    text: 'Tasks',
    value: 'Tasks',
    href: '#tasks',
    isActive: false,
    disabled: false,
    primaryBadge: <NumberBadge number={3} isPrimary={true} />,
    secondaryBadge: <NumberBadge number={3} isPrimary={false} />,
  },
  {
    text: 'Loan details',
    value: 'Loan details',
    href: '#loan-details',
    isActive: false,
    disabled: false,
  },
  {
    text: 'Documents',
    value: 'Documents',
    href: '#documents',
    isActive: false,
    disabled: false,
    primaryBadge: <TextBadge className='ml-2' type='action' text='New' />,
    secondaryBadge: <TextBadge className='ml-2' type='action-secondary' text='New' />,
  },
  {
    text: 'Disabled',
    value: 'Disabled',
    href: '#disabled',
    isActive: false,
    disabled: true,
  },
];

const articles: Article[] = [
  { uid: '1', version: 1, updatedAt: '', updatedBy: '', title: 'Article 1', body: '<h2>Header</h2><p>Some paragraph text</p>', language: 'en', pages: [], loanMilestones: [] },
  {
    uid: '2', version: 1, updatedAt: '', updatedBy: '',
    title: 'Article 2',
    body: '<h2>Header</h2><p>Some paragraph text</p><ul><li>item one</li><li>item two</li><li>item three</li></ul>',
    language: 'en',
    pages: [],
    loanMilestones: [],
  },
  {
    uid: '3', version: 1, updatedAt: '', updatedBy: '',
    title: 'Article 3',
    body: '<h2>Header</h2><p>Some paragraph text</p><ol><li>item one</li><li>item two</li><li>item three</li></ol><p>Some paragraph text</p>',
    language: 'en',
    pages: [],
    loanMilestones: [],
  },
  {
    uid: '4', version: 1, updatedAt: '', updatedBy: '',
    title: 'Article 4',
    body: '<h2>Header</h2><p>Some paragraph text</p><h3>A subheader</h3><p>Some paragraph text</p><p>Some paragraph text</p>',
    language: 'en',
    pages: [],
    loanMilestones: [],
  },
];

const NavigationStyleguide = () => {
  const drawerContainerId = 'styleguide-articles-drawer';

  return (
    <div className='navigations mb-16'>
      <div className='mb-8'>
        <h2 className='header-small'>Navigation Menu Left</h2>
      </div>
      <FlyoutComponent
        contentLabel='Flyout Menu Left'
        flyoutComponent={closeFn => {
          return <TopMenu closeFn={closeFn} />;
        }}
        onChange={active => console.log('flyout left active', active)}
        orientation='left'
      >
        <div className='hamburger'>
          <div className='hamburger-inner'></div>
        </div>
      </FlyoutComponent>
      <div className='mt-16 mb-8'>
        <h2 className='header-small'>Navigation Menu Right</h2>
      </div>
      <FlyoutComponent
        contentLabel='Flyout Menu Right'
        flyoutComponent={closeFn => {
          return <TopMenu closeFn={closeFn} />;
        }}
        onChange={active => console.log('flyout right active', active)}
        orientation='right'
      >
        <div className='hamburger'>
          <div className='hamburger-inner'></div>
        </div>
      </FlyoutComponent>

      <div className='mt-16 mb-8'>
        <h2 className='header-medium'>Subnavigation</h2>
      </div>
      <SubpageNavigation tabs={tabs} />

      <div className='mt-16 mb-8'>
        <h2 className='header-medium'>Helpful links</h2>
      </div>

      <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 pb-16'>
        <div className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8 h-96'>I AM CONTENT</div>
        <div className='grid grid-cols-4 col-span-4 relative' id={drawerContainerId}>
          <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3 relative'>
            <div className='flex flex-col'>
              <div className='m-8'>
                <p className='title-text-text header-small bold mb-6'>Helpful links</p>
                <ArticlesList
                  articles={articles}
                  containerId={drawerContainerId}
                  loanGuid='styleguide-mock-loan-guid'
                  page={PAGES.homeLoanOverview}
                  loanMilestone={LoanMilestones.APPLICATION}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationStyleguide;
