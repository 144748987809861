/* istanbul ignore file */
export const SET_GATELESS_CONFIG = '[Gateless] SET_CONFIG';
export const ADD_GATELESS_EVENT = '[Gateless] EVENT';
export const SET_GATELESS_VOA_SSO_LINK_ID = '[Gateless] SET_VOA_SSO_LINK_ID';
export const EVENT_TASKLIST_SYNC = 'task-list-sync-completed';
export const EVENT_DOCUMENT_UPLOAD_COMPLETE = 'document-upload-complete';
export const EVENT_TASK_UPDATED = 'task-updated';
export const EVENT_TASK_CREATED = 'task-created';
export const EVENT_TASK_DELETED = 'task-deleted';
export const EVENT_VOA_SSO_LINK_AVAILABLE = 'voa-sso-link-available';
export const EVENT_VOA_FINICITY_CREATE_ACCOUNTS_FAILURE = 'finicity-create-accounts-failure';
export const EVENTS_TASK_MODIFIED = [EVENT_TASK_UPDATED, EVENT_TASK_CREATED, EVENT_TASK_DELETED];
export const EVENTS_FAILURE = [EVENT_VOA_FINICITY_CREATE_ACCOUNTS_FAILURE];
