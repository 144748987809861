import { AxiosResponse } from 'axios';
import { useState } from 'react';
import api from '../../features/api';
import { log } from '../../utils/logger';
import submitForm from '../../utils/submitForm';
import Button from '../ui/button/Button';
import { iButtonType, IconOrIconName, TextOrChildren } from '../ui/button/IButton';
import FormError from '../ui/form/FormError';
import { appIsDefaultLanguage, useMultilingual } from '../../hooks/useMultilingual';
import { useAppSelector } from '../../hooks/hooks';
import { selectAppLanguage } from '../../features/app/appSlice';
import { spanishDisclaimerModalSubject } from '../routes/modals/SpanishNavigationDisclaimerModal';

export type SSOData = {
  url: string;
  method: string;
  formData: Record<string, string>;
};

export type SSOButtonProps = IconOrIconName &
  TextOrChildren & {
    className?: string;
    ssoDataURL: string;
    errorMessage: string;
    disabled?: boolean;
    buttonType: iButtonType;
    handleSubmit?: () => void;
  };

export const SSOButton = ({ ssoDataURL, errorMessage, children, handleSubmit, ...restProps }: SSOButtonProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [hasError, setHasError] = useState(false);
  const multilingual = useMultilingual();
  const appLanguage = useAppSelector(selectAppLanguage);
  const isDefaultLanguage = appIsDefaultLanguage(appLanguage);

  const confirmedSubmitForm = () => {
    setIsFetching(true);
    setHasError(false);
    const newWindow = window.open();
    api
      .getSSOData(ssoDataURL)
      .then((response: AxiosResponse<SSOData>) => {
        const ssoData = response.data;
        submitForm({
          action: ssoData.url,
          method: ssoData.method,
          onSubmit: handleSubmit,
          fields: ssoData.formData,
          newWindow,
        });
        setIsFetching(false);
      })
      .catch(error => {
        log({ level: 'error', message: `Error fetching SSO data: ${error.message}` });
        setIsFetching(false);
        setHasError(true);
      });
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    if (multilingual && !isDefaultLanguage) {
      event.preventDefault();
      spanishDisclaimerModalSubject.next({
        open: true,
        href: null,
        target: null,
        onConfirm: confirmedSubmitForm,
      });
      return;
    }
    confirmedSubmitForm();
  };

  return (
    <>
      <Button loading={isFetching} onClick={handleButtonClick} {...restProps}>
        {children}
      </Button>
      {hasError && (
        <div className='mt-4'>
          <FormError error={errorMessage} />
        </div>
      )}
    </>
  );
};
