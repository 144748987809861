import { useEffect } from 'react';
import useEmbed from '../../hooks/useEmbed';

const BodyClasses = () => {
  const embed = useEmbed();
  useEffect(() => {
    if (embed) {
      document.body.classList.add('embed');
    } else {
      document.body.classList.remove('embed');
    }
  }, [embed]);

  return null;
};

export default BodyClasses;
