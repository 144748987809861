import { ReactNode, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppGlobal from './components/appGlobal/AppGlobal';
import AppSpinner from './components/appGlobal/AppSpinner';
import BodyClasses from './components/bodyClasses/BodyClasses';
import BreakpointDebugger from './components/breakpointDebugger/BreakpointDebugger';
import Cobrowse from './components/cobrowse/Cobrowse';
import EmbedMode from './components/embedMode/EmbedMode';
import Analytics from './components/analytics/Analytics';
import ErrorBoundary from './components/error/ErrorBoundary';
import InactivityTimeout from './components/inactivityTimeout/InactivityTimeout';
import Preload from './components/preload/Preload';
import RouteLogger from './components/routeLogger/RouteLogger';
import Routes from './components/routes/Routes';
import useRoutes from './components/routes/useRoutes';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import TriggerView from './components/triggerView/TriggerView';
import { RouteContext, RouteState, initialRouteState } from './features/RouteContext';
import { SupportContext } from './features/SupportContext';
import { initApm } from './features/apm/apm';
import useErrorHandling from './hooks/useErrorHandling';
import { UnsupportedBrowserError } from './pages/unsupportedBrowserError/UnsupportedBrowserError';
import { log } from './utils/logger';
import EventsWebSocket from './components/eventsWebSocket/EventsWebSocket';
import useFeatureEnabled from './hooks/useFeatureEnabled';

log({ level: 'info', message: 'Initializing app' });
initApm();

const App = () => {
  const [routeState, setRouteState] = useState<RouteState>(initialRouteState);
  const [supportComponent, setSupportComponent] = useState<ReactNode | undefined>(undefined);
  const includeEventsWebSocket = useFeatureEnabled('myaccount-events-websocket');

  useErrorHandling();

  return (
    <Router>
      <UnsupportedBrowserError>
        <RouteContext.Provider value={{ routeState, setRouteState }}>
          <SupportContext.Provider value={{ supportComponent, setSupportComponent }}>
            <ErrorBoundary>
              <Preload />
              <BodyClasses />
              <ScrollToTop />
              <RouteLogger />
              <TriggerView />
              <EmbedMode />
              {includeEventsWebSocket && <EventsWebSocket />}
              <Analytics />
              <AppSpinner />
              <InactivityTimeout />
              <Cobrowse />
              <BreakpointDebugger />
              <AppGlobal>
                <Routes routes={useRoutes()} />
              </AppGlobal>
            </ErrorBoundary>
          </SupportContext.Provider>
        </RouteContext.Provider>
      </UnsupportedBrowserError>
    </Router>
  );
};

export default App;
