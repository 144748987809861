import cn from 'classnames';
import type { PhoneButtonGroupProps } from './IPhoneButtonGroup';
import { PhoneButton } from './PhoneButton';

export const PhoneButtonGroup = (props: PhoneButtonGroupProps) => {
  const { phoneList, onPhoneClick, className, ...restProps } = props;
  return phoneList && phoneList.length > 0 ? (
    <div className='flex flex-col'>
      {phoneList.map(({ phone, suffix, className: itemClassName, onClick, ...restItem }, index) => {
        const handleClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
          onClick?.(event);
          onPhoneClick?.(event, phone);
        };
        return (
          <div key={phone}>
            <PhoneButton
              {...restProps}
              {...restItem}
              phone={phone}
              suffix={suffix}
              className={cn({ 'mt-2': index !== 0 }, className, itemClassName)}
              onClick={handleClick}
            />
          </div>
        );
      })}
    </div>
  ) : null;
};
