import MaskedInputs from './MaskedInputs';
import RadioButton from '../../../components/ui/radioButton/RadioButton';
import Forms from '../Forms';

const Inputs = () => {
  return (
    <>
      <div className='inputs'>
        <h2 className='header-medium mb-8'>Masked text inputs</h2>
        <MaskedInputs />
      </div>
      <div className='inputs'>
        <h2 className='header-medium my-8'>Text inputs</h2>
        <Forms />
      </div>
      <div className='inputs'>
        <h2 className='header-medium mb-8'>Radio button inputs</h2>
        <RadioButton
          id='id1'
          value='one'
          name='numbers'
          onClick={() => {
            console.log('One selected!');
          }}
          className='mb-6'
          label='1'
        />
        <RadioButton
          id='id2'
          value='two'
          name='numbers'
          onClick={() => {
            console.log('Two selected!');
          }}
          label='2'
          defaultChecked={true}
        />
        <RadioButton
          id='id3'
          value='two'
          name='numbers'
          onClick={() => {
            console.log('Three selected!');
          }}
          label='3'
        />
      </div>
    </>
  );
};

export default Inputs;
