import celebrateImage from '../../../images/celebrate.svg';
import checklistImage from '../../../images/checklist-empty.svg';
import checkImage from '../../../images/check.svg';
import folderImage from '../../../images/folder.svg';
import chromeLogoImage from '../../../images/chrome-logo.svg';
import edgeLogoImage from '../../../images/edge-logo.svg';
import firefoxLogoImage from '../../../images/firefox-logo.svg';
import safariLogoImage from '../../../images/safari-logo.svg';
import offerHousesCloudsImage from '../../../images/offer-houses-clouds.svg';
import offerHouseCloudsImage from '../../../images/offer-house-clouds.svg';
import offerSunImage from '../../../images/offer-sun.svg';

const images = [
  {
    src: celebrateImage,
    width: '70',
    height: '',
  },
  {
    src: checklistImage,
    width: '',
    height: '',
  },
  {
    src: checkImage,
    width: '',
    height: '',
  },
  {
    src: '/images/error-found.svg',
    width: '150',
    height: '150',
  },
  {
    src: folderImage,
    width: '',
    height: '',
  },
  {
    src: chromeLogoImage,
    width: '48',
    height: '48',
  },
  {
    src: edgeLogoImage,
    width: '48',
    height: '48',
  },
  {
    src: firefoxLogoImage,
    width: '48',
    height: '48',
  },
  {
    src: safariLogoImage,
    width: '48',
    height: '48',
  },
  {
    src: offerHousesCloudsImage,
    width: '',
    height: '',
  },
  {
    src: offerHouseCloudsImage,
    width: '',
    height: '',
  },
  {
    src: offerSunImage,
    width: '',
    height: '',
  },
];

const Illustrations = () => {
  return (
    <div className='illustrations'>
      <div className='header-medium'>Illustrations</div>
      <div className='mb-6 flex flex-wrap'>
        {images.map((item, index) => {
          return (
            <div key={index} className='m-4'>
              <img src={item.src} width={item.width} height={item.height} alt=''></img>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Illustrations;
