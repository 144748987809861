import { iDocument } from '../../../features/documents/types';
import Time from '../../ui/time/Time';
import { formatDate, FORMAT_ABBREVIATED_TIME, FORMAT_LONG_TIME } from '../../../utils/date';
import { sendEvent } from '../../../features/analytics/sendEvent';
import Button from '../../ui/button/Button';
import { useAppDispatch } from '../../../hooks/hooks';
import { updateGlobalLoading } from '../../../features/app/appSlice';
import { sendDocumentEventToShellApp } from '../../../features/mobileApp/shellAppEvents';
import { useMobileAppDocumentEvent } from '../../../hooks/useMobileAppDocumentEvent';

const rowClass = 'flex flex-row items-center justify-between rounded-xl transition-colors hover:bg-info-10 px-4';

// TODO - to be removed
export const Document = ({
  document: { id, title, href, 'date-added': date, 'task-id': taskId, category },
}: {
  document: iDocument;
}) => {
  const mobileAppDocumentEvent = useMobileAppDocumentEvent();
  const dispatch = useAppDispatch();
  const viewHref = mobileAppDocumentEvent ? undefined : href;
  const downloadHref = mobileAppDocumentEvent ? undefined : `${href}?download=true`;

  const handleViewDocument = async () => {
    sendEvent('documentView', { documentId: id, documentCategory: category, taskId: taskId });
    if (mobileAppDocumentEvent) {
      dispatch(updateGlobalLoading(true));
      await sendDocumentEventToShellApp('VIEW_DOCUMENT', href, title);
      dispatch(updateGlobalLoading(false));
    }
  };

  const handleDownloadDocument = async () => {
    sendEvent('documentDownload', { documentId: id, documentCategory: category, taskId: taskId });
    if (mobileAppDocumentEvent) {
      dispatch(updateGlobalLoading(true));
      await sendDocumentEventToShellApp('DOWNLOAD_DOCUMENT', href, title);
      dispatch(updateGlobalLoading(false));
    }
  };

  return (
    <div className={rowClass} key={id}>
      <div className='flex flex-col py-2 mb-1 truncate'>
        <Button
          className='truncate OneLinkNoTx'
          href={viewHref}
          target='_blank'
          buttonType='tertiary'
          onClick={handleViewDocument}
          text={title}
        />
        {date && (
          <Time
            className='text-xs mt-1'
            date={date}
            label={formatDate(date, FORMAT_ABBREVIATED_TIME)}
            title={formatDate(date, FORMAT_LONG_TIME)}
          />
        )}
      </div>
      <Button
        href={downloadHref}
        rel='noreferrer'
        text={title}
        onClick={handleDownloadDocument}
        className='hidden mr-4 lg:inline no-underline no-select'
        buttonType='icon'
        iconSize='1.25rem'
        iconName='interface-download'
      />
    </div>
  );
};

export default Document;
