import { apm, init } from '@elastic/apm-rum';
import { env, isLocalDev, appName, version } from '../../utils/env';
import { content } from '../tenant/tenant';
import { User } from '../user/types';

export const initApm = () => {
  init({
    active: !isLocalDev,
    serviceName: appName,
    serverUrl: content.apm.serverUrl,
    serviceVersion: version,
    environment: env
  });
};

export const setIdentity = ({ email, guid }: Partial<User>): void => {
  apm.setUserContext({ email, id: guid });
};

export const setCustomContext = (custom: Record<string, unknown>): void => {
  apm.setCustomContext(custom);
};

export const addLabels = (label: Record<string, string | number | boolean>): void => {
  apm.addLabels(label);
};
