import { useEffect, useRef, useState } from 'react';
import Button from '../../../ui/button/Button';
import Circle from '../../../ui/circle/Circle';
import Icon from '../../../ui/icon/Icon';
import Modal from '../../../ui/modal/Modal';
import { useAppDispatch } from '../../../../hooks/hooks';
import { updateUserLoanAttribute } from '../../../../features/user/userSlice';
import { Loan } from '../../../../features/loans/types';
import timestamp from '../../../../utils/timestamp';
import { sendEvent } from '../../../../features/analytics/sendEvent';
import { log } from '../../../../utils/logger';

export const FastTrackApprovedModalContent = ({ onContinue }: { onContinue: () => void }) => {
  const Pill = ({ text }: { text: string }) => {
    return (
      <div className='flex flex-row flex-grow border border-inactive-25 rounded-lg p-4'>
        <Circle size='1rem' bgColor='ok-125'>
          <Icon name='check-tick' size='0.7rem' />
        </Circle>
        <p className='pl-2 text-ok-125 font-bold'>{text}</p>
      </div>
    );
  };

  return (
    <div className='flex flex-col text-center'>
      <p className='text-marketing-xs md:text-marketing-md'>You're approved!</p>
      <div className='my-8 flex flex-col gap-4 md:flex-row justify-around'>
        <Pill text='Credit' />
        <Pill text='Income' />
        <Pill text='Assets' />
      </div>
      <p className='mb-6'>I am pleased to inform you that you've been fully approved for a mortgage loan through Rate! This approval includes verification of your credit, income and assets.</p>
      <p className='mb-6'>We have a few remaining steps to get you cleared to close. Click, continue to see what's next.</p>
      <Button
        buttonType='primary'
        text='Continue'
        className='self-center w-full lg:w-fit-content'
        size='large'
        onClick={onContinue}
      />
    </div>
  );
};

export const FAST_TRACK_SHOWN_KEY = 'fastTrackShown';

export const FastTrackApprovedModal = ({ loan }: { loan: Loan }) => {
  const dispatch = useAppDispatch();
  const { 'loan-guid': loanGuid } = loan;
  const [open, setOpen] = useState(false);
  const modalOpened = useRef<boolean>(false);

  useEffect(() => {
    if (!open && !modalOpened.current) {
      modalOpened.current = true;
      setOpen(true);
    }
  }, [dispatch, loanGuid, open]);

  const onClose = () => {
    dispatch(updateUserLoanAttribute({ loanGuid, value: { [FAST_TRACK_SHOWN_KEY]: timestamp() } }));
    setOpen(false);
  };

  const onOpen = () => {
    sendEvent('fastTrackApprovalModalShown', { loanGuid });
    log({ loanGuid, message: `Fast track approval modal shown` });
  };

  return (
    <Modal
      open={open}
      onOpen={onOpen}
      onRequestClose={onClose}
      contentLabel='Fast track approved'
    >
      <FastTrackApprovedModalContent onContinue={onClose} />
    </Modal>
  );
};
