import { ReactNode } from 'react';
import cn from 'classnames';
import Accordion from '../accordion/Accordion';

export interface IFAQ {
  question: string | ReactNode;
  answer: string | ReactNode;
  active?: boolean;
}

const FAQs = (
  {
    id,
    faqs,
    heading,
    containerClassNames = '',
    onToggle
  }: {
    id?: string,
    faqs: IFAQ[],
    heading?: string,
    containerClassNames?: string,
    onToggle?:(isActive: boolean, faq: IFAQ, i: number) => void,
  }
) => {
  return (
    <div id={id} className={cn('faqs', containerClassNames)}>
      {heading && <h2 className='header-small mb-4'>{heading}</h2>}
      {faqs.map((faq, i) => (
        <div key={`FAQ-${i}`} className={cn('border-t border-inactive-25', { 'border-b': i === faqs.length - 1 })}>
          <Accordion title={faq.question} active={!!faq.active} onToggle={(isActive: boolean) => { onToggle?.(isActive, faq, i)}}>
            <div className='mb-4'>
              {faq.answer}
            </div>
          </Accordion>
        </div>
      ))}
    </div>
  );
};

export default FAQs;
