import type { Loan } from '../features/loans/types';
import { AlpMilestone, AlpLoanType, LoanMilestones } from '../features/loans/types';

export const getAlpHELOCLoan = (milestone: AlpMilestone): Loan => {
  const alpLoan = {
    ...alpHELOCLoanInprocess,
    'alp-heloc-loan': {
      milestone,
    },
  };
  return alpLoan;
};

export const getFundedAlpHELOCLoan = (): Loan => {
  const alpLoan = {
    ...alpHELOCLoanInprocess,
    'loan-milestone': LoanMilestones.CLOSED,
  };
  return alpLoan;
};

export const getAlpCESLoan = () => {
  return alpCESLoan;
};

export const getFundedAlpCESLoan = () => {
  const alpLoan = {
    ...alpHELOCLoanInprocess,
    'alp-loan-type': AlpLoanType.CES,
    'loan-milestone': LoanMilestones.CLOSED,
  };
  return alpLoan;
};

export const alpHELOCLoanInprocess: Loan = {
  "alp-heloc-loan": {
    milestone: AlpMilestone.APPLICATION,
  },
  "alp-loan-type": AlpLoanType.HELOC,
  "appraisal-status": "received",
  "tasks-ready": "ready",
  "interest-rate": 6.5,
  "last-updated": "2022-10-12T13:22:28.043Z",
  "va?": false,
  "loan-milestone": LoanMilestones.PREAPPROVAL,
  "estimated-closing-date": "2022-10-29T00:00:00Z",
  "generate-l1-pal?": false,
  "lap?": false,
  "refinance?": false,
  "property": {
      "street-address": "3734 W Wrightwood Ave",
      "city": "Westland",
      "state": "MI"
  },
  "ext-features": {
      "gateless-jumbo-tasks": "jumbo-tasks-20220601",
      "my-account": true,
      "case-memory-resources-ready": true,
      "gateless-smart-underwrite": false,
      "gateless-va-tasks": "va-tasks-20220601",
      "google-doc-ai": true,
      "gateless-smart-underwrite-disabled": true,
      "task-list-ready": true,
      "gateless-blended-tasks": "blended",
      "document-autosync": true,
      "gateless-fha-tasks": "fha-tasks-20220601",
      "voa-vendor": "finicity",
      "gateless-tasks-20211025": true
  },
  "loan-number": "224176627DEV",
  "milestone-modals?": true,
  "base-loan-amount": 160000,
  "smart-underwrite?": false,
  "generate-l2-pal?": true,
  "digital-mortgage?": true,
  "down-payment": 40000,
  "team": {
      "lo": {
          "features": [
              "l1-pal",
              "l2-pal"
          ],
          "emp-id": 4723,
          "fax": "773-555-5555",
          "email": "testlo@rate.com",
          "photo-url": "https://www.guaranteedrate.com/images/vp/johnsample.jpg",
          "phone": "773-555-1212",
          "name": "John Sample",
          "licenses": [
              {
                  "state": "KY",
                  "license-number": "123-test"
              },
              {
                  "state": "IA",
                  "license-number": "123-test"
              },
              {
                  "state": "VA",
                  "license-number": "123-test"
              },
              {
                  "state": "OR",
                  "license-number": "123-test"
              },
              {
                  "state": "SC",
                  "license-number": "123-test"
              },
              {
                  "state": "CT",
                  "license-number": "123-test"
              },
              {
                  "state": "FL",
                  "license-number": "123-test"
              },
              {
                  "state": "GA",
                  "license-number": "GA - TAO - 123-test"
              },
              {
                  "state": "NE",
                  "license-number": "123-test"
              },
              {
                  "state": "ME",
                  "license-number": "123-test"
              },
              {
                  "state": "MT",
                  "license-number": "123-test"
              },
              {
                  "state": "WI",
                  "license-number": "123-test"
              },
              {
                  "state": "TN",
                  "license-number": "123-test"
              },
              {
                  "state": "WV",
                  "license-number": "123-test"
              },
              {
                  "state": "VT",
                  "license-number": "123-test"
              },
              {
                  "state": "ND",
                  "license-number": "123-test"
              },
              {
                  "state": "DE",
                  "license-number": "123-test"
              },
              {
                  "state": "IN",
                  "license-number": "123-test"
              },
              {
                  "state": "AR",
                  "license-number": "123-test"
              },
              {
                  "state": "NH",
                  "license-number": "123-test"
              },
              {
                  "state": "AL",
                  "license-number": "123-test"
              },
              {
                  "state": "MA",
                  "license-number": "123-test"
              },
              {
                  "state": "PA",
                  "license-number": "123-test"
              },
              {
                  "state": "LA",
                  "license-number": "123-test"
              },
              {
                  "state": "OK",
                  "license-number": "123-test"
              },
              {
                  "state": "MS",
                  "license-number": "123-test"
              },
              {
                  "state": "TX",
                  "license-number": "123-test"
              },
              {
                  "state": "UT",
                  "license-number": "123-test"
              },
              {
                  "state": "CO",
                  "license-number": "123-test"
              },
              {
                  "state": "NM",
                  "license-number": "123-test"
              },
              {
                  "state": "SD",
                  "license-number": "123-test"
              },
              {
                  "state": "NY",
                  "license-number": "123-test"
              },
              {
                  "state": "RI",
                  "license-number": "123-test"
              },
              {
                  "state": "NJ",
                  "license-number": "123-test"
              },
              {
                  "state": "MO",
                  "license-number": "123-test"
              },
              {
                  "state": "ID",
                  "license-number": "123-test"
              },
              {
                  "state": "AZ",
                  "license-number": "123-test"
              },
              {
                  "state": "HI",
                  "license-number": "123-test"
              },
              {
                  "state": "MI",
                  "license-number": "123-test"
              },
              {
                  "state": "NV",
                  "license-number": "123-test"
              },
              {
                  "state": "KS",
                  "license-number": "123-test"
              },
              {
                  "state": "MD",
                  "license-number": "anton-test"
              },
              {
                  "state": "MN",
                  "license-number": "123-test"
              },
              {
                  "state": "CA",
                  "license-number": "123-test"
              },
              {
                  "state": "OH",
                  "license-number": "123-test"
              },
              {
                  "state": "DC",
                  "license-number": "123-test"
              },
              {
                  "state": "NC",
                  "license-number": "123-test"
              },
              {
                  "state": "IL",
                  "license-number": "123456"
              },
              {
                  "state": "WA",
                  "license-number": "123-test"
              }
          ],
          "status": "active",
          "id": "testlo",
          "role-name": "Loan Officer",
          "nmls-id": "12345",
          "role-key": "lo",
          title: "VP Of Mortgage Lending",
      },
      "mc": {
          "role-name": "Mortgage Consultant",
          "fax": "999-999-999",
          "email": "testmc@rate.com",
          "phone": "888-888-8888",
          "name": "Test MC",
          "id": "testmc",
          "role-key": "mc"
      }
  },
  "show-preapproval-letters?": true,
  "loan-guid": "0409aabe-4775-44e0-960d-de825c2abb84",
  "prequalification?": true,
  "borrower": {
      "name": "Andy  America",
      "email": "myaccount-3092-1@yopmail.com",
      "ssn?": true
  },
  "amortization-type": "Fixed",
  "aus-completed": "timed-out",
  "loan-term-months": 360,
  "application-status": "completed",
  "gr-loan-guid": "60295559-d74d-481a-98eb-15fbba311c3e",
  "started-date": "2022-10-11T20:41:21.000Z",
  'comortgagor?': false,
  'conditional-approval-with-letter?': false,
};

export const alpCESLoan: Loan = {
  ...alpHELOCLoanInprocess,
  "alp-loan-type": AlpLoanType.CES,
  "alp-ces-loan": undefined,
};
