import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RequestError, RequestRejectValue } from '../../interfaces/IRequest';
import type { RootState } from '../../store/types';
import api from '../api';
import { PartialDataError, logRequestError, rejectPartialDataValue, rejectValue } from '../requestHelper';
import { HELOCLoanMetadata, HELOCLoansState, iHELOCLoan } from './types';

const namespace = 'helocLoans';

const initialState: HELOCLoansState = {
  byId: {},
  ids: [],
  metadata: {
    hasData: false,
    isFetching: false,
    hasError: false,
  },
};

export const fetchHELOCLoans = createAsyncThunk<
  iHELOCLoan[],
  undefined,
  { rejectValue: RequestRejectValue }
>(`${namespace}/fetchHELOCLoans`, async (_, { rejectWithValue }) => {
  try {
    const response = await api.getHELOCLoans();
    const data = response.data.filter((helocLoan: iHELOCLoan) => !!helocLoan.id);
    if (response.data.length > data.length) {
      throw new PartialDataError('Failed to retrieve loan for product account', data);
    }
    return response.data;
  } catch (err) {
    const error = err as PartialDataError<iHELOCLoan[]> | AxiosError<RequestError>;
    logRequestError(`${namespace}/fetchHELOCLoans`, error);

    if (error instanceof PartialDataError) {
      return rejectWithValue(rejectPartialDataValue(error) as RequestRejectValue<iHELOCLoan[]>);
    }

    if (!error.response) {
      throw err;
    }

    return rejectWithValue(rejectValue(error) as RequestRejectValue);
  }
});

const getStateData = (loans: iHELOCLoan[]) =>
  loans.reduce(
    (accState, loan: iHELOCLoan) => ({
      ...accState,
      ids: [...accState.ids, loan.id],
      byId: {
        ...accState.byId,
        [loan.id]: loan,
      },
    }),
    { byId: initialState.byId, ids: initialState.ids },
  );

export const helocLoansSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchHELOCLoans.pending, (state) => {
      state.metadata.isFetching = true;
      state.metadata.hasError = false;
    });
    builder.addCase(fetchHELOCLoans.fulfilled, (state, action) => {
      state.metadata.hasData = true;
      state.metadata.isFetching = false;
      const updatedDataState = getStateData(action.payload);
      state.ids = updatedDataState.ids;
      state.byId = updatedDataState.byId;
    });
    builder.addCase(fetchHELOCLoans.rejected, (state, { payload, error }) => {
      state.metadata.isFetching = false;
      state.metadata.hasError = true;
      state.metadata.error = error;
      const rejectedPayload = payload as RequestRejectValue<iHELOCLoan[]>;
      if (rejectedPayload?.type === 'partialDataError' && rejectedPayload?.data) {
        state.metadata.hasLoanFetchFailure = true;
        state.metadata.hasData = true;
        const updatedDataState = getStateData(rejectedPayload.data);
        state.ids = updatedDataState.ids;
        state.byId = updatedDataState.byId;
      }
    });
  },
});

export const selectHELOCLoansMetadata = (state: RootState): HELOCLoanMetadata => {
  return state.helocLoans.metadata;
};

export const selectIsHELOCLoansFetching = (state: RootState): boolean => {
  return !!state.helocLoans.metadata.isFetching;
};

export const selectHELOCLoansHasData = (state: RootState): boolean => {
  return !!state.helocLoans.metadata.hasData;
};

export const selectHELOCLoansHasError = (state: RootState): boolean => {
  return !!state.helocLoans.metadata.hasError;
};

export const selectHELOCLoansHasFetchFailure = (state: RootState): boolean => {
  return !!state.helocLoans.metadata.hasLoanFetchFailure;
};

export const selectHELOCLoanIds = (state: RootState) => state.helocLoans.ids;

export const selectHELOCLoans = (state: RootState) => state.helocLoans.byId;

export const selectFundedHELOCLoanIds = createSelector(selectHELOCLoanIds, selectHELOCLoans, (ids, loans) => {
  return ids.filter(id => isHELOCLoanFunded(loans[id]));
});

export const selectProcessingHELOCLoanIds = createSelector(selectHELOCLoanIds, selectHELOCLoans, (ids, loans) => {
  return ids.filter(id => !isHELOCLoanFunded(loans[id]));
});

export const selectHELOCLoanById = (state: RootState, loanGuid: string): iHELOCLoan | undefined =>
  selectHELOCLoans(state)[loanGuid];

export const isHELOCLoanFunded = (loan: iHELOCLoan): boolean => loan.loanDetails.status === 'funded';

export const helocLoansActions = helocLoansSlice.actions;
export default helocLoansSlice.reducer;

export const getDisplayedLoanNumber = (loan: iHELOCLoan) => loan.servicer?.loanNumber || loan.originator.loanNumber;