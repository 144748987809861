import cn from 'classnames';
import { CircleProps } from './ICircle';
import { isEmpty } from '../../../utils/object';

export const Circle = ({ text, children, className, style, size, bgColor, textColor, textSize }: CircleProps) => {
  style = { ...style, width: '1.5rem', height: '1.5rem', ...(size && { minWidth: size }), ...(size && { minHeight: size }) };
  return (
    <span
      className={cn(
        `bg-${bgColor}`,
        `text-${textColor}`,
        `body-${textSize}`,
        'inline-flex relative align-bottom rounded-full text-center leading-none box-content whitespace-nowrap items-center justify-center font-bold',
        className,
      )}
      data-ui='circle'
      {...(!isEmpty(style) && { style })}
    >
      {text || children}
    </span>
  );
};

Circle.defaultProps = {
  bgColor: 'gray-75',
  textColor: 'white',
  textSize: 'medium',
};

export default Circle;
