import { getDisplayedLoanNumber, selectPersonalLoanById } from '../../../features/personalLoans/personalLoansSlice';
import { iPersonalLoan } from '../../../features/personalLoans/types';
import { useAppSelector } from '../../../hooks/hooks';
import { usePersonalLoanConfig } from '../../../hooks/usePersonalLoanConfig';
import { DashboardLoanCard } from './DashboardLoanCards';
import { TimeProps } from '../../ui/time/ITime';
import Time from '../../ui/time/Time';
import { formatDateWithoutTimezone, FORMAT_SHORT, FORMAT_LONG } from '../../../utils/date';

const DateInfo = (loan: iPersonalLoan) => {
  let dateInfo;
  if (loan.loanDetails.contract?.fundingDate) {
    const fundingDate = loan.loanDetails.contract?.fundingDate;
    dateInfo = {
      date: fundingDate,
      label: `Closed on ${formatDateWithoutTimezone(fundingDate, FORMAT_SHORT)}`,
      title: formatDateWithoutTimezone(fundingDate, FORMAT_LONG),
    };
  }

  if (!dateInfo) return null;

  return <Time {...(dateInfo as TimeProps)} />;
};

export const DashboardPersonalLoanCardPresentational = ({ loan }: { loan: iPersonalLoan }) => {
  const loanConfig = usePersonalLoanConfig(loan);
  const loanNumber = getDisplayedLoanNumber(loan);
  if (!loanConfig) return null;
  return (
    <DashboardLoanCard
      loanAmount={loan.loanDetails.amount}
      loanConfig={loanConfig}
      loanNumber={loanNumber}
      dateNode={<DateInfo {...loan} />}
    />
  );
};

export const DashboardPersonalLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const loan = useAppSelector(state => selectPersonalLoanById(state, loanGuid));
  if (!loan) return null;
  return (
    <DashboardPersonalLoanCardPresentational loan={loan} />
  );
};
