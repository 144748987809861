import cn from 'classnames';
import { RecommendationsProps } from './IRecommendations';
import './Recommendations.scss';
import Icon from '../icon/Icon';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';
import { ReactNode } from 'react';

const Recommendations = (props: RecommendationsProps) => {
  const { className, title, text, children, link, cta } = props;
  return (
    <ConditionalWrapper
      condition={!!link}
      wrapper={(children: ReactNode) => (
        <a className='no-underline' href={link}>
          {children}
        </a>
      )}
    >
      <div className={cn('recommendations bg-info-10 text-sm text-body rounded-xl p-6', className)} data-ui='recommendations'>
        <div className='flex flex-col sm:flex-row lg:flex-col'>
          <div className='sm:mr-6'>
            <div className='text-xl font-bold hyphens-auto rounded-xl mb-4 sm:mb-2 lg:mb-4 break-words'>{title}</div>
            <div className={`inline-block ${link ? 'w-11/12' : ''}`}>{text || children}</div>
          </div>
          {cta && <div className='mt-4 ml-0 sm:ml-auto sm:my-auto lg:mt-4 lg:ml-0 flex-shrink-0'>{cta}</div>}
          {link && (
            <div className='recommendations__chevron inline-block align-top float-right'>
              <Icon name='chevron-right-large' size='1.25rem' />
            </div>
          )}
        </div>
      </div>
    </ConditionalWrapper>
  );
};

export default Recommendations;
