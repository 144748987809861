import Button from '../button/Button';
import { FormButtonProps } from './IFormButton';
import cn from 'classnames';

const FormButton = (props: FormButtonProps) => {
  const { children, className, containerClassName, ...rest } = props;
  return (
    <div className={cn('form-btn flex items-center', containerClassName)}>
      <Button className={cn(className)} type='submit' buttonType='primary' {...rest} data-ui='form-btn'>
        {children}
      </Button>
    </div>
  );
};

export default FormButton;
