import { User } from '../../features/user/types';
import Dropdown from '../ui/dropdown/Dropdown';
import { useSecondaryNavigationLinks } from '../navigation/Navigation';

const HeaderDropdown = ({ user }: { user: User | null }) => {
  const id = 'headerDropdown';
  const navLinks = useSecondaryNavigationLinks(id, true);
  const headerDropdownItems = navLinks.map(link => {
    return {
      value: link.id,
      text: link.text,
      dataQa: `header-${link.id}-link`,
      ...(link.to && { to: link.to }),
      ...(link.href && { href: link.href }),
      ...(link.target && { target: link.target }),
      ...(link.rel && { rel: link.rel }),
      ...(link.onClick && { onClick: link.onClick }),
    };
  });

  return (
    <div className='flex items-center'>
      <Dropdown
        id={id}
        text={
          user?.name?.first ? (
            <span className='bold text-sm whitespace-nowrap'>
              Welcome, <span className='OneLinkNoTx'>{user.name.first}</span>
            </span>
          ) : (
            <span className='bold text-sm'>Welcome</span>
          )
        }
        iconName='user-circle'
        iconSize='1.25rem'
        iconAriaLabel='Profile'
        items={headerDropdownItems}
        offsetRight={true}
      />
    </div>
  );
};

export default HeaderDropdown;
