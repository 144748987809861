import { ReactNode } from 'react';
import cn from 'classnames';

interface CardProps {
  children: ReactNode;
  title?: string;
  className?: string;
  dropShadow?: boolean;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
  anchorRef?: React.MutableRefObject<null>;
}

const Card = ({ children, title, className, dropShadow = false, onClick, anchorRef }: CardProps) => {
  const handleOnClick = (evt: React.MouseEvent<HTMLElement>): void => {
    const selectedText = window.getSelection()?.toString();
    const link = anchorRef?.current as HTMLButtonElement | null;
    const target = evt.target as HTMLElement;

    // Prevent duplicate onClick events
    if (target === link || link?.contains(target)) {
      return;
    }

    // Prevent onClick when user clicked clickable element first
    if (target.nodeName === 'A' || target.nodeName === 'BUTTON' || target.getAttribute('role') === 'button') {
      return;
    }

    // Prevent click if text is selected
    if (selectedText) {
      return;
    }

    onClick && onClick(evt);
  };

  return (
    <div
      className={cn(className, 'p-6', 'border', 'border-gray-25', 'rounded-xl', !!onClick ? 'cursor-pointer' : '')}
      style={dropShadow ? {
        filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
      } : {}}
      onClick={handleOnClick}
      title={title}
    >
      {children}
    </div>
  );
};

export default Card;
