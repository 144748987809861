import { breadcrumbPaths, PATHS, TITLES } from '../components/routes/paths';
import { selectLoan } from '../features/loans/loansSlice';
import { useAppSelector } from './hooks';
import { useRouteMatch } from 'react-router-dom';
import {
  getDisplayedLoanNumber as getDisplayedPersonalLoanNumber,
  selectPersonalLoanById,
} from '../features/personalLoans/personalLoansSlice';
import { getDisplayedLoanNumber as getDisplayedHelocLoanNumber, selectHELOCLoanById } from '../features/helocLoans/helocLoansSlice';
import { Breadcrumb } from '../components/ui/breadcrumbs/iBreadcrumb';
import { content } from '../features/tenant/tenant';
import { useIsAuth } from '../features/auth/authSlice';

export const usePathBreadcrumbs = () => {
  const isAuthenticated = useIsAuth();
  const routeMatch = useRouteMatch<{ guid: string | undefined }>({ path: breadcrumbPaths, exact: true });
  const pathname = routeMatch?.path;
  const loanGuid = routeMatch?.params?.guid;
  const pathWithGuid = loanGuid && pathname?.replace(':guid', loanGuid);

  // There can only be one loan, skip selector if loan is found
  const homeLoan = useAppSelector(state => (loanGuid ? selectLoan(state, loanGuid) : undefined));
  const personalLoan = useAppSelector(state => (loanGuid && !homeLoan ? selectPersonalLoanById(state, loanGuid) : undefined));
  const helocLoan = useAppSelector(state =>
    loanGuid && !personalLoan && !homeLoan ? selectHELOCLoanById(state, loanGuid) : undefined,
  );

  const breadcrumbs: Breadcrumb[] = [{ text: content.title, path: isAuthenticated ? PATHS.loanCenter : PATHS.home }];

  switch (pathname) {
    case PATHS.homeLoan:
    case PATHS.homeLoanOverview:
    case PATHS.homeLoanTasks:
    case PATHS.homeLoanDetails:
    case PATHS.homeLoanDocuments:
    case PATHS.homeLoanServicing:
      if (homeLoan?.['loan-number']) {
        breadcrumbs.push({ text: `Loan #${homeLoan?.['loan-number']}`, path: pathWithGuid });
      }
      break;
    case PATHS.personalLoan:
      const personalLoanNumber = personalLoan ? getDisplayedPersonalLoanNumber(personalLoan) : undefined;
      if (personalLoanNumber) {
        breadcrumbs.push({ text: `Loan #${personalLoanNumber}`, path: pathWithGuid });
      } else {
        breadcrumbs.push({ text: TITLES.personalLoan, path: pathname });
      }
      break;
    case PATHS.helocLoan:
    case PATHS.helocLoanServicing:
      const helocLoanNumber = helocLoan ? getDisplayedHelocLoanNumber(helocLoan) : undefined;
      if (helocLoanNumber) {
        breadcrumbs.push({ text: `Loan #${helocLoanNumber}`, path: pathWithGuid });
      } else {
        breadcrumbs.push({ text: TITLES.helocLoan, path: pathname });
      }
      break;
    case PATHS.homeValue:
      breadcrumbs.push({ text: TITLES.homeValue, path: pathname });
      break;
    case PATHS.insurance:
      breadcrumbs.push({ text: TITLES.insurance, path: pathname });
      break;
    case PATHS.profile:
      breadcrumbs.push({ text: TITLES.profile, path: pathname });
      break;
    case PATHS.styleguide:
      breadcrumbs.push({ text: TITLES.styleguide, path: pathname });
      break;
  }
  return breadcrumbs;
};
