import { useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';
import Documents from '../../components/loan/documents/Documents';
import DocumentsNew from '../../components/loan/documents/DocumentsNew';
import PreapprovalsView from '../../components/loan/preapprovals/Preapprovals';
import PreapprovalsNew from '../../components/loan/preapprovals/PreapprovalsNew';
import { PATHS, TITLES, linkTo } from '../../components/routes/paths';
import { useFetchDocuments } from '../../features/documents/documentsSlice';
import { isFundedLoan, isTasklistLoan, willNeverHaveDocuments } from '../../features/loans/helpers';
import type { Loan } from '../../features/loans/types';
import {
  useFetchPreapprovals,
  usePreapprovalsEligible,
  usePreapprovalsEnabled,
} from '../../features/preapprovals/preapprovalsSlice';
import { updateUserLoanAttribute } from '../../features/user/userSlice';
import { useAppDispatch } from '../../hooks/hooks';
import { useDocumentTitleLoanPage } from '../../hooks/useDocumentTitle';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { log } from '../../utils/logger';

const DocumentsView = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;
  const dispatch = useAppDispatch();
  const newDocTables = useFeatureEnabled('documents-table');
  const { hasData: hasDocumentsData } = useFetchDocuments(loanGuid);
  const { hasData: hasPreapprovalsData } = useFetchPreapprovals(loanGuid);
  const isLoanEligible = usePreapprovalsEligible(loanGuid);
  const preapprovalEnabled = usePreapprovalsEnabled(loanGuid);
  const shouldShowPreapprovals = preapprovalEnabled && isLoanEligible;
  const updatedDocsLastViewed = useRef(false);
  const pageReady = hasDocumentsData && (!shouldShowPreapprovals || (shouldShowPreapprovals && hasPreapprovalsData));

  useDocumentTitleLoanPage(TITLES.homeLoanDocuments, loan['loan-number']);

  useEffect(() => {
    if (pageReady && !updatedDocsLastViewed.current) {
      updatedDocsLastViewed.current = true;
      dispatch(updateUserLoanAttribute({ loanGuid, value: { 'docs-last-viewed': Date.now() } }));
    }
    return () => {
      dispatch(updateUserLoanAttribute({ loanGuid, value: { 'docs-last-viewed': Date.now() } }));
    }
  }, [dispatch, loanGuid, pageReady]);

  return (
    <>
      {newDocTables ? (
        <>
          <PreapprovalsNew loan={loan} />
          <DocumentsNew loanGuid={loanGuid} />
        </>
      ) : (
        <>
          <PreapprovalsView loan={loan} />
          <Documents loanGuid={loan['loan-guid']} />
        </>
      )}
    </>
  );
};

const WithRedirect = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;
  const canViewDocs = isFundedLoan(loan) || isTasklistLoan(loan);

  if (!canViewDocs) {
    log({ loanGuid, message: 'Loan does not meet document criteria, redirecting back to loan url' });
    return (
      <Redirect
        to={{
          pathname: linkTo(PATHS.homeLoan, { guid: loanGuid }),
        }}
      />
    );
  }

  if (willNeverHaveDocuments(loan)) {
    log({
      loanGuid,
      message: 'Loan was created before documents were available. No documents will exist, redirecting back to loan url',
    });
    return (
      <Redirect
        to={{
          pathname: linkTo(PATHS.homeLoan, { guid: loanGuid }),
        }}
      />
    );
  }

  return <DocumentsView loan={loan} />;
};

export default withTransaction('LoanDocuments', 'page-load')(WithRedirect);
