import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store/types';
import api from '../api';
import { Article, ArticleLink, ArticleLinksQueryParams, ArticleLinksType, ArticleQueryParams, ArticlesEntity, LoanType, ManagedContentState } from './types';
import { LoanMilestones } from '../loans/types';
import { PAGES, PATHS } from '../../components/routes/paths';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useEffect } from 'react';
import { frontendFeatureEnabled } from '../../hooks/useFeatureEnabled';
import { useRouteMatch } from 'react-router-dom';
import { selectLoan } from '../loans/loansSlice';
import { selectAppLanguage } from '../app/appSlice';

const namespace = 'managedContent';

const initialEntityState = {
  data: [],
  hasData: false,
  isFetching: false,
  hasError: false,
};

const initialMilestoneEntityState = {
  [PAGES.homeLoanDetails]: initialEntityState,
  [PAGES.homeLoanDocuments]: initialEntityState,
  [PAGES.homeLoanOverview]: initialEntityState,
  [PAGES.homeLoanServicing]: initialEntityState,
  [PAGES.homeLoanTasks]: initialEntityState,
};

const initialPageEntityState = {
  [LoanMilestones.APPLICATION]: initialMilestoneEntityState,
  [LoanMilestones.PREAPPROVAL]: initialMilestoneEntityState,
  [LoanMilestones.PREAPPROVED]: initialMilestoneEntityState,
  [LoanMilestones.CONDITIONAL_APPROVAL]: initialMilestoneEntityState,
  [LoanMilestones.CONDITIONALLY_APPROVED]: initialMilestoneEntityState,
  [LoanMilestones.CLEAR_TO_CLOSE]: initialMilestoneEntityState,
  [LoanMilestones.CLOSED]: initialMilestoneEntityState,
  [LoanMilestones.FUNDED]: initialMilestoneEntityState,
};

const initialState: ManagedContentState = {
  articlesMap: {
    [LoanType.MORTGAGE]: initialPageEntityState,
    [LoanType.HELOC]: initialPageEntityState,
  },
  articleLinksMap: {
    all: undefined,
    funded: undefined,
    noLoans: undefined,
    loanInProcess: undefined,
  },
};

export const fetchArticles = createAsyncThunk<Article[], ArticleQueryParams>(`${namespace}/fetchArticles`, async params => {
  const resp = await api.getArticles(params);
  return resp.data;
});

export const fetchArticleLinks = createAsyncThunk<ArticleLink[], ArticleLinksQueryParams>(`${namespace}/fetchArticleLinks`, async params => {
  const resp = await api.getArticleLinks(params);
  return resp.data;
});

export const managedContentSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      fetchArticles.pending,
      (
        state,
        {
          meta: {
            arg: { loanMilestone, page, loanType },
          },
        },
      ) => {
        state.articlesMap[loanType][loanMilestone][page].isFetching = true;
        state.articlesMap[loanType][loanMilestone][page].hasError = false;
      },
    );
    builder.addCase(
      fetchArticles.fulfilled,
      (
        state,
        {
          meta: {
            arg: { loanMilestone, page, loanType },
          },
          payload,
        },
      ) => {
        state.articlesMap[loanType][loanMilestone][page].data = payload;
        state.articlesMap[loanType][loanMilestone][page].hasData = true;
        state.articlesMap[loanType][loanMilestone][page].isFetching = false;
      },
    );
    builder.addCase(
      fetchArticles.rejected,
      (
        state,
        {
          meta: {
            arg: { loanMilestone, page, loanType },
          },
        },
      ) => {
        state.articlesMap[loanType][loanMilestone][page].hasError = true;
        state.articlesMap[loanType][loanMilestone][page].isFetching = false;
      },
    );
    builder.addCase(
      fetchArticleLinks.fulfilled,
      (
        state,
        {
          meta: {
            arg: { scenario },
          },
          payload,
        }
      ) => {
        state.articleLinksMap[scenario] = payload;
      }
    )
  },
});

export const selectArticles = (state: RootState, loanMilestone: LoanMilestones, page: PAGES, loanType: LoanType): ArticlesEntity =>
  state.managedContent.articlesMap[loanType]?.[loanMilestone]?.[page] || initialEntityState;

export const selectArticleLinks = (state: RootState, scenario: ArticleLinksType) =>
  state.managedContent.articleLinksMap[scenario];

export const useFetchArticles = (
  loanMilestone: LoanMilestones,
  page: PAGES,
  language: string,
  loanType: LoanType,
): ArticlesEntity => {
  const dispatch = useAppDispatch();
  const articlesState = useAppSelector(state => selectArticles(state, loanMilestone, page, loanType));
  const articlesEnabled = frontendFeatureEnabled('helpful-links');
  const { hasData, hasError, isFetching } = articlesState;

  useEffect(() => {
    if (articlesEnabled && !hasData && !hasError && !isFetching) {
      dispatch(fetchArticles({ loanMilestone, page, language, loanType }));
    }
  }, [articlesEnabled, hasData, hasError, isFetching, dispatch, loanMilestone, page, language, loanType]);

  return articlesState;
};

export const useArticleLinks = (scenario: ArticleLinksType) => {
  const articleLinks = useAppSelector(state => selectArticleLinks(state, scenario));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (articleLinks === undefined) {
      dispatch(fetchArticleLinks({ scenario }));
    }
  }, [dispatch, scenario, articleLinks]);

  return articleLinks;
};

export const useShowHelpfulLinks = () => {
  const pathsWithArticles = [PATHS.homeLoanOverview, PATHS.homeLoanTasks, PATHS.homeLoanDetails, PATHS.homeLoanDocuments];
  const milestonesWithArticles = [
    LoanMilestones.APPLICATION,
    LoanMilestones.PREAPPROVAL,
    LoanMilestones.PREAPPROVED,
    LoanMilestones.CONDITIONAL_APPROVAL,
    LoanMilestones.CONDITIONALLY_APPROVED,
    LoanMilestones.CLEAR_TO_CLOSE,
    LoanMilestones.CLOSED,
  ];
  const routeMatch = useRouteMatch<{ guid: string | undefined }>({ path: pathsWithArticles, exact: true });
  const loanGuid = routeMatch?.params?.guid;
  const loan = useAppSelector((state: RootState) => loanGuid && selectLoan(state, loanGuid));
  const language = useAppSelector(selectAppLanguage);
  return (
    loan && frontendFeatureEnabled('helpful-links') && language !== 'es' && milestonesWithArticles.includes(loan['loan-milestone'])
  );
};

export default managedContentSlice.reducer;
