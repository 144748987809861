import { useEffect } from 'react';
import { withTransaction } from '@elastic/apm-rum-react';
import { updateGlobalLoading } from '../../features/app/appSlice';
import { useAppDispatch } from '../../hooks/hooks';
import submitForm from '../../utils/submitForm';
import { useLocation } from 'react-router-dom';
import { LocationState } from '../../interfaces/ILocationState';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { TITLES } from '../../components/routes/paths';

const Login = () => {
  const location = useLocation<LocationState>();
  const returnTo = location.state?.from?.pathname;
  const dispatch = useAppDispatch();
  useDocumentTitle(TITLES.login);

  useEffect(() => {
    dispatch(updateGlobalLoading(true));
    submitForm({ method: 'get', action: `/okta/login`, fields: returnTo ? { redirect: returnTo } : undefined });
  }, [dispatch, returnTo]);

  return null;
};

export default withTransaction('Login', 'page-load')(Login);