import { BorrowerAgent, Task } from '../../../features/tasks/types';
import AgentTaskForm from './AgentTaskForm';

const AgentFormModalContent = (
  {
    task,
    onNext,
  }:
  {
    task: Task<'borrower-agent'>,
    onNext: (agent: BorrowerAgent) => void;
  }
) => {
  return (
    <div className='flex flex-col'>
      <h2 className='text-2xl text-center mb-5'>Tell us about your real estate agent</h2>
      <p className='text-center mb-5'>Simplify your mortgage process with streamlined communications, quick access to key documents, and shared timelines between yourself, your loan officer, and your agent.</p>
      <p className='text-center mb-5'>We just need to verify a few pieces of information to bring them in the loop.</p>
      <AgentTaskForm task={task} onSubmit={onNext} />
    </div>
  );
};

export default AgentFormModalContent;
