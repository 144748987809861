import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import configReducer from '../features/config/configSlice';
import userReducer from '../features/user/userSlice';
import loansReducer from '../features/loans/loansSlice';
import { tasksReducer } from '../features/tasks';
import { gatelessReducer } from '../features/gateless';
import insuranceReducer from '../features/insurance/insuranceSlice';
import homeValueReducer from '../features/homeValue/homeValueSlice';
import homeSearchReducer from '../features/homeSearch/homeSearchSlice';
import preapprovalsReducer from '../features/preapprovals/preapprovalsSlice';
import appReducer from '../features/app/appSlice';
import documentsReducer from '../features/documents/documentsSlice';
import personalLoansReducer from '../features/personalLoans/personalLoansSlice';
import helocReducer from '../features/helocLoans/helocLoansSlice';
import servicingReducer from '../features/servicing/servicingSlice';
import managedContentReducer from '../features/managedContent/managedContentSlice';
import rateAlertReducer from '../features/rateAlert/rateAlertSlice';

// Keep these in alphabetical order
const combinedReducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  config: configReducer,
  documents: documentsReducer,
  gateless: gatelessReducer,
  helocLoans: helocReducer,
  homeValue: homeValueReducer,
  homeSearch: homeSearchReducer,
  insurance: insuranceReducer,
  loans: loansReducer,
  managedContent: managedContentReducer,
  personalLoans: personalLoansReducer,
  preapprovals: preapprovalsReducer,
  rateAlert: rateAlertReducer,
  servicing: servicingReducer,
  tasks: tasksReducer,
  user: userReducer,
});

const rootReducer = (state: any, action: any) =>
  combinedReducers(action.type === 'auth/logout' ? { config: state.config } : state, action);

export default rootReducer;
