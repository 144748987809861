import { useHistory } from 'react-router-dom';
import Carousel from '../../components/ui/carousel/Carousel';
import { FinancialSolution, FinancialSolutionsContent } from '../financialSolutions/FinancialSolutionsContent';
import cn from 'classnames';
import { linkTo, PATHS } from '../../components/routes/paths';

const getSolutionsWidgets = (solutions: FinancialSolution[]) => {
  const widgets = [];

  for (let i = 0; i < solutions.length; i++) {
    const sol1 = solutions[i];
    if (solutions.length > i + 1) {
      const sol2 = solutions[i + 1];
      widgets.push(
        <div className='keen-slider__slide flex flex-col space-y-6'>
          <SolutionPill solution={sol1} />
          <SolutionPill solution={sol2} />
        </div>
      );
      i++;
    } else {
      widgets.push(
        <div className='keen-slider__slide flex flex-col'>
          <SolutionPill solution={sol1} />
        </div>
      );
    }
  }

  return widgets;
};

export const SolutionPill = ({ solution }: { solution: FinancialSolution }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(linkTo(PATHS.financialSolution, { solutionId: solution.solutionId }));
  };

  return (
    <div className='flex flex-row items-center justify-between rounded' style={{ height: '86px', backgroundColor: '#eee6d9' }} role='button' onClick={handleClick}>
      <div className='flex flex-col px-6'>
        <p className='font-bold'>{solution.title}</p>
        {solution.shortDescription && <p>{solution.shortDescription}</p>}
      </div>
      <div style={{ width: '86px', height: '86px' }}>
        <img style={{ minWidth: '86px', height: '86px' }} className='object-cover rounded-r' src={solution.iconImage} alt={solution.title} />
      </div>
    </div>
  );
};

const DashbaordFinancialSolutions = ({ className }: { className?: string }) => {
  const solutions = Object.values(FinancialSolutionsContent);
  const widgets = getSolutionsWidgets(solutions);
  return (
    <div className={cn(className)}>
      <Carousel items={widgets} showButtons={false} showDots={true} />
    </div>
  );
};

export default DashbaordFinancialSolutions;
