import Colors from '../Colors';
import Grid from '../Grid';
import Icons from '../Icons';
import { ConfigurationNote, NavigationSections } from '../shared';
import BrandElements from './BrandElements';
import CornerRadius from './CornerRadius';
import Illustrations from './Illustrations';
import NavigationStyleguide from './NavigationStyleguide';
import Typography from './Typography';

const navSections = [
  'Colors',
  'Typography',
  'Corner radius',
  'Iconography',
  'Illustrations',
  'Brand elements',
  'Navigation',
  'Grids',
];

const Elements = () => {
  return (
    <div className='elements'>
      <div className='flex justify-center flex-wrap mb-12'>
        <NavigationSections navSections={navSections} />
      </div>

      <div id='Colors'>
        <Colors />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='All hex colors configurable except white' />
        </div>
      </div>

      <div id='Typography'>
        <Typography />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='Font family is configurable' />
        </div>
      </div>

      <div id='Corner radius'>
        <CornerRadius />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='All corner radius options are configurable except "None 0px" and "Full 9999px"' />
        </div>
      </div>

      <div id='Iconography'>
        <div className='mb-6'>
          <Icons />
        </div>
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='None' />
        </div>
      </div>

      <div id='Illustrations'>
        <div className='mt-6 mb-12'>
          <Illustrations />
          <ConfigurationNote message='None. New image files needed if revised.' />
        </div>
      </div>

      <div id='Brand elements'>
        <div className='mt-6 mb-12'>
          <BrandElements />
          <ConfigurationNote message='All logos configurable.' />
        </div>
      </div>

      <div id='Navigation'>
        <div className='mt-6 mb-12'>
          <NavigationStyleguide />
          <ConfigurationNote message='None. Styles and nested components will change based on atoms.' />
        </div>
      </div>

      <div id='Grids'>
        <div className='mt-6 mb-12'>
          <Grid />
        </div>
      </div>

    </div>
  );
};

export default Elements;
