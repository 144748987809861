import { useHistory } from 'react-router-dom';
import { SingleColumnContent } from '../../components/layout/Layout';
import Circle from '../../components/ui/circle/Circle';
import Icon from '../../components/ui/icon/Icon';
import { SolutionPill } from '../dashboard/DashboardFinancialSolutions';
import { FinancialSolutionsContent } from './FinancialSolutionsContent';
import { linkTo, PATHS } from '../../components/routes/paths';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { getConfigFromStore } from '../../features/config/getConfigFromStore';
import cn from 'classnames';

const FinancialSolutionsPills = () => {
  const solutions = Object.values(FinancialSolutionsContent);
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
      {solutions.map((solution, index) => {
        return <SolutionPill key={index} solution={solution} />;
      })}
    </div>
  );
};

export const HomeSearchComponent = ({ className }: { className?: string }) => {
  const history = useHistory();
  const config = getConfigFromStore();
  const isHomeSearchEnabled = useFeatureEnabled('home-search') && config?.links['home-search'];

  if (!isHomeSearchEnabled) {
    return null;
  }

  const handleClick = () => {
    history.push(linkTo(PATHS.homeSearch));
  };

  return (
    <div className={cn(className, 'flex flex-row rounded p-6 bg-tab-active')} role='button' onClick={handleClick}>
      <Circle size='48px' textColor='white' bgColor='action-50'>
        <Icon name='search' size='16px' />
      </Circle>
      <div className='ml-4 flex flex-col'>
        <h1 className='font-bold'>Home Search</h1>
        <p>Find a house that's right for you</p>
      </div>
    </div>
  );
};

const FinancialSolutions = () => {
  return (
    <SingleColumnContent>
      <h1 className='mb-4 mt-2 text-marketing-xs md:text-marketing-md'>Financial solutions</h1>
      <p className='mb-6'>There are a variety of financial solutions that can be tailored to suit your needs.</p>
      <FinancialSolutionsPills />
      <HomeSearchComponent className='mt-6' />
    </SingleColumnContent>
  );
};

export default FinancialSolutions;
