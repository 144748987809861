import { formatPhoneNumber, unformatPhoneNumber } from '../../../../utils/phone';
import FAQs from '../../../ui/faqs/FAQs';
import Tel from '../../../ui/tel/Tel';

export const PersonalLoanFAQs = ({ phoneNumber }: { phoneNumber?: string }) => {
  const tel = phoneNumber ? <Tel tel={unformatPhoneNumber(phoneNumber)} label={formatPhoneNumber(phoneNumber)} /> : null;
  const qAndA = [
    {
      question: 'Where can I find a list of my loan details?',
      answer: (
        <p>
          Click the “Manage account” button within the personal loan details page. The “Manage account” button will take you to your
          web account, where you will be able to view your payment history and loan details, sign up for auto debit, and make
          payments.
        </p>
      ),
    },
    {
      question: 'Why is the “Manage account” button not active yet?',
      answer: (
        <p>
          The “Manage account” button will become active once the loan is funded and ready to be serviced. Once your loan is
          approved, the button will be active within one to three business days.
        </p>
      ),
    },
    {
      question: 'How do I make a one-time payment?',
      answer: (
        <p>
          You may log in to your account by clicking the “Manage account” button on the Personal Loan Detail page. Once you have
          logged in, you will be able to make a payment by clicking “Make a Payment” from your home page.
        </p>
      ),
    },
    {
      question: 'How do I setup automatic payments from my bank account?',
      answer: (
        <p>
          Once you have logged in to your account by clicking the “Manage account” button on the Personal Loans Detail page, you
          will be able to click “Payments” and then “Auto Debit” to enroll your loan(s) in auto debit.
        </p>
      ),
    },
  ];

  if (tel) {
    qAndA.unshift({
      question: 'Who do I contact for customer support regarding my loan(s) once they are funded and in servicing?',
      answer: (
        <p>
          You may reach us by phone at {tel}. Our lines are open from 7:00 am to 8:00 pm (Central) Monday through Friday (excluding
          holidays). All calls with Guaranteed Rate may be monitored or recorded.
        </p>
      ),
    });
  }

  return <FAQs faqs={qAndA} heading='Frequently asked questions' />;
};
