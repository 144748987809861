import Spinner from '../spinner/Spinner';
import { ModalLoadingProps } from './IModal';
import ModalHeader from './ModalHeader';

export const ModalLoading = ({ title, text }: ModalLoadingProps) => {
  title = title || 'Hang tight while we update our records.';
  text = text || 'This may take a few seconds.';
  return (
    <>
      <ModalHeader title={title} subtitle={text} textAlignment='center' />
      <div className='pt-12 pb-6'>
        <Spinner />
      </div>
    </>
  );
};

export default ModalLoading;
