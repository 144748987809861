import { LoanMilestones } from '../../../features/loans/types';
import { LoanMilestoneStep } from './ILoanStatusInfo';

export const LOAN_STATUS_STEPS: LoanMilestoneStep[] = [
  {
    loanMilestone: LoanMilestones.APPLICATION,
    title: 'Application',
    trackerMilestone: null,
  },
  {
    loanMilestone: LoanMilestones.PREAPPROVAL,
    title: 'Pre-approval',
    description: 'Shop for your dream home with your pre-approval letter.',
    trackerMilestone: null,
  },
  {
    loanMilestone: LoanMilestones.PREAPPROVED,
    title: 'Pre-approved',
    description: 'Shop for your dream home with your pre-approval letter.',
    trackerMilestone: LoanMilestones.PREAPPROVED,
    trackerTitle: 'Pre-approved',
    trackerDescription:
      "An early evaluation by a lender of a potential home buyer's credit report plus earnings, savings and debt information.",
  },
  {
    loanMilestone: LoanMilestones.CONDITIONAL_APPROVAL,
    title: 'Approval',
    description: 'Conditional approval milestone description',
    trackerMilestone: LoanMilestones.PREAPPROVAL, // Only pre-approved if a letter exists
    trackerTitle: 'Conditional approval',
    trackerDescription: 'Conditional approval description',
  },
  {
    loanMilestone: LoanMilestones.CONDITIONALLY_APPROVED,
    title: 'Approved',
    description: 'Conditions must be met before your mortgage is approved.',
    trackerMilestone: LoanMilestones.CONDITIONALLY_APPROVED,
    trackerTitle: 'Approved',
    trackerDescription:
      'The Underwriter signed-off on the loan and most of the documentation, but still needs a few more items before fully approving the borrower for the loan.',
  },
  {
    loanMilestone: LoanMilestones.CLEAR_TO_CLOSE,
    title: 'Clear to close',
    description: 'Almost home! Your closing date is being scheduled.',
    trackerMilestone: LoanMilestones.CLEAR_TO_CLOSE,
    trackerTitle: 'Clear to close',
    trackerDescription: `You meet all of your lenders' requirements to qualify for a mortgage, and your mortgage team has been given the green light to move forward with your home loan.`,
  },
  {
    loanMilestone: LoanMilestones.CLOSED,
    title: 'Loan closed',
    description: 'Prepare documents and funds for the closing table.',
    trackerMilestone: LoanMilestones.CLOSED,
    trackerTitle: 'Loan closed',
    trackerDescription: `At closing, you'll sign the remaining mortgage documents and pay your down payment and closing costs.`,
  },
  {
    loanMilestone: LoanMilestones.FUNDED,
    title: 'Funded',
    trackerMilestone: null,
  },
];

export const LOAN_STATUS_MAP = LOAN_STATUS_STEPS.reduce(
  (accumulator: Record<string, LoanMilestoneStep>, step: LoanMilestoneStep): Record<string, LoanMilestoneStep> => {
    accumulator[step.loanMilestone] = step;
    return accumulator;
  },
  {},
);
