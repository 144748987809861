import { selectAppLanguage, reloadAppLanguage } from '../../../features/app/appSlice';
import { selectLanguages } from '../../../features/config/configSlice';
import { useAppSelector } from '../../../hooks/hooks';
import { log } from '../../../utils/logger';
import { isoToName } from '../../../utils/multilingual';
import Button from '../../ui/button/Button';

const LanguageButton = () => {
  const languages = useAppSelector(selectLanguages);
  const appLanguageCode = useAppSelector(selectAppLanguage);
  const languageShown = languages?.filter(lang => lang !== appLanguageCode)?.[0];

  // Disable if languageShown is not set
  if (!languageShown) return null;

  const handleClick = () => {
    log({ level: 'info', message: `LanguageButton: User clicked language toggler ${JSON.stringify({ currentLanguage: appLanguageCode, selectedLanguage: languageShown })}` });
    reloadAppLanguage(languageShown);
  };

  return (
    <Button buttonType='subnav' size='medium' onClick={handleClick} className='OneLinkNoTx -mr-3'>
      <span className='bold text-sm'>{isoToName(languageShown) || languageShown}</span>
    </Button>
  );
};

export default LanguageButton;
