export const ucfirst = (str: string) => str[0].toUpperCase() + str.slice(1);
export const lcfirst = (str: string) => str[0].toLowerCase() + str.slice(1);
export const uppercase = (str: string) => str.toUpperCase();
export const lowercase = (str: string) => str.toLowerCase();
export const format = (formatString?: string, ...args: (string | undefined)[]) => {
  let newString = formatString;
  if (newString) {
    for (const arg of args) {
      if (arg) {
        newString = newString.replace('%s', arg);
      }
    }
  }
  return newString;
};

export const capitalize = (str: string) =>
  str
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
    .join(' ');

// mimic lodash lowerCase https://lodash.com/docs/4.17.15#lowerCase
export const lowerCase = (str: string) => {
  if (!str) return '';

  const charArray = Array.from(str);
  const strArray = [];

  for (let i = 0; i < charArray.length; i++) {
    const char = charArray[i];
    if (!isAlpha(char)) {
      const lastIndex = i - 1;
      if (lastIndex >= 0 && isAlpha(charArray[lastIndex])) {
        strArray.push(' ');
      }
    } else if (isUpper(char)) {
      const lastIndex = i - 1;
      if (lastIndex >= 0 && isLower(charArray[lastIndex])) {
        strArray.push(' ');
      }
      strArray.push(char.toLowerCase());
    } else {
      strArray.push(char);
    }
  }
  const retval = strArray.join('').trim();
  return retval;
};

const isUpper = (ch: string) => {
  return ch >= 'A' && ch <= 'Z';
};

const isLower = (ch: string) => {
  return ch >= 'a' && ch <= 'z';
};

const isAlpha = (ch: string) => {
  return isUpper(ch) || isLower(ch);
};
