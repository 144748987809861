import { useEffect } from 'react';
import { content } from '../features/tenant/tenant';

const getDocumentTitle = (pageTitle: string | null) => {
  const appTitle = `${content.title} | ${content.company}`;
  return `${pageTitle ? `${pageTitle} - ` : ''}${appTitle}`;
};

const getLoanTitle = (title: string | null, loanNumber: string): string => {
  return loanNumber ? `${title ? `${title} - ` : ''}Loan #${loanNumber}` : title ? title : '';
};

export const useDocumentTitle = (pageTitle: string | null) => {
  useEffect(() => {
    if (pageTitle || pageTitle === null) {
      document.title = getDocumentTitle(pageTitle);
    }
  }, [pageTitle]);
};

export const useDocumentTitleLoanPage = (pageTitle: string | null, loanNumber: string) => {
  const title = getLoanTitle(pageTitle, loanNumber);
  useDocumentTitle(title);
};

export default useDocumentTitle;
