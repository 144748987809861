import { useState, useRef, memo, useEffect, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import useInterval from '../../../hooks/useInterval';
import { useUser } from '../../../features/user/userSlice';
import useAnimationFrame from '../../../hooks/useAnimationFrame';
import phrases from './phrases';
import Modal from '../../ui/modal/Modal';
import { log } from '../../../utils/logger';
import { ReactComponent as HouseGraphic } from '../../../images/house-sprite.svg';
import { ReactComponent as Online } from '../../../images/status-online.svg';
import Hailey from '../../../images/assistant-hailey.png';
import './WaitingForTasks.scss';
import msToMinutesSeconds from '../../../utils/msToMinutesSeconds';
import { sendEvent } from '../../../features/analytics/sendEvent';
import timestamp from '../../../utils/timestamp';
import timestampDifference from '../../../utils/timestampDifference';
import { ImagePreloader } from '../../ui/image/ImagePreloader';
import cn from 'classnames';

const SETTINGS = {
  contentDuration: 3300, // 44 phrases x 3.3 secs ~= 145 seconds or 2.42 minutes
  houseDuration: 750,
  numHouseFrames: 9,
};

const ContentPhrase = ({ show, phrase, className }: { show: boolean; phrase: string; className?: string }) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition in={show} timeout={500} classNames='animating-text' nodeRef={nodeRef}>
      <div className={cn('animating-text text-center mx-auto text-marketing-xs', className)} ref={nodeRef}>
        {phrase}
      </div>
    </CSSTransition>
  );
};

const MemoizedContentPhrase = memo(ContentPhrase);

const House = () => {
  const [frame, setFrame] = useState(1);
  const timer = useRef(0);
  const reversed = useRef(false);

  const animation = useCallback(deltaTime => {
    timer.current = timer.current + deltaTime;

    if (timer.current >= SETTINGS.houseDuration) {
      timer.current = 0;

      setFrame(frame => {
        if (frame === 1) {
          reversed.current = false;
        } else if (frame === SETTINGS.numHouseFrames) {
          reversed.current = true;
        }
        return reversed.current ? frame - 1 : frame + 1;
      });
    }
  }, []);

  useAnimationFrame(animation);

  return (
    <div className='waiting-for-tasks__house'>
      <svg className='svg-sprite'>
        <use xlinkHref={`#house-${frame}`}></use>
      </svg>
    </div>
  );
};

const Help = ({ show }: { show: boolean }) => {
  const user = useUser();
  const nodeRef = useRef(null);
  return (
    <CSSTransition in={show} timeout={300} classNames='assistant-help' nodeRef={nodeRef}>
      <div className='assistant-help' ref={nodeRef}>
        <div className='assistant-help__avatar'>
          <img alt='Avatar of Assistant' src={Hailey} width='72' height='72' className='photo photo-rounded' />
          <div className='assistant-help__status'>
            <Online />
          </div>
        </div>
        <div className='assistant-help__text'>
          <p>
            {user?.name?.first && (
              <>
                Hi <span className='OneLinkNoTx'>{user.name.first}! </span>
              </>
            )}
            I'm Hailey!
          </p>
          <p>Sorry this is taking longer than usual.</p>
          <p>Please check back in 10 minutes or so when your account should be all set!</p>
        </div>
      </div>
    </CSSTransition>
  );
};

const MemoizedHelp = memo(Help);

export const WaitingContent = () => {
  const [phraseId, setPhraseId] = useState(0);
  const [showHelp, setShowHelp] = useState(false);
  const animationShownTimeRef = useRef(timestamp());
  const shouldShowHelp = phraseId === phrases.length - 1;

  const [clear] = useInterval(() => {
    if (phraseId >= phrases.length - 1) {
      clear();
    } else {
      setPhraseId(phraseId => phraseId + 1);
    }
  }, SETTINGS.contentDuration);

  useEffect(() => {
    if (!showHelp && shouldShowHelp) {
      setShowHelp(true);
    }
  }, [showHelp, shouldShowHelp]);

  useEffect(() => {
    if (showHelp) {
      const duration = msToMinutesSeconds(timestampDifference(timestamp(), animationShownTimeRef.current));
      log({
        message: `WaitingForTasks: Help assistant is shown. User has been waiting for ${duration} minutes.`,
      });
      sendEvent('WaitingForTasks: helpAssistantShown', { duration });
    }
  }, [showHelp]);

  useEffect(() => {
    if (phraseId) {
      const duration = msToMinutesSeconds(timestampDifference(timestamp(), animationShownTimeRef.current));
      log({
        message: `WaitingForTasks: Phrase ${phraseId} shown. User has been waiting for ${duration} minutes.`,
      });
    }
  }, [phraseId]);

  return (
    <div className='waiting-for-tasks'>
      <div className='animating-text-container waiting-for-tasks__content mx-auto'>
        {phrases.map((phrase, i) => {
          return <MemoizedContentPhrase phrase={phrase.text} className={phrase.fontClass} show={i === phraseId} key={i} />;
        })}
      </div>
      <House />
      <MemoizedHelp show={showHelp} />
    </div>
  );
};

export const WaitingForTasks = ({ show, onClosed }: { show: boolean; onClosed?: () => void }) => {
  const animationShownTimeRef = useRef<string>();

  const handleOnClosed = () => {
    const duration = animationShownTimeRef.current ? msToMinutesSeconds(timestampDifference(timestamp(), animationShownTimeRef.current)) : 0;
    if (duration) {
      log({ message: `WaitingForTasks: Waiting animation is hidden after ${duration} minutes` });
      sendEvent('WaitingForTasks: waitingAnimationHidden', { duration });
    }
    onClosed?.();
  };

  const handleUnload = () => {
    const duration = animationShownTimeRef.current ? msToMinutesSeconds(timestampDifference(timestamp(), animationShownTimeRef.current)) : 0;
    if (duration) {
      log({ message: `WaitingForTasks: User abandoned the web page after ${duration} minutes` });
      sendEvent('WaitingForTasks: waitingAnimationAbandoned', { duration });
    }
    return null;
  };

  const handleOnOpen = () => {
    animationShownTimeRef.current = timestamp();
    log({ message: 'WaitingForTasks: Waiting animation is shown' });
    sendEvent('WaitingForTasks: waitingAnimationShown');
  };

  useEffect(() => {
    window.onbeforeunload = show ? handleUnload : null;
    return () => {
      window.onbeforeunload = null;
    };
  }, [show]);

  return (
    <>
      <ImagePreloader>
        <HouseGraphic />
      </ImagePreloader>
      <Modal
        contentLabel='Waiting for Tasklist'
        open={show}
        closeable={false}
        onOpen={handleOnOpen}
        onClosed={handleOnClosed}
        id='modal-waiting-for-tasks'
        overlayClassName='overlay-waiting-for-tasks'
        bodyOpenClassName='active-waiting-for-tasks'
      >
        <WaitingContent />
      </Modal>
    </>
  );
};
