import { getStorage, removeStorage, setStorage } from './localStore';
import type { IStorageKey } from './localStore';

// GET

export const get = <ValueType>(prefix: string, key: IStorageKey, defaultValue?: ValueType): ValueType | undefined =>
  getStorage<ValueType>(window.sessionStorage, prefix, key, defaultValue);

// SET

export const set = <ValueType = any>(prefix: string, key: IStorageKey, value: ValueType): ValueType =>
  setStorage<ValueType>(window.sessionStorage, prefix, key, value);

// DELETE

export const remove = (prefix: string, key: IStorageKey): void => removeStorage(window.sessionStorage, prefix, key);
