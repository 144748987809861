import { useEffect, useRef } from 'react';
import { withTransaction } from '@elastic/apm-rum-react';
import { log } from '../../utils/logger';
import {
  selectFundedPersonalLoanIds,
  selectPersonalLoanIds,
  selectPersonalLoansHasError,
  selectPersonalLoansHasFetchFailure,
  selectProcessingPersonalLoanIds,
} from '../../features/personalLoans/personalLoansSlice';
import { useAppSelector } from '../../hooks/hooks';
import { useCurrentLo } from '../../hooks/useCurrentLo';
import Card from '../../components/ui/card/Card';
import { Skeleton, SkeletonGroup } from '../../components/ui/skeleton/Skeleton';
import {
  selectFundedHELOCLoanIds,
  selectHELOCLoansHasError,
  selectHELOCLoansHasFetchFailure,
  selectProcessingHELOCLoanIds,
} from '../../features/helocLoans/helocLoansSlice';
import Alert from '../../components/ui/alert/Alert';
import { content } from '../../features/tenant/tenant';
import { sendEvent } from '../../features/analytics/sendEvent';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { LoanOfficerCard } from '../../components/loan/support/LoanOfficerCard';
import { EmailButton } from '../../components/ui/button/EmailButton';
import { PhoneButton } from '../../components/ui/button/PhoneButton';
import { SingleColumnContent } from '../../components/layout/Layout';
import {
  selectFundedLoanGuids,
  selectProcessingLoanGuids,
  selectFundedAlpHELOCLoanGuids,
  selectProcessingAlpHELOCLoanGuids,
  selectProcessingCESLoanGuids,
  selectFundedCESLoanGuids,
  selectProcessingMiscLoanGuids,
  selectFundedMiscLoanGuids,
} from '../../features/loans/loansSlice';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { TITLES } from '../../components/routes/paths';
import { LoanOfficer } from '../../features/loans/types';
import { RateAppAd3 } from '../../components/rateAppAd/RateAppAd';
import { useMobileAppMode } from '../../components/embedMode/EmbedMode';
import { useIsLoadingLoans } from '../../hooks/useIsLoadingLoans';
import { DashboardHomeLoanCard } from '../../components/loan/loanCard/DashboardHomeLoanCard';
import { DashboardCESLoanCard } from '../../components/loan/loanCard/DashboardCESLoanCard';
import { DashboardAlpHELOCLoanCard } from '../../components/loan/loanCard/DashboardAlpHELOCLoanCard';
import { DashboardHELOCLoanCard } from '../../components/loan/loanCard/DashboardHELOCLoanCard';
import { DashboardPersonalLoanCard } from '../../components/loan/loanCard/DashboardPersonalLoanCard';

type LoanFetchErrorEvent = 'personalLoans' | 'helocLoans';

export const LoanCardSkeletonLoader = () => (
  <Card className='flex mb-8'>
    <div className='flex flex-col w-full px-4'>
      <Skeleton className='mb-2 w-24 lg:w-32' />
      <Skeleton className='mb-2 w-full lg:w-96' style={{ maxWidth: '240px' }} />
      <SkeletonGroup className='mt-2 lg:gap-6 w-80 lg:w-108' columnClassName='w-40 lg:w-full' columnCount={2} wrapMobile={true} />
    </div>
  </Card>
);

const LoanFetchError = ({ loanType, eventName }: { loanType: string; eventName: LoanFetchErrorEvent }) => (
  <Alert type='error' showClose={false} className='mb-4'>
    <p className='body-small text-base'>
      {loanType} are not loading. If the issue persists,{' '}
      <EmailButton
        buttonType='inline'
        label='email'
        email={content.personalLoans.errorEmail}
        onClick={() => sendEvent(`${eventName}EmailClick`, { email: content.personalLoans.errorEmail })}
      />{' '}
      or{' '}
      <PhoneButton
        buttonType='inline'
        label='call'
        phone={content.personalLoans.errorPhone}
        onClick={() => sendEvent(`${eventName}PhoneClick`, { phone: content.personalLoans.errorPhone })}
      />{' '}
      for help.
    </p>
  </Alert>
);

const LoanCenterErrors = () => {
  const personalLoansHasError = useAppSelector(selectPersonalLoansHasError);
  const helocLoansHasError = useAppSelector(selectHELOCLoansHasError);
  const helocLoansHasFetchFailure = useAppSelector(selectHELOCLoansHasFetchFailure);
  const personalLoansHasFetchFailure = useAppSelector(selectPersonalLoansHasFetchFailure);

  if (!personalLoansHasError && !helocLoansHasError) return null;

  return (
    <div className='mt-8'>
      {personalLoansHasError && !personalLoansHasFetchFailure && (
        <LoanFetchError loanType='Personal loans' eventName='personalLoans' />
      )}
      {helocLoansHasError && !helocLoansHasFetchFailure && <LoanFetchError loanType='HELOC loans' eventName='helocLoans' />}
      {personalLoansHasFetchFailure && <LoanFetchError loanType='Some personal loans' eventName='personalLoans' />}
      {helocLoansHasFetchFailure && <LoanFetchError loanType='Some HELOC loans' eventName='helocLoans' />}
    </div>
  );
};

let hasLoggedNoLoansFound = false;
const NoLoansFound = () => {
  useEffect(() => {
    if (!hasLoggedNoLoansFound) {
      hasLoggedNoLoansFound = true;
      log({ message: 'No loans found shown to user', level: 'warn' });
    }
  }, []);

  return (
    <>
      <div className='flex flex-col items-center'>
        <img src='/images/error-found.svg' width='72' height='72' alt='Magnifying glass' />
        <h1 className='text-2xl mt-8 mb-4'>We could not find any loans.</h1>
        <p className='text-center'>
          Your team may be setting up your loan, so please check back later. If this issue persists, please contact us at{' '}
          <EmailButton email={content.supportEmail} className='OneLinkNoTx' /> or{' '}
          <PhoneButton phone={content.supportPhone} suffix={content.supportPhoneOption} /> for assistance.
        </p>
      </div>
    </>
  );
};

const LoansList = () => {
  // Mortgage loans
  const processinghomeLoanIds = useAppSelector(selectProcessingLoanGuids);
  const fundedhomeLoanIds = useAppSelector(selectFundedLoanGuids);

  // CES loans
  const processingCESLoanIds = useAppSelector(selectProcessingCESLoanGuids);
  const fundedCESLoanIds = useAppSelector(selectFundedCESLoanGuids);

  // Personal loans
  const personalLoansEnabled = useFeatureEnabled('personal-loans');
  const personalLoanIds = useAppSelector(selectPersonalLoanIds);
  const fundedPersonalLoanIds = useAppSelector(selectFundedPersonalLoanIds);
  const processingPersonalLoanIds = useAppSelector(selectProcessingPersonalLoanIds);

  // ALP HELOC
  const processingAlpHELOCLoanIds = useAppSelector(selectProcessingAlpHELOCLoanGuids);
  const fundedAlpHELOCLoanIds = useAppSelector(selectFundedAlpHELOCLoanGuids);

  // HELOC loans
  const helocLoansEnabled = useFeatureEnabled('heloc-loans');
  const processingHELOCLoanIds = useAppSelector(selectProcessingHELOCLoanIds);
  const fundedHELOCLoanIds = useAppSelector(selectFundedHELOCLoanIds);

  // Misc loans
  const processingMiscLoanIds = useAppSelector(selectProcessingMiscLoanGuids);
  const fundedMiscLoanIds = useAppSelector(selectFundedMiscLoanGuids);

  useEffect(() => {
    !processinghomeLoanIds?.length && log({ message: 'No processing home loans found' });
  }, [processinghomeLoanIds]);

  useEffect(() => {
    !fundedhomeLoanIds?.length && log({ message: 'No funded home loans found' });
  }, [fundedhomeLoanIds]);

  useEffect(() => {
    !processingCESLoanIds?.length && log({ message: 'No processing CES loans found' });
  }, [processingCESLoanIds]);

  useEffect(() => {
    !fundedCESLoanIds?.length && log({ message: 'No funded CES loans found' });
  }, [fundedCESLoanIds]);

  useEffect(() => {
    personalLoansEnabled && !personalLoanIds?.length && log({ message: 'No personal loans found' });
  }, [personalLoansEnabled, personalLoanIds]);

  useEffect(() => {
    !processingAlpHELOCLoanIds?.length && log({ message: 'No processing ALP HELOC loans found' });
  }, [processingAlpHELOCLoanIds]);

  useEffect(() => {
    !fundedAlpHELOCLoanIds?.length && log({ message: 'No funded ALP HELOC loans found' });
  }, [fundedAlpHELOCLoanIds]);

  useEffect(() => {
    helocLoansEnabled && !processingHELOCLoanIds?.length && log({ message: 'No processing HELOC loans found' });
  }, [helocLoansEnabled, processingHELOCLoanIds]);

  useEffect(() => {
    helocLoansEnabled && !fundedHELOCLoanIds?.length && log({ message: 'No funded HELOC loans found' });
  }, [helocLoansEnabled, fundedHELOCLoanIds]);

  useEffect(() => {
    processingMiscLoanIds?.length && log({ message: `Unknown processing loan types found ${processingMiscLoanIds.join()}` });
  }, [processingMiscLoanIds]);

  useEffect(() => {
    fundedMiscLoanIds?.length && log({ message: `Unknown funded loan types found ${fundedMiscLoanIds.join()}` });
  }, [fundedMiscLoanIds]);

  const hasProcessingLoans =
    !!processinghomeLoanIds.length ||
    !!processingCESLoanIds.length ||
    !!processingHELOCLoanIds.length ||
    !!processingPersonalLoanIds.length ||
    !!processingAlpHELOCLoanIds.length ||
    !!processingMiscLoanIds.length
    ;
  const hasFundedLoans =
    !!fundedhomeLoanIds.length ||
    !!fundedCESLoanIds.length ||
    !!fundedHELOCLoanIds.length ||
    !!fundedPersonalLoanIds.length ||
    !!fundedAlpHELOCLoanIds.length ||
    !!fundedMiscLoanIds.length
    ;

  return (
    <div className='loans-list'>
      {hasProcessingLoans && (
        <>
          <h1 className='text-marketing-xs md:text-marketing-md mb-6'>Loans in process</h1>
          <ul className='space-y-8'>
            {processinghomeLoanIds.map(id => {
              return (
                <li className='loan' key={id}>
                  <DashboardHomeLoanCard loanGuid={id} />
                </li>
              );
            })}
            {processingCESLoanIds.map(id => {
              return (
                <li className='loan' key={id}>
                  <DashboardCESLoanCard loanGuid={id} />
                </li>
              );
            })}
            {processingAlpHELOCLoanIds.map(id => {
              return (
                <li className='loan' key={id}>
                  <DashboardAlpHELOCLoanCard loanGuid={id} />
                </li>
              );
            })}
            {processingHELOCLoanIds.map(id => {
              return (
                <li className='loan' key={id}>
                  <DashboardHELOCLoanCard loanGuid={id} />
                </li>
              );
            })}
            {processingPersonalLoanIds.map(id => {
              return (
                <li className='loan' key={id}>
                  <DashboardPersonalLoanCard loanGuid={id} />
                </li>
              );
            })}
            {processingMiscLoanIds.map(id => {
              return (
                <li className='loan' key={id}>
                  <DashboardHomeLoanCard loanGuid={id} />
                </li>
              );
            })}
          </ul>
        </>
      )}
      {hasFundedLoans && (
        <>
          <h1 className='text-marketing-xs md:text-marketing-md mb-6 mt-12 first:mt-0'>Funded loans</h1>
          <ul className='space-y-8'>
            {fundedhomeLoanIds.map(id => {
              return (
                <li className='loan' key={id}>
                  <DashboardHomeLoanCard loanGuid={id} />
                </li>
              );
            })}
            {fundedCESLoanIds.map(id => {
              return (
                <li className='loan' key={id}>
                  <DashboardCESLoanCard loanGuid={id} />
                </li>
              );
            })}
            {fundedAlpHELOCLoanIds.map(id => {
              return (
                <li className='loan' key={id}>
                  <DashboardAlpHELOCLoanCard loanGuid={id} />
                </li>
              );
            })}
            {fundedHELOCLoanIds.map(id => {
              return (
                <li className='loan' key={id}>
                  <DashboardHELOCLoanCard loanGuid={id} />
                </li>
              );
            })}
            {fundedPersonalLoanIds.map(id => {
              return (
                <li className='loan' key={id}>
                  <DashboardPersonalLoanCard loanGuid={id} />
                </li>
              );
            })}
            {fundedMiscLoanIds.map(id => {
              return (
                <li className='loan' key={id}>
                  <DashboardHomeLoanCard loanGuid={id} />
                </li>
              );
            })}
          </ul>
        </>
      )}
      {!hasProcessingLoans && !hasFundedLoans && <NoLoansFound />}
      <LoanCenterErrors />
    </div>
  );
};

export const RateAppCallout = () => {
  const mobileApp = useMobileAppMode();
  const enabled = useFeatureEnabled('rate-app-ad');
  if (!enabled || mobileApp) return null;
  return (
    <div className='mt-12'>
      <RateAppAd3 location='loanCenter' />
    </div>
  );
};

const HomeLoanTeam = () => {
  const { lo } = useCurrentLo();
  const hasLoggedHomeLoanTeam = useRef<boolean>(false);

  useEffect(() => {
    if (lo && (!lo.email || lo.status === 'inactive')) {
      const empId = lo['emp-id'];
      if (!hasLoggedHomeLoanTeam.current) {
        hasLoggedHomeLoanTeam.current = true;
        log({
          level: 'info',
          message: `HomeLoanTeam: Current loan officer exists but is missing required data or is inactive ${JSON.stringify(
            (({ status, email, name }) => ({ empId, status, email, name }))(lo),
          )}`,
        });
      }
    }
  }, [lo]);

  if (!lo || !lo.email || lo.status === 'inactive') return null;

  return (
    <div className='mt-12'>
      <h2 className='text-marketing-xs md:text-marketing-md mb-6'>Your preferred home-buying team</h2>
      <LoanOfficerCard loanOfficer={lo as LoanOfficer} />
    </div>
  );
};

const Loans = () => {
  useDocumentTitle(TITLES.loanCenter);
  const showSkeletonLoader = useIsLoadingLoans();
  return (
    <SingleColumnContent>
      {showSkeletonLoader ? (
        <LoanCardSkeletonLoader />
      ) : (
        <>
          <LoansList />
          <RateAppCallout />
          <HomeLoanTeam />
        </>
      )}
    </SingleColumnContent>
  );
};

export default withTransaction('Loans', 'page-load')(Loans);
