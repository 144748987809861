import ReactModal from 'react-modal';
import Button from '../button/Button';
import './SlidingDrawer.scss';
import Icon from '../icon/Icon';
import { SlidingDrawerProps } from './iSlidingDrawer';
import { useEffect, useRef } from 'react';
import nextId from '../../../utils/nextId';

// Must match --sliding-drawer-transition-duration defined in src/components/ui/sliding-drawer/SlidingDrawer.scss
const TRANSITION_DURATION = 200;

export const SlidingDrawer = ({
  id,
  isOpen,
  containerId,
  children,
  title,
  buttonProps,
  handleClose,
  shouldCloseOnOutsideClick,
}: SlidingDrawerProps) => {
  const { text: buttonText, children: buttonChildren, ...restProps } = buttonProps;
  const idRef = useRef(id || nextId('sliding_drawer_'));
  const container = containerId ? document.getElementById(containerId) : undefined;

  useEffect(() => {
    if (!shouldCloseOnOutsideClick) return;
    const eventListener = (evt: MouseEvent): void => {
      const target = evt.target as HTMLElement;
      if (isOpen && container !== target && !container?.contains(target)) {
        handleClose?.();
      }
    };
    window.addEventListener('click', eventListener);
    return () => {
      window.removeEventListener('click', eventListener);
    };
  }, [isOpen, handleClose, container, shouldCloseOnOutsideClick]);

  return (
    <div className='sliding-drawer-wrapper'>
      <Button {...restProps}>{buttonChildren || buttonText}</Button>
      <ReactModal
        id={idRef.current}
        isOpen={isOpen}
        closeTimeoutMS={TRANSITION_DURATION}
        onRequestClose={handleClose}
        {...(container && { parentSelector: () => container })}
        className={{
          base: 'Sliding__Drawer',
          afterOpen: 'Sliding__Drawer--after-open',
          beforeClose: 'Sliding__Drawer--before-close',
        }}
        overlayClassName={{
          base: 'Sliding__Drawer__Overlay',
          afterOpen: 'Sliding__Drawer__Overlay--after-open',
          beforeClose: 'Sliding__Drawer__Overlay--before-close',
        }}
        htmlOpenClassName={'Sliding__Drawer__Html--open'}
        bodyOpenClassName={'Sliding__Drawer__Body--open'}
      >
        <div className='flex flex-col h-full'>
          <div className='bg-body-100 p-6 sticky top-0'>
            <div className='flex justify-between'>
              <p className='font-bold text-white text-2xl'>{title}</p>
              <Button
                className='text-white hover:text-white'
                buttonType='icon'
                icon={<Icon name='close' size='1rem' />}
                rotate={true}
                text='Close menu'
                onClick={handleClose}
              />
            </div>
          </div>
          <div className='flex-grow overflow-y-auto w-full'>
            {children}
          </div>
        </div>
      </ReactModal>
    </div>
  );
};
