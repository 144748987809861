import Modal from '../../../ui/modal/Modal';
import ModalFooter from '../../../ui/modal/ModalFooter';
import Circle from '../../../ui/circle/Circle';
import Button from '../../../ui/button/Button';
import { usdAmount } from '../../../../utils/amount';
import { ImagePreloader } from '../../../ui/image/ImagePreloader';

export const PreapprovedCongratsModalContent = ({ amount, onClose }: { amount?: number; onClose: () => void }) => (
  <>
    <header className='text-center text-subheadline'>
      <h1>Congratulations!</h1>
      {amount ? <h1>You're pre-approved for {usdAmount(amount, false, true)}!</h1> : <h1>You're pre-approved!</h1>}
    </header>
    <p className='text-center my-4'>
      We're excited to provide you with a pre-approval that will allow you to make an offer on a home. Your{' '}
      <span className='whitespace-nowrap'>pre-approval</span> letter will be available in your documents portal shortly.
    </p>
    <div className='flex flex-col lg:flex-row pb-8 lg:pb-0'>
      <div className='flex justify-center flex-1'>
        <img width='272' height='244' src='/images/checklist-success.svg' alt='' />
      </div>
      <div className='flex flex-col gap-y-4 flex-1'>
        <h2>What's next?</h2>
        <div className='flex flex-row gap-x-4'>
          <Circle size="1.875rem" bgColor='info-10' textColor='black' className='flex-shrink-0'>
            1
          </Circle>
          <p>We'll keep your to-do list up to date with everything needed.</p>
        </div>
        <div className='flex flex-row gap-x-4'>
          <Circle size="1.875rem" bgColor='info-10' textColor='black' className='flex-shrink-0'>
            2
          </Circle>
          <p>As you complete tasks, they will move from incomplete to complete.</p>
        </div>
        <div className='flex flex-row gap-x-4'>
          <Circle size="1.875rem" bgColor='info-10' textColor='black' className='flex-shrink-0'>
            3
          </Circle>
          <p>We start to review after each task is completed.</p>
        </div>
      </div>
    </div>
    <ModalFooter>
      <Button onClick={onClose} buttonType='primary' size='large'>
        Let's get started
      </Button>
    </ModalFooter>
  </>
);

export const PreapprovedCongratsModal = ({
  amount,
  open,
  onOpen,
  onRequestClose,
}: {
  amount?: number;
  open: boolean;
  onOpen: () => void;
  onRequestClose: () => void;
}) => (
  <>
    <ImagePreloader>
      <img src='/images/checklist-success.svg' alt='' />
    </ImagePreloader>
    <Modal open={open} onOpen={onOpen} onRequestClose={onRequestClose} contentLabel='Preapproved Modal'>
      <PreapprovedCongratsModalContent amount={amount} onClose={onRequestClose} />
    </Modal>
  </>
);

export default PreapprovedCongratsModal;
