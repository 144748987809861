import Button from '../../../components/ui/button/Button';
import Recommendations from '../../../components/ui/recommendations/Recommendations';

const InsightsPanel = () => {
  return (
    <>
      <div className='insights-panel'>
        <h2 className='header-medium mb-8'>Insights panel</h2>
        <div className='insights-panel-default-container mb-12 flex' style={{ marginRight: '100px' }}>
          <div className='insights-panel-default mr-12' style={{ width: '250px' }}>
            <h3 className='header-small'>Right rail</h3>
            <div style={{ width: '224px' }}>
              <Recommendations title='Insights'>
                Dolor purus non enim praesent elementum. Nulla aliquet porttitor lacus luctus accumsan.
              </Recommendations>
            </div>
            <div className='body-small italic mt-4'>
              Insights should have a link or text control that directs the user to something that could help. In rare cases, a
              secondary button may be used.
            </div>
          </div>
          <div className='insights-panel-default'>
            <h3 className='header-small'>Center content area</h3>
            <div style={{ maxWidth: '100%', minWidth: '224px' }}>
              <Recommendations title='Insights'>
                Dolor purus non enim praesent elementum. Nulla aliquet porttitor lacus luctus accumsan.
              </Recommendations>
            </div>
            <div className='body-small italic mt-4'>
              An alternative way to depict an insight that calls more attention to the information. This is also the how the panel
              should appear when the the space in the right rail gets too small. All insights move the the center content area in
              mobile.
            </div>
          </div>
        </div>

        <div className='insights-panel-marketing-container flex' style={{ marginRight: '100px' }}>
          <div className='insights-panel-marketing mr-12' style={{ width: '100%' }}>
            <h3 className='header-small'>Right rail - marketing/cross-sells</h3>
            <div style={{ width: '224px' }}>
              <Recommendations title='Headline'>
                <div>Dolor purus non enim praesent elementum. Nulla aliquet porttitor lacus luctus accumsan.</div>
                <Button buttonType='secondary' title='Control label' size='medium' className='mt-4 w-full'>
                  Control Label
                </Button>
              </Recommendations>
            </div>
            <div className='body-small italic mt-4'>
              A secondary button may be used when the content is marketing related or a cross-sell. No more than 1 marketing insight
              in the rail at a time.
            </div>
          </div>
          <div className='insights-panel-marketing'>
            <h3 className='header-small'>Center content area - marketing/cross-sells</h3>
            <div style={{ maxWidth: '100%', minWidth: '224px' }}>
              <Recommendations title='Insights'>
                <div>Dolor purus non enim praesent elementum. Nulla aliquet porttitor lacus luctus accumsan.</div>
                <div>
                  <Button buttonType='secondary' title='Control label' size='medium' className='mt-4'>
                    Control Label
                  </Button>
                </div>
              </Recommendations>
            </div>
            <div className='body-small italic mt-4'>
              An alternative way to depict a marketing insight or cross-sell that calls more attention to the information. This is
              also the how the panel should appear when the the space in the right rail gets too small. All insights move the the
              center content area in mobile.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsightsPanel;
