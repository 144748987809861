import { createContext, useContext, useEffect } from 'react';

export interface RouteState {
  name?: string;
  subview?: string;
}

interface RouteContextState {
  routeState: RouteState;
  setRouteState?: (state: RouteState) => void;
}

export const initialRouteState: RouteState = {
  name: undefined,
  subview: undefined,
};

export const RouteContext = createContext<RouteContextState>({
  routeState: initialRouteState,
  setRouteState: undefined,
});

export const useSetRouteState = ({ name, subview }: Partial<RouteState>) => {
  const { routeState, setRouteState } = useContext(RouteContext);

  useEffect(() => {
    if (name === routeState.name) {
      setRouteState?.({ ...routeState, ...{ subview } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeState.name, name, subview]);
};
