import type { Loan, LoanFeatures } from './types';
import { LoanMilestones } from './types';

export const isFundedLoan = (loan: Loan): boolean => loan['loan-milestone'] === LoanMilestones.FUNDED;

export const willNeverHaveDocuments = (loan: Loan): boolean => isFundedLoan(loan) && loan['no-documents?'] === true;

export const isTasklistLoan = (loan: Loan): boolean =>
  !isFundedLoan(loan) && ['completed', 'not-editable'].includes(loan['application-status']);

export const isApplicationLoan = (loan: Loan): boolean =>
  !isFundedLoan(loan) && ['editable', 'timed-out'].includes(loan['application-status']);

export const isLoanDigitalMortgage = (loan: Loan): boolean => !!loan['digital-mortgage?'];

export const isPreapprovalsEnabled = (loan: Loan): boolean => !!loan['show-preapproval-letters?'];

export const preapprovalsMightExist = (loan: Loan): boolean =>
  isPreapprovalsEnabled(loan) && ![LoanMilestones.APPLICATION, LoanMilestones.PREAPPROVAL].includes(loan['loan-milestone']);

export const isFirstMilestone = (loan: Loan): boolean => {
  if (loan['show-preapproval-letters?']) {
    return [LoanMilestones.PREAPPROVAL, LoanMilestones.PREAPPROVED].includes(loan['loan-milestone']);
  } else if (loan['refinance?']) {
    return loan['loan-milestone'] === LoanMilestones.CONDITIONAL_APPROVAL;
  } else {
    return loan['loan-milestone'] === LoanMilestones.APPLICATION;
  }
};

export const isLoanTasksReady = (loanOrLoanFeatures: Loan | LoanFeatures): boolean => loanOrLoanFeatures['tasks-ready'] === 'ready';
export const isLoanTasksNotReady = (loanOrLoanFeatures: Loan | LoanFeatures): boolean =>
  loanOrLoanFeatures['tasks-ready'] === 'not-ready';
export const isLoanTasksTimedOut = (loanOrLoanFeatures: Loan | LoanFeatures): boolean =>
  loanOrLoanFeatures['tasks-ready'] === 'timed-out' ||
  (!!loanOrLoanFeatures['tasks-timed-out'] && loanOrLoanFeatures['tasks-ready'] !== 'ready');

export const isLoanAusCompleted = (loanOrLoanFeatures: Loan | LoanFeatures): boolean =>
  loanOrLoanFeatures['aus-completed'] === 'complete';
export const isLoanAusNotCompleted = (loanOrLoanFeatures: Loan | LoanFeatures): boolean =>
  loanOrLoanFeatures['aus-completed'] === 'not-complete';
export const isLoanAusTimedOut = (loanOrLoanFeatures: Loan | LoanFeatures): boolean =>
  loanOrLoanFeatures['aus-completed'] === 'timed-out' ||
  (!!loanOrLoanFeatures['aus-timed-out'] && loanOrLoanFeatures['aus-completed'] !== 'complete');

// same day is ready when it isn't pending-required
export const isLoanSameDayReady = (loanOrLoanFeatures: Loan | LoanFeatures): boolean =>
  loanOrLoanFeatures['same-day-status'] !== 'pending-required';

export const isLoanSameDayIneligible = (loanOrLoanFeatures: Loan | LoanFeatures): boolean =>
  loanOrLoanFeatures['same-day-status'] === 'not-eligible';

// Right now we know a processing loan is ready (in MyAccount) when tasks are ready AND same day is ready
export const isLoanReady = (loan: Loan): boolean => isFundedLoan(loan) || (isLoanTasksReady(loan) && isLoanSameDayReady(loan));
