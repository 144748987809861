import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { fetchPersonalLoans, selectPersonalLoansMetadata } from '../personalLoans/personalLoansSlice';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';

const useFetchPersonalLoansData = () => {
  const dispatch = useAppDispatch();
  const isPersonalLoansEnabled = useFeatureEnabled('personal-loans');  
  const metadata = useAppSelector(selectPersonalLoansMetadata);
  const { hasData } = metadata;

  useEffect(() => {
    !hasData && isPersonalLoansEnabled && dispatch(fetchPersonalLoans());
  }, [hasData, isPersonalLoansEnabled, dispatch]);

  return metadata;
};

export default useFetchPersonalLoansData;
