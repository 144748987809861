import { isHELOCLoanFunded } from '../../../../features/helocLoans/helocLoansSlice';
import { iHELOCLoan } from '../../../../features/helocLoans/types';
import { usdAmount } from '../../../../utils/amount';
import { formatDate, FORMAT_SHORT } from '../../../../utils/date';
import { formatPercent } from '../../../../utils/percent';
import DetailsCard from '../../detailsCard/DetailsCard';
import { getAddress } from '../../loanCard/HELOCLoanCard';
import { HelocLoanMilestoneHeroContents } from '../../milestone/MilestoneHero';
import { LoanMilestoneStep } from '../../loanStatusInfo/ILoanStatusInfo';
import { IDataItem } from '../../../ui/datalist/dataItem/IDataItem';
import { useHELOCLoanConfig } from '../../../../hooks/useHELOCLoanConfig';
import { getHELOCLoanTermString } from '../../../../utils/getLoanTermString';

const getMilestoneStep = (helocLoan: iHELOCLoan, address: string) => {
  return {
    title: isHELOCLoanFunded(helocLoan) ? 'Funded' : 'Approved',
    description: address,
  } as LoanMilestoneStep;
};

const SubHeader = ({ helocLoan }: { helocLoan: iHELOCLoan }) => {
  const loanConfig = useHELOCLoanConfig(helocLoan);
  const address = getAddress(helocLoan.propertyAddress);
  const milestoneStep = getMilestoneStep(helocLoan, address);
  return (
    <>
      {loanConfig && <HelocLoanMilestoneHeroContents loanConfig={loanConfig} milestoneStep={milestoneStep} />}
      {!isHELOCLoanFunded(helocLoan) && (
        <p className='mt-4'>Your funds are being transferred and should be available in 5-10 days after approval date.</p>
      )}
      <div className='border-b border-gray-25 my-6' />
    </>
  );
};

export const HELOCLoanDetailsCard = ({ helocLoan }: { helocLoan: iHELOCLoan }) => {
  const isFunded = isHELOCLoanFunded(helocLoan);
  const details: IDataItem[] = [
    {
      value: helocLoan.originator.loanNumber,
      label: 'Loan number',
    },
    {
      value: usdAmount(helocLoan.loanDetails.initialDrawAmount),
      label: 'Original credit limit',
    },
    {
      value: getHELOCLoanTermString(helocLoan) || 'Not available',
      label: 'Original loan term',
    },
    {
      value: formatPercent(helocLoan.loanDetails.interestRate * 100, 3),
      label: 'Original interest rate',
    },
    {
      value: helocLoan.loanDetails.originalDrawPeriod ? `${helocLoan.loanDetails.originalDrawPeriod} months` : undefined,
      label: 'Original draw period',
    },
    {
      value: isFunded ? formatDate(helocLoan.loanDetails.fundingDate, FORMAT_SHORT) : undefined,
      label: 'Original date funded',
    },
  ];
  return <DetailsCard subHeader={<SubHeader helocLoan={helocLoan} />} details={details} />;
};
