import { useEffect } from 'react';
import useFetchInitialData from '../../features/app/useFetchInitialData';
import useFetchGlobalData from '../../features/app/useFetchGlobalData';
import UnexpectedError from '../../pages/unexpectedError/UnexpectedError';
import { sendEvent } from '../../features/analytics/sendEvent';
import { useSetInitialLanguage } from '../../hooks/useMultilingual';
import useError from '../../hooks/useError';
import { log } from '../../utils/logger';

let hasLoggedAppHasInitData = false;
let hasLoggedAppHasGlobalData = false;

const AppGlobal = ({ children }: { children: JSX.Element }) => {
  const hasInitData = useFetchInitialData();
  const hasGlobalData = useFetchGlobalData();
  const { showingError, isGlobalError, isErrorPage } = useError();
  useSetInitialLanguage();

  useEffect(() => {
    if (hasInitData && !hasLoggedAppHasInitData) {
      hasLoggedAppHasInitData = true;
      log({ level: 'info', message: 'App initialized' });
      sendEvent('appHasInitData');
    }
  }, [hasInitData]);

  useEffect(() => {
    if (hasGlobalData && !hasLoggedAppHasGlobalData) {
      hasLoggedAppHasGlobalData = true;
      log({ level: 'info', message: 'App has global data' });
      sendEvent('appHasGlobalData');
    }
  }, [hasGlobalData]);

  // Skip hasInitialData check
  if (showingError) {
    return <UnexpectedError cause={isGlobalError ? 'GlobalError' : isErrorPage ? 'ErrorPage' : undefined} />;
  }

  // Prevent rendering children without initial data and global data
  if (!hasInitData || !hasGlobalData) {
    return null;
  }

  return children;
};

export default AppGlobal;
