import Circle from '../circle/Circle';

export type NumberBadgeProps = {
  number: number;
  isPrimary?: boolean;
  bgColor?: string;
  textColor?: string;
};

export const NumberBadge = ({ number, isPrimary, bgColor, textColor }: NumberBadgeProps) => {
  return (
    <Circle
      className='ml-2'
      bgColor={bgColor || (isPrimary ? 'action-125' : 'action-10')}
      size='1.5rem'
      textSize='small'
      textColor={textColor || (isPrimary ? 'white' : 'action-125')}
      text={`${number}`}
    />
  );
};
