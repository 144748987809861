import { useRef } from 'react';
import nextId from '../../../utils/nextId';
import FormDropdown from '../../ui/form/FormDropdown';

const INCOMES_ARRAY = [
  {
    text: 'W2',
    value: 'W2',
    dataQa: 'W2',
  },
  {
    text: 'Self Employer',
    value: 'self_employer',
    dataQa: 'self_employer',
  },
];

export const IncomeTypeDropdown = (
  {
    className,
    onChange,
    onError,
  }:
  {
    className?: string;
    onChange?: (value: string) => void
    onError?: (error?: string) => void;
  }
) => {
  const idRef = useRef(nextId('income-types-dropdown'));
  return (
    <FormDropdown
      className={className}
      id={idRef.current}
      name='income_type'
      title='Type of income'
      items={INCOMES_ARRAY}
      onChange={onChange}
      onError={onError}
    />
  );
};
