/* istanbul ignore file */
export const SET_TASKS = '[Tasks] SET';
export const SET_TASK = '[Task] SET';
export const REMOVE_TASK = '[Task] REMOVE';
export const UPDATE_TASK = '[Task] UPDATE';
export const SET_HAS_TASK_ERROR = '[Task] SET HAS ERROR';
export const SET_TASKS_ARE_LOADING = '[Task] SET LOADING';
export const TASK_MODAL_TOGGLE = '[Task] MODAL TOGGLE';
export const UPDATE_TASK_COMPLETED = '[Task] UPDATE TASK COMPLETED';
export const SET_OTHERS_PENDING_TASKS = '[Task] SET OTHERS PENDING TASKS';
