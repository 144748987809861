import { Component, ErrorInfo } from 'react';
import UnexpectedError from '../../pages/unexpectedError/UnexpectedError';
import { log } from '../../utils/logger';
import Cobrowse from '../cobrowse/Cobrowse';
import InactivityTimeout from '../inactivityTimeout/InactivityTimeout';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export interface IState {
  hasError: boolean;
}

const Error = () => (
  <>
    <Cobrowse />
    <InactivityTimeout />
    <UnexpectedError cause='ErrorBoundary' />
  </>
);

/**
 * Application error boundary
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, IState> {
  state = {
    hasError: false,
  };

  // render phase method
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // commit phase method for side effects (e.g. log error to error service)
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log({ error, message: 'hit error boundary' });
  }

  render() {
    return this.state.hasError ? <Error /> : this.props.children;
  }
}

export default ErrorBoundary;
