import { AddressProps } from './IAddress';
import cn from 'classnames';
import { capitalize, uppercase } from '../../../utils/str';

function Address(props: AddressProps) {
  const {
    className,
    name,
    'street-address': streetAddress1,
    'street-address-2': streetAddress2,
    city,
    state,
    'postal-code': postalCode,
    singleLine = false,
  } = props;

  return (
    <div className={cn(className, 'address OneLinkNoTx')}>
      {name && <div className='address-name'>{name}</div>}
      {(streetAddress1 || streetAddress2) && (
        <>
          <span className={cn('address-line', !singleLine && 'block')}>
            {streetAddress1 && <span className={cn('address-street', !singleLine && 'block')}>{capitalize(streetAddress1)}</span>}
            {streetAddress2 && (
              <>
                {singleLine && <span>{', '}</span>}
                <span className={cn('address-street-2', !singleLine && 'block')}>{capitalize(streetAddress2)}</span>
              </>
            )}
          </span>
          {singleLine && (city || state || postalCode) && <span>{`, `}</span>}
        </>
      )}
      {(city || state || postalCode) && (
        <span className={cn('address-line', !singleLine && 'block')}>
          {city && (
            <>
              <span className='address-city'>{capitalize(city)}</span>
              {(state || postalCode) && <span>{`, `}</span>}
            </>
          )}
          {state && <span className='address-state'>{uppercase(state)}</span>}
          {postalCode && (
            <>
              {state && ' '}
              <span className='address-postal'>{postalCode}</span>
            </>
          )}
        </span>
      )}
    </div>
  );
}

export default Address;
