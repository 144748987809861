import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { validateAuth } from '../auth/authSlice';
import { fetchConfig } from '../config/configSlice';
import { selectHasInitialData } from './appSlice';

// First initialization layer, fetch config and check user's auth status
const useFetchInitialData = () => {
  const dispatch = useAppDispatch();
  const hasInitData = useAppSelector(selectHasInitialData);

  useEffect(() => {
    dispatch(validateAuth());
    dispatch(fetchConfig());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return hasInitData;
};

export default useFetchInitialData;
