import { useLoan } from '../../../features/loans/hooks';
import { Loan } from '../../../features/loans/types';
import { useHomeLoanConfig } from '../../../hooks/useHomeLoanConfig';
import { DashboardLoanCard } from './DashboardLoanCards';
import { FORMAT_LONG, FORMAT_SHORT, formatDate, formatDateWithoutTimezone } from '../../../utils/date';
import { TimeProps } from '../../ui/time/ITime';
import Time from '../../ui/time/Time';
import { SocialShareConfigs } from '../../social/ISocialShareConfig';

const DateInfo = ({
  'closing-date': closingDate,
  'scheduled-closing-date': scheduledClosingDate,
  'estimated-closing-date': estimatedClosingDate,
  'last-updated': lastUpdated,
  'started-date': startedDate,
}: Loan) => {
  let dateInfo;
  if (closingDate) {
    dateInfo = {
      date: closingDate,
      label: `Closed on ${formatDateWithoutTimezone(closingDate, FORMAT_SHORT)}`,
      title: formatDateWithoutTimezone(closingDate, FORMAT_LONG),
    };
  } else if (scheduledClosingDate) {
    dateInfo = {
      date: scheduledClosingDate,
      label: `Close date ${formatDateWithoutTimezone(scheduledClosingDate, FORMAT_SHORT)}`,
      title: formatDateWithoutTimezone(scheduledClosingDate, FORMAT_LONG),
    };
  } else if (estimatedClosingDate) {
    dateInfo = {
      date: estimatedClosingDate,
      label: `Est. close date ${formatDateWithoutTimezone(estimatedClosingDate, FORMAT_SHORT)}`,
      title: formatDateWithoutTimezone(estimatedClosingDate, FORMAT_LONG),
    };
  } else if (lastUpdated) {
    dateInfo = {
      date: lastUpdated,
      label: `Last modified ${formatDate(lastUpdated, FORMAT_SHORT)}`,
      title: formatDate(lastUpdated, FORMAT_LONG),
    };
  } else if (startedDate) {
    dateInfo = {
      date: startedDate,
      label: `Application date ${formatDateWithoutTimezone(startedDate, FORMAT_SHORT)}`,
      title: formatDateWithoutTimezone(startedDate, FORMAT_LONG),
    };
  }

  if (!dateInfo) return null;

  return <Time {...(dateInfo as TimeProps)} />;
};

export const DashboardHomeLoanCardPresentational = ({ loan }: { loan: Loan }) => {
  const loanConfig = useHomeLoanConfig(loan);
  const team = loan?.team;

  return (
    <DashboardLoanCard
      loanConfig={loanConfig}
      team={team}
      loanAmount={loan['base-loan-amount']}
      address={loan.property}
      dateNode={<DateInfo {...loan} />}
      loanNumber={loan['loan-number']}
      configKey={loan['loan-milestone'] === 'funded' ? SocialShareConfigs.LOAN_CENTER_FUNDED : undefined}
    />
  );
};

export const DashboardHomeLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const { data } = useLoan(loanGuid);
  if (!data) return null;
  return (
    <DashboardHomeLoanCardPresentational loan={data} />
  );
};
