import { Request } from '../../interfaces/IRequest';

/* == Mortgage Loan Team ================================ */

type LoanOfficerFeature = 'l1-pal' | 'l2-pal';

interface LoanOfficerLicense {
  state: string;
  'license-number': string;
}

const teamMemberRoleNames = ['Loan Officer', 'Mortgage Consultant', 'Loan Coordinator', 'Real Estate Agent'];
type LoanTeamMemberRoleName = (typeof teamMemberRoleNames)[number];

const teamMemberRoleKeys = ['lo', 'mc', 'lc', 'realtor'];
type LoanTeamMemberRoleKey = (typeof teamMemberRoleKeys)[number];

export interface LoanTeamMember {
  email?: string;
  fax?: string;
  readonly id: string;
  name: string;
  phone?: string;
  'role-name': LoanTeamMemberRoleName;
  'role-key': LoanTeamMemberRoleKey;
}

export interface LoanOfficer extends LoanTeamMember {
  readonly 'emp-id': number;
  features?: LoanOfficerFeature[];
  status: 'active' | 'inactive' | 'unknown';
  'nmls-id': string;
  'photo-url'?: string;
  licenses?: LoanOfficerLicense[];
  title?: string;
}

export interface Realtor extends LoanTeamMember {
  'photo-url'?: string;
  title?: string;
  'agency-name'?: string;
}

export interface LoanTeam {
  lo?: LoanOfficer;
  mc?: LoanTeamMember;
  lc?: LoanTeamMember;
  realtor?: Realtor;
}

/* == Mortgage Loan Borrower ================================ */

export interface LoanBorrower {
  name: string;
  email: string;
  'ssn?': boolean;
  'language-preference'?: string;
}

/* == Mortgage Loan Features ================================ */

const loanApplicationStatuses = ['editable', 'not-editable', 'completed', 'timed-out'] as const;
export type LoanApplicationStatus = (typeof loanApplicationStatuses)[number];

const loanNewTasksReadyStatuses = ['ready', 'not-ready', 'timed-out', 'unknown'] as const;
type LoanNewTasksReadyStatus = (typeof loanNewTasksReadyStatuses)[number];

const loanAusStatuses = ['complete', 'not-complete', 'timed-out', 'unknown'] as const;
type LoanAusStatus = (typeof loanAusStatuses)[number];

const sameDayStatuses = [
  'approved',
  'eligible',
  'prequal',
  'pending-required',
  'pending',
  'not-eligible',
  'timed-out',
  'disabled',
] as const;
type SameDayStatus = (typeof sameDayStatuses)[number];

interface LoanFeaturesGateless {
  [key: string]: string | boolean;
  'my-account': boolean;
  'task-list-ready': boolean;
  'voa-vendor': string;
  'gateless-tasks-20211025': boolean;
}

interface LoanFeaturesAusResults {
  'du-passed?': boolean;
  'lpa-passed?': boolean;
}

export enum LoanMilestones {
  APPLICATION = 'application',
  PREAPPROVAL = 'pre-approval',
  PREAPPROVED = 'pre-approved',
  CONDITIONAL_APPROVAL = 'conditional-approval',
  CONDITIONALLY_APPROVED = 'conditionally-approved',
  CLEAR_TO_CLOSE = 'clear-to-close',
  CLOSED = 'closed',
  FUNDED = 'funded',
}

export interface LoanFeatures {
  'loan-milestone': LoanMilestones;
  'application-status': LoanApplicationStatus;
  'tasks-ready'?: LoanNewTasksReadyStatus;
  'same-day-status'?: SameDayStatus;
  'loan-inaccessible?'?: boolean;
  'tasks-timed-out'?: boolean;
  'aus-completed'?: LoanAusStatus;
  'aus-results'?: LoanFeaturesAusResults;
  'aus-timed-out'?: boolean;
  'smart-underwrite?': boolean;
  'milestone-modals?': boolean;
  'show-talkuments-link?'?: boolean;
  'show-preapproval-letters?'?: boolean;
  'generate-l1-pal?'?: boolean;
  'generate-l2-pal?'?: boolean;
  'ext-features': LoanFeaturesGateless;
  'fast-track-approval'?: boolean;
}

/* == Mortgage Loan Servicing ================================ */

export interface LoanServicingSSO {
  token: string;
  'login-url': string;
}

export interface LoanServicer {
  name: string;
  'street-address': string;
  'postal-code': string;
  city: string;
  state: string;
  phone: string;
  website: string | null;
}

/* == Falcon Loan Data ================================ */

export enum AlpMilestone {
  APPLICATION = 'application',
  PREAPPROVED = 'pre-approved',
  REVIEWED = 'reviewed',
  APPROVED = 'approved',
  CLOSED = 'closed',
  DENIED = 'denied',
}

interface AlpLoan {
  milestone: AlpMilestone;
}

export enum AlpLoanType {
  HELOC = 'heloc',
  CES = 'ces',
}

/* == Mortgage Loan ================================ */

interface LoanAddress {
  'street-address'?: string;
  'street-address-2'?: string;
  city?: string;
  state?: string;
  'postal-code'?: string;
}

const loanAppraisalStatus = ['pending', 'ordered', 'scheduled', 'received', 'approved', 'waived'];
export type LoanAppraisalStatus = (typeof loanAppraisalStatus)[number];

export interface Loan extends LoanFeatures {
  readonly 'loan-guid': string;
  'alp-heloc-loan'?: AlpLoan;
  'alp-ces-loan'?: AlpLoan;
  'alp-loan-type'?: AlpLoanType;
  'amortization-type': string;
  'loan-term-months': number;
  'base-loan-amount': number;
  borrower: LoanBorrower;
  'closing-date'?: string | null;
  'closing-time'?: string | null;
  coborrower?: LoanBorrower;
  'started-date': string;
  'down-payment': number;
  'scheduled-closing-date'?: string;
  'estimated-closing-date'?: string;
  readonly 'gr-loan-guid': string;
  'interest-rate'?: number;
  'last-updated': string;
  property: LoanAddress;
  'lap?': boolean;
  'no-documents?'?: boolean;
  'refinance?': boolean;
  'digital-mortgage?': boolean;
  'prequalification?': boolean;
  team?: LoanTeam;
  readonly 'loan-number': string;
  'lead-source'?: string;
  'originating-app'?: string;
  'va?': boolean;
  'respa-to-ctc'?: string;
  'appraisal-due-date'?: string;
  'appraisal-scheduled-date'?: string;
  'appraisal-status': LoanAppraisalStatus;
  'appraisal-amount'?: number;
  'contingency-date'?: string;
  'lock-expiration-date'?: string;
  'comortgagor?': boolean;
  comortgagor?: LoanBorrower;
  'conditional-approval-with-letter?': boolean;
}

export interface LoansMap {
  [index: string]: Loan;
}

/* == Loans Slice ================================ */

export interface EntityRequest extends Request {
  isFetching: boolean;
  hasData: boolean;
  hasError: boolean;
}

export interface LoanByGuidEntity extends EntityRequest {
  data: Loan | undefined;
}

export type LoanFeaturesByGuidEntity = EntityRequest;

export type LoanRealtorByGuidEntity = EntityRequest;

export interface LoanByGuidMap {
  [index: string]: LoanByGuidEntity;
}

export interface LoanFeaturesByGuidMap {
  [index: string]: LoanFeaturesByGuidEntity;
}

export interface LoanRealtorByGuidMap {
  [index: string]: LoanRealtorByGuidEntity;
}

export interface LoansEntity extends EntityRequest {
  data: string[] | undefined;
}

export interface LoansState {
  loansByGuid: LoanByGuidMap;
  loanFeaturesByGuid: LoanFeaturesByGuidMap;
  loanRealtorByGuid: LoanRealtorByGuidMap;
  loans: LoansEntity;
}

export type FetchLoansResponse = Loan[];
export type FetchLoanResponse = Loan;
export type FetchLoanFeaturesResponse = LoanFeatures;
export type FetchLoanRealtorResponse = Realtor;
