import useFetchHELOCLoansData from '../features/helocLoans/useFetchHELOCLoansData';
import { useLoans } from '../features/loans/hooks';
import useFetchPersonalLoansData from '../features/personalLoans/useFetchPersonalLoansData';
import useFeatureEnabled from './useFeatureEnabled';

export const useIsLoadingLoans = () => {
  const { hasData: hasHomeLoansData } = useLoans();
  const { hasData: hasPersonalLoansData, hasError: hasPersonalLoansError } = useFetchPersonalLoansData();
  const { hasData: hasHELOCLoansData, hasError: hasHELOCLoansError } = useFetchHELOCLoansData();

  // Loading statuses
  const homeLoansNeedsFetching = !hasHomeLoansData;
  const personalLoansEnabled = useFeatureEnabled('personal-loans');
  const personalLoansNeedsFetching = personalLoansEnabled && !hasPersonalLoansData && !hasPersonalLoansError;
  const helocLoansEnabled = useFeatureEnabled('heloc-loans');
  const helocLoansNeedsFetching = helocLoansEnabled && !hasHELOCLoansData && !hasHELOCLoansError;
  return homeLoansNeedsFetching || helocLoansNeedsFetching || personalLoansNeedsFetching;
};
