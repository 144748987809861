import { sendEvent } from '../../../features/analytics/sendEvent';
import { updateGlobalLoading } from '../../../features/app/appSlice';
import { sendDocumentEventToShellApp } from '../../../features/mobileApp/shellAppEvents';
import { useAppDispatch } from '../../../hooks/hooks';
import { useMobileAppDocumentEvent } from '../../../hooks/useMobileAppDocumentEvent';
import { log } from '../../../utils/logger';
import Button from '../../ui/button/Button';
import cn from 'classnames';

const DownloadButton = ({ href, filename, className, onClick }: { href: string; filename: string; className?: string; onClick?: () => void }) => {
  const mobileAppDocumentEvent = useMobileAppDocumentEvent();
  const dispatch = useAppDispatch();
  // if mobile app - skip the default download and download the content in browser
  const downloadHref = mobileAppDocumentEvent ? undefined : `${href}?download=true`;

  const handleDownloadDocument = async () => {
    sendEvent('downloadDocument', { href, filename });
    log({ level: 'info', message: `Download document '${href}'` });

    onClick?.();

    // handle JS bridge
    if (mobileAppDocumentEvent) {
      dispatch(updateGlobalLoading(true));
      await sendDocumentEventToShellApp('DOWNLOAD_DOCUMENT', href, filename);
      dispatch(updateGlobalLoading(false));
    }
  };

  return (
    <Button
      href={downloadHref}
      rel='noreferrer'
      text={`download ${filename}`}
      onClick={handleDownloadDocument}
      className={cn('no-underline no-select', className)}
      buttonType='icon'
      iconSize='1.25rem'
      iconName='interface-download'
      iconHoverEffect={true}
    />
  );
};

export default DownloadButton;
