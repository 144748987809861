import { getDisplayedLoanNumber, selectHELOCLoanById } from '../../../features/helocLoans/helocLoansSlice';
import { HELOCPropertyAddress, iHELOCLoan } from '../../../features/helocLoans/types';
import { useAppSelector } from '../../../hooks/hooks';
import { useHELOCLoanConfig } from '../../../hooks/useHELOCLoanConfig';
import { usdAmount } from '../../../utils/amount';
import Address from '../../ui/address/Address';
import { LoanCard } from './LoanCard';

export const getLoanAmount = (loanAmount: number | undefined) => {
  return loanAmount ? `${usdAmount(loanAmount, false, false)} HELOC` : undefined;
};

export const getHELOCProperty = (address: HELOCPropertyAddress) => {
  return {
    'street-address': address.street?.[0],
    'street-address-2': address.street?.[1],
    city: address.city,
    state: address.state,
    'postal-code': address.zip,
  };
};

export const getAddress = (propertyAddress: HELOCPropertyAddress) => {
  return (
    propertyAddress &&
    `${propertyAddress.street?.map(
      (street: string, index: number) =>
        `${street}${(propertyAddress.street?.length || 0) > index + 1 || propertyAddress.city ? ', ' : ''}`,
    )}${propertyAddress.city}${propertyAddress.city && propertyAddress.state ? ', ' : ''}${propertyAddress.state}${
      propertyAddress.state && propertyAddress.zip ? ', ' : ''
    }${propertyAddress.zip}`
  );
};

export const HELOCLoanCard = ({ helocLoanId }: { helocLoanId: string }) => {
  const helocLoan = useAppSelector(state => selectHELOCLoanById(state, helocLoanId));
  if (!helocLoan) return null;
  return (
    <HELOCLoanCardPresentational helocLoan={helocLoan} />
  );
};

export const HELOCLoanCardPresentational = ({ helocLoan }: { helocLoan: iHELOCLoan }) => {
  const loanNumber = getDisplayedLoanNumber(helocLoan);
  const loanConfig = useHELOCLoanConfig(helocLoan);

  return loanConfig ? (
    <LoanCard
      loanNumber={loanNumber}
      loanConfig={loanConfig}
      loanNumberPrefix='HELOC loan #'
      subHeader={getLoanAmount(helocLoan.loanDetails.initialDrawAmount)}
      property={<Address {...getHELOCProperty(helocLoan.propertyAddress)} />}
    />
  ) : null;
};
