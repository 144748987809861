import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { selectLoan } from '../features/loans/loansSlice';
import { isFundedLoan, isLoanTasksReady, isLoanReady } from '../features/loans/helpers';
import { selectors as tasksSelectors } from '../features/tasks';
import { fetchTasks } from '../features/tasks/actions';
import { useAppSelector } from './hooks';
import useIsMounted from './useIsMounted';

export const useFetchTasks = (
  loanGuid: string,
  { refetch, refetchOnError }: { refetch?: boolean; refetchOnError?: boolean } = {},
) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const hasTasksData = useAppSelector(state => tasksSelectors.hasLoanTasksData(state, loanGuid));
  const tasksLoading = useAppSelector(state => tasksSelectors.loanTasksAreLoading(state, loanGuid));
  const hasTasksError = useAppSelector(state => tasksSelectors.hasLoanTaskError(state, loanGuid));
  const loan = useAppSelector(state => selectLoan(state, loanGuid));
  const loanReady = loan && isLoanReady(loan);
  const isFunded = loan && isFundedLoan(loan);
  const tasksReady = loan && isLoanTasksReady(loan);

  const canFetch = !isFunded && loanReady && tasksReady;
  const shouldFetch = useRef(canFetch && !tasksLoading && (refetch || (!hasTasksData && (!hasTasksError || refetchOnError))));

  useEffect(() => {
    if (isMounted() && shouldFetch.current) {
      dispatch(fetchTasks(loanGuid));
    }
  }, [dispatch, isMounted, loanGuid]);

  return {
    hasTasksData,
    hasTasksError,
    tasksLoading,
  };
};