import { sendEvent } from '../../../features/analytics/sendEvent';
import FAQs, { IFAQ } from '../../ui/faqs/FAQs';
import { content } from '../../../features/tenant/tenant';

const servicingGeneralFAQs = () => {
  const questions: IFAQ[] = [
    {
      question: 'When will I get my first billing statement?',
      answer: (
        <p>
          The first billing statement is mailed approximately 15 days prior to the first payment due date. Once a payment is made,
          a new billing statement is generated for the next installment. If you do not receive a billing statement, you can access
          and pay your account online, or give us a call at number above.
        </p>
      ),
    },
    {
      question: 'How do I make a payment?',
      answer: (
        <p>
          Once your account has been activated in our servicing system, you will be able to make your payment online. You can also
          make your payment over the phone. You can also mail in your payment using the payment address information in your closing
          package or monthly billing statement.
        </p>
      ),
    },
    {
      question: 'I just received a notice that my loan was sold or that my loan was transferring to another servicer. What does this mean?',
      answer: (
        <>
          <p>
            If the ownership of your loan is sold, you will receive a change of ownership notice from the new owner. Loans are regularly
            sold to Fannie Mae and Freddie Mac but can also be sold to other banks and financial institutions. Often, even though the
            ownership of the loan is sold, {content.legalName || content.company} will continue to be the servicer of the loan.
          </p>
          <p>
            If the servicing of your loan is going to change, then you will receive a notice of servicing transfer in the mail. This is
            mailed 15 days prior to the effective date of the servicing transfer. This notice will let you the specific date that Guaranteed
            Rate will stop servicing your loan and the new servicer will start servicing the loan.
          </p>
          <p>
            It typically takes the new servicer a few days after the servicing transfer effective date to fully set up your account. At that
            point, you will be able to access your account directly through their website.
          </p>
        </>
      ),
    },
    {
      question: 'My loan is transferring but I already sent my payment, or I am scheduled for an automatic draft. What should I do?',
      answer: (
        <>
          <p>
            If you have sent a payment to {content.legalName || content.company}, there is no need to cancel your check or send a new payment. We will automatically
            forward all payments received within 60 days of service transfer to the new servicer on your behalf.
          </p>
          <p>
            If you are scheduled for automatic withdrawals, then we will stop drafting on the servicing transfer effective date. Additionally,
            we will attempt to transfer your drafting information to the new servicer. You will have to contact the new servicer to confirm if
            they have accepted the drafting information or if you will need to resubmit.
          </p>
        </>
      ),
    },
  ];

  return questions;
};

export const ServicingGeneralFAQs = () => {
  const questions = servicingGeneralFAQs();

  const onToggle = (isActive: boolean, faq: IFAQ, i: number) => {
    isActive ? sendEvent(`servicing general: FAQ${i} opened`, {title: faq.question}) : sendEvent(`servicing general: FAQ${i} closed`, {title: faq.question});
  };

  return <FAQs faqs={questions} heading='Frequently asked questions' onToggle={onToggle} />;
};
