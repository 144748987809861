import { sendEvent } from '../../../features/analytics/sendEvent';
import FAQs, { IFAQ } from '../../ui/faqs/FAQs';

const servicingTransferFAQs = () => {
  const questions: IFAQ[] = [
    {
      question: 'When will the new servicer contact me?',
      answer: (
        <p>
          If the ownership of your loan is sold, you will receive a change of ownership notice from the new owner. Loans
          are regularly sold to Fannie Mae and Freddie Mac but can also be sold to other banks and financial institutions. Often,
          even though the ownership of the loan is sold, Guaranteed Rate will continue to be the servicer of the loan. If the
          servicing of your loan is going to change, then you will receive a notice of servicing transfer in the mail. Guaranteed
          Rate will mail a notice of servicing transfer 15 days prior to the effective date of the servicing transfer. The new
          servicer will mail a similar notice within 15 days after the servicing transfer effective date. Additionally, the new
          servicer is likely to call and/or email you shortly after the servicing transfer effective date.
        </p>
      ),
    },
    {
      question: 'When can I access my account at the new servicer?',
      answer: (
        <p>
          It typically takes the new servicer a few business days after the servicing transfer effective date to fully onboard
          your loan into their servicing portal. At that point, you should be able to access your account online, make payments, etc.
        </p>
      ),
    },
    {
      question: 'What if I am enrolled in automatic payment drafting?',
      answer: (
        <p>
          If you are enrolled in automatic payment drafting, we will continue to draft your monthly payment until the servicing
          transfer effective date. We will attempt to transfer your automatic drafting information to your new servicer. Your
          new servicer will inform you if they require you to re-enroll in automatic drafting following the servicing transfer effective date.
        </p>
      ),
    },
    {
      question: 'What if I already made a payment to Guaranteed Rate?',
      answer: (
        <p>
          If you have already made a payment to Guaranteed Rate, don't worry. There is no need to cancel your check or send a new
          payment. We will automatically forward all payments received within 60 days of service transfer to the new servicer on your behalf. 
        </p>
      ),
    }
  ];
  return questions;
};

export const ServicingTransferFAQs = () => {
  const questions = servicingTransferFAQs();

  const onToggle = (isActive: boolean, faq: IFAQ, i: number) => {
    isActive ? sendEvent(`servicing transfer: FAQ${i} opened`, {title: faq.question}) : sendEvent(`servicing transfer: FAQ${i} closed`, {title: faq.question});
  };

  return <FAQs id='servicing-transfer-faqs' faqs={questions} heading='Frequently asked questions' onToggle={onToggle} />;
};
