import Circle from '../../../components/ui/circle/Circle';
import CircleIndicator from '../../../components/ui/circleIndicator/CircleIndicator';
import Icon from '../../../components/ui/icon/Icon';

const CircleStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Circle</div>
      <ul>
        <li className='mb-4'>
          <Circle className='bg-primary'>2</Circle>
        </li>
        <li className='mb-4'>
          <Circle size='30px'>3</Circle>
        </li>
        <li className='mb-4'>
          <Circle text='11' size='40px'></Circle>
        </li>
        <li className='mb-4'>
          <Circle size='40px'>
            <Icon name='arrow-down' />
          </Circle>
        </li>
        <li className='mb-4'>
          <Circle size='45px' textColor='black' bgColor='ok-10'>
            <Icon name='cheeseburger' size='16px' />
          </Circle>
        </li>
        <li className='mb-4'>
          <CircleIndicator iconName='arrow-down' bgColor='ok-10' />
        </li>
      </ul>
    </>
  );
};

export default CircleStyleguide;
