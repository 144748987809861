import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import type { RootState } from '../../store/types';
import { log } from '../../utils/logger';
import { sendEvent } from '../analytics/sendEvent';
import api from '../api';
import { HomeValueState, HomeValueUserAddress, HomeValueResponse } from './types';

const namespace = 'homeValue';

const initialState: HomeValueState = {
  address: null,
  previousAddress: null,
  valuation: null,
  valuationHistory: [],
  hasData: false,
  hasInitialData: false,
  fetchCount: 0,
  fetching: false,
  error: false,
};

export const useFetchHomeValueOnce = (): HomeValueState => {
  const dispatch = useAppDispatch();
  const homeValueState = useAppSelector(selectHomeValueState);
  const { fetchCount } = homeValueState;

  useEffect(() => {
    if (fetchCount === 0) {
      dispatch(fetchHomeValue());
    }
  }, [dispatch, fetchCount]);  

  return homeValueState;
};

export const fetchHomeValue = createAsyncThunk<HomeValueResponse, undefined | HomeValueUserAddress>(
  `${namespace}/fetchHomeValue`,
  async address => {
    try {
      const resp = await api.getHomeValue(address);

      sendEvent('homeValueSearchSuccess', {
        address,
        valuation: resp.data?.valuation,
      });

      return resp.data;
    } catch (error) {
      log({
        level: 'error',
        message: `fetchHomeValue error - ${error}`,
        isAxiosError: axios.isAxiosError(error),
      });

      sendEvent('homeValueSearchFailure', {
        address,
        error,
      });

      throw error;
    }
  },
);

export const homeValueSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearHomeValueData: state => {
      state.previousAddress = state.address;
      state.address = null;
      state.valuation = null;
      state.valuationHistory = [];
      state.hasData = false;
    },
    clearHomeValueError: state => {
      state.error = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchHomeValue.pending, state => {
        state.fetchCount = state.fetchCount + 1;
        state.fetching = true;
        state.error = false;
      })
      .addCase(fetchHomeValue.fulfilled, (state, { payload }) => {
        state.fetching = false;
        state.hasData = true;
        state.hasInitialData = true;
        state.address = payload.address;
        state.valuation = payload.valuation;
        state.valuationHistory = payload['valuation-history'];
      })
      .addCase(fetchHomeValue.rejected, state => {
        state.fetching = false;
        state.error = true;
      });
  },
});

const selectHomeValueState = (state: RootState) => state.homeValue;

export const selectHomeValueAddress = (state: RootState) => selectHomeValueState(state).address;

export const selectPreviousHomeValueAddress = (state: RootState) => selectHomeValueState(state).previousAddress;

export const selectHasHomeValueData = (state: RootState) => selectHomeValueState(state).hasData;

export const selectHasHomeValueInitialData = (state: RootState) => selectHomeValueState(state).hasInitialData;

export const selectHomeValueData = (state: RootState) => selectHomeValueState(state).valuation;

export const selectHomeValueError = (state: RootState) => selectHomeValueState(state).error;

export const selectHomeValueFetching = (state: RootState) => selectHomeValueState(state).fetching;

// index 0 is the current valuation, and 1 is the most recent history
export const selectLastHomeValueHistory = (state: RootState) => selectHomeValueState(state).valuationHistory[1];

export const { clearHomeValueData, clearHomeValueError } = homeValueSlice.actions;

export default homeValueSlice.reducer;
