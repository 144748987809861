import { withTransaction } from '@elastic/apm-rum-react';
import { Redirect } from 'react-router';
import { LeftContentRightRail } from '../../components/layout/Layout';
import ServicingDetailsActions from '../../components/loan/funded/ServicingDetailsActions';
import ServicingDetailsCard from '../../components/loan/funded/ServicingDetailsCard';
import ServicingError from '../../components/loan/funded/ServicingError';
import { HELOCFigureFAQs, HELOCUnknownServicerFAQs } from '../../components/loan/heloc/details/HELOCLoanFAQs';
import withHELOCLoan from '../../components/loan/heloc/withHELOCLoan';
import { EmailPhoneWidget, SupportHeadline } from '../../components/loan/support/Support';
import SupportHours from '../../components/loan/support/SupportHours';
import { DOC_TITLES, PATHS, linkTo } from '../../components/routes/paths';
import { isHELOCLoanFunded } from '../../features/helocLoans/helocLoansSlice';
import { iHELOCLoan } from '../../features/helocLoans/types';
import { useFetchHELOCServicing } from '../../features/servicing/useFetchHELOCServicing';
import { content } from '../../features/tenant/tenant';
import { useDocumentTitleLoanPage } from '../../hooks/useDocumentTitle';
import { ServicingConfig, useHELOCServicingConfig } from '../../hooks/useServicingConfig';
import { log } from '../../utils/logger';
import { ServicingSkeleton } from '../loan/Servicing';
import { HELOCFigureSupport, HELOCLoanDetails, HELOCLoanNav, HELOCUnknownServicerSupport } from './HELOCLoanDetails';

const HELOCServicingSupport = ({ helocLoanId, isLoading }: { helocLoanId: string; isLoading: boolean }) => {
  const servicingConfig = useHELOCServicingConfig(helocLoanId);
  // Service Mac contact
  let email = content.helocLoans.serviceMac.email;
  let phone = content.helocLoans.serviceMac.phone;
  if (servicingConfig?.servicer?.email || servicingConfig?.servicer?.phone) {
    email = servicingConfig.servicer.email as string;
    phone = servicingConfig.servicer.phone as string;
  }
  return (
    <div className='flex flex-col'>
      <SupportHeadline headline='For HELOC questions:' />
      <EmailPhoneWidget widgetName='helocServicingSupport' isLoading={isLoading} email={email} phone={phone} />
      {!isLoading && content.helocLoans.serviceMac.supportHours && (!servicingConfig?.servicer || !!servicingConfig?.servicer?.name.indexOf('ServiceMac')) && (
        <div className='mt-6'>
          <SupportHours supportDaysTime={content.helocLoans.serviceMac.supportHours} />
        </div>
      )}
    </div>
  );
};

const HELOCLoanServicingContent = ({ servicingConfig }: { servicingConfig: ServicingConfig }) => {
  return (
    <>
      <ServicingDetailsCard config={servicingConfig} />
      <div className='mb-12' />
      <ServicingDetailsActions config={servicingConfig} />
    </>
  );
};

const HELOCLoanServicing = ({ helocLoan }: { helocLoan: iHELOCLoan }) => {
  const { id: helocLoanId, originator } = helocLoan;
  const servicingConfig = useHELOCServicingConfig(helocLoanId);
  const { servicingFetching, servicingFetched, servicingIdle, servicingHasError, servicingLoanIsFigure } = useFetchHELOCServicing(helocLoanId, originator.loanNumber);

  useDocumentTitleLoanPage(DOC_TITLES.helocLoanServicing, helocLoanId);

  let rightRailComponent = null;
  let servicingComponent = null;

  if (servicingIdle || (servicingFetching && !servicingFetched)) {
    // loading
    rightRailComponent = <HELOCServicingSupport helocLoanId={helocLoanId} isLoading={true} />;
    servicingComponent = <ServicingSkeleton />;
  } else if (servicingHasError || !servicingConfig?.servicing || servicingConfig.servicing.experienceInd === 0) {
    if (servicingLoanIsFigure) {
      // 404 error and loan is originated by before 11/1/2023
      rightRailComponent = <HELOCFigureSupport />;
      servicingComponent = <HELOCLoanDetails helocLoan={helocLoan} faqComponent={<HELOCFigureFAQs />} />;
    } else {
      // error
      rightRailComponent = <HELOCUnknownServicerSupport />;
      servicingComponent = <ServicingError loan={helocLoan} servicingConfig={servicingConfig} faqComponent={<HELOCUnknownServicerFAQs />} />;
    }
  } else {
    // service mac
    rightRailComponent = <HELOCServicingSupport helocLoanId={helocLoanId} isLoading={false} />;
    servicingComponent = <HELOCLoanServicingContent servicingConfig={servicingConfig} />;
  }

  return (
    <LeftContentRightRail
      subnav={<HELOCLoanNav helocLoan={helocLoan} />}
      rightRail={rightRailComponent}
    >
      {servicingComponent}
    </LeftContentRightRail>
  );
};

const WithRedirect = ({ helocLoan }: { helocLoan: iHELOCLoan }) => {
  const { id: loanId } = helocLoan;
  if (!isHELOCLoanFunded(helocLoan)) {
    log({ loanGuid: loanId, message: 'Loan does not meet servicing criteria, redirecting back to loan url' });
    return (
      <Redirect
        to={{
          pathname: linkTo(PATHS.helocLoan, { guid: loanId }),
        }}
      />
    );
  }

  return <HELOCLoanServicing helocLoan={helocLoan} />;
};

export default withTransaction('HELOCLoan', 'page-load')(withHELOCLoan(WithRedirect));
