import { useEffect } from 'react';
import { fetchServicing, selectServicingFetching, selectServicingHasError, selectServicingHasFetched, selectServicingIdle } from './servicingSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';

export const useFetchServicing = (loanGuid: string) => {
  const dispatch = useAppDispatch();
  const servicingFetching = useAppSelector(state => selectServicingFetching(state, loanGuid));
  const servicingFetched = useAppSelector(state => selectServicingHasFetched(state, loanGuid));
  const servicingIdle = useAppSelector(state => selectServicingIdle(state, loanGuid));
  const servicingHasError = useAppSelector(state => selectServicingHasError(state, loanGuid));

  useEffect(() => {
    !servicingFetching && !servicingFetched && !servicingHasError && dispatch(fetchServicing(loanGuid));
  }, [dispatch, loanGuid, servicingFetching, servicingFetched, servicingHasError]);

  return {
    servicingFetching,
    servicingFetched,
    servicingIdle,
    servicingHasError,
  };
};
