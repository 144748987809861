import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import api from '../../../features/api';
import { patchTask, removeTask } from '../../../features/tasks/actions';
import { Task } from '../../../features/tasks/types';
import { Error, VoieOrderResponse } from '../../../features/voie/types';
import { useAdditionalTaskDetails } from '../../../hooks/useAdditionalTaskDetails';
import { useDefaultSupport } from '../../../hooks/useDefaultSupport';
import useScript from '../../../hooks/useScript';
import { useTaskText } from '../../../hooks/useTaskText';
import { log } from '../../../utils/logger';
import { serializeError } from '../../../utils/serializeError';
import Button from '../../ui/button/Button';
import FormButtonComponent from '../../ui/form/FormButtonComponent';
import Icon from '../../ui/icon/Icon';
import ConfirmationModalContent from '../../ui/modal/ConfirmationModalContent';
import ErrorModalContent from '../../ui/modal/ErrorModalContent';
import Modal from '../../ui/modal/Modal';
import ModalFooter from '../../ui/modal/ModalFooter';
import ModalHeader from '../../ui/modal/ModalHeader';
import ModalLoading from '../../ui/modal/ModalLoading';
import { AdditionalTaskDetails, DefaultTaskBodyDescription } from '../task/Task';
import { AxiosError } from 'axios';
import { sendTaskUpdateEvent } from '../../../features/analytics/sendTaskUpdateEvent';

export const VOIE_TYPES = ['credentialed_voie'] as const;

declare global {
  interface Window {
    Truework?: {
      credentials: {
        Env: {
          Sandbox: 'string';
          Production: 'string';
        };
        init: ({ publishableKey, sessionToken, env }: { publishableKey: string; sessionToken: string; env: string }) => {
          open: () => void;
          onOpen: (callback: () => void) => void;
          onClose: (callback: () => void) => void;
          onSuccess: (callback: () => void) => void;
          onError: (callback: (e: Error) => void) => void;
        };
      };
    };
  }
}

const voieStatus = ['idle', 'loading', 'patching', 'success', 'error'] as const;
export type iVoieStatus = (typeof voieStatus)[number];

const errorTypes = [
  'voieOrderResponseInvalid',
  'voieOrderResponseError',
  'trueworkScriptError',
  'trueworkModuleMissing',
  'trueworkOnError',
  'trueworkUnknown',
  'taskPatchError',
] as const;
export type ErrorType = (typeof errorTypes)[number];

export const errorMessages = {
  defaultManually: 'Unable to verify your income manually. Please try again later.',
  defaultAutomatically: 'Unable to verify your income automatically. Please try again later.',
  voieOrderResponseInvalid: undefined,
  voieOrderResponseError: undefined,
  trueworkScriptError: undefined,
  trueworkModuleMissing: undefined,
  trueworkOnError: undefined,
  trueworkUnknown: undefined,
  taskPatchError:
    'Submission was successful, but failed to mark task as completed. Please contact your loan officer for assistance.',
};

const VoieVerifyAssetsAutomatically = ({
  loanGuid,
  task,
  onError,
}: {
  loanGuid: string;
  task: Task<'credentialed_voie'>;
  onError: (error: string | undefined) => void;
}) => {
  const { 'task-id': taskId, 'task-completed': taskCompleted, source, 'task-type': taskType, 'task-meta': taskMeta } = task;
  const { publishableKey: publishableKeyFromTask, sessionToken: sessionTokenFromTask } = taskMeta || {};
  const [orderResponse, setOrderResponse] = useState<VoieOrderResponse>();
  const [status, setStatus] = useState<iVoieStatus>('idle');
  const [scriptLoaded, scriptHasError, scriptError] = useScript({
    src: 'https://js.truework.com/v1',
    skip: status !== 'loading',
  });
  const dispatch = useDispatch();

  const handleError = (errorType: ErrorType, error: string) => {
    setStatus('error');
    onError(errorMessages[errorType] || errorMessages.defaultAutomatically);
    log({ level: 'error', loanGuid, taskId, message: `Failed to create VOIE order: ${errorType} ${error}` });
  };

  useEffect(() => {
    if (status !== 'error') {
      onError(undefined);
    }
  }, [onError, status]);

  useEffect(() => {
    if (scriptHasError) {
      setStatus('error');
      onError(errorMessages['trueworkScriptError'] || errorMessages.defaultAutomatically);
      log({
        loanGuid,
        taskId: taskId,
        level: 'error',
        message: `VOIE: Truework script did not load: ${JSON.stringify({ scriptError })}`,
      });
    }
  }, [loanGuid, scriptError, scriptHasError, scriptLoaded, taskId, onError]);

  useEffect(() => {
    const trueworkModule = window.Truework?.credentials;
    const { uiElement, publishableKey } = orderResponse || {};
    if (status === 'loading' && scriptLoaded && trueworkModule && publishableKey && uiElement?.token) {
      try {
        const credentials = trueworkModule.init({
          publishableKey: publishableKey,
          sessionToken: uiElement.token,
          env: trueworkModule.Env.Production,
        });
        credentials.onOpen(() => {
          setStatus('idle');
        });
        credentials.onSuccess(() => {
          sendTaskUpdateEvent({ taskId, taskType });
          setStatus('success');
          if (!taskCompleted) {
            setStatus('patching');
            dispatch(patchTask(loanGuid, taskId, { source, 'task-completed': true }))
              .then(() => setStatus('success'))
              .catch((error: AxiosError) => {
                setStatus('error');
                handleError('taskPatchError', serializeError(error));
              });
          }
        });
        credentials.onError(e => {
          handleError('trueworkOnError', JSON.stringify(e));
        });
        credentials.onClose(() => {
          setOrderResponse(undefined);
        });
        credentials.open();
      } catch (error) {
        setStatus('error');
        handleError('trueworkUnknown', serializeError(error));
      }
    } else if (scriptLoaded && !trueworkModule) {
      handleError('trueworkModuleMissing', 'Script loaded, but Truework credentials object not found.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded, orderResponse, status]);

  const orderVoie = async () => {
    log({ loanGuid, taskId, level: 'info', message: 'Order VOIE' });
    try {
      const { data } = await api.postVoieOrder(loanGuid);
      if (data.publishableKey && data.uiElement) {
        setOrderResponse(data);
        dispatch(
          patchTask(loanGuid, taskId, {
            source,
            'task-meta': {
              sessionToken: data.uiElement.token,
              orderId: data.orderId,
              publishableKey: data.publishableKey,
            },
          }),
        );
      } else {
        handleError('voieOrderResponseInvalid', JSON.stringify(data));
      }
    } catch (error) {
      handleError('voieOrderResponseError', JSON.stringify(error));
    }
  };

  const handleButtonClick = async () => {
    setStatus('loading');
    try {
      if (!publishableKeyFromTask || !sessionTokenFromTask) {
        orderVoie();
      } else {
        setOrderResponse({ publishableKey: publishableKeyFromTask, uiElement: { token: sessionTokenFromTask } });
      }
    } catch (error) {
      handleError('voieOrderResponseError', serializeError(error));
    }
  };

  return (
    <>
      {!taskCompleted && (
        <FormButtonComponent
          containerClassName='pb-3'
          buttonContainerClassName='mt-0 w-full md:w-fit-content'
          className='w-full md:w-fit-content'
          buttonType='primary'
          onClick={handleButtonClick}
          loading={status === 'loading' || status === 'patching'}
        >
          Verify income automatically
        </FormButtonComponent>
      )}
      {status === 'success' && (
        <div className='flex justify-center items-center mb-3'>
          <Icon name='check-tick' className='text-ok mr-2' size='0.75rem' />
          <p>Your submission was successful</p>
        </div>
      )}
    </>
  );
};

const VoieVerifyAssetsManually = ({
  loanGuid,
  task,
  onError,
}: {
  loanGuid: string;
  task: Task<'credentialed_voie'>;
  onError: (error: string | undefined) => void;
}) => {
  const { 'task-id': taskId, source, 'task-type': taskType } = task;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [status, setStatus] = useState<iVoieStatus>('idle');

  const handleButtonClick = () => {
    log({ loanGuid, taskId, level: 'info', message: 'VOIE: Verify Manually: User clicked "Verify assets manually"' });
    setShowModal(true);
  };

  const handleRequestClose = () => {
    log({ loanGuid, taskId, level: 'info', message: 'VOIE: Verify Manually: User closed modal' });
    setShowModal(false);
  };

  const handleConfirm = async () => {
    sendTaskUpdateEvent({ taskId, taskType });
    log({ loanGuid, taskId, level: 'info', message: 'VOIE: Verify Manually: User confirmed' });
    setStatus('loading');
    try {
      await api.patchLoanTask(loanGuid, taskId, { source, 'task-removed': true, 'task-type': taskType });
      setStatus('success');
      log({
        loanGuid,
        taskId,
        level: 'info',
        message: `VOIE: Verify Manually: Success`,
      });
    } catch (error) {
      setStatus('error');
      log({
        loanGuid,
        taskId,
        level: 'error',
        message: `VOIE: Verify Manually: Task update failed ${serializeError(error)}`,
      });
    }
  };

  const handleCancel = () => {
    log({ loanGuid, taskId, level: 'info', message: 'VOIE: Verify Manually: User cancelled' });
    setShowModal(false);
  };

  const handleModalOpen = () => {
    setStatus('idle');
    onError(undefined);
  };

  const handleModalClosed = () => {
    if (status === 'error') {
      onError(errorMessages.defaultManually);
    }
    if (status === 'success') {
      dispatch(removeTask(loanGuid, taskId));
    }
  };

  const handleModalDone = () => {
    setShowModal(false);
  };

  const ModalContentPrompt = (
    <ConfirmationModalContent
      title='Are you sure you want to provide documents manually?'
      textNode={
        <div className='flex flex-col'>
          <p className='mb-4'>
            We will add multiple tasks to your tasklist asking for documents. This may be less convenient than providing
            verification via automated account access.
          </p>
          <p className='font-bold'>This cannot be undone.</p>
        </div>
      }
      confirmButtonText='Yes, continue'
      onConfirm={handleConfirm}
      cancelButtonText='No, cancel'
      onCancel={handleCancel}
      className='px-6 lg:px-0 max-w-lg lg:max-w-full mx-auto'
    />
  );

  const ModalContentLoading = <ModalLoading title='Hang tight while we send your request.' />;

  const support = useDefaultSupport();
  const ModalContentError = (
    <ErrorModalContent
      onDone={handleModalDone}
      title='We were unable to complete this action'
      location='your tasklist'
      support={support}
      className='px-6 lg:px-0 max-w-lg lg:max-w-full mx-auto'
    />
  );

  const ModalContentSuccess = (
    <div className='px-6 lg:px-0 max-w-lg lg:max-w-full mx-auto'>
      <ModalHeader title='You have opted to verify your income & employment manually.' textAlignment='center' />
      <div className='text-center'>
        <p className='my-4'>New tasks will be assigned to upload documents.</p>
      </div>
      <ModalFooter>
        <Button onClick={() => setShowModal(false)} buttonType='primary' size='large'>
          Back to your tasklist
        </Button>
      </ModalFooter>
    </div>
  );

  return (
    <div className='text-center'>
      <Modal
        open={showModal}
        onOpen={handleModalOpen}
        onClosed={handleModalClosed}
        contentLabel='Verification of Employment - Verify Manually'
        onRequestClose={handleRequestClose}
        fullWidth={true}
      >
        {status === 'loading'
          ? ModalContentLoading
          : status === 'error'
          ? ModalContentError
          : status === 'success'
          ? ModalContentSuccess
          : ModalContentPrompt}
      </Modal>
      <Button buttonType='tertiary' text='or, verify your income manually' onClick={handleButtonClick} />
    </div>
  );
};

const VoieTaskBody = ({ loanGuid, task }: { loanGuid: string; task: Task<'credentialed_voie'> }) => {
  const [error, setError] = useState<string | undefined>();
  const taskDescription = useTaskText(task, 'description', loanGuid);
  const additionalDetails = useAdditionalTaskDetails(task, loanGuid);

  const handleError = (error: string | undefined) => {
    setError(error);
  };

  return (
    <>
      <DefaultTaskBodyDescription taskDescription={taskDescription} />
      {additionalDetails && <AdditionalTaskDetails additionalDetails={additionalDetails} />}
      <div className='flex flex-col justify-center'>
        <VoieVerifyAssetsAutomatically loanGuid={loanGuid} task={task} onError={handleError} />
        {task['task-completed'] ? (
          <div className='flex font-bold justify-center'>You have successfully connected to your payroll.</div>
        ) : (
          <VoieVerifyAssetsManually loanGuid={loanGuid} task={task} onError={handleError} />
        )}
        {error && (
          <div className='text-center h-0'>
            <p className='form-button-error-text text-error-important'>{error}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default VoieTaskBody;
