import Circle from '../circle/Circle';
import Icon from '../icon/Icon';

export const CurrentStepIcon = () => {
  return (
    <Circle size='1.5rem' bgColor='ok-100'>
      <Icon name='check-tick' size='0.7rem' />
    </Circle>
  );
};

// Horizontal Components

export const HorizontalCurrentStepIcon = () => {
  return (
    <div className='w-8 h-8 flex items-center justify-center' style={{ border: '4px white solid' }}>
      <CurrentStepIcon />
    </div>
  );
};

export const LeftCurrentStepWidget = () => {
  return (
    <div className='flex w-full h-8'>
      <div className='flex-1' />
      <div className='mx-1'>
        <HorizontalCurrentStepIcon />
      </div>
      <div className='flex-1 h-1/2 border-b border-inactive-25' />
    </div>
  );
};

export const LeftCompleteStepWidget = () => {
  return (
    <div className='flex w-full h-8'>
      <div className='flex-1' />
      <div className='w-4 h-4 mx-1 rounded-full self-center bg-ok-100' />
      <div className='flex-1 h-1/2 border-b border-ok-100' />
    </div>
  );
};

export const CurrentStepWidget = () => {
  return (
    <div className='flex w-full h-8'>
      <div className='flex-1 h-1/2 border-b border-ok-100' />
      <div className='mx-1'>
        <HorizontalCurrentStepIcon />
      </div>
      <div className='flex-1 h-1/2 border-b border-inactive-25' />
    </div>
  );
};

export const CompleteStepWidget = () => {
  return (
    <div className='flex w-full h-8'>
      <div className='flex-1 h-1/2 border-b border-ok-100' />
      <div className='w-4 h-4 mx-1 rounded-full self-center bg-ok-100' />
      <div className='flex-1 h-1/2 border-b border-ok-100' />
    </div>
  );
};

export const UpcomingStepWidget = () => {
  return (
    <div className='flex w-full h-8'>
      <div className='flex-1 h-1/2 border-b border-inactive-25' />
      <div className='w-4 h-4 mx-1 rounded-full self-center border border-body-100' />
      <div className='flex-1 h-1/2 border-b border-inactive-25' />
    </div>
  );
};

export const LeftUpcomingStepWidget = () => {
  return (
    <div className='flex w-full h-8'>
      <div className='flex-1 h-1/2' />
      <div className='w-4 h-4 mx-1 rounded-full self-center border border-body-100' />
      <div className='flex-1 h-1/2 border-b border-inactive-25' />
    </div>
  );
};

export const RightUpcomingStepWidget = () => {
  return (
    <div className='flex w-full h-8'>
      <div className='flex-1 h-1/2 border-b border-inactive-25' />
      <div className='w-4 h-4 mx-1 rounded-full self-center border border-body-100' />
      <div className='flex-1 h-1/2' />
    </div>
  );
};

export const RightCurrentStepWidget = () => {
  return (
    <div className='flex w-full h-8'>
      <div className='flex-1 h-1/2 border-b border-ok-100' />
      <div className='mx-1'>
        <HorizontalCurrentStepIcon />
      </div>
      <div className='flex-1 h-1/2' />
    </div>
  );
};

// Vertical Components

export const VerticalCurrentStepIcon = () => {
  return (
    <div className='-mt-1.5 w-8 flex items-baseline justify-center' style={{ border: '4px white solid' }}>
      <CurrentStepIcon />
    </div>
  );
};

export const TopCurrentStepWidget = () => {
  return (
    <>
      <VerticalCurrentStepIcon />
      <div className='flex-1 w-1/2 border-r border-inactive-25' />
    </>
  );
};

export const TopCompleteStepWidget = () => {
  return (
    <div className='flex flex-col flex-1 w-8'>
      <div className='flex justify-center'>
        <div className='w-4 h-4 mb-1 rounded-full self-center bg-ok-100' style={{ marginTop: '2px' }} />
      </div>
      <div className='flex-1 w-1/2 border-r border-ok-100' />
    </div>
  );
};

export const BottomUpcomingStepIcon = () => {
  return (
    <div className='flex flex-col flex-1 w-8'>
      <div className='flex justify-center'>
        <div className='w-4 h-4 mb-1 rounded-full self-center border border-body-100' style={{ marginTop: '2px' }} />
      </div>
      <div className='flex-1' />
    </div>
  );
};

export const VerticalUpcomingStepWidget = () => {
  return (
    <div className='flex flex-col flex-1 w-8'>
      <div className='flex justify-center'>
        <div className='w-4 h-4 mb-1 rounded-full self-center border border-body-100' style={{ marginTop: '2px' }} />
      </div>
      <div className='flex-1 w-1/2 border-r border-inactive-25' />
    </div>
  );
};

export const VerticalCurrentStepWidget = () => {
  return (
    <div className='flex flex-col flex-1'>
      <VerticalCurrentStepIcon />
      <div className='flex-1 w-1/2 border-r border-inactive-25' />
    </div>
  );
};

export const VerticalCompleteStepWidget = () => {
  return (
    <div className='flex flex-col flex-1 w-8'>
      <div className='flex justify-center'>
        <div className='w-4 h-4 mb-1 rounded-full self-center bg-ok-100' style={{ marginTop: '2px' }} />
      </div>
      <div className='flex-1 w-1/2 border-r border-ok-100' />
    </div>
  );
};
