import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { FIRST_VISIT_KEY } from '../../hooks/useLoanFirstVisitAt';
import { TASKS_READY_KEY } from '../../hooks/useLoanTasksReadyAt';
import useRetry, { iUseRetryReturn } from '../../hooks/useRetry';
import { log } from '../../utils/logger';
import msToMinutesSeconds from '../../utils/msToMinutesSeconds';
import timestamp from '../../utils/timestamp';
import timestampDifference from '../../utils/timestampDifference';
import {
  isFirstMilestone,
  isLoanAusNotCompleted,
  isLoanReady,
  isLoanTasksNotReady,
  isLoanTasksReady,
  isLoanTasksTimedOut,
  isPreapprovalsEnabled,
  preapprovalsMightExist,
} from '../loans/helpers';
import { fetchLoan, fetchLoanFeatures, selectLoanEntity, setLoanTasksTimedOut, setLoanAusTimedOut } from '../loans/loansSlice';
import type { Loan } from '../loans/types';
import { LoanMilestones } from '../loans/types';
import {
  fetchPreapprovals,
  fetchScenarioPreapprovalLetter,
  selectPreapprovalsByLoanGuid,
  selectScenarioPreapprovalLetter,
  usePreapprovalLetterLevel,
  useShouldGeneratePreapproval,
} from '../preapprovals/preapprovalsSlice';
import { fetchTasks } from '../tasks/actions';
import { hasLoanTaskError, hasLoanTasksData } from '../tasks/selectors';
import { selectUserLoanAttributesByLoan } from '../user/userSlice';

export const SETTINGS = {
  pollLoanDuration: 10000,
  // 24 retries every 10 secs is ~4 minutes. Math isn't 100% accurate.
  maxTasksReadyRetries: 24,
  // 3 retries every 10 secs is ~30 seconds. Math isn't 100% accurate.
  maxAusCompletedRetries: 3,
};

// Retry Hook for tasks ready
export const useRetryForTasksReady = (loan: Loan): iUseRetryReturn => {
  const { 'loan-guid': loanGuid } = loan;
  const dispatch = useAppDispatch();
  const startTimestampRef = useRef(timestamp());
  const firstVisitAt = useAppSelector(state => selectUserLoanAttributesByLoan(state, loanGuid))?.[FIRST_VISIT_KEY];

  const logWithTimestamps = (message: string) => {
    const now = timestamp();
    const timeSinceWaiting = msToMinutesSeconds(timestampDifference(now, startTimestampRef.current)) + ' minutes';
    const timeSinceFirstVisit = firstVisitAt ? msToMinutesSeconds(timestampDifference(now, firstVisitAt)) + ' minutes' : null;
    log({
      loanGuid,
      message: `useRetryForTasksReady: ${message} ${JSON.stringify({
        firstVisitAt,
        timeSinceFirstVisit,
        startTimestamp: startTimestampRef.current,
        timeSinceWaiting,
      })}`,
    });
  };

  const handleRetry = ({ retryNum, totalTime }: { retryNum: number; totalTime: number }) => {
    logWithTimestamps(
      `Retrying loan features. ${JSON.stringify({
        retryNum,
        totalTime,
      })}`,
    );
    dispatch(fetchLoanFeatures(loanGuid));
  };

  const handleTimedOut = ({ retryNum, totalTime }: { retryNum: number; totalTime: number }) => {
    logWithTimestamps(
      `Giving up. ${JSON.stringify({
        retryNum,
        totalTime,
      })}`,
    );
    // One last retry is triggered on timeout, let's wait another poll duration before timing out
    setTimeout(() => dispatch(setLoanTasksTimedOut(loanGuid)), SETTINGS.pollLoanDuration);
  };

  return useRetry({
    shouldRetry: isLoanTasksNotReady(loan),
    triggerRetryOnInit: false,
    maxRetries: SETTINGS.maxTasksReadyRetries,
    retryDuration: SETTINGS.pollLoanDuration,
    onRetry: handleRetry,
    onTimedOut: handleTimedOut,
  });
};

// Retry Hook for AUS Completion
export const useRetryForAusCompleted = (loan: Loan, skip?: boolean): iUseRetryReturn => {
  const { 'loan-guid': loanGuid, 'loan-milestone': loanMilestone } = loan;
  const dispatch = useDispatch();

  const handleRetry = ({ retryNum, totalTime }: { retryNum: number; totalTime: number }) => {
    log({
      loanGuid,
      message: `useRetryForAusCompleted: Re-fetching loan features. ${JSON.stringify({
        retryNum,
        totalTime,
      })}`,
    });
    dispatch(fetchLoanFeatures(loanGuid));
  };

  const handleTimedOut = ({ retryNum, totalTime }: { retryNum: number; totalTime: number }) => {
    log({
      loanGuid,
      message: `useRetryForAusCompleted: Giving up. ${JSON.stringify({
        retryNum,
        totalTime,
      })}`,
    });
    // One last retry is triggered on timeout, let's wait another poll duration before timing out
    setTimeout(() => dispatch(setLoanAusTimedOut(loanGuid)), SETTINGS.pollLoanDuration);
  };

  return useRetry({
    shouldRetry: !skip && loanMilestone === LoanMilestones.PREAPPROVAL && isLoanTasksReady(loan) && isLoanAusNotCompleted(loan),
    maxRetries: SETTINGS.maxAusCompletedRetries,
    retryDuration: SETTINGS.pollLoanDuration,
    onRetry: handleRetry,
    onTimedOut: handleTimedOut,
  });
};

// 1. If tasks have timed out, skip waiting UI and show the timed out experience
// 2. If tasks are not ready, show the waiting UI, initialize the loan
// 3. If tasks are ready AND tasklist has not been shown AND loan is first milestone,
//    show the waiting UI, initialize the loan
// 4. If tasks are ready, AND (tasklist has already been shown OR loan milestone is
//    beyond the first milestone), skip waiting UI
const useShouldInitialize = (loan: Loan): boolean => {
  const { 'loan-guid': loanGuid } = loan;
  const tasksPreviouslyReady = !!useAppSelector(state => selectUserLoanAttributesByLoan(state, loanGuid))?.[TASKS_READY_KEY];
  const ready = isLoanReady(loan);
  const tasksTimedout = isLoanTasksTimedOut(loan);
  const firstMilestone = isFirstMilestone(loan);
  return !tasksTimedout && (!ready || (firstMilestone && !tasksPreviouslyReady));
};

// Controls Waiting for Tasks Boolean
let hasLoggedDisableWaiting = false;
export const useWaitingForTasks = (
  loan: Loan,
): {
  waiting: boolean;
  initialWaiting: boolean;
} => {
  const {
    'loan-guid': loanGuid,
    'generate-l1-pal?': shouldGenerateL1,
    'generate-l2-pal?': shouldGenerateL2,
    'application-status': applicationStatus,
  } = loan;
  const shouldInitialize = useShouldInitialize(loan);
  const loanApplicationCompleted = applicationStatus === 'completed';
  const tasksTimedout = isLoanTasksTimedOut(loan);
  const ausTimedout = isLoanTasksTimedOut(loan);
  const tasksHasError = useAppSelector(state => hasLoanTaskError(state, loanGuid));
  const tasksHasData = useAppSelector(state => hasLoanTasksData(state, loanGuid));
  const tasksReadyAndHasData = isLoanTasksReady(loan) && tasksHasData;

  // Pre-approval statuses
  const checkForPreapprovals = preapprovalsMightExist(loan);
  const { loadingStatus: preapprovalsLoadingStatus } = useAppSelector(state => selectPreapprovalsByLoanGuid(state, loanGuid));
  const { data: scenarioPreapprovalData, loadingStatus: scenarioPreapprovalLoadingStatus } = useAppSelector(state =>
    selectScenarioPreapprovalLetter(state, loanGuid),
  );
  // will generate, is generating, or has generated a pre-approval letter
  const willGeneratePreapproval = scenarioPreapprovalLoadingStatus !== 'idle' || shouldGenerateL1 || shouldGenerateL2;

  const [initialWaiting] = useState(shouldInitialize);
  const [waiting, setWaiting] = useState(initialWaiting);

  // Dismiss waiting animation when...
  // • tasks ready flag has timed out or
  // • tasks are ready but there was an error loading tasks
  useEffect(() => {
    if (waiting && (tasksTimedout || tasksHasError)) {
      if (!hasLoggedDisableWaiting) {
        hasLoggedDisableWaiting = true;
        log({
          loanGuid,
          message: `useWaitingForTasks: Disabling waiting animation because tasks timed out or an error occured loading tasks. ${JSON.stringify(
            {
              tasksTimedout,
              tasksHasError,
            },
          )}`,
        });
      }
      setWaiting(false);
    }
  }, [loanGuid, waiting, tasksTimedout, tasksHasError]);

  // Dismiss waiting animation when...
  // • loan application is completed
  // • tasks are ready, and we have tasks data
  // • pre-approvals are not enabled
  // • if pre-approvals are enabled, we know they don't exist
  useEffect(() => {
    if (waiting && loanApplicationCompleted && tasksReadyAndHasData && !willGeneratePreapproval && !checkForPreapprovals) {
      if (!hasLoggedDisableWaiting) {
        hasLoggedDisableWaiting = true;
        log({
          loanGuid,
          message: `useWaitingForTasks: Disabling waiting animation because tasks are ready. We assume pre-approval letters don't need to be fetched or pre-approvals are not enabled for this loan. ${JSON.stringify(
            {
              loanApplicationCompleted,
              tasksReadyAndHasData,
              willGeneratePreapproval,
              checkForPreapprovals,
            },
          )}`,
        });
      }
      setWaiting(false);
    }
  }, [loanGuid, waiting, loanApplicationCompleted, tasksReadyAndHasData, willGeneratePreapproval, checkForPreapprovals]);

  // Dismiss waiting animation when...
  // • loan application is completed
  // • tasks are ready, and when we have tasks data
  // • pre-approvals are enabled
  // • loan doesn't qualify for pre-approval letter generation
  // • we have pre-approval data or an error occured
  useEffect(() => {
    if (
      waiting &&
      loanApplicationCompleted &&
      tasksReadyAndHasData &&
      !willGeneratePreapproval &&
      checkForPreapprovals &&
      ['success', 'failed'].includes(preapprovalsLoadingStatus)
    ) {
      if (!hasLoggedDisableWaiting) {
        hasLoggedDisableWaiting = true;
        log({
          loanGuid,
          message: `useWaitingForTasks: Disabling waiting animation because tasks are ready. Scenario pre-approval letter generation is not enabled but we should check for externally generated pre-approval letters. Pre-approvals were fetched or errored. ${JSON.stringify(
            {
              loanApplicationCompleted,
              tasksReadyAndHasData,
              willGeneratePreapproval,
              checkForPreapprovals,
              preapprovalsLoadingStatus,
            },
          )}`,
        });
      }
      setWaiting(false);
    }
  }, [
    loanGuid,
    waiting,
    loanApplicationCompleted,
    tasksReadyAndHasData,
    willGeneratePreapproval,
    checkForPreapprovals,
    preapprovalsLoadingStatus,
  ]);

  // Dismiss waiting animation when...
  // • loan application is completed
  // • tasks are ready, and when we have tasks data
  // • pre-approvals are enabled
  // • generating a pre-approval letter was attempted
  // • there aren't any pre-approval letters
  // TODO Assuming no pre-approval letters exist but maybe logs will prove us wrong
  useEffect(() => {
    if (
      waiting &&
      loanApplicationCompleted &&
      tasksReadyAndHasData &&
      willGeneratePreapproval &&
      (scenarioPreapprovalLoadingStatus === 'failed' ||
        (scenarioPreapprovalLoadingStatus === 'success' && !scenarioPreapprovalData?.id))
    ) {
      if (!hasLoggedDisableWaiting) {
        hasLoggedDisableWaiting = true;
        log({
          loanGuid,
          message: `useWaitingForTasks: Disabling waiting animation because tasks are ready. Scenario pre-approval letter was attempted but no letter was generated. Pre-approval letters should not exist. ${JSON.stringify(
            {
              loanApplicationCompleted,
              tasksReadyAndHasData,
              willGeneratePreapproval,
              shouldGenerateL1,
              shouldGenerateL2,
              scenarioPreapprovalLoadingStatus,
              scenarioPreapprovalDataId: scenarioPreapprovalData?.id,
              preapprovalsLoadingStatus,
            },
          )}`,
        });
      }
      setWaiting(false);
    }
  }, [
    loanGuid,
    waiting,
    loanApplicationCompleted,
    tasksReadyAndHasData,
    willGeneratePreapproval,
    shouldGenerateL1,
    shouldGenerateL2,
    scenarioPreapprovalLoadingStatus,
    scenarioPreapprovalData?.id,
    preapprovalsLoadingStatus,
  ]);

  // Dismiss waiting animation when...
  // • loan application is completed
  // • tasks are ready, and when we have tasks data
  // • generating a pre-approval letter was generated
  // • we have pre-approval data or an error occured
  useEffect(() => {
    if (
      waiting &&
      loanApplicationCompleted &&
      tasksReadyAndHasData &&
      willGeneratePreapproval &&
      scenarioPreapprovalLoadingStatus === 'success' &&
      scenarioPreapprovalData?.id &&
      ['success', 'failed'].includes(preapprovalsLoadingStatus)
    ) {
      if (!hasLoggedDisableWaiting) {
        hasLoggedDisableWaiting = true;
        log({
          loanGuid,
          message: `useWaitingForTasks: Disabling waiting animation because tasks are ready. Scenario pre-approval letter was successfully generated. Pre-approvals were fetched or errored. ${JSON.stringify(
            {
              loanApplicationCompleted,
              tasksReadyAndHasData,
              willGeneratePreapproval,
              shouldGenerateL1,
              shouldGenerateL2,
              scenarioPreapprovalLoadingStatus,
              scenarioPreapprovalDataId: scenarioPreapprovalData?.id,
              preapprovalsLoadingStatus,
            },
          )}`,
        });
      }
      setWaiting(false);
    }
  }, [
    loanGuid,
    waiting,
    loanApplicationCompleted,
    tasksReadyAndHasData,
    willGeneratePreapproval,
    shouldGenerateL1,
    shouldGenerateL2,
    scenarioPreapprovalLoadingStatus,
    scenarioPreapprovalData?.id,
    preapprovalsLoadingStatus,
  ]);

  // Dismiss waiting animation when...
  // • loan application is completed
  // • tasks are ready, and when we have tasks data
  // • should generate L2 pre-approval
  // • AUS flag has timed out and is not fetching
  useEffect(() => {
    if (
      waiting &&
      loanApplicationCompleted &&
      tasksReadyAndHasData &&
      shouldGenerateL2 &&
      scenarioPreapprovalLoadingStatus === 'idle' &&
      ausTimedout
    ) {
      if (!hasLoggedDisableWaiting) {
        hasLoggedDisableWaiting = true;
        log({
          loanGuid,
          message: `useWaitingForTasks: Disabling waiting animation because aus has timed out while attempting to generate an L2 pre-approval letter. ${JSON.stringify(
            {
              loanApplicationCompleted,
              tasksReadyAndHasData,
              shouldGenerateL2,
              scenarioPreapprovalLoadingStatus,
              ausTimedout,
            },
          )}`,
        });
      }
      setWaiting(false);
    }
  }, [
    loanGuid,
    waiting,
    shouldGenerateL2,
    scenarioPreapprovalLoadingStatus,
    ausTimedout,
    loanApplicationCompleted,
    tasksReadyAndHasData,
  ]);

  return {
    waiting,
    initialWaiting,
  };
};

// Initializes loan setup while waiting for tasks animation is shown
export const useWaitingForTasksSetup = (loan: Loan): { waiting: boolean; initialWaiting: boolean } => {
  const { 'loan-guid': loanGuid, 'generate-l2-pal?': shouldGenerateL2, 'application-status': applicationStatus } = loan;
  const dispatch = useAppDispatch();
  const oldDispatch = useDispatch();
  const { waiting, initialWaiting } = useWaitingForTasks(loan);
  const preapprovalsEnabled = isPreapprovalsEnabled(loan);
  const checkForPreapprovals = preapprovalsMightExist(loan);
  const { shouldGenerateL1Preapproval, shouldGenerateL2Preapproval } = useShouldGeneratePreapproval(loan);
  const shouldGeneratePreapproval = shouldGenerateL1Preapproval || shouldGenerateL2Preapproval;
  const level = usePreapprovalLetterLevel(loan);
  const tasksReady = isLoanTasksReady(loan);
  const tasksHasData = useAppSelector(state => hasLoanTasksData(state, loanGuid));
  const tasksHasError = useAppSelector(state => hasLoanTaskError(state, loanGuid));
  const { isFetching: isLoanFetching } = useAppSelector(state => selectLoanEntity(state, loanGuid));
  const { data: scenarioPreapprovalData, loadingStatus: scenarioPreapprovalLoadingStatus } = useAppSelector(state =>
    selectScenarioPreapprovalLetter(state, loanGuid),
  );
  const { hasData: preapprovalsHasData } = useAppSelector(state => selectPreapprovalsByLoanGuid(state, loanGuid));
  const fetchingTasksRef = useRef<boolean>(false);

  useRetryForTasksReady(loan);
  useRetryForAusCompleted(loan, !shouldGenerateL2);

  // Fetch Tasks
  // When tasks are ready
  useEffect(() => {
    if (waiting && tasksReady && !fetchingTasksRef.current && !tasksHasData && !tasksHasError) {
      log({
        loanGuid,
        message: `useWaitingForTasksSetup: Tasks are ready. Fetching tasks. ${JSON.stringify({
          tasksReady,
          tasksHasData,
          tasksHasError,
        })}`,
      });
      fetchingTasksRef.current = true;
      oldDispatch(fetchTasks(loanGuid)).finally(() => {
        fetchingTasksRef.current = false;
      });
    }
  }, [oldDispatch, loanGuid, waiting, tasksReady, tasksHasData, tasksHasError]);

  // Re-fetch loan
  // When tasks are ready, and application status is not complete
  // Yes this is needed. Logs show that application status is often not-editable when entering MyAccount
  // L1 pre-approval generation requires application status to be 'completed'
  useEffect(() => {
    if (waiting && tasksReady && !isLoanFetching && applicationStatus !== 'completed') {
      log({
        loanGuid,
        message: `useWaitingForTasksSetup: Tasks are ready. Loan is out of sync. Fetching loan again. ${JSON.stringify({
          tasksReady,
          applicationStatus,
        })}`,
      });
      dispatch(fetchLoan(loanGuid));
    }
  }, [dispatch, loanGuid, waiting, tasksReady, isLoanFetching, applicationStatus]);

  // Generate pre-approval letter
  // When loan qualifies for pre-approval generation and previously hasn't been attempted
  useEffect(() => {
    if (waiting && shouldGeneratePreapproval && level && scenarioPreapprovalLoadingStatus === 'idle') {
      const readyPhrase = level === '2' ? 'Tasks are ready.' : 'DMX application status is completed.';
      log({ loanGuid, message: `useWaitingForTasksSetup: ${readyPhrase} Generating Level ${level} pre-approval letter.` });
      dispatch(fetchScenarioPreapprovalLetter({ loanGuid, level }));
    }
  }, [dispatch, loanGuid, waiting, shouldGeneratePreapproval, level, scenarioPreapprovalLoadingStatus]);

  // Fetch pre-approvals
  // When tasks are ready, pre-approvals are enabled,
  // Loan doesn't qualify for pre-approval letter generation and pre-approval letters might exist
  // Generating a letter failed and pre-approval letters might exist
  // We generated a letter
  useEffect(() => {
    if (
      waiting &&
      tasksReady &&
      preapprovalsEnabled &&
      !preapprovalsHasData &&
      ((!shouldGeneratePreapproval && checkForPreapprovals) ||
        (shouldGeneratePreapproval && scenarioPreapprovalLoadingStatus === 'success' && scenarioPreapprovalData?.id))
    ) {
      log({
        loanGuid,
        message: `useWaitingForTasksSetup: Fetching pre-approval letters. ${JSON.stringify({
          tasksReady,
          preapprovalsEnabled,
          preapprovalsHasData,
          checkForPreapprovals,
          shouldGeneratePreapproval,
          level,
          scenarioPreapprovalLoadingStatus,
          scenarioPreapprovalDataId: scenarioPreapprovalData?.id,
        })}`,
      });
      dispatch(fetchPreapprovals(loanGuid));
    }
  }, [
    dispatch,
    loanGuid,
    waiting,
    tasksReady,
    preapprovalsEnabled,
    preapprovalsHasData,
    shouldGeneratePreapproval,
    checkForPreapprovals,
    scenarioPreapprovalLoadingStatus,
    scenarioPreapprovalData?.id,
    level,
  ]);

  // Refetch loan
  // When an approved pre-approval letter was generated
  useEffect(() => {
    if (
      waiting &&
      !isLoanFetching &&
      shouldGeneratePreapproval &&
      scenarioPreapprovalLoadingStatus === 'success' &&
      scenarioPreapprovalData?.hasApproval
    ) {
      log({
        loanGuid,
        message: `useWaitingForTasksSetup: An approved pre-approval letter was generated. Fetching loan again. ${JSON.stringify({
          shouldGeneratePreapproval,
          scenarioPreapprovalLoadingStatus,
          scenarioPreapprovalDataHasApproval: scenarioPreapprovalData?.hasApproval,
        })}`,
      });
      dispatch(fetchLoan(loanGuid));
    }
  }, [
    dispatch,
    loanGuid,
    waiting,
    isLoanFetching,
    shouldGeneratePreapproval,
    scenarioPreapprovalLoadingStatus,
    scenarioPreapprovalData?.hasApproval,
  ]);

  return {
    waiting,
    initialWaiting,
  };
};
