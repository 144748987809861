import { useCallback, useEffect, useState } from 'react';
import Alert from '../../ui/alert/Alert';
import Modal from '../../ui/modal/Modal';
import FileUpload from '../../ui/upload/FileUpload';
import DocumentSuccessImage from '../../../images/document-success.svg';
import { linkTo, PATHS } from '../../routes/paths';
import api from '../../../features/api';
import ConfirmationModalContent from '../../ui/modal/ConfirmationModalContent';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { sendEvent } from '../../../features/analytics/sendEvent';
import Button from '../../ui/button/Button';
import { ButtonProps } from '../../ui/button/IButton';
import { log } from '../../../utils/logger';

const UploadSuccess = ({ loanGuid, closeModal, confirmText }: { loanGuid: string; closeModal: () => void; confirmText: string; }) => {
  const history = useHistory();
  return (
    <ConfirmationModalContent
      imageSrc={DocumentSuccessImage}
      title='Upload successful'
      text='You have successfully submitted files to your loan team. To view and download your files, visit the documents page.'
      confirmButtonText={confirmText}
      onConfirm={() => {
        closeModal();
      }}
      cancelButtonText='Go to documents'
      onCancel={() => {
        history.push(linkTo(PATHS.homeLoanDocuments, { guid: loanGuid }));
      }}
    />
  );
};

const ClosePrompt = ({ onConfirm, onCancel }: { onConfirm: () => void; onCancel: () => void }) => {
  return (
    <ConfirmationModalContent
      title='Are you sure you want to abandon your uploaded files?'
      text='There are uploaded documents that have not been submitted. All abandoned documents will be removed upon exiting this screen.'
      confirmButtonText='Yes, continue'
      onConfirm={onConfirm}
      cancelButtonText='No, cancel'
      onCancel={onCancel}
    />
  );
};

const GenericUploadAlert = ({ loanGuid, onModalToggle }: { loanGuid: string; onModalToggle: (open: boolean) => void }) => {
  return (
    <Alert
      type='info'
      description="You've completed all your tasks, upload any additional documents as requested."
      showClose={false}
      auxButton={
        <GenericUploadButton
          loanGuid={loanGuid}
          onModalToggle={onModalToggle}
          buttonProps={{ text: 'Upload documents', buttonType: 'tertiary', className: 'font-bold', color: 'action-125' }}
          source='LoanTaskList'
          backToText='Back to task list'
        />
      }
    />
  );
};

export const GenericUploadButton = ({
  loanGuid,
  onModalToggle,
  buttonProps,
  source,
  backToText,
}: {
  loanGuid: string;
  onModalToggle?: (open: boolean) => void;
  buttonProps: ButtonProps;
  source?: string;
  backToText: string;
}) => {
  const [showModal, setShowModal] = useState<boolean | undefined>(undefined);
  const [filesSelected, setFilesSelected] = useState(false);
  const [showFileSelector, setShowFileSelector] = useState(false);
  const [showClosePrompt, setShowClosePrompt] = useState(false);
  const [showUploadSuccess, setShowUploadSuccess] = useState(false);

  const handleModalToggle = useCallback(
    (showModal: boolean) => {
      log({ loanGuid, message: `GenericUpload: Modal ${showModal ? 'opened' : 'closed'} ${JSON.stringify({ source })}` });
      onModalToggle?.(showModal);
    },
    [loanGuid, source, onModalToggle],
  );

  useEffect(() => {
    if (showModal !== undefined) {
      handleModalToggle(showModal);
    }
  }, [handleModalToggle, showModal]);

  const handleUploadButton = () => {
    setFilesSelected(false);
    setShowFileSelector(true);
    setShowClosePrompt(false);
    setShowUploadSuccess(false);
    setShowModal(true);
  };

  const preventCloseModal = () => {
    if (showFileSelector) {
      if (filesSelected) {
        setShowFileSelector(false);
        setShowClosePrompt(true);
        setShowUploadSuccess(false);
        return true;
      }
    }
    return false;
  };

  const handleSelectedFiles = (files: File[]) => {
    setFilesSelected(files.length > 0);
  };

  const uploadFile = (file: File) => {
    return api.postDocument(loanGuid, file);
  };

  const doneUploadChecks = (hasError: boolean) => {
    log({ loanGuid, message: `GenericUpload: uploaded files has error ${hasError}` });
    if (hasError) {
      setShowFileSelector(true);
      setShowClosePrompt(false);
      setShowUploadSuccess(false);
    } else {
      setShowFileSelector(false);
      setShowClosePrompt(false);
      setShowUploadSuccess(true);
    }
  };

  const onSubmitFiles = (files: File[]) => {
    log({ loanGuid, message: 'GenericUpload: Submitted files' });
    sendEvent('genericDocumentsSubmit', { loanGuid, count: files.length });
  };

  const handleRequestClose = () => {
    if (showFileSelector || showUploadSuccess) {
      setShowModal(false);
    } else if (showClosePrompt) {
      setShowFileSelector(true);
      setShowClosePrompt(false);
    }
  };

  return (
    <>
      <Button onClick={handleUploadButton} {...buttonProps} />
      <Modal
        open={!!showModal}
        preventClose={preventCloseModal}
        onRequestClose={handleRequestClose}
        // always trigger close when clicking overlay. handleOnClose controls if modal is actually closed though
        shouldCloseOnOverlayClick={true}
        contentLabel='Upload Files'
      >
        {showUploadSuccess && <UploadSuccess loanGuid={loanGuid} closeModal={() => setShowModal(false)} confirmText={backToText} />}
        {showClosePrompt && (
          <ClosePrompt
            onConfirm={() => {
              setShowModal(false);
            }}
            onCancel={() => {
              setShowFileSelector(true);
              setShowClosePrompt(false);
            }}
          />
        )}
        <div className={cn({ hidden: !showFileSelector })}>
          <FileUpload
            loanGuid={loanGuid}
            selectedFilesCallback={handleSelectedFiles}
            processFile={uploadFile}
            doneProcessCallback={doneUploadChecks}
            submitFilesCallback={onSubmitFiles}
            scrollIntoViewOnSelect={false}
          />
        </div>
      </Modal>
    </>
  );
};

export default GenericUploadAlert;
