import { TaskTemplate } from '../../../components/tasks/task/Task';
import TaskModal from '../../../components/tasks/tasklist/TaskModal';
import {
  taskAffirmations,
  taskAgentConsent,
  taskCreditLox,
  taskDataLox,
  taskDocutech,
  taskDocutechPending,
  taskEConsent,
  taskInterpretatoin,
  taskLangPref,
  taskPayment,
  taskPaymentNoPayment,
  taskTwnConsent,
  taskUpload,
  taskUploadCompleted,
  taskUploadInsurance,
  taskVendorVoa,
  taskVoa,
  taskVoai,
  taskVoie,
  taskVoieReverify
} from '../../../mocks/tasks';

const TEST_LOAN_GUID = '6b7ee090-93c3-4110-a4f3-fd6336383b9d';

const TasklistStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Mock tasks list</div>
      <TaskTemplate task={taskAgentConsent} loanGuid={TEST_LOAN_GUID} index={16} />
      <TaskTemplate task={taskEConsent} loanGuid={TEST_LOAN_GUID} index={0} />
      <TaskTemplate task={taskTwnConsent} loanGuid={TEST_LOAN_GUID} index={1} />
      <TaskTemplate task={taskLangPref} loanGuid={TEST_LOAN_GUID} index={2} />
      <TaskTemplate task={taskPayment} loanGuid={TEST_LOAN_GUID} index={3} />
      <TaskTemplate task={taskPaymentNoPayment} loanGuid={TEST_LOAN_GUID} index={4} />
      <TaskTemplate task={taskDataLox} loanGuid={TEST_LOAN_GUID} index={5} />
      <TaskTemplate task={taskCreditLox} loanGuid={TEST_LOAN_GUID} index={6} />
      <TaskTemplate task={taskDocutech} loanGuid={TEST_LOAN_GUID} index={7} />
      <TaskTemplate task={taskDocutechPending} loanGuid={TEST_LOAN_GUID} index={8} />
      <TaskTemplate task={taskVoa} loanGuid={TEST_LOAN_GUID} index={9} />
      <TaskTemplate task={taskVendorVoa} loanGuid={TEST_LOAN_GUID} index={10} />
      <TaskTemplate task={taskVoie} loanGuid={TEST_LOAN_GUID} index={11} />
      <TaskTemplate task={taskVoieReverify} loanGuid={TEST_LOAN_GUID} index={12} />
      <TaskTemplate task={taskAffirmations} loanGuid={TEST_LOAN_GUID} index={13} />
      <TaskTemplate task={taskInterpretatoin} loanGuid={TEST_LOAN_GUID} index={14} />
      <TaskTemplate task={taskUpload} loanGuid={TEST_LOAN_GUID} index={15} />
      <TaskTemplate task={taskUploadCompleted} loanGuid={TEST_LOAN_GUID} index={16} />
      <TaskTemplate task={taskUploadInsurance} loanGuid={TEST_LOAN_GUID} index={17} />
      <TaskTemplate task={taskVoai} loanGuid={TEST_LOAN_GUID} index={18} />
      <div>
        <TaskModal />
      </div>
    </>
  );
};

export default TasklistStyleguide;
