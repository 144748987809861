import { useState } from 'react';
import Button from './Button';
import cn from 'classnames';

interface Props {
  error?: boolean;
  label: string;
  onClick?: () => void;
  className?: string;
};

const OneClickButton = ({ error, label, onClick, className }: Props) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    onClick?.();
  };

  return (
    <div className={cn('flex', className)}>
      <Button
        buttonType='secondary'
        type='button'
        className={
          cn(
            'text-base h-10 min-w-0-important text-body-100',
            { 'border-criticalText text-criticalText': error },
            { 'border-action-100 bg-action-10 hover:border-action-100 hover:bg-action-10 hover:text-body-100': clicked },
            { 'border-input-50 hover:text-body-100 hover:border-action-100 hover:bg-input-10': !clicked },
          )
        }
        text={label}
        size='small'
        onClick={handleClick}
      />
    </div>
  );
};

export default OneClickButton;
