import Carousel from '../../components/ui/carousel/Carousel';
import { useArticleLinks } from '../../features/managedContent/managedContentSlice';
import { ArticleLink } from '../../features/managedContent/types';

const getArticleWidgets = (articles: ArticleLink[]) => {
  const widgets = [];

  for (let i = 0; i < articles.length; i++) {
    const art1 = articles[i];
    if (articles.length > i + 1) {
      const art2 = articles[i + 1];
      widgets.push(
        <div className='keen-slider__slide flex flex-col space-y-6'>
          <ArticlePill article={art1} />
          <ArticlePill article={art2} />
        </div>
      );
      i++;
    } else {
      widgets.push(
        <div className='keen-slider__slide flex flex-col'>
          <ArticlePill article={art1} />
        </div>
      );
    }
  }

  return widgets;
};

export const ArticlePill = ({ article }: { article: ArticleLink }) => {
  return (
    <div className='flex flex-row items-center justify-between rounded' style={{ height: '86px', backgroundColor: '#eee6d9' }}>
      <div className='flex flex-col px-6'>
        <p className='font-bold'>{article.title}</p>
        {article.subtitle && <p>{article.subtitle}</p>}
      </div>
      <div style={{ width: '86px', height: '86px' }}>
        <img style={{ minWidth: '86px', height: '86px' }} className='object-cover rounded-r' src={article.imageLink} alt={article.title} />
      </div>
    </div>
  );
};

export const DashboardArticlesPresentational = ({articles}: { articles: ArticleLink[] }) => {
  const widgets = getArticleWidgets(articles);
  return (
    <Carousel items={widgets} showButtons={false} showDots={true} />
  );
};

const DashboardArticles = ({ className }: { className?: string }) => {
  const articles = useArticleLinks('loanInProcess');

  if (articles === undefined) return null;

  return (
    <div className={className}>
      <DashboardArticlesPresentational articles={articles} />
    </div>
  );
};

export default DashboardArticles;
