import { selectAppLanguage, selectGlobalAdtrk } from '../features/app/appSlice';
import { selectCurrentLo } from '../features/user/selectCurrentLo';
import { dmxApplication } from '../utils/grLinks';
import { useAppSelector } from './hooks';
import { useMultilingual } from './useMultilingual';

const useStartAnApplicationLink = () => {
  const multilingual = useMultilingual();
  const appLanguage = useAppSelector(selectAppLanguage);
  const langPref = (multilingual && appLanguage) ? appLanguage : undefined;
  const lo = useAppSelector(selectCurrentLo);
  const adtrk = useAppSelector(selectGlobalAdtrk);
  return { lo, link: dmxApplication({ 'emp-id': lo?.['emp-id'], langPref, adtrk }) };
};

export default useStartAnApplicationLink;
