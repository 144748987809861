import { AlpHELOCLoanCardPresentational } from '../../../components/loan/loanCard/AlpHELOCLoanCard';
import { HomeLoanCardPresentational } from '../../../components/loan/loanCard/HomeLoanCard';
import { DashboardAlpHELOCLoanCardPresentational } from '../../../components/loan/loanCard/DashboardAlpHELOCLoanCard';
import { DashboardHELOCLoanCardPresentational } from '../../../components/loan/loanCard/DashboardHELOCLoanCard';
import { DashboardHomeLoanCardPresentational } from '../../../components/loan/loanCard/DashboardHomeLoanCard';
import { DashboardNoLoansCard } from '../../../components/loan/loanCard/DashboardLoanCards';
import { DashboardPersonalLoanCardPresentational } from '../../../components/loan/loanCard/DashboardPersonalLoanCard';
import { AlpMilestone } from '../../../features/loans/types';
import { getAlpHELOCLoan, getFundedAlpHELOCLoan } from '../../../mocks/alpHelocLoanInprocess';
import helocLoanFunded from '../../../mocks/helocLoanFunded';
import loanFunded from '../../../mocks/loanFunded';
import loanInprocess from '../../../mocks/loanInprocess';
import { personalLoanInprocess } from '../../../mocks/personalLoanInprocess';
import { HELOCLoanCardPresentational } from '../../../components/loan/loanCard/HELOCLoanCard';
import { PersonalLoanCardPresentational } from '../../../components/loan/loanCard/PersonalLoanCard';

const HomeLoanCard1 = () => {
  const loan = loanInprocess;
  return (
    <>
      <p>Old</p>
      <HomeLoanCardPresentational loan={loan} />
      <p>New</p>
      <DashboardHomeLoanCardPresentational loan={loan} />
    </>
  );
};

const HomeLoanCard2 = () => {
  const loan = loanFunded;
  loan['lead-source'] = 'rdc';
  return (
    <>
      <p>Old</p>
      <HomeLoanCardPresentational loan={loan} />
      <p>New</p>
      <DashboardHomeLoanCardPresentational loan={loan} />
    </>
  );
};

const VaHomeLoanCard1 = () => {
  const loan = { ...loanInprocess };
  loan['va?'] = true;
  return (
    <>
      <p>Old</p>
      <HomeLoanCardPresentational loan={loan} />
      <p>New</p>
      <DashboardHomeLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard1 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.APPLICATION);
  //return <AlpHELOCLoanCardPresentational loan={loan} />;
  return (
    <>
      <p>Old</p>
      <AlpHELOCLoanCardPresentational loan={loan} />
      <p>New</p>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard2 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.PREAPPROVED);
  return (
    <>
      <p>Old</p>
      <AlpHELOCLoanCardPresentational loan={loan} />
      <p>New</p>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard3 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.REVIEWED);
  return (
    <>
      <p>Old</p>
      <AlpHELOCLoanCardPresentational loan={loan} />
      <p>New</p>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard4 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.APPROVED);
  return (
    <>
      <p>Old</p>
      <AlpHELOCLoanCardPresentational loan={loan} />
      <p>New</p>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard5 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.DENIED);
  return (
    <>
      <p>Old</p>
      <AlpHELOCLoanCardPresentational loan={loan} />
      <p>New</p>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard6 = () => {
  const loan = getFundedAlpHELOCLoan();
  return (
    <>
      <p>Old</p>
      <AlpHELOCLoanCardPresentational loan={loan} />
      <p>New</p>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard7 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.CLOSED);
  return (
    <>
      <p>Old</p>
      <AlpHELOCLoanCardPresentational loan={loan} />
      <p>New</p>
      <DashboardAlpHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const HELOCLoanCard8 = () => {
  const loan = helocLoanFunded;
  return (
    <>
      <p>Old</p>
      <HELOCLoanCardPresentational helocLoan={loan} />
      <p>New</p>
      <DashboardHELOCLoanCardPresentational loan={loan} />
    </>
  );
};

const PersonalLoanCard = () => {
  const loan = personalLoanInprocess;
  return (
    <>
      <p>Old</p>
      <PersonalLoanCardPresentational personalLoan={loan} />
      <p>New</p>
      <DashboardPersonalLoanCardPresentational loan={loan} />
    </>
  );
};

const DashboardLoanCard = () => {
  const loan = loanInprocess;
  return <DashboardHomeLoanCardPresentational loan={loan} />;
};

const DashboardHELOCLoanCard = () => {
  const loan = helocLoanFunded;
  return <DashboardHELOCLoanCardPresentational loan={loan} />;
};

const DashboardALPHELOCLoanCard1 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.APPLICATION);
  return <DashboardAlpHELOCLoanCardPresentational loan={loan} />;
};

const DashboardPersonalLoanCard = () => {
  const loan = personalLoanInprocess;
  return <DashboardPersonalLoanCardPresentational loan={loan} />;
};

const LoanCardsStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Mock home loan cards</div>
      <div className='mb-2' />
      <HomeLoanCard1 />
      <div className='mb-2' />
      <HomeLoanCard2 />
      <div className='mb-2' />
      <VaHomeLoanCard1 />
      <div className='header-medium mb-8'>Mock falcon loan cards</div>
      <div className='mb-2' />
      <HELOCLoanCard1 />
      <div className='mb-2' />
      <HELOCLoanCard2 />
      <div className='mb-2' />
      <HELOCLoanCard3 />
      <div className='mb-2' />
      <HELOCLoanCard4 />
      <div className='mb-2' />
      <HELOCLoanCard7 />
      <div className='mb-2' />
      <HELOCLoanCard5 />
      <div className='mb-2' />
      <HELOCLoanCard6 />
      <div className='header-medium mb-8'>HELOC loan cards</div>
      <HELOCLoanCard8 />
      <div className='header-medium mb-8'>Personal loan cards</div>
      <PersonalLoanCard />
      <div className='header-medium mb-8'>Mock Dashboard loan cards</div>
      <div className='mb-2' />
      <DashboardNoLoansCard />
      <div className='mb-2' />
      <DashboardLoanCard />
      <div className='mb-2' />
      <DashboardHELOCLoanCard />
      <div className='mb-2' />
      <DashboardALPHELOCLoanCard1 />
      <div className='mb-2' />
      <DashboardPersonalLoanCard />
    </>
  );
};

export default LoanCardsStyleguide;
