import { themeId } from '../../../features/tenant/tenant';
import THEMES from '../../../themes/themes';

// Clear babel-cache for THEMES.THEMES to be recompiled with new JV property
// npm run clear:babel-cache
const themes = THEMES.THEMES as Record<iThemes, unknown>;
const theme = themes[themeId] as TenantTheme;

const sansFont = theme.fontFamily.sans[0].replace(/['"]+/g, '');
// const serifFont = theme.fontFamily.serif[0].replace(/['"]+/g, '');

const typography = {
  marketing: [
    {
      title: 'Marketing medium',
      type: `${sansFont} 48px L56 Weight 900`,
      size: 'md',
      description: 'Largest & boldest headline on a page or in a modal.',
    },
    {
      title: 'Marketing xsmall',
      type: `${sansFont} 32px L40 Weight 900`,
      size: 'xs',
      description: 'Largest & boldest headline on a page or in a modal.',
    },
  ],
  header: [
    {
      title: 'Header Large',
      type: `${sansFont} 48px L56`,
      size: 'large',
      description: 'Used on simple, uncluttered pages such as 404s or to call out especially important data.',
    },
    {
      title: 'Header medium',
      type: `${sansFont} 32px L40`,
      size: 'medium',
      description: 'Standard headline size',
    },
    {
      title: 'Header small',
      type: `${sansFont} 24px L32`,
      size: 'small',
      description: 'Standard sub-head',
    },
  ],
  body: [
    {
      title: 'Body large',
      type: `${sansFont} 20px L28`,
      size: 'large',
    },
    {
      title: 'Body medium',
      type: `${sansFont} 16px L24`,
      size: 'medium',
    },
    {
      title: 'Body small',
      type: `${sansFont} 14px L20`,
      size: 'small',
      semibold: true,
    },
  ],
  disclaimer: [
    {
      title: 'Disclaimer',
      type: `${sansFont} 12px L16`,
      size: 'disclaimer',
      description: '',
    },
  ],
  label: [
    {
      title: 'Label',
      type: `${sansFont} 12px L12 C.75`,
      size: 'label',
      description: '',
    },
  ],
};

const TypographyBody = () => {
  return (
    <>
      {typography.body.map((item, index) => {
        return (
          <div key={index}>
            <div className='flex'>
              <div>
                <div className={`body-${item.size}`}>{item.title}</div>
                {item.semibold && <div className={`body-${item.size} font-semibold`}>{item.title}</div>}
                <div className={`body-${item.size} bold `}>{item.title}</div>
                <div className='mt-6 mb-2 italic'>{item.type}</div>
              </div>
              <div className='text-white px-3 align-middle bold bg-black ml-3 flex items-center' style={{ height: '60px' }}>
                <span className={`body-${item.size}`}>{item.title}</span>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const TypographyHeader = () => {
  return (
    <>
      {typography.header.map((item, index) => {
        return (
          <div key={index}>
            <h2 className={`header-${item.size}`}>{item.title}</h2>
            <div className='mt-6 mb-2 italic'>{item.type}</div>
            <div>{item.description}</div>
          </div>
        );
      })}
    </>
  );
};

const TypographyMarketing = () => {
  return (
    <>
      {typography.marketing.map((item, index) => {
        return (
          <div key={index}>
            <h2 className={`text-marketing-${item.size}`}>{item.title}</h2>
            <div className='mt-6 mb-2 italic'>{item.type}</div>
            <div>{item.description}</div>
          </div>
        );
      })}
    </>
  );
};


const TypographyDisclaimer = () => {
  return (
    <>
      {typography.disclaimer.map((item, index) => {
        return (
          <div key={index}>
            <div className={`body-${item.size}`}>{item.title}</div>
            <div className='mt-6 mb-2 italic'>{item.type}</div>
          </div>
        );
      })}
    </>
  );
};

const TypographyLabel = () => {
  return (
    <>
      {typography.label.map((item, index) => {
        return (
          <div key={index}>
            <div className={`body-${item.size}`}>{item.title}</div>
            <div className='mt-6 mb-2 italic'>{item.type}</div>
          </div>
        );
      })}
    </>
  );
};

const Typography = () => {
  return (
    <>
      <div className='typography'>
        <div className='mb-6'>
          <h2 className='header-medium'>Typography</h2>
        </div>
        <div className='border border-solid border-1 border-inactive-25 rounded-xl p-12 '>
        <div className='grid grid-cols-3 gap-10 mb-5'>
            <TypographyMarketing />
          </div>

          <hr />

          <div className='grid grid-cols-3 gap-10 mb-5'>
            <TypographyHeader />
          </div>

          <hr />

          <div className='grid grid-cols-3 gap-10 mb-5'>
            <TypographyBody />
          </div>

          <hr />

          <div>
            <div className='grid grid-cols-3 gap-10'>
              <TypographyDisclaimer />
              <TypographyLabel />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Typography;
