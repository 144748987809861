import { MutableRefObject, useEffect, useRef } from 'react';

export const useEventListener = (eventName: string, handler: (e: CustomEvent) => void, element = window) => {
  const savedHandler = useRef() as MutableRefObject<(e: CustomEvent) => void>;

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = (event: CustomEvent) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener as EventListener);
    return () => {
      element.removeEventListener(eventName, eventListener as EventListener);
    };
  }, [eventName, element]);
};

export default useEventListener;
