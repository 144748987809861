import { useEffect, useRef } from 'react';

const useRecursiveTimeout = <T>(callback: () => Promise<T>, delay: number, triggerOnInit: boolean, condition: boolean) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let id: number;

    const tick = async () => {
      await savedCallback.current();
      if (id) {
        id = window.setTimeout(tick, delay);
      }
    };

    if (condition) {
      id = window.setTimeout(tick, triggerOnInit ? 0 : delay);
    }

    return () => {
      if (id) {
        clearTimeout(id);
      }
      id = 0;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useRecursiveTimeout;
