// Transform object keys.  Recursive.  Does not check structure for looping.
// Intended to manipulate the API payload immediately after it is received and before
// any casting into other data structures.
export const transformObjectKeys = (data: any, transform: (str: string) => string): any => {
  if (!data) {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map(item => transformObjectKeys(item, transform));
  }
  if (typeof data === 'object') {
    return Object.entries(data).reduce((agg: any, [key, val]: [key: string, val: unknown]) => {
      agg[transform(key)] = transformObjectKeys(val, transform);
      return agg;
    }, {});
  }
  return data;
};

export const isEmpty = (obj: Record<string, any> | null): boolean | null =>
  obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

export const isObject = (value: any) => value instanceof Object;
