import { useEffect } from 'react';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Error from '../../components/error/Error';
import Button from '../../components/ui/button/Button';
import { PATHS, TITLES } from '../../components/routes/paths';
import { log } from '../../utils/logger';
import { sendEvent } from '../../features/analytics/sendEvent';
import { useAppDispatch } from '../../hooks/hooks';
import { updateNotFound } from '../../features/app/appSlice';

/**
 * Catch all component for undeclared routes
 */
const NotFound = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateNotFound(true));
  }, [dispatch]);

  useEffect(() => {
    window.history.replaceState(null, '');
    sendEvent('error: notFound shown');
    log({ level: 'info', message: 'error: notFound UI shown' });
  }, []);

  useDocumentTitle(TITLES.notFound);

  const handleReturnToLoanCenterClick = () => {
    sendEvent('error: notFound: returnToLoanCenterClick');
    log({ level: 'info', message: 'error: notFound: returnToLoanCenterClick' });
    window.location.replace(PATHS.loanCenter);
  };

  return (
    <div className='main-content error-view'>
      <Error
        title='Uh oh, looks like we made a wrong turn.'
        text='No worries. Let’s go home.'
        error='Error 404'
        cta={
          <div className='pt-4 flex justify-center'>
            <Button
              buttonType='primary'
              size='large'
              onClick={handleReturnToLoanCenterClick}
              className='error-view-btn flex-grow lg:flex-none'
              style={{ minWidth: '248px' }}
            >
              Return to loan center
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default NotFound;
