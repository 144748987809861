export interface IStorage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

export type IStorageKey = string | Record<string, string | number>;

export const tryParse = (m: string | null) => {
  try {
    return JSON.parse(m || 'false');
  } catch (e) {
    return false;
  }
};

export const buildId = (prefix: string, id: IStorageKey) =>
  `${prefix}:${typeof id === 'string' ? id : JSON.stringify(Object.entries(id).sort((a, b) => a[0].localeCompare(b[0])))}`;

// GET

export const getStorage = <ValueType>(
  storage: IStorage,
  prefix: string,
  key: IStorageKey,
  defaultValue?: ValueType,
): ValueType | undefined => {
  const id = buildId(prefix, key);
  if (storage.getItem(id)) {
    return tryParse(storage.getItem(id));
  } else {
    return defaultValue;
  }
};

export const get = <ValueType>(prefix: string, key: IStorageKey, defaultValue?: ValueType): ValueType | undefined =>
  getStorage<ValueType>(window.localStorage, prefix, key, defaultValue);

// SET

export const setStorage = <ValueType = any>(storage: IStorage, prefix: string, key: IStorageKey, value: ValueType): ValueType => {
  storage.setItem(buildId(prefix, key), JSON.stringify(value));
  return value;
};

export const set = <ValueType = any>(prefix: string, key: IStorageKey, value: ValueType): ValueType =>
  setStorage<ValueType>(window.localStorage, prefix, key, value);

// DELETE

export const removeStorage = (storage: IStorage, prefix: string, key: IStorageKey): void =>
  storage.removeItem(buildId(prefix, key));

export const remove = (prefix: string, key: IStorageKey): void => removeStorage(window.localStorage, prefix, key);
