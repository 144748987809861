import UAParser from 'ua-parser-js';
import { getCookieValue } from './getCookieValue';
import { get } from './sessionStore';
import { random } from './uuid';
import { decodeParam, getSearchParam } from './url';

export const clientID = random(24);

interface IBrowserInfo {
  name?: string;
  version?: string;
  os?: string;
}

export interface IBrowser extends IBrowserInfo {
  supported: boolean;
  cookieEnabled: boolean;
}

const ua = window.navigator.userAgent;
const uaParsed = UAParser(ua);
const browserInfo = {
  name: uaParsed.browser.name,
  version: uaParsed.browser.version,
  os: uaParsed.os.name,
} as IBrowserInfo;
const majorVersion = (browserInfo.version ?? '').split('.')[0];

const isStandalone = () =>
  (window.navigator as any)?.standalone === true || window.matchMedia('(display-mode: standalone)').matches;

export const getResolution = () =>
  typeof window !== undefined
    ? {
        viewport: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
        screen: {
          width: window.screen.width,
          height: window.screen.height,
          pixelDepth: window.screen.pixelDepth,
        },
        visible: document.visibilityState === 'visible',
        standalone: isStandalone(),
      }
    : {};

export const embed = () => {
  if (typeof window === undefined) return {};

  const embedParam = getSearchParam({ name: 'embed' });

  return {
    embedSearchParam: !!(embedParam !== null && embedParam !== 'false'),
    embedParamStored: !!get('mya', 'embed'),
    isShellApp: !!getCookieValue('is_shell_app'),
  };
};

export const adtrk = () => {
  if (typeof window === undefined) return {};

  const adtrkSearchParam = getSearchParam({ name: 'adtrk' }, { caseInsensitive: true, decode: true });
  let adtrkCookie = getCookieValue('AdTrk');
  adtrkCookie = adtrkCookie ? decodeParam(adtrkCookie) : undefined;

  return {
    adtrkSearchParam,
    adtrkCookie,
  };
};

export const lo = () => {
  if (typeof window === undefined) return {};

  const empIdSearchParam =
    getSearchParam({ name: 'emp-id' }, { caseInsensitive: true }) || getSearchParam({ name: 'emp_id' }, { caseInsensitive: true });
  const loIdSearchParam = getSearchParam({ name: 'loid' }, { caseInsensitive: true });
  const loIdCookie = getCookieValue('loId');

  return {
    empIdSearchParam,
    loIdSearchParam,
    loIdCookie,
  };
};

export const multilingual = () => {
  if (typeof window === undefined) return {};

  return {
    language: window.navigator?.language?.slice(0, 2),
    langPref: getCookieValue('langPref'),
  };
};

// Internet Explorer 6-11
const hasIEUserAgent = ua.indexOf('MSIE') !== -1 || ua.indexOf('Trident/7.0') !== -1;
const hasIEFeatures = /*@cc_on!@*/ false || !!(document as global.Document).documentMode;
const isIE = hasIEUserAgent || hasIEFeatures;

// Safari
const isSafari = browserInfo.name?.toLowerCase() === 'safari';
const isOlderSafari = isSafari && parseInt(majorVersion) <= 11;
const isFirefox = browserInfo.name?.toLowerCase() === 'firefox';
const isOlderFirefox = isFirefox && parseInt(majorVersion) <= 90;

export const isUnsupported = isIE || isOlderSafari || isOlderFirefox;
export const cookieEnabled = navigator.cookieEnabled;
export const referrer = document.referrer;

const browser = { ...browserInfo, supported: !isUnsupported, cookieEnabled, referrer };

export const getBrowser = () => ({
  clientID,
  ...browser,
  ...getResolution(),
  ...multilingual(),
  ...embed(),
  ...adtrk(),
  ...lo(),
});

export const isSafariBrowser = isSafari;

export default getBrowser;
