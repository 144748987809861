import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RequestError, RequestRejectValue } from '../../interfaces/IRequest';
import type { RootState } from '../../store/types';
import api from '../api';
import { PartialDataError, logRequestError, rejectPartialDataValue, rejectValue } from '../requestHelper';
import { PersonalLoanMetadata, PersonalLoansState, iPersonalLoan } from './types';

const namespace = 'personalLoans';

const initialState: PersonalLoansState = {
  byId: {},
  ids: [],
  metadata: {
    hasData: false,
    isFetching: false,
    hasError: false,
  },
};

export const fetchPersonalLoans = createAsyncThunk<
  iPersonalLoan[],
  undefined,
  { rejectValue: RequestRejectValue }
>(`${namespace}/fetchPersonalLoans`, async (_, { rejectWithValue }) => {
  try {
    const response = await api.getPersonalLoans();
    const data = response.data.filter((personalLoan: iPersonalLoan) => !!personalLoan.id);
    if (response.data.length > data.length) {
      throw new PartialDataError('Failed to retrieve loan for product account', data);
    }
    return response.data;
  } catch (err) {
    const error = err as PartialDataError<iPersonalLoan[]> | AxiosError<RequestError>;
    logRequestError(`${namespace}/fetchPersonalLoans`, error);

    if (error instanceof PartialDataError) {
      return rejectWithValue(rejectPartialDataValue(error) as RequestRejectValue<iPersonalLoan[]>);
    }

    if (!error.response) {
      throw err;
    }

    return rejectWithValue(rejectValue(error) as RequestRejectValue);
  }
});

const getStateData = (loans: iPersonalLoan[]) =>
  loans.reduce(
    (accState, loan: iPersonalLoan) => ({
      ...accState,
      ids: [...accState.ids, loan.id],
      byId: {
        ...accState.byId,
        [loan.id]: loan,
      },
    }),
    { byId: initialState.byId, ids: initialState.ids },
  );

export const personalLoansSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPersonalLoans.pending, (state) => {
      state.metadata.isFetching = true;
      state.metadata.hasError = false;
    });
    builder.addCase(fetchPersonalLoans.fulfilled, (state, action) => {
      state.metadata.hasData = true;
      state.metadata.isFetching = false;
      const updatedDataState = getStateData(action.payload);
      state.ids = updatedDataState.ids;
      state.byId = updatedDataState.byId;
    });
    builder.addCase(fetchPersonalLoans.rejected, (state, { payload, error }) => {
      state.metadata.isFetching = false;
      state.metadata.hasError = true;
      state.metadata.error = error;
      const rejectedPayload = payload as RequestRejectValue<iPersonalLoan[]>;
      if (rejectedPayload?.type === 'partialDataError' && rejectedPayload?.data) {
        state.metadata.hasLoanFetchFailure = true;
        state.metadata.hasData = true;
        const updatedDataState = getStateData(rejectedPayload.data);
        state.ids = updatedDataState.ids;
        state.byId = updatedDataState.byId;
      }
    });
  },
});

export const selectPersonalLoansMetadata = (state: RootState): PersonalLoanMetadata => {
  return state.personalLoans.metadata;
};

export const selectIsPersonalLoansFetching = (state: RootState): boolean => {
  return !!state.personalLoans.metadata.isFetching;
};

export const selectPersonalLoansHasData = (state: RootState): boolean => {
  return !!state.personalLoans.metadata.hasData;
};

export const selectPersonalLoansHasError = (state: RootState): boolean => {
  return !!state.personalLoans.metadata.hasError;
};

export const selectPersonalLoansHasFetchFailure = (state: RootState): boolean => {
  return !!state.personalLoans.metadata.hasLoanFetchFailure;
};

export const selectPersonalLoanIds = (state: RootState) => state.personalLoans.ids;

export const selectPersonalLoans = (state: RootState) => state.personalLoans.byId;

export const selectFundedPersonalLoanIds = createSelector(selectPersonalLoanIds, selectPersonalLoans, (ids, loans) => {
  return ids.filter(id => isPersonalLoanFunded(loans[id]));
});

export const selectProcessingPersonalLoanIds = createSelector(selectPersonalLoanIds, selectPersonalLoans, (ids, loans) => {
  return ids.filter(id => !isPersonalLoanFunded(loans[id]));
});

export const selectPersonalLoanById = (state: RootState, loanGuid: string): iPersonalLoan | undefined =>
  selectPersonalLoans(state)[loanGuid];

export const isPersonalLoanFunded = (loan: iPersonalLoan): boolean => !!loan.servicer;

export const getNextPaymentDate = (loan: iPersonalLoan): string | undefined =>
  loan.loanAccounting?.nextDueDate || loan.loanDetails.contract?.firstPaymentDueDate;

export const personalLoansActions = personalLoansSlice.actions;

export default personalLoansSlice.reducer;

export const getDisplayedLoanNumber = (loan: iPersonalLoan) => loan.servicer?.loanNumber || loan.originator.loanNumber;