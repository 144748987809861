import Icon from '../../ui/icon/Icon';
import Tooltip from '../../ui/tooltip/Tooltip';

const LoanNumberTooltip = (
  <Tooltip
    place='right'
    html={true}
    tip='Your <strong>loan number</strong> may change after closing. Please refer to this loan number when contacting customer support.'
  >
    <Icon name='information-circle' size='0.875rem' className='text-secondary' />
  </Tooltip>
);

export default LoanNumberTooltip;
