import { useEffect, useRef } from 'react';
import { withTransaction } from '@elastic/apm-rum-react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { updateGlobalLoadingOnce } from '../../features/app/appSlice';
import HomeValueResults from '../../components/homeValue/homeValueResults/HomeValueResults';
import HomeValueSearch from '../../components/homeValue/homeValueSearch/HomeValueSearch';
import {
  clearHomeValueError,
  selectHomeValueAddress,
  selectHomeValueData,
  useFetchHomeValueOnce
} from '../../features/homeValue/homeValueSlice';
import HomeValueSearchSkeletonLoading from '../../components/homeValue/homeValueSkeleton/HomeValueSkeleton';
import { sendEvent } from '../../features/analytics/sendEvent';
import { SingleColumnContent } from '../../components/layout/Layout';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { TITLES } from '../../components/routes/paths';

const HomeValueContent = () => {
  const dispatch = useAppDispatch();
  const homeValueData = useAppSelector(selectHomeValueData);
  const homeValueAddress = useAppSelector(selectHomeValueAddress);
  const homeValueState = useFetchHomeValueOnce();
  const { error, hasInitialData, fetchCount, fetching } = homeValueState;
  const clearInitError = useRef(false);

  useEffect(() => {
    // Clear home value error before component unmount
    return () => {
      dispatch(clearHomeValueError());
    };
  }, [dispatch]);

  useEffect(() => {
    (hasInitialData || error) && dispatch(updateGlobalLoadingOnce(false));
  }, [dispatch, hasInitialData, error]);

  useEffect(() => {
    // if init error is not cleared and home value is fetched and was first fetch and has result data or error
    if (!clearInitError.current && fetchCount === 1 && (hasInitialData || error)) {
      clearInitError.current = true;
      // if there is actually an error, clear it
      if (error) {
        dispatch(clearHomeValueError());
      }
    }
  }, [dispatch, error, fetchCount, hasInitialData]);

  if (fetching || fetchCount < 1) {
    return <HomeValueSearchSkeletonLoading />;
  }

  if (hasInitialData && homeValueAddress) {
    sendEvent('homeValueReturnedSearch', { address: homeValueAddress });
  }

  return homeValueData && homeValueAddress ? <HomeValueResults data={homeValueData} address={homeValueAddress}/> : <HomeValueSearch />;
};

const HomeValue = () => {
  useDocumentTitle(TITLES.homeValue);
  return (
    <SingleColumnContent>
      <HomeValueContent />
    </SingleColumnContent>
  );
};

export default withTransaction('HomeValue', 'page-load')(HomeValue);
