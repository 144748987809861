import cn from 'classnames';
import { useRef, useState } from 'react';
import ReactModal from 'react-modal';
import nextId from '../../utils/nextId';
import './FlyoutComponent.scss';

// Must match --flyout-transition-duration defined in src/components/flyout/FlyoutComponent.scss
const TRANSITION_DURATION = 200;

interface Props {
  'aria-describedby'?: string;
  'aria-labelledby'?: string;
  buttonTitle?: string;
  children: React.ReactNode;
  contentLabel: string;
  flyoutComponent: (closeFn: () => void) => JSX.Element;
  id?: string;
  onChange?: (active: boolean) => void;
  orientation: 'left' | 'right';
}

const FlyoutComponent = ({
  'aria-labelledby': labelledby,
  contentLabel,
  'aria-describedby': describedby,
  buttonTitle,
  children,
  flyoutComponent: FlyoutContentComponent,
  id,
  onChange,
  orientation,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const idRef = useRef(id || nextId('flyout_'));

  const handleOpen = () => {
    setIsOpen(true);
    onChange?.(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    onChange?.(false);
  };

  return (
    <>
      <button
        onClick={handleOpen}
        title={buttonTitle || 'Toggle Menu'}
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-controls={idRef.current}
      >
        {children}
      </button>

      <ReactModal
        id={idRef.current}
        closeTimeoutMS={TRANSITION_DURATION}
        isOpen={isOpen}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick={true}
        contentLabel={contentLabel}
        className={{
          base: cn('Flyout__Content', `Flyout__Content-${orientation}`),
          afterOpen: cn('Flyout__Content--after-open', `Flyout__Content-${orientation}--after-open`),
          beforeClose: cn('Flyout__Content--before-close', `Flyout__Content-${orientation}--before-close`),
        }}
        overlayClassName={{
          base: 'ReactModal__Overlay Flyout__Overlay',
          afterOpen: 'ReactModal__Overlay--after-open Flyout__Overlay--after-open',
          beforeClose: 'ReactModal__Overlay--before-close Flyout__Overlay--before-close',
        }}
        htmlOpenClassName={'Flyout__Html--open'}
        bodyOpenClassName={'Flyout__Body--open'}
        aria={
          labelledby || describedby
            ? {
                labelledby: labelledby ? labelledby : undefined,
                describedby: describedby ? describedby : undefined,
              }
            : undefined
        }
      >
        {FlyoutContentComponent(handleClose)}
      </ReactModal>
    </>
  );
};

export default FlyoutComponent;
