import cn from 'classnames';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';
import Icon from '../icon/Icon';
import { TelProps } from './ITel';

const Tel = ({ tel, suffix, label, className, title, showIcon, labelClassName, onClick, defaultStyle = true, ...rest }: TelProps) => (
  <a
    href={`tel: ${tel}`}
    className={cn('tel', { 'tel-link': defaultStyle }, className, 'whitespace-nowrap', 'inline-block')}
    title={title}
    data-ui='tel'
    onClick={onClick}
    {...rest}
  >
    <ConditionalWrapper condition={!!showIcon} el='span' className='flex'>
      {showIcon && <Icon name='phone' className='mr-2 no-underline' size='1.25rem' />}
      <span className={cn({ 'tel-label': defaultStyle }, labelClassName)}>{label || tel}</span>
      {suffix && <span className={cn({ 'tel-label': defaultStyle }, 'ml-2', labelClassName)}>{suffix}</span>}
    </ConditionalWrapper>
  </a>
);

export default Tel;
