import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import { ConfigurationNote } from '../shared';

const NavigationStyleguide = () => {
  return (
    <div className='navigation-styleguide'>
      <div className='navigation-styleguide__header'>
        <h2 className='header-medium'>Header</h2>
        <div className='body-medium italic mb-2'>Adjust browser for proper responsive sizing</div>
        <div className='w-fit-content'><Header /></div>
        <div className='my-6 app-sidebar'>{/* todo: include new navigation */}</div>
        <div className='my-6'>
          <h2 className='header-medium'>Footer</h2>
          <div>
            <div className='body-medium italic mb-2'>Adjust browser for proper responsive sizing</div>
            <Footer />
            <ConfigurationNote message='Logo, links and copy are configurable.' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationStyleguide;
