import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import type { AppDispatch, RootState } from '../../store/types';
import api from '../api';
import { rejectValue } from '../requestHelper';
import { documentState, documentData, iDocument, FetchDocumentsResponse, documentMetadata } from './types';
import useIsMounted from '../../hooks/useIsMounted';
import { selectDocumentsLastViewed } from '../user/userSlice';
import { sendEvent } from '../analytics/sendEvent';
import { log } from '../../utils/logger';
import { setDataLayer } from '../analytics/setDataLayer';
import { RequestError, RequestRejectValue } from '../../interfaces/IRequest';
import { AxiosError } from 'axios';
import { selectLoan } from '../loans/loansSlice';
import { willNeverHaveDocuments } from '../loans/helpers';
import { forceLogout } from '../auth/authSlice';

const namespace = 'documents';

const initialData: documentData = {
  documents: [],
  metadata: {
    loading: false,
    loadingStatus: 'idle',
    hasData: false,
    hasError: false,
  },
};

const initialState: documentState = {
  byLoanGuid: {},
};

const logFetchedDocuments = (loanGuid: string, documents: iDocument[]) => {
  const documentsLogArray = documents.map(document => {
    return { loanGuid, id: document.id, category: document.category, taskId: document['task-id'] };
  });
  sendEvent('documentsFetched', { loanGuid, documents: documentsLogArray });
  log({ loanGuid, level: 'info', message: `Fetched documents ${JSON.stringify(documentsLogArray)}` });
};

export const fetchDocuments = createAsyncThunk<FetchDocumentsResponse, string, {
  dispatch: AppDispatch; rejectValue: RequestRejectValue
}>(
  `${namespace}/fetchDocuments`,
  async (loanGuid, { dispatch, rejectWithValue }) => {
    try {
      const resp = await api.getDocuments(loanGuid);
      setDataLayer('documents', { [loanGuid]: resp.data.document });
      logFetchedDocuments(loanGuid, resp.data.documents);
      return resp.data;
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      if (error.response?.status === 401) {
        dispatch(forceLogout('fetchDocuments'));
      }
      if (!error.response) {
        throw err
      }
      return rejectWithValue(rejectValue(error));
    }
  },
  {
    condition: (loanGuid, { getState }) => {
      const { loadingStatus } = selectDocumentsMetadata(getState() as RootState, loanGuid);
      return loadingStatus !== 'pending';
    },
  },
);

export const documentsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDocuments.pending, (state, action) => {
      const loanGuid = action.meta.arg;
      state.byLoanGuid[loanGuid] = {
        ...initialData,
        ...state.byLoanGuid[loanGuid],
      };
      state.byLoanGuid[loanGuid].metadata = {
        ...initialData.metadata,
        ...state.byLoanGuid[loanGuid].metadata,
        loadingStatus: 'pending',
        loading: true,
        hasError: false,
      };
    });
    builder.addCase(fetchDocuments.fulfilled, (state, action) => {
      const loanGuid = action.meta.arg;
      state.byLoanGuid[loanGuid] = {
        ...initialData,
        ...state.byLoanGuid[loanGuid],
        documents: action.payload.documents,
      };
      state.byLoanGuid[loanGuid].metadata = {
        ...initialData.metadata,
        ...state.byLoanGuid[loanGuid].metadata,
        hasData: true,
        loadingStatus: 'success',
        loading: false,
      };
    });
    builder.addCase(fetchDocuments.rejected, (state, action) => {
      const loanGuid = action.meta.arg;
      state.byLoanGuid[loanGuid] = {
        ...initialData,
        ...state.byLoanGuid[loanGuid],
      };
      state.byLoanGuid[loanGuid].metadata = {
        ...initialData.metadata,
        ...state.byLoanGuid[loanGuid].metadata,
        loadingStatus: 'failed',
        loading: false,
        hasError: true,
      };
    });
  },
});

export const selectDocumentsData = (state: RootState, loanGuid: string): documentData => {
  return state.documents.byLoanGuid[loanGuid] || initialData;
};

export const selectDocuments = (state: RootState, loanGuid: string): iDocument[] | null => {
  if (!state.documents.byLoanGuid[loanGuid]?.metadata.hasData) {
    // TODO Why return null when intial data is an empty array?
    return null;
  }
  return state.documents.byLoanGuid[loanGuid]?.documents;
};

const selectSigningDocuments = createSelector(selectDocuments, documents =>
  documents?.filter(doc => doc.category === 'signing-documents'),
);

const selectUploadedDocuments = createSelector(selectDocuments, documents =>
  documents?.filter(doc => doc.category === 'upload-documents'),
);

export const selectDocumentsByGroups = createSelector(
  selectDocuments,
  selectSigningDocuments,
  selectUploadedDocuments,
  (all, signing, uploaded) => ({ all, signing, uploaded }),
);

export const selectDocumentsMetadata = (state: RootState, loanGuid: string): documentMetadata => {
  return state.documents.byLoanGuid[loanGuid]?.metadata || initialData.metadata;
};

export const selectHasNewDocument = createSelector(
  selectDocumentsLastViewed,
  selectSigningDocuments,
  (docsLastViewed, documents): boolean =>
    !!documents &&
    documents.length > 0 &&
    (!docsLastViewed || documents.some(document => Date.parse(document['date-added']) > docsLastViewed)),
);

export const useFetchDocuments = (loanGuid: string, skip?: boolean) => {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  const loan = useAppSelector(state => selectLoan(state, loanGuid));
  const mayHaveDocuments = loan && !willNeverHaveDocuments(loan);
  const documentsData = useAppSelector(state => selectDocumentsData(state, loanGuid));
  const { documents, metadata: { loadingStatus, hasData, hasError, loading } } = documentsData;
  const notLoading = useRef(!loading);
  const shouldFetch = !skip && mayHaveDocuments && notLoading.current;

  useEffect(() => {
    if (isMounted() && shouldFetch) {
      dispatch(fetchDocuments(loanGuid));
    }
  }, [dispatch, isMounted, loanGuid, shouldFetch]);

  return {
    documents,
    loadingStatus,
    hasData,
    hasError,
  };
};

export const selectHasClosingDocument = createSelector(
  selectSigningDocuments,
  (documents): boolean =>
    !!documents && documents.length > 0 && documents.some(document => document.subcategory?.toLowerCase().includes('closing')),
);

export const documentsActions = documentsSlice.actions;
export default documentsSlice.reducer;