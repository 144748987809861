import { useRouteMatch } from 'react-router-dom';
import { useAppSelector } from './hooks';
import { selectCurrentLo } from '../features/user/selectCurrentLo';
import { PATHS } from '../components/routes/paths';

// Retrieves current lo from user info, route matches are true anytime
// the current lo is shown regardless of placement
export const useCurrentLo = () => {
  const routeMatch = useRouteMatch({
    path: [PATHS.loanCenter],
    exact: true,
  });
  const lo = useAppSelector(selectCurrentLo);
  return { routeMatch, lo };
};
