import { ReactNode } from 'react';

/* Preload Image, prevent flickering */
export const ImagePreloader = ({ children }: { children: ReactNode }) => {
  return (
    <div className='hidden' aria-hidden='true'>
      {children}
    </div>
  );
};
