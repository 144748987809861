import cn from 'classnames';
import Button from '../button/Button';
import './ConfirmationModalContent.scss';

interface ConfirmationModalContentProps {
  imageSrc?: string;
  imageAlt?: string;
  imageComponent?: React.ReactNode;
  title?: string;
  text?: string;
  textNode?: React.ReactNode;
  footer?: string;
  confirmButtonText?: string;
  onConfirm?: () => void;
  confirmHref?: string;
  confirmHrefTarget?: string;
  confirmLoading?: boolean;
  cancelButtonText?: string;
  onCancel?: () => void;
  className?: string;
}

const ConfirmationModalContent = ({
  imageSrc,
  imageAlt = 'Image',
  imageComponent,
  title,
  text,
  textNode,
  footer,
  confirmButtonText,
  onConfirm,
  confirmHref,
  confirmHrefTarget,
  confirmLoading = false,
  cancelButtonText,
  onCancel,
  className,
}: ConfirmationModalContentProps) => {
  return (
    <div className={cn(className, 'confirmation-modal-content flex flex-col items-center justify-center')}>
      {imageSrc && <img className='mb-10 text-center' src={imageSrc} width='67' alt={imageAlt} />}
      {imageComponent ? imageComponent : null}
      {title && <h2 className='text-2xl font-bold mb-4 mx-1 lg:mx-12 text-center'>{title}</h2>}
      {text && <p className='mb-8 mx-1 lg:mx-12 text-center'>{text}</p>}
      {textNode && <div className='mx-1 lg:mx-12 text-center'>{textNode}</div>}
      {(onConfirm || onCancel) &&
        <div className='flex flex-col-reverse w-full justify-center mt-8 lg:flex-row lg:gap-x-5 lg:w-fit'>
          {onConfirm && <Button buttonType='secondary' onClick={onConfirm} size='large' className='button' href={confirmHref} target={confirmHrefTarget} loading={confirmLoading}>
            {confirmButtonText || 'Confirm'}
          </Button>}
          {/* gap in flexbox doesn't work with ios safari */}
          {/* people started saying it works for safari 14 in 2021 but it doesn't in my testing */}
          {onCancel && <Button buttonType='primary' onClick={onCancel} size='large' className='button mb-4 lg:mb-0'>
            {cancelButtonText || 'Cancel'}
          </Button>}
        </div>
      }
      {footer && <div className='mx-1 lg:mx-12 mt-8 text-sm text-center'>{footer}</div>}
    </div>
  )
};

export default ConfirmationModalContent;
