import { Image } from '../../ui/image/Image';
import HomeFeatures from './HomeFeatures';
import { HomeValueData } from '../../../pages/homeValue/IHomeValue';
import { HomeValueUserAddress } from '../../../features/homeValue/types';
import './HomeDetails.scss';

const HomeDetails = ({ data, address }: { data: HomeValueData, address: HomeValueUserAddress }) => {
  const details = data.details;
  const url = 'https://maps.googleapis.com/maps/api/streetview?';
  const formattedAddress = address.metadata['formatted-address-with-unit'];
  const params = new URLSearchParams({
    fov: '60',
    size: '640x640',
    key: 'AIzaSyCVarbPudKoN48-Id5OSc2Wvcf1LHiu-HQ',
    location: formattedAddress || '',
  }).toString();
  const src = url + params;

  return (
    <section className='home-details mb-12'>
      <div className='mb-8 lg:mb-0 home-details__home-features-container'>
        <HomeFeatures details={details} />
      </div>

      {/* image */}
      <div className='home-details__image-container'>
        <Image
          placeholder='/images/property.svg'
          src={src}
          alt={formattedAddress || 'Placeholder property'}
          imgClassNames='rounded-xl'
          square
        />
      </div>
    </section>
  );
};

export default HomeDetails;
