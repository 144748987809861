import { Redirect, Route, useLocation } from 'react-router-dom';
import { PublicRouteProps } from './IRoutes';
import { useIsAuth } from '../../features/auth/authSlice';
import { LocationState } from '../../interfaces/ILocationState';
import { PATHS } from './paths';

// A wrapper for Public Routes that redirects to the loans
// page if user is authenticated.
const PublicRoute = ({ component: Component, ...rest }: PublicRouteProps) => {
  const isAuthenticated = useIsAuth();
  const location = useLocation<LocationState>();
  const returnTo = location.state?.from?.pathname;

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: returnTo || PATHS.loanCenter,
              state: {}
            }}
          />
        )
      }
    />
  );
};

export default PublicRoute;
