import { useIsAuth } from '../../features/auth/authSlice';
import { useUser } from '../../features/user/userSlice';
import useEmbed from '../../hooks/useEmbed';
import { useMultilingual } from '../../hooks/useMultilingual';
import LanguageButton from '../multilingual/languageButton/LanguageButton';
import { TopNavigation } from '../navigation/TopNavigation';
import HeaderDropdown from './HeaderDropdown';
import HeaderLogo from './HeaderLogo';
import cn from 'classnames';

const TopNavHeader = ({ className }: { className: string }) => {
  const shouldShowLanguageButton = useMultilingual();
  const isAuthenticated = useIsAuth();
  const user = useUser();
  const embed = useEmbed();

  return (
    <header className={cn(className, 'flex flex-row items-center justify-between py-5')}>
      <HeaderLogo className='lg:mr-8' />
      {isAuthenticated && <TopNavigation />}
      {!isAuthenticated && shouldShowLanguageButton && <LanguageButton />}
      {isAuthenticated && (
        <div className='header-user ml-auto'>
          {shouldShowLanguageButton && (
            <div className='lg:mr-3'>
              <LanguageButton />
            </div>
          )}
          {!embed && (
            <div className='hidden lg:inline'>
              <HeaderDropdown user={user} />
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default TopNavHeader;
