import { iPersonalLoan } from '../features/personalLoans/types';

export const personalLoanInprocess: iPersonalLoan = {
  id: 'test-personal-loan-id',
  borrowerId: 'test-borrower-id',
  tenantId: 'test-tenant-id',
  productAccountId: 'test-product-account-id',
  productType: 'test-product-type',
  program: 'test-program',
  originator: {
    accountId: 'test-originator-account-id',
    customerId: 'test-customer-id',
    loanNumber: 'test-loan-number'
  },
  loanDetails: {
    amount: 100000,
    term: 60,
    termType: 'Months',
    apr: 5.5,
    monthlyPaymentAmount: 2000,
    contract: {
      firstPaymentDueDate: '2024-01-01',
      fundingDate: '2023-12-01',
      originationFee: 1000,
      signedTime: '2023-12-01'
    },
    status: 'test-status',
  },
  servicer: {
    accountId: 'test-servicer-account-id',
    customerId: 'test-customer-id',
    loanNumber: 'test-loan-number'
  },
  loanAccounting: {
    loanId: 'test-loan-id',
    principalBalance: 100000,
    currentAmountDue: 50000,
    pastAmountDue: 65000,
    feeAmountDue: 1000,
    totalAmountDue: 115000,
    daysLate: 30,
    interestBalance: 5000,
    investorFeeAmountDue: 100,
    lenderFeeAmountDue: 100,
    nextDueDate: '2024-02-01',
    paidToDate: '5000',
    defaultDate: '2024-01-01',
    partialPaymentAmount: 1000,
    perDiem: {
      amount: 1000,
      interestMethod: 'test-interest-method',
      interestRate: 5.5,
    },
    regularPaymentAmount: 2000,
    remainingTerm: 60,
    servicerFeeAmountDue: 100,
    termFrequency: 'test-term-frequency',
    transactionAmount: 2000,
    lastPaymentDate: '2024-01-01',
    totalPrincipalPaid: 5000,
    totalInterestPaid: 5000,
    totalFeePaid: 100,
    status: 'test-status',
  },
};
