import Button from '../../components/ui/button/Button';

// shared between elements and components
export const NavigationSections = ({ navSections }: { navSections: string[] }) => {
  return (
    <>
      {navSections.map((item, index) => {
        return (
          <Button className='mx-2' buttonType='tertiary' href={'#' + item} key={index}>
            {item}
          </Button>
        );
      })}
    </>
  );
};

export const ConfigurationNote = ({ message }: { message: string }) => (
  <div>
    <span className='text-ok-100 bold mr-2'>C </span> {message}
  </div>
);
