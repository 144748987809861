import BodyLock from '../../bodyLock/BodyLock';

export const SpinnerFullScreen = () => (
  <div className='fixed inset-0 flex justify-center items-center bg-white z-50'>
    <BodyLock />
    <Spinner />
  </div>
);

/**
 * Theme logo loading spinner for fullscreen and within individual components
 */
const Spinner = () => (
  <div className='spinner' data-ui='spinner'>
    <img alt='Logo' className='spinner-icon' src='/images/spinner-icon.svg' />
    <div className='spinner-spinning'></div>
  </div>
);

export default Spinner;
