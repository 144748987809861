import MiniSpinner from '../../../components/ui/spinner/MiniSpinner';
import Spinner from '../../../components/ui/spinner/Spinner';

const Loaders = () => {
  return (
    <>
      <div className='loaders'>
        <div className='header-medium mb-8'>Loaders</div>
        <div className='loaders-container'>
          <div className='loaders-group mb-4 flex'>
            <div className='loaders-spinner mr-4'>
              <Spinner />
            </div>
            <div className='loaders-mini'>
              <MiniSpinner color='body' />
              <br />
              <MiniSpinner />
            </div>
            <br />
            <div className='body-small italic ml-4' style={{ width: '300px' }}>
              Used to when a small individual component needs to load something. For example, a loading button or uploading files.
            </div>
          </div>
        </div>
        <div className='body-small italic'>
          Used to load content when page has loaded. For example, tasks or documents. Should be a general representation of the
          content being loaded.
        </div>
      </div>
    </>
  );
};

export default Loaders;
