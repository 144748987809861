import { Skeleton } from '../../ui/skeleton/Skeleton';

const TaskLoading = ({ width = 'w-full' }) => {
  return (
    <div className='border-b border-inactive-25 py-5'>
      <Skeleton className={`${width}`} />
    </div>
  );
};

export const TasklistLoading = () => {
  return (
    <div className='flex flex-col w-full pb-4'>
      <div className='flex items-center pb-5'>
        <div className='flex items-center'>
          <Skeleton className='mr-6 rounded-full' style={{ height: '48px', width: '48px' }} />
        </div>
        <Skeleton className='w-full' style={{ maxWidth: '325px' }} />
      </div>
      <Skeleton className='w-full mb-2' style={{ maxWidth: '400px' }} />
      <Skeleton className='w-full mb-2' />
      <Skeleton className='w-full' />
      <div className='border-t border-inactive-25 mt-5' />
      <TaskLoading width='w-3/4' />
      <TaskLoading />
    </div>
  );
};
