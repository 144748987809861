import React from 'react';
import Icon from '../ui/icon/Icon';
import Link from '../ui/link/Link';
import Tel from '../ui/tel/Tel';
import './RegistrationLandingComponents.scss';
import Button from '../ui/button/Button';
import { oktaLogin } from '../../utils/grLinks';
import { useDefaultSupport } from '../../hooks/useDefaultSupport';
import { unformatPhoneNumber } from '../../utils/phone';

const RegistrationLandingComponent = ({ title, error = true, children }: { title: string, error?: boolean, children: React.ReactNode }) => {
  return (
    <div className='text-center flex flex-col justify-center items-center'>
      <div className='flex lg:flex-col'>
        <div className='mr-2 lg:mr-0 lg:justify-center lg:mb-4 flex'>
          {error ?
            <Icon name='warning-triangle' className='self-center text-3xl text-critical' /> :
            <Icon name='check-tick-circle' className='self-center text-3xl text-ok' />
          }
        </div>
        <h1 className='header-medium lg:text-5xl lg:mb-3'>{error ? 'Oops!' : 'Success!'}</h1>
      </div>
      <span className='text-lg mb-8'>{title}</span>
      <div className='result__action'>
        {children}
      </div>
    </div>
  );
};

export const LockedResultComponent = () => {
  const { phone } = useDefaultSupport();
  return (
    <RegistrationLandingComponent title='Your account has been locked.'>
      <p className='text-lg'>
        <span>Please contact us at </span>
        <Tel label={phone} tel={unformatPhoneNumber(phone)} />
        <br className='lg:hidden' />
        <span> for help.</span>
      </p>
    </RegistrationLandingComponent>
  );
};

export const TokenInvalidComponent = ({ reason = 'expired', clearRegistration }: { reason?: 'expired' | 'timed-out', clearRegistration: () => void }) => {
  return (
    <RegistrationLandingComponent title={`Your registration link has ${reason}.`}>
      <p className='text-lg'>
        If you previously registered, please <Button buttonType='inline' href={oktaLogin()}>log in</Button>.
        <br />
        If you need to register, continue <Button buttonType='inline' onClick={clearRegistration}>registration</Button>.
      </p>
    </RegistrationLandingComponent>
  );
};

export const SuccessResultComponent = ({ handleResendEmail, resendStatus }: { handleResendEmail: () => void, resendStatus: string }) => {
  return (
    <RegistrationLandingComponent title={`We've sent an email with an activation link.`} error={false}>
      <>
        {resendStatus !== 'success' && (
          <>
            <span>Did not receive the email? </span>
            <br />
            <span>Check your spam filter, or </span>
            <Link onClick={handleResendEmail} className='underline hover:no-underline'>
              resend the link
            </Link>
            .
          </>
        )}
        {resendStatus === 'processing' && <p className='text-sm text-ok-100'>
          Resending link...
        </p>}
        {resendStatus === 'error' && <p className='text-sm text-criticalText'>
          The link was not sent. Try again.
        </p>}
        {resendStatus === 'success' && <p className='text-sm text-ok-100'>
          A link has been sent.
        </p>}
      </>
    </RegistrationLandingComponent>
  );
};
