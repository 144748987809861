import type { Loan } from '../features/loans/types';
import { LoanMilestones } from '../features/loans/types';

export const loanFunded: Loan = {
  'appraisal-status': 'received',
  'interest-rate': 4,
  'last-updated': '2022-08-19T19:55:55.657Z',
  'va?': false,
  'lead-source': 'GR LF Purchase',
  'loan-milestone': LoanMilestones.FUNDED,
  'scheduled-closing-date': '2022-08-11T00:00:00.000Z',
  'estimated-closing-date': '2022-08-11T00:00:00Z',
  'lap?': false,
  'refinance?': false,
  property: {
    'street-address': '4515 chestnut dr',
    city: 'Westland',
    state: 'MI',
    'postal-code': '48185',
  },
  'ext-features': {
    'gateless-va-tasks': 'va-tasks-20220401',
    'gateless-jumbo-tasks': 'jumbo-tasks-20220401',
    'gateless-tasks-20211025': true,
    'voa-vendor': 'finicity',
    'task-list-ready': true,
    'my-account': true,
    'uw-milestone-reached': true,
  },
  'loan-number': '224386821',
  'closing-date': '2022-08-19T00:00:00.000Z',
  'milestone-modals?': true,
  'base-loan-amount': 240000,
  'smart-underwrite?': false,
  'digital-mortgage?': true,
  'down-payment': 60000,
  team: {
    lo: {
      features: ['l2-pal'],
      'emp-id': 18580,
      fax: '773-555-5555',
      email: 'testlo@rate.com',
      'photo-url': 'https://www.guaranteedrate.com/images/vp/TestLO2.jpg',
      phone: '773-555-1212',
      name: 'Test LO2',
      licenses: [
        {
          state: 'KY',
          'license-number': 'TEST-KY',
        },
        {
          state: 'IA',
          'license-number': 'TEST-IA',
        },
        {
          state: 'VA',
          'license-number': 'TEST-VA',
        },
        {
          state: 'OR',
          'license-number': 'TEST-OR',
        },
        {
          state: 'WY',
          'license-number': 'TEST-WY',
        },
        {
          state: 'SC',
          'license-number': 'TEST-SC',
        },
        {
          state: 'CT',
          'license-number': 'TEST-CT',
        },
        {
          state: 'FL',
          'license-number': 'TEST-FL',
        },
        {
          state: 'GA',
          'license-number': 'TEST-GA',
        },
        {
          state: 'NE',
          'license-number': 'TEST-NE',
        },
        {
          state: 'ME',
          'license-number': 'TEST-ME',
        },
        {
          state: 'MT',
          'license-number': 'TEST-MT',
        },
        {
          state: 'WI',
          'license-number': 'TEST-WI',
        },
        {
          state: 'TN',
          'license-number': 'TEST-TN',
        },
        {
          state: 'WV',
          'license-number': 'TEST-WV',
        },
        {
          state: 'VT',
          'license-number': 'TEST-VT',
        },
        {
          state: 'ND',
          'license-number': 'TEST-ND',
        },
        {
          state: 'DE',
          'license-number': 'TEST-DE',
        },
        {
          state: 'IN',
          'license-number': 'TEST-ID',
        },
        {
          state: 'AR',
          'license-number': 'TEST-AR',
        },
        {
          state: 'NH',
          'license-number': 'TEST-NH',
        },
        {
          state: 'AL',
          'license-number': 'TEST-AL',
        },
        {
          state: 'MA',
          'license-number': 'TEST-MA',
        },
        {
          state: 'PA',
          'license-number': 'TEST-PA',
        },
        {
          state: 'LA',
          'license-number': 'TEST-LA',
        },
        {
          state: 'OK',
          'license-number': 'TEST-OK',
        },
        {
          state: 'MS',
          'license-number': 'TEST-MS',
        },
        {
          state: 'TX',
          'license-number': 'TEST-TX',
        },
        {
          state: 'UT',
          'license-number': 'TEST-UT',
        },
        {
          state: 'CO',
          'license-number': 'TEST-CO',
        },
        {
          state: 'NM',
          'license-number': 'TEST-NM',
        },
        {
          state: 'SD',
          'license-number': 'TEST-SD',
        },
        {
          state: 'NY',
          'license-number': 'TEST-NY',
        },
        {
          state: 'RI',
          'license-number': 'TEST-RI',
        },
        {
          state: 'NJ',
          'license-number': 'TEST-NJ',
        },
        {
          state: 'MO',
          'license-number': 'TEST-MO',
        },
        {
          state: 'ID',
          'license-number': 'TEST-ID',
        },
        {
          state: 'AZ',
          'license-number': 'TEST-AZ',
        },
        {
          state: 'AK',
          'license-number': 'TEST-AK',
        },
        {
          state: 'HI',
          'license-number': 'TEST-HI',
        },
        {
          state: 'MI',
          'license-number': 'TEST-MI',
        },
        {
          state: 'NV',
          'license-number': 'TEST-NV',
        },
        {
          state: 'KS',
          'license-number': 'TEST-KS',
        },
        {
          state: 'MD',
          'license-number': 'TEST-MD',
        },
        {
          state: 'MN',
          'license-number': 'TEST-MN',
        },
        {
          state: 'CA',
          'license-number': 'TEST-CA',
        },
        {
          state: 'OH',
          'license-number': 'TEST-OH',
        },
        {
          state: 'DC',
          'license-number': 'TEST-DC',
        },
        {
          state: 'NC',
          'license-number': 'TEST-NC',
        },
        {
          state: 'IL',
          'license-number': 'TEST-ILLINOIS',
        },
        {
          state: 'WA',
          'license-number': 'TEST-WA',
        },
      ],
      status: 'active',
      id: 'testlo',
      'role-name': 'Loan Officer',
      "nmls-id": "12345",
      'role-key': 'lo',
      title: "VP Of Mortgage Lending",
    },
    mc: {
      'role-name': 'Mortgage Consultant',
      fax: '999-999-999',
      email: 'encompasstrainers@rate.com',
      phone: '888-888-8888',
      name: 'Test MC',
      id: 'testmc',
      'role-key': 'mc',
    },
    lc: {
      id: 'testlc',
      email: 'encompasstrainers@rate.com',
      name: 'Test LC',
      'role-name': 'Loan Coordinator',
      phone: '773-290-0505',
      'role-key': 'lc',
    },
  },
  'loan-guid': '84c68020-a65d-4f17-a7d9-2fae605d0ddd',
  'prequalification?': false,
  borrower: {
    name: 'Andy America',
    email: 'myaccount-servicing01@yopmail.com',
    'language-preference': 'en',
    'ssn?': true,
  },
  coborrower: {
    name: 'Amy America',
    email: 'myaccount-r18-03b@yopmail.com',
    'language-preference': 'en',
    'ssn?': true,
  },
  'amortization-type': 'Fixed',
  'loan-term-months': 360,
  'application-status': 'completed',
  'gr-loan-guid': '6b7ee090-93c3-4110-a4f3-fd6336383b9d',
  'respa-to-ctc': '1 day',
  'started-date': '2022-07-28T16:47:27.000Z',
  'comortgagor?': false,
  'conditional-approval-with-letter?': false,
};

export default loanFunded;
