import { CSSProperties } from 'react';
import { FadeProps } from './IFade';
import Animation from './Animation';

export const transitionStyles = {
  entering: { opacity: 1, visibility: 'visible' },
  entered: { opacity: 1, visibility: 'visible' },
  exiting: { opacity: 0, visibility: 'visible' },
  exited: { opacity: 0, visibility: 'hidden' },
} as Record<string, CSSProperties>;

export const getDefaultStyles = (duration: number): CSSProperties => ({
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  visibility: 'hidden',
});

const Fade = ({ on, duration = 300, children, className, ...rest }: FadeProps) => {
  return (
    <Animation
      on={on}
      duration={duration}
      defaultStyle={getDefaultStyles(duration)}
      transitionStyles={transitionStyles}
      className={className}
      {...rest}
    >
      {children}
    </Animation>
  );
};

export default Fade;
