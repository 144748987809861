import { AlpMilestone, type Loan } from '../../../features/loans/types';
import { formatAddressObject } from '../../../utils/addressFormatter';
import { alpCESApplication } from '../../../utils/grLinks';
import Button from '../../ui/button/Button';

export const CESMilestoneHeadline = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid, property, 'alp-ces-loan': cesLoan } = loan;

  const milestone = cesLoan?.milestone;
  let title = undefined;
  let contentNode = undefined;

  if (milestone === AlpMilestone.DENIED) {
    title = 'Thank you for your recent application for a Guaranteed Rate Loan';
    contentNode = (
      <>
        <p className='text-xl'>
          After carefully reviewing your application, we are sorry to advise you that we cannot grant you a loan. Your notice will be added to your documents within 30 days.
        </p>
        <p className='text-xl mt-8'>
          You can click “Apply again” to start a new application.
        </p>
        <Button
          className='mt-10'
          buttonType='primary'
          size='large'
          text='Apply again'
          href={alpCESApplication(loanGuid)}
          target='_blank'
        />
      </>
    );
  } else {
    title = `Let's get you approved for`;
    contentNode = <p className='text-xl'>{formatAddressObject(property)}</p>;
  }

  return (
    <>
      <h1 className='text-marketing-xs md:text-marketing-md mb-2'>{title}</h1>
      <>{contentNode}</>
    </>
  );
};
