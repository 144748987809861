import { RateAppAd1, RateAppAd2, RateAppAd3, RateAppAd4 } from "../../../components/rateAppAd/RateAppAd";

const RateAppAdStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Rate App Ad</div>
      <div className='mb-8' style={{ maxWidth: '912px' }}>
        <RateAppAd1 location='styleguide' />
      </div>

      <div className='mb-8' style={{ maxWidth: '912px' }}>
        <RateAppAd3 location='styleguide' />
      </div>

      <div className='mb-8'>
        <RateAppAd4 location='styleguide' />
      </div>

      <div className='mb-8'>
        <RateAppAd2 location='styleguide' />
      </div>
    </>
  );
};

export default RateAppAdStyleguide;
