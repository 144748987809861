import type { RootState } from '../store/types';
import { useAppSelector } from './hooks';
import { selectLoanTeam} from '../features/loans/loansSlice';
import { useDefaultSupport } from './useDefaultSupport';

export interface LoanSupport {
  name: string;
  email: string;
  phone: string;
}

const useLoanSupport = (loanGuid: string): LoanSupport => {
  const { lo } = useAppSelector((state: RootState) => selectLoanTeam(state, loanGuid)) || {};
  const defaultSupport = useDefaultSupport();
  return lo?.name && lo?.email && lo?.phone
    ? {
        name: lo.name,
        email: lo.email,
        phone: lo.phone,
      }
    : defaultSupport;
};

export default useLoanSupport;
