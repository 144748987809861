import { useEffect, useState } from 'react';
import { Task } from '../../../features/tasks/types';
import { useAdditionalTaskDetails } from '../../../hooks/useAdditionalTaskDetails';
import { useTaskText } from '../../../hooks/useTaskText';
import FormButtonComponent from '../../ui/form/FormButtonComponent';
import { AdditionalTaskDetails, DefaultTaskBodyDescription } from '../task/Task';
import Modal from '../../ui/modal/Modal';
import { log } from '../../../utils/logger';
import { serializeError } from '../../../utils/serializeError';
import api from '../../../features/api';
import { Connect, ConnectErrorEvent } from 'connect-web-sdk';
import { sendTaskUpdateEvent } from '../../../features/analytics/sendTaskUpdateEvent';
import { updateTaskCompleted } from '../../../features/tasks/actions';
import { useDispatch } from 'react-redux';

export const ASSETS_REVERIFY_TYPES = ['gr_voai_reverify', 'gr_voa_reverify', 'gr_voe_transactions_reverify'] as const;

const AssetsReverifyTaskBody = ({
  loanGuid,
  task,
}: {
  loanGuid: string;
  task: Task<'gr_voai_reverify' | 'gr_voa_reverify' | 'gr_voe_transactions_reverify'>;
}) => {
  const { 'task-id': taskId, 'task-type': taskType, 'task-meta': taskMeta } = task;
  const taskDescription = useTaskText(task, 'description', loanGuid);
  const additionalDetails = useAdditionalTaskDetails(task, loanGuid);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [vendorUrl, setVendorUrl] = useState<string>();
  const dispatch = useDispatch();

  const handleError = () => {
    setLoading(false);
    setError('Verify error. Please try again later.');
  };

  const handleTaskButtonClick = async () => {
    try {
      sendTaskUpdateEvent({ taskId, taskType });
      log({ loanGuid, taskId, level: 'info', message: 'Assets Reverification: Order' });
      setLoading(true);
      setError(undefined);

      if (!taskMeta?.orderId) {
        log({ loanGuid, taskId, level: 'error', message: 'Assets Reverification: Reverify failed — no order ID present on task' });
        setLoading(false);
        setError('Task is missing data. Please contact support.');
        return;
      }

      const response = await api.reverifyAssets(
        loanGuid,
        taskType === 'gr_voe_transactions_reverify' ? 'final-voe' : 'refresh',
        taskMeta.orderId,
      );

      if (response?.data?.uiElement) {
        log({ loanGuid, taskId, level: 'info', message: 'Assets Reverification: Success' });
        setVendorUrl(response.data.uiElement);
      } else {
        log({ loanGuid, taskId, level: 'info', message: 'Assets Reverification: Order failed — no link' });
        handleError();
      }
    } catch (error) {
      log({ loanGuid, taskId, level: 'error', message: `Assets Reverification: Order failed — ${serializeError(error)}` });
      handleError();
    }
  };

  useEffect(() => {
    setShowVendorModal(!!vendorUrl);
  }, [vendorUrl]);

  const onVendorModalOpened = () => {
    if (!vendorUrl) return;

    setLoading(false);

    Connect.launch(
      vendorUrl,
      {
        onDone: () => {
          handleVendorClose();
          dispatch(updateTaskCompleted(taskId, true));
        },
        onCancel: () => {
          handleVendorClose();
        },
        onError: (event: ConnectErrorEvent) => {
          log({ loanGuid, taskId, level: 'error', message: `Assets Reverification: Finicity error ${event}` });
        },
      },
      {
        selector: '#finicity-connect-container',
        overlay: 'rgba(255, 255, 255, 0)',
      },
    );
  };

  const handleVendorClose = () => {
    (window as any).finicityConnect?.destroy();
    setVendorUrl(undefined);
    setShowVendorModal(false);
  };

  const onVendorModalClose = () => {
    handleVendorClose();
  };

  const AssetsReverifyTask = () => {
    return (
      <div className='flex flex-col justify-center'>
        <FormButtonComponent
          containerClassName='pb-3'
          buttonContainerClassName='w-full md:w-fit-content'
          className='w-full md:w-fit-content'
          buttonType='primary'
          error={error}
          loading={loading}
          onClick={handleTaskButtonClick}
        >
          Verify assets and income
        </FormButtonComponent>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={showVendorModal}
        contentLabel='Vendor Reverification'
        onRequestClose={onVendorModalClose}
        fullWidth={true}
        onOpened={onVendorModalOpened}
        contentStyle={{ width: '100%', height: '755px' }}
      >
        <div id='finicity-connect-container' className='flex items-center justify-center' />
      </Modal>

      <DefaultTaskBodyDescription taskDescription={taskDescription} />
      {additionalDetails && <AdditionalTaskDetails additionalDetails={additionalDetails} />}
      <AssetsReverifyTask />
    </>
  );
};

export default AssetsReverifyTaskBody;
