import { debug } from '../../utils/env';
import { log } from '../../utils/logger';
import { serializeError } from '../../utils/serializeError';
import api from '../api';
import { getAppGlobalMobileAppFromStore } from '../app/getAppFromStore';
import { frontendFeatureEnabled } from '../../hooks/useFeatureEnabled';

declare global {
  interface Window {
    // ios
    webkit?: any;
    // android
    JSBridge?: any;
  }
}

const documentShellAppTypes = ['VIEW_DOCUMENT', 'DOWNLOAD_DOCUMENT'] as const;
export type DocumentShellAppType = (typeof documentShellAppTypes)[number];

const getBase64FromUrl = async (url: string) => {
  try {
    const resp = await api.getBlob(url);
    const blob = resp.data;
    const base64 = await blobToBase64(blob);
    return base64;
  } catch (error) {
    log({ level: 'error', message: `get blob ${url} error ${serializeError(error)}` });
  }
  return null;
};

const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = () => reject('blob to base64 error');
    reader.readAsDataURL(blob);
  });
};

export const sendDocumentEventToShellApp = async (
  type: DocumentShellAppType,
  href: string,
  filename: string,
  skipDataBlob = false,
) => {
  const object = {
    type,
    href,
    filename,
  };

  if (!skipDataBlob) {
    const blob = await getBase64FromUrl(href);
    if (blob) {
      (object as any).payload = blob;
    }
  }

  sendShellAppEvent(JSON.stringify(object));
};

export const sendGlobalLoadingEventToShellApp = (loading: boolean) => {
  const object = {
    type: 'MYA_GLOBAL_LOADING',
    loading,
  };
  sendShellAppEvent(JSON.stringify(object));
};

const sendShellAppEvent = (message: string) => {
  const enabled = frontendFeatureEnabled('mobile-app-events');
  const mobileApp = getAppGlobalMobileAppFromStore();

  if (!enabled) return;

  if (!mobileApp) return;

  // iOS
  const iosPostMessage = window.webkit?.messageHandlers?.jsMessageHandler?.postMessage;
  // android
  const androidPostMessage = window.JSBridge?.postMessage;
  // if either exist
  const hasBridge = iosPostMessage || androidPostMessage;

  if (hasBridge) {
    try {
      if (debug) {
        const flag = '⏱️';
        console.log(`${flag}%c Event:`, 'color: blue', 'Shell App Event', { message });
      }

      if (iosPostMessage) {
        window.webkit.messageHandlers.jsMessageHandler.postMessage(message);
      } else {
        window.JSBridge.postMessage(message);
      }
    } catch (error) {
      log({ level: 'error', message: `post native message ${message} error ${serializeError(error)}` });
    }
  } else {
    log({ level: 'error', message: `no iOS or Android JS bridge detected, unable to post message ${message} to native app` });
  }
};
