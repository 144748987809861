import { selectLoan } from '../features/loans/loansSlice';
import { Task } from '../features/tasks/types';
import { RootState } from '../store/types';
import { useAppSelector } from './hooks';
import { useMultilingual } from './useMultilingual';

export const useAdditionalTaskDetails = (task: Task<any>, loanGuid: string) => {
  const multilingual = useMultilingual();
  const loan = useAppSelector((state: RootState) => selectLoan(state, loanGuid));
  const additionalDetails = task['additional-details'];
  return multilingual && loan?.['lap?'] && additionalDetails;
};
