import { withTransaction } from '@elastic/apm-rum-react';
import { PersonalLoanDetailsCard } from '../../components/loan/personal/details/PersonalLoanDetailsCard';
import { PersonalLoanFAQs } from '../../components/loan/personal/details/PersonalLoanFAQs';
import withPersonalLoan from '../../components/loan/personal/withPersonalLoan';
import SupportHours from '../../components/loan/support/SupportHours';
import { EmailPhoneWidget, SupportHeadline } from '../../components/loan/support/Support';
import { SubpageNavigation } from '../../components/navigation/SubpageNavigation';
import { DOC_TITLES, PATHS } from '../../components/routes/paths';
import { SSOButton } from '../../components/ssoButton/SSOButton';
import { personalLoansSSO } from '../../features/api';
import { getDisplayedLoanNumber, isPersonalLoanFunded } from '../../features/personalLoans/personalLoansSlice';
import { iPersonalLoan } from '../../features/personalLoans/types';
import { content } from '../../features/tenant/tenant';
import { buildTab } from '../loan/LoanNav';
import { useDocumentTitleLoanPage } from '../../hooks/useDocumentTitle';
import { LeftContentRightRail } from '../../components/layout/Layout';

const PersonalLoanDetails = ({ personalLoan }: { personalLoan: iPersonalLoan }) => {
  const isFunded = isPersonalLoanFunded(personalLoan);
  return (
    <div className='OneLinkNoTx'>
      <PersonalLoanDetailsCard personalLoan={personalLoan} />
      <h2 className='text-2xl mb-6'>Service loan</h2>
      <div className='mb-6'>
        <p>
          {isFunded ?
            'Click on “Manage Account” button to see all servicing details about your loan. Using this link, you can see all your loan details, make payments or change payment method, see your loan documents and much more.' :
            'Your loan is currently in the process to get funded. Once the loan is funded, The "Manage Account" button will get activated and subsequently you will be able to manage and service your account.'
          }
        </p>
      </div>
      <div className='mb-12'>
        <SSOButton
          disabled={!isFunded}
          text='Manage Account'
          buttonType='primary'
          className='w-full md:w-auto'
          iconName='external-link'
          errorMessage='An error occurred. Please try again.'
          ssoDataURL={personalLoansSSO(personalLoan.id)}
        />
      </div>
      <PersonalLoanFAQs phoneNumber={content?.personalLoans?.faqPhone} />
    </div>
  );
};

const PersonalLoansSupport = ({ personalLoan }: { personalLoan: iPersonalLoan }) => {
  const contact = personalLoan && isPersonalLoanFunded(personalLoan) ? content.personalLoans.funded : content.personalLoans.approved;
  const { email, phone } = contact;
  return email || phone ? (
    <div className='flex flex-col'>
      <SupportHeadline headline='For personal loan questions:' />
      <EmailPhoneWidget widgetName='personalLoanSupport' email={email} phone={phone} />
      {contact.supportHours?.length &&
        <div className='mt-6'>
          <SupportHours supportDaysTime={contact.supportHours} />
        </div>
      }
    </div>
  ) : null;
};

const PersonalLoanNav = ({ personalLoan }: { personalLoan: iPersonalLoan }) => {
  const tab = buildTab('Loan details', PATHS.personalLoan, PATHS.personalLoan, personalLoan.id);
  return <SubpageNavigation tabs={[tab]} />
};

const PersonalLoan = ({ personalLoan }: { personalLoan: iPersonalLoan }) => {
  const loanNumber = getDisplayedLoanNumber(personalLoan);
  useDocumentTitleLoanPage(DOC_TITLES.personalLoan, loanNumber);

  return (
    <LeftContentRightRail
      subnav={<PersonalLoanNav personalLoan={personalLoan} />}
      rightRail={<PersonalLoansSupport personalLoan={personalLoan} />}
    >
      <PersonalLoanDetails personalLoan={personalLoan} />
    </LeftContentRightRail>
  );
};

export default withTransaction('PersonalLoan', 'page-load')(withPersonalLoan(PersonalLoan));
