import FormGroup from '../../../components/ui/form/FormGroup';

const MaskedInputs = () => {
  const testOnChange = (value: any) => {
    console.log('test on change', value);
  };
  
  return (
    <>
      <p>Masked Date Input FormGroup</p>
      <FormGroup
        label='Test date'
        id='test-date'
        name='date'
        type='date'
        onChange={testOnChange}
        onValueChange={testOnChange}
      />

      <p>Masked Dollar Input FormGroup</p>
      <FormGroup
        label='Test currency'
        id='test-currency'
        name='currency'
        type='currency'
        onChange={testOnChange}
        onValueChange={testOnChange}
      />

      <p>Masked Phone Input Formgroup</p>
      <FormGroup
        label='Test phone'
        id='test-phone'
        name='phone'
        type='phone'
        onChange={testOnChange}
        onValueChange={testOnChange}
      />
    </>
  );
};

export default MaskedInputs;
