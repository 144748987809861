import Icon from '../icon/Icon';

const Sorter = ({ label, desc, onClick }: { label: string; desc?: boolean; onClick: (...any: any[]) => any }) => (
  <button className='select-none cursor-pointer' onClick={onClick}>
    Sort: <span className='font-bold'>{label}</span>
    <span className={`ml-2 inline-flex align-middle${desc ? ' transform rotate-180' : ''}`}>
      <Icon name='sort-ascending' size='0.625rem' />
    </span>
  </button>
);

export default Sorter;
