import { getHELOCProperty } from './HELOCLoanCard';
import { getDisplayedLoanNumber, selectHELOCLoanById } from '../../../features/helocLoans/helocLoansSlice';
import { iHELOCLoan } from '../../../features/helocLoans/types';
import { useAppSelector } from '../../../hooks/hooks';
import { useHELOCLoanConfig } from '../../../hooks/useHELOCLoanConfig';
import { DashboardLoanCard } from './DashboardLoanCards';
import { FORMAT_LONG, FORMAT_SHORT, formatDateWithoutTimezone } from '../../../utils/date';
import { TimeProps } from '../../ui/time/ITime';
import Time from '../../ui/time/Time';

const DateInfo = (loan: iHELOCLoan) => {
  let dateInfo;
  if (loan.loanDetails.fundingDate) {
    const fundingDate = loan.loanDetails.fundingDate;
    dateInfo = {
      date: fundingDate,
      label: `Closed on ${formatDateWithoutTimezone(fundingDate, FORMAT_SHORT)}`,
      title: formatDateWithoutTimezone(fundingDate, FORMAT_LONG),
    };
  }

  if (!dateInfo) return null;

  return <Time {...(dateInfo as TimeProps)} />;
};

export const DashboardHELOCLoanCardPresentational = ({ loan }: { loan: iHELOCLoan }) => {
  const loanConfig = useHELOCLoanConfig(loan);
  const loanNumber = getDisplayedLoanNumber(loan);
  if (!loanConfig) return null;
  return (
    <DashboardLoanCard
      loanAmount={loan.loanDetails.initialDrawAmount}
      loanConfig={loanConfig}
      dateNode={<DateInfo {...loan} />}
      loanNumber={loanNumber}
      address={getHELOCProperty(loan.propertyAddress)}
    />
  );
};

export const DashboardHELOCLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const loan = useAppSelector(state => selectHELOCLoanById(state, loanGuid));
  if (!loan) return null;
  return (
    <DashboardHELOCLoanCardPresentational loan={loan} />
  );
};
