import { themeId } from '../../../features/tenant/tenant';
import THEMES from '../../../themes/themes';

const themes = THEMES.THEMES as Record<iThemes, unknown>;
const theme = themes[themeId] as TenantTheme;

// corner radius is configurable beyond what is listed here
// see: https://tailwindcss.com/docs/border-radius

const labels: Record<Partial<TenantThemeBorderRadius>, string> = {
  none: 'None',
  DEFAULT: 'Light',
  xl: 'Medium',
  full: 'Full',
  button: 'Button',
};

const classNames: Record<Partial<TenantThemeBorderRadius>, string> = {
  none: 'rounded-none',
  DEFAULT: 'rounded',
  xl: 'rounded-xl',
  full: 'rounded-full',
  button: 'rounded-button',
};

const CornerRadiusItem = ({ radius }: { radius: TenantThemeBorderRadius }) => {
  const tailwindClassName = classNames[radius];
  const size = theme.borderRadius?.[radius];

  return (
    <div>
      <h2 className='header-small'>{labels[radius] ? `${labels[radius]} ` : <>&nbsp;</>}</h2>
      <div
        id={`example-${tailwindClassName}`}
        className={`mt-6 mb-2 inline-block ${tailwindClassName} bg-black`}
        style={{ width: '72px', height: '72px' }}
      />
      <div className='text-xs'>Tailwind: {tailwindClassName}</div>
      <div className='text-xs'>Size: {size}</div>
    </div>
  );
};

const CornerRadius = () => (
  <div>
    <div className='mb-6'>
      <h2 className='header-medium'>Corner radius</h2>
    </div>
    {theme.borderRadius && (
      <div className='corner-radius grid grid-cols-4 gap-10 mb-5'>
        {Object.keys(theme.borderRadius).map(radius => (
          <CornerRadiusItem radius={radius as TenantThemeBorderRadius} key={radius} />
        ))}
      </div>
    )}
  </div>
);

export default CornerRadius;
