import { Location } from 'history';
import { useLocation } from 'react-router-dom';
import { searchParams } from '../utils/url';

const useSearchParams = () => {
  const { search } = useLocation<Location>();
  return searchParams(search);
};

export default useSearchParams;
