import { useEffect } from 'react';
import type { RootState } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { selectIsAuth } from '../auth/authSlice';
import {
  fetchUser,
  fetchUserAttribute,
  selectHasUser,
  selectHasUserAttribute,
  selectUserAttributeEntity,
  selectUserEntity,
} from '../user/userSlice';
import { fetchLoans, selectLoansEntity } from '../loans/loansSlice';
import { LOAN_ATTRIBUTES } from '../user/types';
import { selectHasGlobalData, updateGlobalError } from './appSlice';
import useEmbed from '../../hooks/useEmbed';

// Second initialization layer, fetch global data when user is authenticated
const useFetchGlobalData = () => {
  const dispatch = useAppDispatch();
  const embed = useEmbed();
  const isAuth = useAppSelector(selectIsAuth);
  const hasUserData = useAppSelector(selectHasUser);
  const { error: userError } = useAppSelector(selectUserEntity);
  const hasUserLoanAttributes = useAppSelector((state: RootState) => selectHasUserAttribute(state, LOAN_ATTRIBUTES));
  const { error: userAttributeError } = useAppSelector(selectUserAttributeEntity);
  const { hasData: hasLoansData, error: loansError } = useAppSelector(selectLoansEntity);
  const hasGlobalData = useAppSelector(selectHasGlobalData);

  // User

  useEffect(() => {
    isAuth && !hasUserData && dispatch(fetchUser());
  }, [isAuth, hasUserData, dispatch]);

  useEffect(() => {
    isAuth && !hasUserData && userError && dispatch(updateGlobalError(userError));
  }, [isAuth, hasUserData, userError, dispatch]);

  // User Loan Attributes

  useEffect(() => {
    isAuth && !hasUserLoanAttributes && dispatch(fetchUserAttribute(LOAN_ATTRIBUTES));
  }, [isAuth, hasUserLoanAttributes, dispatch]);

  useEffect(() => {
    isAuth && !hasUserLoanAttributes && userAttributeError && dispatch(updateGlobalError(userAttributeError));
  }, [isAuth, hasUserLoanAttributes, userAttributeError, dispatch]);

  // Loans

  useEffect(() => {
    // Don't fetch loans when embed mode is true
    if (embed) return;

    isAuth && !hasLoansData && dispatch(fetchLoans());
  }, [embed, isAuth, hasLoansData, dispatch]);

  useEffect(() => {
    isAuth && !hasLoansData && loansError && dispatch(updateGlobalError(loansError));
  }, [isAuth, hasLoansData, loansError, dispatch]);

  return hasGlobalData;
};

export default useFetchGlobalData;
