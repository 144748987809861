import FacebookShareButton from './FacebookShareButton';
import TwitterShareButton from './TwitterShareButton';
import useFacebookShare from '../../hooks/useFacebookShare';
import useTwitterShare from '../../hooks/useTwitterShare';
import type { SocialShareConfigs } from './ISocialShareConfig';
import themeConfig from '../../features/tenant/tenant';
import cn from 'classnames';
import { useSocialShare } from '../../hooks/useSocialShare';

const SocialShareComponent = (props: { configKey: SocialShareConfigs; fullButton?: boolean; showTitle?: boolean; className?: string }) => {
  const { configKey, fullButton = false, showTitle = true, className } = props; 

  // pass config + loan
  const facebookShareProps = useFacebookShare(configKey);
  const twitterShareProps = useTwitterShare(configKey);
  const socialShareEnabled = useSocialShare(configKey);

  const socialSharingContent = themeConfig.content.socialSharing;
  const metadata = socialSharingContent ? socialSharingContent[configKey] : null;

  if (!socialShareEnabled) {
    return null;
  }

  return (
    <div className={cn(className, metadata && metadata.display === 'below' ? 'text-center' : 'flex')}>
      {showTitle && <p className={metadata && metadata.display === 'below' ? 'mb-4' : 'mr-4'}>{metadata ? metadata.title : 'Share the news'}</p>}
      <div className='inline-flex items-start'>
        {facebookShareProps && <FacebookShareButton facebookProps={facebookShareProps} configKey={props.configKey} fullButton={fullButton} />}
        <span className={cn({ 'mr-2': !fullButton }, { 'mr-8': fullButton })} />
        {twitterShareProps && <TwitterShareButton twitterProps={twitterShareProps} configKey={props.configKey} fullButton={fullButton} />}
      </div>
    </div>
  );
};

export default SocialShareComponent;
