import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { fetchTasks } from '../tasks/actions';
import {
  SET_GATELESS_CONFIG,
  ADD_GATELESS_EVENT,
  EVENT_TASKLIST_SYNC,
  EVENT_TASK_UPDATED,
  EVENT_TASK_CREATED,
  EVENT_TASK_DELETED,
  EVENT_VOA_SSO_LINK_AVAILABLE,
  SET_GATELESS_VOA_SSO_LINK_ID,
  EVENT_VOA_FINICITY_CREATE_ACCOUNTS_FAILURE,
  EVENTS_FAILURE,
} from './actionTypes';
import { GatelessConfig, GatelessEvent } from './types';
import type { RootState } from '../../store/types';
import { throttle } from '../../utils/throttleThunk';
import { log } from '../../utils/logger';

export const setGatelessConfig =
  (loanGuid: string, config: GatelessConfig): ThunkAction<Promise<void>, RootState, undefined, AnyAction> =>
  async (dispatch, getState): Promise<void> => {
    const { 'consumer-token': token } = config;
    clearTimeout(getState().gateless.byLoanGuid[loanGuid]?.config?.refreshTimeoutIdx);
    const { exp } = JSON.parse(atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')));
    log({ loanGuid, message: `Gateless config updated for loan ${loanGuid}` });
    dispatch({
      type: SET_GATELESS_CONFIG,
      payload: {
        ...config,
        loanGuid,
        refreshTimeoutIdx: setTimeout(() => dispatch(fetchTasks(loanGuid, true)), exp * 1000 - 300000 - Date.now()),
      },
    });
  };

export const addGatelessEvent = (loanGuid: string, event: GatelessEvent) => {
  log({
    loanGuid,
    taskId: event.taskId,
    level: EVENTS_FAILURE.includes(event.eventName) ? 'error' : 'info',
    message: `${event.eventName} Gateless event triggered for loan ${loanGuid} ${JSON.stringify(event)}`,
  });
  return {
    type: ADD_GATELESS_EVENT,
    payload: {
      loanGuid,
      event,
    },
  };
};

export const setGatelessVoaSsoLinkId = (loanGuid: string, ssoLinkId: string | undefined | null) => {
  return {
    type: SET_GATELESS_VOA_SSO_LINK_ID,
    payload: {
      loanGuid,
      ssoLinkId,
    },
  };
};

export const gatelessEvent = (
  loanGuid: string,
  event: GatelessEvent,
): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>, getState): Promise<void> => {
    const { eventName, caseId } = event;

    // Events without a caseId are ignored.
    if (!eventName || !caseId) {
      return;
    }

    dispatch(addGatelessEvent(loanGuid, event));

    if ([EVENT_TASKLIST_SYNC, EVENT_TASK_UPDATED, EVENT_TASK_CREATED, EVENT_TASK_DELETED].includes(eventName)) {
      log({
        loanGuid,
        taskId: event.taskId,
        message: `Refetching tasks. Triggered by ${eventName} event. ${JSON.stringify(event)}`,
      });
      dispatch(throttle(`gateless:fetch-tasks:${loanGuid}`, fetchTasks(loanGuid)));
    } else if (eventName === EVENT_VOA_SSO_LINK_AVAILABLE) {
      const ssoLinkId = event.ssoLinkId;
      dispatch(setGatelessVoaSsoLinkId(loanGuid, ssoLinkId));
    } else if (eventName === EVENT_VOA_FINICITY_CREATE_ACCOUNTS_FAILURE) {
      dispatch(setGatelessVoaSsoLinkId(loanGuid, null));
    }
  };
};
