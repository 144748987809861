import cn from 'classnames';
import { ModalFooterProps } from './IModal';

const ModalFooter = ({ children, className, alignment = 'center' }: ModalFooterProps) => (
  <div
    className={cn(
      'modal__footer',
      'flex flex-col-reverse lg:flex-row  w-full mt-8',
      { 'justify-center': alignment === 'center' },
      { 'justify-end': alignment === 'right' },
      className,
    )}
    data-ui='modal-footer'
  >
    {children}
  </div>
);

export default ModalFooter;
