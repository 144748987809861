import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { openModal, closeModal, setModalContent } from '../tasklist/TaskModal';
import TaskTitleBadge from '../task/TaskTitleBadge';
import Button from '../../ui/button/Button';
import { LanguagePreferenceTaskProps, LanguagePreferenceTitleProps } from './iLanguagePreference';
import { isoToName } from '../../../utils/multilingual';
import LanguagePreferenceModalContent from './LanguagePreferenceModal';
import { updateLanguagePreferenceTask } from '../../../features/tasks/actions';
import ModalLoading from '../../ui/modal/ModalLoading';
import ErrorModalContent from '../../ui/modal/ErrorModalContent';
import { useDefaultSupport } from '../../../hooks/useDefaultSupport';
import { selectAppLanguage } from '../../../features/app/appSlice';
import { log } from '../../../utils/logger';
import '../task/Task.scss';
import { sendTaskUpdateEvent } from '../../../features/analytics/sendTaskUpdateEvent';

export const LANGUAGE_PREFERENCE_TYPES = ['language_preference'] as const;

export const LanguagePreferenceTaskBody = (props: LanguagePreferenceTaskProps) => {
  const {
    task: {
      'task-completed': completed,
      'task-type': taskType,
      'task-id': taskId,
      'custom-task-meta': { 'language-preference': selectedLanguage },
    },
    loanGuid,
  } = props;
  const dispatch = useAppDispatch();
  const buttonText = completed ? 'Edit' : 'Select';
  const appLanguage = useAppSelector(selectAppLanguage);
  const modalContent = (
    <LanguagePreferenceModalContent
      loanGuid={loanGuid}
      taskId={taskId}
      selectedLanguage={selectedLanguage}
      appLanguage={appLanguage}
      onClick={(language: string) => updateLanguagePreference(language)}
    />
  );
  const support = useDefaultSupport();
  const errorModalContent = (
    <ErrorModalContent
      onDone={() => closeModal(taskId)}
      title='Selection unsuccessful'
      location='your tasklist'
      support={support}
    />
  );

  const handleButtonClick = (): void => {
    log({ loanGuid, taskId, message: 'languagePreferenceTask: Task modal opened' });
    openModal(taskId, modalContent, `${taskType} Task`);
  };

  const updateLanguagePreference = (selection: string): void => {
    if (selection !== selectedLanguage) {
      setModalContent(taskId, <ModalLoading title='Hang tight while we update your preference.' />);
      sendTaskUpdateEvent({ taskId, taskType });
      log({ loanGuid, taskId, message: `languagePreferenceTask: Language preference updating: "${selection}"` });
      dispatch(updateLanguagePreferenceTask({ loanGuid, taskId, language: selection }))
        .then(({ requiresReload }) => {
          !requiresReload && closeModal(taskId);
          log({ loanGuid, taskId, message: `languagePreferenceTask: Language preference update complete: "${selection}"` });
        })
        .catch(() => {
          setModalContent(taskId, errorModalContent);
          log({ level: 'error', loanGuid, taskId, message: `languagePreferenceTask: Language preference update failed: "${selection}"` });
        });
    } else {
      closeModal(taskId);
    }
  };

  return (
    <Button className='task-button' buttonType='tertiary' title='Language preference task' onClick={handleButtonClick} name="openTaskLanguagePreferenceModal">
      {buttonText}
    </Button>
  );
};

export const LanguagePreferenceTaskTitle = ({
  taskTitle,
  task: {
    'task-completed': completed,
    'custom-task-meta': { 'language-preference': language },
  },
}: LanguagePreferenceTitleProps) => {
  const appLanguage = useAppSelector(selectAppLanguage);
  return <TaskTitleBadge title={taskTitle} additionalContent={completed && language ? `: ${isoToName(language, appLanguage === 'en')}` : undefined} />;
};
