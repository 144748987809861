import { ChangeEvent, useEffect, useState } from 'react';
import nextId from '../../../utils/nextId';
import { log } from '../../../utils/logger';
import FormGroup from '../form/FormGroup';
import { FormGroupElement } from '../form/IFormGroup';
import useGoogleApi from '../../../hooks/useGoogleApi';

const GooglePlacesAutocomplete = ({
  id,
  label,
  onBlur,
  onAddressChange,
  onChange,
  error,
}: {
  id?: string;
  label?: string;
  onAddressChange?: (autocomplete: google.maps.places.Autocomplete) => void;
  onChange?: (e: ChangeEvent<FormGroupElement>) => void;
  onBlur?: () => void;
  error?: string;
}) => {
  const elementId = id || nextId('autocomplete');
  const [valueState, setValueState] = useState<string>('');
  const [googleConfigured, setGoogleConfigured] = useState(false);

  const [googleapisLoaded, googleapisError] = useGoogleApi({
    libraries: 'places'
  });

  const setPlaceValue = (autocomplete: google.maps.places.Autocomplete) => {
    const address = autocomplete.getPlace();
    setValueState(address['formatted_address'] as string);
  };

  const configureAuto = () => {
    if (!window.google || googleConfigured) {
      googleapisError && log({ level: 'error', message: 'Google Places script error' });
      return;
    }

    setGoogleConfigured(true);

    const input = document.getElementById(elementId) as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(input, {
      types: ['geocode'],
    });
    autocomplete.setFields(['formatted_address', 'address_components', 'geometry']);

    // listener for selecting from dropdown and enter
    autocomplete.addListener('place_changed', () => {
      if (!autocomplete.getPlace().address_components) return;
      setPlaceValue(autocomplete);
      onAddressChange?.(autocomplete);
    });
  };

  useEffect(configureAuto, [googleapisLoaded, googleapisError, window.google]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = (e: ChangeEvent<FormGroupElement>) => {
    setValueState(e.target.value);
    onChange?.(e);
  };

  return (
    <FormGroup
      onChange={handleOnChange}
      onBlur={onBlur}
      value={valueState}
      error={error}
      placeholder=''
      id={elementId}
      label={label || 'Property address'}
    />
  );
};

export default GooglePlacesAutocomplete;
