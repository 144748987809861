import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { version, scm } from '../utils/env';
import { log } from '../utils/logger';
import { HomeValueUserAddress } from './homeValue/types';
import { iInsuranceEligibility } from './insurance/types';
import { UserAttributeKey, UserKey } from './user/types';
import { ServicingSSORequest, ServicingSSOResponse } from './servicing/types';
import {
  BorrowerAffirmationsPayload,
  BorrowerAgent,
  iReverifyActionType,
  iSourceType,
  LoxCreditInquiryPayload,
  LoxStandardPayload,
  TaskPatch,
} from './tasks/types';
import { ArticleLinksQueryParams, ArticleQueryParams } from './managedContent/types';
import getFromStore from '../store/getFromStore';
import { selectConfigCsrfToken } from './config/configSlice';
import { uuid } from '../utils/uuid';
import { VoieOrderResponse } from './voie/types';
import { RateAlert } from './rateAlert/types';

interface iRequestConfig extends AxiosRequestConfig {
  metadata: {
    startTime: number;
  };
}

interface iResponse extends AxiosResponse {
  config: iRequestConfig;
}

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

const toSeconds = (duration: number) => (duration / 1000).toFixed(2);

export const createAxios = (options: AxiosRequestConfig) => {
  const newInstance = axios.create(options);

  newInstance.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
    config.headers = config.headers ?? {};
    config.headers['x-request-id'] = uuid();
    const token = getFromStore(selectConfigCsrfToken);
    if (token) {
      config.headers['x-csrf-token'] = token;
    }
    return config;
  });

  newInstance.interceptors.request.use(
    (config: AxiosRequestConfig): iRequestConfig => {
      const metadata = { startTime: performance.now() };
      return { ...config, metadata };
    },
    async (error: AxiosError): Promise<AxiosError> => {
      return await Promise.reject(error);
    },
  );

  newInstance.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
      const resp = response as iResponse;
      const { config, headers } = resp;
      const responseTime = performance.now() - config.metadata.startTime;
      config.url !== '/v1/log' &&
        log({
          level: 'info',
          requestId: headers?.['x-request-id'],
          correlationId: headers?.['x-correlation-id'],
          cfRay: headers?.['cf-ray'],
          message: `Request success ${config.method} ${axios.getUri(config)} completed in ${toSeconds(responseTime)}s`,
        });
      return response;
    },
    async (error: AxiosError<{ causes?: unknown }>): Promise<AxiosError> => {
      const { config, response } = error;
      const level = response != null && response.status < 500 ? 'info' : 'error';
      config.url !== '/v1/log' &&
        log({
          level,
          requestId: response?.headers?.['x-request-id'],
          correlationId: response?.headers?.['x-correlation-id'],
          cfRay: response?.headers?.['cf-ray'],
          message: `Request fail ${config.method} ${axios.getUri(config)} - ${error.message}${
            error.response?.data?.causes ? ` ${JSON.stringify(error.response.data.causes)}` : ''
          }`,
        });
      return await Promise.reject(error);
    },
  );

  return newInstance;
};

const http = createAxios({
  timeout: 30000,
  withCredentials: true,
});

const blobHttp = createAxios({
  // timeout in 5 mins
  timeout: 300000,
  withCredentials: true,
  responseType: 'blob',
});

const uploadHttp = createAxios({
  // timeout in 5 mins
  timeout: 300000,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// -----------------------------------------------------------------------------
// CONFIG

const getConfig = () => http.get('/v1/config');

// -----------------------------------------------------------------------------
// AUTH

const getAuth = () => http.get('/v1/auth');

const postAuth = ({ username, password }: { username: string; password: string }) =>
  http.post('/v1/authenticate', {
    username,
    password,
  });

// -----------------------------------------------------------------------------
// USER

const getUser = () => http.get('/v1/user');

const updateUser = (key: UserKey, value: string) => http.patch(`/v1/user/${key}`, { [key]: value });

const getUserAttribute = (attributeKey: UserAttributeKey) => http.get(`/v1/user/attributes/${attributeKey}`);

const postUserAttribute = (attributeKey: UserAttributeKey, data: Record<string, any>) =>
  http.post(`/v1/user/attributes/${attributeKey}`, data);

// -----------------------------------------------------------------------------
//  EXTERNALLY MANAGED CONTENT

const getArticles = (params?: ArticleQueryParams) => http.get(`/v2/content/articles`, params && { params });

const getArticleLinks = (params: ArticleLinksQueryParams) => http.get(`/v2/content/article-links`, params && { params });

// -----------------------------------------------------------------------------
// HOMEVALUE

const getHomeValue = (params?: HomeValueUserAddress) => http.get(`/v1/home-value`, params && { params });

const getHomeValueHistory = (params?: HomeValueUserAddress) => http.get(`/v1/home-value/history`, params && { params });

// -----------------------------------------------------------------------------
// HOME SEARCH

const getHomeSearchSavedHomes = () => http.get(`/v2/home-search/homes`);

const getHomeSearchSavedSearches = () => http.get(`/v2/home-search/searches`);

// -----------------------------------------------------------------------------
// MORTGAGE LOANS

const getLoans = () => http.get('/v1/loans');

const getLoan = (loanGuid: string) => http.get(`/v1/loans/${loanGuid}`);

const postLoanInsurance = (loanGuid: string) => http.post(`/v1/loans/${loanGuid}/insurance-quote`);

const getLoanFeatures = (loanGuid: string) => http.get(`/v1/loans/${loanGuid}/features`);

const postLanguagePreference = (loanGuid: string, language: string) =>
  http.post(`/v1/loans/${loanGuid}/language-preference/${language}`);

const getRealEstateAgent = (loanGuid: string) => http.get(`/v2/loans/${loanGuid}/agent`);

const postRealEstateAgent = (loanGuid: string, agent: BorrowerAgent) => http.post(`/v2/loans/${loanGuid}/agent`, agent);

// -----------------------------------------------------------------------------
// PERSONAL LOANS

const getPersonalLoans = () => http.get('/v1/personal-loans');

// -----------------------------------------------------------------------------
// HELOC LOANS

const getHELOCLoans = () => http.get('/v1/heloc-loans');

// -----------------------------------------------------------------------------
// SSO

const getSSOData = (ssoDataURL: string) => http.get(ssoDataURL);

export const personalLoansSSO = (loanGuid: string) => `/v1/personal-loans/${loanGuid}/servicer-sso`;

// -----------------------------------------------------------------------------
// SERVICING

const getV2Servicing = (loanGuid: string) => http.get(`/v2/loans/${loanGuid}/servicing`);

const postV2LoanServicingSSO = (loanGuid: string, payload: ServicingSSORequest) =>
  http.post<ServicingSSOResponse>(`/v2/loans/${loanGuid}/servicing/sso`, payload);

const getHELOCServicing = (loanId: string, loanNumber: string) =>
  http.get(`/v1/heloc-loans/${loanId}/servicing?loan-number=${loanNumber}`);

// -----------------------------------------------------------------------------
// VOIE / VOAI / VOA

const postVoieOrder = (loanGuid: string) => http.post<VoieOrderResponse>(`/v2/loans/${loanGuid}/voie-order`);

const postVoaiOrder = (loanGuid: string, returnUrl: string) => http.post(`/v2/loans/${loanGuid}/voai-order`, { returnUrl });

const postVoaOrder = (loanGuid: string, returnUrl: string) => http.post(`/v2/loans/${loanGuid}/voa-order`, { returnUrl });

const reverifyAssets = (loanGuid: string, action: iReverifyActionType, orderId: string) =>
  http.post(`/v2/loans/${loanGuid}/reverify-assets`, { action, orderId });

// -----------------------------------------------------------------------------
// INSURANCE ELIGIBILITY

const getInsuranceEligibility = (loanGuid: string, campaign: string) =>
  http.get<iInsuranceEligibility>(`/v1/loans/${loanGuid}/insurance-online-marketplace?campaign=${campaign}`);

// -----------------------------------------------------------------------------
// PREFI RATE ALERT

const getRateAlert = (loanGuid: string) => http.get<RateAlert>(`/v1/loans/${loanGuid}/rate-alert`);

// -----------------------------------------------------------------------------
// GATELESS

const getGatelessContext = (loanGuid: string) => http.get(`/v1/loans/${loanGuid}/gateless-context`);

// -----------------------------------------------------------------------------
// GATELESS REFACTOR
const getSignSsoLink = (loanGuid: string, taskId: string, redirectUri: string) =>
  http.post(`/v1/loans/${loanGuid}/tasks/${taskId}/sign/redirect`, { redirectUri });

const orderVoaReport = (loanGuid: string, vendor: string, language: string) =>
  http.post(`/v1/loans/${loanGuid}/voa/order?vendor=${vendor}&language=${language}`);

const getVoaSsoLink = (loanGuid: string, vendor: string, ssoLinkId: string) =>
  http.get(`/v1/loans/${loanGuid}/voa/sso-link?vendor=${vendor}&ssoLinkId=${ssoLinkId}`);

// -----------------------------------------------------------------------------
// TASKS

// TODO: If/when we decide to lazy-load the task affirmations, omit include-affirmation-responses param here and use `getTaskAffirmations`
const getLoanTasks = (loanGuid: string) => http.get(`/v1/loans/${loanGuid}/tasks?include-affirmation-responses=true`);

const getLoanTask = (loanGuid: string, taskId: string, source: iSourceType) =>
  http.get(`/v1/loans/${loanGuid}/tasks/${taskId}?source=${source}`);

const getTaskAffirmations = (loanGuid: string, taskId: string) => http.get(`/v1/loans/${loanGuid}/tasks/${taskId}`);

const patchLoanTask = (loanGuid: string, taskId: string, payload: TaskPatch<'credentialed_voie' | 'gr_assets_voai'>) =>
  http.patch(`/v1/loans/${loanGuid}/tasks/${taskId}`, payload);

const postTaskDocument = (
  loanGuid: string,
  taskId: string,
  body: LoxStandardPayload | LoxCreditInquiryPayload | BorrowerAffirmationsPayload,
) => {
  if (!body.timezone) {
    log({
      loanGuid,
      level: 'info',
      taskId,
      message: `Time zone missing from postTaskDocument request. Time: ${new Date().toLocaleTimeString('en-us', {
        timeZoneName: 'short',
      })}`,
    });
  }
  return http.post(`/v1/loans/${loanGuid}/tasks/${taskId}/task-document`, body);
};

const getPendingTasks = (loanGuid: string) => http.get(`/v1/loans/${loanGuid}/tasks/pending`);

// -----------------------------------------------------------------------------
// WEBSOCKET

const getWebsocketToken = () => http.get('/v2/websocket/token');

// -----------------------------------------------------------------------------
// DOCUMENTS

const getDocuments = (loanGuid: string) => http.get(`/v1/loans/${loanGuid}/documents`);

const postDocument = (loanGuid: string, file: File, taskId?: string, source?: iSourceType) => {
  const formData = new FormData();
  formData.append('document', file);
  if (taskId && source) {
    formData.append('task-id', taskId);
    formData.append('source', source);
  }
  return uploadHttp.post(`/v1/loans/${loanGuid}/documents`, formData);
};

// -----------------------------------------------------------------------------
// CONSENT

const postConsent = ({
  loanGuid,
  consentType,
  name,
  agreed,
  language,
}: {
  loanGuid: string;
  consentType: string;
  name: string;
  agreed: boolean;
  language: string;
}) =>
  http.post(`/v1/loans/${loanGuid}/consent`, {
    'consent-type': consentType,
    'full-name': name,
    agreed,
    language,
  });

// -----------------------------------------------------------------------------
// PRE APPROVAL

const getPreapprovals = (guid: string) => http.get(`/v1/loans/${guid}/preapproval-letters`);

const getScenarioPreapprovalLetter = (loanGuid: string, level: string) =>
  http.get(`/v1/loans/${loanGuid}/preapproval-letters/scenario-and-letter`, { params: { level } });

const postPreapprovalViewed = (loanGuid: string, letterId: string) =>
  http.post(`/v1/loans/${loanGuid}/preapproval-letters/${letterId}/viewed`);

// -----------------------------------------------------------------------------
// REGISTRATION

const getRegistrationStatus = (params: { email?: string; token?: string }) => http.get(`/v2/user/registration/status`, { params });

const postRegistrationRequest = (email: string, ssn: string, firstName: string, lastName: string, language: string | null,  experience?: string) =>
  http.post('/v2/user/registration/request', { email, last4ssn: ssn, firstName, lastName, language, experience });

const postRegistrationComplete = (token: string, password: string, language: string | null) =>
  http.post<{ email: string }>('/v1/user/registration/complete', { token, password, language }).then(r => r.data);

// -----------------------------------------------------------------------------
// COBORROWER REGISTRATION

const getTokenStatus = (token: string) =>
  http.get(`/v1/borrower/ssn/status/${token}`).then(({ data }) => ({
    ...data,
    state: data.state === 'invalid' ? 'invalid-token' : data.state,
    triesRemaining: data['remaining-attempts'],
  }));

class VerifySSNError extends Error {}
export class ExpiredToken extends VerifySSNError {}
export class TooManyAttempts extends VerifySSNError {}
export class InvalidSSNAttempt extends VerifySSNError {
  constructor(public remainingAttempts: number) {
    super();
  }
}
export type SSNErrors = ExpiredToken | TooManyAttempts | InvalidSSNAttempt;

interface SSNErrorResponse {
  error: 'expired' | 'too many attempts' | 'invalid';
  'remaining-attempts'?: number;
}

const postBorrowerVerifySSN = (token: string, last4ssn: string) =>
  http.post(`/v1/borrower/ssn/validate/${token}`, { last4ssn }).catch((error: AxiosError<SSNErrorResponse | string>) => {
    if (error.response?.status === 500) throw error;
    if (typeof error.response?.data === 'string') throw new Error(error.response.data as string);
    if (error.response?.data.error === 'expired') throw new ExpiredToken();
    if (error.response?.data.error === 'too many attempts') throw new TooManyAttempts();
    if (error.response?.data.error === 'invalid') throw new InvalidSSNAttempt(error.response.data['remaining-attempts'] as number);
    throw error;
  });

const postBorrowerSetup = (token: string, last4ssn: string, password: string) =>
  http.post(`/v1/borrower/setup/${token}`, { last4ssn, password });

// -----------------------------------------------------------------------------
// LOG

const postLog = (body: { [x: string]: unknown }) => {
  body = { ...body, version, scm };
  (navigator.sendBeacon &&
    navigator.sendBeacon('/v1/log', new Blob([JSON.stringify(body)], { type: 'application/json; charset=UTF-8' }))) ||
    http.post('/v1/log', body);
};

const getBlob = (url: string) => blobHttp.get(url);

export const api = {
  getConfig,
  postLog,
  getAuth,
  postAuth,
  getUser,
  updateUser,
  getUserAttribute,
  postUserAttribute,
  getGatelessContext,
  getArticles,
  getArticleLinks,
  getHomeValue,
  getHomeValueHistory,
  getInsuranceEligibility,
  getRateAlert,
  getLoans,
  getLoan,
  getLoanFeatures,
  getLoanTasks,
  getLoanTask,
  getSignSsoLink,
  getTaskAffirmations,
  getVoaSsoLink,
  patchLoanTask,
  getPendingTasks,
  getHELOCLoans,
  getPersonalLoans,
  getSSOData,
  getScenarioPreapprovalLetter,
  getPreapprovals,
  orderVoaReport,
  postPreapprovalViewed,
  postV2LoanServicingSSO,
  postLoanInsurance,
  postLanguagePreference,
  postConsent,
  getV2Servicing,
  getHELOCServicing,
  getRegistrationStatus,
  postRegistrationRequest,
  postRegistrationComplete,
  getTokenStatus,
  postBorrowerVerifySSN,
  postBorrowerSetup,
  getDocuments,
  postDocument,
  postTaskDocument,
  getBlob,
  postVoieOrder,
  postVoaiOrder,
  postVoaOrder,
  reverifyAssets,
  getRealEstateAgent,
  postRealEstateAgent,
  getWebsocketToken,
  getHomeSearchSavedHomes,
  getHomeSearchSavedSearches,
};

export default api;
