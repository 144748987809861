import { withTransaction } from '@elastic/apm-rum-react';
import { MilestoneHeadline } from '../../components/loan/milestone/MilestoneHeadline';
import LoanMilestoneTracker from '../../components/loan/milestone/MilestoneTracker';
import { LoanNextSteps } from '../../components/loan/nextSteps/LoanNextSteps';
import { AlpMilestone, AlpLoanType, type Loan } from '../../features/loans/types';
import { MilestoneModals } from '../../components/loan/modals/milestone/MilestoneModals';
import { MODAL_MILESTONES } from '../../components/loan/modals/milestone/MilestoneModal';
import { Welcome } from '../../components/loan/modals/welcome/Welcome';
import { useDocumentTitleLoanPage } from '../../hooks/useDocumentTitle';
import { TITLES } from '../../components/routes/paths';
import { Eligibility } from '../../components/loan/modals/eligibility/Eligibility';
import { AlpHELOCMilestoneHeadline } from '../../components/loan/milestone/AlpHELOCMilestoneHeadline';
import { CESMilestoneHeadline } from '../../components/loan/milestone/CESMilestoneHeadline';
import { FAST_TRACK_SHOWN_KEY, FastTrackApprovedModal } from '../../components/loan/modals/fastTrack/FastTrackApprovedModal';
import { useAppSelector } from '../../hooks/hooks';
import { selectUserLoanAttributesByLoan } from '../../features/user/userSlice';

const EligibilityWelcomeMilestoneModals = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid, 'loan-milestone': loanMilestone, 'same-day-status': sameDayStatus, 'alp-loan-type': alpLoanType, 'fast-track-approval': fastTrackApproved } = loan;
  const hasMilestoneModals = MODAL_MILESTONES.includes(loanMilestone) && alpLoanType !== AlpLoanType.CES;
  const fastTrackShown = !!useAppSelector(state => selectUserLoanAttributesByLoan(state, loanGuid))?.[FAST_TRACK_SHOWN_KEY];
  const shouldShowFastTrackModal = !fastTrackShown && fastTrackApproved;

  if (hasMilestoneModals) {
    return <MilestoneModals loan={loan} />;
  }

  // "same day" is OWNING only
  if (sameDayStatus === 'not-eligible' || sameDayStatus === 'prequal' || sameDayStatus === 'eligible' || sameDayStatus === 'approved') {
    return <Eligibility loan={loan} />;
  }

  // "fast track" is RATE only
  if (shouldShowFastTrackModal) {
    return <FastTrackApprovedModal loan={loan} />;
  }

  return <Welcome loan={loan} />;
};

const Overview = ({ loan }: { loan: Loan }) => {
  const { 'loan-number': loanNumber, 'alp-loan-type': alpLoanType, 'alp-heloc-loan': alpHELOCLoan } = loan;
  useDocumentTitleLoanPage(TITLES.homeLoanOverview, loanNumber);
  const showNextSteps = alpHELOCLoan?.milestone !== AlpMilestone.DENIED;

  return (
    <>
      {!alpLoanType && (
        <>
          <EligibilityWelcomeMilestoneModals loan={loan} />
          <MilestoneHeadline loan={loan} />
          <LoanMilestoneTracker loan={loan} />
        </>
      )}
      {alpLoanType === AlpLoanType.HELOC && <AlpHELOCMilestoneHeadline loan={loan} />}
      {alpLoanType === AlpLoanType.CES && <CESMilestoneHeadline loan={loan} />}
      {showNextSteps && <LoanNextSteps loan={loan} />}
    </>
  );
};

export default withTransaction('LoanOverview', 'page-load')(Overview);
