import { useState } from 'react';
import { AlertProps, AlertType } from './IAlert';
import Icon from '../icon/Icon';
import { iIconName } from '../icon/IIcon';
import Button from '../button/Button';
import cn from 'classnames';
import './Alert.scss';

const iconTypes: Record<string, iIconName> = {
  success: 'check-tick-circle',
  info: 'information-circle',
  warning: 'warning-triangle',
  error: 'warning-triangle',
  updating: 'sync',
};

const themeColors: Record<AlertType, { background: string; icon: string; button: string }> = {
  success: {
    background: 'ok-10',
    icon: 'ok-100',
    button: 'ok-125',
  },
  info: {
    background: 'action-10',
    icon: 'action-100',
    button: 'tertiaryBtn-text',
  },
  warning: {
    background: 'warning-10',
    icon: 'warning-100',
    button: 'warning-125',
  },
  error: {
    background: 'critical-10',
    icon: 'critical-100',
    button: 'critical-125',
  },
  updating: {
    background: 'action-10',
    icon: 'action-100',
    button: 'tertiaryBtn-text',
  },
};

const Alert = (props: AlertProps) => {
  const { type, title, description, showClose = true, buttonProps, animateIcon, className, children, auxButton } = props;
  const [showAlert, setShowAlert] = useState(true);

  if (!showAlert) {
    return null;
  }

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setShowAlert(false);
  };

  return (
    <div
      className={cn(
        className,
        'alert',
        'text-sm',
        'text-body',
        'rounded-xl',
        'py-3',
        'px-3',
        'border-solid',
        'border',
        `border-${themeColors[type].icon}`,
        `bg-${themeColors[type].background}`,
      )}
    >
      <div className='alert__container'>
        <div className='alert__content w-full'>
          <div className='alert__icon h-full items-start'>
            <Icon
              name={iconTypes[type]}
              className={cn(`text-${themeColors[type].icon}`, 'relative', 'top-0.5', animateIcon && 'animate-spin')}
              size='1.25rem'
            />
          </div>
          <div className='w-full h-full flex flex-col lg:flex-row lg:items-start justify-between'>
            <div className='alert__text-group'>
              {title && <h3 className='body-small text-base bold mb-0-important'>{title}</h3>}
              <p className='body-small text-base'>{description}</p>
              {children}
            </div>
            {(buttonProps || showClose || auxButton) && (
              <div className='flex h-full gap-3 lg:ml-3 items-start'>
                {buttonProps && buttonProps.text && (
                  <Button
                    buttonType='plain'
                    className='font-bold hover:underline whitespace-nowrap'
                    color={themeColors[type].button}
                    title={buttonProps.title}
                    {...(buttonProps.to && { to: buttonProps.to })}
                    {...(buttonProps.href && { href: buttonProps.href, target: '_blank' })}
                    {...(buttonProps.iconName && { iconName: buttonProps.iconName })}
                    onClick={buttonProps.onClick || undefined}
                  >
                    {buttonProps.text}
                  </Button>
                )}
                {auxButton && auxButton}
              </div>
            )}
          </div>
          {showClose && (
            <Button
              buttonType='icon'
              text='close alert'
              iconName='close'
              className='alert__close inline-flex self-start ml-3 relative top-1'
              onClick={onClose}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;
