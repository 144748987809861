import { useEffect } from 'react';
import { log } from '../../../utils/logger';
import Alert from '../../ui/alert/Alert';
import { useAppSelector } from '../../../hooks/hooks';
import { selectors } from '../../../features/tasks';
import { useDispatch } from 'react-redux';
import { fetchOthersPendingTasks } from '../../../features/tasks/actions';

export interface PendingConsumerTasks {
  consumerName: string;
  pendingTasks: { taskKey: string; taskId: string }[];
}

export const PendingTasksAlertUI = ({ otherBorrowersTasks }: { otherBorrowersTasks: PendingConsumerTasks[] }) => {
  let borrowerNames = otherBorrowersTasks[otherBorrowersTasks.length - 1].consumerName;
  if (otherBorrowersTasks.length === 2) {
    borrowerNames = `${otherBorrowersTasks[0].consumerName} and ${borrowerNames}`;
  } else if (otherBorrowersTasks.length > 2) {
    borrowerNames = `${otherBorrowersTasks
      .slice(0, otherBorrowersTasks.length - 1)
      .map((pending: PendingConsumerTasks) => pending.consumerName)
      .join(', ')} and ${borrowerNames}`;
  }
  const pendingMessage =
    otherBorrowersTasks.length === 1
      ? `${borrowerNames} still has tasks to complete`
      : `${borrowerNames} still have tasks to complete`;

  return (
    <Alert
      type='warning'
      description={`You've completed all your tasks, but ${pendingMessage}.`}
      showClose={false}
      className='mb-6 mt-4'
    />
  );
};

const PendingTasksAlert = ({ loanGuid }: { loanGuid: string }) => {
  const pendingTasksByLoan = useAppSelector(state => selectors.getPendingTasks(state, loanGuid));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!pendingTasksByLoan) {
      dispatch(fetchOthersPendingTasks(loanGuid));
    }
  }, [dispatch, loanGuid, pendingTasksByLoan]);

  useEffect(() => {
    if (!!pendingTasksByLoan?.length) {
      log({
        level: 'info',
        message: `Showing alert indicating other borrowers have incomplete tasks ${JSON.stringify(pendingTasksByLoan)}`,
        loanGuid,
      });
    }
  }, [loanGuid, pendingTasksByLoan]);

  return !!pendingTasksByLoan?.length ? <PendingTasksAlertUI otherBorrowersTasks={pendingTasksByLoan} /> : <div className='h-6' />;
};

export default PendingTasksAlert;
