import { TasksState, TasksActionTypes, Task, AffirmationResponse } from './types';
import {
  SET_TASKS,
  SET_TASK,
  SET_HAS_TASK_ERROR,
  REMOVE_TASK,
  UPDATE_TASK,
  TASK_MODAL_TOGGLE,
  SET_TASKS_ARE_LOADING,
  UPDATE_TASK_COMPLETED,
  SET_OTHERS_PENDING_TASKS,
} from './actionTypes';
import timestamp from '../../utils/timestamp';

const initialState: TasksState = {
  byId: {},
  idsByLoan: {},
  metadataByLoan: {},
  othersPendingTasksByLoan: {},
  taskModalOpen: false,
};

const sortTaskAffirmationsResponse = (task: Task<any>): AffirmationResponse[] => {
  if (!task['affirmation-responses'] || task['affirmation-responses'].length < 2) {
    return task['affirmation-responses'];
  }
  const unsorted = [...task['affirmation-responses']];
  return unsorted.sort((resp1, resp2) => {
    return (resp2.displayOrder || 0) - (resp1.displayOrder || 0);
  });
};

const reducer = (state = initialState, action: TasksActionTypes): TasksState => {
  switch (action.type) {
    case SET_TASKS: {
      const { loanGuid, tasks } = action.payload;
      const timestampString = timestamp();
      const tasksState = tasks.reduce(
        (tasksMap, task) => {
          task['affirmation-responses'] = sortTaskAffirmationsResponse(task);
          const taskId = task['task-id'];
          const isHidden = task['task-meta']?.hidden;
          return {
            ...tasksMap,
            // Building an array of all task ids
            idsByLoan: {
              ...tasksMap.idsByLoan,
              ...(!isHidden && {
                [loanGuid]: [...tasksMap.idsByLoan[loanGuid], taskId],
              }),
            },
            // Object keyed by task ids. Will contain all tasks for all loans.
            byId: {
              ...tasksMap.byId,
              [taskId]: task,
            },
          };
        },
        {
          byId: state.byId,
          idsByLoan: { ...state.idsByLoan, [loanGuid]: [] },
          metadataByLoan: {
            ...state.metadataByLoan,
            [loanGuid]: {
              hasTasks: true,
              hasError: false,
              tasksLoading: false,
              lastUpdated: timestampString,
            },
          },
        },
      );

      return { ...state, ...tasksState };
    }

    case SET_TASK: {
      const { loanGuid, task } = action.payload;
      task['affirmation-responses'] = sortTaskAffirmationsResponse(task);
      const taskId = task['task-id'];
      const isHidden = task['task-meta']?.hidden;
      return {
        ...state,
        byId: {
          ...state.byId,
          [taskId]: task,
        },
        metadataByLoan: {
          ...state.metadataByLoan,
          [loanGuid]: {
            ...state.metadataByLoan[loanGuid],
            hasError: false,
          },
        },
        idsByLoan: {
          ...state.idsByLoan,
          ...(!isHidden &&
            !state.idsByLoan[loanGuid].includes(taskId) && {
              [loanGuid]: [...state.idsByLoan[loanGuid], taskId],
            }),
        },
      };
    }

    case REMOVE_TASK: {
      const { loanGuid, taskId } = action.payload;

      const taskIds = [...state.idsByLoan[loanGuid]];
      const index = taskIds.indexOf(taskId);
      if (index) {
        taskIds.splice(index, 1);
      }

      const byId = { ...state.byId };
      delete byId[taskId];

      return {
        ...state,
        byId,
        idsByLoan: {
          ...state.idsByLoan,
          [loanGuid]: taskIds,
        },
      };
    }

    case UPDATE_TASK: {
      const { taskId: updateTaskId, updates } = action.payload;
      const { taskDetails, taskMeta, customTaskMeta } = updates;
      const updateTaskObject = state.byId[updateTaskId];
      return {
        ...state,
        byId: {
          ...state.byId,
          [updateTaskId]: {
            ...updateTaskObject,
            ...(taskDetails &&
              taskDetails['task-completed'] !== null && {
                'task-completed': taskDetails['task-completed'],
              }),
            ...(taskMeta && {
              'task-meta': {
                ...updateTaskObject['task-meta'],
                ...taskMeta,
              },
            }),
            ...(customTaskMeta && {
              'custom-task-meta': {
                ...updateTaskObject['custom-task-meta'],
                ...customTaskMeta,
              },
            }),
          },
        },
      };
    }

    case SET_HAS_TASK_ERROR: {
      const { loanGuid, hasError } = action.payload;
      return {
        ...state,
        metadataByLoan: {
          ...state.metadataByLoan,
          [loanGuid]: {
            ...state.metadataByLoan[loanGuid],
            hasError: hasError,
          },
        },
      };
    }

    case SET_TASKS_ARE_LOADING: {
      const { loanGuid, tasksLoading } = action.payload;
      return {
        ...state,
        metadataByLoan: {
          ...state.metadataByLoan,
          [loanGuid]: {
            ...state.metadataByLoan[loanGuid],
            tasksLoading,
          },
        },
      };
    }

    case TASK_MODAL_TOGGLE: {
      const { taskModalOpen } = action.payload;
      return {
        ...state,
        taskModalOpen,
      };
    }

    case UPDATE_TASK_COMPLETED: {
      const { taskId, taskCompleted } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [taskId]: {
            ...state.byId[taskId],
            'task-completed': taskCompleted,
          },
        },
      };
    }

    case SET_OTHERS_PENDING_TASKS: {
      const { loanGuid, pendingTasks } = action.payload;
      return {
        ...state,
        othersPendingTasksByLoan: { ...state.othersPendingTasksByLoan, [loanGuid]: pendingTasks },
      };
    }

    default:
      return state;
  }
};

export default reducer;
