import {
  useRef,
  useState,
  KeyboardEvent,
  useEffect,
  ReactNode,
  HTMLAttributes,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
} from 'react';
import nextId from '../../../utils/nextId';
import { TextBadgeProps } from '../badge/ITextBadge';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import { iIconName } from '../icon/IIcon';
import './Dropdown.scss';
import cn from 'classnames';

type ButtonOrLink = HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> &
  AnchorHTMLAttributes<HTMLAnchorElement> &
  ButtonHTMLAttributes<HTMLButtonElement>;

export type DropdownItem = ButtonOrLink & {
  to?: string;
  text: string;
  value: string | undefined;
  dataQa?: string;
  badge?: TextBadgeProps | ReactNode;
  disabled?: boolean;
};

interface DropdownProps {
  id: string;
  className?: string;
  text: ReactNode;
  textBadge?: ReactNode;
  textDataQa?: string;
  iconName?: iIconName;
  iconSize?: string;
  iconAriaLabel?: string;
  items: DropdownItem[];
  mobile?: boolean;
  mobileTitle?: string;
  height?: string;
  offsetRight?: boolean;
  onChange?: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>, item: DropdownItem) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onDropdownShown?: (show: boolean) => void;
  formInput?: boolean;
  supportKeydown?: boolean;
  dropdownBorderColor?: string;
}

const Dropdown = (props: DropdownProps) => {
  const {
    id,
    className,
    text,
    textBadge,
    textDataQa,
    iconName,
    iconSize,
    iconAriaLabel,
    items,
    mobile = false,
    mobileTitle,
    height,
    offsetRight,
    onChange,
    onBlur,
    onFocus,
    onDropdownShown,
    formInput,
    supportKeydown = false,
    dropdownBorderColor = 'action-100',
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [dropdownWidth, setDropdownWidth] = useState<number>(224);
  const [dropdownRight, setDropdownRight] = useState<string>('initial');
  const selectButtonRef = useRef<HTMLButtonElement>(null);
  const dropdownUListRef = useRef<HTMLUListElement>(null);
  const [dropdownId] = useState(nextId('myaccount-dropdown-'));
  const [focusedElementIndex, setFocusedElementIndex] = useState<undefined | number>(undefined);
  const itemsRef = useRef<(HTMLLIElement | null)[]>([]);

  // Side effect to add .active to .dropdown-ul
  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => dropdownUListRef.current?.classList.add('active'), 0);
    }
    onDropdownShown?.(isExpanded);
  }, [isExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  // Dropdown list width should match button width but not go under CSS min width (224px)
  useEffect(() => {
    const element = selectButtonRef.current;
    if (element?.offsetWidth && element.offsetWidth > 224) {
      setDropdownWidth(element.offsetWidth);
    }
  }, [selectButtonRef.current?.offsetWidth, text]);

  // Adjust positioning of dropdown if offset & button width change occurs
  useEffect(() => {
    const buttonOffsetWidth = selectButtonRef.current?.offsetWidth || 0;
    if (offsetRight && buttonOffsetWidth <= dropdownWidth) {
      setDropdownRight(`${dropdownWidth - buttonOffsetWidth}px`);
    }
  }, [selectButtonRef.current?.offsetWidth, text, dropdownWidth, offsetRight]);

  useEffect(() => {
    const onWindowClick = () => {
      closeDropdown();
      setFocusedElementIndex(undefined);
    };

    window.addEventListener('click', onWindowClick);
    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, []);

  const handleClick = (event: React.MouseEvent) => {
    // a button click move focus in Form. prevent this to happen
    event.preventDefault();

    // force button to focus - for safari
    selectButtonRef.current?.focus();

    if (isExpanded) {
      closeDropdown();
    } else {
      setIsExpanded(true);
      dropdownUListRef.current?.focus();
    }

    // top button stop click event here
    event.stopPropagation();
  };

  const handleTransitionEnd = () => {
    // if transitioned in
    if (dropdownUListRef.current?.classList.contains('active')) {
      // remove overflow-hidden so focus outline doesn't get cut off
      dropdownUListRef.current?.classList.remove('overflow-hidden');
      // if already transitioned out, update isExpanded to false
    } else {
      setIsExpanded(false);
    }
  };

  const closeDropdown = () => {
    dropdownUListRef.current?.classList.add('overflow-hidden');
    dropdownUListRef.current?.classList.remove('active');
    setIsExpanded(false);
  };

  const getItemButtonId = (index: number) => {
    return `${dropdownId}-${index}`;
  };

  const handleDropdownButtonFocus = () => {
    setFocusedElementIndex(undefined);
    onFocus?.();
  };

  const handleItemFocus = (index: number) => {
    setFocusedElementIndex(index);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (isExpanded) {
      // if support keydown AND a alphabet key
      const key = event.key.toLowerCase();
      if (supportKeydown && key.length === 1 && key.charAt(0) >= 'a' && key.charAt(0) <= 'z') {
        const itemsContents = itemsRef.current.map(item => {
          return item?.textContent;
        })
        let index = 0;
        for (let i = 0; i < itemsContents.length; i++) {
          const item = itemsContents[i]?.toLowerCase();
          if (item?.charAt(0) === key) {
            index = i;
            break;
          }
        }
        const item = itemsRef.current[index];
        if (index !== 0 && dropdownUListRef.current && item) {
          dropdownUListRef.current.scrollTop = item.offsetTop;
          item.focus();
          setFocusedElementIndex(index);
        }
      } else if (event.code === 'Escape' || event.key === 'Escape') {
        event.stopPropagation();
        closeDropdown();
        (dropdownUListRef.current?.closest('.dropdown')?.firstElementChild as HTMLElement)?.focus();
      } else if (event.code === 'ArrowDown' || event.key === 'ArrowDown') {
        stopButtonEvent(event);
        handleDropdownItemArrowDown();
      } else if (event.code === 'ArrowUp' || event.key === 'ArrowUp') {
        stopButtonEvent(event);
        handleDropdownItemArrowUp();
      }
    } else {
      if (event.code === 'ArrowDown' || event.key === 'ArrowDown') {
        stopButtonEvent(event);
        handleDropdownButtonArrowDown();
      }
    }
  };

  const stopButtonEvent = (event: KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleDropdownButtonArrowDown = () => {
    setIsExpanded(true);
    setTimeout(() => {
      const element = document.getElementById(getItemButtonId(0)) as HTMLElement;
      if (element.tabIndex > -1) {
        element?.focus();
      }
    });
  };

  const handleDropdownItemArrowDown = () => {
    if (focusedElementIndex === undefined || focusedElementIndex === items.length - 1) {
      const element = document.getElementById(getItemButtonId(0)) as HTMLElement;
      if (element.tabIndex > -1) {
        element?.focus();
      }
    } else if (focusedElementIndex !== undefined && focusedElementIndex < items.length) {
      const element = document.getElementById(getItemButtonId(focusedElementIndex + 1)) as HTMLElement;
      if (element.tabIndex > -1) {
        element?.focus();
      }
    }
  };

  const handleDropdownItemArrowUp = () => {
    if (focusedElementIndex !== undefined && focusedElementIndex > 0) {
      const element = document.getElementById(getItemButtonId(focusedElementIndex - 1)) as HTMLElement;
      if (element.tabIndex > -1) {
        element?.focus();
      }
    } else if (focusedElementIndex === 0) {
      const element = document.getElementById(getItemButtonId(items.length - 1)) as HTMLElement;
      if (element.tabIndex > -1) {
        element?.focus();
      }
    }
  };

  const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>, item: DropdownItem): void => {
    if (item.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    item.onClick?.(event);
    onChange?.(event, item);
    closeDropdown();
  };

  return (
    <div className={cn('dropdown relative', className)} data-ui='dropdown' onKeyDown={handleKeyDown}>
      {mobile ? (
        <button
          className='w-full'
          ref={selectButtonRef}
          onClick={handleClick}
          id={`${id}-button`}
          aria-haspopup='true'
          aria-expanded={isExpanded ? 'true' : 'false'}
          aria-controls={`${id}-menu`}
          title={isExpanded ? 'Close menu' : 'Open menu'}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          <div
            className={cn(
              'flex items-center justify-between border border-solid border-1 rounded bg-input-10 px-3 py-1',
              { 'border-input-50': !isExpanded },
              { [`border-${dropdownBorderColor}`]: isExpanded },
              { 'form-input': !!formInput }
            )}
            {...(height && { style: { minHeight: height } })}
          >
            {text ?
              <div className='flex flex-col'>
                <p className='text-left text-xs'>{mobileTitle}</p>
                <p className='text-left'>
                  {text}
                  {textBadge}
                </p>
              </div>
            :
              <p className='text-left'>
                {mobileTitle}
              </p>
            }
            <div>
              {!isExpanded && <Icon name='chevron-down-small' size='0.875rem' />}
              {isExpanded && <Icon name='chevron-up-small' className='relative bottom-0.5' size='0.875rem' />}
            </div>
          </div>
        </button>
      ) : (
        <Button
          buttonType='plain'
          size='medium'
          onClick={handleClick}
          onFocus={handleDropdownButtonFocus}
          iconName={iconName}
          iconSize={iconSize}
          iconAriaLabel={iconAriaLabel}
          id={`${id}-button`}
          aria-haspopup='true'
          aria-expanded={isExpanded ? 'true' : 'false'}
          aria-controls={`${id}-menu`}
          title={isExpanded ? 'Close menu' : 'Open menu'}
          className={cn(`btn--${id} p-3 -mr-3`, { 'border-input-50': !isExpanded }, { 'border-action-100': isExpanded })}
          buttonRef={selectButtonRef}
        >
          <span data-qa={textDataQa}>{text}</span>
        </Button>
      )}
      {isExpanded && (
        <div className='absolute w-full z-10' aria-hidden='false' style={{ right: dropdownRight }}>
          <ul
            className={cn('dropdown-ul bg-white mt-1 border border-solid border-1 rounded overflow-y-auto', `border-${dropdownBorderColor}`)}
            style={{ width: `${dropdownWidth}px`, maxHeight: `300px` }}
            ref={dropdownUListRef}
            onTransitionEnd={handleTransitionEnd}
            id={`${id}-menu`}
            role='menu'
            aria-labelledby={`${id}-button`}
          >
            {items.map((item, index) => {
              return (
                <li
                  key={item.text}
                  value={item.text}
                  role='presentation'
                  id={`${id}-menu-item-${index}`}
                  ref={element => {
                    itemsRef.current[index] = element;
                  }}
                >
                  <Button
                    tabIndex={1}
                    role='menuitem'
                    id={getItemButtonId(index)}
                    title={item.text}
                    href={item.href}
                    to={item.to}
                    target={item.target}
                    className='body-medium hover:bg-input-10 text-left block no-underline p-4 hover:font-bold w-full rounded'
                    data-qa={item.dataQa}
                    disabled={item.disabled}
                    onClick={evt => handleItemClick(evt, item)}
                    onFocus={() => !item.disabled && handleItemFocus(index)}
                  >
                    {item.text}
                    {item.badge}
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
