import { useCurrentLo } from './useCurrentLo';
import { useHomeLoanTeam } from './useHomeLoanTeam';

// Returns an LO if being shown by another component
// Drives logic for showing LO licenses in footer
export const useShownLo = () => {
  const { routeMatch: homeLoanTeamRouteMatch, team: homeLoanTeam } = useHomeLoanTeam();
  const { routeMatch: currentLoRouteMatch, lo: currentLo } = useCurrentLo();
  if (homeLoanTeamRouteMatch) {
    return homeLoanTeam?.lo;
  } else if (currentLoRouteMatch) {
    return currentLo;
  }
  return undefined;
};
