import { useState } from 'react';
import Stopwatch from '../../../components/stopwatch/stopwatch';
import { CircularTracker, sizes } from '../../../components/ui/tracker/CircularTracker';
import { ucfirst } from '../../../utils/str';

const StopwatchComponent = () => {
  const [paused, setPaused] = useState<boolean>(false);
  return (
    <button onClick={() => setPaused(p => !p)}>
      <Stopwatch startMinute={0} paused={paused} />
    </button>
  );
}

const ProgressIndicators = () => {
  return (
    <>
      <div className='progress-indicators'>
        <div className='progress-container flex flex-wrap'>
          <div className='progress-tracker' style={{ marginRight: '100px' }}>
            <h2 className='header-medium mb-8'>Progress indicator</h2>
            <div>
              <ul className='mb-8'>
                {Object.values(sizes).map(size =>
                  [15, 40, 60, 80, 100].map(percentage => (
                    <li className='mb-4' key={`mock_${size}_${percentage}`}>
                      <div className='flex items-center'>
                        <CircularTracker size={size} value={percentage} />
                        <span className='ml-5'>
                          <span className='bold'>{ucfirst(size)}</span> {percentage}% complete
                        </span>
                      </div>
                    </li>
                  )),
                )}
              </ul>
              <Stopwatch startMinute={10} />
              <StopwatchComponent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressIndicators;
