import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { triggerView } from '../../features/analytics/adobeTarget';
import { RouteContext } from '../../features/RouteContext';

const TriggerView = () => {
  const location = useLocation();
  const { routeState } = useContext(RouteContext);
  const { name, subview } = routeState;
  const routeNameRef = useRef<string | undefined>(undefined);
  const delimiter = ':';

  let viewName = name;
  if (viewName && subview) {
    viewName += `${delimiter}${subview}`;
  }

  useEffect(() => {
    if (viewName !== routeNameRef.current) {
      routeNameRef.current = viewName;
      triggerView(viewName || null);
    }
  }, [location.pathname, viewName]);

  return null;
};

export default TriggerView;
