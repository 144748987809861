import { useCallback, useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { PATHS, TITLES } from '../../components/routes/paths';
import { SubNav } from '../../components/ui/subNav/SubNav';
import { id as tenantId } from '../../features/tenant/tenant';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Components from './components/Components';
import Elements from './elements/Elements';
import { scrollIntoViewWithOffset } from '../../utils/scrollIntoViewWithOffset';

import './StyleGuide.scss';

const StyleGuide = () => {
  const location = useLocation();
  const history = useHistory();
  const view = location.pathname.replace(`${PATHS.styleguide}/`, '');

  const handleSubmenuClick = useCallback((key: string) => history.push(`${PATHS.styleguide}/${key}`), [history]);

  useDocumentTitle(TITLES.styleguide);

  useEffect(() => {
    if (location.hash) {
      const el = document.getElementById(location.hash.replace('#', ''));
      scrollIntoViewWithOffset(el, 16);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (location.pathname === PATHS.styleguide) {
    return (
      <Redirect
        to={{
          pathname: `${PATHS.styleguide}/elements`,
        }}
      />
    );
  }

  return (
    <div className='styleguide w-full OneLinkNoTx'>
      <div>
        Product: <span className='bold'>myAccount</span>
      </div>
      <div className='flex mb-4'>
        <div className='mr-2'>
          Tenant: <strong>{tenantId}</strong>
        </div>
        <div>
          Theme: <strong>{themeId}</strong>
        </div>
      </div>
      <div className='mb-6'>
        Key <span className='text-ok-100 bold'>C</span> = Configuration note
      </div>
      {SubNav(view, [
        ['elements', 'Elements', handleSubmenuClick],
        ['components', 'Components', handleSubmenuClick],
      ])}
      {view === 'elements' && <Elements />}
      {view === 'components' && <Components />}
    </div>
  );
};

export default StyleGuide;
