import { updateGlobalLoading } from '../../../features/app/appSlice';
import { sendDocumentEventToShellApp } from '../../../features/mobileApp/shellAppEvents';
import { useAppDispatch } from '../../../hooks/hooks';
import { useMobileAppDocumentEvent } from '../../../hooks/useMobileAppDocumentEvent';
import Button from '../button/Button';
import Disclaimer from '../disclaimer/Disclaimer';
import { ModalDisclaimerContentProps } from './IModal';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

export const ModalDisclaimerContent = ({
  title,
  subtitle,
  disclaimer,
  disclaimerShowCurrentDate,
  dowloadDisclaimerLink,
  dowloadDisclaimerLinkTitle,
  showFooter = true,
  acceptText = 'Accept',
  onAccept,
  declineText = 'Decline',
  onDecline,
}: ModalDisclaimerContentProps) => {
  const mobileAppDocumentEvent = useMobileAppDocumentEvent();
  const dispatch = useAppDispatch();
  const downloadLink = mobileAppDocumentEvent ? undefined : dowloadDisclaimerLink;

  const handleDownloadDocument = async () => {
    if (mobileAppDocumentEvent && dowloadDisclaimerLink) {
      dispatch(updateGlobalLoading(true));
      await sendDocumentEventToShellApp('DOWNLOAD_DOCUMENT', dowloadDisclaimerLink, 'disclaimer.pdf', true);
      dispatch(updateGlobalLoading(false));
    }
  };

  return (
    <>
      <ModalHeader title={title} subtitle={subtitle} textAlignment='left' />
      <div className='mt-8'>
        <Disclaimer html={disclaimer} showCurrentDate={disclaimerShowCurrentDate} />
      </div>
      {showFooter && (
        <ModalFooter alignment="right">
          {dowloadDisclaimerLink && (
            <Button
              className='lg:mr-auto lg:flex mt-6 mx-auto lg:mt-0 lg:mx-0'
              href={downloadLink}
              target='_blank'
              buttonType='quaternary'
              text='Download PDF'
              title={dowloadDisclaimerLinkTitle}
              iconPlacement='left'
              iconName='interface-download'
              iconSize='1.0625rem'
              onClick={handleDownloadDocument}
            />
          )}
          {onDecline && (
            <Button onClick={() => onDecline()} buttonType='secondary' size='large' className='lg:mr-3' style={{ minWidth: '120px' }}>
              {declineText}
            </Button>
          )}
          {onAccept && (
            <Button onClick={() => onAccept()} buttonType='primary' size='large' className='mb-4 lg:mb-0 lg:ml-3' style={{ minWidth: '120px' }}>
              {acceptText}
            </Button>
          )}
        </ModalFooter>
      )}
    </>
  );
};

export default ModalDisclaimerContent;
