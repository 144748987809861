import cn from 'classnames';
import { useIsAuth } from '../../features/auth/authSlice';
import useError from '../../hooks/useError';
import useEmbed from '../../hooks/useEmbed';
import { ImagePreloader } from '../ui/image/ImagePreloader';
import myAccountLogo from '../../images/myaccount-logo.svg';
import FlyoutComponent from '../flyout/FlyoutComponent';
import TopMenu from '../navigation/TopMenu';
import themeConfig from '../../features/tenant/tenant';
import { useMultilingual } from '../../hooks/useMultilingual';
import LanguageButton from '../multilingual/languageButton/LanguageButton';
import HeaderLogo from './HeaderLogo';

const MobileHeader = ({ className }: { className: string }) => {
  const { mobileLogo } = themeConfig.content.header;
  const isAuthenticated = useIsAuth();
  const { showingError, isUnsupportedBrowser } = useError();
  const embed = useEmbed();
  const shouldShowLanguageButton = useMultilingual();

  return (
    <header className={cn(className, 'header justify-between')}>
      <div className='flex flex-row'>
        {(showingError || (!showingError && !isUnsupportedBrowser && isAuthenticated && !embed)) && (
          <div className={cn('h-10 flex flex-row')} style={{ marginRight: '6px' }}>
            {!isUnsupportedBrowser && isAuthenticated && (
              <>
                {/* The following images are used within the flyout component's child component */}
                <ImagePreloader>
                  <img src={myAccountLogo} alt='myAccount logo' />
                  {mobileLogo && <img src='/images/brand-logo-mobile.svg' alt='brand logo for mobile' />}
                </ImagePreloader>
                <FlyoutComponent
                  contentLabel='Navigation Menu'
                  flyoutComponent={closeFn => {
                    return <TopMenu closeFn={closeFn} />;
                  }}
                  orientation='left'
                >
                  <div className='hamburger'>
                    <div className='hamburger-inner'></div>
                  </div>
                </FlyoutComponent>
              </>
            )}
          </div>
        )}
        <HeaderLogo className='lg:mr-8' />
      </div>
      {shouldShowLanguageButton && <LanguageButton />}
    </header>
  );
};

export default MobileHeader;
