import type { User } from '../user/types';
import { version } from '../../utils/env';

declare global {
  interface Window {
    hj?: any;
  }
}

const hj = () => window.hj;

export const setIdentity = ({ email, guid }: User): void => {
  hj()?.('identify', email, { email, guid, version });
};

export const sendEvent = (eventName: string): void => {
  hj()?.('event', eventName);
};

export const setPage = (relativePath: string): void => {
  hj()?.('stateChange', relativePath);
};
