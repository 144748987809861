import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { sendEvent } from '../../../../features/analytics/sendEvent';
import { selectUserLoanAttributesByLoan, updateUserLoanAttribute } from '../../../../features/user/userSlice';
import type { Loan } from '../../../../features/loans/types';
import MilestoneModal, { MODAL_MILESTONES } from './MilestoneModal';
import { log } from '../../../../utils/logger';
import timestamp from '../../../../utils/timestamp';

export const MILESTONES_SHOWN_KEY = 'milestoneModalsShown';

export const MilestoneModals = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid, 'loan-milestone': loanMilestone } = loan;
  const dispatch = useAppDispatch();
  const showModal = MODAL_MILESTONES.includes(loanMilestone);
  const milestoneModalAttributes = useAppSelector(state => selectUserLoanAttributesByLoan(state, loanGuid))?.[MILESTONES_SHOWN_KEY];
  const alreadyShown = !!milestoneModalAttributes?.[loanMilestone];
  const shouldShow = showModal && !alreadyShown;

  const modalsOpened = useRef<Record<string, boolean>>({});
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (shouldShow && !open && !modalsOpened.current[loanMilestone]) {
      modalsOpened.current = { ...modalsOpened.current, [loanMilestone]: true };
      setOpen(true);
      dispatch(
        updateUserLoanAttribute({
          loanGuid,
          value: { [MILESTONES_SHOWN_KEY]: { ...milestoneModalAttributes, [loanMilestone]: timestamp() } },
        }),
      );
    }
  }, [dispatch, loanGuid, open, shouldShow, loanMilestone, milestoneModalAttributes]);

  const handleOpen = () => {
    sendEvent('milestoneModalShown', { loanGuid, loanMilestone });
    log({
      loanGuid,
      message: `Milestone modal shown ${JSON.stringify({
        loanMilestone,
      })}`,
    });
  };

  return <MilestoneModal loan={loan} open={open} onOpen={handleOpen} onRequestClose={() => setOpen(false)} />;
};
