import Icon from '../../../components/ui/icon/Icon';
import Tooltip from '../../../components/ui/tooltip/Tooltip';

const TooltipStyleguide = () => {
  return (
    <>
      <div className='tooltip-styleguide'>
        <h2 className='header-medium mb-8'>Tooltip</h2>
        <Tooltip
          place='bottom'
          html={true}
          tip='The <strong>accuracy range</strong> refers to the highest and lowest median prices in the homes zipcode, providing a better glimpse of the potential value.'
        >
          <Icon name='information-circle' size='2rem' />
        </Tooltip>
      </div>
    </>
  );
};

export default TooltipStyleguide;
