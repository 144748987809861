import { useRouteMatch } from 'react-router-dom';
import { selectLoan } from '../../../features/loans/loansSlice';
import { ILoanRouteParams } from '../../../interfaces/IRouteParams';
import Button from '../../ui/button/Button';
import Recommendations from '../../ui/recommendations/Recommendations';
import type { Loan } from '../../../features/loans/types';
import Modal from '../../ui/modal/Modal';
import { QuoteModalContentProps } from './IInsuranceQuote';
import Icon from '../../ui/icon/Icon';
import { useState } from 'react';
import Tel from '../../ui/tel/Tel';
import { sendEvent } from '../../../features/analytics/sendEvent';
import { homeLoanInProcessPaths } from '../../routes/paths';
import ModalFooter from '../../ui/modal/ModalFooter';
import {
  selectInsuranceQuoteMetadata,
  selectInsuranceQuoteSent,
  sendInsuranceQuoteRequest,
} from '../../../features/insurance/insuranceSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import ModalLoading from '../../ui/modal/ModalLoading';
import ConfirmationModalContent from '../../ui/modal/ConfirmationModalContent';
import ErrorModalContent from '../../ui/modal/ErrorModalContent';
import Link from '../../ui/link/Link';
import { isTasklistLoan } from '../../../features/loans/helpers';

const disallowedStates = new Set([
  'AK',
  'AL',
  'AR',
  'HI',
  'ID',
  'IA',
  'LA',
  'KS',
  'MI',
  'MS',
  'MT',
  'ND',
  'NE',
  'NM',
  'SD',
  'TN',
  'VT',
  'WV',
  'WY',
]);

const insurable = ({ property: { state } }: Loan): boolean => {
  return !!(state && !disallowedStates.has(state));
};

export const Checkmark = ({ title, text }: { title: string; text: string }) => (
  <div className='my-4'>
    <h3 className='flex items-center mb-2'>
      <Icon name='check-tick' className='text-ok mr-2' size='0.9375rem' />
      {title}
    </h3>
    <p>{text}</p>
  </div>
);

export const QuoteModalContent = (props: QuoteModalContentProps) => {
  const { onClose, onClick } = props;

  return (
    <>
      <h1 className='header-small mb-6'>
        Here's why we know you'll love working with <span className='lg:whitespace-nowrap'>Anywhere Insurance Agency</span>
      </h1>
      <Checkmark
        title='We do the leg work for you!'
        text='We work with multiple insurance providers and shop the marketplace for the best price!'
      />
      <Checkmark
        title='We make it quick and easy!'
        text='We understand the loan process and work with your loan team to ensure a hassle-free experience!'
      />
      <ModalFooter className='my-8'>
        <Button onClick={onClose} buttonType='secondary' size='large' className='lg:mr-3' style={{ minWidth: '200px' }}>
          Cancel
        </Button>
        <Button onClick={onClick} buttonType='primary' size='large' className='mb-4 lg:mb-0 lg:ml-3' style={{ minWidth: '200px' }}>
          Get a free quote
        </Button>
      </ModalFooter>
      <div className='bg-gray-10 mt-0 -m-6 p-8 sm:py-10 lg:-mx-12 lg:-mb-11 lg:px-12'>
        <p className='body-disclaimer mb-4'>
          By clicking "Get a free quote", I authorize and consent to Guaranteed Rate Affinity, LLC sharing the information I have
          provided to them, including "eligibility information," with their affiliate, Anywhere Insurance Agency for the purpose of
          Anywhere Insurance Agency using such information to market their products and services to me as described under the Fair
          Credit Reporting Act (15 U.S. Code § 1681(s-3), et seq.).
        </p>
        <p className='body-disclaimer'>
          Guaranteed Rate Affinity, LLC and Anywhere Insurance Agency are affiliates. Because of this relationship, this referral
          may provide Guaranteed Rate Affinity, LLC a financial or other benefit. You are not required to use Anywhere Insurance
          Agency as a condition for obtaining a mortgage loan through Guaranteed Rate Affinity, LLC There are frequently other
          settlement service providers available with similar services. You are free to shop around to determine that you are
          receiving the best services and the best rate for these services.
        </p>
      </div>
    </>
  );
};

const QuoteCTA = ({ loanGuid }: { loanGuid: string }) => {
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [showModal, setModalState] = useState(false);
  const { isFetching: quoteIsFetching, hasError: quoteFailed } = useAppSelector(state =>
    selectInsuranceQuoteMetadata(state, loanGuid),
  );
  const insuranceQuoteSent = useAppSelector(state => selectInsuranceQuoteSent(state, loanGuid));

  const buttonText = (
    <>
      <span className='lg:hidden'>Get a free quote</span>
      <span className='hidden lg:inline xl:hidden'>Free quote</span>
      <span className='hidden xl:inline'>Get a free quote</span>
    </>
  );

  const handleGetQuote = () => {
    if (disabled) return;
    setDisabled(true);
    dispatch(sendInsuranceQuoteRequest(loanGuid));
    sendEvent('insuranceQuoteRequested', { loanGuid });
  };

  return (
    <>
      <Button
        buttonType='secondary'
        title='Get a free insurance quote'
        className='w-full'
        onClick={() => {
          setModalState(true);
          sendEvent('insuranceModalViewed', { loanGuid });
        }}
        children={buttonText}
      />
      <Modal
        contentLabel='Insurance Quote'
        open={showModal}
        onClosed={() => setDisabled(false)}
        onRequestClose={() => setModalState(false)}
      >
        {quoteIsFetching ? (
          <ModalLoading title='Hang tight while we send your request.' />
        ) : quoteFailed ? (
          <ErrorModalContent
            onDone={() => setModalState(false)}
            title='There was an issue submitting your information.'
            textNode={
              <>
                We were unable to submit your information to Anywhere Insurance Agency. If you give us a call at{' '}
                <Tel tel='855-608-5782' label='(855) 608-5782' /> we will be happy to assist you.
              </>
            }
            location='your tasklist'
          />
        ) : insuranceQuoteSent ? (
          <ConfirmationModalContent
            title='Your quote is on the way!'
            textNode={
              <>
                <p className='mb-4 max-w-lg'>
                  Your information has been sent to Anywhere Insurance Agency, and an agent will reach out to you soon.
                </p>
                <p>
                  If you have any questions, please call <Tel tel='855-608-5782' label='(855) 608-5782' /> or{' '}
                  <Link href={`mailto:support@castleedge.com`} target='_blank' rel='noopener'>
                    send an email
                  </Link>
                  .
                </p>
              </>
            }
            cancelButtonText='Back to your task list'
            onCancel={() => setModalState(false)}
          />
        ) : (
          <QuoteModalContent onClose={() => setModalState(false)} onClick={handleGetQuote} />
        )}
      </Modal>
    </>
  );
};

export const GetQuoteRecommendation = ({ loanGuid }: { loanGuid: string }) => {
  return (
    <Recommendations className='mt-4' title='Need insurance?' cta={<QuoteCTA loanGuid={loanGuid} />}>
      Our affiliate, Anywhere Insurance Agency, offers insurance as well.
    </Recommendations>
  );
};

const WithRouteMatchCheck = () => {
  const routeMatch = useRouteMatch<ILoanRouteParams>({
    path: homeLoanInProcessPaths,
    exact: true,
  });
  const guid = routeMatch?.params.guid;
  const loan = useAppSelector(state => guid && selectLoan(state, guid));
  const shouldShow = loan && isTasklistLoan(loan);
  return shouldShow && insurable(loan) ? (
    <div className='mt-8'>
      <GetQuoteRecommendation loanGuid={loan['loan-guid']} />
    </div>
  ) : null;
};

export default WithRouteMatchCheck;
