import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';
import type { Loan } from '../../features/loans/types';
import { isTasklistLoan } from '../../features/loans/helpers';
import { linkTo, PATHS, TITLES } from '../../components/routes/paths';
import TasklistWithTasks from '../../components/tasks/tasklist/Tasklist';
import TasklistError from '../../components/tasks/tasklist/TasklistError';
import { TasklistLoading } from '../../components/tasks/tasklist/TasklistLoading';
import TasklistAlert from '../../components/tasks/tasklistAlert/TasklistAlert';
import { selectors as gatelessSelectors } from '../../features/gateless';
import { selectors as tasksSelectors } from '../../features/tasks';
import { useAppSelector } from '../../hooks/hooks';
import { useDocumentTitleLoanPage } from '../../hooks/useDocumentTitle';
import { useFetchTasks } from '../../hooks/useFetchTasks';
import { RootState } from '../../store/types';
import { log } from '../../utils/logger';

const TasklistView = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;
  const { hasTasksData, hasTasksError } = useFetchTasks(loanGuid, { refetch: true });
  const allTaskIds = useSelector((state: RootState) => tasksSelectors.getLoanTaskIds(state, loanGuid));
  const hasGatelessConfig = useAppSelector(state => gatelessSelectors.hasGatelessConfigByLoanGuid(state, loanGuid));

  useDocumentTitleLoanPage(TITLES.homeLoanTasks, loan['loan-number']);

  // TODO Decouple taskdata and Gateless config. Gateless config is only needed for Gateless provider component
  return !hasTasksData || !hasGatelessConfig ? (
    hasTasksError ? (
      <TasklistError />
    ) : (
      <TasklistLoading />
    )
  ) : hasTasksData && !allTaskIds.length ? (
    <TasklistAlert type='noTasks' />
  ) : (
    <TasklistWithTasks loan={loan} />
  );
};

const WithRedirect = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;

  // The intended current route is to show user tasklist. This checks criteria for showing the tasklist UI
  if (!isTasklistLoan(loan)) {
    log({ loanGuid, message: 'Loan does not meet tasklist criteria, redirecting back to loan url' });
    return (
      <Redirect
        to={{
          pathname: linkTo(PATHS.homeLoan, { guid: loanGuid }),
        }}
      />
    );
  }

  return <TasklistView loan={loan} />;
};

export default withTransaction('LoanTasklist', 'page-load')(WithRedirect);
