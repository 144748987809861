import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { addNavigationBlocker, removeNavigationBlocker } from '../../../features/app/appSlice';
import { NavigationBlocker } from '../../../features/app/types';
import { useAppDispatch } from '../../../hooks/hooks';
import nextId from '../../../utils/nextId';
import ConfirmationModalContent from '../../ui/modal/ConfirmationModalContent';
import Modal from '../../ui/modal/Modal';
import { log } from '../../../utils/logger';

interface Props {
  isDirty: boolean;
  onAbandon: () => void;
  setAccordionActive?: (active: boolean) => void;
  submitType: 'explanations' | 'affirmations';
}

const UnsubmittedModal = ({ isDirty, onAbandon, setAccordionActive, submitType }: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [blockerId] = useState(nextId('lox-unsubmitted-blocker-'));
  const [showModal, setShowModal] = useState(false);
  const isDirtyRef = useRef(false);
  const blockedNavigationUrlRef = useRef<string>();
  const abandonRef = useRef(false);

  const FormNavBlocker: NavigationBlocker = {
    id: blockerId,
    shouldBlockNavigation: () => {
      return isDirtyRef.current && !abandonRef.current;
    },
    shouldBlockUnload: () => {
      return isDirtyRef.current && !abandonRef.current;
    },
    onNavigationBlocked: (url: string) => {
      setShowModal(true);
      blockedNavigationUrlRef.current = url;
      log({ message: 'UnsubmittedModal is shown' });
    },
    onNavigationNativeBlocked: () => {
      setAccordionActive?.(true);
    },
  };

  useEffect(() => {
    dispatch(addNavigationBlocker(FormNavBlocker));
    return () => {
      dispatch(removeNavigationBlocker(FormNavBlocker));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isDirtyRef.current = isDirty;
    if (isDirty) {
      abandonRef.current = false;
    }
  }, [isDirty])

  const onClose = (abandon: boolean) => {
    setShowModal(false);
    onAbandon();
    abandonRef.current = abandon;
    if (abandon && blockedNavigationUrlRef?.current) {
      history.push(blockedNavigationUrlRef.current);
    } else {
      setAccordionActive?.(true);
    }
  };

  return (
    <Modal
      open={showModal}
      contentLabel='unsubmit data warning'
      onRequestClose={() => onClose(false)}
    >
      <ConfirmationModalContent
        title='You have unsubmitted changes'
        text={`There are changes that have not been submitted. Press the "submit ${submitType}" button when you have completed the task.`}
        confirmButtonText='Yes, continue'
        onConfirm={() => onClose(true)}
        cancelButtonText='No, cancel'
        onCancel={() => onClose(false)}
      />
    </Modal>
  );
};

export default UnsubmittedModal;
