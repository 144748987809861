import { useEffect, useState } from 'react';
import { useAuth } from '../../features/auth/authSlice';
import { io, Socket } from 'socket.io-client';
import { content } from '../../features/tenant/tenant';
import { log } from '../../utils/logger';
import { useDispatch } from 'react-redux';
import handleTaskEvent from './TaskEventHandler';
import api from '../../features/api';
import { AxiosResponse } from 'axios';
import { useAppSelector } from '../../hooks/hooks';

type SocketStatus = 'disconnected' | 'connected' | 'initializing' | 'connecting';

const EventsWebSocket = () => {
  const [status, setStatus] = useState<SocketStatus>('disconnected');
  const [socket, setSocket] = useState<Socket>();
  const [connectionAttempts, setConnectionAttempts] = useState(1);
  const auth = useAuth();
  const dispatch = useDispatch();
  const sessionId = useAppSelector(state => state.auth.sessionId);
  // MYA will attempt to connect to the socket for about a minute before giving up
  const MAX_CONNECTION_ATTEMPTS = 6;
  const RECONNECT_INTERVAL_SECONDS = 10;

  useEffect(() => {
    const fetchToken = async (): Promise<string> => {
      return await api
        .getWebsocketToken()
        .then((resp: AxiosResponse) => {
          return resp.data.token;
        })
        .catch(error =>
          log({ level: 'error', error: error.response?.data, message: 'Events Web Socket: Unable to fetch web socket token' }),
        );
    };

    if (status === 'disconnected' && socket && connectionAttempts <= MAX_CONNECTION_ATTEMPTS) {
      log({
        level: 'info',
        message: `Events Web Socket: Socket was disconnected; attempting to re-connect ${connectionAttempts}/${MAX_CONNECTION_ATTEMPTS}`,
        socketId: socket.id,
      });

      setTimeout(() => {
        setStatus('connecting');
        setConnectionAttempts(connectionAttempts + 1);
        // Refresh auth token and re-connect
        fetchToken().then((token: string) => {
          if (token) {
            socket.auth = { sessionId, userId: auth.userId, token };
            socket.connect();
          }
        });
      }, RECONNECT_INTERVAL_SECONDS * 1000);
    }

    if (!socket && auth?.isAuth && status !== 'initializing') {
      // Create a new socket for the user
      // One socket is created per user session in MyAccount
      // The socket will receive all events meant for the user ID
      // If multiple sessions or tabs are open for a user ID, all will receive the events emitted to that user
      setStatus('initializing');
      log({ level: 'info', message: `Events Web Socket: Creating socket for user ID ${auth.userId}` });
      fetchToken().then((token: string) => {
        if (token) {
          setSocket(
            io(content.eventsService.host, {
              reconnection: false,
              auth: { sessionId, userId: auth.userId, token },
            }),
          );
        }
      });
    }

    if (status === 'initializing' && socket) {
      // Add listeners for socket events
      socket.on('connect', () => {
        setStatus('connected');
        log({ level: 'info', message: `Events Web Socket: Socket connected for user ID ${auth.userId}`, socketId: socket.id });
      });

      socket.on('connect_error', error => {
        log({
          level: 'info',
          message: `Events Web Socket: ${error.message}`,
          socketId: socket.id,
        });
        setStatus('disconnected');
      });

      socket.on('disconnect', (reason, details) => {
        log({
          level: 'info',
          message: `Events Web Socket: Closed socket ${JSON.stringify({ reason, details })}`,
          socketId: socket.id,
        });
        setStatus('disconnected');
      });

      // Listen for task events
      // Other event categories and handlers can be added here as needed
      socket.on('task_event', arg => {
        log({
          level: 'info',
          message: `Events Web Socket: SQS message received from queue with receiptHandle ${arg.receiptHandle}`,
          socketId: socket.id,
        });
        handleTaskEvent(arg, dispatch, socket.id);
        socket.emit('acknowledge', { receiptHandle: arg?.receiptHandle });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, socket, auth, sessionId, connectionAttempts]);

  return null;
};

export default EventsWebSocket;
