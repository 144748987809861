import { sendEvent } from '../../../features/analytics/sendEvent';
import type { LoanOfficer } from '../../../features/loans/types';
import { log } from '../../../utils/logger';
import Avatar, { defaultMarginTop } from '../../ui/avatar/Avatar';
import ApplyLoanButton from '../../ui/button/ApplyLoanButton';
import { EmailButton } from '../../ui/button/EmailButton';
import { PhoneButton } from '../../ui/button/PhoneButton';
import Card from '../../ui/card/Card';

export const LoanOfficerCard = ({ loanOfficer, showApplyText = true }: { loanOfficer: LoanOfficer, showApplyText?: boolean }) => {
  const { 'role-name': roleName, 'nmls-id': nmlsId, 'photo-url': photo, name, phone, id, 'emp-id': empId, email, 'role-key': roleKey, title } = loanOfficer;
  const handleEmailEvent = () => sendEvent(`${roleKey}SupportEmailClick`, { email });
  const handlePhoneEvent = () => sendEvent(`${roleKey}SupportPhoneClick`, { phone });

  const onStartApplicationClick = () => {
    sendEvent('startAnApplication: loanOfficerCard');
    log({ level: 'info', message: `startAnApplication: loanOfficerCard lo: ${JSON.stringify({ name, email, id, empId })}` });
  };

  return (
    <Card className='bg-white p-0 md:p-6' dropShadow={false}>
      <div className='flex flex-col'>
        {showApplyText && (
          <p className='mb-3'>
            Let me find the perfect loan for your home purchase or refinance. &nbsp;
            <ApplyLoanButton empId={loanOfficer?.['emp-id']} onClick={onStartApplicationClick} />
          </p>
        )}
        <div className='grid grid-cols-12'>
          <div className='col-span-12 md:col-span-7'>
            <div className='flex md:grid md:grid-cols-7'>
              <div className='mr-6 md:mr-0 md:col-span-2'>
                <Avatar
                  photo={photo}
                  alt={`Photo of ${name}`}
                  data-qa='person-photo'
                  style={{ marginTop: defaultMarginTop }}
                />
              </div>
              <div className='flex flex-col h-full justify-evenly md:col-span-5'>
                <p className='font-bold OneLinkNoTx'>{name}</p>
                <p>{title || roleName}</p>
                <p>NMLS {nmlsId}</p>
              </div>
            </div>
          </div>
          <div className='col-span-12 mt-3 md:mt-0 md:col-span-5'>
            {email || phone ? (
              <div className='flex flex-col h-full justify-evenly'>
                <div className='h-0 md:h-5'></div>
                {email && (
                  <div>
                    <EmailButton buttonType='quaternary' size='small' showIcon={true} email={email} className='OneLinkNoTx min-h-6' data-qa='person-email' onClick={handleEmailEvent} />
                  </div>
                )}
                {phone && (
                  <div>
                    <PhoneButton buttonType='quaternary' size='small' showIcon={true} phone={phone} className='min-h-6' data-qa={`${roleKey}-phone`} onClick={handlePhoneEvent} />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default LoanOfficerCard;
