import { ReactNode } from 'react';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import { PhoneButton } from '../button/PhoneButton';
import { EmailButton } from '../button/EmailButton';

const ErrorModalContent = ({
  onDone,
  support,
  textNode,
  location,
  title,
  className,
}: {
  onDone: () => void;
  support?: {
    name: string;
    email: string;
    phone: string;
    phoneOption?: string;
  };
  textNode?: ReactNode;
  location: string;
  title: string;
  className?: string;
}) => {
  const titleNode = (
    <span className='flex justify-center flex-col lg:flex-row'>
      <Icon name='warning-triangle' className='mb-4 lg:mb-0 lg:mr-4 lg:mt-1.5 text-critical' size='1.25rem' />
      <span>{title}</span>
    </span>
  );

  return (
    <div className={className}>
      <ModalHeader title={titleNode} textAlignment='center' />
      {support && (
        <div className='text-center'>
          <p className='my-4'>Please try again later.</p>
          <p className='my-4 mx-auto' style={{ maxWidth: '440px' }}>
            If the issue persists, please contact <span className='OneLinkNoTx'>{support.name}</span> at{' '}
            {!!support.email && <EmailButton buttonType='inline' email={support.email} className='OneLinkNoTx' />}
            {!!support.email && !!support.phone && ' or '}
            {!!support.phone && <PhoneButton buttonType='inline' phone={support.phone} suffix={support.phoneOption} />} for
            assistance.
          </p>
        </div>
      )}
      {textNode && <div className='text-center'>{textNode}</div>}
      <ModalFooter>
        <Button onClick={onDone} buttonType='primary' size='large'>
          Back to {location}
        </Button>
      </ModalFooter>
    </div>
  );
};

export default ErrorModalContent;