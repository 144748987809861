import { useEffect, useRef } from 'react';
import { updateEmbedMode, selectGlobalEmbed, selectGlobalMobileApp, updateMobileAppMode } from '../../features/app/appSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { set } from '../../utils/sessionStore';
import { log } from '../../utils/logger';
import { embed } from '../../utils/browser';

export const useEmbedMode = () => {
  const { embedSearchParam, embedParamStored, isShellApp } = embed();
  const isEmbed = useRef(isShellApp || embedParamStored || embedSearchParam);

  return { embed: isEmbed.current, embedSearchParam, embedParamStored, isShellApp };
};

export const useMobileAppMode = () => {
  const { isShellApp } = embed();
  return isShellApp;
};

const EmbedMode = () => {
  const dispatch = useAppDispatch();
  const { embed, embedSearchParam } = useEmbedMode();
  const globalEmbed = useAppSelector(selectGlobalEmbed);
  const mobileApp = useMobileAppMode();
  const globalMobileApp = useAppSelector(selectGlobalMobileApp);

  useEffect(() => {
    embedSearchParam && set<boolean>('mya', 'embed', true);
  }, [embedSearchParam]);

  useEffect(() => {
    if (embed !== undefined && embed !== globalEmbed) {
      log({
        message: `EmbedMode: Setting embed mode to "${embed}"`,
      });
      dispatch(updateEmbedMode(embed));
    }
  }, [dispatch, embed, globalEmbed]);

  useEffect(() => {
    if (mobileApp !== undefined && mobileApp !== globalMobileApp) {
      log({
        message: `EmbedMode: Setting mobileApp mode to "${mobileApp}"`,
      });
      dispatch(updateMobileAppMode(mobileApp));
    }
  }, [dispatch, mobileApp, globalMobileApp]);

  return null;
};

export default EmbedMode;
