import { formatDate, FORMAT_LONG, FORMAT_SHORT } from '../../../utils/date';
import { TimeProps } from './ITime';

export const Time = ({ date, title, label, ...restProps }: TimeProps) => {
  const titleProp = title || formatDate(date, FORMAT_LONG);
  const labelProp = label || formatDate(date, FORMAT_SHORT);
  return (
    <time dateTime={date} title={titleProp} {...restProps} data-ui='time'>
      {labelProp}
    </time>
  );
};

export default Time;
