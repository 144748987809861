export const DEFAULT_LANGUAGE = 'en';

// ICU Locale
export const localeMap: Record<string, string> = {
  en: 'en_US',
  es: 'es_ES',
};

// update this map for any additional language we may add
export const isoMap: Record<string, string> = {
  en: 'English',
  es: 'Español',
};

// update this map for any additional language we may add
export const isoMapEnglish: Record<string, string> = {
  en: 'English',
  es: 'Spanish',
};

const languageMap = (inEnglish?: boolean) => {
  const codeMap = inEnglish ? isoMapEnglish : isoMap;
  return Object.keys(codeMap).reduce((map: Record<string, string>, current) => {
    map[codeMap[current]] = current;
    return map;
  }, {});
};

export const isoToName = (code: string, inEnglish?: boolean): string => {
  return inEnglish ? isoMapEnglish[code] || code : isoMap[code] || code;
};

export const nameToIso = (lang: string, inEnglish?: boolean): string => {
  return languageMap(inEnglish)[lang] || lang;
};
