import { useMemo } from 'react';
import { sendEvent } from '../../../features/analytics/sendEvent';
import { updateGlobalLoading } from '../../../features/app/appSlice';
import { sendDocumentEventToShellApp } from '../../../features/mobileApp/shellAppEvents';
import { postPreapprovalViewed } from '../../../features/preapprovals/preapprovalsSlice';
import { selectDocumentsPreviouslyLastViewed } from '../../../features/user/userSlice';
import { Preapproval } from '../../../features/preapprovals/types';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { useMobileAppDocumentEvent } from '../../../hooks/useMobileAppDocumentEvent';
import { usdAmount } from '../../../utils/amount';
import { formatDate, FORMAT_ABBREVIATED, FORMAT_SINGLE_DIGIT_SHORT_YEAR, formatDateWithoutTimezone, FORMAT_LONG_TIME } from '../../../utils/date';
import SortableTable, { sortableHeaderCell, sortableTableCell } from '../../sortableTable/SortableTable';
import DownloadButton from '../documents/DownloadButton';
import cn from 'classnames';
import { log } from '../../../utils/logger';
import TextBadge from '../../ui/badge/TextBadge';
import ConditionalWrapper from '../../ui/conditionalWrapper/ConditionalWrapper';
import dayjs from 'dayjs';
import timestampDifference from '../../../utils/timestampDifference';
import Tooltip from '../../ui/tooltip/Tooltip';
import Icon from '../../ui/icon/Icon';
import PowerbidImage from '../../../images/powerbid.svg';

const PreapprovalDownloadButton = (
  {
    loanGuid,
    preapproval,
    onClick
  }: {
    loanGuid: string;
    preapproval: Preapproval;
    onClick: (loanGuid: string, preapproval: Preapproval) => void
  }
) => {
  const { letter, href } = preapproval;

  const createdDate: Date = new Date(letter['created-at']);
  const downloadFilename = `preapproval-${formatDate(createdDate, FORMAT_SINGLE_DIGIT_SHORT_YEAR)}.pdf`;

  const onDownloadClick = () => {
    onClick(loanGuid, preapproval);
  };

  return (
    <DownloadButton
      href={href}
      filename={downloadFilename}
      onClick={onDownloadClick}
    />
  );
};

const PurchasePriceWidget = ({ loanGuid, preapproval }: { loanGuid: string; preapproval: Preapproval }) => {
  const { letter, letter: { data: letterData, metadata: letterMetaData } } = preapproval;
  const docsLastViewed = useAppSelector(state => selectDocumentsPreviouslyLastViewed(state, loanGuid));
  const added = letter['created-at'];
  const isNew = !docsLastViewed || docsLastViewed < added;
  const isPowerbid = letterMetaData['letter-type'].toLowerCase() === 'pb';
  const hasBadge = isNew || isPowerbid;
  return (
    <ConditionalWrapper
      condition={hasBadge}
      wrapper={children => {
        return (
          <div className='flex items-center'>
            {children}
            {isNew && <TextBadge className='hidden md:inline ml-1' type='action-secondary' text='New' />}
            {isPowerbid && <img className='hidden md:inline ml-1' src={PowerbidImage} alt={`VA Loan`} width='40' height='11' />}
          </div>
        );
      }}
    >
      <p>{usdAmount(letterData['purchase-price'], false)}</p>
    </ConditionalWrapper>
  )
};

const ExpirationDateWidget = ({ preapproval, format }: { preapproval: Preapproval; format: string }) => {
  const { letter: { data: letterData} } = preapproval;
  const expirationDate = letterData['expiration-date'];
  const formatter = (expirationDate && expirationDate.includes('T00:00Z')) ? formatDateWithoutTimezone : formatDate;
  const formattedDate = expirationDate ? formatter(expirationDate, format) : 'N/A';
  const formattedDateFull = expirationDate ? formatter(expirationDate, FORMAT_LONG_TIME) : undefined;
  const now = dayjs().toString();
  const dayDiff = expirationDate ? timestampDifference(formatter(expirationDate), now, 'day') : -1;
  const showTooltip = !!expirationDate && dayDiff <= 10;
  const tooltipText = dayDiff < 0 ? 'Expired' : dayDiff <= 0 ? 'Expires today' : `Expires in ${dayDiff + 1} days`;
  return (
    <ConditionalWrapper
      condition={showTooltip}
      wrapper={children => {
        return (
          <div className='flex items-center'>
            {children}
            <Tooltip
              className='ml-2 hidden md:inline'
              place='bottom'
              tip={tooltipText}
            >
              <Icon name='warning-triangle-filled' size='1rem' style={{ color: '#FFAA2B' }} />
            </Tooltip>
          </div>
        );
      }}
    >
      <p title={formattedDateFull}>{formattedDate}</p>
    </ConditionalWrapper>
  );
};

const tableHeaders = [
  // large
  sortableHeaderCell('Purchase price', true),
  // mobile
  sortableHeaderCell('Pur. price', true),
  // large
  sortableHeaderCell('Loan amount', true),
  // mobile
  sortableHeaderCell('Loan amt.', true),
  // large
  sortableHeaderCell('Expiration date', true),
  // mobile
  sortableHeaderCell('Expires', true),
  sortableHeaderCell(undefined),
];

const PreapprovalsTable = ({ loanGuid, preapprovals }: { loanGuid: string; preapprovals: Preapproval[] }) => {
  const dispatch = useAppDispatch();
  const mobileAppDocumentEvent = useMobileAppDocumentEvent();

  const tableData  = useMemo(() => {
    if (preapprovals && preapprovals.length > 0) {
      const data = preapprovals.map(preapproval => {
        const { letter } = preapproval;
        const { data: letterData } = letter;
        return {
          dataModel: preapproval,
          cells: [
            sortableTableCell({ data: letterData['purchase-price'], widget: <PurchasePriceWidget loanGuid={loanGuid} preapproval={preapproval}/> }),
            sortableTableCell({ data: letterData['purchase-price'], widget: <PurchasePriceWidget loanGuid={loanGuid} preapproval={preapproval}/> }),
            sortableTableCell({ data: letterData['total-loan-amount'], widget: usdAmount(letterData['total-loan-amount'], false) }),
            sortableTableCell({ data: letterData['total-loan-amount'], widget: usdAmount(letterData['total-loan-amount'], false) }),
            sortableTableCell({ data: letterData['expiration-date'], widget: <ExpirationDateWidget preapproval={preapproval} format={FORMAT_ABBREVIATED} /> }),
            sortableTableCell({ data: letterData['expiration-date'], widget: <ExpirationDateWidget preapproval={preapproval} format={FORMAT_SINGLE_DIGIT_SHORT_YEAR} /> }),
            sortableTableCell({ widget: <PreapprovalDownloadButton loanGuid={loanGuid} preapproval={preapproval} onClick={() => onDownloadClick(preapproval)} />, defaultClick: false }),
          ]
        };
      });
      return data;
    }
    return [];
  }, [preapprovals, loanGuid]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleOpenedPreapproval = (
    loanGuid: string,
    preapproval: Preapproval,
    action: 'view' | 'download',
  ) => {
    const { id: letterId, 'viewed?': viewed } = preapproval;

    sendEvent('preapprovalLetterView', { location: 'documents', loanGuid, letterId, action });
    log({ loanGuid, level: 'info', message: `${action} preapproval letter ID '${letterId}'` });

    if (!viewed) {
      dispatch(postPreapprovalViewed(loanGuid, letterId));
    }
  };

  const openPreapproval = async (preapproval: Preapproval) => {
    const { letter, href } = preapproval;
    handleOpenedPreapproval(loanGuid, preapproval, 'view');
    if (mobileAppDocumentEvent) {
      const createdDate: Date = new Date(letter['created-at']);
      const downloadFilename = `preapproval-${formatDate(createdDate, FORMAT_SINGLE_DIGIT_SHORT_YEAR)}.pdf`;
      dispatch(updateGlobalLoading(true));
      await sendDocumentEventToShellApp('VIEW_DOCUMENT', href, downloadFilename);
      dispatch(updateGlobalLoading(false));
    } else {
      window.open(href, '_blank', 'noopener');
    }
  };

  const onDownloadClick = (preapproval: Preapproval) => {
    handleOpenedPreapproval(loanGuid, preapproval, 'download');
  };

  const onRowClick = (preapproval: Preapproval) => {
    openPreapproval(preapproval);
  };

  const onRowKeypress = (preapproval: Preapproval, key: string) => {
    if (key && key !== 'Enter' && key !== 'Spacebar') return;
    openPreapproval(preapproval);
  };

  return (
    <SortableTable
      headers={tableHeaders}
      dataRows={tableData}
      maxRow={6}
      onRowClick={onRowClick}
      onRowKeypress={onRowKeypress}
      columnClassNames={[
        'hidden h-14 lg:table-cell lg:pl-5',
        'lg:hidden h-14',
        'hidden lg:table-cell h-14',
        'lg:hidden h-14',
        'hidden lg:table-cell h-14',
        'lg:hidden h-14',
        'w-10 h-14'
      ]}
      columnDataClassNames={[
        // purchase price
        'OneLinkNoTx',
        'OneLinkNoTx',
        // loan amount
        'OneLinkNoTx',
        'OneLinkNoTx',
        // exp date
        undefined,
        undefined,
        // download icon
        undefined,
      ]}
    />
  );
};

const PreapprovalsTables = ({ loanGuid, preapprovalsGroups }: { loanGuid: string; preapprovalsGroups: Record<string, Preapproval[]> }) => {
  const addresses = Object.keys(preapprovalsGroups);
  return (
    <>
      {addresses.map((address, index) => {
        const preapprovals = preapprovalsGroups[address];
        return (
          <div key={index} className={cn('flex flex-col', { 'mb-5': index < addresses.length - 1 })}>
            <p className='text-xl font-bold OneLinkNoTx'>{address}</p>
            <PreapprovalsTable loanGuid={loanGuid} preapprovals={preapprovals} />
          </div>
        );
      })}
    </>
  );
};

export default PreapprovalsTables;
