import { content } from '../../../features/tenant/tenant';

const HomeValueDisclaimer = () => {
  const companyName = content.legalName || content.company;

  return (
    <p className='mt-8 body-disclaimer'>
      The home valuation tool provides an estimated market value for your home using data collected from third parties and public
      records and is intended to provide you with a general value of the property. This is not an official appraised value nor is it
      the value that {companyName} will rely upon in making a determination of value or in making a lending decision.{` `}
      {companyName} will rely upon the value as provided by a licensed appraiser contracted by the company. The actual appraised
      value of any property may be higher or lower than the estimated market value provided by The home valuation tool. All
      estimations are for informational and educational purposes only, and in no way is any of the data contained herein to be
      construed as financial, investment or legal advice. {companyName} does not guarantee the quality, accuracy or completeness of
      the information provided. {companyName} its affiliates and subsidiaries do not assume any liability for the information
      contained herein, be it direct, indirect, consequential, special, or exemplary, or other damages whatsoever and howsoever
      caused, arising out of or reliance on the information, including any personal or pecuniary loss, whether the action is in
      contract, tort (including negligence) or other tortious action.
    </p>
  );
};

export default HomeValueDisclaimer;
