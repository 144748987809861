import Avatar, { defaultMarginTop } from '../../../components/ui/avatar/Avatar';

const AvatarStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Avatar</div>
      <div>
        <ul>
          <li className='mb-4'>
            <Avatar
              className='person-photo'
              style={{ marginTop: defaultMarginTop }}
              size='large'
              photo='https://d2by57b7z4b9hz.cloudfront.net/johnsample.jpg'
              alt='John Sample profile photo'
              data-qa='person-photo'
            />
          </li>
          <li className='mb-4'>
            <Avatar size='large' />
          </li>
          <li className='mb-4'>
            <Avatar
              className='person-photo'
              style={{ marginTop: defaultMarginTop }}
              size='small'
              photo='https://d2by57b7z4b9hz.cloudfront.net/johnsample.jpg'
              alt='John Sample profile photo'
              data-qa='person-photo'
            />
          </li>
          <li className='mb-4'>
            <Avatar size='small' />
          </li>
        </ul>
      </div>
    </>
  );
};

export default AvatarStyleguide;
