import { useState } from 'react';
import cn from 'classnames';
import { content } from '../../../features/tenant/tenant';
import { AvatarProps, AvatarPlaceholderProps, iAvatarSize } from './IAvatar';

const sizeClassNames: Record<iAvatarSize, string> = {
  small: 'photo-40',
  large: 'photo-72',
};

const sizes: Record<iAvatarSize, string> = {
  small: '40',
  large: '72',
};

export const defaultMarginTop = '0.75rem';

const Avatar = (props: AvatarProps) => {
  const { alt, className, size = 'large', style, 'data-qa': dataQa } = props;
  const [photo, setPhoto] = useState<string | undefined>(props.photo);

  const handleImageError = () => {
    setPhoto(undefined);
  };

  return (
    <div
      className={cn(
        'photo',
        'photo-rounded',
        sizeClassNames[size],
        {
          'photo--placeholder': !photo,
        },
        className,
      )}
      data-ui='avatar'
    >
      {photo ? (
        <img
          src={photo}
          style={style}
          width={sizes[size]}
          height={sizes[size]}
          alt={alt}
          data-qa={dataQa}
          onError={handleImageError}
        />
      ) : (
        <AvatarPlaceholder size={size} />
      )}
    </div>
  );
};

const AvatarPlaceholder = ({ size = 'large' }: AvatarPlaceholderProps) => (
  <img
    src='/images/brand-icon.svg'
    alt={`${content.company} Logo`}
    width={sizes[size]}
    height={sizes[size]}
    data-qa='person-photo-placeholder'
  />
);

export default Avatar;
