import { forwardRef, RefObject } from 'react';
import cn from 'classnames';
import { FormInputProps } from './IFormInput';
import { FormGroupElement } from './IFormGroup';

const FormInput = forwardRef<FormGroupElement, FormInputProps>((props, ref) => {
  const { type = 'text', hasError, className, id, onAnimationStart, onBlur, onChange, onFocus, disabled, removeTextAreaMarginBottom = false, value, ...rest } = props;

  if (type === 'textarea') {
    const textAreaRef = ref as RefObject<HTMLTextAreaElement>;
    return (
      <textarea
        {...(id && {
          id,
          'aria-describedby': `form-error-${id}`
        })}
        {...(ref && {
          ref: textAreaRef
        })}
        aria-invalid={hasError}
        className={cn('form-input form-textarea', className)}
        style={removeTextAreaMarginBottom ? { marginBottom: '-7px' } : {}}
        data-ui='form-textarea'
        onAnimationStart={onAnimationStart}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        value={value}
      />
    );
  } else {
    const inputRef = ref as RefObject<HTMLInputElement>;
    return (
      <input
        type={type}
        aria-invalid={hasError}
        {...(id && {
          id,
          'aria-describedby': `form-error-${id}`
        })}
        {...(ref && {
          ref: inputRef
        })}
        {...rest}
        className={cn('form-input', className)}
        data-ui='form-input'
        onAnimationStart={onAnimationStart}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        value={value}
      />
    );
  }
});

export default FormInput;
