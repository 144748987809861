import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';
import { log } from '../../utils/logger';
import { serializeError } from '../../utils/serializeError';
import axios from 'axios';
import { RootState } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useEffect } from 'react';

const namespace = 'homeSearch';

interface HomeSearchState {
  savedHomes: SavedHomesState;
  savedSearches: SavedSearchesState;
}

interface SavedHomesState {
  savedHomes: SavedHome[];
  hasData: boolean;
  hasInitialData: boolean;
  fetching: boolean;
  error: boolean;
}

interface SavedSearchesState {
  savedSearches: SavedSearch[];
  hasData: boolean;
  hasInitialData: boolean;
  fetching: boolean;
  error: boolean;
}

export interface SavedHome {
  listingPrice: number;
  propertyAddress: {
    streetAddress: string;
  };
  propertyImages: string[];
  propertyInfo: {
    numberOfBedrooms: number;
    fullBathCount: number;
    buildingAreaSqFt: string;
  },
  propertyUrl: string;
}

interface SavedSearch {
  savedSearchState: {
    minBath: string;
    minBed: string;
    searchNameIdentifier: string;
  }
}

const initialState = {
  savedHomes: {
    savedHomes: [] as SavedHome[],
    hasData: false,
    hasInitialData: false,
    fetching: false,
    error: false,
  },
  savedSearches: {
    savedSearches: [] as SavedSearch[],
    hasData: false,
    hasInitialData: false,
    fetching: false,
    error: false,
  }
};

export const useSavedHomes = (): SavedHomesState => {
  const dispatch = useAppDispatch();
  const savedHomesState = useAppSelector(selectSavedHomesState);
  const { savedHomes, hasData, hasInitialData, fetching, error } = savedHomesState;

  useEffect(() => {
    if (!hasInitialData && !fetching && !error) {
      dispatch(fetchSavedHomes());
    }
  }, [dispatch, hasInitialData, fetching, error]);

  return {
    savedHomes,
    hasData,
    hasInitialData,
    fetching,
    error
  };
};

export const useSavedSearches = (): SavedSearchesState => {
  const dispatch = useAppDispatch();
  const savedSearchState = useAppSelector(selectSavedSearchesState);
  const { savedSearches, hasData, hasInitialData, fetching, error } = savedSearchState;

  useEffect(() => {
    if (!hasInitialData && !fetching && !error) {
      dispatch(fetchSavedSearches());
    }
  }, [dispatch, hasInitialData, fetching, error]);

  return {
    savedSearches,
    hasData,
    hasInitialData,
    fetching,
    error
  };
};

export const fetchSavedHomes = createAsyncThunk(
  `${namespace}/fetchSavedHomes`,
  async () => {
    try {
      log({ level: 'info', message: 'Fetching saved homes' });
      const resp = await api.getHomeSearchSavedHomes();
      return resp.data;
    } catch (error) {
      log({ level: 'error', message: `Fetched saved homes error ${serializeError(error)}`, isAxiosError: axios.isAxiosError(error) });
      throw error;
    }
  },
);

export const fetchSavedSearches = createAsyncThunk(
  `${namespace}/fetchSavedSearches`,
  async () => {
    try {
      log({ level: 'info', message: 'Fetching saved homes searches' });
      const resp = await api.getHomeSearchSavedSearches();
      return resp.data;
    } catch (error) {
      log({ level: 'error', message: `Fetched saved homes searches error ${serializeError(error)}`, isAxiosError: axios.isAxiosError(error) });
      throw error;
    }
  },
);

export const homeSearchSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSavedHomes.pending, state => {
        state.savedHomes.fetching = true;
        state.savedHomes.error = false;
      })
      .addCase(fetchSavedHomes.fulfilled, (state, { payload }) => {
        state.savedHomes.fetching = false;
        state.savedHomes.hasData = true;
        state.savedHomes.hasInitialData = true;
        state.savedHomes.savedHomes = payload;
      })
      .addCase(fetchSavedHomes.rejected, state => {
        state.savedHomes.fetching = false;
        state.savedHomes.error = true;
      })
      .addCase(fetchSavedSearches.pending, state => {
        state.savedSearches.fetching = true;
        state.savedSearches.error = false;
      })
      .addCase(fetchSavedSearches.fulfilled, (state, { payload }) => {
        state.savedSearches.fetching = false;
        state.savedSearches.hasData = true;
        state.savedSearches.hasInitialData = true;
        state.savedSearches.savedSearches = payload;
      })
      .addCase(fetchSavedSearches.rejected, state => {
        state.savedSearches.fetching = false;
        state.savedSearches.error = true;
      })
  },
});

const selectHomeSearchState = (state: RootState): HomeSearchState => state.homeSearch;

export const selectSavedHomesState = (state: RootState) => selectHomeSearchState(state).savedHomes;

export const selectSavedSearchesState = (state: RootState) => selectHomeSearchState(state).savedSearches;

export default homeSearchSlice.reducer;
