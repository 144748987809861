import { useRouteMatch } from 'react-router-dom';
import { selectGlobalNotFound, useHasGlobalError } from '../features/app/appSlice';
import { isUnsupported } from '../utils/browser';
import { useAppSelector } from './hooks';
import useSearchParams from './useSearchParams';

interface iError {
  isNotFound: boolean;
  isGlobalError: boolean;
  isErrorPage: boolean;
  showingError: boolean;
  isUnsupportedBrowser: boolean;
}

const useError = (): iError => {
  const isGlobalError = !!useHasGlobalError();
  const errorSearchParam = useSearchParams().get('_t');
  // To show error page based on route match, must include error query param too
  const isErrorPage = !!useRouteMatch('/error') && !!errorSearchParam;
  const isNotFound = useAppSelector(selectGlobalNotFound);

  return {
    isNotFound,
    isGlobalError,
    isErrorPage,
    showingError: isGlobalError || isErrorPage,
    isUnsupportedBrowser: isUnsupported,
  };
};

export default useError;
