import { useContext, useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch, withRouter } from 'react-router-dom';
import { RouteContainerProps, NotFoundContainerProps, IRoute, IRoutesObject } from './IRoutes';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useMultilingual } from '../../hooks/useMultilingual';
import { selectNavigationBlockers, updateGlobalLoadingOnce } from '../../features/app/appSlice';
import { RouteContext } from '../../features/RouteContext';
import { useIsAuth } from '../../features/auth/authSlice';
import { NavigationBlocker } from '../../features/app/types';
import NotFound from '../../pages/notFound/NotFound';
import { MainLayout } from '../layout/Layout';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import RedirectExternal from '../redirect/RedirectExternal';
import { SpanishNavigationDisclaimerModal } from './modals/SpanishNavigationDisclaimerModal';

const RouteContainer = ({ route }: RouteContainerProps) => {
  const { routeType, hasComponentInit, ...restRoute } = route;
  const isAuthenticated = useIsAuth();
  const dispatch = useAppDispatch();
  const match = useRouteMatch({
    path: route.path as string | string[],
    exact: route.exact,
  });
  const [isCurrentRoute, setIsCurrentRoute] = useState(!!match);
  const multilingual = useMultilingual();
  const { setRouteState } = useContext(RouteContext);

  useEffect(() => {
    if (isCurrentRoute) {
      setRouteState?.({ name: route.name, subview: undefined });
    }
  }, [isCurrentRoute]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (route.notFound || (match && !hasComponentInit)) {
      // I don't love this but we need to delegate loading state to login component
      // when user is not logged in and will be routed to login page
      // TODO - !(A & B) = !A | !B
      if (!(!isAuthenticated && routeType === 'privateOnly')) {
        dispatch(updateGlobalLoadingOnce(false));
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsCurrentRoute(!!match);
  }, [match]);

  return (
    <>
      {isCurrentRoute && multilingual && <SpanishNavigationDisclaimerModal path={route.path as string | string[]} exact={route.exact} routeName={route.name} />}
      {routeType === 'privateOnly' ? (
        <PrivateRoute {...restRoute} />
      ) : routeType === 'publicOnly' ? (
        <PublicRoute {...restRoute} />
      ) : (
        <Route {...restRoute} />
      )}
    </>
  );
};

const NotFoundContainer = withRouter(({ children, location }: NotFoundContainerProps) => {
  return location?.state?.notFoundError ? (
    <MainLayout>
      <NotFound />
    </MainLayout>
  ) : (
    children
  );
});

const RedirectContainer = withRouter(({ children, location }: any) => {
  return location?.state?.redirectTo ? <RedirectExternal to={location.state.redirectTo} /> : children;
});

const Routes = ({ routes }: { routes: IRoute[] }) => {
  const history = useHistory();
  const navigationBlockers = useAppSelector(selectNavigationBlockers);
  const navigationBlockersRef = useRef([] as NavigationBlocker[]);

  useEffect(() => {
    navigationBlockersRef.current = navigationBlockers;
  }, [navigationBlockers]);

  useEffect(() => {
    const unblock = history.block(tx => {
      for (const blocker of navigationBlockersRef.current) {
        if (blocker.shouldBlockNavigation(tx.pathname)) {
          blocker.onNavigationBlocked(tx.pathname);
          return false;
        }
      }
    });

    const beforeUnloadListener = (event: BeforeUnloadEvent) => {
      for (const blocker of navigationBlockersRef.current) {
        if (blocker.shouldBlockUnload()) {
          blocker.onNavigationNativeBlocked();
          // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
          event.preventDefault();
          // this is not used. This bothers me so much
          event.returnValue = 'Are you sure?';
          return 'Are you sure?';
        }
      }
      return undefined;
    };

    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      unblock();
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const routesObject = routes.reduce(
    (acc: IRoutesObject, r: IRoute): IRoutesObject => {
      if (r.disabled) {
        return acc;
      }
      if (r.path && r.path !== '*') {
        return {
          ...acc,
          pageRoutes: [...acc.pageRoutes, r],
        };
      } else if (r.redirect) {
        return {
          ...acc,
          withRedirect: [...acc.withRedirect, r],
        };
      } else if (r.path === '*' || (!r.path && !r.redirect)) {
        return {
          ...acc,
          defaultRoute: r,
        };
      }
      return acc;
    },
    {
      pageRoutes: [],
      withRedirect: [],
      defaultRoute: undefined,
    },
  );

  return (
    <RedirectContainer>
      <NotFoundContainer>
        <MainLayout>
          <Switch>
            {routesObject.withRedirect.map((route, i) => {
              const { redirect } = route;
              return redirect && <Redirect key={i} {...redirect} />;
            })}
            {routesObject.pageRoutes.map((route, i) => {
              return <RouteContainer key={i} path={route.path} exact={route.exact} route={route} />;
            })}
            {routesObject.defaultRoute && <RouteContainer route={routesObject.defaultRoute} />}
          </Switch>
        </MainLayout>
      </NotFoundContainer>
    </RedirectContainer>
  );
};

export default Routes;
