import {
  useEffect,
  useRef,
  useCallback,
  MutableRefObject
} from 'react';

const useInterval = (cb: () => void, delay: number) => {
  const savedCallback = useRef(cb);
  const timerIdRef: { current: number } = useRef() as MutableRefObject<number>;

  const clear = useCallback(() => window.clearInterval(timerIdRef.current), []);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    timerIdRef.current = window.setInterval(savedCallback.current, delay);
    return clear;
  });

  return [clear];
};

export default useInterval;
