import { useState } from 'react';
import api from '../../../features/api';
import { selectAppLanguage } from '../../../features/app/appSlice';
import { content } from '../../../features/tenant/tenant';
import { useAppSelector } from '../../../hooks/hooks';
import { appIsDefaultLanguage, useMultilingual } from '../../../hooks/useMultilingual';
import { log } from '../../../utils/logger';
import { serializeError } from '../../../utils/serializeError';
import submitForm from '../../../utils/submitForm';
import { spanishDisclaimerModalSubject } from '../../routes/modals/SpanishNavigationDisclaimerModal';
import Button from '../../ui/button/Button';
import { EmailButton } from '../../ui/button/EmailButton';
import { PhoneButton } from '../../ui/button/PhoneButton';
import Icon from '../../ui/icon/Icon';

// TODO see if can combine with components/ssoButton/SSOButton
const SsoPaymentSubmitButton = (
  {
    text,
    loanGuid,
    servicerShortName,
    servicerLoanNumber,
    onClick,
    onSubmit,
    onError,
    showError = true,
  }: {
    text: string,
    loanGuid: string,
    servicerShortName: string,
    servicerLoanNumber: string,
    onClick?: () => void,
    onSubmit?: () => void,
    onError?: () => void,
    showError?: boolean,
  }
) => {
  const multilingual = useMultilingual();
  const appLanguage = useAppSelector(selectAppLanguage);
  const isDefaultLanguage = appIsDefaultLanguage(appLanguage);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleError = (errorTrace: string) => {
    log({ loanGuid, level: 'error', message: `Servicing SSO failed ${errorTrace}` });
    onError?.();
    if (showError) {
      setHasError(true);
    }
  };

  const confirmedSubmitForm = async () => {
    setLoading(true);
    setHasError(false);
    try {
      const { data } = await api.postV2LoanServicingSSO(loanGuid, { servicer: servicerShortName, 'servicer-loan-number': servicerLoanNumber });
      const { action, fields } = data;
      setLoading(false);
      if (action) {
        onSubmit?.();
        submitForm({
          action,
          fields,
          method: 'POST',
          newWindow: window.open(),
        });
      } else {
        handleError(JSON.stringify(data));
      }
    } catch (error) {
      setLoading(false);
      handleError(serializeError(error));
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    onClick?.();
    if (multilingual && !isDefaultLanguage) {
      event.preventDefault();
      spanishDisclaimerModalSubject.next({
        open: true,
        href: null,
        target: null,
        onConfirm: confirmedSubmitForm,
      });
      return;
    }
    confirmedSubmitForm();
  };

  return (
    <>
      {/* 'loading' does not work very well with tertiary Button with icon */}
      {loading ?
        <div className='flex items-center font-bold text-inactive-75'>
          <p>{text}</p>
          <Icon name='loading' className='h-fit-content justify-center animate-spin ml-2' size='1.125rem' />
        </div>
        :
        <div>
          <Button
            buttonType='tertiary'
            text={text}
            iconName='external-link'
            onClick={(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
              handleButtonClick(event);
            }}
          />
          {hasError && <p className='mt-1 form-button-error-text'>
            An error occurred. Contact&nbsp;
            <PhoneButton className='form-button-error-text' phone={content.servicing.phone} />&nbsp;
            or&nbsp;
            <EmailButton className='form-button-error-text OneLinkNoTx' email={content.servicing.email} />.
          </p>}
        </div>
      }
    </>
  );
};

export default SsoPaymentSubmitButton;
