import { useAppSelector } from './hooks';
import { selectConfigFeatures } from '../features/config/configSlice';
import { features as tenantFeatures } from '../features/tenant/tenant';
import { IFeatures } from '../features/config/types';
import { isLocalDev } from '../utils/env';
import { useMemo } from 'react';

const localFeatures = isLocalDev && process.env.REACT_APP_FEATURES ? JSON.parse(process.env.REACT_APP_FEATURES) : [];
const localFeaturesDisabled =
  isLocalDev && process.env.REACT_APP_FEATURES_DISABLE ? JSON.parse(process.env.REACT_APP_FEATURES_DISABLE) : [];

const getFeatures = (configFeatures: IFeatures[]) => {
  let features = configFeatures;
  if (isLocalDev) {
    const featuresSet = new Set([...configFeatures, ...tenantFeatures, ...localFeatures]);
    localFeaturesDisabled.forEach((disabledFeature: string) => {
      featuresSet.delete(disabledFeature);
    });
    features = [...featuresSet];
  } else {
    const featuresSet = new Set([...configFeatures, ...tenantFeatures]);
    features = [...featuresSet];
  }
  return features;
};

export const useFeatureEnabled = (feature: IFeatures) => {
  const configFeatures = useAppSelector(selectConfigFeatures);
  const features = useMemo(() => getFeatures(configFeatures), [configFeatures]);
  return !!features.includes(feature);
};

const frontendFeaturesOnly = getFeatures([]);

export const frontendFeatureEnabled = (feature: IFeatures) => !!frontendFeaturesOnly.includes(feature);

export default useFeatureEnabled;
