import { iHELOCLoan } from '../features/helocLoans/types';
import { Loan } from '../features/loans/types';
import { lowerCase } from './str';

export const getLoanTermString = (loan: Loan): string | undefined => {
  if (typeof loan['loan-term-months'] === 'number' && typeof loan['amortization-type'] === 'string') {
    return `${String(loan['loan-term-months'] / 12)}-year ${lowerCase(loan['amortization-type'])}`;
  }
  return undefined;
};

export const getHELOCLoanTermString = (helocLoan: iHELOCLoan): string | undefined => {
  if (helocLoan.loanDetails?.termType && typeof helocLoan.loanDetails.termType === 'string') {
    return `${helocLoan.loanDetails.term} ${lowerCase(helocLoan.loanDetails.termType)}`;
  }
  return undefined;
};
