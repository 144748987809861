import { GatelessState } from './types';
import { TasksState } from '../tasks/types';
import { createSelector } from '@reduxjs/toolkit';

/* istanbul ignore next */
interface State {
  gateless: GatelessState;
  tasks: TasksState;
}

export const getGatelessConfigByLoanGuid = (state: State, loanGuid: string) =>
  state.gateless.byLoanGuid[loanGuid]?.config;

export const hasGatelessConfigByLoanGuid = (state: State, loanGuid: string) => !!getGatelessConfigByLoanGuid(state, loanGuid);

export const selectGatelessConsumerToken = createSelector(getGatelessConfigByLoanGuid, (config) => {
  return config?.consumerToken;
});

export const selectGatelessVoaSsoLinkId = createSelector(getGatelessConfigByLoanGuid, (config) => {
  return config?.ssoLinkId;
});
