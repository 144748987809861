import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { isMobile } from '../../../utils/isMobile';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import './FileUpload.scss';
import cn from 'classnames';
import { TaskDocument } from '../../../features/tasks/types';
import { iIconName } from '../icon/IIcon';
import Modal from '../modal/Modal';
import ConfirmationModalContent from '../modal/ConfirmationModalContent';
import { useHistory } from 'react-router-dom';
import Alert from '../alert/Alert';
import { addNavigationBlocker, removeNavigationBlocker, removeNavigationBlockers } from '../../../features/app/appSlice';
import { useAppDispatch } from '../../../hooks/hooks';
import { NavigationBlocker } from '../../../features/app/types';
import nextId from '../../../utils/nextId';
import { log } from '../../../utils/logger';
import { scrollIntoViewWithOffset } from '../../../utils/scrollIntoViewWithOffset';
import { serializeError } from '../../../utils/serializeError';
import DownloadButton from '../../loan/documents/DownloadButton';
import FilenameWidget from './FilenameWidget';
import axios, { AxiosError } from 'axios';
import { RequestError } from '../../../interfaces/IRequest';

const sizes = ['small', 'large'] as const;
type iSize = typeof sizes[number];

const status = ['ready', 'error', 'processing', 'done', 'uploaded', 'unsupported', 'rejected', 'task-removed'] as const;
export type FileUploadStatus = (typeof status)[number];

const ACCEPT_FILE_TYPES = [
  '.key',
  '.pages',
  'image/*',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.apple.keynote',
  'application/vnd.apple.pages',
];

const UNSUPPORTED_FILE_TYPES = [
  '.svg',
  '.svgz',
  'image/svg+xml'
];

type FileUploadProps = {
  loanGuid: string;
  taskId?: string;
  className?: string;
  uploadedDocuments?: TaskDocument[];
  selectedFilesCallback?: (files: File[]) => void;
  submitFilesCallback?: (files: File[]) => void;
  doneProcessCallback?: (hasError: boolean) => void;
  processFile: (file: File) => Promise<any>;
  showTitle?: boolean;
  dropzoneSize?: iSize;
  parallelMax?: number;
  visible?: boolean;
  scrollIntoViewOnSelect?: boolean;
  // force active if this is in a accordion
  setAccordionActive?: (active: boolean) => void;
};

const isSupported = (file: File, loanGuid: string, taskId?: string) => {
  const filetype = file.type || file.name?.substring(file.name.lastIndexOf('.'));
  const support =
    filetype &&
    !UNSUPPORTED_FILE_TYPES.includes(filetype) &&
    (ACCEPT_FILE_TYPES.includes(filetype) || filetype.startsWith('image/'));
  if (!support) {
    log({ loanGuid, taskId, message: `File upload rejected file type ${file.type}` });
  }
  return support;
};

export const isErrorStatus = (fileStatus: FileUploadStatus) => {
  return ['error', 'unsupported', 'rejected', 'task-removed'].includes(fileStatus);
}

const UploadingCard = ({ size }: { size: iSize }) => {
  return (
    <div
      className='w-full border border-solid border-action-100 rounded-xl flex items-center justify-center'
      style={size === 'small' || isMobile() ? { height: '72px' } : { height: '288px' }}
    >
      <Icon name='loading' className='h-fit text-action-100 animate-spin mr-2' size='1.25rem' />
      <p>Uploading...</p>
    </div>
  );
};

interface DropzoneCardProps {
  size?: iSize;
  updateSelectedFiles: (files: File[]) => void;
  onDrop?: (event: React.DragEvent<HTMLElement>) => void;
  onDragOver?: (event: React.DragEvent<HTMLElement>) => void;
  handleBrowseFiles: () => void;
  handleCamera?: () => void;
};

const DesktopDropzoneCard = ({ size, onDrop, onDragOver, handleBrowseFiles }: DropzoneCardProps) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleDraggedOver = (isDraggedOver: boolean) => {
    setIsDraggedOver(isDraggedOver);
  };

  const handleDrop = (event: React.DragEvent<HTMLElement>) => {
    setIsDraggedOver(false);
    onDrop?.(event);
  };

  return (
    <div
      className={cn('cursor-pointer w-full border border-solid border-inactive-25 rounded-xl flex items-center justify-center hover:bg-action-10 hover:border-action-100', {
        'bg-action-10': isDraggedOver,
        'border-action-100': isDraggedOver,
      })}
      style={size === 'small' ? { height: '72px' } : { height: '288px' }}
      onDrop={handleDrop}
      onDragOver={onDragOver}
      onDragEnter={() => handleDraggedOver(true)}
      onDragLeave={() => handleDraggedOver(false)}
      onClick={handleBrowseFiles}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className='flex items-center'>
        <Icon name='upload' className='text-action-125 mr-2.5' size='1.15rem' />
        <span>Drag and drop&nbsp;</span>
        <Button
          hover={isHover}
          buttonType='tertiary'
          size='medium'
          text='or browse files'
          onDragLeave={(event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
          }}
        />
      </div>
    </div>
  );
};

const MobileDropzoneCard = ({ size, handleBrowseFiles, handleCamera }: DropzoneCardProps) => {
  return (
    <div
      className={cn('w-full border border-solid border-inactive-25 rounded-xl flex items-center justify-center')}
      style={size === 'small' ? { height: '72px' } : { height: '288px' }}
    >
      <div className='flex items-center pointer-events-none'>
        <Icon name='upload' className='text-action-125 mr-2.5' size='1.15rem' />
        <Button
          className='pointer-events-auto'
          onClick={handleBrowseFiles}
          buttonType='tertiary'
          size='medium'
          text='Browse files'
        />
        <span>&nbsp;or&nbsp;</span>
        <Button
          className='pointer-events-auto'
          onClick={handleCamera}
          buttonType='tertiary'
          size='medium'
          text='take a photo'
        />
      </div>
    </div>
  );
};

const DropzoneCard = ({ updateSelectedFiles, size }: { updateSelectedFiles: (files: File[]) => void, size: iSize }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const cameraInputRef = useRef<HTMLInputElement>(null);

  const handleDrop = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    const data = event.dataTransfer;
    const files = [...data.files];
    updateSelectedFiles(files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleFileSelectChange = (event: ChangeEvent) => {
    const data = event.target as any;
    const files = [...data.files];
    updateSelectedFiles(files);
  };

  const handleCameraChange = (event: ChangeEvent) => {
    const file = (event.target as any).files?.[0];
    if (file) {
      const extIndex = file.name?.lastIndexOf('.');
      let ext = '';
      if (file.name && extIndex >= 0) {
        ext = file.name.substring(extIndex);
      }
      const blob = file.slice(0, file.size, file.type);
      const image = new File([blob], `camera-${Date.now()}${ext}`, { type: file.type });
      updateSelectedFiles([image]);
    }
  };

  const handleBrowseFiles = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  const handleCamera = () => {
    cameraInputRef?.current?.click();
  };

  return (
    <>
      <input
        type='file'
        ref={fileInputRef}
        onChange={handleFileSelectChange}
        accept={ACCEPT_FILE_TYPES.join()}
        multiple hidden
      />
      <input
        type='file'
        ref={cameraInputRef}
        onChange={handleCameraChange}
        capture='environment'
        accept='image/*'
        hidden
      />
      {isMobile() ?
        <MobileDropzoneCard
          size={size}
          updateSelectedFiles={updateSelectedFiles}
          handleBrowseFiles={handleBrowseFiles}
          handleCamera={handleCamera}
        />
        :
        <DesktopDropzoneCard
          size={size}
          updateSelectedFiles={updateSelectedFiles}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          handleBrowseFiles={handleBrowseFiles}
        />
      }
    </>
  );
};

const FileIconButton = ({
  className,
  iconName,
  text,
  href,
  onClick,
}: {
  className?: string,
  iconName: iIconName,
  text: string,
  href?: string,
  onClick?: () => void,
}) => {
  return (
    <Button
      iconHoverEffect={true}
      style={{ flexShrink: 0 }}
      buttonType='icon'
      iconName={iconName}
      iconSize='1.25rem'
      text={text}
      href={href}
      onClick={() => onClick?.()}
      target={href ? '_blank' : undefined}
    />
  );
};

export const FileIconWidget = ({
  file,
  filename,
  fileStatus = 'ready',
  fileUrl,
  handleRetry,
  handleRemove
} : {
  file?: File,
  filename?: string,
  fileStatus: FileUploadStatus,
  fileUrl?: string,
  handleRetry?: (file?: File) => void,
  handleRemove?: (file?: File) => void
}) => {
  if (fileStatus === 'error') {
    return (
      <div className='flex'>
        <FileIconButton
          className='text-body-100'
          text='Retry file'
          iconName='retry'
          onClick={() => handleRetry?.(file)}
        />
        <FileIconButton
          className='text-body-100'
          text='Remove File'
          iconName='trash'
          onClick={() => handleRemove?.(file)}
        />
      </div>
    );
  } else if (isErrorStatus(fileStatus)) {
    return <FileIconButton
      className='text-body-100'
      text='Remove File'
      iconName='trash'
      onClick={() => handleRemove?.(file)}
    />;
  } else if (fileStatus === 'processing') {
    return (
      <Icon
        name='loading'
        className='justify-center mr-2.5 animate-spin'
        size='1.25rem'
      />
    );
  } else if (fileStatus === 'done') {
    return (
      <div className='flex'>
        {fileUrl && <FileIconButton
          className='text-body-100'
          text='Download'
          iconName='interface-download'
          href={fileUrl}
        />}
        <Icon name='check-tick' className='flex justify-center items-center w-10 h-10 text-ok-100' size='0.75rem' />
      </div>
    );
  } else if (fileStatus === 'uploaded') {
    if (!fileUrl || !filename) return null;
    return <DownloadButton
      href={fileUrl}
      filename={filename}
    />;
  } else {
    return <FileIconButton
      className='text-body-100'
      text='Remove File'
      iconName='trash'
      onClick={() => handleRemove?.(file)}
    />;
  }
};

const FileUpload = ({
  loanGuid,
  taskId,
  selectedFilesCallback,
  submitFilesCallback,
  doneProcessCallback,
  processFile,
  uploadedDocuments,
  dropzoneSize = 'large',
  showTitle = true,
  parallelMax = 5,
  className,
  visible = true,
  setAccordionActive,
  scrollIntoViewOnSelect = true,
}: FileUploadProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [selectedFiles, setSelectedFiles] = useState([] as File[]);
  const [filesStatuses, setFilesStatuses] = useState({} as { [key: string]: FileUploadStatus });
  const selectedFileNamesSet = useRef(new Set<string>()).current;
  const remainingFiles = useRef([] as File[]).current;
  const processingFiles = useRef(new Set<string>()).current;
  const filesServerUrl = useRef({} as { [key: string]: string | undefined }).current;
  const shouldBlockNavigationRef = useRef(false);
  const blockedNavigationUrl = useRef<string>();
  const [showUnfinishedUploadModal, setShowUnfinishedUploadModal] = useState(false);
  const [showUnfinishedUploadAlert, setShowUnfinishedUploadAlert] = useState(false);
  const [showTaskRemovedAlert, setShowTaskRemovedAlert] = useState(false);
  const [fileUploadId] = useState(nextId('fileupload-'));
  const [selectedFilesDivId] = useState(`${fileUploadId}-selectedFiles`);

  const updateSelectedFiles = (files: File[]) => {
    const updateFiles = [...selectedFiles];
    for (const file of files) {
      // only allow one file with the same name
      if (!selectedFileNamesSet.has(file.name)) {
        updateFiles.push(file);
        filesStatuses[file.name] = isSupported(file, loanGuid, taskId) ? 'ready' : 'unsupported';
        filesServerUrl[file.name] = undefined;
        selectedFileNamesSet.add(file.name);
      }
    }
    // sort by file name
    updateFiles.sort((f1, f2) => f1.name.localeCompare(f2.name));
    updateStateFilesStatuses();
    setSelectedFiles(updateFiles);
    selectedFilesCallback?.(updateFiles);

    // scroll to the selected files DIV
    setTimeout(() => {
      const selectedFilesSection = document.getElementById(fileUploadId);
      scrollIntoViewOnSelect && scrollIntoViewWithOffset(selectedFilesSection, 24);
    });
  };

  const handleRetry = (file?: File) => {
    if (!file) return;
    onFileRetry(file);
  };

  const handleRemoveFile = (file?: File) => {
    if (!file) return;
    const updateFiles = selectedFiles.filter(f => f.name !== file.name);
    selectedFileNamesSet.delete(file.name);
    delete filesStatuses[file.name];
    delete filesServerUrl[file.name];
    updateStateFilesStatuses();
    setSelectedFiles(updateFiles);
    selectedFilesCallback?.(updateFiles);
  };

  const removedInvalidFiles = (files: File[]) => {
    const updatedFiles = [];
    for (const file of files) {
      if (['unsupported', 'rejected', 'task-removed'].includes(filesStatuses[file.name])) {
        selectedFileNamesSet.delete(file.name);
        delete filesStatuses[file.name];
        delete filesServerUrl[file.name];
      } else {
        updatedFiles.push(file);
      }
    }
    updateStateFilesStatuses();
    setSelectedFiles(updatedFiles);
    selectedFilesCallback?.(updatedFiles);
  };

  const onFilesCancel = (files: File[]) => {
    selectedFileNamesSet.clear();
    // clear maps
    for (const file of files) {
      delete filesStatuses[file.name];
      delete filesServerUrl[file.name];
    }
    // update the three states
    updateStateFilesStatuses();
    setSelectedFiles([]);
    selectedFilesCallback?.([]);
  }

  const onFileRetry = (file: File) => {
    filesStatuses[file.name] = 'processing';
    updateStateFilesStatuses();
    remainingFiles.push(file);
    processNext();
    submitFilesCallback?.([file]);
  };

  const onFilesSubmit = (files: File[]) => {
    setShowUnfinishedUploadAlert(false);
    const eligibleFiles = [];
    for (const file of files) {
      // skip files that are either done, unsupported, or rejected
      if (!['done', 'unsupported', 'rejected'].includes(filesStatuses[file.name])) {
        filesStatuses[file.name] = 'processing';
        eligibleFiles.push(file);
      }
    }
    // update file statuses from the state's "filesStatuses"
    updateStateFilesStatuses();

    // remove unsupported or rejected files before submit
    removedInvalidFiles(files);

    // on submit callback
    submitFilesCallback?.(eligibleFiles);

    remainingFiles.push(...eligibleFiles);
    // start process in parallel for the max parallel count or the number of eligible files
    const parallelProcess = parallelMax < eligibleFiles.length ? parallelMax : eligibleFiles.length;
    for (let i = 0; i < parallelProcess; i++) {
      processNext();
    }
  };

  const processNext = async () => {
    if (remainingFiles.length === 0) {
      return;
    }

    const file = remainingFiles.pop() as File;
    const { size, type } = file;
    processingFiles.add(file.name);
    updateFileStatus(file.name, 'processing');

    try {
      log({ loanGuid, taskId, message: `Attempting to upload file ${file.name} ${type} ${size} bytes` });
      await processFile(file);
      log({ loanGuid, taskId, message: `Upload file success ${file.name} ${type} ${size} bytes` });
      updateFileStatus(file.name, 'done');
    } catch (error) {
      log({ loanGuid, taskId, level: 'error', message: `Upload file error ${file.name} ${type} ${size} bytes trace: ${serializeError(error)}` });
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<RequestError>;
        if (axiosError.response?.status === 403 && axiosError.response?.headers?.['content-type']?.includes('text/html')) {
          updateFileStatus(file.name, 'rejected');
        } else if (axiosError.response?.status === 410) {
          updateFileStatus(file.name, 'rejected');
          setShowTaskRemovedAlert(true);
          removedInvalidFiles([file]);
        } else {
          updateFileStatus(file.name, 'error');
        }
      } else {
        updateFileStatus(file.name, 'error');
      }
    } finally {
      processingFiles.delete(file.name);
      const isDone = remainingFiles.length === 0 && processingFiles.size === 0;
      if (isDone) {
        let hasError = false;
        for (const status of Object.values(filesStatuses)) {
          if (isErrorStatus(status)) {
            hasError = true;
            break;
          }
        }
        shouldBlockNavigationRef.current = hasError;
        doneProcessCallback?.(hasError);
      } else {
        processNext();
      }
    }
  };

  const updateStateFilesStatuses = () => {
    const newFilesStatuses = { ...filesStatuses };
    setFilesStatuses(newFilesStatuses);
  };

  const updateFileStatus = (filename: string, status: FileUploadStatus) => {
    filesStatuses[filename] = status;
    const newFilesStatuses = { ...filesStatuses };
    setFilesStatuses(newFilesStatuses);
  };

  const removeUploadedFiles = () => {
    const updatedSelectedFiles = [] as File[];
    selectedFiles.forEach(file => {
      if (filesStatuses[file.name] !== 'done') {
        updatedSelectedFiles.push(file);
      } else {
        delete filesStatuses[file.name];
        delete filesServerUrl[file.name];
        selectedFileNamesSet.delete(file.name);
      }
    });
    setSelectedFiles(updatedSelectedFiles);
  };

  const navigationBlocker: NavigationBlocker = {
    id: nextId('fileupload_'),
    shouldBlockNavigation: () => {
      return shouldBlockNavigationRef.current;
    },
    shouldBlockUnload: () => {
      return shouldBlockNavigationRef.current;
    },
    onNavigationBlocked: (url: string) => {
      blockedNavigationUrl.current = url;
      setShowUnfinishedUploadModal(true);
      setShowUnfinishedUploadAlert(true);
    },
    onNavigationNativeBlocked: () => {
      setAccordionActive?.(true);
      setShowUnfinishedUploadAlert(true);
    },
  };

  const handleUnfinishedUpload = (abandon: boolean) => {
    setShowUnfinishedUploadModal(false);
    if (abandon && blockedNavigationUrl.current) {
      // remove all nav blockers unless persist
      dispatch(removeNavigationBlockers());
      setTimeout(() => {
        if (blockedNavigationUrl.current) {
          history.push(blockedNavigationUrl.current);
        }
      });
    } else {
      setAccordionActive?.(true);
      // scroll to the selected files DIV - accordion needs 250 to open
      setTimeout(() => {
        const selectedFilesSection = document.getElementById(fileUploadId);
        scrollIntoViewOnSelect && scrollIntoViewWithOffset(selectedFilesSection, 24);
      }, 500);
    }
  };

  useEffect(() => {
    dispatch(addNavigationBlocker(navigationBlocker));
    return () => {
      dispatch(removeNavigationBlocker(navigationBlocker));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    shouldBlockNavigationRef.current = selectedFiles.length > 0;
    if (selectedFiles.length === 0) {
      setShowUnfinishedUploadAlert(false);
    }
  }, [selectedFiles.length]);

  useEffect(() => {
    removeUploadedFiles();
  }, [uploadedDocuments?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const isUploading = Object.values(filesStatuses).some((status: string) => status === 'processing');
  const hasInvalidFiles = Object.values(filesStatuses).some((status: string) => ['unsupported', 'rejected', 'task-removed'].includes(status));
  const hasOutstandingFiles = Object.values(filesStatuses).some((status: string) => status === 'error' || status === 'ready');

  return (
    <div className={cn('file-upload flex flex-col items-center', className)} id={fileUploadId}>
      {showTitle ?
        <>
          <h3 className='font-bold upload-text mb-4'>Upload documents</h3>
          <p className='mb-8 text-center'>
            Upload applicable documents that may be needed to verify your loan eligibility.
          </p>
        </> : null
      }
      <div className='w-full'>
        {!isUploading && <DropzoneCard updateSelectedFiles={updateSelectedFiles} size={dropzoneSize} />}
        {isUploading && <UploadingCard size={dropzoneSize} />}
      </div>
      {/* spacer */}
      {uploadedDocuments?.length || selectedFiles.length ? <div className='h-6'>&nbsp;</div> : null}
      {/* uploaded files */}
      {uploadedDocuments?.length ? <>
        <div className='flex flex-col w-full'>
          {uploadedDocuments.map((doc) => {
            return (
              <div className='flex justify-between items-center gap-2 h-12' key={doc.id}>
                <FilenameWidget filename={doc.title} fileUrl={doc.href} visible={visible} />
                <FileIconWidget
                  filename={doc.title}
                  fileUrl={doc.href}
                  fileStatus='uploaded'
                />
              </div>
            );
          })}
        </div>
      </> : null}
      {/* selected files */}
      {selectedFiles.length ? <>
        <div id={selectedFilesDivId} className='flex flex-col w-full'>
          {selectedFiles.map((file) => {
            return (
              <div className='flex justify-between items-center gap-2 h-12' key={file.name}>
                <FilenameWidget file={file} fileStatus={filesStatuses[file.name]} visible={visible} />
                <FileIconWidget
                  file={file}
                  fileStatus={filesStatuses[file.name]}
                  handleRemove={handleRemoveFile}
                  handleRetry={handleRetry}
                />
              </div>
            );
          })}
        </div>
        {/* show submit button if a any files are selected, enable the button if there are supported files */}
        {!isUploading && (hasOutstandingFiles || hasInvalidFiles) ? 
        <div className='flex flex-col items-center'>
          <Button
            className='mt-6 submit-button'
            buttonType='primary'
            size='medium'
            text='Submit documents'
            onClick={() => onFilesSubmit(selectedFiles)}
            disabled={!hasOutstandingFiles}
          />
          <Button 
            className='mt-4 cancel-button'
            buttonType='tertiary'
            size='medium'
            text='Cancel'
            onClick={() => onFilesCancel(selectedFiles)}
          />
        </div> : null}
      </> : null}
      {showUnfinishedUploadAlert ? <Alert className='w-full mt-6' type='warning' showClose={false} description='Submit documents to your loan team.' /> : null}
      {showTaskRemovedAlert ? <Alert className='w-full mt-6' type='error' showClose={false} description='This task has been removed. Please refresh the page.' /> : null}
      <Modal
        open={showUnfinishedUploadModal}
        contentLabel='documents upload warning'
        onRequestClose={() => setShowUnfinishedUploadModal(false)}
      >
        <ConfirmationModalContent
          title='Are you sure you want to abandon your selected files?'
          text='There are selected documents that have not been submitted. All unsubmitted documents will be removed at the end of your session.'
          confirmButtonText='Yes, continue'
          onConfirm={() => handleUnfinishedUpload(true)}
          cancelButtonText='No, cancel'
          onCancel={() => handleUnfinishedUpload(false)}
        />
      </Modal>
    </div>
  );
};

export default FileUpload;
