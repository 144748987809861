import cn from 'classnames';
import { FormLabelProps } from './IFormLabel';

const FormLabel = (props: FormLabelProps) => {
  const { htmlFor, className, children, ...rest } = props;
  return (
    <label
      htmlFor={htmlFor}
      {...rest}
      className={cn('form-label', className)}
      data-ui='form-label'
    >
      {children}
    </label>
  );
};

export default FormLabel;
