import ImageCarousel from '../../../components/ui/carousel/ImageCarousel';
import { DashboardArticlesPresentational } from '../../dashboard/DashboardArticles';
import DashbaordFinancialSolutions from '../../dashboard/DashboardFinancialSolutions';

const CarouselStyleguide = () => {
  const items = [
    {
      imageLink: 'https://dih4lvql8rjzt.cloudfront.net/cms/4d807aed-fef6-4bf6-9444-01f8d4e6c8e5_HomeownersInsurance1ResizedCOMP.jpg',
      url: 'what-is-homeowners-insurance',
      title: 'What is Homeowners Insurance?',
      subtitle: 'The complete guide',
    },
    {
      imageLink: 'https://dih4lvql8rjzt.cloudfront.net/cms/fc34fb7a-8d5c-403c-9640-a78073369426_MajorStepsinMortgageLoanProcessing.jpg',
      url: 'mortgage-loan-processing',
      title: 'The 6 Major Steps in Mortgage Loan Processing',
    },
    {
      imageLink: 'https://dih4lvql8rjzt.cloudfront.net/cms/05fbc4aa-9b9c-4cdd-9a34-01f7ed038f45_FirstTime1sizesocialcomp.jpg',
      url: 'first-home-buyers',
      title: 'First-Time Homebuyer’s Guide',
    },
    {
      imageLink: 'https://dih4lvql8rjzt.cloudfront.net/cms/cb97f60b-9912-4935-b37d-36ed6d5a38a1_GettyImages-1037487390.jpg',
      url: 'documents-you-will-need-to-apply-for-a-home-mortgage',
      title: 'Essential Documents Needed for a Mortgage Application',
    },
    {
      imageLink: 'https://dih4lvql8rjzt.cloudfront.net/cms/5b51106d-45df-4c0d-9c80-57ed0308ea1b_GettyImages-1294144618.jpg',
      url: 'best-ways-to-save-money',
      title: 'What Is the Best Way To Save for a House?',
    },
    {
      title: 'First Time Homebuyer eBook',
      subtitle: 'Our free ultimate guide to buying your first home',
      url: 'fthb-guide',
      imageLink: 'https://dih4lvql8rjzt.cloudfront.net/cms/e2099c5b-385f-4391-ac02-8835a91c144a_ebookherodesktop2x.png',
    },
    {
      title: 'Buying a House',
      url: 'buying-a-house',
      imageLink: 'https://dih4lvql8rjzt.cloudfront.net/cms/72f1012c-4705-4364-a2c2-4ac014465ced_PurchaseHero.png',
    }
  ];

  return (
    <>
      <div className='header-medium mb-8'>Carousel</div>
      <ImageCarousel items={items} />
      <div className='my-8' />
      <DashboardArticlesPresentational articles={items} />
      <div className='my-8' />
      <DashbaordFinancialSolutions />
    </>
  );
};

export default CarouselStyleguide;
