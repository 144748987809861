/* istanbul ignore file */
import { configureStore } from '@reduxjs/toolkit';
import { throttleThunk } from '../utils/throttleThunk';
import rootReducer from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['app/addNavigationBlocker', 'app/removeNavigationBlocker'],
      ignoreState: true,
    },
  }).prepend(throttleThunk),
});
