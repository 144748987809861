import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import { selectUserLoanAttributesByLoan, updateUserLoanAttribute } from '../features/user/userSlice';
import { log } from '../utils/logger';
import timestamp from '../utils/timestamp';

export const FIRST_VISIT_KEY = 'firstVisitAt';

export const useLoanFirstVisitAt = (loanGuid: string): string => {
  const dispatch = useAppDispatch();
  const firstVisitAt = useAppSelector(state => selectUserLoanAttributesByLoan(state, loanGuid))?.[FIRST_VISIT_KEY];
  const firstVisitTimestamp = useRef<string>(firstVisitAt || timestamp());

  useEffect(() => {
    if (!firstVisitAt) {
      dispatch(updateUserLoanAttribute({ loanGuid, value: { [FIRST_VISIT_KEY]: firstVisitTimestamp.current } }));
      log({
        loanGuid,
        message: `First time visit to loan tasklist. ${JSON.stringify({ [FIRST_VISIT_KEY]: firstVisitTimestamp.current })}`,
      });
    }
  }, [dispatch, loanGuid, firstVisitAt]);

  return firstVisitTimestamp.current;
};
