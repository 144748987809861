import { ComponentProps, useLayoutEffect } from 'react';
import { theme } from '../../features/tenant/tenant';
import { useAppSelector } from '../../hooks/hooks';
import { selectHasGlobalData } from '../../features/app/appSlice';

let hasPreloaded = false;

const preloadedLinks: string[] = [];

interface CreateLinkProps extends ComponentProps<'link'> { href: string; }

export const createLink = ({ rel, as, href, crossOrigin, type }: CreateLinkProps) => {
  if (preloadedLinks.includes(href)) return;

  preloadedLinks.push(href);
  const link = document.createElement('link');
  link.rel = rel || 'preload';
  link.href = href;
  if (as) link.as = as;
  if (type) link.type = type;
  if (crossOrigin) link.crossOrigin = 'crossorigin';
  document.getElementsByTagName('head')[0].appendChild(link);
};

const Preload = () => {
  const hasGlobalData = useAppSelector(selectHasGlobalData);

  useLayoutEffect(() => {
    if (hasPreloaded || !hasGlobalData) return;

    hasPreloaded = true;

    createLink({ as: 'image', href: '/images/brand-logo.svg' });
    if (theme.fonts) {
      for (const font of theme.fonts) {
        const [, fontType] = font.split('.');
        createLink({
          as: 'font',
          crossOrigin: 'crossorigin',
          href: `https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/${font}`,
          type: `font/${fontType}`,
        });
      }
    }
  }, [hasGlobalData]);

  return null;
};

export default Preload;
