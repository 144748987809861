import { useState } from 'react';
import { log } from '../utils/logger';
import useScript from './useScript';

const API_KEY = 'AIzaSyCVarbPudKoN48-Id5OSc2Wvcf1LHiu-HQ';

interface Props {
  libraries: string;
}

const loadedGoogleApis: string[] = [];

const useGoogleApi = ({ libraries }: Props) => {
  const src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=${libraries}&callback=onGoogleLibLoaded`;
  const [googleLibLoaded, setGoogleLibLoaded] = useState<boolean>(loadedGoogleApis.includes(src));

  (window as any).onGoogleLibLoaded = () => {
    log({ level: 'info', message: 'Google script loaded' });
    loadedGoogleApis.push(src);
    setGoogleLibLoaded(true);
  };

  const [loaded, hasError, error] = useScript({
    src,
    async: true,
  });

  const ready = loaded && googleLibLoaded;

  return [ready, hasError, error];
};

export default useGoogleApi;
