import RadioButton from './RadioButton';
import cn from 'classnames';

export interface Props {
  description: string;
  groupName: string;
  labels: string[];
  onChange?: (value: string) => void;
  className?: string;
  disabled?: boolean;
}

const RadioButtonsGroup = ({ description, groupName, labels, onChange, className, disabled }: Props) => {
  const handleRadioButtonClick = (clicked: string) => {
    onChange?.(clicked);
  };

  return (
    <div className={cn('flex flex-col md:flex-row md:items-center', className)}>
      <span className='mr-2'>{description}</span>
      <div className='flex flex-row'>
        {labels.map((label: string, index) => {
          return (
            <RadioButton
              id={`${groupName}-${label}`}
              value={label}
              name={groupName}
              onClick={() => handleRadioButtonClick(label)}
              defaultChecked={index === 0}
              label={label}
              key={label}
              className='mt-0'
              disabled={disabled}
            />
          );
        })}
      </div>
    </div>
  );
};

export default RadioButtonsGroup;
