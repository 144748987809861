import cn from 'classnames';
import { HorizontalStepComponentProps } from './IStep';
import {
  CompleteStepWidget,
  CurrentStepWidget,
  LeftCompleteStepWidget,
  LeftCurrentStepWidget,
  LeftUpcomingStepWidget,
  RightCurrentStepWidget,
  RightUpcomingStepWidget,
  UpcomingStepWidget,
} from './StepWidgets';

const HorizontalStepComponent = ({ width, stepList, currentStepId }: HorizontalStepComponentProps) => {
  const currentIndex = stepList.findIndex(step => step.id === currentStepId);
  return (
    <div className='w-full flex'>
      {stepList.map((step, index) => {
        return (
          <div key={step.id} className={cn('flex', { 'flex-grow': index !== stepList.length - 1 })}>
            <div className='flex flex-col items-center' style={{ 'width': width }}>
              {
                index === 0 ? (currentIndex === index ? <LeftCurrentStepWidget /> : currentIndex < 0 ? <LeftUpcomingStepWidget /> : <LeftCompleteStepWidget />) :
                index === stepList.length - 1 ? ( currentIndex === index ? <RightCurrentStepWidget /> : <RightUpcomingStepWidget />) :
                (
                  currentIndex === index ? <CurrentStepWidget /> :
                  currentIndex < index ? <UpcomingStepWidget /> :
                  <CompleteStepWidget />
                )
              }
              <p className={cn('text-center text-sm sm:text-base mt-1', { 'font-bold': currentIndex === index })}>
                {step.name}
              </p>
            </div>
            {index !== stepList.length - 1 &&
              <div className={cn('flex-grow border-t border-inactive-25', { 'border-ok-100': currentIndex > index })} style={{ marginTop: '15px' }} />
            }
          </div>
        );
      })}
    </div>
  );
};

export default HorizontalStepComponent;
