import ConfirmationModalContent from '../../ui/modal/ConfirmationModalContent';

const HomeValueEditModal = ({ onClose, onConfirm }: { onClose: () => void; onConfirm: () => void }) => {
  return (
    <ConfirmationModalContent
      title ='Are you sure you want to change your address?'
      text='Selecting another address will override your current home estimate and related home value history. Are you sure you want to continue?'
      confirmButtonText='Yes, please'
      onConfirm={onConfirm}
      cancelButtonText='No, cancel'
      onCancel={onClose}
    />
  );
};

export default HomeValueEditModal;
