import { QuoteModalContent } from '../../../components/loan/insuranceQuote/InsuranceQuote';
import { QuoteModalContent as CastleEdgeQuoteModalContent } from '../../../components/loan/insuranceQuote/CastleEdgeQuote';
import Modal from '../../../components/ui/modal/Modal';

const InsuranceModalStyleguide = () => {
  return (
    <ul>
      <li className='mb-4'>
        <Modal
          buttonProps={{
            buttonType: 'primary',
            text: 'Insurance Modal',
          }}
          contentLabel='Insurance Quote'
          render={() => {
            return (
              <QuoteModalContent
                onClose={() => {
                  console.log('Close clicked');
                }}
                onClick={() => {
                  console.log('Primary call to action clicked');
                }}
              />
            );
          }}
        />
      </li>
      <li className='mb-4'>
        <Modal
          buttonProps={{
            buttonType: 'primary',
            text: 'Insurance Modal (custom name)',
          }}
          contentLabel='Insurance Quote (custom name)'
          render={() => {
            return (
              <QuoteModalContent
                insuranceCompanyName='Foo Bar Insurance, LLC'
                onClose={() => {
                  console.log('Close clicked');
                }}
                onClick={() => {
                  console.log('Primary call to action clicked');
                }}
              />
            );
          }}
        />
      </li>
      <li className='mb-4'>
        <Modal
          buttonProps={{
            buttonType: 'primary',
            text: 'Castle Edge Insurance Modal',
          }}
          contentLabel='Castle Edge Insurance Quote'
          render={() => {
            return (
              <CastleEdgeQuoteModalContent
                onClose={() => {
                  console.log('Close clicked');
                }}
                onClick={() => {
                  console.log('Primary call to action clicked');
                }}
              />
            );
          }}
        />
      </li>
    </ul>
  )
};

export default InsuranceModalStyleguide;
