import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import type { AppThunk, RootState } from '../../store/types';
import { sendEvent } from '../analytics/sendEvent';
import api from '../api';
import { rejectValue } from '../requestHelper';
import { isFundedLoan, isLoanAusCompleted, isLoanReady, isLoanTasksReady, isPreapprovalsEnabled } from '../loans/helpers';
import { selectLoan } from '../loans/loansSlice';
import type { Loan } from '../loans/types';
import { selectDocumentsLastViewed } from '../user/userSlice';
import {
  Entities,
  Preapproval,
  PreapprovalEligibility,
  PreapprovalsEntity,
  PreapprovalsState,
  ScenarioPreapprovalEntity,
  ScenarioPreapprovalResponse,
} from './types';
import { log } from '../../utils/logger';
import { setDataLayer } from '../analytics/setDataLayer';
import { RequestError, RequestRejectValue } from '../../interfaces/IRequest';
import { AxiosError } from 'axios';

const logFetchedPreapprovals = (loanGuid: string, preapprovals: Preapproval[]) => {
  const preapprovalsIdArray = preapprovals.map(preapproval => preapproval.id);
  sendEvent('preapprovalsLetterFetched', { loanGuid, preapprovals: preapprovalsIdArray });
  log({ loanGuid, level: 'info', message: `Fetched preapprovals ${preapprovalsIdArray.join(',')}` });
};

export const fetchPreapprovals = createAsyncThunk<Preapproval[], string, { rejectValue: RequestRejectValue }>(
  'preapprovals/fetchPreapprovals',
  async (loanGuid, { rejectWithValue }) => {
    try {
      const response = await api.getPreapprovals(loanGuid);
      setDataLayer('preapprovals', { [loanGuid]: response.data });
      logFetchedPreapprovals(loanGuid, response.data);
      return response.data;
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      if (!error.response) {
        throw err
      }
      return rejectWithValue(rejectValue(error));
    }
  },
  {
    condition: (loanGuid: string, { getState }) => {
      const fetching = selectPreapprovalsByLoanGuid(getState() as RootState, loanGuid).loadingStatus === 'pending';
      return !fetching;
    },
  },
);

export const fetchScenarioPreapprovalLetter = createAsyncThunk<
  ScenarioPreapprovalResponse<true | false>,
  { loanGuid: string; level: string }
>(
  'preapprovals/fetchScenarioPreapprovalLetter',
  async ({ loanGuid, level }) => {
    const response = await api.getScenarioPreapprovalLetter(loanGuid, level);
    if (response.data?.['has-approval']) {
      sendEvent('preapprovalLetterGenerated', {loanGuid, level, palGuid: response.data.id});
    }
    return response.data;
  },
  {
    condition: ({ loanGuid }, { getState }) => {
      const fetching = selectEntitiesByLoanGuid(getState() as RootState, loanGuid).scenarioPreapproval.loadingStatus === 'pending';
      return !fetching;
    },
  },
);

export const postPreapprovalViewed =
  (loanGuid: string, letterId: string): AppThunk =>
  async dispatch => {
    dispatch(preapprovalViewed({ loanGuid, letterId }));
    await api.postPreapprovalViewed(loanGuid, letterId);
  };

export const initialStateEntities: Entities = {
  preapprovals: {
    loadingStatus: 'idle',
    isFetching: false,
    hasData: false,
    hasError: false,
    data: [],
  },
  // preapproval letter and scenerio data
  scenarioPreapproval: {
    loadingStatus: 'idle',
    isFetching: false,
    hasData: false,
    hasError: false,
    data: null,
  },
};

const initialState: PreapprovalsState = {
  byLoanGuid: {},
};

export const preapprovalsSlice = createSlice({
  name: 'preapprovals',
  initialState,
  reducers: {
    preapprovalViewed: (state, { payload: { loanGuid, letterId } }) => {
      if (state.byLoanGuid[loanGuid]?.preapprovals) {
        state.byLoanGuid[loanGuid].preapprovals.data = state.byLoanGuid[loanGuid].preapprovals.data.map(
          (preapproval: Preapproval) => (preapproval.id === letterId ? { ...preapproval, 'viewed?': true } : preapproval),
        );
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPreapprovals.pending, (state, { meta: { arg: loanGuid } }) => {
        state.byLoanGuid[loanGuid] = {
          ...initialStateEntities,
          ...state.byLoanGuid[loanGuid],
        };

        state.byLoanGuid[loanGuid].preapprovals = {
          ...initialStateEntities.preapprovals,
          ...state.byLoanGuid[loanGuid].preapprovals,
          loadingStatus: 'pending',
          isFetching: true,
          hasError: false,
        };
      })
      .addCase(fetchPreapprovals.fulfilled, (state, { meta: { arg: loanGuid }, payload }) => {
        state.byLoanGuid[loanGuid].preapprovals.loadingStatus = 'success';
        state.byLoanGuid[loanGuid].preapprovals.isFetching = false;
        state.byLoanGuid[loanGuid].preapprovals.hasData = true;
        state.byLoanGuid[loanGuid].preapprovals.data = payload;
      })
      .addCase(fetchPreapprovals.rejected, (state, { meta: { arg: loanGuid }, error }) => {
        state.byLoanGuid[loanGuid].preapprovals.loadingStatus = 'failed';
        state.byLoanGuid[loanGuid].preapprovals.isFetching = false;
        state.byLoanGuid[loanGuid].preapprovals.hasError = true;
        state.byLoanGuid[loanGuid].preapprovals.error = error;
      })
      .addCase(fetchScenarioPreapprovalLetter.pending, (state, { meta: { arg: {loanGuid} } }) => {
        state.byLoanGuid[loanGuid] = {
          ...initialStateEntities,
          ...state.byLoanGuid[loanGuid],
        };

        state.byLoanGuid[loanGuid].scenarioPreapproval = {
          ...initialStateEntities.scenarioPreapproval,
          ...state.byLoanGuid[loanGuid].scenarioPreapproval,
          loadingStatus: 'pending',
          isFetching: true,
          hasError: false,
        };
      })
      .addCase(fetchScenarioPreapprovalLetter.fulfilled, (state, { meta: { arg: {loanGuid} }, payload }) => {
        state.byLoanGuid[loanGuid].scenarioPreapproval.loadingStatus = 'success';
        state.byLoanGuid[loanGuid].scenarioPreapproval.isFetching = false;
        state.byLoanGuid[loanGuid].scenarioPreapproval.hasData = true;
        state.byLoanGuid[loanGuid].scenarioPreapproval.data = {
          previouslyCreated: !!payload['previously-created'],
          hasApproval: !!payload['has-approval'],
          id: payload.id,
          amount: payload['total-loan-amount'],
          href: payload.href,
        };
      })
      .addCase(fetchScenarioPreapprovalLetter.rejected, (state, { meta: { arg: {loanGuid} }, error }) => {
        state.byLoanGuid[loanGuid].scenarioPreapproval.loadingStatus = 'failed';
        state.byLoanGuid[loanGuid].scenarioPreapproval.isFetching = false;
        state.byLoanGuid[loanGuid].scenarioPreapproval.hasError = true;
        state.byLoanGuid[loanGuid].scenarioPreapproval.error = error;
      });
  },
});

export const { preapprovalViewed } = preapprovalsSlice.actions;

export const selectPreapprovalsState = (state: RootState) => state.preapprovals;

export const selectEntitiesByLoanGuid = (state: RootState, loanGuid: string) =>
  selectPreapprovalsState(state).byLoanGuid[loanGuid] || initialStateEntities;

export const selectPreapprovalsByLoanGuid = createSelector(
  selectEntitiesByLoanGuid,
  (entities: Entities): PreapprovalsEntity => {
    const now = dayjs();
    return {
      ...entities.preapprovals,
      data: entities.preapprovals.data.filter(
        preapproval => {
          const expDate = preapproval.letter.data['expiration-date'];
          return !expDate || now.diff(dayjs(expDate)) < 0;
        },
      ),
    };
  }
);

export const hasLoanPreapprovalsData = createSelector(
  selectPreapprovalsByLoanGuid,
  ({ hasData }: PreapprovalsEntity): boolean => !!hasData,
);

export const selectMaxPreapproval = createSelector(
  selectPreapprovalsByLoanGuid,
  ({ data: preapprovals }: PreapprovalsEntity): Preapproval | null => {
    return preapprovals?.length
      ? preapprovals.reduce((max, preapproval) =>
          max.letter.data['total-loan-amount'] > preapproval.letter.data['total-loan-amount'] ? max : preapproval,
        )
      : null;
  },
);

export const selectLatestPreapproval = createSelector(
  selectPreapprovalsByLoanGuid,
  ({ data: preapprovals }: PreapprovalsEntity): Preapproval | null => {
    return preapprovals?.length
      ? preapprovals.reduce((latest, preapproval) =>
          latest.letter['created-at'] > preapproval.letter['created-at'] ? latest : preapproval,
        )
      : null;
  },
);

// Check if loan has pre-approvals enabled
export const usePreapprovalsEnabled = (loanGuid: string): boolean => {
  const loan = useAppSelector((state: RootState) => selectLoan(state, loanGuid));
  return !!(loan && isPreapprovalsEnabled(loan));
};

// Check if loan is eligible for pre-approvals ex. loan task are ready
export const usePreapprovalsEligible = (loanGuid: string): boolean => {
  const loan = useAppSelector((state: RootState) => selectLoan(state, loanGuid));
  return !!loan && !isFundedLoan(loan) && isLoanReady(loan) && isLoanTasksReady(loan);
};

export const useFetchPreapprovals = (loanGuid: string, skip?: boolean) => {
  const dispatch = useAppDispatch();
  const preapprovalState = useAppSelector(state => selectPreapprovalsByLoanGuid(state, loanGuid));
  const isLoanEligible = usePreapprovalsEligible(loanGuid);
  const preapprovalsEnabled = usePreapprovalsEnabled(loanGuid) && isLoanEligible && !skip;
  const { hasData, hasError } = preapprovalState;

  useEffect(() => {
    if (preapprovalsEnabled && !hasData && !hasError) {
      dispatch(fetchPreapprovals(loanGuid));
    }
  }, [dispatch, loanGuid, preapprovalsEnabled, hasData, hasError]);

  return preapprovalState;
};

// Pre-approval letter generation

export const useShouldGeneratePreapproval = (loan: Loan): PreapprovalEligibility => {
  const {
    'generate-l2-pal?': shouldGenerateL2,
    'generate-l1-pal?': shouldGenerateL1,
    'application-status': applicationStatus,
  } = loan;
  const ausCompleted = isLoanAusCompleted(loan);
  const tasksReady = isLoanTasksReady(loan);
  const loanGuid = loan['loan-guid'];
  const { data: preapprovalsData } = useAppSelector(state =>
    selectPreapprovalsByLoanGuid(state, loanGuid),
  );
  // Prevent generating a letter if one already exists
  const hasLetters = !!preapprovalsData?.length;
  return {
    shouldGenerateL1Preapproval: !!(!hasLetters && shouldGenerateL1 && applicationStatus === 'completed'),
    shouldGenerateL2Preapproval: !!(!hasLetters && shouldGenerateL2 && tasksReady && ausCompleted),
  };
};

// Returns level of pre-approval letter that should be generated
export const usePreapprovalLetterLevel = (loan: Loan): string | null => {
  const { shouldGenerateL1Preapproval, shouldGenerateL2Preapproval } = useShouldGeneratePreapproval(loan);
  if (shouldGenerateL2Preapproval) {
    return '2';
  } else if (shouldGenerateL1Preapproval) {
    return '1';
  } else {
    return null;
  }
};

export const selectScenarioPreapprovalLetter = createSelector(
  selectEntitiesByLoanGuid,
  (entities: Entities): ScenarioPreapprovalEntity => entities.scenarioPreapproval,
);

export const selectHasApprovedScenarioPreapprovalLetter = createSelector(
  selectScenarioPreapprovalLetter,
  (scenarioPreapproval: ScenarioPreapprovalEntity): boolean => !!scenarioPreapproval.data?.hasApproval,
);

// New Pre-approval logic

export const selectHasNewPreapproval = createSelector(
  selectDocumentsLastViewed,
  selectPreapprovalsByLoanGuid,
  (docsLastViewed, { data: preapprovals }): boolean =>
    !!preapprovals &&
    preapprovals?.length > 0 &&
    (!docsLastViewed || preapprovals.some(preapproval => preapproval.letter['created-at'] > docsLastViewed)),
);

// Helper - given an array of pre-approvals, check if at least one pre-approval letter hasn't been viewed
export const hasUnviewedPreapproval = (preapprovals: Preapproval[]) =>
  preapprovals?.some((preapproval: Preapproval) => !preapproval['viewed?']);

export default preapprovalsSlice.reducer;
