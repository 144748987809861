import type { Loan } from '../features/loans/types';
import { LoanMilestones } from '../features/loans/types';
import { useAppSelector } from './hooks';
import { useFetchPreapprovals, selectLatestPreapproval, selectMaxPreapproval } from '../features/preapprovals/preapprovalsSlice';
import type { Preapproval } from '../features/preapprovals/types';

interface PreapprovalData {
  skip: boolean;
  loading: boolean;
  maxPreapproval: Preapproval | null;
  latestPreapproval: Preapproval | null;
}

const usePreapprovalsForPreapprovedMilestone = (loan: Loan): PreapprovalData => {
  const { 'loan-guid': loanGuid, 'loan-milestone': loanMilestone } = loan;
  const skip = loanMilestone !== LoanMilestones.PREAPPROVED;
  const preapprovalState = useFetchPreapprovals(loanGuid, skip);
  const maxPreapproval = useAppSelector(state => selectMaxPreapproval(state, loanGuid));
  const latestPreapproval = useAppSelector(state => selectLatestPreapproval(state, loanGuid));

  return {
    skip,
    loading: !skip && preapprovalState.loadingStatus === 'pending',
    maxPreapproval: !skip ? maxPreapproval : null,
    latestPreapproval: !skip ? latestPreapproval : null,
  };
};

export default usePreapprovalsForPreapprovedMilestone;
