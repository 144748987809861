import { Dispatch } from 'react';
import { fetchTask, updateTask } from '../../features/tasks/actions';
import { iSourceType } from '../../features/tasks/types';
import { log } from '../../utils/logger';

type WSEventType = 'TaskCreated' | 'TaskUpdated' | 'TaskCompleted';

type TaskEventData = {
  source: iSourceType;
  taskId: string;
  loanId: string;
  isRemoved: boolean;
  isCompleted: boolean;
};

interface WSResponse {
  taskData: TaskEventData;
  userId: string;
  receiptHandle: string;
  eventType: WSEventType;
}

const handleTaskEvent = (response: WSResponse, dispatch: Dispatch<any>, socketId?: string) => {
  const { taskData, eventType } = response;

  if (!taskData) {
    log({
      level: 'error',
      message: `Events Web Socket: Expected task data is missing`,
      socketId,
    });
    return;
  }

  const { loanId, taskId, isRemoved, source } = taskData;

  log({
    level: 'info',
    message: `Events Web Socket: ${eventType} event received`,
    loanGuid: loanId,
    taskId,
    socketId,
  });

  if (!['TaskCreated', 'TaskUpdated', 'TaskCompleted'].includes(eventType)) {
    log({
      level: 'warn',
      message: `Events Web Socket: Unknown event type ${eventType}`,
    });
    return;
  }

  if (isRemoved) {
    // Task will be filtered out in backend; no need to fetch it, just update store to hide it
    dispatch(updateTask(taskId, { taskDetails: { 'task-removed': isRemoved } }));
  } else {
    dispatch(fetchTask(loanId, taskId, source));
  }
};

export default handleTaskEvent;
