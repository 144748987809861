import { swiperPayment } from '../../../utils/grLinks';
import Embed from '../../ui/embed/Embed';
import { PaymentModalProps } from './IPayment';
import './PaymentModal.scss';

const PaymentModal = ({ swiperId, taskTitle, language }: PaymentModalProps) => (
  <div className='payment-modal__container'>
    <Embed className='payment-modal__iframe' title={taskTitle} src={swiperPayment(swiperId, language)} />
  </div>
);

export default PaymentModal;
