/**
 * Return an address string in the format "Street Address, City, State, Zip Code".
 * If any parameter is missing, ensures there are no hanging spaces or commas.
 */
import { HomeValueUserAddress } from '../features/homeValue/types';
import IAddress from '../interfaces/IAddress';

export const formatAddress = (street?: string, city?: string, state?: string, zipCode?: string) => {
  return `${street || ''}${street && city ? ', ' : ''}${city || ''}${city && state ? ', ' : ''}${state || ''}${state && zipCode ? ' ' : ''}${zipCode || ''}`;
};

export const formatAddressObject = (address: IAddress) => {
  if (!address) return 'N/A';
  const { 'street-address': street, city, state, 'postal-code': zip } = address;
  return formatAddress(street, city, state, zip);
};

export const convertGoogleAddress = (autocompleteAddress: google.maps.places.Autocomplete, unit?: string): HomeValueUserAddress | null => {
  if (!autocompleteAddress || !autocompleteAddress.getPlace) {
    return null;
  }

  const desiredFields = ['street_number', 'route', 'locality', 'administrative_area_level_1', 'postal_code'];
  const place = autocompleteAddress.getPlace();
  const addressComponents = place['address_components'];

  const addressFields: { [key: string]: string } | undefined = addressComponents
    ?.filter(component => desiredFields.includes(component.types[0]))
    .reduce((map, field) => {
      return {
        ...map,
        [field.types[0]]: field['short_name'],
      }
    }, {})

  if (!addressFields) {
    return null;
  }

  const streetAddress = (showUnitComma: boolean) => `${addressFields['street_number']} ${addressFields['route']}${unit ? (showUnitComma ? ', ' : ' ') + unit : ''}`;

  const formattedAddressWithUnit = `${streetAddress(true)}, ${addressFields.locality}, ${addressFields['administrative_area_level_1']} ${addressFields['postal_code']}`;

  const address = {
    street_address: streetAddress(false),
    city: addressFields.locality,
    state: addressFields['administrative_area_level_1'],
    zip: addressFields['postal_code'],
    metadata: {
      'formatted-address-with-unit': formattedAddressWithUnit,
    },
  };

  return address;
}
