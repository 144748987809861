import { DEFAULT_LANGUAGE } from './multilingual';

export const translateUrl = (url: string, appLanguage: string | null): string => {
  if (!appLanguage || appLanguage === DEFAULT_LANGUAGE) return url;
  try {
    const urlObject = new URL(url);
    if (url.startsWith(`${urlObject.origin}/${appLanguage}/`)) {
      return url;
    }
    return `${urlObject.origin}/${appLanguage}${urlObject.pathname}`;
  } catch {
    return url;
  }
};
