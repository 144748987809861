import cn from 'classnames';

import { themeId } from '../../features/tenant/tenant';
import THEMES from '../../themes/themes';
import { ucfirst } from '../../utils/str';

// Clear babel-cache for THEMES.THEMES to be recompiled with new JV property
// npm run clear:babel-cache
const themes = THEMES.THEMES as Record<iThemes, unknown>;
const theme = themes[themeId] as TenantTheme;
const themeColors: TenantThemeColors = { ...theme.colors, white: { DEFAULT: '#ffffff' } };

interface ColorShade {
  value: string; // numberic or DEFAULT
  hex: string;
  tailwind: string;
}

interface Color {
  id: string;
  shades: ColorShade[];
}

interface ColorSection {
  id: string;
  colors: Color[];
}

const sections = [
  { id: 'primary', colors: ['brand', 'body'] },
  { id: 'secondary', colors: ['action', 'input', 'white', 'inactive'] },
  { id: 'status', colors: ['ok', 'warning', 'critical', 'info'] },
];

interface TokenLabels {
  [index: string]: { [index: string]: { [index: string]: string } };
}
const tokenLabels: TokenLabels = {
  primary: {
    brand: {
      125: 'Primary/Brand/Dark',
      100: 'Primary/Brand/Base',
      10: 'Primary/Brand/Lightest',
    },
    body: {
      125: 'Primary/Body/Dark',
      100: 'Primary/Body/Base',
    },
  },
  secondary: {
    action: {
      125: 'Secondary/Action/Dark',
      100: 'Secondary/Action/Base',
      75: 'Secondary/Action/Light',
      50: 'Secondary/Action/Lighter',
      10: 'Secondary/Action/Lightest',
    },
    input: {
      50: 'Secondary/Input/Light',
      10: 'Secondary/Input/Lightest',
    },
    white: {
      DEFAULT: 'Secondary/White',
    },
    inactive: {
      100: 'Secondary/Inactive/Base',
      75: 'Secondary/Inactive/Medium',
      50: 'Secondary/Inactive/Light',
      25: 'Secondary/Inactive/Lighter',
      10: 'Secondary/Inactive/Lightest',
    },
  },
  status: {
    ok: {
      125: 'Status/Ok/Dark',
      100: 'Status/Ok/Base',
      10: 'Status/Ok/Lightest',
    },
    warning: {
      125: 'Status/Warning/Dark',
      100: 'Status/Warning/Base',
      10: 'Status/Warning/Lightest',
    },
    critical: {
      125: 'Status/Critical/Dark',
      100: 'Status/Critical/Base',
      10: 'Status/Critical/Lightest',
    },
    info: {
      125: 'Status/Info/Dark',
      100: 'Status/Info/Base',
      10: 'Status/Info/Lightest',
    },
  },
};

const colorsBySection = (): ColorSection[] =>
  sections.map(section => {
    const colors = section.colors
      .filter(color => themeColors[color])
      .map((color: string) => {
        const colorObj = themeColors[color];
        return {
          id: color,
          shades: Object.keys(colorObj).map((shade: string) => {
            return {
              value: shade,
              hex: colorObj[shade],
              tailwind: shade.toLowerCase() === 'default' ? color : `${color}-${shade}`,
            };
          }),
        };
      });
    return { id: section.id, colors };
  });

const ColorsComponent = () => {
  const sections = colorsBySection();

  return (
    <div className='colors'>
      <div className='mb-14'>
        <h2 className='header-medium'>Colors</h2>
      </div>
      {sections.map((section, section_i) => (
        <div key={section_i} className={cn({ 'mb-16': section_i !== sections.length - 1 })}>
          <div className='mb-8'>
            <h2 className='header-medium'>{ucfirst(section.id)}</h2>
          </div>
          <div className='flex flex-wrap -m-4'>
            {section.colors.map((color, color_i) => (
              <div key={`${section_i}_${color_i}`} className={cn('m-4', { 'mb-10': color_i !== section.colors.length - 1 })}>
                <div className='mb-3'>
                  <h3 className='header-small'>{ucfirst(color.id)}</h3>
                </div>
                <div className='flex flex-wrap mb-12 -m-4'>
                  {color.shades.map((shade, shade_i) => (
                    <div key={`${section_i}_${color_i}_${shade_i}`} className='m-4'>
                      <div className='pallet'>
                        <div className={`pallet__color bg-${shade.tailwind}`}></div>
                        <div className='flex flex-col my-4'>
                          <div className='flex justify-center items-center'>
                            <span className='pallet__subtext text-sm mr-2'>{shade.tailwind}</span>
                            <span> | </span>
                            <span className='pallet__subtext text-sm ml-2'>{shade.hex.toUpperCase()}</span>
                          </div>
                          {tokenLabels[section.id]?.[color.id]?.[shade.value] && (
                            <div className='flex justify-center items-center'>
                              <span className='pallet__subtext text-xs'>{tokenLabels[section.id][color.id][shade.value]}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ColorsComponent;
