import type { LoanOfficer } from '../features/loans/types';

export const lo: LoanOfficer = {
  'emp-id': 4723,
  'nmls-id': '12345',
  'photo-url': 'https://www.guaranteedrate.com/images/vp/johnsample.jpg',
  'role-key': 'lo',
  'role-name': 'Loan Officer',
  email: 'testlo@rate.com',
  fax: '773-555-5555',
  features: ['l1-pal', 'l2-pal'],
  id: 'testlo',
  licenses: [
    {
      state: 'KY',
      'license-number': '123-test',
    },
    {
      state: 'IA',
      'license-number': '123-test',
    },
    {
      state: 'VA',
      'license-number': '123-test',
    },
    {
      state: 'OR',
      'license-number': '123-test',
    },
    {
      state: 'SC',
      'license-number': '123-test',
    },
    {
      state: 'CT',
      'license-number': '123-test',
    },
    {
      state: 'FL',
      'license-number': '123-test',
    },
    {
      state: 'GA',
      'license-number': 'GA - TAO - 123-test',
    },
    {
      state: 'NE',
      'license-number': '123-test',
    },
    {
      state: 'ME',
      'license-number': '123-test',
    },
    {
      state: 'MT',
      'license-number': '123-test',
    },
    {
      state: 'WI',
      'license-number': '123-test',
    },
    {
      state: 'TN',
      'license-number': '123-test',
    },
    {
      state: 'WV',
      'license-number': '123-test',
    },
    {
      state: 'VT',
      'license-number': '123-test',
    },
    {
      state: 'ND',
      'license-number': '123-test',
    },
    {
      state: 'DE',
      'license-number': '123-test',
    },
    {
      state: 'IN',
      'license-number': '123-test',
    },
    {
      state: 'AR',
      'license-number': '123-test',
    },
    {
      state: 'NH',
      'license-number': '123-test',
    },
    {
      state: 'AL',
      'license-number': '123-test',
    },
    {
      state: 'MA',
      'license-number': '123-test',
    },
    {
      state: 'PA',
      'license-number': '123-test',
    },
    {
      state: 'LA',
      'license-number': '123-test',
    },
    {
      state: 'OK',
      'license-number': '123-test',
    },
    {
      state: 'MS',
      'license-number': '123-test',
    },
    {
      state: 'TX',
      'license-number': '123-test',
    },
    {
      state: 'UT',
      'license-number': '123-test',
    },
    {
      state: 'CO',
      'license-number': '123-test',
    },
    {
      state: 'NM',
      'license-number': '123-test',
    },
    {
      state: 'SD',
      'license-number': '123-test',
    },
    {
      state: 'NY',
      'license-number': '123-test',
    },
    {
      state: 'RI',
      'license-number': '123-test',
    },
    {
      state: 'NJ',
      'license-number': '123-test',
    },
    {
      state: 'MO',
      'license-number': '123-test',
    },
    {
      state: 'ID',
      'license-number': '123-test',
    },
    {
      state: 'AZ',
      'license-number': '123-test',
    },
    {
      state: 'HI',
      'license-number': '123-test',
    },
    {
      state: 'MI',
      'license-number': '123-test',
    },
    {
      state: 'NV',
      'license-number': '123-test',
    },
    {
      state: 'KS',
      'license-number': '123-test',
    },
    {
      state: 'MD',
      'license-number': 'anton-test',
    },
    {
      state: 'MN',
      'license-number': '123-test',
    },
    {
      state: 'CA',
      'license-number': '123-test',
    },
    {
      state: 'OH',
      'license-number': '123-test',
    },
    {
      state: 'DC',
      'license-number': '123-test',
    },
    {
      state: 'NC',
      'license-number': '123-test',
    },
    {
      state: 'IL',
      'license-number': '123456',
    },
    {
      state: 'WA',
      'license-number': '123-test',
    },
  ],
  name: 'John Sample',
  phone: '773-555-1212',
  status: 'active',
};

export default lo;
