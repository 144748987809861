import { useEffect } from 'react';
import { SpinnerFullScreen } from '../ui/spinner/Spinner';

const RedirectExternal = ({ to }: { to: string }) => {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);

  return <SpinnerFullScreen />;
};

export default RedirectExternal;
