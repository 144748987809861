import { useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { matchPath } from 'react-router-dom';
import { noop, Subject } from 'rxjs';
import { addNavigationBlocker, removeNavigationBlocker, selectAppLanguage } from '../../../features/app/appSlice';
import { NavigationBlocker } from '../../../features/app/types';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { MULTILINGUAL_PATHS, useMultilingual } from '../../../hooks/useMultilingual';
import { DEFAULT_LANGUAGE } from '../../../utils/multilingual';
import nextId from '../../../utils/nextId';
import ConfirmationModalContent from '../../ui/modal/ConfirmationModalContent';
import Modal from '../../ui/modal/Modal';

interface SpanishDisclaimerModalState {
  open: boolean;
  href: string | null;
  target: string | null;
  onConfirm: () => void;
}

export const spanishDisclaimerModalSubject: Subject<SpanishDisclaimerModalState> = new Subject();

export const SpanishNavigationDisclaimerModalContent = ({ onCancel, onConfirm }: { onCancel: () => void, onConfirm: () => void }) => {
  return (
    <ConfirmationModalContent
      title={`Ahora está saliendo de ${window.location.hostname} en español`}
      text='El contenido de la (s) siguiente (s) página (s) solo está disponible en inglés. Si decide comprar algún producto a través de MyAccount, tome en cuenta que las solicitudes, divulgaciones legales, documentos u otro material relacionado con los productos o servicios de Guaranteed Rate promocionados en esta página son ofrecidos solamente en inglés.'
      // the color setup is extremely unfortunate
      confirmButtonText='No, regresar'
      onConfirm={onCancel}
      cancelButtonText='Si, seguir'
      onCancel={onConfirm}
    />
  );
};

const LanguageNavigationBlocker = ({ path, exact, routeName }: { path: string | string[], exact?: boolean; routeName: string }) => {
  const dispatch = useAppDispatch();
  const match = useRouteMatch({
    path,
    exact,
  });
  const languageNavigationBlockerRef = useRef<NavigationBlocker>();
  const blockedPathRef = useRef<string>();
  const history = useHistory();
  const multilingual = useMultilingual();
  const [isCurrentRoute, setIsCurrentRoute] = useState(!!match);
  const isCurrentRouteRef = useRef<boolean>(!!match);
  const appLanguage = useAppSelector(selectAppLanguage);
  const appLanguageRef = useRef<string | null>(appLanguage);

  useEffect(() => {
    isCurrentRouteRef.current = !!match;
    appLanguageRef.current = appLanguage;
    setIsCurrentRoute(!!match);
  }, [match, appLanguage]);

  useEffect(() => {
    const onConfirmInternalNavigation = () => {
      if (languageNavigationBlockerRef.current) {
        dispatch(removeNavigationBlocker(languageNavigationBlockerRef.current));
        languageNavigationBlockerRef.current = undefined;
      }
      setTimeout(() => {
        if (blockedPathRef.current) {
          history.push(blockedPathRef.current);
        }
      });
    };

    if (isCurrentRoute && multilingual) {
      const languageNavigationBlocker: NavigationBlocker = {
        id: nextId(`route_blocker_spanish_disclaimer_${routeName}_`),
        persist: true,
        shouldBlockNavigation: (url?: string) => {
          if (url) {
            const multilingualPaths = matchPath(url, {
              path: MULTILINGUAL_PATHS,
              exact: true,
            });

            // if the app is not currently English AND the path navigating to is not one of the multilingual paths
            if (appLanguageRef.current !== DEFAULT_LANGUAGE && !multilingualPaths) {
              return true;
            }
          }
          return false;
        },
        shouldBlockUnload: () => {
          return false;
        },
        onNavigationBlocked: (url: string) => {
          blockedPathRef.current = url;
          spanishDisclaimerModalSubject.next({
            open: true,
            href: null,
            target: null,
            onConfirm: onConfirmInternalNavigation,
          });
        },
        onNavigationNativeBlocked: noop,
      };
      // keep the blocker around
      languageNavigationBlockerRef.current = languageNavigationBlocker;
      dispatch(addNavigationBlocker(languageNavigationBlocker));
    } else {
      if (languageNavigationBlockerRef.current) {
        dispatch(removeNavigationBlocker(languageNavigationBlockerRef.current));
        languageNavigationBlockerRef.current = undefined;
      }
    }

    // on destruct remove blocker
    return () => {
      if (languageNavigationBlockerRef.current) {
        dispatch(removeNavigationBlocker(languageNavigationBlockerRef.current));
        languageNavigationBlockerRef.current = undefined;
      }
    };
  }, [isCurrentRoute, dispatch, history, multilingual, routeName]);

  return null;
};

export const SpanishNavigationDisclaimerModal = ({ path, exact, routeName }: { path: string | string[], exact?: boolean; routeName: string }) => {
  const [showModal, setShowModal] = useState(false);
  const [href, setHref] = useState<string | null>(null);
  const [target, setTarget] = useState<string | null>(null);
  const onConfirmFunctionRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    const subscription = spanishDisclaimerModalSubject.subscribe(state => {
      setShowModal(state.open);
      setHref(state.href);
      setTarget(state.target);
      onConfirmFunctionRef.current = state.onConfirm;
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const onModalCancel = () => {
    setShowModal(false);
  };

  const onModalConfirm = () => {
    setShowModal(false);
    if (href) {
      let opener = window.open(href, target || '_self', 'noopener noreferrer');
      // eslint-disable-next-line
      opener = null;
    }
    onConfirmFunctionRef.current && onConfirmFunctionRef.current();
  }

  return (
    <>
      <LanguageNavigationBlocker path={path} exact={exact} routeName={routeName} />
      <Modal overlayStyle={{ zIndex: 3 }} open={showModal} onRequestClose={onModalCancel} contentLabel='Spanish Navigation Disclaimer'>
        <SpanishNavigationDisclaimerModalContent onCancel={onModalCancel} onConfirm={onModalConfirm} />
      </Modal>
    </>
  );
};
