import { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import { ModalProps, ModalContentProps } from './IModal';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import nextId from '../../../utils/nextId';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';
import './Modal.scss';

// Must match --modal-transition-duration defined in src/components/ui/modal/Modal.scss
const TRANSITION_DURATION = 100;

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

const ModalContent = ({
  fixScrollOnOpen,
  fullWidth,
  fullHeight,
  showClose,
  closeable,
  closeButtonBg,
  closeModal,
  render,
  children,
  className,
  verticalClassName,
  horizontalClassName,
}: ModalContentProps) => {
  return (
    <>
      {fixScrollOnOpen && document.getElementsByClassName('ReactModal__Content')[0]?.scrollIntoView()}
      {showClose && closeable && (
        <div className='modal__close'>
          <Button
            buttonType='icon'
            hasBg={closeButtonBg}
            rotate={true}
            icon={<Icon name='close' size='1.125rem' hoverEffect={true} />}
            text='Close'
            onClick={closeModal}
          />
        </div>
      )}
      <div className={cn('overflow-hidden lg:rounded-xl', className)}>
        <ConditionalWrapper
          condition={!fullWidth}
          el='div'
          className={cn('px-6 max-w-lg lg:px-12 lg:max-w-full mx-auto', horizontalClassName)}
        >
          <ConditionalWrapper condition={!fullHeight} el='div' className={cn('py-6 pt-12 lg:pb-11', verticalClassName)}>
            {render?.(closeModal) || children}
          </ConditionalWrapper>
        </ConditionalWrapper>
      </div>
    </>
  );
};

const Modal = (props: ModalProps) => {
  const {
    'aria-describedby': describedby,
    'aria-labelledby': labelledby,
    bodyOpenClassName,
    buttonProps,
    className,
    contentClassName,
    contentVerticalClassName,
    contentHorizontalClassName,
    showClose = true,
    closeable = true,
    closeButtonBg = true,
    contentLabel,
    htmlOpenClassName,
    id,
    preventClose,
    onRequestClose,
    onClose,
    onClosed,
    onOpen,
    onOpened,
    open,
    overlayClassName,
    shouldCloseOnOverlayClick,
    overlayStyle,
    contentStyle,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(!!(open === undefined ? false : open));
  const [animating, setAnimating] = useState<boolean>(false);
  const modalId = id || nextId('modal_');

  useEffect(() => {
    return () => {
      setAnimating(false);
    };
  }, []);

  useEffect(() => {
    setIsOpen(!!open);
  }, [open]);

  const handleClosed = useCallback(() => {
    setAnimating(false);
    onClosed?.();
  }, [onClosed]);

  const handleClose = useCallback(() => {
    setAnimating(true);
    onClose?.();
  }, [onClose]);

  const handleOpen = useCallback(() => {
    setAnimating(true);
    onOpen?.();
  }, [onOpen]);

  const handleOpened = useCallback(() => {
    setAnimating(false);
    onOpened?.();
  }, [onOpened]);

  const handleRequestClose = () => {
    if (preventClose?.()) return null;
    setIsOpen(false);
    onRequestClose?.();
    handleClose();
  };

  useEffect(() => {
    if (isOpen) {
      handleOpen();
    }
    // Technically we should add handleOpen to the deps array but then we'd need to add useCallback
    // to every parent component when passing the `onOpened` function prop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!animating && !isOpen && open === true) {
      setIsOpen(true);
      setAnimating(true);
    }
    if (!animating && isOpen && open === false) {
      setIsOpen(false);
      setAnimating(true);
    }
  }, [animating, open, isOpen]);

  return (
    <>
      {buttonProps && <Button onClick={() => setIsOpen(true)} {...buttonProps} />}
      <ReactModal
        id={modalId}
        closeTimeoutMS={TRANSITION_DURATION}
        isOpen={isOpen}
        onAfterOpen={handleOpened}
        onAfterClose={handleClosed}
        onRequestClose={handleRequestClose}
        preventScroll={true}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick !== undefined ? shouldCloseOnOverlayClick : closeable}
        contentLabel={contentLabel}
        className={cn('modal lg:rounded-xl', className)}
        overlayClassName={cn('modal-overlay', overlayClassName)}
        htmlOpenClassName={cn('modal-html--open', htmlOpenClassName)}
        bodyOpenClassName={cn('modal-body--open', bodyOpenClassName)}
        style={{ overlay: overlayStyle, content: contentStyle }}
        aria={
          labelledby || describedby
            ? {
                labelledby: labelledby ? labelledby : undefined,
                describedby: describedby ? describedby : undefined,
              }
            : undefined
        }
      >
        <ModalContent
          {...props}
          className={contentClassName}
          verticalClassName={contentVerticalClassName}
          horizontalClassName={contentHorizontalClassName}
          closeable={closeable}
          closeButtonBg={closeButtonBg}
          showClose={showClose}
          closeModal={handleRequestClose}
        />
      </ReactModal>
    </>
  );
};

export default Modal;
