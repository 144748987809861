import { useState, ChangeEvent } from 'react';
import FormButton from '../../ui/form/FormButton';
import GooglePlacesAutocomplete from '../../ui/googlePlacesAutocomplete/GooglePlacesAutocomplete';
import Card from '../../ui/card/Card';
import FormGroup from '../../ui/form/FormGroup';
import HomeValueFAQs from '../homeValueFAQs/HomeValueFAQs';
import './HomeValueSearch.scss';
import { convertGoogleAddress } from '../../../utils/addressFormatter';
import {
  clearHomeValueError,
  selectHomeValueError,
  fetchHomeValue,
  selectPreviousHomeValueAddress,
} from '../../../features/homeValue/homeValueSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { sendEvent } from '../../../features/analytics/sendEvent';
import cn from 'classnames';
import { NAMES } from '../../routes/paths';
import { useSetRouteState } from '../../../features/RouteContext';
import { FormGroupElement } from '../../ui/form/IFormGroup';

const HomeValueSearch = () => {
  const dispatch = useAppDispatch();
  const [googleAutocomplete, setGoogleAutocomplete] = useState<google.maps.places.Autocomplete>(
    {} as google.maps.places.Autocomplete,
  );
  const [unit, setUnit] = useState<string>('');
  const previousAddress = useAppSelector(selectPreviousHomeValueAddress);
  const isError = useAppSelector(selectHomeValueError);
  const [isInputValid, setIsInputValid] = useState(true);
  const [isBlurError, setIsBlurError] = useState(false);

  useSetRouteState({ name: NAMES.homeValue, subview: 'search' });

  const emitAddressFrom = (autocomplete: google.maps.places.Autocomplete) => {
    setIsInputValid(true);
    setIsBlurError(false);
    setGoogleAutocomplete(autocomplete);
  };

  const handleSubmit = () => {
    const address = convertGoogleAddress(googleAutocomplete, unit);

    if (address) {
      sendEvent('homeValueSearch', {
        address,
        previousAddress: previousAddress ? previousAddress : null,
      });

      dispatch(fetchHomeValue(address));
    } else {
      setIsInputValid(false);
    }
  };

  const handleBlur = () => {
    !isInputValid && setIsBlurError(true);
  }

  const handleInputChanged = (e: ChangeEvent<FormGroupElement>) => {
    setIsInputValid(false);
    dispatch(clearHomeValueError());
  };

  const handleUnitChange = (event: ChangeEvent<FormGroupElement>) => {
    setUnit(event.target.value);
    dispatch(clearHomeValueError());
  };

  const errorMessage = isError ? 'Address not found in our database. Try again.' : isBlurError ? 'Please enter a property address and select it from the drop down.' : undefined;

  return (
    <>
      <h1 className='mb-4 mt-2 header-medium'>How much is my home worth?</h1>
      <p className='mb-8'>
        Enter your address to see your current home value. We will search millions of home records in our database to provide a real
        world estimate, scope out the competition, and explore options that may help you get the best return on your investment.
      </p>
      <Card className='home-value-search mb-12 py-8 px-3 sm:px-12 border border-gray-25 rounded-xl'>
        <h2 className='text-xl mb-4'>What&apos;s your address?</h2>
        <div className='flex flex-col lg:grid lg:grid-cols-12 lg:gap-6'>
          <div className={cn('lg:col-span-8 mb-6 lg:mb-0', { 'mb-9': !!errorMessage })}>
            <GooglePlacesAutocomplete error={errorMessage} onAddressChange={emitAddressFrom} onChange={handleInputChanged} onBlur={handleBlur} />
          </div>
          <FormGroup
            className='form-group-override lg:col-span-4'
            error={isError || isBlurError ? ' ' : null}
            onChange={handleUnitChange}
            placeholder=''
            id='unit'
            label='Unit'
          />
        </div>
        {/* TODO - consider disable this button if empty or google cannot autocomplete the input */}
        <FormButton
          disabled={isError || !isInputValid}
          onClick={handleSubmit}
          className='home-value-search__button w-full block mx-auto sm:text-xl'
        >
          See my home value
        </FormButton>
      </Card>
      <HomeValueFAQs />
    </>
  );
};

export default HomeValueSearch;
