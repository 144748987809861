import cn from 'classnames';
import Button from '../button/Button';
import Card from '../card/Card';
import Icon from '../icon/Icon';
import Modal from '../modal/Modal';
import { Skeleton } from '../skeleton/Skeleton';
import { ActionBannerProps } from './iActionBanner';

export const ActionBannerSkeleton = () => {
  return (
    <Card className='flex px-3 py-3 mt-4'>
      <div className='flex items-center'>
        <Skeleton className='mr-4 w-12 h-12 rounded-xl' />
      </div>
      <div className='flex flex-col justify-center w-full'>
        <Skeleton className='mb-2 w-full lg:w-64' height='18px' />
        <Skeleton className='w-full lg:w-96' height='18px' />
      </div>
    </Card>
  );
};

const ActionBanner = ({
  title,
  description,
  contentLabel,
  isPrimary,
  bannerNumber,
  bannerIcon,
  buttonProps,
  modalContent,
}: ActionBannerProps) => {
  const buttonClasses = 'bold whitespace-nowrap w-full sm:w-auto my-0.5';
  const chevronIcon = (
    <Icon name={buttonProps?.href ? 'external-link' : 'chevron-right-small'} size='1rem' className='align-middle' hoverEffect={true} />
  );
  return (
    <div
      className={cn('flex sm:flex-nowrap sm:flex-row p-3 border border-gray-25 rounded-xl', {
        'flex-col': isPrimary,
        'no-wrap': !isPrimary,
      })}
    >
      <div className='flex flex-1 sm:flex-auto'>
        <div
          className={cn('bg-action-10 text-body-100 w-12 h-12 rounded-xl mr-4 flex-none', {
            'p-3': !!bannerIcon,
            'p-2': !!bannerNumber,
          })}
        >
          <div className='text-2xl text-center font-semibold'>{bannerNumber}</div>
          {bannerIcon && <Icon name={bannerIcon} size='1.5rem' />}
        </div>
        <div className='flex-1 sm:flex-auto mr-6'>
          <div className='bold'>{title}</div>
          <div>{description}</div>
        </div>
      </div>
      <div
        className={cn('flex lg:items-center items-start justify-end sm:flex-auto sm:w-auto sm:mt-0 self-auto', {
          'mt-4': isPrimary,
          'flex-1': isPrimary,
          'w-full': isPrimary,
        })}
      >
        {!modalContent &&
          buttonProps &&
          (buttonProps.text && isPrimary ? (
            <Button
              buttonType='primary'
              className={buttonClasses}
              title={buttonProps.title}
              {...(buttonProps.to && { to: buttonProps.to })}
              {...(buttonProps.href && { href: buttonProps.href, target: '_blank', iconName: 'external-link' })}
              onClick={buttonProps.onClick || undefined}
            >
              {buttonProps.text}
            </Button>
          ) : (
            <Button
              {...(buttonProps?.to && { to: buttonProps.to })}
              {...(buttonProps?.href && { href: buttonProps.href, target: '_blank' })}
              onClick={buttonProps?.onClick || undefined}
              buttonType={'icon'}
              icon={chevronIcon}
              className='-mt-2 -mr-1'
            >
              {chevronIcon}
            </Button>
          ))}
        {modalContent && buttonProps && (
          <Modal
            buttonProps={{
              text: buttonProps.text || '',
              buttonType: isPrimary ? 'primary' : 'icon',
              className: cn(buttonClasses, {'-mt-2 -mr-1': !isPrimary}),
              title: buttonProps.title,
              icon: !isPrimary ? chevronIcon : undefined,
            }}
            contentLabel={`${contentLabel} modal`}
          >
            {modalContent}
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ActionBanner;
