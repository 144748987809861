import { useEffect } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { ILoanRouteParams } from '../../interfaces/IRouteParams';
import { LocationState } from '../../interfaces/ILocationState';
import { updateGlobalError, updateGlobalLoadingOnce } from '../../features/app/appSlice';
import type { Loan } from '../../features/loans/types';
import { log } from '../../utils/logger';
import { setDataLayer } from '../../features/analytics/setDataLayer';
import { selectLoansEntity } from '../../features/loans/loansSlice';
import { useLoan } from '../../features/loans/hooks';
import { RequestRejectValue } from '../../interfaces/IRequest';

interface withLoanProps {
  loan: Loan;
}

export const withLoan = <P extends Record<string, unknown>>(Component: React.ComponentType<P & withLoanProps>) =>
  function New(props: P) {
    const dispatch = useAppDispatch();
    const location = useLocation<LocationState>();
    const { guid } = useParams<ILoanRouteParams>();
    const { data: loan, hasData, hasError, error } = useLoan(guid);
    const errorStatus = (error as RequestRejectValue)?.status;
    const { hasData: hasLoansData, data } = useAppSelector(state => selectLoansEntity(state));
    const notFound = hasLoansData && !data?.includes(guid);

    useEffect(() => {
      !hasData && hasError && errorStatus !== 404 && dispatch(updateGlobalError(`Fetch loan error`));
    }, [dispatch, hasData, hasError, errorStatus]);

    useEffect(() => {
      (hasData || hasError || notFound) && dispatch(updateGlobalLoadingOnce(false));
    }, [dispatch, hasData, hasError, notFound]);

    useEffect(() => {
      hasData && loan && setDataLayer('currentLoan', loan);
    }, [hasData, loan]);

    if (notFound || (hasError && errorStatus === 404)) {
      log({ loanGuid: guid, message: 'No loan found. Showing 404 error.' });
      return (
        <Redirect
          to={{
            pathname: location.pathname,
            state: { notFoundError: true },
          }}
        />
      );
    }

    if (!hasData || !loan) return null;

    return <Component {...props} loan={loan} />;
  };

export default withLoan;
