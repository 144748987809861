import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import type { Loan } from '../features/loans/types';
import { isLoanReady, isTasklistLoan } from '../features/loans/helpers';
import { selectors as tasksSelectors } from '../features/tasks';
import { selectUserLoanAttributesByLoan, updateUserLoanAttribute } from '../features/user/userSlice';
import { log } from '../utils/logger';
import timestamp from '../utils/timestamp';

export const TASKS_READY_KEY = 'tasksReadyAt';

export const useLoanTasksReadyAt = (loan: Loan): string => {
  const { 'loan-guid': loanGuid } = loan;
  const dispatch = useAppDispatch();
  const hasTasksData = useAppSelector(state => tasksSelectors.hasLoanTasksData(state, loanGuid));
  const tasksReadyAt = useAppSelector(state => selectUserLoanAttributesByLoan(state, loanGuid))?.[TASKS_READY_KEY];
  const tasksReadyTimestamp = useRef<string>(tasksReadyAt || timestamp());
  const tasklistLoan = isTasklistLoan(loan);
  const loanReady = isLoanReady(loan);
  const ready = tasklistLoan && loanReady && hasTasksData;

  useEffect(() => {
    if (ready && !tasksReadyAt) {
      dispatch(updateUserLoanAttribute({ loanGuid, value: { [TASKS_READY_KEY]: tasksReadyTimestamp.current } }));
      log({
        loanGuid,
        message: `Tasks ready for loan tasklist. ${JSON.stringify({ [TASKS_READY_KEY]: tasksReadyTimestamp.current })}`,
      });
    }
  }, [dispatch, ready, loanGuid, tasksReadyAt]);

  return tasksReadyTimestamp.current;
};