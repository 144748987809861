import { getNextPaymentDate, isPersonalLoanFunded } from '../../../../features/personalLoans/personalLoansSlice';
import { iPersonalLoan } from '../../../../features/personalLoans/types';
import { usdAmount } from '../../../../utils/amount';
import { formatDate, FORMAT_SHORT } from '../../../../utils/date';
import { formatPercent } from '../../../../utils/percent';
import DetailsCard from '../../detailsCard/DetailsCard';
import LoanNumberTooltip from '../../detailsCard/LoanNumberTooltip';
import { PersonalLoanMilestoneHeroContents } from '../../milestone/MilestoneHero';
import { LoanMilestoneStep } from '../../loanStatusInfo/ILoanStatusInfo';
import { IDataItem } from '../../../ui/datalist/dataItem/IDataItem';
import { PaymentTracker } from './PaymentTracker';
import { usePersonalLoanConfig } from '../../../../hooks/usePersonalLoanConfig';

const calculateCurrentBalance = (personalLoan: iPersonalLoan) => {
  return (
    +(personalLoan.loanAccounting?.principalBalance || 0) +
    (personalLoan.loanAccounting?.interestBalance || 0) +
    (personalLoan.loanAccounting?.feeAmountDue || 0)
  );
};

const getMilestoneStep = (personalLoan: iPersonalLoan) => {
  const status = personalLoan?.loanAccounting?.status || personalLoan?.loanDetails?.status;
  switch(status) {
    case 'repayment':
    case 'forbearance':
      return {
        title: 'Active',
        description: 'Your loan is active.',
      } as LoanMilestoneStep;
    case 'default':
    case 'charge off':
    case 'write off':
      return {
        title: 'Default',
        description: 'Your loan is currently in default. Please contact us',
      } as LoanMilestoneStep;
    case 'paid in full':
      return {
        title: 'Paid in Full',
        description: 'Congratulations! Your loan is now paid in full.',
      } as LoanMilestoneStep;
    case 'cancel':
    case 'rejected':
      return {
        title: 'Not Active',
        description: 'Please contact us.',
      } as LoanMilestoneStep;
    case 'deferred':
    case 'fraud':
      return {
        title: 'Active',
        description: 'Please contact us.',
      } as LoanMilestoneStep;
    case 'approved':
    default :
      return {
        title: 'Approved',
        description: 'Your funds are being transferred and should be available in next 1 - 3 business days.',
      } as LoanMilestoneStep;
  }
};

const getFormattedDate = (date: string | undefined): string | undefined => {
  return date ? formatDate(date, FORMAT_SHORT) : undefined;
};

const SubHeader = ({ personalLoan }: { personalLoan: iPersonalLoan }) => {
  const loanConfig = usePersonalLoanConfig(personalLoan);
  const loanAmount = personalLoan.loanDetails.amount;
  const milestoneStep = getMilestoneStep(personalLoan);
  return (
    <>
      {loanConfig && <PersonalLoanMilestoneHeroContents milestoneStep={milestoneStep} />}
      {!isPersonalLoanFunded(personalLoan) && <div className='border border-gray-25 mb-4 mt-8' />}
      {isPersonalLoanFunded(personalLoan) && (
        <>
          <PaymentTracker
            totalAmount={loanAmount}
            amountRemaining={personalLoan.loanAccounting?.totalAmountDue || 0}
            className='pb-4 pt-8'
          />
          <div className='funded-loan-details grid grid-cols-2 gap-x-4 sm:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 pb-10 justify-items-start'>
            <div className='flex flex-col lg:flex-row self-start'>
              <div className='self-center mr-2'>Current balance</div>
              <div className='text-2xl font-bold'>{usdAmount(calculateCurrentBalance(personalLoan))}</div>
            </div>
            <div className='flex flex-col lg:flex-row self-start'>
              <div className='self-center mr-2'>Original loan amount</div>
              <div className='text-2xl font-bold'>{usdAmount(loanAmount)}</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

/**
 * Payment Card for personal loan
 */
export const PersonalLoanDetailsCard = ({ personalLoan }: { personalLoan: iPersonalLoan }) => {
  const remainingTerm = personalLoan.loanAccounting?.remainingTerm;
  const details: IDataItem[] = isPersonalLoanFunded(personalLoan)
    ? [
        {
          value: personalLoan.servicer?.loanNumber || 'N/A',
          label: 'Loan number',
          tooltip: LoanNumberTooltip,
        },
        {
          value: personalLoan.loanDetails.term
            ? `${personalLoan.loanDetails.term} ${personalLoan.loanDetails.termType === 'monthly' ? 'months' : ''}`
            : undefined,
          label: 'Loan term',
        },
        {
          value: formatPercent(personalLoan.loanDetails.apr * 100, 3),
          label: 'Interest rate',
        },
        {
          value: getFormattedDate(personalLoan.loanAccounting?.lastPaymentDate) || 'N/A',
          label: 'Last payment date',
        },
        {
          value: usdAmount(personalLoan.loanAccounting?.transactionAmount) || 'N/A',
          label: 'Last payment amount',
        },
        {
          value: personalLoan.loanDetails.status,
          label: 'Loan status',
        },
        {
          value: remainingTerm ? `${remainingTerm} months` : undefined,
          label: 'Remaining terms',
        },
        {
          value: getFormattedDate(getNextPaymentDate(personalLoan)),
          label: 'Next payment due',
        },
        {
          value: usdAmount(personalLoan.loanDetails.monthlyPaymentAmount),
          label: 'Next payment amount',
        },
      ]
    : [
        {
          value: personalLoan.originator?.loanNumber || 'N/A',
          label: 'Loan number',
          tooltip: LoanNumberTooltip,
        },
        {
          value: usdAmount(personalLoan.loanDetails.amount),
          label: 'Loan amount',
        },
        {
          value: personalLoan.loanDetails.term ? `${personalLoan.loanDetails.term} months` : undefined,
          label: 'Loan term',
        },
        {
          value: formatPercent(personalLoan.loanDetails.apr * 100, 3),
          label: 'Interest rate',
        },
        {
          value: getFormattedDate(personalLoan.loanDetails.contract?.firstPaymentDueDate),
          label: 'First payment due',
        },
        {
          value: usdAmount(personalLoan.loanDetails.monthlyPaymentAmount),
          label: 'First payment amount',
        },
      ];
  return <DetailsCard subHeader={<SubHeader personalLoan={personalLoan} />} details={details} />;
};
