import { selectAppLanguage, selectGlobalAdtrk } from '../../../features/app/appSlice';
import { useAppSelector } from '../../../hooks/hooks';
import { useMultilingual } from '../../../hooks/useMultilingual';
import { dmxApplication } from '../../../utils/grLinks';
import Button from './Button';
import cn from 'classnames';
import { iButtonType } from './IButton';

const ApplyLoanButton = (
  {
    empId,
    onClick,
    className,
    buttonType = 'tertiary',
    text = 'Apply now',
  }:
  {
    empId?: number;
    onClick: () => void;
    className?: string;
    buttonType?: iButtonType;
    text?: string;
  }
) => {
  const multilingual = useMultilingual();
  const appLanguage = useAppSelector(selectAppLanguage);
  const langPref = multilingual && appLanguage ? appLanguage : undefined;
  const adtrk = useAppSelector(selectGlobalAdtrk);

  return (
    <Button
      className={cn(className)}
      buttonType={buttonType}
      text={text}
      href={dmxApplication({ 'emp-id': empId, langPref, adtrk })}
      onClick={onClick}
      target='_blank'
      rel='noopener'
    />
  );
};

export default ApplyLoanButton;
