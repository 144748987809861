export const PREFIX = 'id';
let lastId = 0;

export const nextId = (prefix?: string): string => {
  lastId++;
  return `${prefix || PREFIX}${lastId}`;
}

export const resetId = (): void => {
  lastId = 0;
};

export default nextId;
