import Card from '../../ui/card/Card';
import { usdAmount } from '../../../utils/amount';
import { HomeValueData } from '../../../pages/homeValue/IHomeValue';
import './HomeValueEstimate.scss';
import Tooltip from '../../ui/tooltip/Tooltip';
import Icon from '../../ui/icon/Icon';
import { HomeValueUserAddress } from '../../../features/homeValue/types';
import { useAppSelector } from '../../../hooks/hooks';
import { selectLastHomeValueHistory } from '../../../features/homeValue/homeValueSlice';
import { formatDate, FORMAT_SHORT } from '../../../utils/date';

const HomeValueEstimate = ({ data, address }: { data: HomeValueData; address: HomeValueUserAddress }) => {
  return (
    <Card className='p-6 border border-gray-25 rounded-xl mb-12'>
      <div className='home-value-estimate text-center'>
        <h1 className='header-small mb-4'>Your estimated home value</h1>
        <div className='md:flex'>
          {/* top or left side */}
          <div className='md:w-1/2 md:mr-6'>
            <p className='text-5xl font-bold'>{usdAmount(data.estimates.average, false)}</p>
            <ValueChange data={data} />
            <EstimateRange data={data} />
          </div>

          {/* bottom or right side */}
          <div className='flex justify-center items-center mx-auto md:w-1/2 text-left md:pr-2'>
            <p className='home-value-estimate__disclaimer'>
              Your estimated home value was calculated based on market data collected from third-party sources, home features, and
              comparable homes sold in your area: {address.zip}. <strong>It is not based on an appraisal of your home.</strong>
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

const ValueChange = ({ data }: { data: HomeValueData }) => {
  const lastValueHistory = useAppSelector(selectLastHomeValueHistory);
  // if no history or estimates for the address, show the current value generated date
  if (!lastValueHistory?.estimates) {
    return (
      <div className='mt-2 mb-3 flex flex-row justify-center items-center'>
        <p>As of: {formatDate(data['generated-at'], FORMAT_SHORT)}</p>
      </div>
    );
  }

  const currentValue = data.estimates.average;
  const lastValue = lastValueHistory.estimates.average;
  const delta = currentValue - lastValue;
  const isAsending = delta > 0;
  const deltaAbsolute = Math.abs(delta);
  const deltaPercentage = Math.round((deltaAbsolute / lastValue) * 100);
  const deltaString = `${usdAmount(deltaAbsolute)} (${deltaPercentage}%)`;

  return (
    <div className='mt-2 mb-6 flex flex-col sm:flex-row justify-center items-center'>
      {delta !== 0 && (
        <div
          className='flex items-center'
          title={lastValueHistory['generated-at'] ? `From: ${formatDate(lastValueHistory['generated-at'], FORMAT_SHORT)}` : ''}
        >
          <Icon
            name={isAsending ? 'arrow-up' : 'arrow-down'}
            className={`w-5 h-5 mr-2 ${isAsending ? 'text-ok' : 'text-critical'}`}
            size='1rem'
          />
          <p className={`font-bold ${isAsending ? 'text-ok' : 'text-critical'}`}>
            {isAsending ? '+' : '-'} {deltaString}
          </p>
        </div>
      )}
      <p className='ml-0 sm:ml-6'>As of: {formatDate(data['generated-at'], FORMAT_SHORT)}</p>
    </div>
  );
};

const EstimateRange = ({ data }: { data: HomeValueData }) => {
  const estimates = data.estimates;
  const showRange = estimates.average !== estimates.max && estimates.average !== estimates.min;
  return showRange ? (
    <>
      <div className='flex justify-center mb-2'>
        <p className='font-bold'>Accuracy range </p>
        <Tooltip
          className='ml-2'
          place='bottom'
          html={true}
          tip='The <strong>accuracy range</strong> refers to the highest and lowest median prices in the homes zipcode, providing a better glimpse of the potential value.'
        >
          <Icon name='information-circle' size='1.25rem' className='text-action' />
        </Tooltip>
      </div>
      <div className='home-value-estimate__range-container flex flex-col md:flex-row gap-0 md:gap-6 justify-center items-center flex-wrap mb-6 md:mb-0'>
        <div className='flex w-full md:w-min items-center justify-center whitespace-nowrap'>
          <CaretIcon direction='up' />
          <p className='w-10 text-left'>High</p>
          <p>{estimates?.max ? usdAmount(estimates.max, false) : '$ --'}</p>
        </div>
        <div className='flex w-full md:w-min items-center justify-center whitespace-nowrap'>
          <CaretIcon direction='down' />
          <p className='w-10 text-left'>Low</p>
          <p>{estimates?.min ? usdAmount(estimates.min, false) : '$ --'}</p>
        </div>
      </div>
    </>
  ) : null;
};

const CaretIcon = ({ direction }: { direction: 'up' | 'down' }) => (
  <span className={`${direction === 'up' ? 'bg-ok-10' : 'bg-critical-10'} flex justify-center items-center rounded-full mr-2`}>
    {direction === 'up' ? (
      <Icon
        name='sort-ascending'
        className='home-value-estimate__sort-ascending w-5 h-5 text-ok inline-flex justify-center items-center'
        size='0.5rem'
      />
    ) : (
      <Icon
        name='sort-descending'
        className='home-value-estimate__sort-descending w-5 h-5 text-critical inline-flex justify-center items-center'
        size='0.5rem'
      />
    )}
  </span>
);

export default HomeValueEstimate;
