import { Redirect, useLocation, useParams } from 'react-router-dom';
import useFetchHELOCLoansData from '../../../features/helocLoans/useFetchHELOCLoansData';
import { selectHELOCLoanById } from '../../../features/helocLoans/helocLoansSlice';
import { iHELOCLoan } from '../../../features/helocLoans/types';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { LocationState } from '../../../interfaces/ILocationState';
import { ILoanRouteParams } from '../../../interfaces/IRouteParams';
import { log } from '../../../utils/logger';
import { useEffect } from 'react';
import { updateGlobalLoadingOnce } from '../../../features/app/appSlice';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';

interface withHELOCLoanProps {
  helocLoan: iHELOCLoan;
}

export const withHELOCLoan = <P extends Record<string, unknown>>(Component: React.ComponentType<P & withHELOCLoanProps>) =>
  function New(props: P) {
    const dispatch = useAppDispatch();
    const helocLoanEnabled = useFeatureEnabled('heloc-loans');
    const { hasData, hasError } = useFetchHELOCLoansData();
    const location = useLocation<LocationState>();
    const { guid } = useParams<ILoanRouteParams>();
    const helocLoan = useAppSelector(state => selectHELOCLoanById(state, guid));

    useEffect(() => {
      (!helocLoanEnabled || hasData || hasError) && dispatch(updateGlobalLoadingOnce(false));
    }, [dispatch, helocLoanEnabled, hasData, hasError]);

    if (helocLoanEnabled && !hasData && !hasError) return null;

    if (!helocLoan) {
      log({ message: 'No HELOC loan found. Showing 404 error.' });
      return (
        <Redirect
          to={{
            pathname: location.pathname,
            state: { notFoundError: true },
          }}
        />
      );
    }

    return <Component {...props} helocLoan={helocLoan} />;
  };

export default withHELOCLoan;
