import { SocialShareConfigs } from '../components/social/ISocialShareConfig';
import useFacebookShare from './useFacebookShare';
import useFeatureEnabled from './useFeatureEnabled';
import useTwitterShare from './useTwitterShare';

export const useSocialShare = (configKey: SocialShareConfigs) => {
  const facebookShareProps = useFacebookShare(configKey);
  const twitterShareProps = useTwitterShare(configKey);
  return useFeatureEnabled('social-sharing') && (facebookShareProps || twitterShareProps);
};
