import FAQs, { IFAQ } from '../../ui/faqs/FAQs';
import { content } from '../../../features/tenant/tenant';

const HomeValueFAQs = () => {
  const faqs = homeValueFAQs();
  return <FAQs faqs={faqs} heading='Frequently asked questions' />;
};

const homeValueFAQs = (): IFAQ[] => [
  {
    question: 'What is a home value estimate?',
    answer: <p>A calculation that determines a general selling price of your property in the current market.</p>,
  },
  {
    question: 'How is my home value calculated?',
    answer: <p>The estimate uses data collected from third parties and public records.</p>,
  },
  {
    // TODO: What is the decision regarding this Q&A? Keep or remove?
    question: 'What does high and low mean?',
    answer: (
      <p>
        An estimate's accuracy varies based on the availability of property information. A low- and high-end range may provide a
        better glimpse of the potential value.
      </p>
    ),
  },
  {
    question: 'How accurate is my home value estimate?',
    answer: (
      <p>
        Estimates may vary based on the available information on a property. This is not an official appraised value. The actual
        appraised value of any property may be higher or lower.
      </p>
    ),
  },
  {
    question: 'How often does my home value change?',
    answer: (
      <p>
        The estimate will be updated every month based on our available records. Make sure the checkbox is filled to get alerts any
        time your value changes. The history panel near the bottom of the page will track the changes over time.
      </p>
    ),
  },
  {
    question: 'How much does an estimate cost?',
    answer: (
      <p>
        Free. The home value estimate is a complimentary service provided by {content.legalName || content.company} to help our customers make informed
        decisions.
      </p>
    ),
  },
];

export default HomeValueFAQs;
