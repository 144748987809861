import { LoanConfig } from '../components/loan/loanCard/ILoanCard';
import { AlpLoanType, Loan } from '../features/loans/types';
import { useAlpHELOCLoanConfig } from './useAlpHELOCLoanConfig';
import { useCESLoanConfig } from './useCESLoanConfig';
import { useHomeLoanConfig } from './useHomeLoanConfig';

export const useLoanConfig = (loan: Loan): LoanConfig => {
  const homeLoanConfig = useHomeLoanConfig(loan);
  const cesLoanConfig = useCESLoanConfig(loan);
  const alpHelocLoanConfig = useAlpHELOCLoanConfig(loan);

  if (loan['alp-loan-type'] === AlpLoanType.HELOC) {
    return alpHelocLoanConfig;
  } else if (loan['alp-loan-type'] === AlpLoanType.CES) {
    return cesLoanConfig;
  }
  return homeLoanConfig;
};
