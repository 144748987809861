interface CookieOptions {
    path?: string;
    domain?: string;
    'max-age'?: number;
    expires?: string | Date;
    secure?: boolean;
    samesite?: string;
}

export const setCookie = (name: string, value: string | boolean, options: CookieOptions = {}) => {
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + (value !== true && encodeURIComponent(value));

  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey as keyof CookieOptions];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
};