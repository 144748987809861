import { Task } from '../../../features/tasks/types';
import TaskCompletedDocuments from '../task/TaskCompletedDocuments';
import BorrowerAffirmationsIncomplete from './BorrowerAffirmationsIncomplete';

export const BORROWER_AFFIRMATION_TYPES = ['affirmations', 'interpretation_ack'] as const;

interface Props {
  loanGuid: string;
  task: Task<'affirmations'> | Task<'interpretation_ack'>;
  setAccordionActive?: (active: boolean) => void;
}

const BorrowerAffirmationsTaskBody = ({ loanGuid, task, setAccordionActive }: Props) => {
  return (
    <>
      {!!task['task-completed']
        ? <TaskCompletedDocuments loanGuid={loanGuid} task={task} />
        : <BorrowerAffirmationsIncomplete loanGuid={loanGuid} task={task} setAccordionActive={setAccordionActive} />
      }
    </>
  );
};

export default BorrowerAffirmationsTaskBody;
