interface ErrorProps {
  title: string;
  text?: string;
  error?: string;
  cta: React.ReactNode;
}

const Error = ({ title, text, error, cta }: ErrorProps) => (
  <div>
    <div className='pt-20 error-view-icon'>
      <img src='/images/error-found.svg' width='150' height='150' alt='Magnifying glass' />
    </div>
    <div className='pt-12'>
      <h1 className='text-marketing-xs md:text-marketing-md pb-5'>{title}</h1>
      {error && <p className='text-xl font-bold text-critical pb-2'>{error}</p>}
      {text && <p className='pb-5 text-xl'>{text}</p>}
      {cta}
    </div>
  </div>
);

export default Error;
