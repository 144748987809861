import { useEffect, useRef, useState } from 'react';
import stopwatchImage from '../../images/stopwatch.svg';

const Stopwatch = ({ startMinute = 0, paused }: { startMinute?: number, paused?: boolean; }) => {
  const [rotateDeg, setRotateDeg] = useState(startMinute * 6);
  const rotateDegTimeoutRef = useRef(0);

  useEffect(() => {
    setRotateDeg(deg => deg + 6);
    return () => {
      if (rotateDegTimeoutRef.current) {
        window.clearTimeout(rotateDegTimeoutRef.current);
      }
      rotateDegTimeoutRef.current = 0;
    };
  }, []);

  useEffect(() => {
    window.clearTimeout(rotateDegTimeoutRef.current);
    rotateDegTimeoutRef.current = 0;
    if (!paused) {
      rotateDegTimeoutRef.current = window.setTimeout(() => {
        setRotateDeg(deg => deg + 6);
      }, 1000);
    }
  }, [rotateDeg]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div
        className='border-r w-5 h-5 relative'
        style={{
          left: '14px',
          top: '45px',
          transform: `rotate(${rotateDeg}deg)`,
          transformOrigin: 'bottom right',
        }}
      />
      <img src={stopwatchImage} alt='stopwatch' />
    </div>
  );
}

export default Stopwatch;
