import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { isLocalDev } from '../../utils/env';

type Breakpoint = [label: string, classNames: string];

const BreakpointDebugger = () => {
  const enabled = useFeatureEnabled('breakpoint-debugger');
  if (!isLocalDev || !enabled) {
    return null;
  }

  const breakpoints: Breakpoint[] = [
    ['xs', 'sm:hidden'],
    ['sm', 'hidden sm:inline md:hidden'],
    ['md', 'hidden md:inline lg:hidden'],
    ['lg', 'hidden lg:inline xl:hidden'],
    ['xl', 'hidden xl:inline'],
  ];

  return (
    <div className='px-2 pt-0.5 fixed z-10 bottom-0 left-0 bg-black text-white text-sm'>
      screen:{' '}
      {breakpoints.map(([breakpoint, classNames]) => {
        return <span className={classNames} key={breakpoint}>{breakpoint}</span>;
      })}
    </div>
  );
};

export default BreakpointDebugger;
