/* istanbul ignore file */
// todo: update grid to include responsive sizing with left/right sidebars
const Grid = () => (
  <>
    <h2 className='header-medium'>Grid</h2>
    <div className='styleguide__grid h-64 relative border border-gray-25 rounded-xl overflow-hidden'>
      <div className='-z-1 absolute w-full h-full'>
        <div className='grid grid-cols-4 sm:grid-cols-8 lg:grid-cols-12 gap-8 h-full'>
          <div className='bg-action-10 h-100'></div>
          <div className='bg-action-10 h-100'></div>
          <div className='bg-action-10 h-100'></div>
          <div className='bg-action-10 h-100'></div>
          <div className='hidden lg:block bg-action-10 h-100'></div>
          <div className='hidden lg:block bg-action-10 h-100'></div>
          <div className='hidden lg:block bg-action-10 h-100'></div>
          <div className='hidden lg:block bg-action-10 h-100'></div>
          <div className='hidden sm:block bg-action-10 h-100'></div>
          <div className='hidden sm:block bg-action-10 h-100'></div>
          <div className='hidden sm:block bg-action-10 h-100'></div>
          <div className='hidden sm:block bg-action-10 h-100'></div>
        </div>
      </div>
    </div>
  </>
);

export default Grid;
