/* istanbul ignore file */
import FormGroup from '../../components/ui/form/FormGroup';
import Form from '../../components/ui/form/Form';
import { isRequired, isEmail } from '../../components/ui/form/validations';
import { useState } from 'react';
import FormButton from '../../components/ui/form/FormButton';
import { Task } from '../../features/tasks/types';
import LoxStandardForm from '../../components/tasks/lox/LoxStandardForm';
import LoxCreditInquiryForm from '../../components/tasks/lox/LoxCreditInquiryForm';
import BorrowerAffirmations from '../../components/tasks/affirmations/BorrowerAffirmationsTaskBody';
import MockFormStyleguide from './components/MockFormStyleguide';

const FormExample = () => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | null>(null);

  const [name, setName] = useState<string>('');
  const [nameSubmit, setNameSubmit] = useState<string>('');
  const [nameError, setNameError] = useState<string | null>(null);
  const [nameSubmitError, setNameSubmitError] = useState<string | null>(null);
  const [textAreaText, setTextAreaText] = useState<string>('');

  const errors = [emailError, nameError].filter(Boolean);

  const handleNameChange = (evt: any) => {
    setName(evt.target.value);
  };

  const handleNameChangeSubmit = (evt: any) => {
    setNameSubmit(evt.target.value);
  };

  const handleNameValidate = (value: string) => {
    let error;
    if (!isRequired(value)) {
      setNameError('Required field');
      error = 'required';
    }

    if (!error) {
      setNameError(null);
    }

    return error;
  };
  
  const handleNameSubmitValidate = (value: string) => {
    let error;
    if (!isRequired(value)) {
      setNameSubmitError('Required field');
      error = 'required';
    }

    if (!error) {
      setNameSubmitError(null);
    }

    return error;
  };

  const handleEmailChange = (evt: any) => {
    setEmail(evt.target.value);
  };

  const handleEmailValidate = (value: string) => {
    let error;
    if (!isRequired(value)) {
      setEmailError('Required field');
      error = 'required';
    }

    if (!error && !isEmail(value)) {
      setEmailError('Invalid Email');
      error = 'invalid';
    }

    if (!error) {
      setEmailError(null);
    }

    return error;
  };

  const handleTextAreaChange = (evt: any) => {
    setTextAreaText(evt.target.value);
  };

  const validateAll = () => {
    const errors = [];
    errors.push(handleNameValidate(name));
    errors.push(handleNameSubmitValidate(nameSubmit));
    errors.push(handleEmailValidate(email));
    return errors.filter(Boolean);
  };

  const handleSubmit = (evt: any) => {
    evt.preventDefault();
    const errors = validateAll();

    if (!errors.length) {
      alert('Form Submitted');
    }
  };

  return (
    <>
      <Form method='post' noValidate={true}>
        <FormGroup
          label='Name'
          id='input_1'
          name='name'
          type='text'
          required={true}
          maxLength='128'
          onValidate={handleNameValidate}
          error={nameError}
          value={name}
          onChange={handleNameChange}
        />
        <FormGroup
          label='Name'
          id='input_2'
          name='name'
          type='text'
          required={true}
          maxLength='128'
          onValidate={handleNameValidate}
          error={nameError}
          value='Jane Smith'
          onChange={handleNameChange}
        />
        <FormGroup
          label='Name'
          id='input_3'
          name='name'
          type='text'
          required={true}
          maxLength='128'
          onValidate={handleNameValidate}
          error='A name is required'
          value=''
          onChange={handleNameChange}
        />
        <FormGroup
          label='Email'
          id='input_4'
          name='email'
          type='email'
          required={true}
          maxLength='128'
          onValidate={handleEmailValidate}
          error={emailError}
          value={email}
          onChange={handleEmailChange}
        />
        <FormGroup
          label='Email'
          id='input_5'
          name='email'
          type='email'
          required={true}
          maxLength='128'
          onValidate={handleNameValidate}
          error='An email is required'
          value='jsmithfoo@yopmail.com'
          onChange={handleEmailChange}
        />

        <FormGroup
          label='Text area'
          id='input_7'
          name='area'
          type='textarea'
          required={true}
          value={textAreaText}
          error='Text area error'
          onChange={handleTextAreaChange}
        />
        <FormGroup
          label='Text area'
          id='input_6'
          name='area'
          type='textarea'
          required={false}
          value={textAreaText}
          onChange={handleTextAreaChange}
        />
      </Form>
      <div className='header-medium my-8'>Form Submit</div>
      <Form method='post' noValidate={true} onSubmit={handleSubmit}>
        <FormGroup
          label='Name'
          id='input_1_submit'
          name='name_submit'
          type='text'
          required={true}
          maxLength='128'
          onValidate={handleNameSubmitValidate}
          error={nameSubmitError}
          value={nameSubmit}
          onChange={handleNameChangeSubmit}
        />
        <FormGroup
          label='Email'
          id='input_2_submit'
          name='email'
          type='email'
          required={true}
          maxLength='128'
          onValidate={handleEmailValidate}
          error={emailError}
          value={email}
          onChange={handleEmailChange}
        />
        <FormButton disabled={!!errors.length}>Submit</FormButton>
      </Form>
    </>
  );
};

const Forms = () => (
  <div>
    <div className='styleguide__forms mb-8'>
      <FormExample />
    </div>
    <div className='styleguide__forms mb-8'>
      <LoxStandardForm
        loanGuid='1234'
        task={{
          'task-type': 'data_lox',
          'task-description': 'Test description',
          'task-title': 'Test title',
        } as Task<any>}
      />
    </div>
    <div className='styleguide__forms mb-8'>
      <LoxCreditInquiryForm
        loanGuid='1234'
        task={{
          'task-type': 'credit_inquiry_lox',
          'task-description': 'Test credit inquiry description',
          'task-title': 'Test credit inquiry title',
          'task-explanations': [
            {
              taskExplanationId: '789',
              taskId: '1234',
              additionalData: {
                creditor: 'TEST 1',
                inquiryDate: '11/18/2022',
              },
            },
            {
              taskExplanationId: '456',
              taskId: '1234',
              additionalData: {
                creditor: 'TEST 2',
                inquiryDate: '10/15/2022',
              },
            },
          ],
        } as unknown as Task<any>}
      />
    </div>
    <div className='styleguide__forms mb-8'>
      <MockFormStyleguide />
    </div>
    <div className='styleguide__forms mb-8'>
      <BorrowerAffirmations
        loanGuid='1234'
        task={{
          'task-type': 'data_lox',
          'task-description': 'Test Borrower Affirmations',
          'task-title': 'Test title',
          'affirmation-responses': [
            {
              affirmationResponseId: '1234',
              affirmationResponseType: 'Acknowledge',
              content: {
                'en': 'TEST content english',
                'es': 'TEST content spanish',
              },
              displayOrder: 100,
              title: 'TEST title',
            },
            {
              affirmationResponseId: '7890',
              affirmationResponseType: 'Acknowledge',
              content: {
                'en': 'TEST content english',
                'es': 'TEST content spanish',
              },
              displayOrder: 90,
              title: 'TEST title',
            },
            {
              affirmationResponseId: '4567',
              affirmationResponseType: 'Reconcile',
              content: {
                'en': 'TEST content english',
                'es': 'TEST content spanish',
              },
              displayOrder: 80,
              title: 'TEST title',
            },
          ],
        } as unknown as Task<any>}
      />
    </div>
  </div>
);

export default Forms;
