import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import {
  fetchHELOCServicing,
  selectServicingFetching,
  selectServicingHasError,
  selectServicingHasFetched,
  selectServicingIdle,
  selectServicingNotFound,
} from './servicingSlice';
import { selectHELOCLoanById } from '../helocLoans/helocLoansSlice';
import { beforeServiceMacCutoffDate } from '../../utils/helocServicer';

export const useFetchHELOCServicing = (loanId: string, loanNumber: string) => {
  const dispatch = useAppDispatch();
  const servicingFetching = useAppSelector(state => selectServicingFetching(state, loanId));
  const servicingFetched = useAppSelector(state => selectServicingHasFetched(state, loanId));
  const servicingIdle = useAppSelector(state => selectServicingIdle(state, loanId));
  const servicingHasError = useAppSelector(state => selectServicingHasError(state, loanId));

  // if servicing is not found and closing date is before serviceMacCutoffDate, then loan is serviced by Figure
  const servicingNotFound = useAppSelector(state => selectServicingNotFound(state, loanId));
  const helocLoan = useAppSelector(state => selectHELOCLoanById(state, loanId));
  const servicingLoanIsFigure = servicingNotFound && helocLoan?.loanDetails.fundingDate && beforeServiceMacCutoffDate(helocLoan.loanDetails.fundingDate);

  useEffect(() => {
    !servicingFetching && !servicingFetched && !servicingHasError && dispatch(fetchHELOCServicing({ loanId, loanNumber }));
  }, [dispatch, loanId, loanNumber, servicingFetching, servicingFetched, servicingHasError]);

  return {
    servicingFetching,
    servicingFetched,
    servicingIdle,
    servicingHasError,
    servicingLoanIsFigure,
  };
};
