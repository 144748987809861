import { Redirect } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';
import { isFundedLoan } from '../../features/loans/helpers';
import type { Loan } from '../../features/loans/types';
import { log } from '../../utils/logger';
import { PATHS, linkTo, TITLES } from '../../components/routes/paths';
import { useDocumentTitleLoanPage } from '../../hooks/useDocumentTitle';
import ServicingDetailsActions from '../../components/loan/funded/ServicingDetailsActions';
import ServicingDetailsCard from '../../components/loan/funded/ServicingDetailsCard';
import ServicingError from '../../components/loan/funded/ServicingError';
import { ServicingGeneralFAQs } from '../../components/loan/funded/ServicingGeneralFAQs';
import { ServicingTransferFAQs } from '../../components/loan/funded/ServicingTransferFAQs';
import Card from '../../components/ui/card/Card';
import { Skeleton } from '../../components/ui/skeleton/Skeleton';
import { useFetchServicing } from '../../features/servicing/useFetchServicing';
import { useServicingConfig } from '../../hooks/useServicingConfig';

export const ServicingSkeleton = () => {
  return (
    <div className='flex flex-col'>
      <Card>
        <Skeleton className='mb-4 w-40 h-10' />
        <Skeleton className='mb-1 w-full h-4' />
        <Skeleton className='mb-1 w-full h-4' />
        <hr className='my-6' />
        <Skeleton className='mb-1 w-full h-4' />
        <Skeleton className='mb-1 w-full h-4' />
      </Card>
      <div className='mb-10' />
      <Skeleton className='mb-1 w-full h-20' />
    </div>
  );
};

const ServicingDetails = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid, 'loan-number': loanNumber } = loan;
  const servicingConfig = useServicingConfig(loanGuid);
  const { servicingFetching, servicingFetched, servicingIdle, servicingHasError } = useFetchServicing(loanGuid);

  useDocumentTitleLoanPage(TITLES.homeLoanServicing, loanNumber);

  // if servicing page is idle or loading for the first time - show loading page
  if (servicingIdle || (servicingFetching && !servicingFetched)) {
    return <ServicingSkeleton />;
  } else if (servicingHasError || !servicingConfig?.servicing || servicingConfig.servicing.experienceInd === 0) {
    return <ServicingError loan={loan} />;
  }

  return (
    <div className='flex flex-col'>
      <ServicingDetailsCard config={servicingConfig} />
      <div className='mb-10' />
      <ServicingDetailsActions config={servicingConfig} />
      <div className='mb-10' />
      {[3, 4, 5, 6].includes(servicingConfig.servicing.experienceInd) ? <ServicingTransferFAQs /> : <ServicingGeneralFAQs />}
    </div>
  );
};

const WithRedirect = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;

  if (!isFundedLoan(loan)) {
    log({ loanGuid, message: 'Loan does not meet servicing criteria, redirecting back to loan url' });
    return (
      <Redirect
        to={{
          pathname: linkTo(PATHS.homeLoan, { guid: loanGuid }),
        }}
      />
    );
  }

  return <ServicingDetails loan={loan} />;
};

export default withTransaction('LoanServicing', 'page-load')(WithRedirect);