import { Redirect, Route } from 'react-router-dom';
import { useIsAuth } from '../../features/auth/authSlice';
import { PrivateRouteProps } from './IRoutes';
import { PATHS } from './paths';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const isAuthenticated = useIsAuth();

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: PATHS.login,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
