import cn from 'classnames';
import { useMemo } from 'react';
import ActionBanner from '../actionBanner/ActionBanner';
import { ActionBannerProps } from '../actionBanner/iActionBanner';

const ActionBannerList = ({ actions }: { actions: ActionBannerProps[] }) => {
  const sortedActions = useMemo(() => actions.sort((a, b) => (a.priority > b.priority ? 1 : -1)), [actions]);
  return (
    <>
      {sortedActions.map((actionBannerProps: ActionBannerProps, i: number) => {
        return (
          <div key={i} className={cn({ 'mb-4': i !== sortedActions.length - 1 })}>
            <ActionBanner {...actionBannerProps} isPrimary={i === 0} />
          </div>
        );
      })}
    </>
  );
};

export default ActionBannerList;
