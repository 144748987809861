import { useState } from 'react';
import { HomeValueData } from '../../../pages/homeValue/IHomeValue';
import HomeValueEditModal from '../homeValueEditModal/HomeValueEditModal';
import Icon from '../../ui/icon/Icon';
import Modal from '../../ui/modal/Modal';
import { useAppDispatch } from '../../../hooks/hooks';
import { clearHomeValueData } from '../../../features/homeValue/homeValueSlice';
import { HomeValueUserAddress } from '../../../features/homeValue/types';
import Button from '../../ui/button/Button';

const HomeValueTitle = ({ data, address }: { data: HomeValueData; address: HomeValueUserAddress }) => {
  const dispatch = useAppDispatch();
  const resetHomeValData = () => dispatch(clearHomeValueData());
  const [showModal, setModalState] = useState(false);

  return (
    <>
      <h1 className='text-marketing-xs md:text-marketing-md mb-4'>Whoa! Check out that home&nbsp;value.</h1>
      <div className='flex flex-col sm:flex-row mb-8'>
        <p className='text-xl'>{address.metadata['formatted-address-with-unit']} </p>
        <Button
          onClick={() => setModalState(true)}
          buttonType='tertiary'
          title='Edit your home value address'
          size='large'
          text='Edit'
          iconPlacement='right'
          className='ml-0 sm:ml-3 mt-1 sm:mt-0 self-start sm:self-auto'
          icon={<Icon name='edit' size='1.25rem' />}
        />
      </div>
      <Modal open={showModal} onRequestClose={() => setModalState(false)} contentLabel='Edit Address Modal'>
        <HomeValueEditModal onConfirm={resetHomeValData} onClose={() => setModalState(false)} />
      </Modal>
    </>
  );
};

export default HomeValueTitle;
