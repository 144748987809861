import { sendEvent as hotjarSendEvent } from './hotjar';
import { isLocalDev, isTest, debug } from '../../utils/env';

export const sendEvent = (eventType: string, eventDetails: Record<string, unknown> = {}) => {
  const data = window.digitalData;
  const trackEvent = new CustomEvent(eventType, { detail: { eventType, eventDetails, data } });

  if (debug) {
    const flag = '⏱️';
    console.log(`${flag}%c Event:`, 'color: blue', eventType, { eventType, eventDetails, data });
  } else if (isTest || !isLocalDev) {
    dispatchEvent(trackEvent);
    hotjarSendEvent(eventType);
  }
};
