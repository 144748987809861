import { IMaskInput } from 'react-imask';
import cn from 'classnames';
import { useRef } from 'react';

export interface FormDollarInputProps {
  className?: string;
  hasError?: boolean;
  [x: string]: any;
}

const FormDollarInput = (props: FormDollarInputProps) => {
  const { className, onChange, onFocus, onBlur, onAnimationStart, disabled, value, id } = props;
  const formValueRef = useRef(value);
  const ref = useRef(null);

  const onDollarAccept = (value: string) => {
    formValueRef.current = value;
    onChange?.(value);
  };

  const handleFocus = (event: FocusEvent) => {
    if (event.target && !formValueRef.current) {
      (event.target as HTMLInputElement).placeholder = '$ ';
    }
    onFocus?.(event);
  };

  const handleBlur = (event: FocusEvent) => {
    if (!formValueRef.current && (ref.current as any)?.maskRef) {
      (ref.current as any).maskRef.value = '';
      (event.target as HTMLInputElement).placeholder = '';
    }
    onBlur?.(event);
  };

  return (
    <IMaskInput
      id={id}
      ref={ref}
      mask={'$ amount'}
      blocks={{
        amount: {
          mask: Number,
          scale: 2,
          thousandsSeparator: ',',
          padFractionalZeros: true,
          radix: '.',
        }
      }}
      className={cn('form-input', className)}
      onAccept={onDollarAccept}
      onAnimationStart={onAnimationStart}
      onBlur={handleBlur}
      onFocus={handleFocus}
      disabled={disabled}
      value={value}
      unmask={true}
    />
  );
};

export default FormDollarInput;
