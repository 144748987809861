import ModalFooter from '../../ui/modal/ModalFooter';
import Button from '../../ui/button/Button';
import { DEFAULT_LANGUAGE, isoMap, isoToName } from '../../../utils/multilingual';
import { useState } from 'react';
import RadioButton from '../../ui/radioButton/RadioButton';
import { log } from '../../../utils/logger';
import { content } from '../../../features/tenant/tenant';

const LanguagePreferenceModalContent = ({
  loanGuid,
  taskId,
  onClick,
  selectedLanguage,
  appLanguage,
}: {
  loanGuid: string;
  taskId: string;
  onClick: (language: string) => void;
  selectedLanguage: string | null;
  appLanguage: string | null;
}) => {
  const [language, setLanguage] = useState(selectedLanguage || appLanguage);

  const handleRadioButtonClick = (preferredLanguage: string) => {
    setLanguage(preferredLanguage);
    log({ loanGuid, taskId, message: `languagePreferenceTask: User selected "${preferredLanguage}"` });
  };

  const taskContent = content.tasks?.languagePreference?.[appLanguage || DEFAULT_LANGUAGE];

  if (!taskContent) return null;

  return (
    <div>
      <div className='OneLinkNoTx text-center mb-4'>
        <h2 className='text-2xl'>{taskContent.title}</h2>
      </div>
      <div className='text-md'>
        <div className='OneLinkNoTx mb-4'>
          <p>{taskContent.disclaimer}</p>
        </div>
        <div className='md:justify-center flex flex-row mb-4'>
          {Object.keys(isoMap).map((isoValue: string) => (
            <div className='px-3' key={isoValue}>
              <RadioButton
                id={`language_${isoValue}`}
                value={isoValue}
                name='language'
                onClick={() => handleRadioButtonClick(isoValue)}
                defaultChecked={language === isoValue}
                label={isoToName(isoValue)}
              />
            </div>
          ))}
        </div>
      </div>
      <ModalFooter>
        <Button
          className='OneLinkNoTx'
          disabled={!language || selectedLanguage === language}
          onClick={() => language && onClick(language)}
          buttonType='primary'
          size='large'
        >
          {taskContent.buttonText}
        </Button>
      </ModalFooter>
    </div>
  );
};

export default LanguagePreferenceModalContent;
