import { getCookieValue } from "./getCookieValue";

export const appName = process.env.REACT_APP_NAME;
export const version = process.env.REACT_APP_VERSION;
export const env = process.env.REACT_APP_ENV;
export const isLocalDev = process.env.NODE_ENV === 'development';
export const isTest = process.env.NODE_ENV === 'test';
export const isDev = env === 'dev';
export const isStage = env === 'stage';
export const isProd = env === 'prod';
export const debug = (isLocalDev || !isProd) && (process.env.REACT_APP_DEBUG === 'true' || process.env.REACT_APP_LOGGER_DEBUG === 'true' || !!getCookieValue('mya_debug'));
export const profile = process.env.REACT_APP_PROFILE;

if (!profile || !['dev', 'prod'].includes(profile)) {
  throw new Error('Invalid profile defined');
}

if (!env || !['dev', 'stage', 'prod'].includes(env)) {
  throw new Error('Invalid environment defined');
}

interface InfoResponse {
  'app-name': string;
  'app-version': string;
  branch: string;
  scm: string;
}

export let scm: undefined | string;
export const getInfo = async (): Promise<InfoResponse | null> => {
  if (isTest || isLocalDev) {
    return null;
  }

  try {
    const response = await fetch('/info');
    return await response.json();
  } catch (e) {
    return null;
  }
}

getInfo().then(data => { scm = data?.scm });