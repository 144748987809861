import { LoanConfig } from '../components/loan/loanCard/ILoanCard';
import { linkTo, PATHS } from '../components/routes/paths';
import { isFundedLoan } from '../features/loans/helpers';
import { Loan } from '../features/loans/types';

export const useCESLoanConfig = (loan: Loan): LoanConfig => {
  const { 'loan-guid': loanGuid } = loan;
  const loanConfig: LoanConfig = {
    loanTypeText: 'Home Equity',
    showTracker: !isFundedLoan(loan),
    trackerValue: 50,
    actionHref: isFundedLoan(loan) ?
      linkTo(PATHS.homeLoanServicing, { guid: loanGuid }) :
      linkTo(PATHS.homeLoanOverview, { guid: loanGuid }),
    milestoneText: isFundedLoan(loan) ? 'Closed' : 'Approved',
    actionLabel: 'View loan',
  };
  return loanConfig;
};
