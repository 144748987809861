import Name from '../../interfaces/IName';
import { getBrowser, IBrowser } from '../../utils/browser';
import { debug } from '../../utils/env';
import set from '../../utils/set';
import type { Loan } from '../loans/types';

export interface DigitalData {
  browser: IBrowser;
  user?: {
    email: string;
    guid: string;
    name?: Name;
  };
  currentLoanGuid: string | null;
  currentLoan: Loan | null;
  loans?: Loan[];
  pageInfo?: {
    version?: string;
    apiVersion?: string;
    tenant?: string;
    theme?: string;
    url?: string;
    path?: string;
    referrer?: string;
  };
  taskInfo?: {
    taskId: string;
    taskType: string;
  },
  'language-preference'?: string;
}
declare global {
  interface Window {
    digitalData?: DigitalData;
  }
}

const initialState: DigitalData = {
  browser: getBrowser(),
  currentLoanGuid: null,
  currentLoan: null,
  loans: [],
};

export const setDataLayer = (path: string, value: any) => {
  const digitalData = { ...initialState, ...window.digitalData };
  window.digitalData = set(path, value, digitalData) as DigitalData;
  if (debug) {
    const flag = '⏱️';
    console.log(`${flag}%c DataLayer:`, 'color: blue', 'DataLayer Updated', { path, value }, window.digitalData);
  }
};
