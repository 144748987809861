import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import useIsMounted from '../../hooks/useIsMounted';
import {
  fetchLoan,
  fetchLoanFeatures,
  fetchLoanRealtor,
  fetchLoans,
  selectLoanEntity,
  selectLoanFeaturesEntity,
  selectLoanRealtorEntity,
  selectLoansEntity,
} from './loansSlice';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';

export const useLoans = ({ refetch, refetchOnError }: { refetch?: boolean; refetchOnError?: boolean } = {}) => {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  const entityState = useAppSelector(selectLoansEntity);

  const { isFetching, hasData, hasError } = entityState;

  const shouldFetch = useRef(!isFetching && (refetch || (!hasData && (!hasError || refetchOnError))));

  useEffect(() => {
    if (isMounted() && shouldFetch.current) {
      dispatch(fetchLoans());
    }
  }, [dispatch, isMounted]);

  return entityState;
};

export const useLoan = (guid: string, { refetch, refetchOnError }: { refetch?: boolean; refetchOnError?: boolean } = {}) => {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  const entityState = useAppSelector(state => selectLoanEntity(state, guid));
  const { isFetching, hasData, hasError } = entityState;

  const { hasData: hasLoansData, data } = useAppSelector(state => selectLoansEntity(state));
  const canFetch = !hasLoansData || data?.includes(guid);

  const shouldFetch = useRef(canFetch && !isFetching && (refetch || (!hasData && (!hasError || refetchOnError))));

  useEffect(() => {
    if (isMounted() && shouldFetch.current) {
      dispatch(fetchLoan(guid));
    }
  }, [dispatch, guid, isMounted]);

  return entityState;
};

export const useLoanFeatures = (
  guid: string,
  { refetch, refetchOnError }: { refetch?: boolean; refetchOnError?: boolean } = {},
) => {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  const entityState = useAppSelector(state => selectLoanFeaturesEntity(state, guid));
  const { isFetching, hasData, hasError } = entityState;

  const shouldFetch = useRef(!isFetching && (refetch || (!hasData && (!hasError || refetchOnError))));

  useEffect(() => {
    if (isMounted() && shouldFetch.current) {
      dispatch(fetchLoanFeatures(guid));
    }
  }, [dispatch, guid, isMounted]);

  return entityState;
};

export const useLoanRealtor = (guid: string, { refetch, refetchOnError }: { refetch?: boolean; refetchOnError?: boolean } = {}) => {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  const entityState = useAppSelector(state => selectLoanRealtorEntity(state, guid));
  const { isFetching, hasData, hasError } = entityState;

  const shouldFetch = useRef(
    useFeatureEnabled('realtor-info') && !isFetching && (refetch || (!hasData && (!hasError || refetchOnError))),
  );

  useEffect(() => {
    if (isMounted() && shouldFetch.current) {
      dispatch(fetchLoanRealtor(guid));
    }
  }, [dispatch, guid, isMounted]);

  return entityState;
};
