import { SocialShareConfigs } from '../components/social/ISocialShareConfig';
import { TwitterShareProps } from '../components/social/ITwitterShareProps';
import themeConfig from '../features/tenant/tenant';

const useTwitterShare = (configKey: SocialShareConfigs): TwitterShareProps | undefined => {
  const socialSharingContent = themeConfig.content.socialSharing;
  if (!socialSharingContent) {
    return undefined;
  }
  return socialSharingContent[configKey]?.twitter;
};

export default useTwitterShare;
