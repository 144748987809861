import { content } from "../features/tenant/tenant";

export const useDefaultSupport = () => {
  return {
    name: 'support',
    email: content.supportEmail,
    phone: content.supportPhone,
    phoneOption: content.supportPhoneOption,
  };
}
