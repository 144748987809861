import { LoanTypeBadge, MilestoneBadge } from '../../../components/loan/milestone/MilestoneComposite';
import { taskTitleTypes } from '../../../components/tasks/task/ITaskTitleBadge';
import TaskTitleBadge from '../../../components/tasks/task/TaskTitleBadge';
import { textBadgeTypes } from '../../../components/ui/badge/ITextBadge';
import TextBadge from '../../../components/ui/badge/TextBadge';
import type { Loan } from '../../../features/loans/types';
import { LoanMilestones } from '../../../features/loans/types';
import { useHomeLoanConfig } from '../../../hooks/useHomeLoanConfig';

const MockMilestoneBadge = ({ loan }: { loan: Loan }) => <MilestoneBadge text={useHomeLoanConfig(loan).milestoneText as string} />;

const Badges = () => {
  const mockLoan = {
    'loan-milestone': 'application',
    'application-status': 'completed',
  } as Loan;

  return (
    <>
      <div className='badges-styleguide'>
        <div className='flex flex-row gap-5'>
          <div>
            <div className='header-medium  mb-6'>Text Badges</div>
            <ul>
              {textBadgeTypes.map(type => (
                <li className='mb-4' key={`mock_${type}`}>
                  <TextBadge type={type} text={type} />
                </li>
              ))}
            </ul>
          </div>
          <div>
            <div className='header-medium mb-6'>Milestone Badges</div>
            <ul>
              {Object.values(LoanMilestones).map(status => (
                <li className='mb-4' key={`mock_${status}`}>
                  <MockMilestoneBadge loan={{ ...mockLoan, 'loan-milestone': status } as Loan} />
                </li>
              ))}
            </ul>
          </div>
          <div>
            <div className='header-medium mb-6'>Loan Type Badges</div>
            <ul>
              <li className='mb-4'>
                <LoanTypeBadge text='Purchase' />
              </li>
              <li className='mb-4'>
                <LoanTypeBadge text='Refinance' />
              </li>
              <li className='mb-4'>
                <LoanTypeBadge text='HELOC' />
              </li>
              <li className='mb-4'>
                <LoanTypeBadge text='Personal loan' />
              </li>
            </ul>
          </div>
          <div>
            <div className='header-medium mb-6'>Task Title Badges</div>
            <ul>
              {taskTitleTypes.map(badgeType => (
                <li className='mb-4' key={`mock_${badgeType}`}>
                  <TaskTitleBadge badgeType={badgeType} title='Task title' />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Badges;
