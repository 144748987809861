import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AddressProps } from '../../components/ui/address/IAddress';
import { RootState } from '../../store/types';
import api from '../api';
import { ServicerAddress, Servicing, ServicingData, ServicingSsoEntity, ServicingState } from './types';
import { AxiosError } from 'axios';
import { RequestError, RequestRejectValue } from '../../interfaces/IRequest';
import { rejectValue } from '../requestHelper';

const namespace = 'servicing';

const initialData: ServicingData = {
  servicing: null,
  metaData: {
    loadingStatus: 'idle',
    hasError: false,
    hasFetched: false,
  },
};

const initialSsoData: ServicingSsoEntity = {
  data: undefined,
  metaData: {
    loadingStatus: 'idle',
    hasError: false,
    hasFetched: false,
  },
};

const initialState: ServicingState = {
  byLoanGuid: {},
  ssoByLoanGuid: {},
};

export const fetchServicing = createAsyncThunk<Servicing, string>(
  `${namespace}/fetchServicing`,
  async loanGuid => {
    const resp = await api.getV2Servicing(loanGuid);
    return resp.data;
  },
  {
    condition: (loanGuid, { getState }) => {
      const fetching = selectServicingFetching(getState() as RootState, loanGuid);
      return !fetching;
    },
  },
);

export const fetchHELOCServicing = createAsyncThunk<Servicing, { loanId: string; loanNumber: string }, { rejectValue: RequestRejectValue }>(
  `${namespace}/fetchHELOCServicing`,
  async ({ loanId, loanNumber }, { rejectWithValue }) => {
    try {
      const resp = await api.getHELOCServicing(loanId, loanNumber);
      return resp.data;
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      if (!error.response) {
        throw err
      }
      return rejectWithValue(rejectValue(error));
    }
  },
  {
    condition: ({ loanId }, { getState }) => {
      const fetching = selectServicingFetching(getState() as RootState, loanId);
      return !fetching;
    },
  },
);

const convertServicerAddress = (name: string, address: ServicerAddress): AddressProps => {
  return {
    name,
    'street-address': address.street,
    city: address.city,
    state: address.state,
    'postal-code': address.postalCode,
  };
};

const servicingStatePending = (state: ServicingState, key: string) => {
  state.byLoanGuid[key] = {
    ...initialData,
    ...state.byLoanGuid[key],
  };
  state.byLoanGuid[key].metaData = {
    ...initialData.metaData,
    ...state.byLoanGuid[key].metaData,
    loadingStatus: 'pending',
    hasError: false,
  };
};

const servicingStateFullfilled = (state: ServicingState, key: string, servicing: Servicing) => {
  // convert servicing addresses
  if (servicing.servicer?.address) {
    servicing.servicer.address = convertServicerAddress(
      servicing.servicer.name,
      servicing.servicer.address as ServicerAddress,
    );
  }
  if (servicing.newServicer?.address) {
    servicing.newServicer.address = convertServicerAddress(
      servicing.newServicer.name,
      servicing.newServicer.address as ServicerAddress,
    );
  }

  state.byLoanGuid[key].servicing = { ...servicing };
  state.byLoanGuid[key].metaData.loadingStatus = 'success';
  state.byLoanGuid[key].metaData.hasFetched = true;
};

const servicingStateRejected = (state: ServicingState, key: string) => {
  state.byLoanGuid[key] = {
    ...initialData,
    ...state.byLoanGuid[key],
  };
  state.byLoanGuid[key].metaData = {
    ...initialData.metaData,
    ...state.byLoanGuid[key].metaData,
    loadingStatus: 'failed',
    hasFetched: true,
    hasError: true,
  };
};

export const servicingSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchServicing.pending, (state, action) => {
        const loanGuid = action.meta.arg;
        servicingStatePending(state, loanGuid);
      })
      .addCase(fetchHELOCServicing.pending, (state, action) => {
        const loanId = action.meta.arg.loanId;
        servicingStatePending(state, loanId);
      })
      .addCase(fetchServicing.fulfilled, (state, { payload, meta }) => {
        const servicing = { ...payload };
        const loanGuid = meta.arg;
        servicingStateFullfilled(state, loanGuid, servicing);
      })
      .addCase(fetchHELOCServicing.fulfilled, (state, { payload, meta }) => {
        const servicing = { ...payload };
        const loanId = meta.arg.loanId;
        servicingStateFullfilled(state, loanId, servicing);
      })
      .addCase(fetchServicing.rejected, (state, action) => {
        const loanGuid = action.meta.arg;
        servicingStateRejected(state, loanGuid);
      })
      .addCase(fetchHELOCServicing.rejected, (state, action) => {
        const loanId = action.meta.arg.loanId;
        servicingStateRejected(state, loanId);
        if (action.payload?.status === 404) {
          state.byLoanGuid[loanId].metaData.notFound = true;
        }
      })
  },
});

const selectSelf = (state: RootState) => state.servicing;

export const selectServicingEntity = (state: RootState, loanGuid: string) =>
  selectSelf(state).byLoanGuid?.[loanGuid] || initialData;

export const selectServicing = (state: RootState, loanGuid: string) => selectServicingEntity(state, loanGuid).servicing;

export const selectServicingMeta = (state: RootState, loanGuid: string) => selectServicingEntity(state, loanGuid).metaData;

export const selectServicingFetching = (state: RootState, loanGuid: string): boolean =>
  selectServicingMeta(state, loanGuid).loadingStatus === 'pending';

export const selectServicingHasFetched = (state: RootState, loanGuid: string): boolean =>
  selectServicingMeta(state, loanGuid).hasFetched;

export const selectServicingHasError = (state: RootState, loanGuid: string): boolean =>
  selectServicingMeta(state, loanGuid).loadingStatus === 'failed';

export const selectServicingIdle = (state: RootState, loanGuid: string): boolean =>
  selectServicingMeta(state, loanGuid).loadingStatus === 'idle';

export const selectServicingSsoEntity = (state: RootState, loanGuid: string) =>
  selectSelf(state).ssoByLoanGuid[loanGuid] || initialSsoData;

export const selectServicingSsoEntityData = (state: RootState, loanGuid: string) => selectServicingSsoEntity(state, loanGuid).data;

export const selectServicingNotFound = (state: RootState, loanGuid: string) => !!selectServicingMeta(state, loanGuid).notFound;

export default servicingSlice.reducer;
