import { api } from '../features/api';
import { isLocalDev, isTest, debug } from '../utils/env';
import { getBrowser } from '../utils/browser';
import { getAppSessionIdFromStore, getAppLanguageFromStore } from '../features/app/getAppFromStore';
import { getAuthFromStore } from '../features/auth/getAuthFromStore';

export type LoggerMessage = string;
export type LoggerLevel = 'info' | 'debug' | 'warn' | 'error' | 'critical';

export const getErrorLogLevel = (error: Error): LoggerLevel => {
  if (error.message?.includes('UET is not defined')) {
    return 'info';
  }

  if (error.message?.includes('chrome-extension')) {
    return 'info';
  }

  return 'error';
};

export const log = ({
  level,
  loanGuid,
  taskId,
  message,
  requestId,
  correlationId,
  socketId,
  cfRay,
  error,
  isAxiosError = false,
}: {
  level?: LoggerLevel;
  loanGuid?: string;
  taskId?: string;
  message: LoggerMessage;
  requestId?: string;
  correlationId?: string;
  socketId?: string;
  cfRay?: string;
  error?: Error;
  isAxiosError?: boolean;
}) => {
  const loggedMessage = error ? `${message} - ${error}` : message;
  level = level || (error ? getErrorLogLevel(error) : 'info');

  const uri = `${window.location.pathname}${window.location.search}${window.location.hash}`;

  if (isAxiosError) return; // no need for duplicate logs if axios error

  const clientSessionId = getAppSessionIdFromStore();
  const appLanguage = getAppLanguageFromStore();
  const { lastValidated, isAuth, sessionId, userEmail, userId } = getAuthFromStore();

  const params = {
    level,
    uri,
    browser: getBrowser(),
    ...(appLanguage && { language: appLanguage }),
    clientSessionId,
    ...(error?.stack && { stacktrace: error?.stack }),
    ...(requestId && { requestId }),
    ...(correlationId && { correlationId }),
    ...(socketId && { socketId }),
    ...(cfRay && { cfRay }),
    ...(loanGuid && { loanGuid }),
    ...(taskId && { taskId }),
    ...(!!lastValidated && { auth: { isAuth, sessionId, userEmail, userId } }),
  };

  if (isTest || debug) {
    const flag = ['warn', 'critical'].includes(level) ? '📙' : level === 'error' ? '📕' : level === 'debug' ? '📘' : '📗';

    const args = [`${flag}%c Logger:`, 'color: blue', loggedMessage, params];
    switch (level) {
      case 'info':
        window.console.info.apply(console, args);
        break;
      case 'debug':
        window.console.debug.apply(console, args);
        break;
      case 'warn':
        window.console.info.apply(console, args);
        break;
      case 'error':
        window.console.info.apply(console, args);
        break;
      case 'critical':
        window.console.info.apply(console, args);
        break;
    }
    error && console.error(message, error);
  }
  if (!isTest && !isLocalDev) {
    api.postLog({ message: loggedMessage, ...params });
  }
};
