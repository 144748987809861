import { PAGES } from '../../components/routes/paths';
import { LoanMilestones } from '../loans/types';

export interface ArticleQueryParams {
  page: PAGES;
  loanMilestone: LoanMilestones;
  language: string;
  loanType: LoanType;
}

export interface Article {
  title: string;
  uid: string;
  updatedAt: string;
  updatedBy: string;
  version: number;
  body: string;
  language: string;
  pages: PAGES[];
  loanMilestones: LoanMilestones[];
}

export interface ArticlesEntity {
  isFetching: boolean;
  hasData: boolean;
  hasError: boolean;
  data: Article[];
}

export enum LoanType {
  MORTGAGE = 'mortgage',
  HELOC = 'heloc',
}

export const articleLinksType = ['all', 'funded', 'noLoans', 'loanInProcess'] as const;
export type ArticleLinksType = typeof articleLinksType[number];

export interface ArticleLink {
  title: string;
  subtitle?: string;
  url: string;
  imageLink: string;
}

export interface ManagedContentState {
  articlesMap: Record<LoanType, Record<LoanMilestones, Record<PAGES, ArticlesEntity>>>;
  articleLinksMap: Record<ArticleLinksType, ArticleLink[] | undefined>;
}

export interface ArticleLinksQueryParams {
  scenario: ArticleLinksType;
}
