import { useParams } from 'react-router-dom';
import { ILoanRouteParams } from '../../../interfaces/IRouteParams';
import checklistEmpty from '../../../images/checklist-empty.svg';
import useLoanSupport from '../../../hooks/useLoanSupport';
import { EmailButton } from '../../ui/button/EmailButton';
import { PhoneButton } from '../../ui/button/PhoneButton';

const TasklistError = () => {
  const { guid } = useParams<ILoanRouteParams>();
  const support = useLoanSupport(guid);

  return (
    <div className='flex flex-col items-center'>
      <img className='mb-4' src={checklistEmpty} alt='' width='70' height='98' />
      <h2 className='text-subheadline mb-4'>Your tasks are not loading.</h2>
      <p className='text-center'>
        Please contact <span className='OneLinkNoTx'>{support.name}</span> at{' '}
        <EmailButton buttonType='inline' email={support.email} className='OneLinkNoTx' /> or{' '}
        <PhoneButton buttonType='inline' phone={support.phone} /> for assistance.
      </p>
    </div>
  );
};

export default TasklistError;
