import { useEffect, useRef, useState } from 'react';
import { Subject } from 'rxjs';
import { useAppDispatch } from '../../hooks/hooks';
import { log } from '../../utils/logger';
import ConfirmationModalContent from '../ui/modal/ConfirmationModalContent';
import Modal from '../ui/modal/Modal';
import { updateCobrowseInSession } from '../../features/app/appSlice';
import { content } from '../../features/tenant/tenant';
import Button from '../ui/button/Button';
import useCobrowse from '../../hooks/useCobrowse';

/* eslint-disable @typescript-eslint/no-var-requires */
const cobrowse = require('cobrowse-sdk-js');

export const cobrowseSubject: Subject<boolean> = new Subject();

const Cobrowse = () => {
  const { enabled } = useCobrowse();
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [cobrowseCode, setCobrowseCode] = useState<string>();
  const [cobrowseError, setCobrowseError] = useState(false);
  const [showSessionControl, setShowSessionControl] = useState(false);
  const consentPromiseRef = useRef<(value: boolean) => void>();
  const currentSessionRef = useRef<any>();
  const currentSessionStateRef = useRef(null);
  const sessionTimeoutRef = useRef<number>();

  useEffect(() => {
    const subscription = cobrowseSubject.subscribe(_data => {
      log({ level: 'info', message: 'Initializing co-browse session' });
      initializeCobrowse();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initializeCobrowse = () => {
    if (!enabled || !content.cobrowse?.license) {
      return;
    }

    setShowConsent(false);
    setCobrowseError(false);

    cobrowse.license = content.cobrowse.license;

    const digitalData = window.digitalData;
    cobrowse.customData = {
      user_email: digitalData?.user?.email,
      user_id: digitalData?.user?.guid,
      user_name: digitalData?.user?.name?.full,
      language: digitalData?.['language-preference'],
      'device_name': `${digitalData?.user?.name?.first}'s ${digitalData?.browser.os} ${digitalData?.browser.name}`,
      company: digitalData?.pageInfo?.tenant,
      version: digitalData?.pageInfo?.version,
      apiVersion: digitalData?.pageInfo?.apiVersion,
    };

    // deny remote control
    cobrowse.confirmRemoteControl = () => {
      return new Promise((resolve) => {
        resolve(false);
      });
    };

    // deny full device
    cobrowse.confirmFullDevice = () => {
      return new Promise((resolve) => {
        resolve(false);
      });
    };

    // custom consent dialog
    cobrowse.confirmSession = () => {
      return new Promise((resolve) => {
        setShowConsent(true);
        consentPromiseRef.current = resolve;
      });
    };

    cobrowse.showSessionControls = () => {
      setShowSessionControl(true);
    };

    cobrowse.hideSessionControls = () => {
      setShowSessionControl(false);
    };

    cobrowse.start({ register: false })
      .then(() => cobrowse.client())
      .then(() => cobrowse.createSessionCode())
      .then((code: string) => {
        log({ level: 'info', message: `Received co-browse code ${code}` });
        setShowModal(true);
        setCobrowseCode(code);
        // timeout the code in 60 seconds
        sessionTimeoutRef.current = window.setTimeout(() => {
          sessionTimeoutRef.current = undefined;
          endSession();
        }, 60000);
      })
      .catch((error: any) => {
        log({ level: 'error', message: `Co-browse failed ${error}` });
        setCobrowseError(true);
      });

    cobrowse.on('session.loaded', (session: any) => {
      currentSessionRef.current = session;
    });

    cobrowse.on('session.updated', (session: any) => {
      const agent = session.agent();
      const newSessionState = session?.state();
      if (newSessionState && newSessionState !== currentSessionStateRef.current && ['authorizing', 'active', 'ended'].includes(newSessionState)) {
        currentSessionStateRef.current = newSessionState;
        log({
          level: 'info',
          message: `Co-browse session ${session?.state()} code: ${cobrowseCode} agent: ${agent && JSON.stringify(agent)}`
        });
      }
      if (session?.state() === 'active') {
        if (sessionTimeoutRef.current) {
          window.clearTimeout(sessionTimeoutRef.current);
          sessionTimeoutRef.current = undefined;
        }
        dispatch(updateCobrowseInSession(true));
        setShowModal(false);
      } else if (session?.state() === 'ended') {
        dispatch(updateCobrowseInSession(false));
      }
    });
  };

  const onDecline = () => {
    consentPromiseRef.current?.(false);
    closeModal();
  };

  const onAccept = () => {
    consentPromiseRef.current?.(true);
    closeModal();
  };

  const endSession = () => {
    currentSessionRef.current?.end();
    closeModal();
  };

  const closeModal = () => {
    setShowModal(false);
    setCobrowseError(false);
  };

  return (
    <>
      <Modal
        open={showModal}
        contentLabel='Share screen code'
        onRequestClose={endSession}
      >
        <ConfirmationModalContent
          title={
            showConsent ? 'Support request' :
            cobrowseCode ? `Your code is ${cobrowseCode}` :
            cobrowseError ? 'Share screen error' : undefined
          }
          text={
            showConsent ? 'A support agent would like to temporarily use this web page with you. Do you want to allow this?' :
            cobrowseCode ? `Share this code with a loan team member to work together.` :
            cobrowseError ? 'Cannot start share screen session. Please try again later.' : undefined
          }
          confirmButtonText={ showConsent ? 'Decline' : undefined }
          onConfirm={ showConsent ? onDecline : undefined }
          cancelButtonText={ showConsent ? 'Accept' : 'Back' }
          onCancel={ showConsent ? onAccept : endSession }
        />
      </Modal>
      {showSessionControl && <div className='flex w-full justify-center'>
        <Button
          className='fixed bottom-4 z-10'
          text='End share screen session'
          buttonType='primary'
          onClick={endSession}
        />
      </div>}
    </>
  );
};

export default Cobrowse;
