import { useEffect, useRef } from 'react';
import type { Loan } from '../../../features/loans/types';
import { usdAmount } from '../../../utils/amount';
import { formatPercent } from '../../../utils/percent';
import Alert from '../../ui/alert/Alert';
import Card from '../../ui/card/Card';
import { IDataItem } from '../../ui/datalist/dataItem/IDataItem';
import { DetailsCardContent } from '../detailsCard/DetailsCard';
import LoanNumberTooltip from '../detailsCard/LoanNumberTooltip';
import { ServicingStatus } from './ServicingDetailsCard';
import { ServicingGeneralFAQs } from './ServicingGeneralFAQs';
import { log } from '../../../utils/logger';
import { getHELOCLoanTermString, getLoanTermString } from '../../../utils/getLoanTermString';
import { iHELOCLoan } from '../../../features/helocLoans/types';
import { ServicingConfig } from '../../../hooks/useServicingConfig';
import { FORMAT_SHORT, formatDateWithoutTimezone } from '../../../utils/date';

interface ServicingErrorProps {
  loanNumber: string;
  loanTerm?: string;
  loanAmount?: string;
  interestRate?: string;
  firstPaymentDue?: string;
  firstPaymentAmount?: string;
}

const NA = 'Not available';

const ServicingErrorInfo = (errorProps: ServicingErrorProps)=> {
  const data: IDataItem[] = [
    {
      value: errorProps.loanNumber,
      label: 'Loan number',
      tooltip: LoanNumberTooltip,
      labelClassName: 'mb-0',
    },
    {
      value: errorProps.loanTerm || NA,
      label: 'Loan term',
      labelClassName: 'mb-0',
    },
    {
      value: errorProps.loanAmount || NA,
      label: 'Loan amount',
      labelClassName: 'mb-0',
    },
    {
      value: errorProps.interestRate || NA,
      label: 'Interest rate',
      labelClassName: 'mb-0',
    },
    {
      value: errorProps.firstPaymentDue ? formatDateWithoutTimezone(errorProps.firstPaymentDue, FORMAT_SHORT) : NA,
      label: 'First payment due',
      labelClassName: 'mb-0',
    },
    {
      value: errorProps.firstPaymentAmount ? usdAmount(errorProps.firstPaymentAmount) : NA,
      label: 'First payment amount',
      labelClassName: 'mb-0',
    }
  ];

  return (
    <DetailsCardContent className='gap-y-4' details={data} />
  );
};

const ServicingError = (
  {
    loan,
    servicingConfig,
    faqComponent,
  }: {
    loan: Loan | iHELOCLoan;
    servicingConfig?: ServicingConfig;
    faqComponent?: React.ReactNode;
  }
) => {
  const loanGuid = (loan as Loan)['loan-guid'] || (loan as iHELOCLoan).id;
  const hasLogged = useRef<boolean>(false);

  useEffect(() => {
    if (!hasLogged.current) {
      hasLogged.current = true;
      log({ loanGuid, level: 'error', message: 'Servicing error shown' });
    }
  }, [loanGuid]);

  let errorProps: ServicingErrorProps = { loanNumber: NA };
  if ((loan as Loan)['loan-guid']) {
    // loan is Loan
    const mortgageLoan = loan as Loan;
    errorProps = {
      loanNumber: mortgageLoan['loan-number'],
      loanTerm: getLoanTermString(mortgageLoan),
      loanAmount: mortgageLoan['base-loan-amount'] ? usdAmount(mortgageLoan['base-loan-amount']) : undefined,
      interestRate: mortgageLoan['interest-rate'] ? formatPercent(mortgageLoan['interest-rate']) : undefined,
      firstPaymentAmount: servicingConfig?.servicingLoan.firstPaymentAmount,
      firstPaymentDue: servicingConfig?.servicingLoan.firstPaymentDue,
    } as ServicingErrorProps;
  } else if ((loan as iHELOCLoan).id) {
    // loan is iHELOCLoan
    const helocLoan = loan as iHELOCLoan;
    errorProps = {
      loanNumber: helocLoan.originator.loanNumber,
      loanTerm: getHELOCLoanTermString(helocLoan),
      loanAmount: usdAmount(helocLoan.loanDetails.initialDrawAmount),
      interestRate: formatPercent(helocLoan.loanDetails.interestRate * 100, 3),
      firstPaymentAmount: servicingConfig?.servicingLoan.firstPaymentAmount,
      firstPaymentDue: servicingConfig?.servicingLoan.firstPaymentDue,
    } as ServicingErrorProps;
  } else {
    log({ loanGuid, level: 'error', message: 'Servicing error loan type is not recognized' });
  }

  return (
    <div className='flex flex-col'>
      <Alert type='error' showClose={false} description='Some data is currently unavailable, if the issue persists please contact support.' />
      <div className='mb-6' />
      <Card>
        <ServicingStatus title='Loan details' />
        <ServicingErrorInfo {...errorProps} />
      </Card>
      <div className='mb-10' />
      {faqComponent ? <>{faqComponent}</> : <ServicingGeneralFAQs />}
    </div>
  );
};

export default ServicingError;
