import cn from 'classnames';
import { ModalHeaderProps } from './IModal';

const ModalHeader = ({
  title,
  subtitle,
  children,
  textAlignment = 'left',
  titleId = 'modal-heading',
  className,
}: ModalHeaderProps) => (
  <div
    className={cn(
      'modal__header',
      { 'text-center': textAlignment === 'center' },
      { 'text-right': textAlignment === 'right' },
      className,
    )}
    data-ui='modal-header'
  >
    {title && (
      <h3 id={titleId} className='header-small'>
        {title}
      </h3>
    )}
    {subtitle && <div className='body-medium mt-4'>{subtitle}</div>}
    {children && <div className='mt-4'>{children}</div>}
  </div>
);

export default ModalHeader;
