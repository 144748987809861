import Address from '../../../components/ui/address/Address';

const AddressStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Address</div>
      <div>
        <ul>
          <li className='mb-4'>
            <Address
              street-address='1234 West Webster Avenue'
              street-address-2='Apt 3'
              city='Chicago'
              state='IL'
              postal-code='60614'
            />
          </li>
          <li className='mb-4'>
            <Address street-address='1234 West Webster Avenue' street-address-2='Apt 3' />
          </li>
          <li className='mb-4'>
            <Address street-address='1234 West Webster Avenue' city='Chicago' state='IL' postal-code='60614' />
          </li>
          <li className='mb-4'>
            <Address street-address='1234 West Webster Avenue' />
          </li>
          <li className='mb-4'>
            <Address city='Chicago' state='IL' postal-code='60614' />
          </li>
          <li className='mb-4'>
            <Address city='Chicago' state='IL' />
          </li>
          <li className='mb-4'>
            <Address city='Chicago' postal-code='60614' />
          </li>
          <li className='mb-4'>
            <Address name='Some Property Name' city='Chicago' state='IL' postal-code='60614' />
          </li>
          <li className='mb-4'>
            <Address name='Some Property Name' city='Chicago' state='IL' />
          </li>
          <li className='mb-4'>
            <Address name='Some Property Name' city='Chicago' postal-code='60614' />
          </li>
          <li className='mb-4'>
            <Address name='Some Property Name' state='IL' postal-code='60614' />
          </li>
          <h2 className='text-xl'>Single line</h2>
          <li className='mb-4'>
            <Address
              singleLine={true}
              street-address='1234 West Webster Avenue'
              street-address-2='Apt 3'
              city='Chicago'
              state='IL'
              postal-code='60614'
            />
          </li>
          <li className='mb-4'>
            <Address singleLine={true} street-address='1234 West Webster Avenue' street-address-2='Apt 3' />
          </li>
          <li className='mb-4'>
            <Address singleLine={true} street-address='1234 West Webster Avenue' city='Chicago' state='IL' postal-code='60614' />
          </li>
          <li className='mb-4'>
            <Address singleLine={true} street-address='1234 West Webster Avenue' />
          </li>
          <li className='mb-4'>
            <Address singleLine={true} city='Chicago' state='IL' postal-code='60614' />
          </li>
          <li className='mb-4'>
            <Address singleLine={true} city='Chicago' state='IL' />
          </li>
          <li className='mb-4'>
            <Address singleLine={true} city='Chicago' postal-code='60614' />
          </li>
          <li className='mb-4'>
            <Address singleLine={true} name='Some Property Name' city='Chicago' state='IL' postal-code='60614' />
          </li>
          <li className='mb-4'>
            <Address singleLine={true} name='Some Property Name' city='Chicago' state='IL' />
          </li>
          <li className='mb-4'>
            <Address singleLine={true} name='Some Property Name' city='Chicago' postal-code='60614' />
          </li>
          <li className='mb-4'>
            <Address singleLine={true} name='Some Property Name' state='IL' postal-code='60614' />
          </li>
        </ul>
      </div>
    </>
  );
};

export default AddressStyleguide;
