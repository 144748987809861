import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RequestError, RequestRejectValue } from '../../interfaces/IRequest';
import type { AppDispatch, RootState } from '../../store/types';
import { setDataLayer } from '../analytics/setDataLayer';
import api from '../api';
import { rejectValue, logRequestError } from '../requestHelper';
import { forceLogout } from '../auth/authSlice';
import { isFundedLoan } from './helpers';
import {
  type LoansState,
  type FetchLoansResponse,
  type FetchLoanResponse,
  type Loan,
  type LoansEntity,
  type LoanByGuidEntity,
  type LoanFeaturesByGuidEntity,
  type EntityRequest,
  type LoanTeam,
  type FetchLoanFeaturesResponse,
  AlpLoanType,
  FetchLoanRealtorResponse,
  LoanRealtorByGuidEntity,
} from './types';
import { sendEvent } from '../analytics/sendEvent';
import { log } from '../../utils/logger';

const namespace = 'loans';

const initialMetadataState: EntityRequest = {
  loadingStatus: 'idle',
  isFetching: false,
  hasData: false,
  hasError: false,
};

const initialLoanEntityState: LoanByGuidEntity = { ...initialMetadataState, data: undefined };

const initialLoanFeaturesEntityState: LoanFeaturesByGuidEntity = { ...initialMetadataState };

const initialLoanRealtorEntityState: LoanRealtorByGuidEntity = { ...initialMetadataState };

const initialLoansEntityState: LoansEntity = { ...initialMetadataState, data: undefined };

const initialState: LoansState = {
  loansByGuid: {},
  loanFeaturesByGuid: {},
  loanRealtorByGuid: {},
  loans: initialLoansEntityState,
};

const logFetchedLoans = (loans: Loan[]) => {
  const loanGuids = loans.map(loan => {
    return loan['loan-guid'];
  });
  sendEvent('loansFetched', { loanGuids });
  log({ level: 'info', message: `Fetched loans ${JSON.stringify(loanGuids)}` });
};

export const fetchLoans = createAsyncThunk<
  FetchLoansResponse,
  undefined,
  { dispatch: AppDispatch; rejectValue: RequestRejectValue }
>(
  `${namespace}/fetchLoans`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.getLoans();
      logFetchedLoans(response.data);
      return response.data;
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      logRequestError(`${namespace}/fetchLoans`, error);
      if (error.response?.status && error.response.status === 401) {
        dispatch(forceLogout(`${namespace}/fetchLoans`));
      }
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(rejectValue(error));
    }
  },
  {
    condition: (_, { getState }) => {
      const { isFetching } = selectLoansEntity(getState() as RootState);
      return !isFetching;
    },
  },
);

export const fetchLoan = createAsyncThunk<FetchLoanResponse, string, { dispatch: AppDispatch; rejectValue: RequestRejectValue }>(
  `${namespace}/fetchLoan`,
  async (loanGuid, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.getLoan(loanGuid);
      return response.data;
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      logRequestError(`${namespace}/fetchLoan`, error);
      if (error.response?.status && error.response.status === 401) {
        dispatch(forceLogout(`${namespace}/fetchLoan`));
      }
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(rejectValue(error));
    }
  },
  {
    condition: (loanGuid, { getState }) => {
      const { isFetching } = selectLoanEntity(getState() as RootState, loanGuid);
      return !isFetching;
    },
  },
);

export const fetchLoanFeatures = createAsyncThunk<
  FetchLoanFeaturesResponse,
  string,
  { dispatch: AppDispatch; rejectValue: RequestRejectValue }
>(
  `${namespace}/fetchLoanFeatures`,
  async (loanGuid, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.getLoanFeatures(loanGuid);
      return response.data;
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      logRequestError(`${namespace}/fetchLoanFeatures`, error);
      if (error.response?.status && error.response.status === 401) {
        dispatch(forceLogout(`${namespace}/fetchLoanFeatures`));
      }
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(rejectValue(error));
    }
  },
  {
    condition: (loanGuid, { getState }) => {
      const { isFetching } = selectLoanFeaturesEntity(getState() as RootState, loanGuid);
      return !isFetching;
    },
  },
);

export const fetchLoanRealtor = createAsyncThunk<
  FetchLoanRealtorResponse,
  string,
  { dispatch: AppDispatch; rejectValue: RequestRejectValue }
>(
  `${namespace}/fetchLoanRealtor`,
  async (loanGuid, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.getRealEstateAgent(loanGuid);
      return response.data;
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      logRequestError(`${namespace}/fetchLoanRealtor`, error);
      if (error.response?.status && error.response.status === 401) {
        dispatch(forceLogout(`${namespace}/fetchLoanRealtor`));
      }
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(rejectValue(error));
    }
  },
  {
    condition: (loanGuid, { getState }) => {
      const { isFetching } = selectLoanRealtorEntity(getState() as RootState, loanGuid);
      return !isFetching;
    },
  },
);

export const loansSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setLoanTasksTimedOut: (state, action: PayloadAction<string>) => {
      const guid = action.payload;
      if (state.loansByGuid[guid].data?.['tasks-ready'] !== 'ready') {
        state.loansByGuid[guid] = {
          ...initialLoanEntityState,
          ...state.loansByGuid[guid],
          data: {
            ...(state.loansByGuid[guid].data as Loan),
            'tasks-timed-out': true,
          },
        };
      }
    },
    setLoanAusTimedOut: (state, action: PayloadAction<string>) => {
      const guid = action.payload;
      if (state.loansByGuid[guid].data?.['aus-completed'] !== 'complete') {
        state.loansByGuid[guid] = {
          ...initialLoanEntityState,
          ...state.loansByGuid[guid],
          data: {
            ...(state.loansByGuid[guid].data as Loan),
            'aus-timed-out': true,
          },
        };
      }
    },
  },
  extraReducers: builder => {
    // Fetch Loans
    builder.addCase(fetchLoans.pending, state => {
      state.loans.loadingStatus = 'pending';
      state.loans.isFetching = true;
      state.loans.hasError = false;
      state.loans.error = undefined;
    });
    builder.addCase(fetchLoans.fulfilled, (state, action) => {
      state.loans.loadingStatus = 'success';
      state.loans.isFetching = false;
      state.loans.hasData = true;

      const guids: string[] = [];
      for (const loan of action.payload) {
        guids.push(loan['loan-guid']);
        // Build byLoan map dictionary (Possibly partial data)
        state.loansByGuid[loan['loan-guid']] = {
          ...initialLoanEntityState,
          data: loan,
          // TODO When loans endpoint is thinned out with partial data, change remove hasData: true
          hasData: true,
        };
      }

      state.loans.data = guids;
      setDataLayer('loans', action.payload);
    });
    builder.addCase(fetchLoans.rejected, (state, { payload, error }) => {
      state.loans.loadingStatus = 'failed';
      state.loans.isFetching = false;
      state.loans.hasError = true;
      state.loans.error = payload || error;
    });
    // Fetch Loan
    builder.addCase(fetchLoan.pending, (state, action) => {
      const guid = action.meta.arg;
      state.loansByGuid[guid] = {
        ...initialLoanEntityState,
        ...state.loansByGuid[guid],
        loadingStatus: 'pending',
        isFetching: true,
        hasError: false,
        error: undefined,
      };
    });
    builder.addCase(fetchLoan.fulfilled, (state, action) => {
      const guid = action.meta.arg;
      state.loansByGuid[guid] = {
        ...initialLoanEntityState,
        ...state.loansByGuid[guid],
        data: action.payload,
        loadingStatus: 'success',
        isFetching: false,
        hasData: true,
      };
    });
    builder.addCase(fetchLoan.rejected, (state, { meta, payload, error }) => {
      const guid = meta.arg;
      state.loansByGuid[guid] = {
        ...initialLoanEntityState,
        ...state.loansByGuid[guid],
        loadingStatus: 'failed',
        isFetching: false,
        hasError: true,
        error: payload || error,
      };
    });
    // Fetch Loan Features
    builder.addCase(fetchLoanFeatures.pending, (state, action) => {
      const guid = action.meta.arg;
      state.loanFeaturesByGuid[guid] = {
        ...initialLoanFeaturesEntityState,
        ...state.loanFeaturesByGuid[guid],
        loadingStatus: 'pending',
        isFetching: true,
        hasError: false,
        error: undefined,
      };
    });
    builder.addCase(fetchLoanFeatures.fulfilled, (state, action) => {
      const guid = action.meta.arg;
      // Updates loan data, data is combined with Loan
      state.loansByGuid[guid] = {
        ...initialLoanEntityState,
        ...state.loansByGuid[guid],
        data: {
          ...(state.loansByGuid[guid].data as Loan),
          ...action.payload,
        },
      };
      state.loanFeaturesByGuid[guid] = {
        ...initialLoanFeaturesEntityState,
        ...state.loanFeaturesByGuid[guid],
        loadingStatus: 'success',
        isFetching: false,
        hasData: true,
      };
    });
    builder.addCase(fetchLoanFeatures.rejected, (state, { meta, payload, error }) => {
      const guid = meta.arg;
      state.loanFeaturesByGuid[guid] = {
        ...initialLoanFeaturesEntityState,
        ...state.loanFeaturesByGuid[guid],
        loadingStatus: 'failed',
        isFetching: false,
        hasError: true,
        error: payload || error,
      };
    });
    // Fetch Loan Realtor
    builder.addCase(fetchLoanRealtor.pending, (state, action) => {
      const guid = action.meta.arg;
      state.loanRealtorByGuid[guid] = {
        ...initialLoanRealtorEntityState,
        ...state.loanRealtorByGuid[guid],
        loadingStatus: 'pending',
        isFetching: true,
        hasError: false,
        error: undefined,
      };
    });
    builder.addCase(fetchLoanRealtor.fulfilled, (state, action) => {
      const guid = action.meta.arg;
      // Updates loan data, data is combined with Loan
      state.loansByGuid[guid] = {
        ...initialLoanEntityState,
        ...state.loansByGuid[guid],
        data: {
          ...(state.loansByGuid[guid].data as Loan),
          team: {
            ...state.loansByGuid[guid].data?.team,
            realtor: action.payload,
          },
        },
      };
      state.loanRealtorByGuid[guid] = {
        ...initialLoanRealtorEntityState,
        ...state.loanRealtorByGuid[guid],
        loadingStatus: 'success',
        isFetching: false,
        hasData: true,
      };
    });
    builder.addCase(fetchLoanRealtor.rejected, (state, { meta, payload, error }) => {
      const guid = meta.arg;
      state.loanRealtorByGuid[guid] = {
        ...initialLoanRealtorEntityState,
        ...state.loanRealtorByGuid[guid],
        loadingStatus: 'failed',
        isFetching: false,
        hasError: true,
        error: payload || error,
      };
    });
  },
});

export const { setLoanTasksTimedOut, setLoanAusTimedOut } = loansSlice.actions;
export default loansSlice.reducer;

/* == Mortgage Selectors ================================ */

const selectSelf = (state: RootState): LoansState => state[namespace];

// Loans
export const selectLoansEntity = (state: RootState): LoansEntity => selectSelf(state).loans;
export const selectGuids = (state: RootState) => selectLoansEntity(state).data;

// Loan
export const selectLoansByGuidEntity = (state: RootState) => selectSelf(state).loansByGuid;
export const selectLoanEntity = (state: RootState, guid: string) => selectLoansByGuidEntity(state)[guid] ?? initialLoanEntityState;
export const selectLoan = (state: RootState, guid: string): Loan | undefined => selectLoanEntity(state, guid).data;

export const selectLoanTeam = createSelector(selectLoan, (loan): LoanTeam | undefined => loan?.team);
export const selectLoanVoaVendor = createSelector(selectLoan, (loan): string | undefined => loan?.['ext-features']['voa-vendor']);

// Loan Features
export const selectLoanFeaturesByGuid = (state: RootState) => selectSelf(state).loanFeaturesByGuid;
export const selectLoanFeaturesEntity = (state: RootState, guid: string) =>
  selectLoanFeaturesByGuid(state)[guid] ?? initialLoanFeaturesEntityState;

// Loan Realtor
export const selectLoanRealtorByGuid = (state: RootState) => selectSelf(state).loanRealtorByGuid;
export const selectLoanRealtorEntity = (state: RootState, guid: string) =>
  selectLoanRealtorByGuid(state)[guid] ?? initialLoanRealtorEntityState;

// Misc Selectors
export const isALoanFunded = createSelector(
  selectLoansByGuidEntity,
  loansMap => !!Object.values(loansMap).filter(loan => loan.data && isFundedLoan(loan.data)).length,
);

// select processing mortgage loan guids
export const selectProcessingLoanGuids = createSelector(selectGuids, selectLoansByGuidEntity, (guids, loansMap): string[] =>
  guids
    ? guids.filter(guid => {
        const loan = loansMap[guid]?.data;
        return loan && !isFundedLoan(loan) && !loan['alp-loan-type'];
      })
    : [],
);

// select funded mortgage loan guids
export const selectFundedLoanGuids = createSelector(selectGuids, selectLoansByGuidEntity, (guids, loansMap): string[] =>
  guids
    ? guids.filter(guid => {
        const loan = loansMap[guid]?.data;
        return loan && isFundedLoan(loan) && !loan['alp-loan-type'];
      })
    : [],
);

// select all mortgage loan guids
export const selectLoanGuids = createSelector(selectGuids, selectLoansByGuidEntity, (guids, loansMap): string[] =>
  guids
    ? guids.filter(guid => {
        const loan = loansMap[guid]?.data;
        return loan && !loan['alp-loan-type'];
      })
    : [],
);

// select processing ALP HELOC loan guids
export const selectProcessingAlpHELOCLoanGuids = createSelector(selectGuids, selectLoansByGuidEntity, (guids, loansMap): string[] =>
  guids
    ? guids.filter(guid => {
        const loan = loansMap[guid]?.data;
        return loan && !isFundedLoan(loan) && loan['alp-loan-type'] === AlpLoanType.HELOC;
      })
    : [],
);

// select funded ALP HELOC loan guids
export const selectFundedAlpHELOCLoanGuids = createSelector(selectGuids, selectLoansByGuidEntity, (guids, loansMap): string[] =>
  guids
    ? guids.filter(guid => {
        const loan = loansMap[guid]?.data;
        return loan && isFundedLoan(loan) && loan['alp-loan-type'] === AlpLoanType.HELOC;
      })
    : [],
);

// select all ALP HELOC loan guids
export const selectAlpHELOCLoanGuids = createSelector(selectGuids, selectLoansByGuidEntity, (guids, loansMap): string[] =>
  guids
    ? guids.filter(guid => {
        const loan = loansMap[guid]?.data;
        return loan && loan['alp-loan-type'] === AlpLoanType.HELOC;
      })
    : [],
);

// select processing CES guids
export const selectProcessingCESLoanGuids = createSelector(selectGuids, selectLoansByGuidEntity, (guids, loansMap): string[] =>
  guids
    ? guids.filter(guid => {
        const loan = loansMap[guid]?.data;
        return loan && !isFundedLoan(loan) && loan['alp-loan-type'] === AlpLoanType.CES;
      })
    : [],
);

// select funded CES guids
export const selectFundedCESLoanGuids = createSelector(selectGuids, selectLoansByGuidEntity, (guids, loansMap): string[] =>
  guids
    ? guids.filter(guid => {
        const loan = loansMap[guid]?.data;
        return loan && isFundedLoan(loan) && loan['alp-loan-type'] === AlpLoanType.CES;
      })
    : [],
);

// select all CES guids
export const selectCESLoanGuids = createSelector(selectGuids, selectLoansByGuidEntity, (guids, loansMap): string[] =>
  guids
    ? guids.filter(guid => {
        const loan = loansMap[guid]?.data;
        return loan && loan['alp-loan-type'] === AlpLoanType.CES;
      })
    : [],
);

export const selectProcessingMiscLoanGuids = createSelector(selectGuids, selectLoansByGuidEntity, (guids, loansMap): string[] => {
  return guids
    ? guids.filter(guid => {
        const loan = loansMap[guid].data;
        const alpLoanType = loan?.['alp-loan-type'];
        return loan && !isFundedLoan(loan) && alpLoanType && !Object.values(AlpLoanType).includes(alpLoanType);
      })
    : [];
});

export const selectFundedMiscLoanGuids = createSelector(selectGuids, selectLoansByGuidEntity, (guids, loansMap): string[] => {
  return guids
    ? guids.filter(guid => {
        const loan = loansMap[guid].data;
        const alpLoanType = loan?.['alp-loan-type'];
        return loan && isFundedLoan(loan) && alpLoanType && !Object.values(AlpLoanType).includes(alpLoanType);
      })
    : [];
});
