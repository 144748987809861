import { GetQuoteRecommendation as CastleEdgeGetQuoteRecommendation  } from "../../../components/loan/insuranceQuote/CastleEdgeQuote";
import { QuoteCTA, iSize } from "../../../components/loan/insuranceQuote/InsuranceQuote";

const InsuranceCalloutStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Insurance Callouts</div>
      {['small', 'medium', 'large'].map((x) => (
        <div key={x}>
          size: {x}
          <QuoteCTA loanGuid='1234' size={x as iSize} source='StyleGuide' location='right-rail' />
        </div>
      ))}

      <div className='header-medium mt-12 mb-8'>Castle Edge Insurance Callout</div>
      <div>
        <CastleEdgeGetQuoteRecommendation loanGuid='1324' />
      </div>
    </>
  );
};

export default InsuranceCalloutStyleguide;
