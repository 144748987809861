import Card from '../../ui/card/Card';
import { Skeleton } from '../../ui/skeleton/Skeleton';

export const MobileHomeValueSearchSkeletonLoading = () => (
  <div className='lg:hidden'>
    {/* address */}
    <div className='mb-8'>
      <Skeleton className='mb-2' style={{ maxWidth: '160px' }} />
      <Skeleton className='mb-2 h-10' />
      <Skeleton className='mb-2 h-10' />
      <Skeleton className='mb-2' />
      <Skeleton className='mb-2' style={{ maxWidth: '160px' }} />
    </div>
    {/* estimate */}
    <Card className='mb-8'>
      <Skeleton className='mb-8 mx-4' />
      <Skeleton className='mb-2 h-10' />
      <Skeleton className='mb-8' />
      <Skeleton className='mb-2 mx-auto' style={{ maxWidth: '160px' }} />
      <Skeleton className='mb-8' />
      <Skeleton className='mb-2' />
      <Skeleton className='mb-2' />
      <Skeleton className='mb-2' />
      <Skeleton className='mb-2' style={{ maxWidth: '180px' }} />
    </Card>
  </div>
);

export const DesktopHomeValueSearchSkeletonLoading = () => (
  <div className='hidden lg:block'>
    {/* address */}
    <div className='mb-8'>
      <Skeleton className='w-1/3 mb-5' />
      <Skeleton className='w-2/3 h-10 mb-5' />
      <Skeleton className='w-2/3 mb-5' />
    </div>
    {/* estimate */}
    <Card className='mb-12 px-12'>
      <div className='flex w-full justify-center mb-8'>
        <Skeleton className='w-1/3' />
      </div>
      <div className='flex w-full gap-12'>
        <div className='flex flex-col w-full'>
          <Skeleton className='mb-2 w-full h-10' />
          <Skeleton className='mb-8 w-full' />
          <Skeleton className='mb-2 mx-auto w-28' style={{ maxWidth: '120px' }} />
          <Skeleton className='mb-2 w-full' />
        </div>
        <div className='flex flex-col w-full'>
          <Skeleton className='mb-2 w-full' />
          <Skeleton className='mb-2 w-full' />
          <Skeleton className='mb-2 w-44' style={{ maxWidth: '180px' }} />
        </div>
      </div>
    </Card>
  </div>
);

const HomeValueSearchSkeletonLoading = () => {
  return (
    <div className='home-value-search-skeleton-loading'>
      <MobileHomeValueSearchSkeletonLoading />
      <DesktopHomeValueSearchSkeletonLoading />
    </div>
  );
};

export default HomeValueSearchSkeletonLoading;
