import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios, { AxiosError } from 'axios';
import api from '../api';
import {
  SET_TASKS,
  SET_TASK,
  REMOVE_TASK,
  SET_HAS_TASK_ERROR,
  SET_TASKS_ARE_LOADING,
  UPDATE_TASK,
  TASK_MODAL_TOGGLE,
  UPDATE_TASK_COMPLETED,
  SET_OTHERS_PENDING_TASKS,
} from './actionTypes';
import { iSourceType, Task, TaskPatch, TaskUpdateUpdates } from './types';
import { setGatelessConfig } from '../gateless/actions';
import { getGatelessConfigByLoanGuid } from '../gateless/selectors';
import { selectUser, selectUserLanguage, updateUser } from '../user/userSlice';
import type { RootState } from '../../store/types';
import { log } from '../../utils/logger';
import { reloadAppLanguage, selectAppLanguage } from '../app/appSlice';
import { RequestError } from '../../interfaces/IRequest';
import { forceLogout } from '../auth/authSlice';

export const fetchTasks = (
  loanGuid: string,
  onlyGateless?: boolean,
): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch, getState): Promise<void> => {
    const state = getState() as RootState;
    const hasConfig = Boolean(getGatelessConfigByLoanGuid(state, loanGuid));

    if (state.tasks.metadataByLoan[loanGuid]?.tasksLoading) {
      return;
    }

    dispatch(setTasksAreLoading(loanGuid, true));

    try {
      const promises = [];
      // TODO decouple gatelss context from fetch tasks
      (!hasConfig || onlyGateless) && promises.push(api.getGatelessContext(loanGuid));
      !onlyGateless && promises.push(api.getLoanTasks(loanGuid));

      const values = await Promise.all(promises);
      for (const value of values) {
        value.data?.['consumer-token'] && dispatch(setGatelessConfig(loanGuid, value.data));
        value.data?.tasks && dispatch(setTasks(loanGuid, value.data.tasks));
      }
      dispatch(setHasTaskError(loanGuid, false));
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      if (error.response && error.response.status === 401) {
        dispatch(forceLogout('fetchTasks'));
      } else {
        log({ level: 'error', loanGuid, message: `fetchTasks error - ${error}`, isAxiosError: axios.isAxiosError(error) });
        dispatch(setHasTaskError(loanGuid, true));
      }
    } finally {
      dispatch(setTasksAreLoading(loanGuid, false));
    }
  };
};

export const fetchTask = (
  loanGuid: string,
  taskId: string,
  source: iSourceType,
): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>): Promise<void> => {
    try {
      const resp = await api.getLoanTask(loanGuid, taskId, source);
      if (resp.data['task-removed']) {
        dispatch(removeTask(loanGuid, taskId));
      } else {
        dispatch(setTask(loanGuid, resp.data));
      }
    } catch (error) {
      log({ level: 'error', loanGuid, message: `fetchTask error - ${error}`, isAxiosError: axios.isAxiosError(error) });
      if ((error as AxiosError<RequestError>).response?.status === 404) {
        dispatch(removeTask(loanGuid, taskId));
      }
      throw error;
    }
  };
};

export const patchTask = (
  loanGuid: string,
  taskId: string,
  payload: TaskPatch<'credentialed_voie'>,
): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>): Promise<void> => {
    try {
      const resp = await api.patchLoanTask(loanGuid, taskId, payload);
      if (resp.data['task-removed']) {
        dispatch(removeTask(loanGuid, taskId));
      } else {
        dispatch(setTask(loanGuid, resp.data));
      }
    } catch (error) {
      log({ level: 'error', loanGuid, message: `patchTask error - ${error}`, isAxiosError: axios.isAxiosError(error) });
      if ((error as AxiosError<RequestError>).response?.status === 404) {
        dispatch(removeTask(loanGuid, taskId));
      }
      throw error;
    }
  };
};

export const sendConsent = (
  loanGuid: string,
  consentType: string,
  agreed: boolean,
  taskId: string,
): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>, getState: () => RootState): Promise<void> => {
    const state = getState();
    const language = selectAppLanguage(state) || 'en';
    const user = selectUser(state);
    try {
      const resp = await api.postConsent({
        loanGuid,
        consentType,
        agreed,
        name: user?.name?.['full-with-suffix'] || '',
        language,
      });
      dispatch(
        updateTask(taskId, {
          taskDetails: { 'task-completed': true },
          customTaskMeta: { consented: resp.data.agreed === true || resp.data.agreed === false, agreed: resp.data.agreed === true },
        }),
      );
    } catch (error) {
      log({ level: 'error', loanGuid, message: `sendConsent error - ${error}`, isAxiosError: axios.isAxiosError(error) });
      throw error;
    }
  };
};

export const updateLanguagePreferenceTask = ({
  loanGuid,
  taskId,
  language,
}: {
  loanGuid: string;
  taskId: string;
  language: string;
}): ThunkAction<Promise<{ requiresReload: boolean }>, RootState, undefined, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
    getState: () => RootState,
  ): Promise<{ requiresReload: boolean }> => {
    try {
      await api.postLanguagePreference(loanGuid, language);
      const state = getState();
      const userLanguage = selectUserLanguage(state);
      const appLanguage = selectAppLanguage(state);
      const requiresReload = language !== appLanguage;
      // No need to call API to update user if user's language preference already matches new task selection
      if (language !== userLanguage) {
        await dispatch(updateUser({ key: 'language-preference', value: language }));
      }
      if (requiresReload) {
        reloadAppLanguage(language);
      } else {
        dispatch(
          updateTask(taskId, {
            taskDetails: { 'task-completed': true },
            customTaskMeta: { 'language-preference': language },
          }),
        );
      }
      return {
        requiresReload,
      };
    } catch (error) {
      log({
        level: 'error',
        loanGuid,
        message: `updateLanguagePreferenceTask error - ${error}`,
        isAxiosError: axios.isAxiosError(error),
      });
      throw error;
    }
  };
};

export const fetchOthersPendingTasks = (loanGuid: string): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>): Promise<void> => {
    try {
      const resp = await api.getPendingTasks(loanGuid);
      dispatch(setOthersPendingTasks(loanGuid, resp.data));
    } catch (error) {
      log({
        level: 'error',
        loanGuid,
        message: `Failed to get pending tasks ${error}`,
      });
    }
  };
};

export const setTasks = (loanGuid: string, tasks: Task<any>[]) => {
  return {
    type: SET_TASKS,
    payload: {
      loanGuid,
      tasks,
    },
  };
};

export const setTask = (loanGuid: string, task: Task<any>) => {
  return {
    type: SET_TASK,
    payload: {
      loanGuid,
      task,
    },
  };
};

export const removeTask = (loanGuid: string, taskId: string) => {
  return {
    type: REMOVE_TASK,
    payload: {
      loanGuid,
      taskId,
    },
  };
};

export const updateTask = (taskId: string, updates: TaskUpdateUpdates = {}) => {
  return {
    type: UPDATE_TASK,
    payload: {
      taskId,
      updates,
    },
  };
};

export const setHasTaskError = (loanGuid: string, hasError: boolean) => {
  return {
    type: SET_HAS_TASK_ERROR,
    payload: {
      loanGuid,
      hasError,
    },
  };
};

export const setTasksAreLoading = (loanGuid: string, tasksLoading: boolean) => {
  return {
    type: SET_TASKS_ARE_LOADING,
    payload: {
      loanGuid,
      tasksLoading,
    },
  };
};

export const setTaskModalOpen = (taskModalOpen: boolean) => {
  return {
    type: TASK_MODAL_TOGGLE,
    payload: {
      taskModalOpen,
    },
  };
};

export const updateTaskCompleted = (taskId: string, taskCompleted: boolean) => {
  return {
    type: UPDATE_TASK_COMPLETED,
    payload: {
      taskId,
      taskCompleted,
    },
  };
};

export const setOthersPendingTasks = (loanGuid: string, pendingTasks: Task<any>[]) => {
  return {
    type: SET_OTHERS_PENDING_TASKS,
    payload: {
      loanGuid,
      pendingTasks,
    },
  };
};
