import type { Loan } from '../../../features/loans/types';
import { LoanMilestones } from '../../../features/loans/types';
import { usdAmount } from '../../../utils/amount';
import { formatAddressObject } from '../../../utils/addressFormatter';
import { User } from '../../../features/user/types';
import { useAppSelector } from '../../../hooks/hooks';
import { selectUser } from '../../../features/user/userSlice';
import usePreapprovalsForPreapprovedMilestone from '../../../hooks/usePreapprovalsForPreapprovedMilestone';
import { Skeleton } from '../../ui/skeleton/Skeleton';

const milestoneHeadlineConfig = (loan: Loan, user: User | null, preapprovedAmount?: number) => {
  const { 'loan-milestone': milestone, property, 'respa-to-ctc': timeToApproval } = loan;
  const userFirstName = user?.name?.first || '';
  const propertyAddress = formatAddressObject(property);

  switch (milestone) {
    case LoanMilestones.APPLICATION:
      return {
        title: "You're almost approved!",
        subtitle: "Let's work on getting you approved!",
      };
    case LoanMilestones.PREAPPROVAL:
      return {
        title: "You're almost pre-approved!",
        subtitle: "Let's work on getting you a pre-approval letter!",
      };
    case LoanMilestones.PREAPPROVED:
      return {
        title: "You're pre-approved!",
        subtitle: preapprovedAmount
          ? `Your purchase power is currently set to ${usdAmount(preapprovedAmount, false, false)}.`
          : undefined,
      };
    case LoanMilestones.CONDITIONAL_APPROVAL:
      return {
        title: `Let's get you approved for`,
        subtitle: `${propertyAddress}.`,
      };
    case LoanMilestones.CONDITIONALLY_APPROVED:
      return {
        title: "You're initially approved!",
        subtitle: `For ${propertyAddress}.`,
      };
    case LoanMilestones.CLEAR_TO_CLOSE:
      return {
        title: "You're cleared to close! 🥳",
        subtitle: timeToApproval
          ? `Your loan was final approved in ${timeToApproval}, when the industry standard is 30 days.`
          : "Great job, we've got everything we need. Now let's get your closing scheduled!",
      };
    case LoanMilestones.CLOSED:
      return {
        title: `Congrats${!!userFirstName ? ', ' : ''}${userFirstName}!`,
        subtitle:
          "Enjoy your new home and this new exciting chapter in your lives. If I can be of any help for you or your family and friends in the future, please don't hesitate to reach out.",
      };
  }
};

export const MilestoneHeadline = ({ loan }: { loan: Loan }) => {
  const { 'loan-milestone': milestone } = loan;
  const currentUser = useAppSelector(selectUser);
  const { latestPreapproval, loading } = usePreapprovalsForPreapprovedMilestone(loan);
  const preapprovedAmount = latestPreapproval?.letter?.data?.['purchase-price'];
  const milestoneHeadline = milestoneHeadlineConfig(loan, currentUser, preapprovedAmount);

  if (!milestoneHeadline) {
    return null;
  }

  return (
    <>
      <h1 className='text-marketing-xs md:text-marketing-md mb-2'>{milestoneHeadline.title}</h1>
      {loading && milestone === LoanMilestones.PREAPPROVED ? (
        <Skeleton className='mb-7 w-1/2' />
      ) : (
        <p className='text-xl mb-8'>{milestoneHeadline.subtitle}</p>
      )}
    </>
  );
};
