import { useFetchDocuments } from '../../../features/documents/documentsSlice';
import { useMemo } from 'react';
import { DocumentsTable } from './DocumentsTable';
import { iDocument } from '../../../features/documents/types';
import { useFetchTasks } from '../../../hooks/useFetchTasks';

const documentSorter = (desc: boolean) => (a: iDocument, b: iDocument) => {
  const aOn = Date.parse(a['date-added']);
  const bOn = Date.parse(b['date-added']);
  return desc ? bOn - aOn : aOn - bOn;
};

const Documents = ({ loanGuid }: { loanGuid: string }) => {
  // fetch tasks for the task names in uploaded documents table
  useFetchTasks(loanGuid);
  const { documents, loadingStatus, hasData } = useFetchDocuments(loanGuid);
  const loading = ['idle', 'pending'].includes(loadingStatus) && !hasData;

  const signingDocuments = useMemo(
    () => documents?.filter(doc => doc.category === 'signing-documents').sort(documentSorter(true)),
    [documents],
  );

  const uploadedDocuments = useMemo(
    () => documents?.filter(doc => doc.category === 'upload-documents').sort(documentSorter(true)),
    [documents],
  );

  return (
    <>
      <DocumentsTable
        loanGuid={loanGuid}
        type='signing-documents'
        documents={signingDocuments}
        loading={loading}
      />
      <div className='border-b border-gray-25 lg:border-0 my-5 mt-6' />
      <DocumentsTable
        className='mt-6'
        loanGuid={loanGuid}
        type='upload-documents'
        documents={uploadedDocuments}
        loading={loading}
      />
    </>
  );
};

export default Documents;
