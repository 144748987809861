import { iIconName }  from '../components/ui/icon/IIcon';
import { selectHELOCLoanById } from '../features/helocLoans/helocLoansSlice';
import { selectLoan } from '../features/loans/loansSlice';
import { selectServicing, selectServicingMeta } from '../features/servicing/servicingSlice';
import { Servicer, ServicingDetails, ServicingLoan } from '../features/servicing/types';
import { getHELOCLoanTermString, getLoanTermString } from '../utils/getLoanTermString';
import { useAppSelector } from './hooks';

export type ServicingConfig = {
  loanGuid: string;
  servicingLoan: ServicingLoan;
  servicing: ServicingDetails;
  servicer?: Servicer;
  newServicer?: Servicer;
  priorServicer?: Servicer;
  servicingTitleIcon?: iIconName;
  isHELOC: boolean;
};

export const useServicingConfig = (loanGuid: string): ServicingConfig | undefined => {
  const loan = useAppSelector(state => selectLoan(state, loanGuid));
  const servicingObject = useAppSelector(state => selectServicing(state, loanGuid));
  const servicingMeta = useAppSelector(state => selectServicingMeta(state, loanGuid));

  if (!loan || !servicingObject || !servicingObject.servicing || servicingMeta.loadingStatus !== 'success') {
    return undefined;
  }

  const servicingLoan = { ...servicingObject.loan };
  servicingLoan.loanTermString = getLoanTermString(loan);

  return {
    loanGuid: loan['loan-guid'],
    servicingLoan,
    servicing: servicingObject.servicing,
    servicingTitleIcon: [3, 4, 5, 6].includes(servicingObject.servicing.experienceInd) ? 'warning-triangle' : undefined,
    servicer: servicingObject.servicer,
    newServicer: servicingObject.newServicer,
    priorServicer: servicingObject.priorServicer,
    isHELOC: false,
  };
};

export const useHELOCServicingConfig = (loanId: string): ServicingConfig | undefined => {
  const helocLoan = useAppSelector(state => selectHELOCLoanById(state, loanId));
  const servicingObject = useAppSelector(state => selectServicing(state, loanId));

  if (!helocLoan || !servicingObject || !servicingObject.servicing) {
    return undefined;
  }

  const helocLoanDetails = helocLoan.loanDetails;
  const servicingLoan = {
    ...servicingObject.loan,
    loanNumber: helocLoan.originator.loanNumber,
    interestRate: helocLoanDetails.interestRate,
    loanTermString: getHELOCLoanTermString(helocLoan),
  };

  return {
    loanGuid: helocLoan.id,
    servicingLoan,
    servicing: servicingObject.servicing,
    servicer: servicingObject.servicer,
    isHELOC: true,
  };
};
