import React, { useEffect, useMemo, useState } from 'react';
import {
  useFetchPreapprovals,
  hasUnviewedPreapproval,
  postPreapprovalViewed,
  usePreapprovalsEnabled,
  usePreapprovalsEligible,
} from '../../../features/preapprovals/preapprovalsSlice';
import { Preapproval, PreapprovalGroup } from '../../../features/preapprovals/types';
import type { Loan } from '../../../features/loans/types';
import Sorter from '../../ui/sorter/Sorter';
import { Skeleton, SkeletonGroup } from '../../ui/skeleton/Skeleton';
import { usdAmount } from '../../../utils/amount';
import { formatDate, FORMAT_LONG_TIME, FORMAT_SINGLE_DIGIT_SHORT_YEAR } from '../../../utils/date';
import { formatAddress } from '../../../utils/addressFormatter';
import { useAppDispatch } from '../../../hooks/hooks';
import { ThunkDispatch } from 'redux-thunk';
import type { RootState } from '../../../store/types';
import { AnyAction } from 'redux';
import TextBadge from '../../ui/badge/TextBadge';
import { updateUserLoanAttribute } from '../../../features/user/userSlice';
import { sendEvent } from '../../../features/analytics/sendEvent';
import Button from '../../ui/button/Button';
import { updateGlobalLoading } from '../../../features/app/appSlice';
import { sendDocumentEventToShellApp } from '../../../features/mobileApp/shellAppEvents';
import { useMobileAppDocumentEvent } from '../../../hooks/useMobileAppDocumentEvent';

export const letterLocations = ['documents', 'tasklist'] as const;
export type iLetterLocation = typeof letterLocations[number];

// TODO - to be removed

export const handleOpenedPreapproval = (
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
  preapproval: Preapproval,
  loanGuid: string,
  location: iLetterLocation,
) => {
  const { id: letterId, 'viewed?': viewed } = preapproval;
  sendEvent('preapprovalLetterView', { location: location, loanGuid: loanGuid, letterId: letterId });
  if (!viewed) {
    dispatch(postPreapprovalViewed(loanGuid, letterId));
  }
};

const makePreapprovalRow = (item: Preapproval, loanGuid: string, mobileAppDocumentEvent: boolean, dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  const { id: letterId, letter, href } = item;
  const { data: letterData } = letter;
  const createdDate: Date = new Date(letter['created-at']);
  const viewHref = mobileAppDocumentEvent ? undefined : href;
  const downloadFilename = `preapproval-${formatDate(createdDate, FORMAT_SINGLE_DIGIT_SHORT_YEAR)}.pdf`;

  const handleClick = async () => {
    handleOpenedPreapproval(dispatch, item, loanGuid, 'documents');
    if (mobileAppDocumentEvent) {
      dispatch(updateGlobalLoading(true));
      await sendDocumentEventToShellApp('VIEW_DOCUMENT', href, downloadFilename);
      dispatch(updateGlobalLoading(false));
    } else {
      window.open(viewHref, '_blank');
    }
  };

  const handleKeypress = async (event: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (event.key && event.key !== 'Enter' && event.key !== 'Spacebar') return;
    handleOpenedPreapproval(dispatch, item, loanGuid, 'documents');
    if (mobileAppDocumentEvent) {
      dispatch(updateGlobalLoading(true));
      await sendDocumentEventToShellApp('VIEW_DOCUMENT', href, downloadFilename);
      dispatch(updateGlobalLoading(false));
    } else {
      window.open(viewHref, '_blank');
    }
  };

  return (
    <tr
      key={letterId}
      className='hover:bg-info-10 cursor-pointer'
      tabIndex={0}
      role='button'
      onClick={handleClick}
      onKeyPress={handleKeypress}
    >
      <td className='font-bold py-2 px-4 sm:text-base text-sm rounded-l-xl' title={formatDate(createdDate, FORMAT_LONG_TIME)}>{formatDate(createdDate, FORMAT_SINGLE_DIGIT_SHORT_YEAR)}</td>
      <td className='font-bold py-2 px-4 sm:text-base text-sm'>{usdAmount(letterData['purchase-price'], false)}</td>
      <td className='font-bold py-2 px-4 sm:text-base text-sm'>{usdAmount(letterData['total-loan-amount'], false)}</td>
      <td className='font-bold text-right py-2 px-4 sm:text-base text-sm rounded-r-xl'>
        <Button buttonType='tertiary' title='View Pre-approval letter' size='medium'>
          Open
        </Button>
      </td>
    </tr>
  );
};

const makePreapprovalGroup = (
  propertyAddress: string,
  preapprovals: Preapproval[],
  loanGuid: string,
  mobileAppDocumentEvent: boolean,
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
) => {
  const hasNewDocs: boolean = hasUnviewedPreapproval(preapprovals);
  return (
    <div key={propertyAddress}>
      <div className='flex'>
        <h4 className='lg:inline-block whitespace-nowrap'>{propertyAddress}</h4>
        {hasNewDocs && <TextBadge className='ml-3' type='ok-secondary' text='New!' />}
      </div>
      <table className='table-fixed mb-8 w-full'>
        <thead>
          <tr>
            <th className='w-1/4 text-left sm:text-base text-sm p-3 font-normal'>Issued date</th>
            <th className='w-1/4 text-left sm:text-base text-sm p-3 font-normal'>Purchase price</th>
            <th className='w-1/4 text-left sm:text-base text-sm p-3 font-normal'>Loan amount</th>
            <th className='w-1/4 text-left sm:text-base text-sm p-3 font-normal'>&nbsp;</th>
          </tr>
        </thead>
        <tbody>{preapprovals.map((preapproval: Preapproval) => makePreapprovalRow(preapproval, loanGuid, mobileAppDocumentEvent, dispatch))}</tbody>
      </table>
    </div>
  );
};


export const PreapprovalsSkeletons = () => {
  return (
    <div className='flex flex-col mb-8 w-full'>
      <Skeleton className='mb-5 w-full' height='20px'/>
      <SkeletonGroup className='mb-5' height='20px' columnCount={4} wrapMobile={false} />
      <SkeletonGroup className='mb-5' height='20px' columnCount={4} wrapMobile={false} />
    </div>
  );
};

export const groupPreapprovalsByAddress = (result: PreapprovalGroup, currentValue: Preapproval) => {
  const address =
    formatAddress(
      currentValue?.letter?.data?.['property-address'] === 'PREQUALIFICATION'
        ? ''
        : currentValue?.letter?.data?.['property-address'],
      currentValue?.letter?.data?.['property-city'],
      currentValue?.letter?.data?.['property-state'],
    ) || 'Other';
  (result[address] = result[address] || []).push(currentValue);
  return result;
};

const preapprovalsSorter = (desc: boolean) => (a: Preapproval, b: Preapproval) => {
  const aOn = a?.letter['created-at'];
  const bOn = b?.letter['created-at'];
  return desc ? bOn - aOn : aOn - bOn;
};

const PreapprovalsDocuments = ({ loan }: { loan: Loan }) => {
  const dispatch = useAppDispatch();
  const { 'loan-guid': loanGuid } = loan;
  const {
    data: preapprovalsUnsorted,
    hasData: hasPreapprovalsData,
    hasError: hasPreapprovalsError,
  } = useFetchPreapprovals(loanGuid);
  const isLoanEligible = usePreapprovalsEligible(loanGuid);
  const preapprovalEnabled = usePreapprovalsEnabled(loanGuid);
  const shouldShowPreapprovals = preapprovalEnabled && isLoanEligible;
  const mobileAppDocumentEvent = useMobileAppDocumentEvent();

  useEffect(() => {
    if (hasPreapprovalsData) {
      dispatch(updateUserLoanAttribute({ loanGuid, value: { 'docs-last-viewed': Date.now() } }));
    }
  }, [dispatch, loanGuid, hasPreapprovalsData]);

  const [preapprovalsDesc, setPreapprovalsDesc] = useState(true);
  const preapprovals = useMemo(
    () => [...preapprovalsUnsorted].sort(preapprovalsSorter(preapprovalsDesc)),
    [preapprovalsUnsorted, preapprovalsDesc],
  );
  const preapprovalGroups = useMemo(() => preapprovals.reduce(groupPreapprovalsByAddress, {}), [preapprovals]);
  const hasNewDocs: boolean = hasUnviewedPreapproval(preapprovals);

  useEffect(() => {
    hasNewDocs && sendEvent('newDocsBadgeShownDocumentsPage', { loanGuid });
  }, [loanGuid, hasNewDocs]);

  // Loan conditionals for displaying preapprovals
  if (!shouldShowPreapprovals) {
    return null;
  }

  // Don't show preapprovals if no letters exist after fetching
  if (hasPreapprovalsError || (hasPreapprovalsData && !preapprovals.length)) {
    return null;
  }

  return (
    <div className='border-b border-gray-25 mb-8'>
      <div className='flex flex-row justify-between items-end'>
        <div className='text-2xl font-bold'>
          Pre-approval<span className='inline sm:hidden'>s</span> <span className='sm:inline hidden'>letters</span>
        </div>
        {hasPreapprovalsData && <Sorter label="Date" desc={preapprovalsDesc} onClick={() => setPreapprovalsDesc(!preapprovalsDesc)} />}
      </div>
      {!hasPreapprovalsData ? (
        <PreapprovalsSkeletons />
      ) : (
        <>
          <div className='mt-4 mb-8'>Contact your loan officer if you want to update your letter.</div>
          {Object.keys(preapprovalGroups).map((key: string) =>
            makePreapprovalGroup(key, preapprovalGroups[key], loanGuid, mobileAppDocumentEvent, dispatch),
          )}
        </>
      )}
    </div>
  );
};

export default PreapprovalsDocuments;
