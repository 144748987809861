import Card from '../../../ui/card/Card';
import { EmailPhoneWidget } from './LoanOfficerCardModalContent';

export type CustomerCareCardProps = {
  email: string;
  phone: string;
  phoneOption?: string;
};

const CustomerCareCard = ({ email, phone, phoneOption }: CustomerCareCardProps) => {
  return (
    <Card className='bg-white w-fit-content p-0 md:p-6' dropShadow={false}>
      <div className='flex flex-col md:flex-row'>
        <div className='mt-4 md:mt-0'>
          <EmailPhoneWidget email={email} phone={phone} phoneSuffix={phoneOption} modalName='customerCare' />
        </div>
      </div>
    </Card>
  );
};

export const CustomerCareCardModalContent = (supportContact: CustomerCareCardProps) => {
  return (
    <div className='flex flex-col items-center mb-1'>
      <p className='text-center text-marketing-xs md:text-marketing-md self-center mb-4'>We're here to help.</p>
      <p className='text-center mb-8'>Contact Customer Care:</p>
      <CustomerCareCard {...supportContact} />
    </div>
  );
};
