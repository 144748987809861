import { sendEvent } from '../../../../features/analytics/sendEvent';
import type { LoanOfficer } from '../../../../features/loans/types';
import Avatar, { defaultMarginTop } from '../../../ui/avatar/Avatar';
import { EmailButton } from '../../../ui/button/EmailButton';
import { PhoneButton } from '../../../ui/button/PhoneButton';
import Card from '../../../ui/card/Card';

type EmailPhoneModalName = 'loCard' | 'customerCare';

export const EmailPhoneWidget = ({ email, phone, phoneSuffix, modalName }: { email?: string; phone?: string; phoneSuffix?: string; modalName: EmailPhoneModalName }) => {
  const handleEmailEvent = () => sendEvent(`${modalName} EmailClick`, { email });
  const handlePhoneEvent = () => sendEvent(`${modalName} PhoneClick`, { phone });
  return (
    <>
      {email || phone ? (
        <div className='flex flex-col h-full'>
          {email && (
            <div>
              <EmailButton
                showIcon={true}
                buttonType='tertiary'
                size='small'
                email={email}
                data-qa='person-email'
                className='OneLinkNoTx'
                onClick={handleEmailEvent}
              />
            </div>
          )}
          {(email && phone) && <div className='mt-1' />}
          {phone && (
            <div>
              <PhoneButton
                showIcon={true}
                buttonType='tertiary'
                size='small'
                phone={phone}
                suffix={phoneSuffix}
                data-qa='person-phone'
                onClick={handlePhoneEvent}
              />
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

const LoanOfficerModalCard = ({ loanOfficer }: { loanOfficer: LoanOfficer }) => {
  const { 'role-name': roleName, 'nmls-id': nmlsId, 'photo-url': photo, name, phone, email, title } = loanOfficer;
  return (
    <Card
      className='bg-white w-full md:w-fit-content p-0 md:p-6'
      dropShadow={false}
    >
      <div className='flex flex-col md:flex-row'>
        <div className='flex flex-col md:flex-row'>
          <div className='flex mr-0 md:mr-8'>
            <div>
              <Avatar
                className='mr-6'
                photo={photo}
                alt={`Photo of ${name}`}
                data-qa='person-photo'
                style={{ marginTop: defaultMarginTop }}
              />
            </div>
            <div className='h-full'>
              <p className='text-base font-bold mb-1.5 OneLinkNoTx'>{name}</p>
              <p className='text-base capitalize mb-1.5'>{title || roleName}</p>
              <p className='text-base'>NMLS {nmlsId}</p>
            </div>
          </div>
        </div>
        <div className='mt-4 md:mt-0'>
          <EmailPhoneWidget email={email} phone={phone} modalName='loCard' />
        </div>
      </div>
    </Card>
  );
};

export const LoanOfficerCardModalContent = ({ loanOfficer }: { loanOfficer: LoanOfficer }) => {
  return (
    <div className='flex flex-col items-center mb-1'>
      <p className='text-center text-marketing-xs md:text-marketing-md self-center mb-4'>Awesome! Let's tell your Loan Officer.</p>
      <p className='text-center mb-8'>Connect with your Loan Officer, {loanOfficer.name}, to get the process started.</p>
      <LoanOfficerModalCard loanOfficer={loanOfficer} />
    </div>
  );
};
