/* istanbul ignore file */
import { themeId } from '../features/tenant/tenant';
import THEMES from '../themes/themes';

const themes = THEMES.THEMES as Record<iThemes, unknown>;
const theme = themes[themeId] as TenantTheme;

/**
 * This code was referenced from
 * https://www.cssscript.com/demo/confetti-falling-animation/
 *
 * We are grateful to the community who contributed this.
 */
export const startConfetti = (duration: number) => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const maxParticleCount = 75;
  const particleSpeed = 2;
  const particles = [] as any[];
  let streamingConfetti = false;
  let waveAngle = 0;
  let animationTimer = null;
  let canvasContext: CanvasRenderingContext2D | null = null;

  let colors = ['DodgerBlue', 'OliveDrab', 'Gold', 'Pink', 'SlateBlue', 'LightBlue', 'Violet', 'PaleGreen', 'SteelBlue', 'SandyBrown', 'Chocolate', 'Crimson'];
  if (theme.colors?.brand?.['100']) {
    colors = [theme.colors.brand['100']];
  }

  const resetParticle = (particle: any, width: number, height: number) => {
    particle.color = colors[(Math.random() * colors.length) | 0];
    particle.x = Math.random() * width;
    particle.y = Math.random() * height - height;
    particle.diameter = Math.random() * 10 + 5;
    particle.tilt = Math.random() * 10 - 10;
    particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
    particle.tiltAngle = 0;
    return particle;
  }

  const runAnimation = () => {
    if (canvasContext) {
      canvasContext.clearRect(0, 0, window.innerWidth, window.innerHeight);
      if (particles.length === 0) {
        animationTimer = null;
      } else {
        updateParticles();
        drawParticles(canvasContext);
        animationTimer = window.requestAnimationFrame(runAnimation);
      }
    }
  }

  const drawParticles = (context: CanvasRenderingContext2D) => {
    for (const particle of particles) {
      context.beginPath();
      context.lineWidth = particle.diameter;
      context.strokeStyle = particle.color;
      const x = particle.x + particle.tilt;
      context.moveTo(x + particle.diameter / 2, particle.y);
      context.lineTo(x, particle.y + particle.tilt + particle.diameter / 2);
      context.stroke();
    }
  }

  const updateParticles = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    waveAngle += 0.01;
    for (let i = 0; i < particles.length; i++) {
      const particle = particles[i];
      if (!streamingConfetti && particle.y < -15) {
        particle.y = height + 100;
      } else {
        particle.tiltAngle += particle.tiltAngleIncrement;
        particle.x += Math.sin(waveAngle);
        particle.y += (Math.cos(waveAngle) + particle.diameter + particleSpeed) * 0.5;
        particle.tilt = Math.sin(particle.tiltAngle) * 15;
      }
      if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
        if (streamingConfetti && particles.length <= maxParticleCount) {
          resetParticle(particle, width, height);
        } else {
          particles.splice(i, 1);
          i--;
        }
      }
    }
  }

  let canvas = document.getElementById('confetti-canvas') as HTMLCanvasElement;

  if (canvas === null) {
    canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.setAttribute('id', 'confetti-canvas');
    canvas.setAttribute('style', 'display: block; z-index: 999999; pointer-events: none; position: fixed');

    const root = document.getElementById('root');
    if (!root) {
      // no animation
      return;
    }
    root.insertBefore(canvas, root.firstChild);

    canvas.width = width;
    canvas.height = height;
    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }, true);
  }

  canvasContext = canvas?.getContext('2d');

  // if we are missing either, no animation
  // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
  // requestAnimationFrame is widely supported
  if (!canvasContext || !window.requestAnimationFrame) {
    return;
  }

  while (particles.length < maxParticleCount) {
    particles.push(resetParticle({}, width, height));
  }

  streamingConfetti = true;

  setTimeout(() => {
    streamingConfetti = false;
  }, duration);

  if (animationTimer === null) {
    runAnimation();
  }
}
