import { DisclaimerProps } from './IDisclaimer';
import { formatDate, FORMAT_SHORT } from '../../../utils/date';

import './Disclaimer.scss';

export const Disclaimer = ({ text, children, html, showCurrentDate }: DisclaimerProps) => {
  return (
    <div className='disclaimer rounded border border-solid border-gray-25 px-6 py-6 max-h-80 tall:max-h-96 overflow-auto'>
      {showCurrentDate && <p className='disclaimer__current-date mb-4'>{`${formatDate(new Date(), FORMAT_SHORT)}`}</p>}
      {!html && <div className='body-disclaimer'>{text || children}</div>}
      {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
    </div>
  );
};

export default Disclaimer;
