import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import type { AppDispatch, RootState } from '../../store/types';
import { RequestError } from '../../interfaces/IRequest';
import { ConfigState, Config, OktaErrorObject } from './types';
import api from '../api';
import { updateGlobalError } from '../app/appSlice';
import { setDataLayer } from '../analytics/setDataLayer';
import { id as tentantId, themeId } from '../tenant/tenant';
import { log } from '../../utils/logger';
import { version } from '../../utils/env';

const namespace = 'config';

const initialState: ConfigState = {
  config: null,
  loadingStatus: 'idle',
};

export const fetchConfig = createAsyncThunk<Config, undefined, { dispatch: AppDispatch }>(
  `${namespace}/fetchConfig`,
  async (_, { dispatch }) => {
    try {
      const response = await api.getConfig();
      const { 'error-codes': errorCodes, ...data } = response.data;
      return { ...data, errorCodes };
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      if (!axios.isAxiosError(error)) {
        log({
          level: 'error',
          message: `fetchConfig error - ${error}`,
        });
      }
      dispatch(updateGlobalError(error.response?.data || 'Fetch config error'));
      throw err;
    }
  },
);

export const configSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchConfig.pending, state => {
        state.loadingStatus = 'pending';
      })
      .addCase(fetchConfig.fulfilled, (state, { payload }) => {
        state.loadingStatus = 'success';
        state.config = payload;
        setDataLayer('pageInfo', {
          version,
          apiVersion: payload.version,
          theme: themeId,
          tenant: tentantId,
        });
      })
      .addCase(fetchConfig.rejected, (state, { error }) => {
        state.loadingStatus = 'failed';
        state.error = error;
      });
  },
});

const selectConfigState = (state: RootState) => state.config;

export const selectConfig = (state: RootState) => selectConfigState(state).config;

export const selectConfigCsrfToken = (state: RootState) => selectConfigState(state).config?.csrf;

// TODO Move to frontend config and deprecate backend gateless configs
export const selectConfigGateless = (state: RootState) => selectConfig(state)?.gateless;

export const selectConfigFeatures = (state: RootState) => selectConfig(state)?.features || [];

export const selectHasConfig = (state: RootState) => selectConfig(state) !== null;

export const selectLanguages = (state: RootState) => selectConfig(state)?.languages;

const decodeErrorCodeKey = (errorCodeKey: string): string => {
  try {
    errorCodeKey = atob(errorCodeKey);
  } catch (_) {}
  return errorCodeKey;
};

export const selectError = createSelector(
  selectConfig,
  (_state: RootState, errorCodeKey: string): string => errorCodeKey,
  (config: Config | null, errorCodeKey: string): OktaErrorObject | undefined => {
    const decodedErrorCodeKey = decodeErrorCodeKey(errorCodeKey);
    return (config?.errorCodes && decodedErrorCodeKey) ? config.errorCodes[decodedErrorCodeKey] : undefined;
  },
);

export default configSlice.reducer;
