import { withTransaction } from '@elastic/apm-rum-react';
import { HELOCLoanDetailsCard } from '../../components/loan/heloc/details/HELOCLoanDetailsCard';
import { HELOCFigureFAQs, HELOCUnknownServicerFAQs } from '../../components/loan/heloc/details/HELOCLoanFAQs';
import withHELOCLoan from '../../components/loan/heloc/withHELOCLoan';
import SupportHours from '../../components/loan/support/SupportHours';
import { EmailPhoneWidget, SupportHeadline } from '../../components/loan/support/Support';
import { SubpageNavigation } from '../../components/navigation/SubpageNavigation';
import { DOC_TITLES, PATHS, linkTo } from '../../components/routes/paths';
import { iHELOCLoan } from '../../features/helocLoans/types';
import { content } from '../../features/tenant/tenant';
import { buildTab } from '../loan/LoanNav';
import { useDocumentTitleLoanPage } from '../../hooks/useDocumentTitle';
import { getDisplayedLoanNumber, isHELOCLoanFunded } from '../../features/helocLoans/helocLoansSlice';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { log } from '../../utils/logger';
import { Redirect } from 'react-router-dom';
import { LeftContentRightRail } from '../../components/layout/Layout';
import dayjs from 'dayjs';
import { SERVICE_MAC_CUTOFF_DATE } from '../../utils/helocServicer';

export const HELOCLoanDetails = ({ helocLoan, faqComponent }: { helocLoan: iHELOCLoan; faqComponent: React.ReactNode }) => {
  return (
    <div className='OneLinkNoTx'>
      <HELOCLoanDetailsCard helocLoan={helocLoan} />
      <>{faqComponent}</>
    </div>
  );
};

export const HELOCFigureSupport = () => {
  return (
    <div className='flex flex-col'>
      <SupportHeadline headline='For HELOC questions:' />
      <EmailPhoneWidget widgetName='helocFigureSupport' email={content.helocLoans.figure.email} phone={content.helocLoans.figure.phone} />
      {content.helocLoans.figure.supportHours?.length &&
        <div className='mt-6'>
          <SupportHours supportDaysTime={content.helocLoans.figure.supportHours} />
        </div>
      }
    </div>
  );
};

export const HELOCUnknownServicerSupport = () => {
  const serviceMacCutoffDate = dayjs(SERVICE_MAC_CUTOFF_DATE).format('MM/DD/YYYY');
  return (
    <div className='flex flex-col'>
      <SupportHeadline headline='For HELOC questions:' />
      <p className='bold mb-2'>HELOC funded before {serviceMacCutoffDate}</p>
      <EmailPhoneWidget widgetName='helocUnknownServicerSupport' email={content.helocLoans.figure.email} phone={content.helocLoans.figure.phone} />
      {content.helocLoans.figure.supportHours?.length &&
        <div className='mt-4 mb-6'>
          <SupportHours supportDaysTime={content.helocLoans.figure.supportHours} />
        </div>
      }
      <p className='bold mb-2'>HELOC funded on or after {serviceMacCutoffDate}</p>
      <EmailPhoneWidget widgetName='helocUnknownServicerSupport' email={content.helocLoans.serviceMac.email} phone={content.helocLoans.serviceMac.phone} />
      {content.helocLoans.serviceMac.supportHours?.length &&
        <div className='mt-4'>
          <SupportHours supportDaysTime={content.helocLoans.serviceMac.supportHours} />
        </div>
      }
    </div>
  );
};

export const HELOCLoanNav = ({ helocLoan }: { helocLoan: iHELOCLoan }) => {
  const tab = buildTab('Loan details', PATHS.helocLoan, PATHS.helocLoan, helocLoan.id);
  return <SubpageNavigation tabs={[tab]} />
};

const HELOCLoan = ({ helocLoan }: { helocLoan: iHELOCLoan }) => {
  const loanNumber = getDisplayedLoanNumber(helocLoan);
  const isFunded = isHELOCLoanFunded(helocLoan);

  useDocumentTitleLoanPage(DOC_TITLES.helocLoan, loanNumber);

  // if HELOC loan is funded and still at this route - implied heloc-servicing disabled
  return (
    <LeftContentRightRail
      subnav={<HELOCLoanNav helocLoan={helocLoan} />}
      rightRail={isFunded ? <HELOCUnknownServicerSupport /> : <HELOCFigureSupport />}
    >
      <HELOCLoanDetails helocLoan={helocLoan} faqComponent={isFunded ? <HELOCUnknownServicerFAQs /> : <HELOCFigureFAQs />} />
    </LeftContentRightRail>
  );
};

const WithRedirect = ({ helocLoan }: { helocLoan: iHELOCLoan }) => {
  const helocServicingEnabled = useFeatureEnabled('heloc-servicing');
  const { id: loanId } = helocLoan;
  if (helocServicingEnabled && isHELOCLoanFunded(helocLoan)) {
    log({ loanGuid: loanId, message: 'Loan is funded, redirecting back to servicing url' });
    return (
      <Redirect
        to={{
          pathname: linkTo(PATHS.helocLoanServicing, { guid: loanId }),
        }}
      />
    );
  }

  return <HELOCLoan helocLoan={helocLoan} />;
};

export default withTransaction('HELOCLoan', 'page-load')(withHELOCLoan(WithRedirect));
