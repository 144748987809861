import { useEffect, useRef, useState } from 'react';

const useCountdown = (start: number, callback: () => void) => {
  const end = 0;
  const [count, setCount] = useState<number>(start);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let id: number;

    const tick = async () => {
      if (!id) return;

      if (count > end) {
        setCount(c => c - 1);
      }

      if (count === (end + 1)) {
        clearTimeout(id);
        id = 0;
        savedCallback.current();
      } else if (count > end) {
        id = window.setTimeout(tick, 1000);
      }
    };

    id = window.setTimeout(tick, 1000);

    return () => {
      if (id) {
        clearTimeout(id);
      }
      id = 0;
    };
  }, [count]);

  return count;
};

export default useCountdown;
