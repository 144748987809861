import { SocialShareConfigs } from '../components/social/ISocialShareConfig';
import themeConfig from '../features/tenant/tenant';

const useFacebookShare = (configKey: SocialShareConfigs): FacebookContent | undefined => {
  const socialSharingContent = themeConfig.content.socialSharing;
  if (!socialSharingContent) {
    return undefined;
  }
  return socialSharingContent[configKey]?.facebook;
};

export default useFacebookShare;
