import cn from 'classnames';
import type { iTextBadge, TextBadgeProps } from './ITextBadge';

const getTypeTheme = (type?: iTextBadge) => {
  // inactive
  let bgColor = 'inactive-10';
  let textColor = 'inactive-100';

  if (type === 'ok') {
    bgColor = 'okBadge-bg'
    textColor = 'okBadge-text'
  } else if (type === 'ok-secondary') {
    bgColor = 'okSecondaryBadge-bg'
    textColor = 'okSecondaryBadge-text'
  } else if (type === 'critical') {
    bgColor = 'criticalBadge-bg'
    textColor = 'criticalBadge-text'
  } else if (type === 'critical-secondary') {
    bgColor = 'critical-10';
    textColor = 'critical-125';
  } else if (type === 'warning') {
    bgColor = 'warning-10';
    textColor = 'warning-125';
  } else if (type === 'action') {
    bgColor = 'action-125';
    textColor = 'white';
  } else if (type === 'action-secondary') {
    bgColor = 'action-10';
    textColor = 'action-125';
  } else if (type === 'navigation') {
    bgColor = 'tertiaryBtn-text';
    textColor = 'white';
  }

  return {
    bgColor,
    textColor,
  };
};

const TextBadge = (props: TextBadgeProps) => {
  const { text, children, type, className } = props;
  const theme = getTypeTheme(type);
  const style = { height: 'min-content', width: 'fit-content', ...props.style };
  return (
    <span
      className={cn(
        'text-sm rounded inline-flex items-center justify-center font-semibold leading-5 px-1 whitespace-nowrap',
        `bg-${theme.bgColor}`,
        `text-${theme.textColor}`,
        className,
      )}
      data-ui='text-badge'
      style={{ ...style }}
    >
      {text || children}
    </span>
  );
};

export default TextBadge;
