import dayjs from 'dayjs';
import {
  FORMAT_ABBREVIATED,
  FORMAT_ABBREVIATED_TIME,
  FORMAT_LONG,
  FORMAT_LONG_TIME,
  FORMAT_SHORT,
  FORMAT_SINGLE_DIGIT_SHORT_YEAR,
  formatDate,
} from '../../../utils/date';

const TimeFormatsStyleguide = () => {
  const now = dayjs().toString();

  const content = [
    { date: now, format: FORMAT_LONG_TIME },
    { date: now, format: FORMAT_LONG },
    { date: now, format: FORMAT_ABBREVIATED_TIME },
    { date: now, format: FORMAT_ABBREVIATED },
    { date: now, format: FORMAT_SHORT },
    { date: now, format: FORMAT_SINGLE_DIGIT_SHORT_YEAR },
  ];

  return (
    <>
      <div className='header-medium mb-8'>TimeFormats</div>
      <div>
        <ul>
          {content.map(dateContent => (
            <li className='mb-4' key={dateContent.format}>
              {dateContent.format} <br />
              {formatDate(dateContent.date, dateContent.format)}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default TimeFormatsStyleguide;
