import { useState } from 'react';
import Button from './Button';
import cn from 'classnames';

interface Props {
  className?: string;
  error?: boolean;
  leftLabel: string;
  rightLabel: string;
  onLeftClick: () => void;
  onRightClick: () => void;
  disabled?: boolean;
}

const ToggleButtons = ({ className, error, leftLabel, rightLabel, onLeftClick, onRightClick, disabled }: Props) => {
  const [selected, setSelected] = useState<'left' | 'right' | undefined>(undefined);

  const handleClick = (button: 'left' | 'right') => {
    setSelected(button);
    if (button === 'left') {
      onLeftClick();
    } else if (button === 'right') {
      onRightClick();
    }
  };

  return (
    <div className={cn('flex', className)}>
      <Button
        buttonType='secondary'
        type='button'
        className={cn(
          'text-base h-10 text-body-100 hover:text-body-100 hover:border-action-100 hover:bg-input-10',
          { 'border-criticalText text-criticalText': error },
          { 'border-action-100 bg-action-10': selected === 'left' },
          { 'border-input-50': selected !== 'left' },
        )}
        style={{ minWidth: '4.25rem' }}
        size='small'
        text={leftLabel}
        onClick={() => handleClick('left')}
        disabled={disabled}
      />
      <Button
        buttonType='secondary'
        type='button'
        className={cn(
          'text-base h-10 ml-2 text-body-100 hover:text-body-100 hover:border-action-100 hover:bg-input-10',
          { 'border-criticalText text-criticalText': error },
          { 'border-action-100 bg-action-10': selected === 'right' },
          { 'border-input-50': selected !== 'right' },
        )}
        style={{ minWidth: '4.25rem' }}
        size='small'
        text={rightLabel}
        onClick={() => handleClick('right')}
        disabled={disabled}
      />
    </div>
  );
};

export default ToggleButtons;
