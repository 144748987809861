import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import type { RootState } from '../store/types';
import { homeLoanInProcessPaths } from '../components/routes/paths';
import { selectLoan } from '../features/loans/loansSlice';
import { isTasklistLoan } from '../features/loans/helpers';

// Retrives loan team from the loan guid in the url
export const useHomeLoanTeam = () => {
  const routeMatch = useRouteMatch<{ guid?: string }>({
    path: homeLoanInProcessPaths,
    exact: true,
  });
  const loanGuid = routeMatch?.params.guid;
  const loan = useSelector((state: RootState) => (loanGuid ? selectLoan(state, loanGuid) : undefined));
  const team = loan && isTasklistLoan(loan) ? loan?.team : undefined;
  return { routeMatch, loanGuid, team };
};
