/* istanbul ignore file */
export const taskTitleTypes = ['accepted', 'declined', 'new', 'priority'] as const;
export type iTaskTitleBadgeType = typeof taskTitleTypes[number];

export interface TaskTitleBadgeProps {
  title: string;
  additionalContent?: string;
  badgeType?: iTaskTitleBadgeType | null;
  titleClassName?: string;
  error?: string;
}
