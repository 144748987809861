import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useEventListener } from '../../../hooks/useEventListener';
import { gatelessEvent } from '../../../features/gateless/actions';
import { useAppSelector } from '../../../hooks/hooks';
import { selectors as gatelessSelectors } from '../../../features/gateless';
import useScript from '../../../hooks/useScript';
import { RootState } from '../../../store/types';
import { log } from '../../../utils/logger';
import { content } from '../../../features/tenant/tenant';

export const GatelessComponents = ({ loanGuid }: { loanGuid: string }) => {
  const { env, staticUrl, staticUrlVersion } = content.gateless;
  const gatelessConfig = useAppSelector((state: RootState) => gatelessSelectors.getGatelessConfigByLoanGuid(state, loanGuid));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, gatelessScriptHasError, gatelessScriptError] = useScript({
    src: `${staticUrl}/js/consumer-components.${staticUrlVersion}.js`,
  });

  useEffect(() => {
    gatelessScriptHasError &&
      log({ loanGuid, level: 'warn', message: `Gateless script failed to load ${JSON.stringify({ gatelessScriptError })}` });
  }, [gatelessScriptHasError, gatelessScriptError, loanGuid]);

  if (!gatelessConfig) return null;

  return (
    <>
      <GatelessProvider env={env} consumerToken={gatelessConfig.consumerToken} context={gatelessConfig.context} />
      <GatelessEventListener loanGuid={loanGuid} context={gatelessConfig.context} />
    </>
  );
};

const GatelessProvider = ({ consumerToken, env, context }: { consumerToken: string; env: string; context: string }) => {
  return <gateless-context-provider token={consumerToken} env={env} context={context} />;
};

const GatelessEventListener = ({ loanGuid, context }: { loanGuid: string; context: string }) => {
  const dispatch = useDispatch();

  const handler = (evt: CustomEvent) => {
    const { detail } = evt;
    const { event, context: eventContext, data } = detail;
    if (event === 'message' && context === eventContext) {
      dispatch(gatelessEvent(loanGuid, data));
    }
  };

  useEventListener('gatelessevent', handler);

  return null;
};
