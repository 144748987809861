import Card from '../../ui/card/Card';
import { Skeleton, SkeletonGroup } from '../../ui/skeleton/Skeleton';

const LoanDetailsSkeletonLoader = () => {
  return (
    <Card className='flex flex-col mb-8'>
      <div className='flex'>
        <div className='flex items-center'>
          <Skeleton className='mr-8 mt-2 rounded-full' style={{ width: '48px', height: '48px' }} />
        </div>
        <div className='flex flex-col w-full'>
          <Skeleton className='mb-2 w-1/4' />
          <Skeleton className='w-2/3' />
        </div>
      </div>
      <hr className='my-8' />
      <SkeletonGroup className='mb-2 gap-8' columnCount={3} wrapMobile={false} />
      <SkeletonGroup className='gap-8' columnCount={3} wrapMobile={false} />
    </Card>
  );
};

export default LoanDetailsSkeletonLoader;
