import { Loan } from '../features/loans/types';
import eligibleImage from '../images/eligible-banner.png';
import eligibleMobileImage from '../images/eligible-banner-mobile.png';
import nonEligibleImage from '../images/non-eligible-banner.png';
import nonEligibleMobileImage from '../images/non-eligible-banner-mobile.png';

export interface EligibleModalContent {
  bannerImage: string;
  bannerMobileImage: string;
  bannerText: string;
  bodyText: string;
}

export const useEligibilityModalContent = (loan: Loan, isBusinessHours: boolean): EligibleModalContent | null => {
  const { 'same-day-status': sameDayStatus } = loan;
  let modalContent = null;

  if (sameDayStatus === 'approved') {
    // this is pending
    modalContent = {
      bannerImage: eligibleImage,
      bannerMobileImage: eligibleMobileImage,
      bannerText: 'and just like that...you are in!',
      bodyText: isBusinessHours ?
        'A Mortgage Specialist will give you a ring in the next 15 minutes or so to lock in your rate. In the meantime, you can start completing tasks now.' :
        '<div><p>Our offices are closed at the moment, but no worries - a Mortgage Specialist will give you a call as soon as we\'re open.</p><br><p>Too excited to wait? Feel free to start tackling tasks right now!</p></div>',
    };
    // this is pending
  } else if (sameDayStatus === 'eligible') {
    modalContent = {
      bannerImage: eligibleImage,
      bannerMobileImage: eligibleMobileImage,
      bannerText: 'and just like that...you are in!',
      bodyText: isBusinessHours ?
        'A Mortgage Specialist will give you a ring in the next 15 minutes or so to lock in your rate. In the meantime, you can start completing tasks now.' :
        '<div><p>Our offices are closed at the moment, but no worries - a Mortgage Specialist will give you a call as soon as we\'re open.</p><br><p>Too excited to wait? Feel free to start tackling tasks right now!</p></div>',
    };
  } else if (sameDayStatus === 'prequal') {
    modalContent = {
      bannerImage: eligibleImage,
      bannerMobileImage: eligibleMobileImage,
      bannerText: 'and just like that...you are in!',
      bodyText: isBusinessHours ?
        'A Mortgage Specialist will give you a ring in the next 15 minutes or so to finish your pre-approval. In the meantime, you can start completing tasks now.' :
        '<div><p>Our offices are closed at the moment, but no worries - a Mortgage Specialist will give you a call as soon as we\'re open.</p><br><p>Too excited to wait? Feel free to start tackling tasks right now!</p></div>',
    };
  } else if (sameDayStatus === 'not-eligible') {
    modalContent = {
      bannerImage: nonEligibleImage,
      bannerMobileImage: nonEligibleMobileImage,
      bannerText: 'thanks! application received',
      bodyText: isBusinessHours ?
        'You don\'t need to do anything just now. We\'re looking through everything and a Mortgage Specialist will give you a call in the next 15 minutes or so to talk.' :
        'You don\'t need to do anything just now. We\'re looking through everything and a Mortgage Specialist will give you a call in the morning talk about next steps.',
    };
  }

  return modalContent;
};
