import { createSelector } from '@reduxjs/toolkit';
import { LANGUAGE_PREFERENCE_TYPES } from '../../components/tasks/languagePreference/LanguagePreference';
import { Task, TasksState } from './types';
const { keys } = Object;

interface State {
  tasks: TasksState;
}

export const getLoanGuid =
  (taskId: string) =>
  ({ tasks: { idsByLoan } }: State) => {
    return keys(idsByLoan).find(loanId => idsByLoan[loanId].includes(taskId));
  };

export const hasLoanTasksData = (state: State, loanGuid: string): boolean => !!state.tasks.metadataByLoan[loanGuid]?.hasTasks;

export const hasLoanTaskError = (state: State, loanGuid: string): boolean => !!state.tasks.metadataByLoan[loanGuid]?.hasError;

export const loanTasksAreLoading = (state: State, loanGuid: string): boolean =>
  !!state.tasks.metadataByLoan[loanGuid]?.tasksLoading;

export const getLoanTaskIds = (state: State, loanGuid: string): string[] => state.tasks.idsByLoan[loanGuid] || [];

export const getTasks = (state: State) => state.tasks.byId;

export const getTaskById = (state: State, taskId: string): Task<any> | undefined => getTasks(state)[taskId];

const getLoanIncompleteTaskIds = createSelector(getLoanTaskIds, getTasks, (ids, tasks) =>
  ids.filter(id => tasks[id]['task-completed'] === false),
);

const getLoanCompleteTaskIds = createSelector(getLoanTaskIds, getTasks, (ids, tasks) =>
  ids.filter(id => tasks[id]['task-completed'] === true),
);

export const getLoanTaskIdsByGroups = createSelector(
  getLoanTaskIds,
  getLoanIncompleteTaskIds,
  getLoanCompleteTaskIds,
  (all, incomplete, complete) => ({ all, incomplete, complete }),
);

export const getLanguagePreferenceFromTask = createSelector(getLoanTaskIds, getTasks, (ids, tasks): string | undefined => {
  const languagePreferenceTaskId = ids.find(id => LANGUAGE_PREFERENCE_TYPES.includes(tasks[id]['task-type']));
  return languagePreferenceTaskId && tasks[languagePreferenceTaskId]?.['custom-task-meta']?.['language-preference'];
});

export const getTaskModalOpen = (state: State) => state.tasks.taskModalOpen;

export const getPendingTasks = (state: State, loanGuid: string) => state.tasks.othersPendingTasksByLoan[loanGuid];
