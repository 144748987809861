import Icon from '../icon/Icon';
import { iIconName } from '../icon/IIcon';
import cn from 'classnames';
import { useLayoutEffect, useRef, useState } from 'react';

interface Props {
  bgColor?: string;
  iconName: iIconName;
  iconSize?: string;
  circleSize?: string;
  indicatorIconName?: iIconName;
  indicatorIconSize?: string;
  indicatorBgColor?: string;
}

const CircleIndicator = ({
  iconName,
  iconSize,
  circleSize = '48px',
  bgColor = 'gray-75',
  indicatorIconName,
  indicatorIconSize = '0.4rem',
  indicatorBgColor = 'gray-100',
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const indicatorSizePixel = 18;
  const [indicatorLeftPosition, setIndicatorLeftPosition] = useState(30);

  useLayoutEffect(() => {
    if (containerRef?.current?.clientWidth) {
      const containerWidth = containerRef.current.clientWidth;
      setIndicatorLeftPosition(containerWidth - indicatorSizePixel);
    }
  }, []);

  return (
    <div ref={containerRef} style={{ width: circleSize, height: circleSize }}>
      <div className={cn('circle', 'text-black', `bg-${bgColor}`)} style={{ width: circleSize, height: circleSize }} >
        <Icon name={iconName} size={iconSize} />
      </div>
      {indicatorIconName && 
        <div
          className={cn('circle', 'relative', `bg-${indicatorBgColor}`)}
          style={{
            width: `${indicatorSizePixel}px`,
            height: `${indicatorSizePixel}px`,
            bottom: circleSize,
            left: `${indicatorLeftPosition}px`
          }}
        >
          <Icon
            size={indicatorIconSize}
            name={indicatorIconName}
          />
        </div>
      }
    </div>
  );
};

export default CircleIndicator;
