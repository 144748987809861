import Link from '../link/Link';
import { Breadcrumb, BreadcrumbsProps } from './iBreadcrumb';

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  return (
    <nav aria-label='Breadcrumb' className='flex'>
      {breadcrumbs.map((breadcrumb: Breadcrumb, index: number) => {
        const isCurrent = index === breadcrumbs.length - 1;
        return (
          <div key={index}>
            {index > 0 && <span className='mx-3'>/</span>}
            {isCurrent && (
              <span className='text-sm' {...(isCurrent && { 'aria-current': 'page' })}>
                {breadcrumb.text}
              </span>
            )}
            {!isCurrent && (
              <Link
                to={breadcrumb.path}
                className='no-underline pt-0.5 text-tertiaryBtn-text bold text-sm hover:text-tertiaryBtn-hoverText hover:underline'
                aria-label={breadcrumb.text}
              >
                {breadcrumb.text}
              </Link>
            )}
          </div>
        );
      })}
    </nav>
  );
};

export default Breadcrumbs;
