import { useCallback, useEffect, useState } from 'react';
import useInterval from './useInterval';

export const RetryStatuses = ['timed-out', 'retrying', 'idle'] as const;
export type iRetryStatus = typeof RetryStatuses[number];

export interface iUseRetry {
  shouldRetry: boolean;
  triggerRetryOnInit?: boolean;
  maxRetries?: number;
  retryDuration?: number;
  onRetry: (fn: { retryNum: number; totalTime: number }) => void;
  onTimedOut?: (fn: { retryNum: number; totalTime: number; maxRetries?: number }) => void;
}

export interface iUseRetryReturn {
  status: iRetryStatus;
  retryNum: number;
  totalTime: number;
}

const getTotalTime = (retryNum: number, retryDuration: number, triggerRetryOnInit?: boolean) =>
  (triggerRetryOnInit ? retryNum - 1 : retryNum) * retryDuration;

const useRetry = ({
  shouldRetry,
  triggerRetryOnInit = true,
  maxRetries,
  retryDuration = 10000,
  onRetry,
  onTimedOut,
}: iUseRetry): iUseRetryReturn => {
  const [retryNum, setRetryNum] = useState(0);
  const totalTime = getTotalTime(retryNum, retryDuration, triggerRetryOnInit);
  const timedOut = maxRetries && retryNum >= maxRetries;

  const handleRetry = useCallback(() => {
    setRetryNum(retryNum + 1);
    onRetry({ retryNum: retryNum + 1, totalTime: getTotalTime(retryNum + 1, retryDuration, triggerRetryOnInit) });
  }, [onRetry, retryDuration, retryNum, triggerRetryOnInit]);

  const handleTimeOut = useCallback(() => {
    onTimedOut?.({
      retryNum: retryNum + 1,
      totalTime: getTotalTime(retryNum + 1, retryDuration, triggerRetryOnInit),
      maxRetries,
    });
  }, [maxRetries, onTimedOut, retryDuration, retryNum, triggerRetryOnInit]);

  const [clear] = useInterval(() => {
    if (shouldRetry && !timedOut) {
      handleRetry();
    }

    if (maxRetries && retryNum === maxRetries - 1) {
      handleTimeOut();
      clear();
    }
  }, retryDuration);

  useEffect(() => {
    if (shouldRetry && retryNum === 0 && triggerRetryOnInit) {
      handleRetry();
    }
  }, [handleRetry, shouldRetry, retryNum, triggerRetryOnInit]);

  useEffect(() => {
    !shouldRetry && clear();
  }, [shouldRetry, clear]);

  return {
    status: timedOut ? 'timed-out' : shouldRetry ? 'retrying' : 'idle',
    retryNum,
    totalTime,
  };
};

export default useRetry;
