import { Task } from '../../../features/tasks/types';
import Checkbox from '../../ui/checkbox/Checkbox';
import TaskCompletedDocuments from '../task/TaskCompletedDocuments';
import LoxCreditInquiryForm from './LoxCreditInquiryForm';
import LoxStandardForm from './LoxStandardForm';

export const LOX_TYPES = ['data_lox', 'credit_inquiry_lox'] as const;
export const STANDARD_LOX_TYPE = 'standard_lox';

interface Props {
  loanGuid: string;
  task: Task<'data_lox'> | Task<'credit_inquiry_lox'>;
  setAccordionActive?: (active: boolean) => void;
}

export const LoxDisclaimerCheckbox = ({
  error,
  onChange,
  disclaimer,
  disabled,
}: {
  error?: string;
  onChange?: (value: boolean) => void;
  disclaimer?: string;
  disabled?: boolean;
}) => {
  return (
    <>
      {disclaimer ? (
        <div className='lox-disclaimer' dangerouslySetInnerHTML={{ __html: disclaimer }} />
      ) : (
        <div className='lox-disclaimer'>
          <p>
            <span className='note'>Note:</span> It is a Federal crime punishable by fine or imprisonment, or both, to knowingly make any false statements concerning
            any of the entered facts as applicable under the provisions of Title 18, United States Code, Section 1001, et seq.
          </p>
        </div>
      )}
      <Checkbox
        className='mt-4 text-sm lg:text-base'
        name='lox-disclaimer'
        value='I confirm the above information is accurate'
        error={error}
        onChange={onChange}
        disabled={disabled}
      />
    </>
  );
};

const LoxIncomplete = ({ loanGuid, task, setAccordionActive }: Props) => {
  const { 'task-type': taskType } = task;
  switch (taskType) {
    case 'data_lox':
      return <LoxStandardForm loanGuid={loanGuid} task={task as Task<'data_lox'>} setAccordionActive={setAccordionActive} />;
    case 'credit_inquiry_lox':
      return <LoxCreditInquiryForm loanGuid={loanGuid} task={task as Task<'credit_inquiry_lox'>} setAccordionActive={setAccordionActive} />;
  }
};

const LoxTaskBody = ({ loanGuid, task, setAccordionActive }: Props) => {
  return (
    <>
      {!!task['task-completed']
        ? <TaskCompletedDocuments loanGuid={loanGuid} task={task} />
        : <LoxIncomplete loanGuid={loanGuid} task={task} setAccordionActive={setAccordionActive} />
      }
    </>
  );
};

export default LoxTaskBody;
