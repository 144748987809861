import ModalHeader from '../../ui/modal/ModalHeader';
import Button from '../../ui/button/Button';
import ModalFooter from '../../ui/modal/ModalFooter';

interface ConsentSuccessProps {
  agree: boolean;
  onDone: () => void;
}

const OptedInContent = () => <p className='my-4'>Thank you for waiting.</p>;

const OptedOutContent = () => (
  <>
    <p className='my-4'>You have opted out of being sent digital documents.</p>
    <p className='mb-4'>If you'd like to opt in to receive digital documents, select this task again and accept the terms.</p>
  </>
);

export const ModalConsentSuccess = ({ agree, onDone }: ConsentSuccessProps) => (
  <>
    <ModalHeader title='Your consent settings have been updated.' textAlignment='center' />
    <div className='text-center'>
      {agree ? <OptedInContent /> : <OptedOutContent />}
    </div>
    <ModalFooter>
      <Button onClick={onDone} buttonType='primary' size='large'>
        Back to your tasklist
      </Button>
    </ModalFooter>
  </>
);

export const ModalConsentDeclineConfirmation = ({ onCancel, onContinue }: { onCancel: () => void; onContinue: () => void }) => (
  <div className='flex flex-col items-center justify-center text-center'>
    <h2 className='header-small mb-4'>Are you sure you want to decline consent?</h2>
    <p className='mb-4'>All documentation will be sent to your primary mailing address.</p>
    <p className='bold'>This may delay your loan's closing.</p>
    <ModalFooter>
      <Button onClick={onContinue} buttonType='secondary' size='large' className='lg:mr-3' style={{ minWidth: '170px' }}>
        Yes, continue
      </Button>
      <Button onClick={onCancel} buttonType='primary' size='large' className='mb-4 lg:mb-0 lg:ml-3' style={{ minWidth: '170px' }}>
        No, cancel
      </Button>
    </ModalFooter>
  </div>
);
