import { useEffect, useMemo } from 'react';
import { hasUnviewedPreapproval, useFetchPreapprovals, usePreapprovalsEligible, usePreapprovalsEnabled } from '../../../features/preapprovals/preapprovalsSlice';
import { Preapproval, PreapprovalGroup } from '../../../features/preapprovals/types';
import { formatAddress } from '../../../utils/addressFormatter';
import Card from '../../ui/card/Card';
import { Skeleton, SkeletonGroup } from '../../ui/skeleton/Skeleton';
import PreapprovalsTables from './PreapprovalsTables';
import { sendEvent } from '../../../features/analytics/sendEvent';
import Modal from '../../ui/modal/Modal';
import { Loan } from '../../../features/loans/types';
import { LoanOfficerCardModalContent } from '../modals/support/LoanOfficerCardModalContent';
import { frontendFeatureEnabled } from '../../../hooks/useFeatureEnabled';
import { log } from '../../../utils/logger';

export const PreapprovalsSkeletons = () => {
  return (
    <div className='flex flex-col w-full'>
      <Skeleton className='mb-5 w-full' height='20px'/>
      <SkeletonGroup className='mb-5' height='20px' columnCount={4} wrapMobile={false} />
      <SkeletonGroup height='20px' columnCount={4} wrapMobile={false} />
    </div>
  );
};

const PreapprovalsEmpty = () => {
  return (
    <p>You do not have any pre-approval letters at the moment.</p>
  );
};

export const groupPreapprovalsByAddress = (result: PreapprovalGroup, currentValue: Preapproval) => {
  const address =
    formatAddress(
      currentValue?.letter?.data?.['property-address'] === 'PREQUALIFICATION'
        ? ''
        : currentValue?.letter?.data?.['property-address'],
      currentValue?.letter?.data?.['property-city'],
      currentValue?.letter?.data?.['property-state'],
    ) || 'Other';
  (result[address] = result[address] || []).push(currentValue);
  return result;
};

const preapprovalsSorter = (desc: boolean) => (a: Preapproval, b: Preapproval) => {
  const aOn = a?.letter['created-at'];
  const bOn = b?.letter['created-at'];
  return desc ? bOn - aOn : aOn - bOn;
};

const Wrapper = ({ children }: { children: React.ReactNode} ) => {
  return (
    <>
      <div className='inline lg:hidden'>
        {children}
      </div>
      <div className='hidden lg:inline'>
        <Card>
          {children}
        </Card>
      </div>
      <div className='border-b border-gray-25 lg:border-0 my-6' />
    </>
  );
};

const RequestNewApprovalModal = ({ loan, text }: { loan: Loan; text: string }) => {
  const lo = loan.team?.lo;
  const { 'loan-guid': loanGuid } = loan;

  const onRequestApprovalOpen = () => {
    sendEvent('requestPreapproval: modalOpen', { location: 'documents', loanGuid });
    log({ loanGuid, level: 'info', message: 'request preapproval: modal open' });
  };

  if (!lo || lo.status === 'inactive') return null;

  return (
    <Modal
      buttonProps={{
        text,
        buttonType: 'tertiary',
      }}
      contentLabel={text}
      onOpen={onRequestApprovalOpen}
    >
      <LoanOfficerCardModalContent loanOfficer={lo} />
    </Modal>
  );
};

const Preapprovals = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;
  const {
    data: preapprovalsUnsorted,
    hasData: hasPreapprovalsData,
    hasError: hasPreapprovalsError,
  } = useFetchPreapprovals(loanGuid);
  const isLoanEligible = usePreapprovalsEligible(loanGuid);
  const preapprovalEnabled = usePreapprovalsEnabled(loanGuid);
  const shouldShowPreapprovals = preapprovalEnabled && isLoanEligible;
  const requestPreapprovalEnabled = frontendFeatureEnabled('documents-request-preapproval');

  const preapprovals = useMemo(() => [...preapprovalsUnsorted].sort(preapprovalsSorter(true)), [preapprovalsUnsorted]);
  const preapprovalGroups = useMemo(() => preapprovals.reduce(groupPreapprovalsByAddress, {}), [preapprovals]);
  const hasUnviewedPreapprovals = hasUnviewedPreapproval(preapprovals);

  useEffect(() => {
    hasUnviewedPreapprovals && sendEvent('newDocsBadgeShownDocumentsPage', { loanGuid });
  }, [loanGuid, hasUnviewedPreapprovals]);

  // Loan conditionals for displaying preapprovals
  if (!shouldShowPreapprovals) {
    return null;
  }

  const error = !hasPreapprovalsData && hasPreapprovalsError;
  const loading = !hasPreapprovalsData;
  const empty = hasPreapprovalsData && !preapprovals.length;

  return (
    <Wrapper>
      {!empty
        ?
        <div className='hidden lg:flex items-baseline justify-between'>
          <header className='text-xl font-bold mb-5'>Pre-approval letters</header>
          {requestPreapprovalEnabled && <RequestNewApprovalModal loan={loan} text='Request a new pre-approval letter' />}
        </div>
        :
        <header className='text-xl font-bold mb-5'>Pre-approval letters</header>
      }
      {
        error ? null // TODO: <PreapprvalsError />
        :
        loading ? <PreapprovalsSkeletons />
        :
        empty ? <PreapprovalsEmpty />
        :
        <PreapprovalsTables loanGuid={loanGuid} preapprovalsGroups={preapprovalGroups} />
      }
      {requestPreapprovalEnabled &&
        <>
          {/* desktop empty preapproval */}
          {empty &&
            <div className='hidden lg:block mt-5'>
              <RequestNewApprovalModal loan={loan} text='Request a pre-approval letter' />
            </div>
          }
          {/* mobile */}
          <div className='block lg:hidden mt-5 mb-8'>
            <RequestNewApprovalModal loan={loan} text={empty ? 'Request a pre-approval letter' : 'Request a new pre-approval-letter'} />
          </div>
        </>
      }
    </Wrapper>
  );
};

export default Preapprovals;
