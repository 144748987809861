import { FormEvent, useState } from 'react';
import Checkbox from '../../ui/checkbox/Checkbox';
import Form from '../../ui/form/Form';
import FormButtonComponent from '../../ui/form/FormButtonComponent';
import FormGroup from '../../ui/form/FormGroup';
import { BorrowerAgent, Task } from '../../../features/tasks/types';

const AgentTaskForm = ({ task, onSubmit }: { task: Task<'borrower-agent'>; onSubmit: (agent: BorrowerAgent) => void }) => {
  const agent = task['custom-task-meta'];

  const isFormValid = (agent: BorrowerAgent) => {
    return agent['no-agent'] || (!!agent['first-name'] && !!agent['last-name'] && !!agent.email);
  };

  const [disableSubmit, setDisableSubmit] = useState(!isFormValid(agent));
  const [agentState, setAgentState] = useState(agent);
  const [inputErrors, setInputErrors] = useState<{ [key: string]: string | undefined }>({});

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const submitAgent = {
      ...agentState,
      'no-agent': !!agentState['no-agent'],
    }
    onSubmit(submitAgent);
  };

  const onChange = (key: string, value: string | boolean) => {
    const updatedAgent = {
      ...agentState,
      [key]: value,
    };
    if (inputErrors[key] && value) {
      setInputErrors({
        ...inputErrors,
        [key]: undefined,
      });
    }
    setDisableSubmit(!isFormValid(updatedAgent));
    setAgentState(updatedAgent);
  };

  const onBlur = (key: string, value: string) => {
    const noAgent = agentState['no-agent'];
    // if no agent checkbox is not checked - flag error on field onblur
    if (!noAgent && !value) {
      setInputErrors({
        ...inputErrors,
        [key]: 'Field requried',
      });
    }
  };

  const checkFieldsError = (noAgent: boolean) => {
    const fields = ['first-name', 'last-name', 'email'];
    const updatedInputErrors = { ...inputErrors };
    fields.forEach(field => {
      const fieldValue = (agentState as any)[field];
      updatedInputErrors[field] = noAgent ? undefined : fieldValue ? undefined : 'Field required';
    });
    setInputErrors(updatedInputErrors);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-x-3'>
        <FormGroup
          className='col-span-1'
          label='First name'
          id='agent_first_name'
          name='agent_first_name'
          type='text'
          maxLength='128'
          value={agentState['first-name'] ?? undefined}
          error={inputErrors['first-name']}
          disabled={!!agentState['no-agent']}
          onChange={event => onChange('first-name', event.target.value)}
          onBlur={event => onBlur('first-name', event.target.value)}
        />
        <FormGroup
          className='col-span-1'
          label='Last name'
          id='agent_last_naem'
          name='agent_last_name'
          type='text'
          maxLength='128'
          value={agentState['last-name'] ?? undefined}
          error={inputErrors['last-name']}
          disabled={!!agentState['no-agent']}
          onChange={event => onChange('last-name', event.target.value)}
          onBlur={event => onBlur('last-name', event.target.value)}
        />
        <FormGroup
          className='col-span-1'
          label='Email'
          id='agent_email'
          name='agent_email'
          type='email'
          maxLength='128'
          value={agentState.email ?? undefined}
          error={inputErrors['email']}
          disabled={!!agentState['no-agent']}
          onChange={event => onChange('email', event.target.value)}
          onBlur={event => onBlur('email', event.target.value)}
        />
      </div>
      <Checkbox
        className='mt-5'
        name='agent_checkbox'
        value={`I don't have an agent`}
        initValue={!!agentState['no-agent']}
        onChange={event => {
          onChange('no-agent', event);
          checkFieldsError(event);
        }}
      />
      <FormButtonComponent
        id='agent-submit-button'
        buttonContainerClassName='mt-3 md:mt-0 w-full md:w-fit-content'
        className='mt-3 w-full md:w-fit-content'
        disabled={disableSubmit}
      >
        Next
      </FormButtonComponent>
    </Form>
  );
};

export default AgentTaskForm;
