import { useEffect } from 'react';
import { updateAdtrk, updateCookiedLoId } from '../../features/app/appSlice';
import { useAppDispatch } from '../../hooks/hooks';
import { adtrk, lo } from '../../utils/browser';
import { getBaseDomain } from '../../utils/getBaseDomain';
import { log } from '../../utils/logger';
import { setCookie } from '../../utils/setCookie';

const useAdtrk = () => {
  const { adtrkSearchParam, adtrkCookie } = adtrk();
  return {
    adtrk: adtrkSearchParam || adtrkCookie,
    adtrkSearchParam,
    adtrkCookie,
  };
};

const Adtrk = () => {
  const dispatch = useAppDispatch();
  const { adtrk, adtrkCookie } = useAdtrk();

  useEffect(() => {
    if (adtrk) {
      log({
        level: 'debug',
        message: `Analytics: Found adtrk "${adtrk}"`,
      });
      dispatch(updateAdtrk(adtrk));
    }
  }, [dispatch, adtrk]);

  useEffect(() => {
    if (adtrk && (!adtrkCookie || adtrkCookie !== adtrk)) {
      const domain = getBaseDomain();
      log({
        level: 'debug',
        message: `Analytics: Setting AdTrk cookie ${JSON.stringify({ adtrk, previousAdtrkCookie: adtrkCookie, domain })}`,
      });
      setCookie('AdTrk', adtrk, { domain, 'max-age': 90 * 24 * 60 * 60 });
    }
  }, [adtrk, adtrkCookie]);

  return null;
};

const useLoId = () => {
  const { loIdSearchParam, empIdSearchParam, loIdCookie } = lo();
  return {
    loId: empIdSearchParam || loIdSearchParam || loIdCookie,
    loIdSearchParam,
    empIdSearchParam,
    loIdCookie,
  };
};

const LoId = () => {
  const dispatch = useAppDispatch();
  const { loId } = useLoId();

  useEffect(() => {
    if (loId) {
      log({
        level: 'debug',
        message: `Analytics: Found loId "${loId}"`,
      });
      dispatch(updateCookiedLoId(loId));
    }
  }, [dispatch, loId]);

  return null;
};

const Analytics = () => (
  <>
    <Adtrk />
    <LoId />
  </>
);

export default Analytics;
