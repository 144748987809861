import cn from 'classnames';
import type { ButtonProps, iButtonType, iButtonSize } from './IButton';
import { iIconName } from '../icon/IIcon';
import Icon from '../icon/Icon';
import Link from '../link/Link';
import MiniSpinner from '../spinner/MiniSpinner';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';
import TextBadge from '../badge/TextBadge';

const getButtonSizeClass = (size?: iButtonSize): string | null => (size ? `btn--${size}` : null);
const getButtonTypeClass = (type: iButtonType): string => `btn--${type}`;
const getButtonColorClass = (color?: string): string => {
  return color ? `text-${color} hover:text-${color}` : '';
};

const isActionable = (props: ButtonProps): boolean => !!Object.keys(props).find(prop => prop === 'type' || prop.startsWith('on'));

const Button = (props: ButtonProps) => {
  const {
    to,
    href,
    children,
    text,
    disabled,
    buttonType = 'inline',
    className,
    size,
    loading,
    hover,
    active,
    icon,
    iconName,
    iconSize,
    iconClassname,
    iconStyle,
    iconAriaLabel,
    iconPlacement = 'right',
    anchorRef,
    buttonRef,
    badge,
    color,
    hasBg,
    rotate,
    onClick,
    iconHoverEffect = false,
    ...restProps
  } = props;
  const Element = to || href ? Link : isActionable(props) ? 'button' : 'span';
  const hasIcon = !!(icon || iconName);
  const isbuttonTypeIcon = buttonType === 'icon';

  const buttonText = !isbuttonTypeIcon && (
    <ConditionalWrapper condition={hasIcon || !!badge} el='span' className='btn-text'>
      {children || props.text}
    </ConditionalWrapper>
  );

  const ariaLabel = isbuttonTypeIcon ? props['aria-label'] || text : props['aria-label'];

  const buttonIcon = hasIcon && (
    <span
      className={cn(
        'btn-icon',
        'flex',
        'items-center',
        { 'ml-2': !isbuttonTypeIcon && iconPlacement === 'right' },
        { 'order-first mr-2': !isbuttonTypeIcon && iconPlacement === 'left' },
        iconClassname,
      )}
      style={iconStyle}
    >
      {icon ? icon : <Icon name={iconName as iIconName} size={iconSize} aria-label={iconAriaLabel} hoverEffect={iconHoverEffect} />}
    </span>
  );

  const buttonBadge = !!badge && <TextBadge {...badge} className='ml-2' />;

  return (
    <Element
      data-ui='button'
      {...(loading && { disabled: true })}
      {...restProps}
      {...(ariaLabel && { 'aria-label': ariaLabel })}
      onClick={onClick}
      {...(disabled && { disabled })}
      {...(to && { to })}
      {...(href && { href })}
      {...((to || href) && anchorRef && { anchorRef })}
      {...(!to && !href && buttonRef && { ref: buttonRef })}
      className={cn(
        'btn',
        buttonType && getButtonTypeClass(buttonType),
        getButtonSizeClass(size),
        { 'btn--has-icon': hasIcon },
        { 'btn--has-badge': !!buttonBadge },
        { 'btn--loading': loading },
        { 'hover': hover },
        { 'active': active },
        { 'btn--icon-bg': hasBg },
        { 'btn--icon-rotate': rotate },
        { 'btn--disabled': !loading && disabled },
        getButtonColorClass(color),
        className,
      )}
    >
      <ConditionalWrapper condition={!isbuttonTypeIcon && (hasIcon || !!badge)} el='span' className='btn-wrap flex items-center'>
        {loading ? (
          <div className='btn-spinner'>
            <MiniSpinner />
          </div>
        ) : (
          buttonText
        )}
        {!loading && buttonIcon}
        {buttonBadge}
      </ConditionalWrapper>
    </Element>
  );
};

export default Button;
