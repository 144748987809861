export const encodeParam = (str: string) =>
  encodeURI(str)
    .replace(/%5B/g, '[')
    .replace(/%5D/g, ']')
    .replace(/%20/g, '+')
    .replace(/[!'()*]/g, c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);

export const decodeParam = (str: string) => decodeURIComponent(str.replace(/\+/g, ' '));

export const searchParams = (url?: string) => {
  const search = url ? url.split('?')[1] : window.location.search;
  const params = new URLSearchParams(search);
  const get = (name: string) => params.get(name);
  return { get };
};

export const searchParamsCaseInsensitive = (url?: string) => {
  const search = url ? url.split('?')[1] : window.location.search;
  const params = new URLSearchParams(search);
  const newParams = new URLSearchParams();
  for (const [name, value] of params) {
    newParams.append(name.toLowerCase(), value);
  }
  const get = (name: string) => newParams.get(name);
  return { get };
};

export const getSearchParam = (
  { name, url }: { name: string; url?: string },
  { caseInsensitive, decode }: { caseInsensitive?: boolean; decode?: boolean } = {},
) => {
  if (!name) return null;

  let value = caseInsensitive ? searchParamsCaseInsensitive(url).get(name.toLowerCase()) : searchParams(url).get(name);

  if (value && decode) {
    value = decodeParam(value);
  }

  return value;
};

export const buildSearchParams = (parameters: Record<string, string>) => {
  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries<string>(parameters)) {
    if (value) {
      searchParams.append(key, value);
    }
  }
  const searchParamString = searchParams.toString();
  return searchParamString.length > 0 ? `?${searchParamString}` : '';
};
