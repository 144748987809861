import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { fetchHELOCLoans, selectHELOCLoansMetadata } from './helocLoansSlice';

const useFetchHELOCLoansData = () => {
  const dispatch = useAppDispatch();
  const isHELOCLoansEnabled = useFeatureEnabled('heloc-loans');
  const metadata = useAppSelector(selectHELOCLoansMetadata);
  const { hasData } = metadata;

  useEffect(() => {
    !hasData && isHELOCLoansEnabled && dispatch(fetchHELOCLoans());
  }, [hasData, isHELOCLoansEnabled, dispatch]);

  return metadata;
};

export default useFetchHELOCLoansData;
