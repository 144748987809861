import {
  DesktopHomeValueSearchSkeletonLoading,
  MobileHomeValueSearchSkeletonLoading,
} from '../../../components/homeValue/homeValueSkeleton/HomeValueSkeleton';
import { DocumentsSkeletons } from '../../../components/loan/documents/Documents';
import LoanDetailsSkeletonLoader from '../../../components/loan/loanCard/LoanCardSkeletonLoader';
import { PreapprovalsSkeletons } from '../../../components/loan/preapprovals/Preapprovals';
import { InitLoadingSkeleton } from '../../../components/registration/Registration';
import { TasklistLoading } from '../../../components/tasks/tasklist/TasklistLoading';
import { ActionBannerSkeleton } from '../../../components/ui/actionBanner/ActionBanner';
import { ServicingSkeleton } from '../../loan/Servicing';
import { LoanCardSkeletonLoader } from '../../loans/Loans';

const SkeletonStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Skeletons</div>
      <ul>
        <li className='mb-4'>
          <h2>Loan Card</h2>
          <LoanCardSkeletonLoader />
        </li>
        <li className='mb-4'>
          <h2>Loan Details</h2>
          <LoanDetailsSkeletonLoader />
        </li>
        <li className='mb-4'>
          <h2>Action Banner</h2>
          <ActionBannerSkeleton />
        </li>
        <li className='mb-4'>
          <h2>Tasklist</h2>
          <TasklistLoading />
        </li>
        <li className='mb-4'>
          <h2>Servicing</h2>
          <ServicingSkeleton />
        </li>
        <li className='mb-4'>
          <h2>Pre-approvals</h2>
          <PreapprovalsSkeletons />
        </li>
        <li className='mb-4'>
          <h2>Documents</h2>
          <DocumentsSkeletons />
        </li>
        <li className='mb-4'>
          <h2>Home Value Search</h2>
          <MobileHomeValueSearchSkeletonLoading />
          <DesktopHomeValueSearchSkeletonLoading />
        </li>
        <li className='mb-4'>
          <h2>Registration</h2>
          <InitLoadingSkeleton />
        </li>
      </ul>
    </>
  );
};

export default SkeletonStyleguide;
