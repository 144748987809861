import { LOAN_STATUS_MAP } from '../../../components/loan/loanStatusInfo/LoanStatus';
import { MilestoneTracker } from '../../../components/loan/milestone/MilestoneTracker';
import HorizontalStepComponent from '../../../components/ui/stepper/HorizontalStepComponent';
import VerticalStepComponent from '../../../components/ui/stepper/VerticalStepComponent';
import { LoanMilestones } from '../../../features/loans/types';

const StepperStyleguide = () => {
  const stepList = [
    {
      name: 'Step One',
      id: 'step_1',
    },
    {
      name: 'Step 2',
      id: 'step_2',
    },
    {
      name: 'Step Three',
      id: 'step_3',
    },
    {
      name: 'Step Four',
      id: 'step_4',
    },
  ];

  const stepList1 = [
    {
      name: 'Step 1',
      id: 'step_1',
    },
    {
      name: 'Step 2',
      id: 'step_2',
    },
    {
      name: 'Step 3',
      id: 'step_3',
    },
    {
      name: 'Step 4',
      id: 'step_4',
    },
    {
      name: 'Step 5',
      id: 'step_5',
    },
    {
      name: 'Step 6',
      id: 'step_6',
    },
  ];

  const stepList2 = [
    {
      name: 'Step 1',
      id: 'step_1',
      description:
        "An early evaluation by a lender of a potential home buyer's credit report plus earnings, savings and debt information.",
    },
    {
      name: 'Step 2',
      id: 'step_2',
      description:
        'The Underwriter signed-off on the loan and most of the documentation, but still needs a few more items before fully approving the borrower for the loan.',
    },
    {
      name: 'Step 3',
      id: 'step_3',
      description: `You meet all of your lenders' requirements to qualify for a mortgage, and your mortgage team has been given the green light to move forward with your home loan.`,
    },
    {
      name: 'Step 4',
      id: 'step_4',
      description: `At closing, you'll sign the remaining mortgage documents and pay your down payment and closing costs.`,
    },
  ];

  return (
    <>
      <div className='header-medium my-8'>Horizontal Stepper</div>
      <div className='mb-3 border p-4 rounded-xl'>
        <HorizontalStepComponent width='96px' stepList={stepList} currentStepId='step_1' />
      </div>
      <div className='mb-3 border p-4 rounded-xl'>
        <HorizontalStepComponent width='96px' stepList={stepList1} currentStepId='step_4' />
      </div>
      <div className='mb-3 border p-4 rounded-xl'>
        <HorizontalStepComponent width='96px' stepList={stepList2} currentStepId='step_4' />
      </div>

      <div className='header-medium my-8'>Vertical Stepper (view in mobile)</div>
      <div className='mb-3'>
        <VerticalStepComponent stepList={stepList2} currentStepId='step_2' />
      </div>
      <div className='mb-3'>
        <VerticalStepComponent stepList={stepList2} currentStepId='step_4' />
      </div>

      {[
        { isRefi: false, isVA: false, isRDC: false },
        { isRefi: true, isVA: false, isRDC: false },
        { isRefi: false, isVA: true, isRDC: false },
        { isRefi: true, isVA: true, isRDC: false },
        { isRefi: false, isVA: false, isRDC: true },
      ].map(({ isRefi, isVA, isRDC }, k) => {
        return (
          <div key={k}>
            <div className='header-medium my-8'>{`Milestone tracker -  ${isRefi ? 'Refinance' : 'Purchase'}${
              isVA ? ', VA' : ''
            }${
              isRDC ? ', RDC HTC' : ''
            } Loan`}</div>
            <div className='mb-3'>
              Milestone: {LoanMilestones.APPLICATION}
              <MilestoneTracker isPreapprovalsEnabled={!isRefi} isVA={isVA} isRDC={isRDC} milestone={LoanMilestones.APPLICATION} />
            </div>
            {!isRefi && (
              <>
                <div className='mb-3'>
                  Milestone: {LoanMilestones.PREAPPROVAL}
                  <MilestoneTracker isPreapprovalsEnabled={!isRefi} isVA={isVA} isRDC={isRDC} milestone={LoanMilestones.PREAPPROVAL} />
                </div>
                <div className='mb-3'>
                  Milestone: {LoanMilestones.PREAPPROVED}
                  <MilestoneTracker isPreapprovalsEnabled={!isRefi} isVA={isVA} isRDC={isRDC} milestone={LoanMilestones.PREAPPROVED} />
                </div>
                <div className='mb-3'>
                  Milestone: {LoanMilestones.CONDITIONAL_APPROVAL} w/o pre-approval
                  <MilestoneTracker isPreapprovalsEnabled={!isRefi} isVA={isVA} isRDC={isRDC} milestone={LoanMilestones.CONDITIONAL_APPROVAL} />
                </div>
                <div className='mb-3'>
                  Milestone: {LoanMilestones.CONDITIONAL_APPROVAL} w/ pre-approval
                  <MilestoneTracker
                    isPreapprovalsEnabled={!isRefi}
                    isVA={isVA}
                    isRDC={isRDC}
                    milestone={LoanMilestones.CONDITIONAL_APPROVAL}
                    trackerMilestone={LOAN_STATUS_MAP[LoanMilestones.PREAPPROVED].trackerMilestone}
                  />
                </div>
              </>
            )}
            {isRefi && (
              <>
                <div className='mb-3'>
                  Milestone: {LoanMilestones.CONDITIONAL_APPROVAL}
                  <MilestoneTracker isPreapprovalsEnabled={!isRefi} isVA={isVA} isRDC={isRDC} milestone={LoanMilestones.CONDITIONAL_APPROVAL} />
                </div>
              </>
            )}
            <div className='mb-3'>
              Milestone: {LoanMilestones.CONDITIONALLY_APPROVED}
              <MilestoneTracker isPreapprovalsEnabled={!isRefi} isVA={isVA} isRDC={isRDC} milestone={LoanMilestones.CONDITIONALLY_APPROVED} />
            </div>
            <div className='mb-3'>
              Milestone: {LoanMilestones.CLEAR_TO_CLOSE}
              <MilestoneTracker isPreapprovalsEnabled={!isRefi} isVA={isVA} isRDC={isRDC} milestone={LoanMilestones.CLEAR_TO_CLOSE} />
            </div>
            <div className='mb-3'>
              Milestone: {LoanMilestones.CLOSED}
              <MilestoneTracker isPreapprovalsEnabled={!isRefi} isVA={isVA} isRDC={isRDC} milestone={LoanMilestones.CLOSED} />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default StepperStyleguide;
