import TextBadge from '../badge/TextBadge';
import { StepComponentProps } from './IStep';
import {
  BottomUpcomingStepIcon,
  VerticalCurrentStepIcon,
  TopCompleteStepWidget,
  TopCurrentStepWidget,
  VerticalCompleteStepWidget,
  VerticalCurrentStepWidget,
  VerticalUpcomingStepWidget
} from './StepWidgets';
import cn from 'classnames';

const VerticalStepComponent = ({ stepList, currentStepId }: StepComponentProps ) => {
  const currentIndex = stepList.findIndex(step => step.id === currentStepId);
  return (
    <>
      {stepList.map((step, index) => {
        return (
          <div className='flex mb-0.5' key={step.id}>
            <div className='flex flex-col'>
              {
                index === 0 ?
                (
                  currentIndex === index ? <TopCurrentStepWidget /> :
                  currentIndex < index ? <VerticalUpcomingStepWidget /> :
                  <TopCompleteStepWidget />
                ) :
                index === stepList.length - 1 ? (currentIndex === index ? <VerticalCurrentStepIcon /> : <BottomUpcomingStepIcon />) :
                (
                  currentIndex === index ? <VerticalCurrentStepWidget /> :
                  currentIndex < index ? <VerticalUpcomingStepWidget /> :
                  <VerticalCompleteStepWidget />
                )
              }
            </div>
            <div className={cn('flex flex-col flex-1 ml-3', { 'mb-5': index < stepList.length - 1 })}>
              <div className='flex items-center'>
                <p className='font-bold mr-2 leading-none'>{step.name}</p>
                <TextBadge
                  text={currentIndex === index ? 'Current step' : currentIndex < index ? 'Upcoming' : 'Complete'}
                  type={currentIndex === index ? 'ok' : currentIndex < index ? undefined : 'ok-secondary'}
                />
              </div>
              {step.description && <p className='mt-2'>{step.description}</p>}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default VerticalStepComponent;
