import { ReactNode } from 'react';
import { ServicingConfig } from '../../../hooks/useServicingConfig';
import Address from '../../ui/address/Address';
import Button from '../../ui/button/Button';
import Circle from '../../ui/circle/Circle';
import Icon from '../../ui/icon/Icon';
import { iIconName } from '../../ui/icon/IIcon';
import { PhoneButton } from '../../ui/button/PhoneButton';
import SsoPaymentSubmitButton from './SsoPaymentSubmitButton';
import { sendEvent } from '../../../features/analytics/sendEvent';
import { log } from '../../../utils/logger';
import { Servicer } from '../../../features/servicing/types';
import Tooltip from '../../ui/tooltip/Tooltip';

const ServicingDetailsActionComponent = ({ children }: { children: ReactNode }) => {
  return (
    <div className='flex flex-col flex-1 border-inactive-25 py-6 first:pt-0 last:pb-0 border-b md:border-b-0 md:border-r md:py-0 md:pr-6 md:pl-6 first:pl-0 last:pr-0 last:border-0'>
      {children}
    </div>
  );
};

const ServicingDetailsActionIconText = ({ iconName, size }: { iconName: iIconName, size?: string }) => {
  return (
    <Circle size='45px' textColor='black' bgColor='action-10'>
      <Icon name={iconName} size={size} />
    </Circle>
  );
};

const PaymentButton = (
  {
    servicer,
    text,
    loanGuid,
  }: {
    servicer: Servicer;
    text: string;
    loanGuid: string;
  }
) => {
  const onPaySSOSubmit = () => {
    sendEvent('servicing: payOnlineSSO', { servicerName: servicer.name });
    log({ loanGuid, level: 'info', message: `servicing: payOnlineSSO success ${JSON.stringify({servicerName: servicer.name})}` });
  };

  const onPayOnlineClick = () => {
    sendEvent('servicing: payOnline', { servicerName: servicer.name });
    log({ loanGuid, level: 'info', message: `servicing: payOnline ${JSON.stringify({servicerName: servicer.name})}` });
  };

  const onPaySSOError = () => {
    sendEvent('servicing: payOnlineSSO Error', { servicerName: servicer.name });
    log({ loanGuid, level: 'error', message: `servicing: payOnlineSSO Error ${JSON.stringify({servicerName: servicer.name})}` });
  };

  const paymentUrl = getPaymentUrl(servicer);

  return (
    <div>
      {servicer.ssoEnabled && servicer.shortName && servicer.loanNumber &&
        <SsoPaymentSubmitButton
          text={text}
          servicerShortName={servicer.shortName}
          servicerLoanNumber={servicer.loanNumber}
          loanGuid={loanGuid}
          onSubmit={onPaySSOSubmit}
          onError={onPaySSOError}
        />
      }
      {!servicer.ssoEnabled && paymentUrl &&
        <Button
          buttonType='tertiary'
          text={text}
          iconName='external-link'
          target='_blank'
          href={paymentUrl}
          onClick={onPayOnlineClick}
        />
      }
    </div>
  );
};

const AccessAccountDetailsInProcess = () => {
  return (
    <ServicingDetailsActionComponent>
      <div className='flex items-center mb-4'>
        <ServicingDetailsActionIconText iconName='cheeseburger' />
        <p className='pl-5 font-bold'>Access account details</p>
      </div>
      <p className='mb-3'>Access detailed loan information, account history and statements through your servicing account.</p>
      <Tooltip
        place='bottom'
        defaultColor={false}
        tip='We are setting up your account details now. Please revisit in 2-3 business days for access.'
        id='tooltip-access-account-details-in-process'
      >
        <p className='font-bold text-inactive-100'>Available in 2-3 days.</p>
      </Tooltip>
    </ServicingDetailsActionComponent>
  );
};

const MakePaymentsInProcess = () => {
  return (
    <ServicingDetailsActionComponent>
      <>
        <div className='flex items-center mb-4'>
          <ServicingDetailsActionIconText iconName='dollar-coin' size='1.25rem' />
          <p className='pl-5 font-bold'>Make a payment</p>
        </div>
        <p className='mb-3'>Make secure payments online, by phone or by mail.</p>
        <Tooltip
          place='bottom'
          defaultColor={false}
          tip='We are setting up a secure portal for payments now. Please revisit in 2-3 business days for access.'
          id='tooltip-make-payments-in-process'
        >
          <p className='font-bold text-inactive-100'>Available in 2-3 days.</p>
        </Tooltip>
      </>
    </ServicingDetailsActionComponent>
  );
};

const AccessAccountDetails = ({ config }: { config: ServicingConfig }) => {
  const { servicer, servicingLoan, priorServicer } = config;

  if (!servicer) {
    return null;
  }

  return (
    <ServicingDetailsActionComponent>
      <div className='flex items-center mb-4'>
        <ServicingDetailsActionIconText iconName='cheeseburger' />
        <p className='pl-5 font-bold'>Access account details</p>
      </div>
      <p className='mb-3'>Access detailed loan information, account history and statements through your servicing account.</p>

      {priorServicer ? (
        <div className='flex flex-col'>
          <div className='flex flex-col md:flex-row mb-6 md:mb-4'>
            <div className='w-full md:w-1/2 md:pr-1'>
              <h3 className='font-bold mb-4 md:mb-0'>Current account details</h3>
            </div>
            <div className='w-full md:w-1/2'>
              <PaymentButton text='Access details' servicer={servicer} loanGuid={servicingLoan.loanGuid} />
            </div>
          </div>
          <div className='flex flex-col md:flex-row'>
            <div className='w-full md:w-1/2 md:pr-1'>
              <h3 className='font-bold mb-2 md:mb-1'>Previous account details</h3>
              <p className='text-sm mb-4 md:mb-0'>This account is no longer active, but you can still view past information.</p>
            </div>
            <div className='w-full md:w-1/2'>
              <PaymentButton text='Access details' servicer={priorServicer} loanGuid={servicingLoan.loanGuid} />
            </div>
          </div>
        </div>
      ) : (
        <PaymentButton text='Access details' servicer={servicer} loanGuid={servicingLoan.loanGuid} />
      )}
    </ServicingDetailsActionComponent>
  );
};

const MakePayments = ({ config }: { config: ServicingConfig }) => {
  const { loanGuid, servicer, servicingLoan } = config;

  if (!servicer) {
    return null;
  }

  const onPhonePayClick = () => {
    sendEvent('servicing: payByPhone', { servicerName: servicer?.name });
    log({ loanGuid, level: 'info', message: `servicing: payByPhone ${servicer?.name}` });
  };

  const showOnlinePayment = shouldShowPayOnlineButton(config);

  return (
    <ServicingDetailsActionComponent>
      <div className='flex items-center'>
        <ServicingDetailsActionIconText iconName='dollar-coin' size='1.25rem' />
        <p className='pl-5 font-bold'>Make a payment</p>
      </div>
      {showOnlinePayment && <div className='flex items-start justify-between mt-4 gap-x-4 flex-col md:flex-row'>
        <div className='flex flex-col w-full mb-3 md:w-1/2 md:mb-0'>
          <p className='font-bold mb-1'>Pay online</p>
          <p className='text-sm'>There is no fee.</p>
        </div>
        <div className='w-full md:w-1/2'>
          <PaymentButton
            text='Pay online'
            servicer={servicer}
            loanGuid={servicingLoan.loanGuid}
          />
        </div>
      </div>}
      {servicer.phone && <div className='flex items-start justify-between mt-4 gap-x-4 flex-col md:flex-row'>
        <div className='flex flex-col w-full mb-3 md:w-1/2 md:mb-0'>
          <p className='font-bold mb-1'>Pay by phone</p>
          <p className='text-sm'>There may a fee to pay by phone.</p>
        </div>
        <div className='w-full md:w-1/2'>
          <PhoneButton buttonType='tertiary' phone={servicer.phone} onClick={onPhonePayClick} />
        </div>
      </div>}
      {servicer.address && <div className='flex items-start justify-between mt-4 gap-x-4 flex-col md:flex-row'>
        <div className='flex flex-col w-full mb-3 md:w-1/2 md:mb-0'>
          <p className='font-bold mb-1'>Pay by mail</p>
          <p className='text-sm'>Please attach the payment coupon included in your most recent statement.</p>
        </div>
        <div className='w-full md:w-1/2'>
          <Address {...servicer.address} />
        </div>
      </div>}
    </ServicingDetailsActionComponent>
  );
};

const MakePaymentsRow = ({ config }: { config: ServicingConfig }) => {
  const { loanGuid, servicer, servicingLoan } = config;

  if (!servicer) {
    return null;
  }

  const onPhonePayClick = () => {
    sendEvent('servicing: payByPhone', { servicerName: servicer?.name });
    log({ loanGuid, level: 'info', message: `servicing: payByPhone ${servicer?.name}` });
  };

  const showOnlinePayment = shouldShowPayOnlineButton(config);

  return (
    <>
      {showOnlinePayment && <ServicingDetailsActionComponent>
        <div className='flex flex-col'>
          <div className='flex items-center mb-2'>
            <ServicingDetailsActionIconText iconName='desktop' size='1.5rem' />
            <p className='pl-5 font-bold'>Pay online</p>
          </div>
          <p className='mb-4'>Schedule automatic payments or make a one-time payment.</p>
          <PaymentButton
            text='Pay online'
            servicer={servicer}
            loanGuid={servicingLoan.loanGuid}
          />
        </div>
      </ServicingDetailsActionComponent>}
      {servicer.phone && <ServicingDetailsActionComponent>
        <div className='flex flex-col'>
          <div className='flex items-center mb-2'>
            <ServicingDetailsActionIconText iconName='support-call' size='1.5rem' />
            <p className='pl-5 font-bold'>Pay by phone</p>
          </div>
          <p className='mb-4'>Call our automated service or speak to an expert customer service agent.</p>
          <PhoneButton buttonType='tertiary' phone={servicer.phone} onClick={onPhonePayClick} />
        </div>
      </ServicingDetailsActionComponent>}
      {servicer.address && <ServicingDetailsActionComponent>
        <div className='flex flex-col'>
          <div className='flex items-center mb-2'>
            <ServicingDetailsActionIconText iconName='mailbox' size='1.5rem' />
            <p className='pl-5 font-bold'>Pay by mail</p>
          </div>
          <p className='mb-4'>Please attach the payment coupon included in your most recent statement.</p>
          <Address {...servicer.address} />
        </div>
      </ServicingDetailsActionComponent>}
    </>
  );
};

const ActionInProgress = () => {
  return (
    <>
      <p className='font-bold text-2xl mb-6'>What would you like to do today?</p>
      <div className='flex flex-col md:flex-row'>
        <AccessAccountDetailsInProcess />
        <MakePaymentsInProcess />
      </div>
    </>
  );
};

const ActionAccessDetails = ({ config }: { config: ServicingConfig }) => {
  const { servicer } = config;

  if (!servicer || !shouldShowAccessAccountDetails(config)) {
    return null;
  }

  return (
    <>
      <p className='font-bold text-2xl mb-6'>What would you like to do today?</p>
      <div className='flex flex-col md:flex-row'>
        <AccessAccountDetails config={config} />
      </div>
    </>
  );
};

const ActionAccessDetailsMakePayment = ({ config }: { config: ServicingConfig }) => {
  const { servicer } = config;

  if (!servicer) {
    return null;
  }

  const showAccessDetails = shouldShowAccessAccountDetails(config);

  return (
    <>
      {showAccessDetails
        ? <p className='font-bold text-2xl mb-6'>What would you like to do today?</p>
        : <p className='font-bold text-2xl mb-6'>Make a payment</p>
      }
      <div className='flex flex-col md:flex-row'>
        {showAccessDetails &&
          <>
            <AccessAccountDetails config={config} />
            <MakePayments config={config} />
          </>
        }
        {!showAccessDetails &&
          <MakePaymentsRow config={config} />
        }
      </div>
    </>
  );
};

const shouldShowAccessAccountDetails = (config: ServicingConfig) => {
  const { servicer } = config;

  // don't show if servicer is undefined
  if (!servicer) return false;

  // if any other exp and SSO is not enabled and website is undefined
  if (servicer.ssoEnabled || servicer.website) {
    return true;
  }

  return false;
};

const shouldShowPayOnlineButton = (config: ServicingConfig) => {
  return !!config.servicer?.ssoEnabled || getPaymentUrl(config.servicer);
};

const getPaymentUrl = (servicer: Servicer | undefined) => {
  if (!servicer) return undefined;
  return servicer.paymentUrl || servicer.website;
};

const ServicingDetailsActions = ({ config }: { config: ServicingConfig }) => {
  const { servicing, servicer } = config;

  // if exp 8 - no action
  if ([8].includes(servicing.experienceInd)) {
    return null;
  }

  return (
    <div className='flex flex-col'>
      <>
        {[1].includes(servicing.experienceInd) &&
          <ActionInProgress />
        }
        {[3, 5, 7].includes(servicing.experienceInd) && servicer &&
          <ActionAccessDetails config={config} />
        }
        {[2, 4, 6].includes(servicing.experienceInd) && servicer &&
          <ActionAccessDetailsMakePayment config={config} />
        }
      </>
    </div>
  );
};

export default ServicingDetailsActions;
