import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../store/types';
import { AuthState, ValidateAuthResponse } from './types';
import api from '../api';
import { log } from '../../utils/logger';
import submitForm from '../../utils/submitForm';
import timestamp from '../../utils/timestamp';
import { useAppSelector } from '../../hooks/hooks';
import { clearNavigationBlockers, updateGlobalError } from '../app/appSlice';
import { AxiosError } from 'axios';
import { RequestError } from '../../interfaces/IRequest';

const namespace = 'auth';

const initialState: AuthState = {
  isAuth: false,
  lastValidated: null,
};

export const validateAuth = createAsyncThunk<ValidateAuthResponse, undefined>(
  `${namespace}/validateAuth`,
  async (_, { dispatch }) => {
    try {
      const response = await api.getAuth();
      return response.data;
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      log({ level: 'error', message: `validateAuth error - ${error}` });
      dispatch(updateGlobalError(error.response?.data || 'Validate auth error'));
      throw err;
    }
  },
);

export const unauthenticate = (): AppThunk => (_dispatch, getState) => {
  const state = getState();
  const blockers = state.app.navigationBlockers;
  for (const blocker of blockers) {
    if (blocker.shouldBlockNavigation()) {
      log({ level: 'info', message: `logout blocked by navigation blocker ${blocker.id}` });
      blocker.onNavigationBlocked('/logout');
      return;
    }
  }
  // logout
  submitForm({ action: '/auth-logout' });
};

export const forceLogout =
  (causedBy: string): AppThunk =>
  (dispatch, getState) => {
    // if user isAuth in app state but is being forced to logout, then log
    // this mitigates a false positive during app initialization for unauthed user
    if (getState().auth.isAuth) {
      log({ message: `forceLogout - caused by ${causedBy}` });
      dispatch(clearNavigationBlockers());
      dispatch(unauthenticate());
    }
  };

export const authSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    logout: state => {
      state.isAuth = false;
      state.lastValidated = timestamp();
    },
  },
  extraReducers: builder => {
    builder.addCase(validateAuth.fulfilled, (state, { payload }) => {
      state.isAuth = payload.auth;
      state.lastValidated = timestamp();
      if (payload['session-id']) state.sessionId = payload['session-id'];
      if (payload['user-email']) state.userEmail = payload['user-email'];
      if (payload['user-id']) state.userId = payload['user-id'];
    });
    builder.addCase(validateAuth.rejected, state => {
      state.isAuth = false; // Setting rejected state to false, stops the app spinner
      state.lastValidated = timestamp();
    });
  },
});

export const { logout } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;

export const useAuth = () => useAppSelector(selectAuthState);

export const selectIsAuth = (state: RootState) => !!selectAuthState(state).isAuth;

export const useIsAuth = () => useAppSelector(selectIsAuth);

export const selectLastValidated = (state: RootState) => selectAuthState(state).lastValidated;

export const selectIsAuthValidated = (state: RootState) => !!selectLastValidated(state);

export const useIsAuthValidated = () => useAppSelector(selectIsAuthValidated);

export default authSlice.reducer;
