/**
 * Format a string from `'123-456-7890'` to `'(123) 456-7890'`
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  phoneNumber = phoneNumber || '';
  const match = phoneNumber.match(/[\d]{3}-[\d]{3}-[\d]{4}/)?.[0];

  if (match) {
    return `(${match.slice(0, 3)}) ${match.slice(4)}`;
  }
  return phoneNumber;
};

/**
 * Unformat a string from `'(123) 456-7890'` to `'123-456-7890'`
 */
export const unformatPhoneNumber = (phoneNumber: string) => {
  phoneNumber = phoneNumber || '';
  const match = phoneNumber.match(/\([\d]{3}\) [\d]{3}-[\d]{4}/)?.[0];

  if (match) {
    return `${match.slice(1, 4)}-${match.slice(6)}`;
  }
  return phoneNumber;
};
