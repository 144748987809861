import { SavedHome } from '../../../features/homeSearch/homeSearchSlice';
import NewListings from '../../homeSearch/NewListings';
import { SavedHomesComponentPresentational } from '../../homeSearch/SavedHomes';

const SavedHomesStyleguide = () => {
  const savedHomes: SavedHome[] = [
    {
      listingPrice: 4000000,
      propertyAddress: {
        streetAddress: '123 Main St.'
      },
      propertyImages: ['https://images-15.housecanary.com/d978/6e/66c0a156626a520007e6879d.jpg', 'https://images-15.housecanary.com/e978/6e/66c0a156626a520007e6879e.jpg'],
      propertyInfo: {
        numberOfBedrooms: 4,
        fullBathCount: 3,
        buildingAreaSqFt: '2000'
      },
      propertyUrl: 'https://rate.demo.ch.housecanary.net/property-details/1776-Summer-Cloud-Ct-Folsom-CA-95630',
    },
    {
      listingPrice: 698000,
      propertyAddress: {
        streetAddress: '252 Sequim Cmn'
      },
      propertyImages: ['https://images-15.housecanary.com/a4a0/94/66e0c6b1226cd80007490a4a.jpg', 'https://images-15.housecanary.com/b4a0/94/66e0c6b1226cd80007490a4b.jpg'],
      propertyInfo: {
        numberOfBedrooms: 2,
        fullBathCount: 2,
        buildingAreaSqFt: '897'
      },
      propertyUrl: 'https://rate.demo.ch.housecanary.net/property-details/252-Sequim-Cmn-Fremont-CA-94539',
    },
    {
      listingPrice: 499000,
      propertyAddress: {
        streetAddress: '436 Dempsey Rd'
      },
      propertyImages: ['https://images-15.housecanary.com/198c/0c/66da377c0c47e70007c0c891.jpg', 'https://images-15.housecanary.com/298c/0c/66da377c0c47e70007c0c892.jpg'],
      propertyInfo: {
        numberOfBedrooms: 1,
        fullBathCount: 1,
        buildingAreaSqFt: '676'
      },
      propertyUrl: 'https://rate.demo.ch.housecanary.net/property-details/436-Dempsey-Rd-Unit-139-Milpitas-CA-95035',
    },
  ];
  return (
    <>
      <div className='header-medium mb-8'>Homes search</div>
      <SavedHomesComponentPresentational homes={savedHomes} />
      <NewListings hidden={false} onReady={() => console.log('New Listings ready')} />
    </>
  );
};

export default SavedHomesStyleguide;
