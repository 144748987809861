import { IMaskInput } from 'react-imask';
import cn from 'classnames';

export interface FormPhoneInputProps {
  className?: string;
  hasError?: boolean;
  [x: string]: any;
}

const FormPhoneInput = (props: FormPhoneInputProps) => {
  const { className, onChange, onFocus, onBlur, onAnimationStart, disabled, value, id } = props;

  const onPhoneAccept = (value: string) => {
    onChange?.(value);
  };

  return (
    <IMaskInput
      id={id}
      mask={'(XXX) XXX-XXXX'}
      blocks={{
        X: {
          mask: '0',
        }
      }}
      className={cn('form-input', className)}
      onAccept={onPhoneAccept}
      onAnimationStart={onAnimationStart}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled}
      value={value}
      unmask={true}
    />
  );
};

export default FormPhoneInput;
