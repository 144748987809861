import { useEffect, useState, useMemo, useCallback, ReactNode } from 'react';
import { Subject } from 'rxjs';
import { ModalState } from './iTaskModal';
import Modal from '../../ui/modal/Modal';
import { setTaskModalOpen } from '../../../features/tasks/actions';
import { useAppDispatch } from '../../../hooks/hooks';

export const taskModalSubject: Subject<ModalState> = new Subject();

export const setModalContent = (taskId: string, modalContent: ReactNode) =>
  taskModalSubject.next({ id: taskId, content: modalContent });

export const openModal = (taskId: string, modalContent: ReactNode, contentLabel: string) => {
  taskModalSubject.next({ id: taskId, open: true, content: modalContent, contentLabel });
};

export const closeModal = (taskId: string) => taskModalSubject.next({ id: taskId, open: false });

const initialState = {
  id: null,
  open: false,
} as ModalState;

export const TaskModal = () => {
  const [modal, setModal] = useState<ModalState>(initialState);
  const dispatch = useAppDispatch();

  const handleModalClose = (): void => {
    taskModalSubject.next({ ...modal, open: false });
  };

  const handleModalClosed = (): void => {
    taskModalSubject.next(initialState);
  };

  const handleModalSubject = useCallback(
    (modalUpdate: ModalState) => {
      // Prevent modal updates when modal isn't open
      if (!modal.open && modalUpdate.open === null) {
        return;
      }

      dispatch(setTaskModalOpen(!!modalUpdate.open));

      setModal({
        ...modal,
        ...modalUpdate,
      });
    },
    [modal, setModal, dispatch],
  );

  useEffect(() => {
    const subscription = taskModalSubject.subscribe(handleModalSubject);
    return () => {
      subscription.unsubscribe();
    };
  }, [handleModalSubject]);

  return (
    <Modal
      open={modal.open}
      contentLabel={modal.contentLabel || 'Task Modal'}
      fixScrollOnOpen={true}
      onRequestClose={() => handleModalClose()}
      onClosed={() => handleModalClosed()}
    >
      {useMemo(() => modal.content, [modal.content])}
    </Modal>
  );
};

export default TaskModal;
