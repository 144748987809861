import { useState } from 'react';
import cn from 'classnames';
import './Image.scss';

type ImageProps =
  | {
      src: string;
      alt: string;
      square: boolean;
      placeholder?: string;
      containerClassNames?: string;
      imgClassNames?: string;
      [x: string]: any;
    }
  | {
      src: string;
      alt: string;
      placeholder?: string;
      imgClassNames?: string;
      // if not a square, then don't allow containerClassNames
      square?: never;
      containerClassNames?: never;
      [x: string]: any;
    };

/**
 * Basic image component to be developed further as necessary,
 * for example, lazy loading, abort src request, onload blur, etc.
 */
export const Image = ({
  src,
  alt,
  imgClassNames,
  containerClassNames,
  square,
  placeholder,
  ...restImageProps
}: ImageProps) => {
  const [source, setSource] = useState(placeholder || src);

  return (
    <div
      className={cn(containerClassNames, {
        image: square,
        relative: square,
        'inline-block': !square
      })}
    >
      {placeholder && (
        <img
          className='hidden'
          src={src}
          alt=''
          onLoad={() => setSource(src)}
        />
      )}
      <img
        src={source}
        alt={alt}
        className={
          square
            ? `absolute object-cover w-full h-full ${imgClassNames}`
            : imgClassNames
        }
        {...restImageProps}
      />
    </div>
  );
};
