import { useLocation } from 'react-router-dom';
import { buildTab } from '../../pages/loan/LoanNav';
import { PATHS } from '../routes/paths';
import { NavigationTab, NavigationTabProps } from './SubpageNavigation';
import { useSelector } from 'react-redux';
import { isALoanFunded } from '../../features/loans/loansSlice';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { isLocalDev } from '../../utils/env';
import { getConfigFromStore } from '../../features/config/getConfigFromStore';

const Tab = (tab: NavigationTabProps) => {
  return (
    <NavigationTab textClassName='mx-4 text-base' {...tab} />
  );
};

export const TopNavigation = () => {
  const config = getConfigFromStore();
  const canShowHomeValue = useSelector(isALoanFunded);
  const isDashboardEnabled = useFeatureEnabled('dashboard');
  const isHomeValueEnabled =
    (useFeatureEnabled('home-value') && canShowHomeValue) || (isLocalDev && process.env.REACT_APP_ENABLE_HOME_VAL);
  const isHomeSearchEnabled = useFeatureEnabled('home-search') && config?.links['home-search'];
  const isInsuranceLandingEnabled = useFeatureEnabled('insurance-landing');
  const { pathname } = useLocation();
  const tabs = [];

  if (isDashboardEnabled) {
    tabs.push(buildTab('Dashboard', PATHS.dashboard, pathname, undefined, false));
  }

  tabs.push(buildTab('Loans', PATHS.loanCenter, pathname, undefined, false));

  if (isHomeSearchEnabled) {
    tabs.push(buildTab('Home Search', PATHS.homeSearch, pathname, undefined, false));
  }

  if (isInsuranceLandingEnabled) {
    tabs.push(buildTab('Insurance', PATHS.insurance, pathname, undefined, false));
  }

  if (isHomeValueEnabled) {
    tabs.push(buildTab('Home value', PATHS.homeValue, pathname, undefined, false));
  }

  if (isDashboardEnabled) {
    tabs.push(buildTab('Financial solutions', PATHS.financialSolutions, pathname, undefined, false));
  }

  return (
    <div className='flex flex-row w-full items-center'>
      {tabs.map((tab, index) => {
        return <Tab key={index} {...tab} activeStyle='underline' />;
      })}
    </div>
  );
};
