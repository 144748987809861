import { useState, useEffect } from 'react';

const loadedScripts: string[] = [];

interface ScriptState {
  loaded: boolean;
  hasError: boolean;
  error?: null | ErrorEvent;
}

interface UseScriptProps extends Partial<HTMLScriptElement> {
  skip?: boolean;
}

const useScript = ({ async, defer, crossOrigin, src, skip }: UseScriptProps) => {
  // Keeping track of script loaded and error state
  const [state, setState] = useState<ScriptState>({
    loaded: false,
    hasError: false,
  });

  useEffect(() => {
    if (skip) return;

    // If loadedScripts array already includes src that means another instance ...
    // ... of this hook already loaded this script, so no need to load again.
    if (!src) {
      setState({
        loaded: false,
        hasError: true,
        error: new ErrorEvent('missing src')
      });
    } else if (loadedScripts.includes(src)) {
      setState({
        loaded: true,
        hasError: false,
        error: null,
      });
    } else {
      loadedScripts.push(src);

      // Create script
      const script = document.createElement('script');

      async !== undefined && script.setAttribute('async', !!async ? 'true' : 'false');
      defer !== undefined && script.setAttribute('defer', !!defer ? 'true' : 'false');
      crossOrigin && script.setAttribute('crossorigin', crossOrigin);

      script.setAttribute('src', src);

      // Script event listener callbacks for load and error
      const onScriptLoad = () => {
        setState({
          loaded: true,
          hasError: false,
          error: null,
        });
      };

      // https://developer.mozilla.org/en-US/docs/Web/API/ErrorEvent
      const onScriptError = (errorEvent: ErrorEvent) => {
        // Remove from loadedScripts we can try loading again
        const index = loadedScripts.indexOf(src);
        if (index >= 0) loadedScripts.splice(index, 1);
        script.remove();

        setState({
          loaded: true,
          hasError: true,
          error: errorEvent,
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      // Add script to document body
      document.body.appendChild(script);

      // Remove event listeners on cleanup
      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [async, defer, crossOrigin, src, skip]);
  return [state.loaded, state.hasError, state.error];
};

export default useScript;
