import DownloadButton from '../../loan/documents/DownloadButton';
import FilenameWidget from './FilenameWidget';

interface Props {
  filename: string;
  fileUrl: string;
  showDownloadIcon: boolean;
  onFilenameClick: () => void;
  onDownloadClick: () => void;
}

const DownloadFile = ({ filename, fileUrl, showDownloadIcon, onFilenameClick, onDownloadClick }: Props) => {
  return (
    <div className='flex justify-between items-center gap-2 h-12'>
      <FilenameWidget
        filename={filename}
        fileUrl={fileUrl}
        visible={true}
        onClick={onFilenameClick}
      />
      {showDownloadIcon &&
        <DownloadButton
          href={fileUrl}
          filename={filename}
          onClick={onDownloadClick}
        />
      }
    </div>
  );
};

export default DownloadFile;
