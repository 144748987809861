import { useEffect } from "react";
import { withTransaction } from "@elastic/apm-rum-react";
import { useAppDispatch } from "../../hooks/hooks";
import { updateGlobalLoading } from "../../features/app/appSlice";
import { unauthenticate } from "../../features/auth/authSlice";

const Logout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateGlobalLoading(true));
    dispatch(unauthenticate());
  }, [dispatch]);

  return null;
}

export default withTransaction('Logout', 'page-load')(Logout);