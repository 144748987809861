export const PaymentTracker = ({
  totalAmount,
  amountRemaining,
  className,
}: {
  totalAmount: number;
  amountRemaining: number;
  className: string;
}) => {
  const percentRemaining = totalAmount ? (amountRemaining / totalAmount) * 100 : 100;
  return (
    <div className={`flex ${className}`}>
      <div className='border border-ok' style={{ width: `${100 - percentRemaining}%` }} />
      <div className='border border-gray-25' style={{ width: `${percentRemaining}%` }} />
    </div>
  );
};
