import cn from 'classnames';
import { IDataItem } from './IDataItem';
import Address from '../../address/Address';

const DataItem = (props: IDataItem) => {
  const { 'data-hj-allow': hjAllow } = props;
  const hasValue = props.value || props.address;
  return hasValue ? (
    <div className={cn('dlist-item', props.labelClassName, `order-${props.order || 'last'}`)}>
      <dt className={cn('dlist-label text-sm lg:text-base', {'flex items-center': !!props.tooltip})}>
        {props.label}
        {props.tooltip && <div className='inline-block ml-1'>
          {props.tooltip}
        </div>}
      </dt>
      <dd className={cn('dlist-value text-sm lg:text-base font-bold', props.valueClassName)} data-qa={props['data-qa']} {...((!!hjAllow || hjAllow === '') && { 'data-hj-allow': '' })}>
        {props.value || <Address {...props.address} />}
      </dd>
    </div>
  ) : null;
};

export default DataItem;
