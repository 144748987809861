import { Loan } from '../features/loans/types';

const RATE_INSURANCE_EXCEPTION_STATES = new Set(['MI', 'MN', 'ND', 'NY', 'TX']);

export const getInsuranceCompanyName = (loan: undefined | Loan): string => {
  const state = loan?.property.state;

  if (state && RATE_INSURANCE_EXCEPTION_STATES.has(state)) {
    return 'Rate Insurance Agency, LLC';
  }

  return 'Rate Insurance, LLC';
};
