import { useMemo, useState, useEffect } from 'react';
import type { RootState } from '../../../store/types';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  fetchDocuments,
  selectDocuments,
  selectDocumentsMetadata,
} from '../../../features/documents/documentsSlice';
import { iDocument } from '../../../features/documents/types';
import Sorter from '../../ui/sorter/Sorter';
import { Skeleton } from '../../ui/skeleton/Skeleton';
import Document from './Document';

export const DocumentsSkeletons = () => {
  return (
    <div className='pt-4'>
      <Skeleton className='mb-2 w-full' height='30px' />
      <Skeleton className='mb-2 w-full' height='30px' />
    </div>
  );
};

const documentsSorter = (desc: boolean) => (doc1: iDocument, doc2: iDocument) => {
  return desc ? doc2['date-added']?.localeCompare(doc1['date-added']) : doc1['date-added']?.localeCompare(doc2['date-added']);
};

const DocumentsView = ({ loanGuid }: { loanGuid: string }) => {
  const dispatch = useAppDispatch();
  const documents = useAppSelector((state: RootState) => selectDocuments(state, loanGuid));

  const [signingDocsDesc, setSigningDocsDesc] = useState(true);
  const [uploadDocsDesc, setUploadDocsDesc] = useState(true);

  const signingDocuments = useMemo(
    () => documents?.filter(doc => doc.category === 'signing-documents').sort(documentsSorter(signingDocsDesc)),
    [documents, signingDocsDesc],
  );
  const uploadedDocuments = useMemo(
    () => documents?.filter(doc => doc.category === 'upload-documents').sort(documentsSorter(uploadDocsDesc)),
    [documents, uploadDocsDesc],
  );
  const { loadingStatus } = useAppSelector((state: RootState) => selectDocumentsMetadata(state, loanGuid));
  const shouldShowDocumentsSkeleton = ['idle', 'pending'].includes(loadingStatus);

  useEffect(() => {
    dispatch(fetchDocuments(loanGuid));
  }, [dispatch, loanGuid]);

  return (
    <div>
      <div className='border-b border-gray-25 pb-8'>
        <div className='flex flex-row justify-between items-end pb-2'>
          <div className='text-2xl font-bold'>Documents</div>
          {signingDocuments?.length ? <Sorter label="Date" desc={signingDocsDesc} onClick={() => setSigningDocsDesc(!signingDocsDesc)} /> : null}
        </div>
        {shouldShowDocumentsSkeleton ? (
          <DocumentsSkeletons />
        ) : !signingDocuments?.length ? (
          <p className='pt-4'>Documents created by your loan team will appear here.</p>
        ) : (
          signingDocuments.map(document => <Document key={document.id} document={document} />)
        )}
      </div>

      <div className='pt-8 pb-8'>
        <div className='flex flex-row justify-between items-end pb-2'>
          <div className='text-2xl font-bold'>Uploads</div>
          {uploadedDocuments?.length ? <Sorter label="Date" desc={uploadDocsDesc} onClick={() => setUploadDocsDesc(!uploadDocsDesc)} /> : null}
        </div>
        {shouldShowDocumentsSkeleton ? (
          <DocumentsSkeletons />
        ) : !uploadedDocuments?.length ? (
          <p className='pt-4'>Documents uploaded by you will appear here.</p>
        ) : (
          uploadedDocuments.map(document => <Document key={document.id} document={document} />)
        )}
      </div>
    </div>
  );
};

export default DocumentsView;
