import { debug } from '../../utils/env';

declare global {
  interface Window {
    adobe?: {
      target?: any;
    };
  }
}

const adobeTarget = () => window.adobe?.target;

export const triggerView = (viewName: string | null): void => {
  if (debug) {
    const flag = '⏱️';
    console.log(`${flag}%c AdobeTarget.triggerView:`, 'color: blue', viewName);
  }
  adobeTarget()?.triggerView(viewName);
};