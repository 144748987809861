import HomeValueEstimate from '../homeValueEstimate/HomeValueEstimate';
import HomeDetails from '../homeDetails/HomeDetails';
import HomeValueTitle from '../homeValueTitle/HomeValueTitle';
import { HomeValueData } from '../../../pages/homeValue/IHomeValue';
import HomeValueFAQs from '../homeValueFAQs/HomeValueFAQs';
import HomeValueDisclaimer from '../homeValueDisclaimer/HomeValueDisclaimer';
import { HomeValueUserAddress } from '../../../features/homeValue/types';
import { useSetRouteState } from '../../../features/RouteContext';
import { NAMES } from '../../routes/paths';

const HomeValueResults = ({ data, address }: { data: HomeValueData, address: HomeValueUserAddress }) => {
  useSetRouteState({ name: NAMES.homeValue, subview: 'results' });
  return (
    <>
      <HomeValueTitle data={data} address={address} />
      <HomeValueEstimate data={data} address={address} />
      <HomeDetails data={data} address={address} />
      <HomeValueFAQs />
      <HomeValueDisclaimer />
    </>
  );
};

export default HomeValueResults;
