import { useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './hooks';
import useFeatureEnabled from './useFeatureEnabled';
import { selectIsAuth } from '../features/auth/authSlice';
import { selectHasUser, selectUser } from '../features/user/userSlice';
import {
  selectHasInitialData,
  updateAppLanguage,
  selectAppLanguage,
  reloadAppLanguage,
  selectGlobalEmbed,
} from '../features/app/appSlice';
import { selectConfig } from '../features/config/configSlice';
import { homeLoanPaths, PATHS } from '../components/routes/paths';
import { ILoanRouteParams } from '../interfaces/IRouteParams';
import { DEFAULT_LANGUAGE } from '../utils/multilingual';
import { log, LoggerLevel } from '../utils/logger';
import { multilingual } from '../utils/browser';
import useError from './useError';

export const CAN_BE_TRANSLATED_URLS = [
  'https://www.rate.com',
  'https://www.rate.com/servicing',
  'https://www.rate.com/accessibility',
  'https://www.rate.com/licensing',
  'https://www.rate.com/notice-to-vendor',
  'https://www.rate.com/privacy',
  'https://www.rate.com/sms-terms',
  'https://www.rate.com/terms',
  'https://www.rate.com/texas-consumers-how-to-file-complaint',
];

export const urlCanBeTranslated = (href: string): boolean =>
  !!CAN_BE_TRANSLATED_URLS.find(
    (url: string) => url.replace('https://www.', 'https://') === href.replace('https://www.', 'https://'),
  );

// app language is not set or is in English
export const appIsDefaultLanguage = (appLanguage: string | null) => appLanguage === null || appLanguage === DEFAULT_LANGUAGE;

export const SUPPORT_ES_URLS = [...CAN_BE_TRANSLATED_URLS, 'https://rate.talkuments.com?l=es'];

export const urlSupportsEs = (href: string): boolean =>
  !!SUPPORT_ES_URLS.find(
    (url: string) => url.replace('https://www.', 'https://') === href.replace('https://www.', 'https://'),
  );

export const MULTILINGUAL_PATHS = [
  PATHS.home,
  PATHS.dashboard,
  PATHS.loanCenter,
  PATHS.registration,
  PATHS.homeValue,
  PATHS.homeSearch,
  PATHS.profile,
  PATHS.insurance,
  PATHS.login,
  PATHS.logout,
  PATHS.helocLoan,
  PATHS.helocLoanServicing,
  PATHS.personalLoan,
  ...homeLoanPaths,
];

export const multilingualLink = (url: string, language: string | null) => {
  if (!language) return false;
  try {
    const urlObject = new URL(url);

    if (decodeURIComponent(urlObject.search).includes(`language=${language}`)) {
      return true;
    }

    if (decodeURIComponent(urlObject.search).includes(`langPref=${language}`)) {
      return true;
    }

    if (url.startsWith(`${urlObject.origin}/${language}/`)) {
      return true;
    }
  } catch {}

  return false;
};

export const useMultilingual = (): boolean => {
  const multilingualEnabled = useFeatureEnabled('multilingual');
  const { isNotFound, isGlobalError, isErrorPage } = useError();
  const multilingualRouteMatch = useRouteMatch<ILoanRouteParams>({
    path: MULTILINGUAL_PATHS,
    exact: true,
  });
  return multilingualEnabled && (!!multilingualRouteMatch || isNotFound || isGlobalError || isErrorPage);
};

const isValidLanguage = (language: string, languages?: string[]): boolean => !!languages?.includes(language);

export const useSetInitialLanguage = () => {
  const dispatch = useAppDispatch();
  const multilingualEnabled = useFeatureEnabled('multilingual');
  const appLanguage = useAppSelector(selectAppLanguage);
  const hasInitData = useAppSelector(selectHasInitialData);
  const isAuth = useAppSelector(selectIsAuth);
  const hasUser = useAppSelector(selectHasUser);
  const user = useAppSelector(selectUser);
  const config = useAppSelector(selectConfig);
  const globalEmbed = useAppSelector(selectGlobalEmbed);
  const languages = config?.languages;

  // Browser multilingual
  const { langPref: langPrefCookie, language: browserLanguageValue } = multilingual();

  // Language set by user endpoint
  const userLanguagePreferenceValue = user?.['language-preference'];
  const userLanguagePreference =
    userLanguagePreferenceValue && isValidLanguage(userLanguagePreferenceValue, languages)
      ? userLanguagePreferenceValue
      : undefined;

  // Language set by browser
  const browserLanguage = browserLanguageValue !== undefined && isValidLanguage(browserLanguageValue, languages) ? browserLanguageValue : DEFAULT_LANGUAGE;

  const logger = useCallback(
    (msg: string, level: LoggerLevel = 'info') => {
      log({
        message: `${msg} ${JSON.stringify({
          DEFAULT_LANGUAGE,
          langPrefCookie,
          browserLanguage,
          userLanguagePreference,
        })}`,
        level,
      });
    },
    [langPrefCookie, browserLanguage, userLanguagePreference],
  );

  // Cookie is the only thing that can properly initialize the app's language
  useEffect(() => {
    if (!multilingualEnabled || appLanguage) return;

    const language = langPrefCookie || DEFAULT_LANGUAGE;

    logger(`useSetInitialLanguage: Initializing app language as "${language}"`);
    dispatch(updateAppLanguage(language));

    // DEFAULT_LANGUAGE
  }, [dispatch, logger, multilingualEnabled, appLanguage, langPrefCookie]);

  // When user is not authenticated, determine if cookie is set properly:
  // language is determined by browser.
  // If cookie is null and browserLanguage is not English, reset the language cookie.
  // When cookie is set, trust cookie.
  // When cookie is not set and browser language is English, do nothing.

  // When user is authenticated, determine if cookie is set properly:
  // language is determined by user endpoint (borrower entity)
  // If cookie is null and user language preference is not English and not running in embed mode, reset the language cookie.
  // When cookie is set, trust cookie.
  // When cookie is not set and user language preference is English, do nothing.
  useEffect(() => {
    if (!multilingualEnabled || !hasInitData || langPrefCookie) return;

    const language = isAuth && hasUser ? userLanguagePreference : !isAuth ? browserLanguage : undefined;

    if (language && language !== DEFAULT_LANGUAGE && !globalEmbed) {
      logger(
        `useSetInitialLanguage: langPref cookie is not set. Setting cookie and reloading app with language "${language}"`,
        'info',
      );
      dispatch(reloadAppLanguage(language));
    }
  }, [
    logger,
    dispatch,
    multilingualEnabled,
    hasInitData,
    langPrefCookie,
    isAuth,
    hasUser,
    userLanguagePreference,
    browserLanguage,
    globalEmbed,
  ]);

  return null;
};
