import cn from 'classnames';
import './Skeleton.scss';

type SkeletonProps = {
  height?: string;
  className?: string;
  style?: React.CSSProperties;
};

type SkeletonGroupProps = SkeletonProps & {
  columnClassName?: string,
  columnCount: number,
  wrapMobile: boolean,
};

/**
 * Skeleton default has a height of h-6 (1.5rem/24px)
 * There are 3 ways to change this with these precedent
 * 1. Use a classname to override h-6, a classname shorter than h-6 has no effect
 * 2. Pass a "height" prop to Skeleton
 * 3. Pass the style prop with height
 */
export const Skeleton = ({ height, className, style }: SkeletonProps) => {
  return (
    <div
      className={cn('skeleton relative rounded h-6 overflow-hidden', className)}
      style={{
        height: height,
        ...style,
      }}
    />
  );
};

export const SkeletonGroup = ({ height, columnCount, wrapMobile, columnClassName, className, style }: SkeletonGroupProps) => {
  const gridColumnCss = `grid-cols-${columnCount}`;
  return (
    <div
      className={
        cn('grid w-full gap-2',
          className,
          { [`${gridColumnCss}`]: !wrapMobile },
          { [`grid-cols-1 lg:${gridColumnCss}`]: wrapMobile },
        )
      }
      style={{
        ...style,
      }}
    >
      {Array(columnCount).fill(0).map((_, index) => (
        <div key={index} className={
          cn('skeleton relative rounded h-6 overflow-hidden', columnClassName)}
          style={{
            height: height,
          }}
        />
      ))}
    </div>
  );
};
