import { FormEvent } from 'react';

interface SubmitForm {
  action: string;
  method?: string;
  onSubmit?: (evt: FormEvent<HTMLFormElement>) => void;
  fields?: Record<string, string>;
  newWindow?: Window | null;
}

export const getInputNode = (key: string, value: string): Node => {
  const input = document.createElement('input');
  input.name = key;
  input.type = 'hidden';
  input.value = value;
  return input;
};

const submitForm = ({ action, method = 'post', onSubmit, fields, newWindow }: SubmitForm) => {
  const form = document.createElement('form');
  form.action = action;
  form.method = method;
  if (fields) {
    Object.keys(fields).forEach((key: string) => {
      const node = getInputNode(key, fields[key]);
      form.appendChild(node);
    });
  }
  if (onSubmit) {
    form.onsubmit = (evt: any) => onSubmit(evt as FormEvent<HTMLFormElement>);
  }
  if (newWindow) {
    newWindow.opener = null;
    newWindow.document.body.append(form);
  } else {
    document.body.append(form);
  }
  form.submit();
};

export default submitForm;
