import type { Loan } from '../../../features/loans/types';
import { useHomeLoanConfig } from '../../../hooks/useHomeLoanConfig';
import { formatDate, formatDateWithoutTimezone, FORMAT_SHORT, FORMAT_LONG } from '../../../utils/date';
import Address from '../../ui/address/Address';
import { TimeProps } from '../../ui/time/ITime';
import Time from '../../ui/time/Time';
import { LoanCard } from './LoanCard';
import { SocialShareConfigs } from '../../social/ISocialShareConfig';
import { useLoan } from '../../../features/loans/hooks';

const DateInfo = ({
  'closing-date': closingDate,
  'scheduled-closing-date': scheduledClosingDate,
  'estimated-closing-date': estimatedClosingDate,
  'last-updated': lastUpdated,
}: Loan) => {
  let dateInfo;
  if (closingDate) {
    dateInfo = {
      date: closingDate,
      label: `Closed on ${formatDateWithoutTimezone(closingDate, FORMAT_SHORT)}`,
      title: formatDateWithoutTimezone(closingDate, FORMAT_LONG),
    };
  } else if (scheduledClosingDate) {
    dateInfo = {
      date: scheduledClosingDate,
      label: `Close date ${formatDateWithoutTimezone(scheduledClosingDate, FORMAT_SHORT)}`,
      title: formatDateWithoutTimezone(scheduledClosingDate, FORMAT_LONG),
    };
  } else if (estimatedClosingDate) {
    dateInfo = {
      date: estimatedClosingDate,
      label: `Est. close date ${formatDateWithoutTimezone(estimatedClosingDate, FORMAT_SHORT)}`,
      title: formatDateWithoutTimezone(estimatedClosingDate, FORMAT_LONG),
    };
  } else if (lastUpdated) {
    dateInfo = {
      date: lastUpdated,
      label: `Last modified ${formatDate(lastUpdated, FORMAT_SHORT)}`,
      title: formatDate(lastUpdated, FORMAT_LONG),
    };
  }

  if (!dateInfo) return null;

  return <Time {...(dateInfo as TimeProps)} />;
};

export const HomeLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const { data } = useLoan(loanGuid);
  const loan = data as Loan;
  return (
    <HomeLoanCardPresentational loan={loan} />
  );
};

export const HomeLoanCardPresentational = ({ loan }: { loan: Loan }) => {
  const { 'loan-number': loanNumber, property, 'loan-milestone': loanMilestone } = loan;
  const loanConfig = useHomeLoanConfig(loan);
  return (
    <LoanCard
      loanNumber={loanNumber}
      loanConfig={loanConfig}
      loanNumberPrefix='Loan #'
      subHeader={<DateInfo {...loan} />}
      configKey={loanMilestone === 'funded' ? SocialShareConfigs.LOAN_CENTER_FUNDED : undefined}
      property={<Address {...property} />}
      loan={loan}
    />
  );
};
