const phrases = [
  {
    text: 'OK let’s get you all set up!',
  },
  {
    text: 'This should take 2-3 minutes.',
  },
  {
    text: 'While you wait patiently, we’re very busy doing mortgage stuff.',
    fontClass: 'text-marketing-2xs',
  },
  {
    text: 'Like creating your profile and admin page…',
  },
  {
    text: 'Sending a notification to your loan processing team…',
    fontClass: 'text-marketing-2xs',
  },
  {
    text: 'Creating clear and concise loan tasks…',
  },
  {
    text: 'All sorts of stuff.',
  },
  {
    text: 'Rest assured we’re working hard…',
  },
  {
    text: 'It’s important for us to get this right!',
  },
  {
    text: 'We want your experience to be as smooth and easy as possible.',
    fontClass: 'text-marketing-2xs',
  },
  {
    text: 'If we were with you in person we’d probably offer you a donut.',
    fontClass: 'text-marketing-2xs',
  },
  {
    text: 'And coffee.',
  },
  {
    text: 'Or tea.',
  },
  {
    text: 'But not orange juice.',
  },
  {
    text: 'Orange juice and a donut would be waaaay too much sugar.',
    fontClass: 'text-marketing-2xs',
  },
  {
    text: 'Either way, we just want you to be happy.',
  },
  {
    text: 'Like all our customers who recommend us to their friends and family.',
    fontClass: 'text-marketing-2xs',
  },
  {
    text: 'Friends and family includes your parents.',
  },
  {
    text: 'So our customers recommend us to their parents??',
  },
  {
    text: 'High praise indeed!',
  },
  {
    text: 'Because NOBODY wants to let their parents down!',
  },
  {
    text: 'The point is people trust us.',
  },
  {
    text: 'Not just for a low rate.',
  },
  {
    text: 'But also for the best advice and help.',
  },
  {
    text: 'And for the best technology.',
  },
  {
    text: 'And we never stop working to earn and keep your trust.',
  },
  {
    text: 'After all, for most of us, our home is life’s biggest purchase.',
  },
  {
    text: 'And most important.',
  },
  {
    text: 'It’s where the best moments of your life happen.',
  },
  {
    text: 'Like, it’s where your babies learn to walk and talk.',
  },
  {
    text: 'Or where your dog ate the entire birthday cake.',
  },
  {
    text: 'Which admittedly wasn’t funny in the moment.',
  },
  {
    text: 'But was hilarious like a day later.',
  },
  {
    text: 'Because there’s no place like home.',
  },
  {
    text: 'And it’s our privilege to help make that as accessible…',
  },
  {
    text: 'and affordable…',
  },
  {
    text: 'to as many people as possible.',
  },
  {
    text: 'Because a mortgage isn’t just a loan.',
  },
  {
    text: 'It’s a gateway to a better life.',
  },
  {
    text: 'And we are so lucky we get to help people exactly like you.',
    fontClass: 'text-marketing-2xs',
  },
  {
    text: 'OK we’re good! Thanks for bearing with us!',
  },
  {
    text: 'OK well this taking a little longer than we thought.',
  },
  {
    text: 'And we want to respect your time.',
  },
  {
    text: 'Time to meet Hailey!',
  },
];

export default phrases;
