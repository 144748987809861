import cn from 'classnames';
import { formatPhoneNumber, unformatPhoneNumber } from '../../../utils/phone';
import Button from './Button';
import type { PhoneButtonProps } from './IPhoneButton';
import './PhoneEmailButton.scss';

export const PhoneButton = (props: PhoneButtonProps) => {
  const { label, phone, suffix, showIcon, buttonType = 'inline', size, className, ...restProps } = props;

  const phoneLabel = formatPhoneNumber(phone);

  return phone ? (
    <Button
      buttonType={buttonType}
      size={size}
      href={`tel: ${unformatPhoneNumber(phone)}`}
      title={`Call ${phoneLabel}`}
      className={cn('tel', className)}
      data-ui='PhoneButton'
      data-hj-allow=''
      {...(showIcon && { iconName: 'phone', iconSize: '1.25rem', iconPlacement: 'left', iconClassname: 'phone-button-icon' })}
      {...restProps}
    >
      {label || phoneLabel}
      {suffix && <span>&nbsp;&nbsp;{suffix}</span>}
    </Button>
  ) : null;
};
