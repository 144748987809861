import { useCallback, useEffect, useState } from 'react';
import { SlidingDrawer } from '../../ui/sliding-drawer/SlidingDrawer';
import { PAGES, PATHS, pathToPageMap } from '../../routes/paths';
import { AlpLoanType, Loan, LoanMilestones } from '../../../features/loans/types';
import { Article, LoanType } from '../../../features/managedContent/types';
import { useRouteMatch } from 'react-router-dom';
import { useFetchArticles } from '../../../features/managedContent/managedContentSlice';
import { useAppSelector } from '../../../hooks/hooks';
import { selectAppLanguage } from '../../../features/app/appSlice';
import { DEFAULT_LANGUAGE } from '../../../utils/multilingual';
import { Skeleton } from '../../ui/skeleton/Skeleton';
import Alert from '../../ui/alert/Alert';
import { log } from '../../../utils/logger';
import { sendEvent } from '../../../features/analytics/sendEvent';

export const ArticleItem = ({
  article,
  containerId,
  loanGuid,
  page,
  loanMilestone,
}: {
  article: Article;
  containerId: string;
  loanGuid: string;
  page: PAGES;
  loanMilestone: LoanMilestones;
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [page]);

  const handleClick = useCallback(() => {
    setIsDrawerOpen(true);
    log({
      loanGuid,
      level: 'info',
      message: `Helpful links: User opened an article" ${JSON.stringify({
        title: article.title,
        page,
        loanMilestone,
      })}`,
    });
    sendEvent('helpfulLinkView', { articleTitle: article.title, page, loanMilestone });
  }, [loanGuid, article.title, page, loanMilestone]);

  return (
    <SlidingDrawer
      containerId={containerId}
      isOpen={isDrawerOpen}
      handleClose={() => {
        setIsDrawerOpen(false);
      }}
      title='Helpful links'
      buttonProps={{
        buttonType: 'tertiary',
        className: 'text-left',
        text: article.title,
        title: 'Toggle helpful links',
        'aria-expanded': isDrawerOpen ? 'true' : 'false',
        onClick: handleClick,
      }}
    >
      <section className='pt-6 px-6 article-body'>
        <div dangerouslySetInnerHTML={{ __html: article.body }} />
      </section>
    </SlidingDrawer>
  );
};

export const ArticlesList = ({
  articles,
  containerId,
  loanGuid,
  page,
  loanMilestone,
}: {
  articles: Article[];
  containerId: string;
  loanGuid: string;
  page: PAGES;
  loanMilestone: LoanMilestones;
}) => (
  <>
    {articles.map((article: Article) => (
      <div className='mb-2 helpful-links' key={article.uid}>
        <ArticleItem article={article} containerId={containerId} loanGuid={loanGuid} page={page} loanMilestone={loanMilestone} />
      </div>
    ))}
  </>
);

export const HelpfulLinks = ({ loan, containerId }: { loan: Loan; containerId: string }) => {
  const { 'loan-milestone': loanMilestone, 'loan-guid': loanGuid, 'alp-loan-type': alpLoanType } = loan;
  const { path } = useRouteMatch();
  const page = pathToPageMap(path as PATHS);
  const language = useAppSelector(selectAppLanguage) || DEFAULT_LANGUAGE;
  const loanType = alpLoanType === AlpLoanType.HELOC ? LoanType.HELOC : LoanType.MORTGAGE;
  const { data: articles, hasError, hasData } = useFetchArticles(loanMilestone, page, language, loanType);

  return (
    <div>
      <p className='title-text-text header-small bold mb-6'>Helpful links</p>
      {!hasError &&
        !hasData &&
        Array(3)
          .fill(0)
          .map((_, i) => <Skeleton className='mb-2 w-64' key={i} />)}
      {hasError && !hasData && (
        <Alert
          type='error'
          showClose={false}
          description='Helpful links are currently unavailable. If the issue persists please contact support.'
        />
      )}
      {articles && (
        <ArticlesList articles={articles} containerId={containerId} loanGuid={loanGuid} page={page} loanMilestone={loanMilestone} />
      )}
    </div>
  );
};
