import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { selectAppLanguage } from '../../../features/app/appSlice';
import { useAppSelector } from '../../../hooks/hooks';
import { multilingualLink, urlSupportsEs, urlCanBeTranslated, useMultilingual, appIsDefaultLanguage } from '../../../hooks/useMultilingual';
import { translateUrl } from '../../../utils/translateUrl';
import { spanishDisclaimerModalSubject } from '../../routes/modals/SpanishNavigationDisclaimerModal';
import { LinkProps } from './ILink';
import { isExternalUrl } from '../../../utils/grLinks';

/**
 * Determines whether to use HTML `a` element or react-router-dom `Link` element
 */
const Link = (props: LinkProps) => {
  const multilingual = useMultilingual();
  const appLanguage = useAppSelector(selectAppLanguage);
  const isDefaultLanguage = appIsDefaultLanguage(appLanguage);
  const { children, href, target, to, anchorRef, onClick, disabled, ...restProps } = props;

  let translatedHref = href;
  if (!isDefaultLanguage && href && appLanguage && urlCanBeTranslated(href)) {
    translatedHref = translateUrl(href, appLanguage);
  }

  const withDisabledCheck = (event: React.MouseEvent, cb?: (event: React.MouseEvent) => void): void => {
    if (disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    cb?.(event);
  };

  const onAnchorClick = (event: React.MouseEvent) => {
    // if app AND route supports multilingual AND
    // app is not in default language (English) AND
    // href is defined AND
    // href do not support multilingual AND
    // href is an external link AND
    // href is not translated eConsent document
    if (
      multilingual &&
      appLanguage &&
      !isDefaultLanguage &&
      href &&
      !urlSupportsEs(href) &&
      isExternalUrl(href) &&
      (!translatedHref || !multilingualLink(translatedHref, appLanguage))
    ) {
      event.preventDefault();
      spanishDisclaimerModalSubject.next({
        open: true,
        href,
        target,
        onConfirm: onClick,
      });
      return;
    }
    onClick?.();
  };

  return to ? (
    <RouterLink onClick={event => withDisabledCheck(event, onClick)} to={to} innerRef={anchorRef} {...restProps} {...(disabled && { tabIndex: -1 })}>
      {children}
    </RouterLink>
  ) : href ? (
    <a
      href={translatedHref}
      target={target}
      {...(target === '_blank' && { rel: 'noreferrer' })}
      ref={anchorRef}
      onClick={event => withDisabledCheck(event, onAnchorClick)}
      {...restProps}
      {...(disabled && { tabIndex: -1 })}
    >
      {children}
    </a>
  ) : onClick || props.type ? (
    <button onClick={event => withDisabledCheck(event, onClick)} {...restProps}>
      {children}
    </button>
  ) : (
    <span {...restProps}>
      {children}
    </span>
  );
};

export default Link;
