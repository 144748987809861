import { withTransaction } from '@elastic/apm-rum-react'
import { useUser } from '../../features/user/userSlice';
import { User } from '../../features/user/types';
import DataList from '../../components/ui/datalist/Datalist';
import DataItem from '../../components/ui/datalist/dataItem/DataItem';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { formatPhoneNumber } from '../../utils/phone';
import { isoToName } from '../../utils/multilingual';
import { SingleColumnContent } from '../../components/layout/Layout';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { TITLES } from '../../components/routes/paths';

interface UserInfoProps {
  user: User;
}

const UserInfo = (props: UserInfoProps) => {
  const { user } = props;
  const multilingualEnabled = useFeatureEnabled('multilingual');
  const userLanguageCode = multilingualEnabled && user['language-preference'];
  const userLanguage = userLanguageCode && isoToName(userLanguageCode);
  return (
    <>
      <h3 className='text-2xl mb-4'>Your profile information</h3>
      <DataList>
        <DataItem label='Name' value={user.name?.['full-with-suffix']} data-qa='profile-name' valueClassName='bold OneLinkNoTx' />
        {user.address && <DataItem label='Address' address={user.address} data-qa='profile-address' valueClassName='bold OneLinkNoTx' />}
        {user.phone && <DataItem label='Phone' value={formatPhoneNumber(user.phone)} data-qa='profile-phone' valueClassName='bold' />}
        <DataItem label='Email' value={user.email} data-qa='profile-email' valueClassName='bold OneLinkNoTx' />
        {userLanguage && <DataItem label='Language Preference' value={userLanguage} data-qa='profile-language-preference' valueClassName='bold OneLinkNoTx' />}
      </DataList>
    </>
  );
};

const Profile = () => {
  const user = useUser();
  useDocumentTitle(TITLES.profile);

  return (
    <SingleColumnContent>
      <h1 className='text-marketing-xs md:text-marketing-md mb-6'>My profile</h1>
      {user ? (
        <>
          {user.name?.first && (
            <h2 className='text-2xl mb-4'>
              Hi{' '}
              <span data-qa='user-first-name' className='OneLinkNoTx'>
                {user.name?.first}
              </span>
              , good to see you!
            </h2>
          )}
          <p>Please contact your loan officer if you need to update or make changes to your profile information.</p>
          <hr className='my-8' />
          <UserInfo user={user} />
        </>
      ) : null}
    </SingleColumnContent>
  );
};

export default withTransaction('Profile', 'page-load')(Profile);
