import LoanOfficerCard from '../../../components/loan/support/LoanOfficerCard';
import mockLoanOfficer from '../../../mocks/teamLo';

const LoanOfficerCardStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Loan Officer Card</div>
      <div>
        <LoanOfficerCard loanOfficer={mockLoanOfficer} />
      </div>

      <div className='header-medium my-8'>Without Apply Link</div>
      <div>
        <LoanOfficerCard loanOfficer={mockLoanOfficer} showApplyText={false} />
      </div>
    </>
  );
};

export default LoanOfficerCardStyleguide;
