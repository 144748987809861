import 'keen-slider/keen-slider.min.css';
import './Carousel.scss';
import Carousel from './Carousel';

export interface ImageCarouselItem {
  imageLink: string;
  url: string;
  title?: string;
  subtitle?: string;
}

const ImageCarouselTile = ({ item }: { item: ImageCarouselItem }) => {
  const handleClick = () => {
    console.log(`clicked on tile`, item);
  };

  const TitleComponent = ({ title, subtitle }: { title: string, subtitle?: string }) => {
    return (
      <div className='absolute top-0 flex flex-col px-8 pt-8 text-white'>
        <p className='font-bold text-lg'>{title}</p>
        {subtitle && <p className='text-base'>{subtitle}</p>}
      </div>
    );
  };

  return (
    <div className='keen-slider__slide flex items-center' style={{ height: '380px' }} tabIndex={0} role='button' onClick={handleClick}>
      <div className='w-full h-full rounded-xl'>
        <div className='zoom-tile rounded-xl w-full' style={{ backgroundImage: `url(${item.imageLink})`, backgroundSize: '100% 100%' }} />
        {item.title && <TitleComponent title={item.title} subtitle={item.subtitle} />}
      </div>
    </div>
  );
};

const ImageCarousel = ({ items }: { items: ImageCarouselItem[] }) => {
  const widgets = items.map(item => {
    return <ImageCarouselTile item={item} />;
  });

  return (
    <Carousel items={widgets} />
  );
};

export default ImageCarousel;
