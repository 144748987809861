const SupportHours = ({ supportDaysTime }: { supportDaysTime: { days: string, hours: string }[] } ) => {
  return (
    <div>
      <h3 className='bold body-small'>Hours</h3>
      {supportDaysTime.map((support, index) => {
        return (
          <div key={index}>
            <p className='text-sm leading-6'>{support.days} {support.hours}</p>
          </div>
        );
      })}
    </div>
  );
};

export default SupportHours;
