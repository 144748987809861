import { GatelessState, GatelessActionTypes } from './types';
import {
  SET_GATELESS_CONFIG,
  ADD_GATELESS_EVENT,
  EVENT_TASKLIST_SYNC,
  EVENTS_TASK_MODIFIED,
  SET_GATELESS_VOA_SSO_LINK_ID
} from './actionTypes';
import { random } from '../../utils/uuid';
import timestamp from '../../utils/timestamp';

export const initialMapState = {
  config: {},
  events: [],
  lastTasklistSync: null,
  lastTaskModified: null
};

/* istanbul ignore next */
export const initialState: GatelessState = {
  byLoanGuid: {}
};

export const getContext = () => random(12);

export const reducer = (
  state = initialState,
  action: GatelessActionTypes
): GatelessState => {
  switch (action.type) {
    case SET_GATELESS_CONFIG:
      const {
        'consumer-token': consumerToken,
        'consumer-id': consumerId,
        loanGuid,
        refreshTimeoutIdx,
      } = action.payload;
      return {
        ...state,
        byLoanGuid: {
          ...state.byLoanGuid,
          [loanGuid]: {
            ...initialMapState,
            ...state.byLoanGuid[loanGuid],
            config: {
              consumerToken,
              consumerId,
              context: getContext(),
              refreshTimeoutIdx,
            }
          }
        }
      };

    case ADD_GATELESS_EVENT:
      const {
        loanGuid: eventLoanGuid,
        event: {
          caseId: eventCaseId,
          eventName,
          taskId,
          consumerId: eventConsumerId,
          ...eventData
        }
      } = action.payload;
      const isTasklistSync = eventName === EVENT_TASKLIST_SYNC;
      const isTaskModified = EVENTS_TASK_MODIFIED.includes(eventName);
      const ts = timestamp();
      const event = {
        ts,
        eventName,
        taskId,
        caseId: eventCaseId,
        consumerId: eventConsumerId,
        ...eventData
      };
      return {
        ...state,
        byLoanGuid: {
          ...state.byLoanGuid,
          [eventLoanGuid]: {
            ...state.byLoanGuid[eventLoanGuid],
            events: [event, ...(state.byLoanGuid[eventLoanGuid].events || [])],
            ...(isTasklistSync && { lastTasklistSync: ts }),
            ...(isTaskModified && { lastTaskModified: ts })
          }
        }
      };

    case SET_GATELESS_VOA_SSO_LINK_ID:
      const { loanGuid: loanGuidVoa, ssoLinkId } = action.payload;
      return {
        ...state,
        byLoanGuid: {
          ...state.byLoanGuid,
          [loanGuidVoa]: {
            ...state.byLoanGuid[loanGuidVoa],
            config: {
              ...state.byLoanGuid[loanGuidVoa].config,
              ssoLinkId,
            },
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
