import Button from '../ui/button/Button';
import { selectAppLanguage, selectGlobalAdtrk } from '../../features/app/appSlice';
import { sendEvent } from '../../features/analytics/sendEvent';
import { useAppSelector } from '../../hooks/hooks';
import { TwitterShareProps } from './ITwitterShareProps';
import { SocialShareConfigs } from './ISocialShareConfig';
import { buildSearchParams } from '../../utils/url';
import { translateUrl } from '../../utils/translateUrl';
import { log } from '../../utils/logger';
import twitterImage from '../../images/social-twitter.svg';
import { mainSite } from '../../utils/grLinks';
import { selectCurrentLo } from '../../features/user/selectCurrentLo';

const TwitterShareButton = ({ twitterProps, configKey, fullButton }: { twitterProps: TwitterShareProps; configKey: SocialShareConfigs; fullButton: boolean }) => {
  const { url, ...restTwitterProps } = twitterProps;
  const appLanguage = useAppSelector(selectAppLanguage);
  const adtrk = useAppSelector(selectGlobalAdtrk);
  const lo = useAppSelector(selectCurrentLo);
  
  const translatedUrl = translateUrl(url, appLanguage);
  const mainSiteUrl = mainSite( translatedUrl, { adtrk, loid: lo?.['emp-id']});
  const options = { ...restTwitterProps, url: mainSiteUrl };

  const shareUrl = `http://www.twitter.com/intent/tweet${buildSearchParams(options)}`;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    sendEvent(`twitterShareClick: ${configKey}`);
    log({ message: `Twitter share clicked:  ${configKey}` });
    window.open(shareUrl, 'popup', 'width=600,height=600');
  };

  return (
    <Button
      target='popup'
      rel='noreferrer'
      buttonType='tertiary'
      title='Share on Twitter'
      className='ml-0 fb-xfbml-parse-ignore'
      onClick={handleClick}
      size={fullButton ? 'large' : 'medium'}
    >
      <div className='flex items-center'>
        <img className='w-5 h-5' width='20' height='20' src={twitterImage} alt='Twitter logo' />
        {fullButton ? <p className='ml-2'>Twitter</p> : null}
      </div>
    </Button>
  );
};
export default TwitterShareButton;
