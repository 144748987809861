import Button from './Button';
import type { EmailButtonProps } from './IEmailButton';
import './PhoneEmailButton.scss';

export const EmailButton = (props: EmailButtonProps) => {
  const { label, email, showIcon, buttonType = 'inline', size, ...restProps } = props;

  // zero width space so the browser will try to break wrap at "@"
  const buttonLabel = label ? label : email.replace('@', '@\u200B');

  return email ? (
    <Button
      buttonType={buttonType}
      size={size}
      href={`mailto:${email.toLowerCase()}`}
      target='_blank'
      rel='noreferrer'
      title={`Email ${email}`}
      data-ui='EmailButton'
      data-hj-allow=''
      {...(showIcon && { iconName: 'mail', iconSize: '1.25rem', iconPlacement: 'left', iconClassname: 'email-button-icon' })}
      {...restProps}
    >
      {buttonLabel}
    </Button>
  ) : null;
};
