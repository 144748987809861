import { withTransaction } from '@elastic/apm-rum-react';
import Button from '../../components/ui/button/Button';
import Circle from '../../components/ui/circle/Circle';
import autoImage from '../../images/insurance-auto.png';
import autoImageEs from '../../images/insurance-auto-es.png';
import autoMobileImage from '../../images/insurance-auto-mobile.png';
import autoMobileImageEs from '../../images/insurance-auto-mobile-es.png';
import homeImage from '../../images/insurance-home.png';
import homeImageEs from '../../images/insurance-home-es.png';
import homeMobileImage from '../../images/insurance-home-mobile.png';
import homeMobileImageEs from '../../images/insurance-home-mobile-es.png';
import lifeImage from '../../images/insurance-life.png';
import lifeImageEs from '../../images/insurance-life-es.png';
import lifeMobileImage from '../../images/insurance-life-mobile.png';
import lifeMobileImageEs from '../../images/insurance-life-mobile-es.png';
import petImage from '../../images/insurance-pet.png';
import petImageEs from '../../images/insurance-pet-es.png';
import petMobileImage from '../../images/insurance-pet-mobile.png';
import petMobileImageEs from '../../images/insurance-pet-mobile-es.png';
import grInsuranceLogo from '../../images/gr-insurance-logo.svg';
import { useEffect, useRef, useState } from 'react';
import { log } from '../../utils/logger';
import { insurance } from '../../utils/grLinks';
import cn from 'classnames';
import { useAppSelector } from '../../hooks/hooks';
import { selectAppLanguage } from '../../features/app/appSlice';
import { SingleColumnContent } from '../../components/layout/Layout';
import { EmailButton } from '../../components/ui/button/EmailButton';
import { PhoneButton } from '../../components/ui/button/PhoneButton';
import { content } from '../../features/tenant/tenant';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { TITLES } from '../../components/routes/paths';
// import houseCarImage from '../../images/house-car.png';
// import houseCarImageMobile from '../../images/house-car-mobile.png';

const InsuranceSteps = () => {
  const DesktopStep = ({ step, title, description }: { step: string; title: string; description: string }) => {
    return (
      <>
        <div className='flex mb-4'>
          <span className='mr-6'>
            <Circle size='3rem' bgColor='action-10' textColor='body-100' text={step} />
          </span>
          <p className='font-bold flex items-center'>{title}</p>
        </div>
        <p>{description}</p>
      </>
    );
  };

  const MobileStep = ({ step, title, description, className }: { step: string, title: string, description: string, className?: string }) => {
    return (
      <div className={cn('flex', className)}>
        <Circle className='mr-6' size='2.25rem' bgColor='action-10' textColor='body-100' text={step} />
        <div className='flex flex-col'>
          <p className='font-bold flex items-center'>{title}</p>
          <p>{description}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Desktop */}
      <div className='hidden sm:flex'>
        <div className='flex-1 flex flex-col pr-6 border-r-2 border-inactive-10'>
          <DesktopStep
            step='1'
            title='Provide your information'
            description='Submit essential information to get customized quotes.'
          />
        </div>
        <div className='flex-1 flex flex-col px-6 border-r-2 border-inactive-10'>
          <DesktopStep
            step='2'
            title='Get a quote'
            description='Get customized insurance quotes tailored to your coverage needs.'
          />
        </div>
        <div className='flex-1 flex flex-col pl-6'>
          <DesktopStep
            step='3'
            title='Get insured'
            description='Decide on the right option for your insurance and get insured.'
          />
        </div>
      </div>
      {/* Mobile */}
      <div className='flex sm:hidden flex-col'>
        <MobileStep
          className='mb-4'
          step='1'
          title='Provide your information'
          description='Submit essential information to get customized quotes.'
        />
        <MobileStep
          className='mb-4'
          step='2'
          title='Get a quote'
          description='Get customized insurance quotes tailored to your coverage needs.'
        />
        <MobileStep
          step='3'
          title='Get insured'
          description='Decide on the right option for your insurance and get insured.'
        />
      </div>
    </>
  );
};

const InsuranceCompanies = () => {
  const INSURANCE_LOGOS = [
    {
      filename: 'insurance-logo-1.png',
      height: '17px',
    },
    {
      filename: 'insurance-logo-2.png',
      height: '10px',
    },
    {
      filename: 'insurance-logo-3.png',
      height: '15px',
    },
    {
      filename: 'insurance-logo-4.png',
      height: '23px',
    },
    {
      filename: 'insurance-logo-5.png',
      height: '51px',
    },
    {
      filename: 'insurance-logo-6.png',
      height: '35px',
    },
    {
      filename: 'insurance-logo-7.png',
      height: '36px',
    },
  ];

  const [imageArray, setImageArray] = useState(new Array(INSURANCE_LOGOS.length));
  const images = useRef<string[]>(new Array(INSURANCE_LOGOS.length)).current;

  useEffect(() => {
    loadImages();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadImages = async () => {
    for (let i = 0; i < INSURANCE_LOGOS.length; i++) {
      try {
        const image = await import(`../../images/${INSURANCE_LOGOS[i].filename}`);
        images[i] = image.default;
        setImageArray([...images]);
      } catch (error) {
        log({ level: 'warn', message: `Error fetching insurance logo ${error}` });
      }
    }
  };

  const imageElementArray = [];
  for (let i = 0; i < INSURANCE_LOGOS.length; i++) {
    imageElementArray.push(<img key={i} alt={`carriers ${i}`} src={imageArray[i]} style={{ height: INSURANCE_LOGOS[i].height }} />);
  }

  return (
    <>
      <div className='hidden lg:flex justify-between items-center'>
        {imageElementArray}
      </div>
      <div className='grid lg:hidden grid-cols-2 auto-rows-fr items-center justify-items-center'>
        {imageElementArray}
        <p className='font-bold'>+40 other carriers</p>
      </div>
    </>
  );
};

export const InsuranceContent = () => {
  const lang = useAppSelector(state => selectAppLanguage(state));
  return (
    <>
      <h1 className='mb-4 text-marketing-xs md:text-marketing-md'>
        We are here to help you find the best rate for your insurance
      </h1>
      <h2 className='text-2xl mb-6'>It's easier than you think, here's how it works:</h2>
      <InsuranceSteps />

      <p className='mt-8'>
        If you have any questions, we are here for you. Email us at{' '}
        <EmailButton buttonType='tertiary' email={content.insurance.email} className='OneLinkNoTx' /> or call us at{' '}
        <PhoneButton buttonType='tertiary' phone={content.insurance.phone} />.
      </p>

      <div className='flex flex-row gap-x-4 my-8'>
        <img className='h-9' alt='insurance' src={grInsuranceLogo} />
        <h2 className='text-2xl'>Get a free quote</h2>
      </div>

      <div className='grid grid-cols-2 gap-6'>
        <Button href={insurance('auto')} target='_blank'>
          <img className='hidden sm:inline' alt='auto' loading='lazy' src={lang === 'es' ? autoImageEs : autoImage} />
          <img className='inline sm:hidden' alt='auto' loading='lazy' src={lang === 'es' ? autoMobileImageEs : autoMobileImage} />
        </Button>
        <Button href={insurance('home')} target='_blank'>
          <img className='hidden sm:inline' alt='auto' loading='lazy' src={lang === 'es' ? homeImageEs : homeImage} />
          <img className='inline sm:hidden' alt='auto' loading='lazy' src={lang === 'es' ? homeMobileImageEs : homeMobileImage} />
        </Button>
        <Button href={insurance('life')} target='_blank'>
          <img className='hidden sm:inline' alt='auto' loading='lazy' src={lang === 'es' ? lifeImageEs : lifeImage} />
          <img className='inline sm:hidden' alt='auto' loading='lazy' src={lang === 'es' ? lifeMobileImageEs : lifeMobileImage} />
        </Button>
        <Button href={insurance('pet')} target='_blank'>
          <img className='hidden sm:inline' alt='auto' loading='lazy' src={lang === 'es' ? petImageEs : petImage} />
          <img className='inline sm:hidden' alt='auto' loading='lazy' src={lang === 'es' ? petMobileImageEs : petMobileImage} />
        </Button>
      </div>

      {/* TODO - to bring back when we have bundle
      <h2 className='mt-8 mb-4 text-2xl'>Bundle for extra savings</h2>
      <div className='rounded-xl bg-action-10'>
        <div className='flex'>
          <div className='flex-1 flex flex-col pt-1 pl-1 sm:pt-3 sm:pl-3'>
            <h2 className='mb-4 text-base sm:text-2xl'>Save up to 20% by bundling home and auto</h2>
            <Button
              style={{ 'width': 'fit-content' }}
              buttonType='secondary'
              size='small'
              text='Get started'
              href={insurance('bundle')}
              target='_blank'
            />
          </div>
          <div className='flex-1 hidden sm:flex justify-end'>
            <img alt='bundle' src={houseCarImage} />
          </div>
          <div className='flex-1 flex sm:hidden justify-end'>
            <img alt='bundle' src={houseCarImageMobile} />
          </div>
        </div>
      </div>
      */}

      <h2 className='text-2xl hidden md:block mt-12 sm:mt-8 mb-4 sm:mb-6'>
        We'll search for your best rate from over 40+ top-rated carriers.
      </h2>
      <h2 className='text-2xl block md:hidden mt-8 mb-6'>We will find the best rate</h2>

      <InsuranceCompanies />
    </>
  );
};

const Insurance = () => {
  useDocumentTitle(TITLES.insurance);
  return (
    <SingleColumnContent>
      <InsuranceContent />
    </SingleColumnContent>
  );
};

export default withTransaction('Insurance', 'page-load')(Insurance);