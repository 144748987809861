/**
 * Format string or number to percent with or without rounding
 */
export const formatPercent = (percent?: string | number, toFixed?: number) => {
  if (percent === undefined) {
    return percent;
  }

  const pct: number = typeof percent === 'string' ? +percent.replace(/[%,]/g, '') : percent;

  if (isNaN(pct)) {
    return undefined;
  }

  return toFixed ? `${pct.toFixed(toFixed)}%` : `${pct}%`;
};
