import { withTransaction } from '@elastic/apm-rum-react';
import { SingleColumnContent } from '../../components/layout/Layout';
import { TITLES } from '../../components/routes/paths';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Embed from '../../components/ui/embed/Embed';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { content } from '../../features/tenant/tenant';
import './HomeSearch.scss';
import { getConfigFromStore } from '../../features/config/getConfigFromStore';
import SavedHomesComponent from './SavedHomes';
import NewListings from './NewListings';
import { useAppDispatch } from '../../hooks/hooks';
import { updateGlobalLoadingOnce } from '../../features/app/appSlice';
import Card from '../../components/ui/card/Card';
import { Skeleton } from '../../components/ui/skeleton/Skeleton';

const HomeSearchLoading = ({ hidden }: { hidden: boolean }) => {
  return (
    <div className={cn({ hidden: hidden }, 'home-search')}>
      <Card className='h-full flex flex-col justify-around'>
        <Skeleton className='mb-2 h-24' />
        <Skeleton className='mb-2 h-24' />
        <Skeleton className='h-24' />
      </Card>
    </div>
  );
};

const NewListingsLoading = ({ hidden }: { hidden: boolean }) => {
  const Tile = () => {
    return (
      <Card className='saved-home-tile'>
        <Skeleton className='mb-2 h-10' />
        <Skeleton className='mb-2 h-10' />
        <Skeleton className='mb-2 h-10' />
        <Skeleton className='h-10' />
      </Card>
    );
  };
  return (
    <div className={cn({ hidden: hidden}, 'mt-8 flex justify-center')}>
      <div className='flex flex-col w-fit-content'>
        <Skeleton className='my-4 h-10' />
        <div className='flex flex-row'>
          <Tile />
          <Tile />
          <Tile />
        </div>
      </div>
    </div>
  );
};

const HomeSearchComponent = ({ hidden, onLoad }: { hidden: boolean, onLoad: () => void }) => {
  const config = getConfigFromStore();
  const baseUrl = config?.links['home-search'];
  const homeSearchPublicKey = content.homeSearch.searchPublicKey;
  const host = window.location.host;
  const homeSearchNMLSId = '378202';
  const homeSearchLayout = 'hero';
  const homeSearchDestination = 'homebuyer';
  const homeSearchHeadlineCopy = 'Find your dream home';
  const homeSearchBackgroundImage = 'https%3A%2F%2Fimages.contentstack.io%2Fv3%2Fassets%2Fblt4bf507db5c9bc5ae%2Fbltcb7b490ace3a9987%2F670578a044382994e49a8e1c%2Fhome-search-bg.jpg';
  const newWindow = true;
  const homeSearchUrl =
    `${baseUrl}/widget?public_key=${homeSearchPublicKey}&parent_site_hostname=${host}&nmls_id=${homeSearchNMLSId}&layout=${homeSearchLayout}&destination=${homeSearchDestination}&headline_copy=${homeSearchHeadlineCopy}&background_image=${homeSearchBackgroundImage}&destination_new_window=${newWindow}&sso=true`;
  const disclaimer = 'When you search for properties, you will be leaving rate.com and be redirected to comehome. comehome is managed by HouseCanary, Inc., a California licensed real estate brokerage (California DRE #02113022).';
  return (
    <div>
      <Embed className={cn('w-full home-search rounded-xl', { hidden })} title='Home Search' src={homeSearchUrl} onLoad={onLoad} />
      <p className='mt-4 text-xs' style={{ fontStretch: 'condensed' }}>{disclaimer}</p>
    </div>
  );
};

const HomeSearchContent = () => {
  const dispatch = useAppDispatch();
  const [searchLoaded, setSearchLoaded] = useState(false);
  const [newListingsReady, setNewListingsReady] = useState(false);

  const handleSearchLoaded = () => {
    setSearchLoaded(true);
  };

  const handleNewListingsReady = () => {
    setNewListingsReady(true);
  };

  useEffect(() => {
    if (newListingsReady && searchLoaded) {
      dispatch(updateGlobalLoadingOnce(false));
    }
  }, [dispatch, newListingsReady, searchLoaded]);

  return (
    <div>
      <HomeSearchLoading hidden={searchLoaded} />
      <HomeSearchComponent hidden={!searchLoaded} onLoad={handleSearchLoaded} />
      <SavedHomesComponent className='mt-8' />
      <NewListingsLoading hidden={!!newListingsReady} />
      <NewListings hidden={!newListingsReady} onReady={handleNewListingsReady} />
    </div>
  );
};

const HomeSearch = () => {
  useDocumentTitle(TITLES.homeSearch);
  return (
    <SingleColumnContent>
      <HomeSearchContent />
    </SingleColumnContent>
  );
};

export default withTransaction('HomeValue', 'page-load')(HomeSearch);
