/* istanbul ignore file */
import dayjs from 'dayjs';

export const isBusinessHours = (): boolean => {
  const now = dayjs().tz('America/Chicago');

  // if date is on saturday or sunday
  if (now.day() === 0 || now.day() === 6) {
    return false;
  }

  // start of business is 8:30AM
  const start = now.hour(8).minute(30);
  // end of business is 6:30PM
  const end = now.hour(18).minute(30);

  // we dont have public holiday in this logic

  return now.isAfter(start) && now.isBefore(end);
};
