import { useState, KeyboardEvent } from 'react';
import cn from 'classnames';
import Icon from '../icon/Icon';
import nextId from '../../../utils/nextId';

interface Props {
  initValue?: boolean;
  id?: string;
  name: string;
  value: string;
  label?: string;
  error?: string;
  onChange?: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
}

const Checkbox = ({ initValue = false, id, name, value, label, error, onChange, className, disabled }: Props) => {
  const [checked, setChecked] = useState<boolean>(initValue);
  id = id || nextId(`checkbox_${name}_`);
  label = label || value;

  const handleCheckboxClicked = () => {
    setChecked(!checked);
    onChange?.(!checked);
  };

  const handleCheckboxKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Space') {
      event.preventDefault();
      handleCheckboxClicked();
    }
  };

  return (
    <div className={cn('flex flex-col', className)}>
      <div className='flex items-center'>
        {!disabled && (
          <input
            className='hidden'
            type='checkbox'
            id={id}
            name={name}
            value={value}
            checked={checked}
            readOnly
          />
        )}
        <div
          className={cn(
            'min-w-6 min-h-6 w-6 h-6 border rounded flex items-center justify-center mr-2',
            { 'bg-critical-10 border-critical-100': !!error },
            { 'border-inactive-25': disabled },
            { 'bg-inactive-10': disabled && checked },
            { 'cursor-pointer': !disabled },
            { 'border-input-50': !disabled && !checked },
            { 'border-action-125 bg-input-10': !disabled && checked },
          )}
          role='checkbox'
          aria-checked={checked ? 'true' : 'false'}
          aria-labelledby={`${id}_label`}
          {...(!disabled && {
            tabIndex: 0,
            onClick: handleCheckboxClicked,
            onKeyDown: handleCheckboxKey
          })}
        >
          {checked && <Icon name='check-tick' className={cn({ 'text-action-125': !disabled }, { 'text-inactive-25': disabled })} size='0.8rem' />}
        </div>
        <label
          id={`${id}_label`}
          htmlFor={id}
          className={cn({ 'cursor-pointer': !disabled }, { 'text-inactive-50': disabled })}
          {...(!disabled && {
            onClick: handleCheckboxClicked,
          })}
        >
          {label}
        </label>
      </div>
      {error && <p className={cn('h-0 relative text-form-error text-error-important top-1')}>{error}</p>}
    </div>
  );
};

export default Checkbox;
