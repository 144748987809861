import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store/types';
import api from '../api';
import {
  iInsuranceEligibility,
  eligibilityData,
  insuranceMetadata,
  insuranceState,
  quoteData,
  insuranceQuoteResponse,
} from './types';

const namespace = 'insurance';

const initialEligibilityData: eligibilityData = {
  eligibility: {
    eligible: false,
    url: undefined,
    errors: undefined,
  },
  metadata: {
    hasData: false,
    isFetching: false,
    hasError: false,
  },
};

const initialQuoteData: quoteData = {
  quoteRequestSent: false,
  metadata: {
    hasData: false,
    isFetching: false,
    hasError: false,
  },
};

const initialState: insuranceState = {
  eligibilityByLoanGuid: {},
  quoteByLoanGuid: {},
};

export const fetchInsuranceEligibility = createAsyncThunk<iInsuranceEligibility, { loanGuid: string; campaign: string }>(
  `${namespace}/fetchInsuranceEligibility`,
  async ({ loanGuid, campaign }) => {
    const resp = await api.getInsuranceEligibility(loanGuid, campaign);
    return resp.data;
  },
  {
    condition: ({ loanGuid }, { getState }) => {
      const { isFetching } = selectInsuranceEligibilityMetadata(getState() as RootState, loanGuid);
      return !isFetching;
    },
  },
);

export const sendInsuranceQuoteRequest = createAsyncThunk<insuranceQuoteResponse, string>(
  `${namespace}/sendInsuranceQuoteRequest`,
  async loanGuid => {
    const resp = await api.postLoanInsurance(loanGuid);
    return resp.data;
  },
);

export const insuranceEligibilitySlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchInsuranceEligibility.pending, (state, action) => {
      const { loanGuid } = action.meta.arg;
      state.eligibilityByLoanGuid[loanGuid] = {
        ...initialEligibilityData,
        ...state.eligibilityByLoanGuid[loanGuid],
      };
      state.eligibilityByLoanGuid[loanGuid].metadata = {
        ...initialEligibilityData.metadata,
        ...state.eligibilityByLoanGuid[loanGuid].metadata,
        isFetching: true,
        hasError: false,
      };
    });
    builder.addCase(fetchInsuranceEligibility.fulfilled, (state, action) => {
      const { loanGuid } = action.meta.arg;
      state.eligibilityByLoanGuid[loanGuid] = {
        ...initialEligibilityData,
        ...state.eligibilityByLoanGuid[loanGuid],
        eligibility: action.payload,
      };
      state.eligibilityByLoanGuid[loanGuid].metadata = {
        ...initialEligibilityData.metadata,
        ...state.eligibilityByLoanGuid[loanGuid].metadata,
        hasData: true,
        isFetching: false,
      };
    });
    builder.addCase(fetchInsuranceEligibility.rejected, (state, action) => {
      const { loanGuid } = action.meta.arg;
      state.eligibilityByLoanGuid[loanGuid] = {
        ...initialEligibilityData,
        ...state.eligibilityByLoanGuid[loanGuid],
      };
      state.eligibilityByLoanGuid[loanGuid].metadata = {
        ...initialEligibilityData.metadata,
        ...state.eligibilityByLoanGuid[loanGuid].metadata,
        isFetching: false,
        hasError: true,
      };
    });
    builder.addCase(sendInsuranceQuoteRequest.pending, (state, action) => {
      const loanGuid = action.meta.arg;
      state.quoteByLoanGuid[loanGuid] = {
        ...initialQuoteData,
        ...state.quoteByLoanGuid[loanGuid],
      };
      state.quoteByLoanGuid[loanGuid].metadata = {
        ...initialQuoteData.metadata,
        ...state.quoteByLoanGuid[loanGuid].metadata,
        isFetching: true,
        hasError: false,
      };
    });
    builder.addCase(sendInsuranceQuoteRequest.fulfilled, (state, action) => {
      const loanGuid = action.meta.arg;
      state.quoteByLoanGuid[loanGuid] = {
        ...initialQuoteData,
        ...state.quoteByLoanGuid[loanGuid],
        quoteRequestSent: !!action.payload,
      };
      state.quoteByLoanGuid[loanGuid].metadata = {
        ...initialQuoteData.metadata,
        ...state.quoteByLoanGuid[loanGuid].metadata,
        hasData: true,
        isFetching: false,
      };
    });
    builder.addCase(sendInsuranceQuoteRequest.rejected, (state, action) => {
      const loanGuid = action.meta.arg;
      state.quoteByLoanGuid[loanGuid] = {
        ...initialQuoteData,
        ...state.quoteByLoanGuid[loanGuid],
      };
      state.quoteByLoanGuid[loanGuid].metadata = {
        ...initialQuoteData.metadata,
        ...state.quoteByLoanGuid[loanGuid].metadata,
        isFetching: false,
        hasError: true,
      };
    });
  },
});

export const selectInsuranceEligibility = (state: RootState, loanGuid: string): iInsuranceEligibility => {
  return state.insurance.eligibilityByLoanGuid[loanGuid]?.eligibility || initialEligibilityData.eligibility;
};

export const selectInsuranceQuoteSent = (state: RootState, loanGuid: string): boolean | null => {
  if (!state.insurance.quoteByLoanGuid[loanGuid]?.metadata.hasData) {
    return null;
  }
  return state.insurance.quoteByLoanGuid[loanGuid]?.quoteRequestSent;
};

export const selectInsuranceEligibilityMetadata = (state: RootState, loanGuid: string): insuranceMetadata => {
  return state.insurance.eligibilityByLoanGuid[loanGuid]?.metadata || initialEligibilityData.metadata;
};

export const selectInsuranceQuoteMetadata = (state: RootState, loanGuid: string): insuranceMetadata => {
  return state.insurance.quoteByLoanGuid[loanGuid]?.metadata || initialQuoteData.metadata;
};

export const insuranceEligibilityActions = insuranceEligibilitySlice.actions;
export default insuranceEligibilitySlice.reducer;
