import { selectAppLanguage, updateAppLanguage } from '../../../features/app/appSlice';
import { selectLanguages } from '../../../features/config/configSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { isoToName } from '../../../utils/multilingual';
import Dropdown, { DropdownItem } from '../../ui/dropdown/Dropdown';

const LanguageDropdown = () => {
  const dispatch = useAppDispatch();
  const languageCodes = useAppSelector(selectLanguages);
  const appLanguageCode = useAppSelector(selectAppLanguage);

  const languageItems: DropdownItem[] = languageCodes?.map(langCode => {
    const lang = isoToName(langCode);
    return {
      text: lang,
      value: langCode,
      dataQa: lang,
      onClick: () => {
        dispatch(updateAppLanguage(langCode));
      },
    };
  }) || [];

  // Disable if appLanguageCode is not set
  if (!appLanguageCode) return null;

  return (
    <Dropdown
      id='language-dropdown'
      text={isoToName(appLanguageCode)}
      items={languageItems}
      iconName='chevron-down-small'
      iconSize='0.5rem'
    />
  );
};

export default LanguageDropdown;
