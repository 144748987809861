import { useState } from 'react';
import Dropdown, { DropdownItem } from '../../ui/dropdown/Dropdown';
import cn from 'classnames';
import { FormGroupWrapper } from '../../ui/form/FormGroup';
import FormGroupError from '../../ui/form/FormGroupError';

export interface FormDropdownProps {
  name: string;
  id: string;
  title: string;
  items: DropdownItem[];
  className?: string;
  onChange?: (value: string) => void;
  onError?: (error?: string) => void;
  required?: boolean;
  supportKeydown?: boolean;
}

const FormDropdown = ({ id, name, title, items, className, onChange, onError, required, supportKeydown = false }: FormDropdownProps) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState<DropdownItem | undefined>();
  const [error, setError] = useState<string>();

  const onSelectedChange = (_event: React.MouseEvent, value: DropdownItem) => {
    setValue(value);
    onChange?.(value.text);
  };

  const onDropdownFocus = () => {
    setFocused(true);
  };

  const onDropdownShown = (show: boolean) => {
    if (required && focused && !show && !value) {
      setError(`${title} is required`);
      onError?.(`${title} is required`);
    } else {
      setError(undefined);
      onError?.(undefined);
    }
  };

  return (
    <FormGroupWrapper className={cn(className)} error={error} active={true}>
      <Dropdown
        id={id}
        text={value?.text || ''}
        onChange={onSelectedChange}
        items={items}
        mobile={true}
        mobileTitle={title}
        formInput={true}
        onFocus={onDropdownFocus}
        onDropdownShown={onDropdownShown}
        supportKeydown={supportKeydown}
      />
      <FormGroupError error={error} id={id} />
      <input type='hidden' name={name} value={value?.value || ''} />
    </FormGroupWrapper>
  );
};

export default FormDropdown;
