import { AxiosError } from 'axios';
import { RequestError, RequestRejectValue } from '../interfaces/IRequest';
import { log } from '../utils/logger';

export class PartialDataError<T = unknown> extends Error {
  data: T;
  constructor(message: string, data: T) {
    super();
    this.message = message;
    this.data = data;
  }
}

export const logRequestError = (requestKey: string, error: AxiosError<RequestError> | PartialDataError<unknown>) => {
  if (error instanceof PartialDataError) {
    log({
      level: 'error',
      message: `Request "${requestKey}" error - ${error.message}`,
    });
  } else if (error.response?.status) {
    const clientError = error.response.status < 500;
    log({
      level: clientError ? 'info' : 'error',
      message: `Request "${requestKey}" failed from ${error.response.status} ${clientError ? 'client' : 'server'} error - ${error}`,
    });
  } else {
    log({ level: 'error', message: `Request "${requestKey}" failed - ${JSON.stringify(error)}` });
  }
};

export const rejectValue = (error: AxiosError<RequestError>): RequestRejectValue<unknown> => ({
  message: error.message,
  ...(error.response?.status && { status: error.response.status }),
  ...(error.response?.statusText && { statusText: error.response.statusText }),
  ...(error.response?.data && { data: error.response.data }),
});

export const rejectPartialDataValue = (error: PartialDataError<unknown>): RequestRejectValue<unknown> => ({
  type: 'partialDataError',
  message: error.message,
  data: error.data,
});
