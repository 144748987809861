import ehlLogoImage from '../../../images/ehl-logo.svg';
import myAccountLogo from '../../../images/myaccount-logo.svg';
import { ConfigurationNote } from '../shared';

const images = [
  {
    src: '/images/brand-logo.svg',
    width: '150',
    height: '150',
    isConfigurable: true,
  },
  {
    src: '/images/footer-logo.svg',
    width: '',
    height: '',
    isConfigurable: true,
  },
  {
    src: '/images/brand-icon.svg',
    width: '',
    height: '',
    isConfigurable: true,
  },
  {
    src: ehlLogoImage,
    width: '150',
    height: '30',
    isConfigurable: false,
  },
];

const BrandElements = () => {
  return (
    <div className='brand-elements mb-6'>
      <div className='header-medium'>Brand elements</div>
      <div className='mb-6 flex flex-wrap'>
        {images.map((item, index) => {
          return (
            <div key={index} className='m-4'>
              <img src={item.src} width={item.width} height={item.height} alt=''></img>
              <div className='mt-4'>{!item.isConfigurable && <ConfigurationNote message='Not configurable' />}</div>
            </div>
          );
        })}
      </div>
      <div>
        <div className='body-medium bold mb-6'>Example of configuration</div>
        <div className='flex'>
          <img src='/images/brand-logo.svg' width='120' height='32' alt='' />
          <img src={myAccountLogo} alt='' width='72' height='18' className='header-logo-text ml-2' />
        </div>
      </div>
    </div>
  );
};

export default BrandElements;
