/* istanbul ignore file */
import Icon from '../../components/ui/icon/Icon';
import { iconName } from '../../components/ui/icon/IIcon';

const Icons = () => (
  <div className='styleguide_icons icons'>
    <h2 className='header-medium'>Iconography</h2>
    <div className='mb-8 italic'>Default size is 24px but may get as small as 16px when necessary</div>
    <ul>
      {iconName.map(icon => (
        <li className='icons__icon inline-block text-center' key={icon} style={{ width: '200px', height: '100px' }}>
          <div>
            <Icon name={icon} size='1.5rem' />
          </div>
          <div>{icon}</div>
        </li>
      ))}
    </ul>
  </div>
);

export default Icons;
