import { Redirect } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';
import { DetailsCardContent } from '../../components/loan/detailsCard/DetailsCard';
import { linkTo, PATHS, TITLES } from '../../components/routes/paths';
import Card from '../../components/ui/card/Card';
import { IDataItem } from '../../components/ui/datalist/dataItem/IDataItem';
import { isTasklistLoan } from '../../features/loans/helpers';
import { type LoanAppraisalStatus, type Loan, AlpLoanType } from '../../features/loans/types';
import { useDocumentTitleLoanPage } from '../../hooks/useDocumentTitle';
import { usdAmount } from '../../utils/amount';
import { formatDateWithoutTimezone, FORMAT_SHORT } from '../../utils/date';
import { log } from '../../utils/logger';
import { formatPercent } from '../../utils/percent';
import { getLoanTermString } from '../../utils/getLoanTermString';

const formatDate = (value?: string) => {
  return value ? formatDateWithoutTimezone(value, FORMAT_SHORT) : '-';
};

const KeyDatesComponent = ({ loan }: { loan: Loan }) => {
  const appraisalWaived = loan['appraisal-status'] === 'waived';

  let closeDate;
  let closeDateLabel;
  if (loan['closing-date']) {
    closeDate = formatDate(loan['closing-date']);
    closeDateLabel = 'Closed on';
  } else if (loan['scheduled-closing-date']) {
    closeDate = formatDate(loan['scheduled-closing-date']);
    closeDateLabel = 'Close date';
  } else if (loan['estimated-closing-date']) {
    closeDate = formatDate(loan['estimated-closing-date']);
    closeDateLabel = 'Est. close date';
  }

  const data: IDataItem[] = [
    {
      value: formatDate(loan['contingency-date']),
      label: 'Contingency',
      labelClassName: 'mb-0',
    },
    {
      value: closeDate,
      label: closeDateLabel || 'Close date',
      labelClassName: 'mb-0',
    },
    {
      value: formatDate(loan['lock-expiration-date']),
      label: 'Lock expiration',
      labelClassName: 'mb-0',
    },
    {
      value: appraisalWaived ? undefined : formatDate(loan['appraisal-scheduled-date']),
      label: 'Appraisal scheduled',
      labelClassName: 'mb-0',
    },
    {
      value: appraisalWaived ? undefined : formatDate(loan['appraisal-due-date']),
      label: 'Appraisal due',
      labelClassName: 'mb-0',
    },
  ];

  return (
    <>
      <p className='font-bold text-lg md:text-xl mb-4'>Key dates</p>
      <DetailsCardContent className='gap-y-4' details={data} />
    </>
  );
};

const HELOCKeyDatesComponent = ({ loan }: { loan: Loan }) => {
  const data: IDataItem[] = [
    {
      value: formatDate(loan['started-date']),
      label: 'Application',
      labelClassName: 'mb-0',
    },
    {
      value: formatDate(loan['scheduled-closing-date']),
      label: 'Close date',
      labelClassName: 'mb-0',
    },
  ];

  return (
    <>
      <p className='font-bold text-lg md:text-xl mb-4'>Key dates</p>
      <DetailsCardContent className='gap-y-4' details={data} />
    </>
  );
};

const CESKeyDatesComponent = ({ loan }: { loan: Loan }) => {
  const appraisalWaived = loan['appraisal-status'] === 'waived';

  let closeDate;
  let closeDateLabel;
  if (loan['closing-date']) {
    closeDate = formatDate(loan['closing-date']);
    closeDateLabel = 'Closed on';
  } else if (loan['scheduled-closing-date']) {
    closeDate = formatDate(loan['scheduled-closing-date']);
    closeDateLabel = 'Close date';
  } else if (loan['estimated-closing-date']) {
    closeDate = formatDate(loan['estimated-closing-date']);
    closeDateLabel = 'Est. close date';
  }

  const data: IDataItem[] = [
    {
      value: closeDate,
      label: closeDateLabel || 'Close date',
      labelClassName: 'mb-0',
    },
    {
      value: appraisalWaived ? undefined : formatDate(loan['appraisal-scheduled-date']),
      label: 'Appraisal scheduled',
      labelClassName: 'mb-0',
    },
    {
      value: appraisalWaived ? undefined : formatDate(loan['appraisal-due-date']),
      label: 'Appraisal due',
      labelClassName: 'mb-0',
    },
  ];

  return (
    <>
      <p className='font-bold text-lg md:text-xl mb-4'>Key dates</p>
      <DetailsCardContent className='gap-y-4' details={data} />
    </>
  );
};

const convertAppraisalStatus = (status: LoanAppraisalStatus) => {
  switch (status) {
    case 'pending':
      return 'Order pending';
    case 'ordered':
      return 'Ordered, not scheduled';
    case 'scheduled':
      return 'Inspection scheduled';
    case 'received':
      return 'Appraisal received';
    case 'approved':
      return 'Appraisal approved';
    case 'waived':
      return 'Appraisal waived';
  }
  return '-';
};

const LoanDetailsComponent = ({ loan }: { loan: Loan }) => {
  const data: IDataItem[] = [
    {
      value: loan['loan-number'],
      label: 'Loan number',
      labelClassName: 'mb-0',
    },
    {
      value: getLoanTermString(loan) || '-',
      label: 'Loan term',
      labelClassName: 'mb-0',
    },
    {
      value: usdAmount(loan['base-loan-amount']),
      label: 'Loan amount',
      labelClassName: 'mb-0',
    },
    {
      value: loan['interest-rate'] || typeof loan['interest-rate'] === 'number' ? formatPercent(loan['interest-rate'], 3) : '-',
      label: 'Interest rate',
      labelClassName: 'mb-0',
    },
    {
      value: convertAppraisalStatus(loan['appraisal-status']),
      label: 'Appraisal status',
      labelClassName: 'mb-0',
    },
    {
      value: loan['appraisal-amount'] ? usdAmount(loan['appraisal-amount']) : '-',
      label: 'Appraisal amount',
      labelClassName: 'mb-0',
    },
  ];

  return (
    <>
      <p className='font-bold text-lg md:text-xl mb-4'>Loan details</p>
      <DetailsCardContent className='gap-y-4' details={data} />
    </>
  );
};

const HELOCLoanDetailsComponent = ({ loan }: { loan: Loan }) => {
  const data: IDataItem[] = [
    {
      value: loan['loan-number'],
      label: 'Loan number',
      labelClassName: 'mb-0',
    },
    {
      value: getLoanTermString(loan) || '-',
      label: 'Loan term',
      labelClassName: 'mb-0',
    },
    {
      value: usdAmount(loan['base-loan-amount']),
      label: 'Loan amount',
      labelClassName: 'mb-0',
    },
    {
      value: loan['interest-rate'] || typeof loan['interest-rate'] === 'number' ? formatPercent(loan['interest-rate'], 3) : '-',
      label: 'Interest rate',
      labelClassName: 'mb-0',
    },
  ];

  return (
    <>
      <p className='font-bold text-lg md:text-xl mb-4'>Loan details</p>
      <DetailsCardContent className='gap-y-4' details={data} />
    </>
  );
};

const LoanDetailsCard = ({ loan }: { loan: Loan }) => {
  return (
    <Card>
      <KeyDatesComponent loan={loan} />
      <hr className='my-6' />
      <LoanDetailsComponent loan={loan} />
    </Card>
  );
};

const HELOCLoanDetailsCard = ({ loan }: { loan: Loan }) => {
  return (
    <Card>
      <HELOCKeyDatesComponent loan={loan} />
      <hr className='my-6' />
      <HELOCLoanDetailsComponent loan={loan} />
    </Card>
  );
};

const CESLoanDetailsCard = ({ loan }: { loan: Loan }) => {
  return (
    <Card>
      <CESKeyDatesComponent loan={loan} />
      <hr className='my-6' />
      <LoanDetailsComponent loan={loan} />
    </Card>
  );
};

const DetailsView = ({ loan }: { loan: Loan }) => {
  const { 'alp-loan-type': alpLoanType } = loan;

  useDocumentTitleLoanPage(TITLES.homeLoanDetails, loan['loan-number']);

  if (alpLoanType === AlpLoanType.HELOC) {
    return <HELOCLoanDetailsCard loan={loan} />;
  } else if (alpLoanType === AlpLoanType.CES) {
    return <CESLoanDetailsCard loan={loan} />;
  } else {
    return <LoanDetailsCard loan={loan} />;
  }
};

const WithRedirect = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;

  if (!isTasklistLoan(loan)) {
    log({ loanGuid, message: 'Loan does not meet info criteria, redirecting back to loan url' });
    return (
      <Redirect
        to={{
          pathname: linkTo(PATHS.homeLoan, { guid: loanGuid }),
        }}
      />
    );
  }

  return <DetailsView loan={loan} />;
};

export default withTransaction('LoanDetails', 'page-load')(WithRedirect);
