import { useLoan } from '../../../features/loans/hooks';
import { Loan } from '../../../features/loans/types';
import { useAlpHELOCLoanConfig } from '../../../hooks/useAlpHELOCLoanConfig';
import { DashboardLoanCard } from './DashboardLoanCards';
import { FORMAT_LONG, FORMAT_SHORT, formatDateWithoutTimezone } from '../../../utils/date';
import { TimeProps } from '../../ui/time/ITime';
import Time from '../../ui/time/Time';

const DateInfo = ({ 'started-date': startedDate }: Loan) => {
  let dateInfo;
  if (startedDate) {
    dateInfo = {
      date: startedDate,
      label: `Application date ${formatDateWithoutTimezone(startedDate, FORMAT_SHORT)}`,
      title: formatDateWithoutTimezone(startedDate, FORMAT_LONG),
    };
  }

  if (!dateInfo) return null;

  return <Time {...(dateInfo as TimeProps)} />;
};

export const DashboardAlpHELOCLoanCardPresentational = ({ loan }: { loan: Loan }) => {
  const { 'loan-number': loanNumber, property, 'base-loan-amount': loanAmount } = loan;
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <DashboardLoanCard
      loanAmount={loanAmount}
      loanConfig={loanConfig}
      dateNode={<DateInfo {...loan} />}
      loanNumber={loanNumber}
      address={property}
    />
  );
};

export const DashboardAlpHELOCLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const { data } = useLoan(loanGuid);
  if (!data) return null;
  return (
    <DashboardAlpHELOCLoanCardPresentational loan={data} />
  );
};
