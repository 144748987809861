import Alert from '../../../components/ui/alert/Alert';
import { EmailButton } from '../../../components/ui/button/EmailButton';
import mockLoanOfficer from '../../../mocks/teamLo';

const AlertsStyleguide = () => {
  return (
    <>
      <div className='alerts-styleguide'>
        <div className='header-medium mb-8'>Alerts</div>
        <div>
          <ul className='mb-8'>
            <li className='mb-4'>
              <Alert
                type='info'
                title='Title'
                description='Test Description'
                buttonProps={{ text: 'Test Button' }}
                showClose={false}
              />
            </li>
            <li className='mb-4'>
              <Alert type='success' description='Success Description' showClose={false} />
            </li>
            <li className='mb-4'>
              <Alert
                type='success'
                title='Success Message'
                description='The action you are about to take cannot be undone.'
                showClose={false}
              />
            </li>
            <li className='mb-4'>
              <Alert type='warning' description='Warning Description' />
            </li>
            <li className='mb-4'>
              <Alert type='warning' title='Warning Message' description='The action you are about to take cannot be undone.' />
            </li>
            <li className='mb-4'>
              <Alert type='error' description='Error Description' buttonProps={{ text: 'Dismiss' }} showClose={false} />
            </li>
            <li className='mb-4'>
              <Alert type='error' title='Error Message' description='The action you were trying to take is unavailable.' />
            </li>
            <li className='mb-4'>
              <Alert type='updating' description='Updating Description' buttonProps={{ text: 'Refresh' }} showClose={false} />
            </li>
            <li className='mb-4'>
              <Alert
                type='updating'
                description='Updating Description'
                buttonProps={{ text: 'Refresh' }}
                showClose={false}
                animateIcon={true}
              />
            </li>
            <li className='mb-4'>
              <Alert type='updating' description='Alert text four' animateIcon={true} />
            </li>
            <li className='mb-4'>
              <Alert type='success' description='Alert text one' buttonProps={{ text: 'Done' }} showClose={false} />
            </li>
            <li className='mb-4'>
              <Alert type='warning' description='Alert text two' showClose={false} />
            </li>
            <li className='mb-4'>
              <Alert
                type='warning'
                description='Alert text two point one'
                buttonProps={{ text: 'Test Button' }}
                showClose={false}
              />
            </li>
            <li className='mb-4'>
              <Alert
                type='info'
                description='Your tasks have been updated. Please refresh the page to view'
                buttonProps={{ text: 'Refresh' }}
              />
            </li>
            <li className='mb-4'>
              <Alert
                type='info'
                showClose={false}
                description='Your loan is in progress. Email your loan officer to lock in your rate and terms.'
                auxButton={
                  <EmailButton
                    className='whitespace-nowrap'
                    buttonType='tertiary'
                    showIcon={false}
                    label='Email loan officer'
                    email={mockLoanOfficer.email as string}
                  />
                }
              />
            </li>
            <li className='mb-4'>
              <Alert
                type='info'
                showClose={false}
                description='Your loan is in progress. Email your loan officer to lock in your rate and terms. This is very very very very long padding that will make the line wraps.'
                auxButton={
                  <EmailButton
                    className='whitespace-nowrap'
                    buttonType='tertiary'
                    label='Email loan officer'
                    email={mockLoanOfficer.email as string}
                  />
                }
              />
            </li>
            <li className='mb-4'>
              <Alert
                type='info'
                showClose={true}
                description='Your loan is in progress. Email your loan officer to lock in your rate and terms. This is very very very very long padding that will make the line wraps.'
                auxButton={
                  <EmailButton
                    className='whitespace-nowrap'
                    buttonType='tertiary'
                    showIcon={false}
                    label='Email loan officer'
                    email={mockLoanOfficer.email as string}
                  />
                }
              />
            </li>
            <li className='mb-4'>
              <Alert
                type='info'
                showClose={true}
                description='Your loan is in progress. Email your loan officer to lock in your rate and terms. This is very very very very long padding that will make the line wraps.'
                auxButton={
                  <EmailButton
                    className='whitespace-nowrap'
                    buttonType='tertiary'
                    showIcon={false}
                    label='Email loan officer'
                    email={mockLoanOfficer.email as string}
                  />
                }
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AlertsStyleguide;
