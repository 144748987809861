import { content } from '../../../../features/tenant/tenant';
import FAQs from '../../../ui/faqs/FAQs';
import Link from '../../../ui/link/Link';

export const HELOCFigureFAQs = () => {
  const qAndA = [
    {
      question: 'Questions about HELOC servicing',
      answer: (
        <p>
          Guaranteed Rate has partnered with{' '}
          <Link href={content.helocLoans.figure.website} target='_blank' rel='noopener'>
            Figure
          </Link>{' '}
          to service your loan from Day 1. Figure will collect your monthly payments and be able to answer any other questions you
          have about servicing your HELOC loan. The contact information for Figure is shown on the top right of this page. To find
          out more, visit{' '}
          <Link href={content.helocLoans.figure.helocFAQsLink} target='_blank' rel='noopener'>
            here
          </Link>
          .
        </p>
      ),
    },
  ];
  return <FAQs faqs={qAndA} heading='Frequently asked questions' />;
};

export const HELOCUnknownServicerFAQs = () => {
  const qAndA = [
    {
      question: 'Questions about HELOC servicing',
      answer: (
        <>
          <strong className='text-sm lg:text-base'>HELOC funded before 10/9/2023</strong>
          <p>
            Guaranteed Rate has partnered with{' '}
            <Link href={content.helocLoans.figure.website} target='_blank' rel='noopener'>
              Figure
            </Link>
            {' '}to service your loan from Day 1. Figure will collect your monthly payments and be able to answer any other questions you
            have about servicing your HELOC loan. The contact information for Figure is shown on the top right of this page. To find
            out more, visit{' '}
            <Link href={content.helocLoans.figure.helocFAQsLink} target='_blank' rel='noopener'>
              here
            </Link>
            .
          </p>
          <strong className='mt-4 text-sm lg:text-base'>HELOC funded on or after 10/9/2023</strong>
          <p>
            To access your HELOC loan, make payments, and more please visit <Link href={content.helocLoans.serviceMac.website} target='_blank' rel='noopener'>here</Link>.
          </p>
        </>
      ),
    },
  ];
  return <FAQs faqs={qAndA} heading='Frequently asked questions' />;
};
