import { isObject } from './object';

export const set = (path: string, value: any, obj: Record<string, any> = {}, replaceObjects?: boolean) => {
  const pathArray = path.split('.');
  let currentPathObj = obj;

  for (const [i, key] of pathArray.entries()) {
    if (i === pathArray.length - 1) {
      if (!replaceObjects && isObject(currentPathObj[key]) && isObject(value)) {
        currentPathObj[key] = { ...currentPathObj[key], ...value };
      } else {
        currentPathObj[key] = value;
      }
    } else {
      currentPathObj[key] = currentPathObj[key] || {};
      currentPathObj = currentPathObj[key];
    }
  }

  return { ...obj };
};

export default set;
