import DataItem from '../../../components/ui/datalist/dataItem/DataItem';
import Datalist from '../../../components/ui/datalist/Datalist';

// used for datalist
const mockPaymentFees = [
  {
    label: 'Credit Report Fee',
    value: '$0.01',
  },
  {
    label: 'Appraisal Fee',
    value: '$0.01',
  },
];

// used for datalist
const mockProfileData = [
  {
    label: 'Name',
    value: 'Joe Smith',
    valueClassName: 'bold',
  },
  {
    label: 'Address',
    address: {
      'street-address': '1232 North LaSalle Drive',
      'street-address-2': '',
      city: 'Chicago',
      state: 'IL',
      'postal-code': '60610',
    },
    valueClassName: 'bold',
  },
  {
    label: 'Phone',
    value: '(111)111-1111',
    valueClassName: 'bold',
  },
  {
    label: 'Email',
    value: 'myaccount-finicity-1607973108du--ra@yopmail.com',
    valueClassName: 'bold',
  },
];

const DataListStyleguide = () => {
  return (
    <>
      <div className='profile-datalist mb-12'>
        <div className='header-medium mb-8'>DataList</div>
        <div className='mb-12'>
          <div className='body-large font-bold my-3'>Your profile information:</div>
          <Datalist>
            <DataItem label='Name' value='Joe Smith' valueClassName='bold'></DataItem>
            <DataItem
              address={{
                'street-address': '1232 North LaSalle Drive',
                city: 'Chicago',
                state: 'IL',
                'postal-code': '60610',
              }}
              label='Address'
              data-qa='address'
              valueClassName='bold'
            />
            <DataItem label='Phone' value='(111)111-1111' valueClassName='bold'></DataItem>
            <DataItem label='Email' value='myaccount-finicity-1607973108du--ra@yopmail.com' valueClassName='bold'></DataItem>
          </Datalist>
        </div>
        <div className='mb-12'>
          <div className='body-large font-bold my-3'>Your profile information: (data prop)</div>
          <Datalist data={mockProfileData} />
        </div>
      </div>

      <div className='payment-summary mt-6 mb-12'>
        <div className='body-large font-bold mb-3'>Fee Summary:</div>
        <Datalist inline totalAmount='$0.02' totalTitle='Total Loan Fees'>
          <DataItem label='Credit Report Fee' value='$0.01'></DataItem>
          <DataItem label='Appraisal Fee' value='$0.01'></DataItem>
        </Datalist>
      </div>

      <div className='payment-summary mb-12'>
        <div className='body-large font-bold mb-3'>Fee Summary: (data prop)</div>
        <Datalist inline totalAmount='$0.02' totalTitle='Total Loan Fees' data={mockPaymentFees} />
      </div>
    </>
  );
};

export default DataListStyleguide;
