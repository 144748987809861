import { FormEvent } from 'react';
import Form from '../../../components/ui/form/Form';
import { IncomeTypeDropdown } from '../../../components/tasks/employment/IncomeTypeDropdown';
import FormGroup from '../../../components/ui/form/FormGroup';
import FormButtonComponent from '../../../components/ui/form/FormButtonComponent';
import StatesDropdown from '../../../components/tasks/employment/StatesDropdown';

const MockFormStyleguide = () => {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    console.log('form submit');
  };

  const testOnChange = (value: any) => {
    console.log('test on change', value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className='grid grid-cols-1 md:grid-cols-6 gap-x-3'>
        <StatesDropdown 
          className='col-span-1 md:col-span-3'
        />
        <IncomeTypeDropdown
          className='col-span-1 md:col-span-3'
        />
        <FormGroup
          className='col-span-1 md:col-span-3'
          label='Phone number'
          id='test_phone'
          name='test_phone'
          type='phone'
          required={true}
          maxLength='128'
          onValueChange={testOnChange}
        />
        <FormGroup
          className='col-span-1 md:col-span-3'
          label='Test date'
          id='test_date'
          name='test_date'
          type='date'
          required={true}
          onValueChange={testOnChange}
        />
        <FormGroup
          className='col-span-1 md:col-span-3'
          label='Test currency'
          id='test_currency'
          name='test_currency'
          type='currency'
          onValueChange={testOnChange}
        />
      </div>
      <FormButtonComponent
        id='test-submit-button'
        buttonContainerClassName='mt-3 md:mt-0 w-full md:w-fit-content'
        className='mt-3 w-full md:w-fit-content'
      >
        Test submit
      </FormButtonComponent>
    </Form>
  );
};

export default MockFormStyleguide;
