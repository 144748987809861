import { createSelector } from '@reduxjs/toolkit';
import { selectGlobalCookiedLoId } from '../app/appSlice';
import { selectLoansByGuidEntity } from '../loans/loansSlice';
import { LoanByGuidMap, LoanOfficer } from '../loans/types';
import { User } from './types';
import { selectUser } from './userSlice';

export const selectCurrentLo = createSelector(
  selectGlobalCookiedLoId,
  selectUser,
  selectLoansByGuidEntity,
  (loId: undefined | number, user: User | null, loansMap: LoanByGuidMap): LoanOfficer | Partial<LoanOfficer> | undefined => {
    const currentLo = user?.['current-lo'] || loId;
    if (!currentLo) return undefined;
    for (const loanEntity of Object.values(loansMap)) {
      if (loanEntity.data?.team?.lo && loanEntity.data.team.lo?.['emp-id'] === currentLo) {
        return loanEntity.data.team.lo;
      }
    }
    return { 'emp-id': currentLo };
  },
);
