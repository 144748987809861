import chrome from '../../images/chrome-logo.svg';
import safari from '../../images/safari-logo.svg';
import firefox from '../../images/firefox-logo.svg';
import edge from '../../images/edge-logo.svg';
import './UnsupportedBrowserError.scss';
import { MainLayout } from '../../components/layout/Layout';
import { log } from '../../utils/logger';
import { sendEvent } from '../../features/analytics/sendEvent';
import { useEffect } from 'react';
import useError from '../../hooks/useError';

// We don't fetch configs or auth info. UI assumes user is not authenticated.
export const UnsupportedBrowserError = ({ children }: { children: JSX.Element }) => {
  const { isUnsupportedBrowser } = useError();

  useEffect(() => {
    if (isUnsupportedBrowser) {
      sendEvent('error: unsupportedBrowser');
      log({ level: 'info', message: 'error: unsupportedBrowser' });
    }
  }, [isUnsupportedBrowser]);

  if (!isUnsupportedBrowser) {
    return children;
  }

  return (
    <MainLayout>
      <div className='grid sm:grid-cols-12 grid-cols-1 mx-auto'>
        <div className='py-12 text-center col-start-3 col-span-8'>
          <h1 className='error-header pb-5 font-bold'>Having trouble viewing the site?</h1>
          <div className='max-w-xl mx-auto'>
            <p className='error-body-lg pb-5'>
              Follow the instructions below to update your browser and continue with the loan application process.
            </p>
            <p className='error-body-md font-bold'>
              Our website and tools are optimized to work with the following browsers.
              <br />
              Click to download
            </p>
            <div className='flex mx-auto my-8 max-w-xs px-8 sm:px-0 sm:max-w-md'>
              <div className='flex flex-col sm:flex-row'>
                <div className='mx-4 mb-4 sm:mb-0'>
                  <a href='https://www.google.com/chrome/' target='_blank' rel='noreferrer' className='no-underline'>
                    <img src={chrome} alt='' className='browser-icon mx-auto' />
                    <p>Chrome</p>
                  </a>
                </div>
                <div className='mx-4 mb-4 sm:mb-0'>
                  <a href='https://support.apple.com/downloads/safari' target='_blank' rel='noreferrer' className='no-underline'>
                    <img src={safari} alt='' className='browser-icon mx-auto' />
                    <p>Safari</p>
                  </a>
                </div>
              </div>
              <div className='flex flex-col sm:flex-row'>
                <div className='mx-4 mb-4 sm:mb-0'>
                  <a href='https://www.mozilla.org/firefox/new/' target='_blank' rel='noreferrer' className='no-underline'>
                    <img src={firefox} alt='' className='browser-icon mx-auto' />
                    <p>Firefox</p>
                  </a>
                </div>
                <div className='mx-4 mb-4 sm:mb-0'>
                  <a href='https://www.microsoft.com/edge' target='_blank' rel='noreferrer' className='no-underline'>
                    <img src={edge} alt='' className='browser-icon mx-auto' />
                    <p>MS Edge</p>
                  </a>
                </div>
              </div>
            </div>
            <p className='error-body-md'>
              Update to the latest versions of supported browsers to enjoy the best possible experience.
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
