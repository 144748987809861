import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import cn from 'classnames';
import type { RootState } from '../../../store/types';
import { ILoanRouteParams } from '../../../interfaces/IRouteParams';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import type { Loan } from '../../../features/loans/types';
import { LoanMilestones } from '../../../features/loans/types';
import { fetchTasks } from '../../../features/tasks/actions';
import { selectors as tasksSelectors } from '../../../features/tasks';
import {
  TasksSectionsProps,
  CompleteTasksSectionProps,
  IncompleteTasksSectionProps,
  TasksSectionProps,
  TasksProps,
} from './ITasklist';
import Task from '../task/Task';
import TaskModal from './TaskModal';
import TasklistAlert, { DocumentsTranslationAlert, TasksTranslationAlert } from '../tasklistAlert/TasklistAlert';
import GenericUploadAlert from '../upload/GenericUploadAlert';
import Circle from '../../ui/circle/Circle';
import { log } from '../../../utils/logger';
import useRetry from '../../../hooks/useRetry';
import { getTaskModalOpen } from '../../../features/tasks/selectors';
import { GatelessComponents } from '../gateless/Gateless';
import useGenericUploads from '../../../hooks/useGenericUploads';
import PendingTasksAlert from '../tasklistAlert/PendingTasksAlert';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';

const Tasks = ({ taskIds }: TasksProps) => {
  const { guid } = useParams<ILoanRouteParams>();
  return taskIds.length ? (
    <div className='tasks'>
      {taskIds.map((id, i) => (
        <Task id={id} loanGuid={guid} key={id} index={i} />
      ))}
    </div>
  ) : null;
};

const TasksSection = ({ taskIds, title, badgeBgColor }: TasksSectionProps) => {
  return !!taskIds.length ? (
    <div className='tasks-section'>
      <h2 className='flex items-center mb-2'>
        <Circle
          className={cn('tasks-section-count', 'mr-3', 'flex-none')}
          bgColor={badgeBgColor}
          size='3rem'
          textSize='large'
          textColor='body'
        >
          {taskIds.length}
        </Circle>
        <span className='tasks-section-headline text-subheadline'>{title}</span>
      </h2>
      <Tasks taskIds={taskIds} />
    </div>
  ) : null;
};

const IncompleteTasksSection = ({ loanGuid, taskIds }: IncompleteTasksSectionProps) => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const genericUploadsEnabled = useGenericUploads(loanGuid);
  const showPendingTasksAlert = useFeatureEnabled('pending-tasks-alert') && !taskIds.length;
  const handleModalToggle = useCallback((open: boolean) => {
    setUploadModalOpen(open);
  }, []);

  return (
    <>
      {(!taskIds.length && genericUploadsEnabled) || uploadModalOpen ? (
        <>
          <GenericUploadAlert loanGuid={loanGuid} onModalToggle={handleModalToggle} />
          {!showPendingTasksAlert && <div className='h-6' />}
        </>
      ) : null}
      {showPendingTasksAlert && <PendingTasksAlert loanGuid={loanGuid} />}
      {!taskIds.length && !genericUploadsEnabled && <TasklistAlert type='tasksCompleted' />}
      {!!taskIds.length && <TasksSection taskIds={taskIds} title='Tasks to complete' badgeBgColor='action-10' />}
    </>
  );
};

const CompleteTasksSection = ({ taskIds }: CompleteTasksSectionProps) =>
  !!taskIds.length ? <TasksSection taskIds={taskIds} title='Tasks completed' badgeBgColor='action-10' /> : null;

const TasksSections = ({ loanGuid, idsByGroups }: TasksSectionsProps) => {
  const incompleteTaskIds = idsByGroups.incomplete;
  const completeTaskIds = idsByGroups.complete;
  return (
    <>
      <IncompleteTasksSection loanGuid={loanGuid} taskIds={incompleteTaskIds} />
      {!!incompleteTaskIds.length && !!completeTaskIds.length && <div className='h-10' />}
      <CompleteTasksSection taskIds={completeTaskIds} />
    </>
  );
};

const milestonesToRetryForTasks = new Set([
  LoanMilestones.APPLICATION,
  LoanMilestones.PREAPPROVAL,
  LoanMilestones.PREAPPROVED,
  LoanMilestones.CONDITIONAL_APPROVAL,
]);

const useRetryTasks = (loan: Loan, incompleteTaskIds: string[]): void => {
  const { 'loan-guid': loanGuid, 'loan-milestone': loanMilestone } = loan;
  const dispatch = useAppDispatch();
  const taskModalOpen = useAppSelector(getTaskModalOpen);

  const handleRetry = ({ retryNum, totalTime }: { retryNum: number; totalTime: number }) => {
    if (!taskModalOpen) {
      log({
        loanGuid,
        message: `TasklistWithTasks: User has zero incomplete tasks and loan milestone is ${loanMilestone}. Re-fetching tasks. ${JSON.stringify(
          {
            retryNum,
            totalTime,
          },
        )}`,
      });
      dispatch(fetchTasks(loanGuid));
    }
  };

  useRetry({
    shouldRetry: !incompleteTaskIds.length && milestonesToRetryForTasks.has(loanMilestone),
    retryDuration: 30000,
    maxRetries: 10,
    onRetry: handleRetry,
    triggerRetryOnInit: false,
  });
};

const TasklistWithTasks = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;
  const idsByGroups = useSelector((state: RootState) => tasksSelectors.getLoanTaskIdsByGroups(state, loanGuid));

  useRetryTasks(loan, idsByGroups.incomplete);

  return (
    <div className='tasklist'>
      <TaskModal />
      <GatelessComponents loanGuid={loanGuid} />
      <TasksTranslationAlert loan={loan} />
      <DocumentsTranslationAlert loan={loan} />
      <TasksSections loanGuid={loanGuid} idsByGroups={idsByGroups} />
    </div>
  );
};

export default TasklistWithTasks;
