import { useMemo } from 'react';
import { sendEvent } from '../../../features/analytics/sendEvent';
import { iDocument }  from '../../../features/documents/types';
import { useTaskTextById } from '../../../hooks/useTaskText';
import { formatDate, FORMAT_ABBREVIATED, FORMAT_SINGLE_DIGIT_SHORT_YEAR, FORMAT_LONG_TIME } from '../../../utils/date';
import { log } from '../../../utils/logger';
import SortableTable, { sortableHeaderCell, sortableTableCell, TableDataRow, TableHeaderCell } from '../../sortableTable/SortableTable';
import Card from '../../ui/card/Card';
import DownloadButton from './DownloadButton';
import cn from 'classnames';
import { Skeleton } from '../../ui/skeleton/Skeleton';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import TextBadge from '../../ui/badge/TextBadge';
import ConditionalWrapper from '../../ui/conditionalWrapper/ConditionalWrapper';
import { updateGlobalLoading } from '../../../features/app/appSlice';
import { useMobileAppDocumentEvent } from '../../../hooks/useMobileAppDocumentEvent';
import { sendDocumentEventToShellApp } from '../../../features/mobileApp/shellAppEvents';
import { selectDocumentsPreviouslyLastViewed } from '../../../features/user/userSlice';

export const DocumentsSkeletons = () => {
  return (
    <div className='pt-4'>
      <Skeleton className='mb-2 w-full' height='30px' />
      <Skeleton className='mb-2 w-full' height='30px' />
    </div>
  );
};

const DocumentDownloadButton = ({ loanGuid, document: { id, title, href, 'task-id': taskId, category } }: { loanGuid: string; document: iDocument }) => {
  const onDownloadClick = () => {
    sendEvent('documentDownload', { location: 'documents', documentId: id, documentCategory: category, taskId });
    log({ loanGuid, taskId, level: 'info', message: `Download document id '${id}' category ${category}` });
  };

  return (
    <DownloadButton
      href={href}
      filename={title}
      onClick={onDownloadClick}
    />
  );
};

const DocumentTitleWidget = ({ loanGuid, document }: { loanGuid: string; document: iDocument }) => {
  const docsLastViewed = useAppSelector(state => selectDocumentsPreviouslyLastViewed(state, loanGuid));
  const docsAdded = Date.parse(document['date-added']);
  const isNew = !docsLastViewed || docsLastViewed < docsAdded;

  return (
    <ConditionalWrapper
      condition={isNew}
      wrapper={children => {
        return (
          <div className='flex items-center'>
            {children}
            <TextBadge className='ml-1' type='action-secondary' text='New' />
          </div>
        );
      }}
    >
      <p className='truncate'>
        {document.title}
      </p>
    </ConditionalWrapper>
  );
};

const TaskTitleWidget = ({ loanGuid, document }: { loanGuid: string; document: iDocument }) => {
  const { 'task-id': taskId, 'task-title': taskTitle } = document;
  const taskName = useTaskTextById(taskId || '', 'title', loanGuid) || taskTitle || 'N/A';
  return (
    <p className='truncate'>
      {taskName}
    </p>
  );
};

const Wrapper = ({ children }: { children: React.ReactNode} ) => {
  return (
    <>
      <div className='block lg:hidden'>
        {children}
      </div>
      <div className='hidden lg:block'>
        <Card>
          {children}
        </Card>
      </div>
    </>
  );
};

const InternalDocumentsTable = (
  {
    loanGuid,
    loading,
    title,
    headers,
    dataRows,
    columnClassNames,
    columnHeaderClassNames,
    columnDataClassNames,
    emptyText,
  }:
  {
    loanGuid: string;
    loading: boolean;
    title: string;
    headers: TableHeaderCell[];
    dataRows: TableDataRow[];
    columnClassNames: (string | undefined)[];
    columnHeaderClassNames?: (string | undefined)[];
    columnDataClassNames?: (string | undefined)[];
    emptyText: string;
  }
) => {
  const mobileAppDocumentEvent = useMobileAppDocumentEvent();
  const dispatch = useAppDispatch();

  const viewDocument = async (document: iDocument) => {
    const { id, title, href, 'task-id': taskId, category } = document;

    sendEvent('documentView', { location: 'documents', documentId: id, documentCategory: category, taskId });
    log({ loanGuid, taskId, level: 'info', message: `View document id '${id}' category ${category}` });

    if (mobileAppDocumentEvent) {
      dispatch(updateGlobalLoading(true));
      await sendDocumentEventToShellApp('VIEW_DOCUMENT', href, title);
      dispatch(updateGlobalLoading(false));
    } else {
      window.open(href, '_blank', 'noopener');
    }
  };

  const onRowClick = async (document: iDocument) => {
    viewDocument(document);
  };

  const onRowKeypress = (document: iDocument, key: string) => {
    if (key && key !== 'Enter' && key !== 'Spacebar') return;
    viewDocument(document);
  };

  return (
    <Wrapper>
      <header className='font-bold text-xl'>{title}</header>
      {
        loading ? <DocumentsSkeletons />
        :
        !dataRows || dataRows.length === 0 ? <p className='mt-5'>{emptyText}</p>
        :
        <SortableTable
          headers={headers}
          dataRows={dataRows}
          loading={loading}
          maxRow={6}
          columnClassNames={columnClassNames}
          columnHeaderClassNames={columnHeaderClassNames}
          columnDataClassNames={columnDataClassNames}
          onRowClick={onRowClick}
          onRowKeypress={onRowKeypress}
        />
      }
    </Wrapper>
  );
};

const signingDocumentsTableHeaders = [
  // document name
  sortableHeaderCell('Name', true),
  // large only task category
  sortableHeaderCell('Category', true),
  // large format date
  sortableHeaderCell('Upload date', true),
  // mobile format date
  sortableHeaderCell('Uploaded ', true),
  // sort icon
  sortableHeaderCell(undefined),
];

const uploadedDocumentsTableHeaders = [
  // document name
  sortableHeaderCell('Name', true),
  // large only task title
  sortableHeaderCell('Task', true),
  // large format date
  sortableHeaderCell('Upload date', true),
  // mobile format date
  sortableHeaderCell('Uploaded ', true),
  // sort icon
  sortableHeaderCell(undefined),
];

export const DocumentsTable = (
  {
    className,
    loanGuid,
    type,
    documents,
    loading,
  }:
  {
    className?: string;
    loanGuid: string;
    type: 'upload-documents' | 'signing-documents';
    documents: iDocument[] | undefined;
    loading: boolean;
  }
) => {
  const tableHeaders = type === 'signing-documents' ? signingDocumentsTableHeaders : uploadedDocumentsTableHeaders;

  const tableData = useMemo(() => {
    if (documents && documents.length > 0) {
      const data = documents.map(document => {
        return {
          dataModel: document,
          cells: [
            type === 'signing-documents'
              ? sortableTableCell({ data: document.title, tooltipText: document.title, widget: <DocumentTitleWidget loanGuid={loanGuid} document={document} />})
              : sortableTableCell({ data: document.title }),
            type === 'signing-documents'
              ? sortableTableCell({ data: document.subcategory })
              : sortableTableCell({ data: document['task-title'], widget: <TaskTitleWidget loanGuid={loanGuid} document={document} />}),
            sortableTableCell({ data: document['date-added'], widget: <p title={formatDate(document['date-added'], FORMAT_LONG_TIME)}>{formatDate(document['date-added'], FORMAT_ABBREVIATED)}</p> }),
            sortableTableCell({ data: document['date-added'], widget: <p title={formatDate(document['date-added'], FORMAT_LONG_TIME)}>{formatDate(document['date-added'], FORMAT_SINGLE_DIGIT_SHORT_YEAR)}</p> }),
            sortableTableCell({ widget: <DocumentDownloadButton loanGuid={loanGuid} document={document} />, defaultClick: false }),
          ]
        };
      });
      return data;
    }
    return [];
  }, [loanGuid, documents, type]);

  return (
    <div className={cn(className)}>
      <InternalDocumentsTable
        loanGuid={loanGuid}
        title={ type === 'signing-documents' ? 'Loan documents' : 'Documents uploaded to tasks' }
        headers={tableHeaders}
        dataRows={tableData}
        loading={loading}
        columnClassNames={[
          'w-3/5 lg:w-inherit h-14 lg:pl-5',
          'hidden lg:table-cell h-14',
          'hidden lg:table-cell h-14',
          'table-cell lg:hidden h-14',
          'w-10 h-14'
        ]}
        columnDataClassNames={[
          // name
          'OneLinkNoTx',
          // category/task
          type === 'signing-documents' ? undefined : 'OneLinkNoTx',
          // upload date
          undefined,
          undefined,
          // download icon
          undefined,
        ]}
        emptyText={ type === 'signing-documents' ? 'You do not have any documents at the moment.' : 'You do not have any uploaded documents at the moment.' }
      />
    </div>
  );
};
