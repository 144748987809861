import cn from 'classnames';

type ISubNav<T extends string> = [key: T, label: string | JSX.Element, onClick: (key: T) => void];

export function SubNav<T extends string>(active: T, navs: ISubNav<T>[]) {
  return (
    <div id='support-navigation' className='flex flex-row mt-2 mb-10 border-b border-gray-25 w-full'>
      {navs.map(([key, label, onClick]) => (
        <button
          key={key}
          onClick={() => onClick(key)}
          className={cn(
            'lg:px-10 w-1/2 lg:w-auto py-2 text-center text-lg cursor-pointer no-select relative',
            { 'border-b border-gray-25': key !== active },
            { 'border-b-4 border-primary font-bold': key === active },
          )}
          style={{ top: '1px' }}
        >
          <div className='no-underline'>{label}</div>
        </button>
      ))}
    </div>
  );
}
