import { useEffect, useRef } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { ILoanRouteParams } from '../../interfaces/IRouteParams';
import { useAppSelector } from '../../hooks/hooks';
import { setDataLayer } from '../../features/analytics/setDataLayer';
import { setPage } from '../../features/analytics/hotjar';
import { selectHasConfig } from '../../features/config/configSlice';
import { PATHS, homeLoanPaths } from '../routes/paths';
import getReferrer from '../../utils/getReferrer';
import { log } from '../../utils/logger';

const RouteLogger = () => {
  const unloaded = useRef<boolean>(false);
  const location = useLocation();

  const homeLoanRouteMatch = useRouteMatch<ILoanRouteParams>({
    path: homeLoanPaths,
    exact: true,
  });
  const personalLoanRouteMatch = useRouteMatch<ILoanRouteParams>({
    path: PATHS.personalLoan,
    exact: true,
  });
  const helocLoanRouteMatch = useRouteMatch<ILoanRouteParams>({
    path: [PATHS.helocLoan, PATHS.helocLoanServicing],
    exact: true,
  });
  const loanGuid = personalLoanRouteMatch?.params.guid || helocLoanRouteMatch?.params.guid || homeLoanRouteMatch?.params.guid;
  const hasConfigData = useAppSelector(selectHasConfig);

  useEffect(() => {
    log({
      level: 'info',
      loanGuid,
      message: `user navigated to - ${location.pathname}${location.search}${location.hash}`,
    });
  }, [location.pathname, location.search, location.hash, loanGuid]);

  useEffect(() => {
    if (hasConfigData) {
      setDataLayer('pageInfo', {
        referrer: getReferrer(),
      });
    }
  }, [hasConfigData]);

  useEffect(() => {
    if (!loanGuid) {
      setDataLayer('currentLoan', null);
    }
    setDataLayer('currentLoanGuid', loanGuid || null);
    setDataLayer('pageInfo.loanGuid', loanGuid);
  }, [location.pathname, loanGuid]);

  useEffect(() => {
    setDataLayer('pageInfo', {
      path: location.pathname,
      url: window.location.href,
    });
    setPage(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const onVisibilityChange = () => {
      if (unloaded.current) return null;
      log({ message: `App ${document.hidden ? 'hidden' : 'visible'}` });
      return null;
    };
    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const onBeforeUnload = () => {
      unloaded.current = true;
      log({ message: 'App unloaded' });
      return null;
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  return null;
};

export default RouteLogger;
