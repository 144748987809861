import { Details } from '../../../pages/homeValue/IHomeValue';
import { usdAmount } from '../../../utils/amount';
import './HomeFeatures.scss';
interface HomeFeaturesProps {
  details: Details;
}

const HomeFeatures = ({ details }: HomeFeaturesProps) => {
  return (
    <>
      <div className='flex items-center mb-4'>
        <p className='header-small'>Features</p>
      </div>

      {/* features */}
      <div>
        <Feature title='Home type' val={details['property-type'] ?? 'n/a'} />
        <Feature title='Bedrooms' val={details.beds?.toLocaleString() ?? 'n/a'} />
        <Feature title='Bathrooms' val={details.baths?.toLocaleString() ?? 'n/a'} />
        <Feature title='Square footage' val={details['living-area'] ? details['living-area'].toLocaleString() : 'n/a'} />
        <Feature title='Last sold price' val={usdAmount(details['sold-price'], false) || '$ --'} />
        <Feature title='Last year sold' val={details['sold-date'] ? details['sold-date'].toString().slice(0, 4) : 'n/a'} />
        <Feature title='Year built' val={details['year-built'] ? details['year-built'].toString().slice(0, 4) : 'n/a'} />
        <Feature
          title={details['tax-assessed-year'] ? `${details['tax-assessed-year']} tax assessment` : 'Tax assessment'}
          val={usdAmount(details['tax-assessed-value'], false) || '$ --'}
        />
      </div>
    </>
  );
};

const Feature = ({ title, val }: { title: string; val: string }) => (
  <p className='home-feature-grid mb-4'>
    <span className='home-feature-grid__title'>{title}</span>
    <span className='home-feature-grid__value font-bold'>{val}</span>
  </p>
);

export default HomeFeatures;
