import { useState } from 'react';
import Button from '../../../components/ui/button/Button';
import { buttonSizes, buttonTypes } from '../../../components/ui/button/IButton';
import OneClickButton from '../../../components/ui/button/OneClickButton';
import { PhoneButton } from '../../../components/ui/button/PhoneButton';
import { PhoneButtonGroup } from '../../../components/ui/button/PhoneButtonGroup';
import ToggleButtons from '../../../components/ui/button/ToggleButtons';
import Checkbox from '../../../components/ui/checkbox/Checkbox';
import Icon from '../../../components/ui/icon/Icon';
import { ucfirst } from '../../../utils/str';
import { content } from '../../../features/tenant/tenant';
import { EmailButton } from '../../../components/ui/button/EmailButton';

// todo: add back with navigation reskin
// const Navigation = () => {
//   return (
//     <>
//       <h3 className='header-small'>Navigation TBD</h3>
//     </>
//   );
// };

interface CheckboxFormData {
  id: string;
  name: string;
  value: string;
  checked: boolean;
}

const mockPhone = '626-555-1234';

const Controls = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const defaultCheckboxErrorText = 'Error text';
  const [checkbox5ErrorText, setCheckbox5ErrorText] = useState<string | undefined>(defaultCheckboxErrorText);
  return (
    <>
      <div className='mb-6'>
        <h2 className='header-medium'>Controls</h2>
      </div>
      <div className='controls'>
        <ul className='mb-8 grid grid-cols-3'>
          {[...buttonSizes].reverse().map(size => (
            <li key={size}>
              <h2 className='header-small capitalize'>{size}</h2>

              {/* loop through each button now */}
              {[...buttonTypes]
                .filter(x => x !== 'nav')
                .map(type => (
                  <div key={type}>
                    {type !== 'icon' && (
                      <div className='styleguide__button mb-4'>
                        <Button text={ucfirst(type)} title={`${ucfirst(type)} Button`} buttonType={type} size={size} />
                      </div>
                    )}

                    {/* inline and subnav cannot have icons */}
                    {type !== 'inline' && type !== 'subnav' && (
                      <div className='styleguide__button mb-4'>
                        <Button
                          buttonType={type}
                          title={`${ucfirst(type)} Button`}
                          size={size}
                          iconPlacement='left'
                          icon={
                            <Icon
                              name='interface-download'
                              size='1rem'
                              className='no-underline'
                              style={{
                                display: 'inline-block',
                                textDecoration: 'none',
                              }}
                            />
                          }
                        >
                          {ucfirst(type)}
                        </Button>
                      </div>
                    )}
                    {/* inline and subnav cannot have icons */}
                    {type !== 'inline' && type !== 'subnav' && (
                      <>
                        <div className='styleguide__button mb-4'>
                          <Button
                            buttonType={type}
                            title={`${ucfirst(type)} Button`}
                            size={size}
                            icon={
                              <Icon
                                name='interface-download'
                                size='1rem'
                                className='no-underline'
                                style={{
                                  display: 'inline-block',
                                  textDecoration: 'none',
                                }}
                              />
                            }
                          >
                            {ucfirst(type)}
                          </Button>
                        </div>
                        <div className='styleguide__button mb-4'>
                          <Button
                            text={`${ucfirst(type)} (disabled)`}
                            title={`${ucfirst(type)} Button`}
                            buttonType={type}
                            size={size}
                            disabled={true}
                          />
                        </div>
                      </>
                    )}
                    {/* inline and subnav cannot have loaders */}
                    {(type === 'primary' || type === 'secondary') && (
                      <div className='styleguide__button mb-4'>
                        <Button
                          loading={buttonLoading}
                          onClick={() => {
                            setButtonLoading(true);
                            setTimeout(() => setButtonLoading(false), 1000);
                          }}
                          text={`${ucfirst(type)} (loading)`}
                          title={`${ucfirst(type)} Button`}
                          buttonType={type}
                          size={size}
                        />
                      </div>
                    )}
                  </div>
                ))}
              <div className='styleguide__button mb-4'>
                <Button buttonType='icon' iconName='interface-download' text='Button Aria Label' />
              </div>
              <div className='styleguide__button mb-4'>
                <Button
                  buttonType='icon'
                  text='Button Aria Label'
                  icon={
                    <Icon
                      name='eye-show'
                      size='1rem'
                      className='no-underline'
                      style={{
                        display: 'inline-block',
                        textDecoration: 'none',
                      }}
                    />
                  }
                />
                <div className='styleguide__button mb-4'>
                  <Button
                    buttonType='nav'
                    text='Unselected'
                    icon={
                      <Icon
                        name='eye-show'
                        size='1.25rem'
                        className='no-underline'
                        style={{
                          display: 'inline-block',
                          textDecoration: 'none',
                        }}
                      />
                    }
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>

        <div className='mt-5 flex flex-col gap-4'>
          <h2 className='header-small capitalize'>Phone Buttons</h2>
          <div className='mb-2'>
            <h3>buttonType=inline(default), showIcon=true</h3>
            <PhoneButton showIcon={true} phone={mockPhone} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=inline(default), showIcon=false</h3>
            <PhoneButton showIcon={false} phone={mockPhone} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=plain, showIcon=true</h3>
            <PhoneButton buttonType='plain' showIcon={true} phone={mockPhone} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=plain, showIcon=false</h3>
            <PhoneButton buttonType='plain' showIcon={false} phone={mockPhone} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=quaternary, showIcon=true</h3>
            <PhoneButton buttonType='quaternary' showIcon={true} phone={mockPhone} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=quaternary, showIcon=false</h3>
            <PhoneButton buttonType='quaternary' showIcon={false} phone={mockPhone} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=tertiary, showIcon=true</h3>
            <PhoneButton buttonType='tertiary' showIcon={true} phone={mockPhone} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=tertiary, showIcon=false</h3>
            <PhoneButton buttonType='tertiary' showIcon={false} phone={mockPhone} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=secondary, showIcon=true</h3>
            <PhoneButton buttonType='secondary' showIcon={true} phone={mockPhone} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=secondary, showIcon=false</h3>
            <PhoneButton buttonType='secondary' showIcon={false} phone={mockPhone} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=primary, showIcon=true</h3>
            <PhoneButton buttonType='primary' showIcon={true} phone={mockPhone} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=primary, showIcon=false</h3>
            <PhoneButton buttonType='primary' showIcon={false} phone={mockPhone} />
          </div>
        </div>

        <div className='mt-5 flex flex-col gap-4'>
          <h2 className='header-small capitalize'>Phone Button Groups</h2>
          <div className='mb-2'>
            <h3>buttonType=inline(default), showIcon=true</h3>
            <PhoneButtonGroup phoneList={[{ phone: '626-555-1234' }, { phone: '858-555-1234', suffix: 'option 4' }]} showIcon={true} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=inline(default), showIcon=false</h3>
            <PhoneButtonGroup phoneList={[{ phone: '626-555-1234' }, { phone: '858-555-1234', suffix: 'option 4' }]} showIcon={false} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=plain, showIcon=true</h3>
            <PhoneButtonGroup phoneList={[{ phone: '626-555-1234' }, { phone: '858-555-1234', suffix: 'option 4' }]} buttonType='plain' showIcon={true} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=plain, showIcon=false</h3>
            <PhoneButtonGroup phoneList={[{ phone: '626-555-1234' }, { phone: '858-555-1234', suffix: 'option 4' }]} buttonType='plain' showIcon={false} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=quaternary, showIcon=true</h3>
            <PhoneButtonGroup phoneList={[{ phone: '626-555-1234' }, { phone: '858-555-1234', suffix: 'option 4' }]} buttonType='quaternary' showIcon={true} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=quaternary, showIcon=false</h3>
            <PhoneButtonGroup phoneList={[{ phone: '626-555-1234' }, { phone: '858-555-1234', suffix: 'option 4' }]} buttonType='quaternary' showIcon={false} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=tertiary, showIcon=true</h3>
            <PhoneButtonGroup phoneList={[{ phone: '626-555-1234' }, { phone: '858-555-1234', suffix: 'option 4' }]} buttonType='tertiary' showIcon={true} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=tertiary, showIcon=false</h3>
            <PhoneButtonGroup phoneList={[{ phone: '626-555-1234' }, { phone: '858-555-1234', suffix: 'option 4' }]} buttonType='tertiary' showIcon={false} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=secondary, showIcon=true</h3>
            <PhoneButtonGroup phoneList={[{ phone: '626-555-1234' }, { phone: '858-555-1234', suffix: 'option 4' }]} buttonType='secondary' showIcon={true} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=secondary, showIcon=false</h3>
            <PhoneButtonGroup phoneList={[{ phone: '626-555-1234' }, { phone: '858-555-1234', suffix: 'option 4' }]} buttonType='secondary' showIcon={false} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=primary, showIcon=true</h3>
            <PhoneButtonGroup phoneList={[{ phone: '626-555-1234' }, { phone: '858-555-1234', suffix: 'option 4' }]} buttonType='primary' showIcon={true} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=primary, showIcon=false</h3>
            <PhoneButtonGroup phoneList={[{ phone: '626-555-1234' }, { phone: '858-555-1234', suffix: 'option 4' }]} buttonType='primary' showIcon={false} />
          </div>
          <div className='mb-2'>
            <h3>mixed button types</h3>
            <PhoneButtonGroup className='all-items' phoneList={[{ phone: '626-555-1234' }, { className: 'just-secondary-button', buttonType: 'secondary', phone: '858-555-1234', suffix: 'option 4' }]} buttonType='primary' showIcon={false} />
          </div>
        </div>

        <div className='mt-5 flex flex-col gap-4'>
          <h2 className='header-small capitalize'>Email Buttons</h2>
          <div className='mb-2'>
            <h3>buttonType=inline(default), showIcon=true</h3>
            <EmailButton showIcon={true} email={content.supportEmail} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=inline(default), showIcon=false</h3>
            <EmailButton showIcon={false} email={content.supportEmail} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=plain, showIcon=true</h3>
            <EmailButton buttonType='plain' showIcon={true} email={content.supportEmail} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=plain, showIcon=false</h3>
            <EmailButton buttonType='plain' showIcon={false} email={content.supportEmail} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=quaternary, showIcon=true</h3>
            <EmailButton buttonType='quaternary' showIcon={true} email={content.supportEmail} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=quaternary, showIcon=false</h3>
            <EmailButton buttonType='quaternary' showIcon={false} email={content.supportEmail} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=tertiary, showIcon=true</h3>
            <EmailButton buttonType='tertiary' showIcon={true} email={content.supportEmail} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=tertiary, showIcon=false</h3>
            <EmailButton buttonType='tertiary' showIcon={false} email={content.supportEmail} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=secondary, showIcon=true</h3>
            <EmailButton buttonType='secondary' showIcon={true} email={content.supportEmail} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=secondary, showIcon=false</h3>
            <EmailButton buttonType='secondary' showIcon={false} email={content.supportEmail} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=primary, showIcon=true</h3>
            <EmailButton buttonType='primary' showIcon={true} email={content.supportEmail} />
          </div>
          <div className='mb-2'>
            <h3>buttonType=primary, showIcon=false</h3>
            <EmailButton buttonType='primary' showIcon={false} email={content.supportEmail} />
          </div>
        </div>

        <div className='mt-5 flex flex-col gap-4'>
          <h2 className='header-small capitalize'>Affirmation Checkboxes</h2>
          <form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              const { checkbox1, checkbox2, checkbox3, checkbox4, checkbox5 } = event.target as typeof event.target & {
                checkbox1?: CheckboxFormData;
                checkbox2?: CheckboxFormData;
                checkbox3?: CheckboxFormData;
                checkbox4?: CheckboxFormData;
                checkbox5?: CheckboxFormData;
              };

              console.log(
                'Submit Affirmation Checkboxes',
                JSON.stringify({
                  checkbox1: checkbox1 && {
                    id: checkbox1.id,
                    name: checkbox1.name,
                    value: checkbox1.value,
                    checked: checkbox1.checked,
                  },
                  checkbox2: checkbox2 && {
                    id: checkbox2.id,
                    name: checkbox2.name,
                    value: checkbox2.value,
                    checked: checkbox2.checked,
                  },
                  checkbox3: checkbox3 && {
                    id: checkbox3.id,
                    name: checkbox3.name,
                    value: checkbox3.value,
                    checked: checkbox3.checked,
                  },
                  checkbox4: checkbox4 && {
                    id: checkbox4.id,
                    name: checkbox4.name,
                    value: checkbox4.value,
                    checked: checkbox4.checked,
                  },
                  checkbox5: checkbox5 && {
                    id: checkbox5.id,
                    name: checkbox5.name,
                    value: checkbox5.value,
                    checked: checkbox5.checked,
                  },
                }),
              );
              alert('Check the dev console for logs');
            }}
          >
            <Checkbox initValue={false} id='checkbox1' name='checkbox' value='TEST unchecked checkbox' className='pb-4' />
            <Checkbox initValue={true} id='checkbox2' name='checkbox' value='TEST checked checkbox' className='pb-4' />
            <Checkbox initValue={false} id='checkbox3' name='checkbox' value='TEST unchecked disabled checkbox' disabled={true} className='pb-4' />
            <Checkbox initValue={true} id='checkbox4' name='checkbox' value='TEST checked disabled checkbox' disabled={true} className='pb-4' />
            <Checkbox
              initValue={false}
              id='checkbox5'
              name='checkbox'
              value='TEST error checkbox'
              error={checkbox5ErrorText}
              className='pb-4'
              onChange={isChecked => {
                setCheckbox5ErrorText(isChecked ? undefined : defaultCheckboxErrorText);
              }}
            />
            <Button buttonType='primary' type='submit' className='mt-2'>
              Submit
            </Button>
          </form>
        </div>

        <div className='mt-5 flex flex-col gap-4'>
          <h2 className='header-small capitalize'>Affirmation Buttons</h2>
          <ToggleButtons
            error={false}
            leftLabel='No'
            rightLabel='Yes'
            onLeftClick={() => console.log('left click')}
            onRightClick={() => console.log('right click')}
          />
          <ToggleButtons
            error={true}
            leftLabel='No'
            rightLabel='Yes'
            onLeftClick={() => console.log('left click')}
            onRightClick={() => console.log('right click')}
          />
          <ToggleButtons
            error={false}
            leftLabel='No'
            rightLabel='Yes'
            onLeftClick={() => console.log('left click')}
            onRightClick={() => console.log('right click')}
            disabled={true}
          />
          <OneClickButton error={false} label='I understand' />
          <OneClickButton error={true} label='I understand' />
        </div>
      </div>
    </>
  );
};

export default Controls;
