import { iTheme } from "./types";

const themeConfig = {
  id: window.tenantId,
  themeId: window.themeId,
  content: window.tenantContent,
  features: window.tenantFeatures,
  theme: window.tenantTheme,
} as iTheme;

export const { id, themeId, content, features, theme } = themeConfig;

export default themeConfig;
