import { LoanConfig } from './ILoanCard';
import { linkTo, PATHS } from '../../routes/paths';
import { isFundedLoan } from '../../../features/loans/helpers';
import { useLoan } from '../../../features/loans/hooks';
import { Loan } from '../../../features/loans/types';
import { DashboardLoanCard } from './DashboardLoanCards';
import { TimeProps } from '../../ui/time/ITime';
import Time from '../../ui/time/Time';
import { formatDateWithoutTimezone, FORMAT_SHORT, FORMAT_LONG } from '../../../utils/date';

const DateInfo = ({ 'started-date': startedDate }: Loan) => {
  let dateInfo;
  if (startedDate) {
    dateInfo = {
      date: startedDate,
      label: `Application date ${formatDateWithoutTimezone(startedDate, FORMAT_SHORT)}`,
      title: formatDateWithoutTimezone(startedDate, FORMAT_LONG),
    };
  }

  if (!dateInfo) return null;

  return <Time {...(dateInfo as TimeProps)} />;
};

export const DashboardCESLoanCardPresentational = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;
  const loanConfig: LoanConfig = {
    loanTypeText: 'Home Equity',
    showTracker: !isFundedLoan(loan),
    trackerValue: 50,
    actionHref: isFundedLoan(loan) ?
      linkTo(PATHS.homeLoanServicing, { guid: loanGuid }) :
      linkTo(PATHS.homeLoanOverview, { guid: loanGuid }),
    actionLabel: 'View loan',
  };

  return (
    <DashboardLoanCard
      loanAmount={loan['base-loan-amount']}
      loanConfig={loanConfig}
      dateNode={<DateInfo {...loan} />}
      loanNumber={loan['loan-number']}
      address={loan.property}
    />
  );
};

export const DashboardCESLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const { data } = useLoan(loanGuid);
  if (!data) return null;
  return (
    <DashboardCESLoanCardPresentational loan={data} />
  );
};
