import ActionBannerList from '../../../components/ui/actionBannerList/ActionBannerList';
import { ActionBannerProps } from '../../../components/ui/actionBanner/iActionBanner';
import {
  loanAssistance,
  genericUpload,
  managePreapprovals,
  offerAccepted,
  openTasks,
  visitTalkuments,
} from '../../../components/loan/nextSteps/LoanNextSteps';
import mockLoanOfficer from '../../../mocks/teamLo';
import { ActionBannerSkeleton } from '../../../components/ui/actionBanner/ActionBanner';

export const mockActions: ActionBannerProps[] = [
  loanAssistance('loanNotReady', mockLoanOfficer),
  offerAccepted(mockLoanOfficer),
  managePreapprovals('mock-loan-guid'),
  openTasks('mock-loan-guid', 2),
  visitTalkuments('mock-loan-guid', '1234567890', 'es'),
  genericUpload('mock-loan-guid'),
];

const mockErrorStateActions: ActionBannerProps[] = [
  loanAssistance('loanNotReady'),
  loanAssistance('loanNotReady', mockLoanOfficer),
  loanAssistance('tasksFailed', mockLoanOfficer),
  loanAssistance('tasksFailed'),
];

const ActionBannerListStyleguide = () => {
  return (
    <>
      <div className='header-small mb-6'>What to do next</div>
      <div className='mb-4'>
        <ActionBannerSkeleton />
      </div>
      <ActionBannerList actions={mockActions} />
      <div className='header-small mt-8 mb-6'>What to do next (error states)</div>
      <ActionBannerList actions={mockErrorStateActions} />
    </>
  );
};

export default ActionBannerListStyleguide;
