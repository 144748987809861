import {
  FormErrorProps,
} from './IFormError';

export const FormError = ({ error }: FormErrorProps) => {
  return error ? (
    <div
      className='form-error'
      role='alert'
      aria-live='assertive'
      aria-atomic={true}
    >
      {error}
    </div>
  ) : null;
};

export default FormError;
