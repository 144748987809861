import { useEffect, useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import nextId from '../../utils/nextId';
import Button from '../ui/button/Button';
import Icon from '../ui/icon/Icon';
import cn from 'classnames';
import '../ui/tooltip/Tooltip.scss';
import { Skeleton } from '../ui/skeleton/Skeleton';
import './SortableTable.scss';

export type TableHeaderCell = {
  headerName: string | undefined;
  sortable: boolean;
};

export type TableDataCell = {
  // sortable data
  data: string | number;
  // display widget
  widget: string | React.ReactNode;
  // flag indicates if should use the default click should fire
  defaultClick: boolean;
  // tooltip text on hover
  tooltipText?: string;
  // show tooltip text on hover
  showTooltipTextOnHover?: boolean;
};

export type TableDataRow = {
  cells: TableDataCell[];
  dataModel: any;
};

type Props = {
  headers: TableHeaderCell[];
  dataRows: TableDataRow[];
  // style to apply to each column
  columnClassNames?: (string | undefined)[];
  // style to apply to each column header
  columnHeaderClassNames?: (string | undefined)[];
  // style to apply to each column data
  columnDataClassNames?: (string | undefined)[];
  maxRow?: number;
  loading?: boolean;
  onRowClick?: (data: any) => void;
  onRowKeypress?: (data: any, key: string) => void;
};

const SortIcon = () => {
  return (
    <div className='flex flex-col'>
      <Icon name='sort-ascending' size='0.5rem' />
      <Icon name='sort-descending' size='0.5rem' />
    </div>
  );
};

export const sortableHeaderCell = (headerName: string | undefined, sortable?: boolean) => {
  return {
    headerName,
    sortable: !!sortable,
  };
};

export const sortableTableCell = (
  {
    data,
    widget,
    defaultClick = true,
    tooltipText,
    showTooltipTextOnHover,
  }:
  {
    data?: string | number;
    widget?: React.ReactNode;
    defaultClick?: boolean;
    tooltipText?: string;
    showTooltipTextOnHover?: boolean;
  }
) => {
  const display = widget ? widget : data ? data : '';
  return {
    data: data || '',
    widget: display,
    defaultClick: !!defaultClick,
    tooltipText,
    showTooltipTextOnHover,
  };
};

const SortableTable = (
  {
    headers,
    dataRows: originalDataRows,
    columnClassNames,
    columnHeaderClassNames,
    columnDataClassNames,
    maxRow,
    loading = false,
    onRowClick,
    onRowKeypress,
  }: Props
) => {
  const tableIdRef = useRef(nextId('sortable-table-'));
  const dataRowsRef = useRef([...originalDataRows]);
  const columnCountRef = useRef(headers.length);
  const memorizedMaxRow = useMemo(() => {
    return maxRow && maxRow <= originalDataRows.length ? maxRow : originalDataRows.length;
  }, [maxRow, originalDataRows]);
  const [maxRowDisplay, setMaxRowDisplay] = useState(memorizedMaxRow);
  const [displayData, setDisplayData] = useState(maxRowDisplay >= originalDataRows.length ? [...originalDataRows] : [...originalDataRows].slice(0, maxRowDisplay));
  const [columnSortAscending, setColumnSortAscending] = useState((new Array(columnCountRef.current)).fill(false));
  const [tooltipContent, setTooltipContent] = useState<string | React.ReactNode | undefined>(undefined);

  useEffect(() => {
    dataRowsRef.current = [...originalDataRows];
    const newMaxRow = memorizedMaxRow;
    setMaxRowDisplay(newMaxRow);
    setDisplayData(newMaxRow >= originalDataRows.length ? [...originalDataRows] : [...originalDataRows].slice(0, newMaxRow));
  }, [originalDataRows, maxRow, memorizedMaxRow]);

  const sortByColumn = (columnIndex: number) => {
    // flip sorting order
    const sortAscending = !columnSortAscending[columnIndex];
    columnSortAscending[columnIndex] = sortAscending;
    setColumnSortAscending([...columnSortAscending]);

    const sortedDataRows = originalDataRows.sort((row1, row2) => {
      let data1 = row1.cells[columnIndex].data;
      data1 = typeof data1 === 'string' ? data1.toLowerCase() : data1;
      let data2 = row2.cells[columnIndex].data;
      data2 = typeof data2 === 'string' ? data2.toLowerCase() : data2;
      if (sortAscending) {
        return data1 > data2 ? 1 : data1 < data2 ? -1 : 0;
      } else {
        return data2 > data1 ? 1 : data2 < data1 ? -1 : 0;
      }
    });

    dataRowsRef.current = sortedDataRows;
    setDisplayData(sortedDataRows.slice(0, maxRowDisplay));
  };

  const onShowAllRows = () => {
    setMaxRowDisplay(originalDataRows.length);
    setDisplayData([...dataRowsRef.current]);
  };

  const onDataHover = (event: any, tooltipContent: string | React.ReactNode, forceShow: boolean) => {
    const parentElement = event.target.parentElement;
    // if force show tooltip OR has scroll
    if (
      forceShow ||
      event.target.scrollWidth > event.target.clientWidth ||
      (parentElement && parentElement.scrollWidth > parentElement.clientWidth)
    ) {
      setTooltipContent(tooltipContent);
    }
  };

  const onDataLeave = () => {
    setTooltipContent(undefined);
  };

  const handleClick = (event: React.MouseEvent, rowIndex: number, cellDefaultClick: boolean) => {
    if (!cellDefaultClick || !!window.getSelection()?.toString()) { // Prevent default click event during text selection
      event.stopPropagation();
      return;
    }
    const model = displayData[rowIndex].dataModel
    onRowClick?.(model)
  };

  const handleKeypress = (event: React.KeyboardEvent, rowIndex: number) => {
    const model = displayData[rowIndex].dataModel
    onRowKeypress?.(model, event.key);
  };

  const stopCellKeyPropagation = (event: React.KeyboardEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      <div className='w-full sortable-table table table-fixed border-collapse'>
        {/* headers */}
        <div className='table-row border-b border-inactive-25'>
          {headers.map((header, columnIndex) => {
            return (
              <div key={`header-${columnIndex}`} className={cn('table-cell text-left align-middle py-3', columnClassNames?.[columnIndex], columnHeaderClassNames?.[columnIndex])}>
                {header.sortable
                  ?
                  <Button buttonType='plain' onClick={() => sortByColumn(columnIndex)} icon={<SortIcon />}>
                    <div className='flex items-center'>
                      <span className='font-bold'>{header.headerName}</span>
                    </div>
                  </Button>
                  :
                  header.headerName
                  ?
                  <p className='font-bold'>{header.headerName}</p>
                  :
                  null
                }
              </div>
            );
          })}
        </div>
        {/* data */}
        {loading && displayData.length === 0 &&
          <div className='table-row border-b border-inactive-25'>
            {headers.map((_header, columnIndex) => {
              return (
                <div key={`loading-${columnIndex}`} className={cn('table-cell text-center align-middle px-0.5', columnClassNames?.[columnIndex])}>
                  <Skeleton className='w-full' height='30px' />
                </div>
              );
            })}
          </div>
        }
        {!loading && displayData.map((row, rowIndex) => {
          return (

            <div key={`data-${rowIndex}`} className='table-row border-b border-inactive-25' tabIndex={onRowClick ? 0 : -1} onKeyDown={event => handleKeypress(event, rowIndex)}>
              {row.cells.map((data, columnIndex) => {
                const key = `data-${rowIndex}x${columnIndex}`;
                return (
                  <div
                    key={key}
                    className={cn('table-cell text-left align-middle overflow-hidden', columnClassNames?.[columnIndex], columnDataClassNames?.[columnIndex])}
                    onClick={event => handleClick(event, rowIndex, data.defaultClick)}
                    // if the cell has onRowClick defined or it does not do default click - which implies it handles click
                    role={onRowClick || !data.defaultClick ? 'button' : 'cell'}
                  >
                    <div
                      data-tip
                      data-for={`${tableIdRef.current}-tooltip`}
                      className='truncate'
                      onMouseEnter={event => onDataHover(event, data.tooltipText || data.widget, !!data.showTooltipTextOnHover)}
                      onMouseLeave={onDataLeave}
                      onKeyDown={stopCellKeyPropagation}
                    >
                      {data.widget}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      {/* Show all rows button */}
      {maxRowDisplay < originalDataRows.length &&
        <Button
          className='pt-3 w-fit-content'
          buttonType='tertiary'
          text='See more'
          onClick={onShowAllRows}
        />
      }

      {/* Data tooltip */}
      {tooltipContent &&
        <div className='tooltip'>
          <ReactTooltip
            id={`${tableIdRef.current}-tooltip`}
            place='bottom'
            type='light'
            border={true}
            backgroundColor='#fff'
            borderColor='#d8d8d8'
            className='ow-anywhere'
          >
            <div className='Truncate__Override'>
              {tooltipContent}
            </div>
          </ReactTooltip>
        </div>
      }
    </>
  );
};

export default SortableTable;
