import { selectLoan } from '../features/loans/loansSlice';
import { LoanMilestones } from '../features/loans/types';
import { useAppSelector } from './hooks';

const useGenericUploads = (loanGuid: string): boolean => {
  const loan = useAppSelector(state => selectLoan(state, loanGuid));
  const { 'loan-milestone': loanMilestone } = loan || {};

  return !!loan && !!loanMilestone && ![LoanMilestones.CLOSED, LoanMilestones.FUNDED].includes(loanMilestone);
};

export default useGenericUploads;
