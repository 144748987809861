import DataItem from '../../ui/datalist/dataItem/DataItem';
import { IDataItem } from '../../ui/datalist/dataItem/IDataItem';
import './DetailsCard.scss';
import { LoanDetailsCardProps } from './IDetailsCard';
import cn from 'classnames';

export const DetailsCardContent = ({ subHeader, details, className }: LoanDetailsCardProps) => {
  // if a dlist item is on the last row of the grid then it should have no bottom margin
  // the grid col count needs to match with the dlist item cut off in dlist.css
  return (
    <>
      {subHeader}
      <div className={cn(className, 'grid grid-cols-2 gap-x-4 sm:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3')}>
        {details.map((detail: IDataItem) => {
          return <DataItem {...detail} key={detail.label} />;
        })}
      </div>
    </>
  );
};

const DetailsCard = ({ subHeader, details }: LoanDetailsCardProps) => {
  return (
    <div className='details-card px-6 py-6 mb-12 border rounded-xl border-gray-25'>
      {/* mobile layout */}
      <div className='lg:hidden'>
        <DetailsCardContent subHeader={subHeader} details={details} />
      </div>

      {/* desktop layout */}
      <div className='hidden lg:flex'>
        <div className='w-full'>
          <DetailsCardContent subHeader={subHeader} details={details} />
        </div>
      </div>
    </div>
  );
};

export default DetailsCard;
