import React from 'react';

interface SupportContextState {
  supportComponent?: React.ReactNode;
  setSupportComponent?: (supportComponent: React.ReactNode) => void;
}

export const SupportContext = React.createContext<SupportContextState>({
  supportComponent: undefined,
  setSupportComponent: undefined,
});
