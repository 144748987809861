import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { selectUserLoanAttributesByLoan, updateUserLoanAttribute } from '../../../../features/user/userSlice';
import { sendEvent } from '../../../../features/analytics/sendEvent';
import type { Loan } from '../../../../features/loans/types';
import WelcomeModal from './WelcomeModal';
import timestamp from '../../../../utils/timestamp';
import { log } from '../../../../utils/logger';
import { useFetchTasks } from '../../../../hooks/useFetchTasks';

export const WELCOME_SHOWN_KEY = 'welcomeShown';

export const Welcome = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;
  const dispatch = useAppDispatch();
  const welcomeShown = !!useAppSelector(state => selectUserLoanAttributesByLoan(state, loanGuid))?.[WELCOME_SHOWN_KEY];
  const [open, setOpen] = useState(false);
  const welcomeModalOpened = useRef<boolean>(false);
  const { hasTasksData } = useFetchTasks(loanGuid);

  useEffect(() => {
    if (!welcomeShown && !open && !welcomeModalOpened.current && hasTasksData) {
      welcomeModalOpened.current = true;
      setOpen(true);
      dispatch(updateUserLoanAttribute({ loanGuid, value: { [WELCOME_SHOWN_KEY]: timestamp() } }));
    }
  }, [dispatch, loanGuid, open, welcomeShown, hasTasksData]);

  const handleOpen = () => {
    sendEvent('welcomeModalShown', { loanGuid });
    log({ loanGuid, message: 'Welcome modal shown' });
  };

  return <WelcomeModal onOpen={handleOpen} onRequestClose={() => setOpen(false)} open={open} />;
};
