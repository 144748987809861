import { EmbedProps } from './IEmbed';

const Embed = (props: EmbedProps) => {
  const { title, src, className, onLoad, ...restProps } = props;

  const handleOnload = () => {
    onLoad?.();
  };

  return (
    <iframe onLoad={handleOnload} data-hj-allow-iframe='true' {...restProps} title={title} className={className} src={src} />
  );
};

export default Embed;
