import FormButton from './FormButton';
import { FormButtonComponentProps } from './IFormButton';
import cn from 'classnames';

const FormButtonComponent = (props: FormButtonComponentProps) => {
  const { children, id, error, containerClassName, buttonContainerClassName, ...rest } = props;
  return (
    <div className={cn('flex flex-col items-center', containerClassName)}>
      <FormButton containerClassName={buttonContainerClassName} {...rest}>
        {children}
      </FormButton>
      {error && <p className={cn('relative h-0 form-button-error-text text-error-important')}>
        {error}
      </p>}
    </div>
  );
};

export default FormButtonComponent;
