import { useAppSelector } from '../../hooks/hooks';
import { selectGlobalLoading, selectHasInitialData } from '../../features/app/appSlice';
import { SpinnerFullScreen } from '../ui/spinner/Spinner';
import Fade from '../animations/Fade';
import { useEffect, useState } from 'react';
import useError from '../../hooks/useError';
import { sendGlobalLoadingEventToShellApp } from '../../features/mobileApp/shellAppEvents';

// App spinner is a spinner masked over the main app. If spinner is shown the app will not be shown.
const AppSpinner = () => {
  const hasInitialData = useAppSelector(selectHasInitialData);
  const isLoading = useAppSelector(selectGlobalLoading);
  const { isGlobalError, isErrorPage } = useError();

  const [show, setShow] = useState(true);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (isLoading) {
      setShow(true);
    }

    // Skip hasInitialData check
    if (isGlobalError) {
      setShow(false);
    }

    // This error route doesn't need data
    if (hasInitialData && isErrorPage) {
      setShow(false);
    }

    // Always show spinner during initial data loading
    // Show spinner if component chooses to
    if (hasInitialData && !isLoading) {
      setDuration(60);
      setTimeout(() => !isLoading && setShow(false), 100);
    }
  }, [isGlobalError, hasInitialData, isErrorPage, isLoading]);

  useEffect(() => {
    sendGlobalLoadingEventToShellApp(show);
  }, [show]);

  return (
    <Fade on={show} duration={duration} unmountOnExit={true}>
      <SpinnerFullScreen />
    </Fade>
  );
};

export default AppSpinner;
