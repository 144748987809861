import { Task } from '../../../features/tasks/types';
import { useAdditionalTaskDetails } from '../../../hooks/useAdditionalTaskDetails';
import { useTaskText } from '../../../hooks/useTaskText';
import FormButtonComponent from '../../ui/form/FormButtonComponent';
import { AdditionalTaskDetails, DefaultTaskBodyDescription } from '../task/Task';
import { useEffect, useState } from 'react';
import Modal from '../../ui/modal/Modal';
import { Connect, ConnectErrorEvent } from 'connect-web-sdk';
import { log } from '../../../utils/logger';
import Button from '../../ui/button/Button';
import ConfirmationModalContent from '../../ui/modal/ConfirmationModalContent';
import { serializeError } from '../../../utils/serializeError';
import ErrorModalContent from '../../ui/modal/ErrorModalContent';
import { useDefaultSupport } from '../../../hooks/useDefaultSupport';
import { closeModal, openModal } from '../tasklist/TaskModal';
import api from '../../../features/api';
import { patchTask, updateTaskCompleted } from '../../../features/tasks/actions';
import { useDispatch } from 'react-redux';
import { sendTaskUpdateEvent } from '../../../features/analytics/sendTaskUpdateEvent';

const GrVoaTaskBody = ({ loanGuid, task }: { loanGuid: string; task: Task<'voa' | 'assets_vendor_voa' | 'gr_assets_voa'> }) => {
  const taskDescription = useTaskText(task, 'description', loanGuid);
  const additionalDetails = useAdditionalTaskDetails(task, loanGuid);
  const { 'task-id': taskId, 'task-completed': taskCompleted, source, 'task-type': taskType } = task;
  const [voaUrl, setVoaUrl] = useState<string>();
  const [showVoaModal, setShowVoaModal] = useState(false);
  const [voaModalOpened, setVoaModalOpened] = useState(false);
  const [showRemoveVoaModal, setShowRemoveVoaModal] = useState(false);
  const [showRemoveVoaFailed, setShowRemoveVoaFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removeTaskLoading, setRemoveTaskLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [disabled, setDisabled] = useState(false);
  const defaultSupport = useDefaultSupport();
  const dispatch = useDispatch();

  useEffect(() => {
    // Destroy Finicity iframe on start and end
    (window as any).finicityConnect?.destroy();
    return () => {
      (window as any).finicityConnect?.destroy();
    };
  }, []);

  useEffect(() => {
    const handleClose = () => {
      (window as any).finicityConnect?.destroy();
      setVoaUrl(undefined);
      setShowVoaModal(false);
    };

    const handleFinicity = (voaUrl: string) => {
      Connect.launch(
        voaUrl,
        {
          onDone: () => {
            handleClose();
            dispatch(updateTaskCompleted(taskId, true));
          },
          onCancel: () => {
            handleClose();
          },
          onError: (event: ConnectErrorEvent) => {
            log({ loanGuid, taskId, level: 'error', message: `VOA: Finicity error ${event}` });
          },
        },
        {
          selector: '#finicity-connect-container',
          overlay: 'rgba(255, 255, 255, 0)',
        },
      );
    };

    if (voaUrl && voaModalOpened) {
      handleFinicity(voaUrl);
    }
  }, [voaUrl, voaModalOpened, loanGuid, taskId, dispatch]);

  const onVoaModalOpened = () => {
    setVoaModalOpened(true);
    setLoading(false);
  };

  const onVoaModalClosed = () => {
    setVoaModalOpened(false);
  };

  const handleError = () => {
    setLoading(false);
    setError('Asset verify error. Please try again later.');
  };

  const handleVoaClick = async () => {
    sendTaskUpdateEvent({ taskId, taskType });
    log({ loanGuid, taskId, level: 'info', message: 'VOA: Order' });
    setLoading(true);
    setError(undefined);

    api
      .postVoaOrder(loanGuid, `${window.location.origin}/loan/${loanGuid}/tasks`)
      .then(response => {
        if (response?.data?.uiElement) {
          setVoaUrl(response.data.uiElement);
          setShowVoaModal(true);
          dispatch(
            patchTask(loanGuid, taskId, {
              source,
              'task-meta': {
                orderId: response.data.orderId,
              },
            }),
          );
        } else {
          handleError();
        }
      })
      .catch(error => {
        log({ loanGuid, taskId, level: 'error', message: `VOA: Order failed ${serializeError(error)}` });
        handleError();
      });
  };

  const handleRemoveVoaTask = () => {
    log({ loanGuid, taskId, level: 'info', message: 'VOA: Verify assets manually' });
    setShowRemoveVoaModal(true);
  };

  const VoaTask = () => {
    return (
      <div className='flex flex-col justify-center'>
        <FormButtonComponent
          containerClassName='pb-3'
          buttonContainerClassName='w-full md:w-fit-content'
          className='w-full md:w-fit-content'
          buttonType='primary'
          error={error}
          loading={loading}
          disabled={disabled}
          onClick={handleVoaClick}
        >
          {taskCompleted ? 'Add/review accounts' : 'Verify assets automatically'}
        </FormButtonComponent>
        <div className='text-center'>
          <Button
            buttonType='tertiary'
            text='or, verify your assets manually'
            disabled={disabled || loading}
            onClick={handleRemoveVoaTask}
          />
        </div>
      </div>
    );
  };

  const onVoaModalClose = () => {
    (window as any).finicityConnect?.destroy();
    setVoaUrl(undefined);
    setShowVoaModal(false);
  };

  const onRemoveTask = async () => {
    try {
      sendTaskUpdateEvent({ taskId, taskType });
      log({ loanGuid, taskId, level: 'info', message: 'VOA: Confirm verify assets manually' });
      setDisabled(true);
      setRemoveTaskLoading(true);
      await api.patchLoanTask(loanGuid, taskId, { source, 'task-removed': true });
      log({ loanGuid, taskId, level: 'info', message: 'VOA: Confirm verify assets manually succeed' });
      // Hide component's modal and open a task modal - this will stay open after the tasks update
      setShowRemoveVoaModal(false);
      openModal(
        taskId,
        <ConfirmationModalContent
          title='You opted out of asset verification.'
          textNode={<p>Please upload documents to tasks manually.</p>}
          cancelButtonText='Back to your tasklist'
          onCancel={() => closeModal(taskId)}
        />,
        'Opted out asset verification',
      );
    } catch (error) {
      log({ loanGuid, taskId, level: 'error', message: `VOA: Confirm verify assets manually failed ${serializeError(error)}` });
      setDisabled(false);
      setShowRemoveVoaFailed(true);
    } finally {
      setRemoveTaskLoading(false);
    }
  };

  const onRemoveModalClose = () => {
    log({ loanGuid, taskId, level: 'info', message: 'VOA: Close verify assets manually' });
    setShowRemoveVoaModal(false);
    setShowRemoveVoaFailed(false);
  };

  return (
    <>
      <Modal
        open={showVoaModal}
        contentLabel='VOA'
        onRequestClose={onVoaModalClose}
        fullWidth={true}
        onOpened={onVoaModalOpened}
        onClosed={onVoaModalClosed}
        contentStyle={{ width: '100%', height: '755px' }}
      >
        <div id='finicity-connect-container' className='flex place-items-center justify-center' />
      </Modal>
      <Modal open={showRemoveVoaModal} contentLabel='VOA' onRequestClose={onRemoveModalClose} fullWidth={true}>
        {showRemoveVoaFailed ? (
          <ErrorModalContent
            title='Unable to opt out of asset verification.'
            location='your tasklist'
            support={defaultSupport}
            onDone={onRemoveModalClose}
          />
        ) : (
          <ConfirmationModalContent
            title='Are you sure you want to provide documents manually?'
            textNode={
              <div className='flex flex-col'>
                <p className='mb-4'>
                  We will add multiple tasks to your tasklist asking for documents. This may be less convenient than providing
                  verification via automated account access.
                </p>
                <p className='font-bold'>This cannot be undone.</p>
              </div>
            }
            confirmButtonText='Yes, continue'
            onConfirm={onRemoveTask}
            confirmLoading={removeTaskLoading}
            cancelButtonText='No, cancel'
            onCancel={onRemoveModalClose}
            className='px-6 lg:px-0 max-w-lg lg:max-w-full mx-auto'
          />
        )}
      </Modal>
      <DefaultTaskBodyDescription taskDescription={taskDescription} />
      {additionalDetails && <AdditionalTaskDetails additionalDetails={additionalDetails} />}
      <VoaTask />
    </>
  );
};

export default GrVoaTaskBody;
