import { FormGroupErrorProps } from './IFormGroupError';
import cn from 'classnames';

export const FormGroupError = ({ id, error }: FormGroupErrorProps) => {
  return error ?
    <div
      className={cn('form-error-text')}
      id={`form-error-${id}`}
      role='alert'
      aria-live='assertive'
      aria-atomic={true}
    >
      {error}
    </div> : null;
};

export default FormGroupError;
