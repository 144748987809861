import { DatalistProps, KeyValue } from './IDatalist';
import DataItem from './dataItem/DataItem';

const Datalist = (props: DatalistProps) => {
  if (!props.children && !props.data) {
    return <></>;
  }

  const dataListData = props.data?.map((item: KeyValue, index: number) => {
    return (
      <DataItem
        key={index}
        label={item.label}
        value={item.value}
        address={item.address}
        labelClassName={item.labelClassName}
        valueClassName={item.valueClassName}
      ></DataItem>
    );
  });

  return (
    <dl className={`dlist ${props.inline ? 'dlist--inline' : 'dlist--stacked'}`}>
      {dataListData || props.children}

      {props.totalAmount && (
        <div>
          <div className='dlist-break'></div>
          <div className='dlist-item'>
            <dt className='dlist-label font-bold'>{props.totalTitle}</dt>
            <dd className='dlist-value font-bold' data-qa='payment-fees-total'>
              {props.totalAmount}
            </dd>
          </div>
        </div>
      )}
    </dl>
  );
};

export default Datalist;
