import { iRadioButtonProps } from './iRadioButton';
import cn from 'classnames';

const RadioButton = (props: iRadioButtonProps) => {
  const { id, value, name, onClick, className, defaultChecked, label, disabled } = props;
  return (
    <div className={cn('radio-item', className)}>
      <input
        key={id}
        id={id}
        type='radio'
        value={value}
        name={name}
        onClick={onClick}
        defaultChecked={defaultChecked}
        className='mr-1'
        disabled={disabled}
      />
      <label className='cursor-pointer' htmlFor={id}>{label}</label>
    </div>
  );
};

export default RadioButton;
