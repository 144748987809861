import { useEffect } from 'react';
import { log } from '../utils/logger';

const useErrorHandling = () => {
  useEffect(() => {
    const handleError = (event: ErrorEvent) => {
      const { message, filename, lineno, colno, error } = event;
      event.preventDefault();
      log({ error, message: `Uncaught Error: ${message} ${JSON.stringify({ filename, lineno, colno })}` });
    };
    window.addEventListener('error', handleError);
    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);
};

export default useErrorHandling;